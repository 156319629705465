export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchIcon= require("../assets/searchIcon.svg")
export const filterIcon= require("../assets/filterIcon.svg")
export const closeIcon= require("../assets/image_Close.svg")
export const assignmentIcon= require("../assets/assignment-success.svg")
export const studentIcon= require("../assets/student-success.svg")
export const userGroupIcon= require("../assets/usergroup-success.svg")
export const deleteIcon= require("../assets/delete-icon.svg")

