import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { errorIcon, left_arrow, logo, logoMini, mainImage, right_arrow, star, greenTickIcon, crossIcon } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type ObjectFitValue = 'cover';
type OverflowXProperty = 'hidden';
type TextAlignProperty = 'center'
const webStyle = {
  container: {
    // height: "100%",   
    overflowX: "hidden" as OverflowXProperty,
    alignItems:'start'

  },
  cards: {
    height: '100%',
    display: 'flex',
    marginTop: '50px'
    // alignItems: 'start'
  },

  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "10px 35px",
    maxWidth: "65%",
    // justifyContent:"center",
    margin: "0 auto",
    gap: "15px",
  },
  loginimg: {
    // paddingTop: "210px",
    borderRadius: "10px 10px 0 0",
    height: "550px",
    overflow: "hidden",
    width: "100%",
  },
  innerimg: {
    width: "100%",
    height: "100%",
    objectFit: "cover" as ObjectFitValue,
    objectPosition: '0px 40%',
    borderRadius: "10px 10px 0px 0px"
  },
  customH2: {
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "32px",
  },
  ptext: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    color: "#000",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "10px",
    borderRadius: "0 0 10px 10px",
    background: gradientBackground,
    padding: "25px",
  },
  contentWrappertext: {
    fontSize: "16px",
    color: "#fff",
    fontFamily: "poppins",
    fontWeight: 500,
    letterSpacing: "0.18px",
  },
  logoImage: {
    minWidth: "250px",
    cursor: "pointer"
  },
  logoImageMini: {
    maxWidth: "150px",
  },

  formWrapper: { width: "100%", paddingTop: "20px" },
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    'label': {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  linkStyle: {
    fontSize: "14px",
    color: "#000",
    fontWeight: "400",
    textAlign: "right",
    fontFamily: "Poppins",
  },
  rememberMeStyle: {
    fontSize: "14px",
    color: "#000",
    display: "flex",
    fontFamily: "Poppins",
    alignItems: "center",
    fontWeight: "400",

  },
  loginbtn: {
    background: "#2b3d7d",
    border: "none",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginTop: "15px",
    cursor: "pointer"
  },
  labelStyle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    padding: "10px 0px",
    gap: "10px",
    fontSize: "12px",
    color: "#2B3D7D",
  },
  lineLeft: {
    flex: "30%",
    border: "1px solid",
    borderColor: gradientBackground,
  },
  lineRight: {
    flex: "30%",
    border: "1px solid",
    borderColor: gradientBackground,

  },
  googlebtn: {
    background: "#fff",
    border: "1px solid #2b3d7d",
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  googlebtnText: {
    color: "#2b3d7d",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "8px"
  },
  customInput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    '.MuiInputBase-input': {
      background: 'red'
    },
  },
  bottomtext: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  startbox: {
    display: "flex",
    flexDirection: "column",
  },
  arrowsimg: {
    display: "flex",
    gap: "10px"
  },
  bgcolor: {
    background: "#EFFAFF",
    padding: "30px",
    height: "auto",
  },
  errormsg: {
    height: "50px",
    borderRadius: "14px",
    fontFamily: "Poppins",
    background: "rgba(254,87,90,0.15)",
    color: "#fe575a",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "14px",
    fontWeight: 700,
    padding: "10px 15px",
    position: "relative",
  },
  successMsg: {
    height: "50px",
    borderRadius: "14px",
    fontFamily: "Poppins",
    background: 'rgba(131, 254, 87, 0.15)',
    width: "350px",
    color: 'rgb(93, 176, 86)',
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "14px",
    fontWeight: 700,
    padding: "10px 15px",
    position: "relative",
  },
  inputlabel: {
    fontFamily: "Poppins",
  },
  signupLink: {
    color: "#2B3D7D",
    cursor: "pointer"
  },
  signupLabel: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.16px",
    textAlign: "center" as TextAlignProperty
  },
  signupCard: {
    height: '100%',
    display: 'flex',
  },
};

import ForgotPasswordController, {
  Props, configJSON,
} from "./ForgotPasswordController";
import UserSelectionModal from "../../../components/src/UserSelectionModal.web";
import Signup from "../../email-account-login/src/components/Signup.web";
import EmailAccountLogin from "../../email-account-login/src/EmailAccountLogin.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start

  renderDefaultView = () => {
    return (
      <>
        <Grid container spacing={3}>

          <Grid item xs={12} >

            <Box sx={webStyle.inputStyle}>
              <label style={webStyle.inputlabel}>Email ID </label>
              <Input
                data-test-id="txtInputEmail"
                placeholder={"Enter your email"}
                fullWidth={true}
                value={this.state.email}
                style={
                  this.state.loginError === "Account not found, or not activated"
                    || this.state.loginError === "Email is required!"
                    || this.state.loginError === "Email and password are required"
                    ? { ...webStyle.customInput, border: "1px solid #fe575a" }
                    : webStyle.customInput
                }
                onChange={this.handleChangeEmail}
              />
            </Box>
          </Grid>

          <Grid item xs={12} >
            <button
              onClick={this.handlePostPasswordApi}
              data-testid={"btnEmailLogIn"}
              style={webStyle.loginbtn} >
              {this.state.loading ? <CircularProgress style={{ color: 'white' }} /> : 'Generate Reset Link'}
            </button>
          </Grid>

          <Grid item xs={12} >
            <p>Don't have an account yet? <span data-test-id="signLink"  style={webStyle.signupLink} onClick={() => this.showSelectRoleModal('signup')}>Sign Up / </span><span data-test-id="loginLink" style={webStyle.signupLink} onClick={() => this.showSelectRoleModal('login')}>Login</span></p>
          </Grid>

        </Grid>

      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    let subTitle = configJSON.subTitle;
    let labelTitle = configJSON.labelTitle;
    const location = window.location.pathname

    let componentToRender;
    if (location === "/login/signup") {
      componentToRender = <Signup navigation={""} id="" selectedRole={this.state.selectedRole} />
    }
    else if (location === "/login") {
      componentToRender = <EmailAccountLogin navigation={""} id="" selectedRole={""}  />
    } else {
      componentToRender = this.renderDefaultView();
    }
    return (
      <ThemeProvider data-test-id='test1' theme={theme}>
        <Grid id='test2' container alignItems="center" style={webStyle.container}>
          <Grid className="test3" item xs={12} md={6} style={webStyle.cards}>
            <Box id="test4" sx={webStyle.mainWrapper}>
              <Box data-test-id="test5" sx={webStyle.customH2}>
                {labelTitle}
              </Box>
              <Box className="test6">
                <img style={webStyle.logoImage} src={logo} onClick={() => this.handleNavigation("HomePage")} />
              </Box>
              <Box id="test7" sx={webStyle.ptext}>
                {subTitle}
              </Box>
              {this.state.loginError && (
                <Box data-test-id="test8" sx={webStyle.errormsg}>
                  <img data-testid="loginErrorMsg" src={errorIcon} onClick={()=>{this.setState({loginError: ''})}} style={{cursor:"pointer"}}/>
                  {this.state.loginError}
                </Box>
              )}

              {this.state.loginSuccess && (
                <Box data-test-id="test8" sx={webStyle.successMsg}>
                  <img src={crossIcon} data-testid="crossTick"  onClick={()=>{this.setState({loginSuccess: ''})}} style={{width:'35px',cursor:"pointer",position:"absolute", top:'0px', right:"6px"}}/>
                  <img src={greenTickIcon}  style={{width:'30px'}}/>
                  {this.state.loginSuccess}
                </Box>
              )}

              {componentToRender}
            </Box>
          </Grid>

          <Grid className="test9" item xs={12} md={6} style={webStyle.cards}>
            <Box data-test-id='test10' sx={webStyle.bgcolor}>
              <Box id='test11' sx={webStyle.loginimg}>
                <img style={webStyle.innerimg} src={mainImage} />
              </Box>
              <Box className="test12" sx={webStyle.contentWrapper}>
                <img style={webStyle.logoImageMini} src={logoMini} />
                <p className="test13" style={webStyle.contentWrappertext}>
                Empower Learning with Engagemo: Engage, Inspire, Succeed!.
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <UserSelectionModal hideModal={this.hideModal} isVisibleModal={this.state.isVisibleModal}  selectedRole={this.state.selectedRole} handleSelectRole={this.handleSelectRole} source={this.state.source} handleSubmitRole={this.handleSubmitRole}  />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

