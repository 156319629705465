import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
    popupContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingRight:"30px",
      paddingLeft:"30px",
    },
    container: {
      positiobe: "relative",
    },
    crossIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    paper: {
      position: "absolute",
      width: "488px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      display: "flex",
      justifyContent: "center",
      aignItems: "center",
      padding: "35px 0",
      [theme.breakpoints.down('xs')]: {
        width:"80%"
       },
    },
  }));
  export function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }