export const logoMini = require("../assets/logo-white.png");
export const notification = require("../assets/notification.png");
export const profile = require("../assets/userimg.png");
export const teacher = require("../assets/teacher.png");
export const student = require("../assets/student.png");
export const searchIcon = require("../assets/searchIcon.png");
export const studentIcon = require("../assets/student_icon.png");
export const teacherIcon = require("../assets/teacher_icon.png");
export const calendarPickerIcon = require("../assets/calendar_picker_button.png");
export const defaultImage = require("../assets/default_image.jpg");
export const Assignments = require("../assets/assignments.svg");
export const Lessons = require("../assets/lessons.svg");
export const Quizzes = require("../assets/quizzes.svg");
export const trashImageIcon = require("../assets/image_delete.svg");
export const copyLink = require("../assets/view_link.svg");
export const deleteOption = require("../assets/delete_option_inactive.svg");
export const previewOption = require("../assets/preview_option_inactive.svg");
export const shareOption = require("../assets/share_option_inactive.svg");
export const downloadOption = require("../assets/download_option_inactive.svg");
export const deleteOptionActive = require("../assets/delete_option_active.svg");
export const previewOptionActive = require("../assets/preview_option_active.svg");
export const shareOptionActive = require("../assets/share_option_active.png");
export const downloadOptionActive = require("../assets/download_option_active.svg");
export const assignmentsIcon = require("../assets/assignments_icon.svg");
export const lessonsIcon = require("../assets/lessons_icon.svg");
export const quizzesIcon = require("../assets/quizzes_icon.svg");
export const filterIcon = require("../assets/filterIcon.svg");
export const viewNotesIconInactive = require("../assets/view_notes2_icon.svg");
export const annotateOptionActive = require("../assets/annotate_option_active.svg")
export const annotateOptionInactive = require("../assets/annotate_option_inactive.svg")
export const notesIcon = require("../assets/view_notes_icon.svg");
export const moveToIcon = require("../assets/view_move.svg")
export const moveToActive = require("../assets/view_moreActive.svg")
export const deliverIcon = require("../assets/view_deliver.svg");
export const editIcon = require("../assets/view_edit.svg");
export const folderIcon = require("../assets/view_folder_icon.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const successImage = require("../assets/move_success.png");
export const deliverActive = require("../assets/deliverActive.svg")
export const editActiveIcon = require("../assets/edit_option_active.svg")
export const zoomIn = require("../assets/view_zoomin.svg");
export const zoomOut = require("../assets/view_zoomout.svg");
export const downloadLesson = require("../assets/image_download.svg");
export const save = require("../assets/image_save.svg");
export const drag = require("../assets/image_drag.svg");
export const redo = require("../assets/image_redo.svg");
export const undo = require("../assets/image_undo.svg");
export const nextPage = require("../assets/arrow_next.svg");
export const backPage = require("../assets/arrow_down.svg");
export const venn = require("../assets/venn.svg");
export const activeDragIcon = require("../assets/group_hand_icon2_selected.svg");
export const lessonIcon = require("../assets/group_lesson_icon.svg"); 
export const viewClose = require("../assets/view_close.svg"); 
export const quizIcon = require("../assets/group_quiz_icon.svg");
export const assignmentIcon = require("../assets/group_assignment_icon.svg");
export const viewDownload = require("../assets/view__download.svg");
export const viewAnnotation = require("../assets/view_annotation_symbol.svg");
export const noClassImg = require("../assets/noClassImg.svg")
export const commentIcon = require("../assets/view_comment.svg");
export const highlighterIcon = require("../assets/view_highlight.svg");
export const moreIcon = require("../assets/view_more.svg");