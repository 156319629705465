import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrayDisplayField from "../../../../PeopleManagement2/src/SchoolAdmin/ArrayDisplayField.web";
import StudentContextMenu from "../components/StudentContextMenu.web";
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function StudentsTable(props: any) {
  const {
    studentList,
    pageNo,
    showAddStudentView,
    selectedStudent,
    viewStudent,
    deleteStudent,
    isDeleteSuccess,
    deleteCleanup,
    sendReminder,
    isReminderSuccess,
    reminderCleanup,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContextMenu, setOpenContextMenu] = React.useState(false);
  const [contextMenuUserData, setContextMenuUserData] = React.useState<any>({});
  const [openViewDetailsModal, setViewDetailsModal] =
    React.useState<boolean>(false);
  const [openDeleteProfileModal, setDeleteProfileModal] =
    React.useState<boolean>(false);
  const [openReminderModal, setReminderModal] = React.useState<boolean>(false);
  const [visiblePasswords, setVisiblePasswords] = React.useState<any>({});
  const handleOpenContextMenu = (event: any, id: string, name: string,profileStatus:string,classes:any) => {
    event.preventDefault();
    setContextMenuUserData({
      id,
      name,
      pageNo,
      profileStatus: profileStatus?profileStatus:"incomplete",
      classes
    });
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };
  const handleCloseContextMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenContextMenu(false);
  };
  const handleCloseViewDetailsModal = () => {
    setViewDetailsModal(false);
  };
  const handleOpenViewDetailsModal = (id: string) => {
    viewStudent(id);
    setViewDetailsModal(true);
  };
  const handleCloseDeleteProfileModal = (e: any, id: string) => {
    deleteCleanup(id);
    setDeleteProfileModal(false);
  };
  const handleOpenDeleteProfileModal = () => {
    setDeleteProfileModal(true);
  };
  const handleCloseReminderModal = () => {
    reminderCleanup();
    setReminderModal(false);
  };
  const handleOpenReminderModal = () => {
    setReminderModal(true);
  };
  
  const togglePasswordVisibility = (studentId:any) => {
    setVisiblePasswords((prevVisiblePasswords:any) => ({
      ...prevVisiblePasswords,
      [studentId]: !prevVisiblePasswords[studentId],
    }));
  };
  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="center">S No.</StyledTableCell>
        <StyledTableCell align="center">Full Name</StyledTableCell>
        <StyledTableCell align="center">Class</StyledTableCell>
        <StyledTableCell align="center">Student Email ID</StyledTableCell>
        <StyledTableCell align="center">Password</StyledTableCell>
        <StyledTableCell align="center">Date Created</StyledTableCell>
        <StyledTableCell align="center">Profile Status</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    );
  };
  const tableBody = () => {
    return studentList.map((data: any, index: number) => (
      <StyledTableRow key={data.id}>
        <StyledTableCell component="th" scope="row" align="center">
          {(pageNo - 1) * 10 + index + 1}
        </StyledTableCell>
        <StyledTableCell align="center">{data.fullName}</StyledTableCell>
        <StyledTableCell align="center">
          <ArrayDisplayField
            data={data.classes.map((item: any) => item.name)}
          />
        </StyledTableCell>
        <StyledTableCell align="center">{data.email}</StyledTableCell>
        <StyledTableCell align="center">
          {visiblePasswords[data.id]  ? data.password : `**********`}
        </StyledTableCell>
        <StyledTableCell align="center">{data.creationDate}</StyledTableCell>
        <StyledTableCell align="center">{data.profileStatus}</StyledTableCell>
        <StyledTableCell align="center">
          <MoreVertIcon
            data-test-id="action-icon"
            onClick={(e) => handleOpenContextMenu(e, data.id, data.fullName,data.profileStatus,data.classes)}
          />
        </StyledTableCell>
      </StyledTableRow>
    ));
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>{tableHead()}</TableHead>
          <TableBody>{tableBody()}</TableBody>
        </Table>
      </TableContainer>
      <StudentContextMenu
        data-test-id="context-menu"
        textMenu
        anchorEl={anchorEl}
        openContextMenu={openContextMenu}
        handleCloseContextMenu={handleCloseContextMenu}
        redirect={() => {}}
        contextMenuUserData={contextMenuUserData}
        showAddStudentView={showAddStudentView}
        openViewDetailsModal={openViewDetailsModal}
        handleCloseViewDetailsModal={handleCloseViewDetailsModal}
        handleOpenViewDetailsModal={handleOpenViewDetailsModal}
        handleOpenDeleteProfileModal={handleOpenDeleteProfileModal}
        handleCloseDeleteProfileModal={handleCloseDeleteProfileModal}
        openDeleteProfileModal={openDeleteProfileModal}
        selectedStudent={selectedStudent}
        deleteStudent={deleteStudent}
        isDeleteSuccess={isDeleteSuccess}
        deleteCleanup={deleteCleanup}
        handleCloseReminderModal={handleCloseReminderModal}
        handleOpenReminderModal={handleOpenReminderModal}
        openReminderModal={openReminderModal}
        sendReminder={sendReminder}
        isReminderSuccess={isReminderSuccess}
        togglePasswordVisibility={togglePasswordVisibility}
        visiblePasswords={visiblePasswords}
      />
    </>
  );
}
