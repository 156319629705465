import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { failedCross, profileCreated, profileUpdated } from "../assets";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "5px",
  },
  subTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#3F526D",
    fontWeight: 400,
    width: "390px",
    textAlign: "center",
  },
  image: {
    marginBottom: "15px",
  },
  paper: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },
  crossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    positiobe: "relative",
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const CreateStudentSuccess = (props: any) => {
  const { handleCloseModal, fullName, isUpdateRequest, limitExceeded } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <ClearIcon onClick={handleCloseModal} className={classes.crossIcon} />
        <Box className={classes.popupContent}>
          <img
            className={classes.image}
            src={limitExceeded ? failedCross : isUpdateRequest ? profileUpdated : profileCreated}
          />
          <Typography className={classes.title}>
          {limitExceeded
            ? "Limit Exceeded!"
            : isUpdateRequest
            ? "Changes saved successfully!"
            : "New team member added!"}
          </Typography>
          <Typography className={classes.subTitle}>
          {limitExceeded
              ? "Your 2GB storage limit has been reached. Please delete few files to refresh your limit."
              : isUpdateRequest
              ? `Changes made to profile "${fullName}" have been successfully saved.`
              : `Student "${fullName}" has been successfully added.`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateStudentSuccess;
