import React, {useState} from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { question } from "../../../../assets";
import FileViewerModal from "../../../../../../../components/src/FileViewerModal.web";
const useStyles = makeStyles(() => ({
  mainLayout: {
    padding: "20px",
    position: "relative",
    top: "65px",
    height: "calc(100% - 65px)",
    overflow: "hidden",
    overflowY: "auto",
  },
  studentImage: {
    width: "36px",
    height: "36px",
    marginRight: "10px",
    borderRadius: "50%",
  },
  studentName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "5px",
  },
  commentsLayout: {
    display: "flex",
    marginBottom: "20px",
  },
  comments: {
    fontFamily: "Poppins",
    fontSize: "13px",
    color: "#282829",
    fontWeight: 400,
  },
  commentBox: {
    backgroundColor: "#F1F5F9",
    border: "1px solid #D4DCE5",
    borderRadius: "10px",
    padding: "10px",
  },
  commentImageWrap: {
    background: "#D8D8D8",
    border: "0.5px solid #979797",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    overflow: "hidden",
    width: "184px",
    height: "132px",
  },
  commentImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  timestamp: {
    fontFamily: "Poppins",
    fontSize: "11px",
    color: "#282829",
    fontWeight: 400,
  },
  pdfView:{
    cursor:"pointer"
  }
}));
const Comments = (props: any) => {
  const { messages } = props;
  const classes = useStyles();
  const [openImage, setOpenImage] = useState(false);
  const [imageData, setimageData] = useState({url:"",fileName:""});
  const handleOpen = (thisImageData:{url:string,fileName:string}) => {
    setOpenImage(true);
    setimageData(thisImageData)
  };

  const handleClose = () => {
    setOpenImage(false);
  };
  function timeAgo(timestamp: any): string {
    const intervals: string[] = [
      "year",
      "month",
      "day",
      "hour",
      "minute",
      "second",
    ];
    const durations: number[] = [31536000, 2592000, 86400, 3600, 60, 1];

    const seconds: number = Math.floor(
      (Date.now() - timestamp.seconds * 1000) / 1000
    );
    const interval: number = durations.findIndex(
      (duration: number) => seconds >= duration
    );

    if (interval === -1) {
      return "just now";
    } else {
      const intervalCount = Math.floor(seconds / durations[interval]);
      const intervalLabel = intervals[interval];
      const pluralSuffix = intervalCount === 1 ? "" : "s";

      return `${intervalCount} ${intervalLabel}${pluralSuffix} ago`;
    }
  }
  return (
    <Box className={classes.mainLayout}>
      {messages.map((message: any) => {
          const hasAttachments = message.attachments && message.attachments.length>0;
          let isImage = true;
          if(hasAttachments){            
            const filename = message.attachments[0].fileName
            const extension = filename.split('.');
            if(extension.length>0){
              isImage = extension.pop()?.toLowerCase() == "pdf"?false:true
            }
          }
        return (<Box className={classes.commentsLayout}>
          <img src={message.profileImage} className={classes.studentImage} />
          <Box className={classes.commentBox}>
            <Typography className={classes.studentName}>
              {message.name}{" "}
              <span className={classes.timestamp}>
                {timeAgo(message.createdAt)}
              </span>
            </Typography>
            <Typography className={classes.comments}>
              {message.message}
            </Typography>
            { hasAttachments && (isImage ?
            <Box className={classes.commentImageWrap}>
                <img className={classes.commentImage} src={message.attachments[0].url} onClick={()=>handleOpen(message.attachments[0])} height={200} width={200} data-test-id="imageView"/>
            </Box>:
            <Typography onClick={()=>handleOpen(message.attachments[0])} className={classes.pdfView} data-test-id="pdfView">
              {message.attachments[0].fileName}
            </Typography>
            )}
          </Box>
        </Box>)
    })}
    <FileViewerModal
        open={openImage}
        handleClose={handleClose}
        fileUrl={imageData.url}
        filename={imageData.fileName}
      />
    </Box>
  );
};

export default Comments;
