import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { settingBtn } from "../../assets";
import StorageProvider from "../../../../../framework/src/StorageProvider";



const useStyles = makeStyles(() => ({
  editArea: {
    position :"relative",
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    padding: "25px",
  },
  settingBtn : {
    position :"absolute",
    right :"10px",
    top :"10px",
  },
}));
const NewStage = (props: any) => {
  const classes = useStyles();

  const [pageTypeData, setpageTypeData] = React.useState<string>();

  useEffect(()=>{
    pageType()
  },[])

  const pageType = async () => {
    let quizName = await StorageProvider.get("pageTypeData")
    setpageTypeData(quizName)
  }

  return <div className={classes.editArea}>
   {pageTypeData == "quizName" &&  <Box>
    <Box className={classes.settingBtn} >
        <img src={settingBtn} />
    </Box>
   </Box>}
  </div>;
};

export default NewStage;
