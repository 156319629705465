// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const logoMini= require("../assets/logo-white.svg")
export const notification= require("../assets/notification.svg")
export const profile= require("../assets/userimg.svg")
export const teacher= require("../assets/teacher.svg")
export const student= require("../assets/student.svg")
export const filterIcon= require("../assets/filterIcon.svg")
export const searchIcon= require("../assets/searchIcon.svg")
export const studentIcon= require("../assets/student_icon.svg")
export const teacherIcon= require("../assets/teacher_icon.svg")
export const imageDeletedIcon= require("../assets/image_Deleted.svg")
export const closeIcon= require("../assets/image_Close.svg")
export const uploadImageIcon= require("../assets/ImageUpload.svg")
export const defaultImage = require("../assets/default_image.jpg");


// Customizable Area End