import React from "react";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import SearchInput from "../../../../../components/src/SearchInput.web";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { filterIcon } from "../../assets";
import StudentsTable from "../components/StudentsTable.web";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
const useStyles = makeStyles((theme) => ({
  searchBarLayout: {
    display: "flex",
  },
  addButtonLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "9px"
    },
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
}));
const StudentList = (props: any) => {
  const classes = useStyles();
  const {
    destination,
    locations,
    pageLimitStudent,
    pageNoStudent,
    studentList,
    showAddStudentView,
    redirect,
    selectedStudent,
    viewStudent,
    deleteStudent,
    isDeleteSuccess,
    deleteCleanup,
    sendReminder,
    isReminderSuccess,
    reminderCleanup,
    handlePageChangeStudent,
    searchInputStudentName,
    handleSearchStudent
  } = props;

  const addStudents = (e: any) => {
    showAddStudentView();
  };
  
  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={destination}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addButtonLayout}>
          <Box className={classes.searchBarLayout}>
            <SearchInput 
             placeholder={"Search by name"} 
             handleSearchInput={handleSearchStudent}
             searchInput={searchInputStudentName}
            />
            <img src={filterIcon} style={{ marginLeft: "10px" }} />
          </Box>
          <GenericButton
            type="COLORED"
            label="Add new student"
            handleClick={(e) => addStudents(e)}
            customStyle={{ width: "190px", height: "50px" }}
          />
        </Box>
        <Box>
          {studentList.length === 0 ? (
            <Typography className={classes.noRecords}>
              No records found
            </Typography>
          ) : (
            <>
              <StudentsTable
                selectedStudent={selectedStudent}
                studentList={studentList}
                pageNo={pageNoStudent}
                showAddStudentView={showAddStudentView}
                viewStudent={viewStudent}
                deleteStudent={deleteStudent}
                isDeleteSuccess={isDeleteSuccess}
                deleteCleanup={deleteCleanup}
                sendReminder={sendReminder}
                isReminderSuccess={isReminderSuccess}
                reminderCleanup={reminderCleanup}
              />
              <Box sx={{ px: "35px", py: "50px" }}>
                <BasicPagination
                  handlePageChange={handlePageChangeStudent}
                  pageLimit={pageLimitStudent}
                  pageNo={pageNoStudent}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StudentList;
