import React from "react";
import {
  Box,
  Input,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { errorIcon, googleIcon, left_arrow, logo, logoMini, mainImage, right_arrow, star, continueWith } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type ObjectFitValue = 'cover';
type OverflowXProperty = 'hidden';
type TextAlignProperty = 'center'
const webStyle = {
  container: {
    // height: "100%",   
    overflowX: "hidden" as OverflowXProperty,
    alignItems:'start'

  },
  cards: {
    height: '100%',
    display: 'flex',
    // alignItems: 'start'
  },

  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "10px 35px",
    maxWidth: "65%",
    // justifyContent:"center",
    margin: "0 auto",
    gap: "15px",
  },
  loginimg: {
    // paddingTop: "210px",
    borderRadius: "10px 10px 0 0",
    height: "550px",
    overflow: "hidden",
    width: "100%",
  },
  innerimg: {
    width: "100%",
    height: "100%",
    objectFit: "cover" as ObjectFitValue,
    objectPosition: '0px 40%',
    borderRadius: "10px 10px 0px 0px"
  },
  customH2: {
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "32px",
  },
  ptext: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    color: "#000",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "10px",
    borderRadius: "0 0 10px 10px",
    background: gradientBackground,
    padding: "25px",
  },
  contentWrappertext: {
    fontSize: "16px",
    color: "#fff",
    fontFamily: "poppins",
    fontWeight: 500,
    letterSpacing: "0.18px",
  },
  logoImage: {
    minWidth: "250px",
    cursor: "pointer"
  },
  logoImageMini: {
    maxWidth: "150px",
  },

  formWrapper: { width: "100%", paddingTop: "20px" },
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    'label': {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  linkStyle: {
    fontSize: "14px",
    color: "#000",
    fontWeight: "400",
    textAlign: "right",
    fontFamily: "Poppins",
  },
  rememberMeStyle: {
    fontSize: "14px",
    color: "#000",
    display: "flex",
    fontFamily: "Poppins",
    alignItems: "center",
    fontWeight: "400",

  },
  loginbtn: {
    background: "#2b3d7d",
    border: "none",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginTop: "15px",
    cursor: "pointer"
  },
  labelStyle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    padding: "10px 0px",
    gap: "10px",
    fontSize: "12px",
    color: "#2B3D7D",
  },
  lineLeft: {
    flex: "30%",
    border: "1px solid",
    borderColor: gradientBackground,
  },
  lineRight: {
    flex: "30%",
    border: "1px solid",
    borderColor: gradientBackground,

  },
  googlebtn: {
    background: "#fff",
    border: "1px solid #2b3d7d",
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  googlebtnText: {
    color: "#2b3d7d",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "8px"
  },
  customInput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    '.MuiInputBase-input': {
      background: 'red'
    },
  },
  bottomtext: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  startbox: {
    display: "flex",
    flexDirection: "column",
  },
  arrowsimg: {
    display: "flex",
    gap: "10px"
  },
  bgcolor: {
    background: "#EFFAFF",
    padding: "30px",
    height: "auto",
  },
  errormsg: {
    height: "50px",
    borderRadius: "14px",
    fontFamily: "Poppins",
    background: "rgba(254,87,90,0.15)",
    width: "90%",
    color: "#fe575a",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "14px",
    fontWeight: 700,
    padding: "10px 15px",
    position: "relative",
  },
  inputlabel: {
    fontFamily: "Poppins",
  },
  signupLink: {
    color: "#2B3D7D",
    cursor: "pointer"
  },
  signupLabel: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.16px",
    textAlign: "center" as TextAlignProperty
  },
  signupCard: {
    height: '100%',
    display: 'flex',
  },
};

import EmailAccountLoginController, {
  Props, configJSON,
} from "./EmailAccountLoginController";
import Signup from "./components/Signup.web";
import UserSelectionModal from "../../../components/src/UserSelectionModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  renderDefaultView = () => {
    return (
      <>
        <Grid container spacing={3}>

          <Grid item xs={12} >

            <Box sx={webStyle.inputStyle}>
              <label style={webStyle.inputlabel}>Email ID </label>
              <Input
                data-test-id="txtInputEmail"
                placeholder={"Enter your email"}
                fullWidth={true}
                value={this.state.email}
                style={
                  this.state.loginError === "Account not found, or not activated"
                    || this.state.loginError === "Email is required!"
                    || this.state.loginError === "Email and password are required"
                    ? { ...webStyle.customInput, border: "1px solid #fe575a" }
                    : webStyle.customInput
                }
                onChange={(e) => this.setEmail(e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} >

            <Box sx={webStyle.inputStyle}>
              <label style={webStyle.inputlabel}>Password</label>

              <Input
                data-test-id="txtInputPassword"
                type={this.state.enablePasswordField ? "password" : "text"}
                placeholder={"Enter your password"}
                fullWidth={true}
                style={
                  this.state.loginError === "Login Failed"
                    || this.state.loginError === "Password is required!"
                    || this.state.loginError === "Email and password are required"
                    ? { ...webStyle.customInput, border: "1px solid #fe575a" }
                    : webStyle.customInput
                }
                value={this.state.password}
                onChange={(e) => this.setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {this.state.enablePasswordField ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={7}>
                <Box sx={webStyle.rememberMeStyle}>
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    onClick={() =>
                      this.setRememberMe(!this.state.checkedRememberMe)
                    }
                    checked={this.state.checkedRememberMe}
                  />{" "}
                  {configJSON.rememberMeTxt}
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  data-test-id={"btnForgotPassword"}
                  sx={webStyle.linkStyle}
                  onClick={() => this.goToForgotPassword()}
                  style={{ cursor: 'pointer'}}
                >
                  Forgot Password?
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <button
              data-test-id={"btnEmailLogIn"}
              style={webStyle.loginbtn} onClick={() => this.handleSubmit()}>
              {this.state.loading ? <CircularProgress style={{ color: 'white' }} /> : 'Login'}
            </button>
          </Grid>


          <Grid item xs={12} >
            {/* <Box sx={webStyle.labelStyle}>
          <span style={webStyle.lineLeft}></span>
          <span>{this.state.labelOr}</span>
          <span style={webStyle.lineRight}></span>
                </Box> */}
            <img src={continueWith} alt='continue with' width='100%' />
          </Grid>

          <Grid item xs={12} >
            <button data-test-id={"btnSocialLogin"} style={webStyle.googlebtn} onClick={() => this.doGoogleLogin()}>
              <img src={googleIcon} /> <span style={webStyle.googlebtnText}>{this.state.btnTxtSocialLogin}</span>
            </button>
          </Grid>

          <Grid item xs={12} >
            <p>Don't have an account yet? <span data-testid="signLink" style={webStyle.signupLink} onClick={() => this.showSelectRoleModal('signup')}>Sign Up</span></p>
          </Grid>

        </Grid>

      </>
    )
  }

  render() {

    const location = window.location.pathname
    let componentToRender;
    if (location === "/login/signup") {
      componentToRender = <Signup navigation={""} id="" selectedRole={this.state.selectedRole} />
      this.subTitle = configJSON.signupSubTitle
      this.labelTitle = configJSON.signupLabelTitle;
    } else {
      componentToRender = this.renderDefaultView();
      this.subTitle = configJSON.subTitle
      this.labelTitle = configJSON.labelTitle;
    }
    return (
      <ThemeProvider theme={theme}>
        <Grid container alignItems="center" style={webStyle.container}>

          <Grid item xs={12} md={6} style={location === "/login/signup" ? webStyle.signupCard : webStyle.cards}>
            <Box sx={webStyle.mainWrapper}>
              <Box sx={webStyle.customH2}>
                {this.labelTitle}
              </Box>
              <Box>
                <img src={logo} style={webStyle.logoImage} onClick={this.handleClickLogo} />
              </Box>
              <Box sx={webStyle.ptext}>
                {this.subTitle}
              </Box>
              {this.state.loginError && (

                <Box sx={webStyle.errormsg}>
                  <img src={errorIcon} />
                  {this.state.loginError === "Account not found, or not activated" && "User does not exist. We could not find an account with this email ID."}
                  {this.state.loginError === "Login Failed" && "Incorrect Password. Please enter the correct password!"}
                  {this.state.loginError === "Email and password are required" && "Email and password are required"}
                  {this.state.loginError === "Email is required!" && "Email is required!"}
                  {this.state.loginError === "Password is required!" && "Password is required!"}
                </Box>
              )}

              {componentToRender}
            </Box>
          </Grid>

          <Grid item xs={12} md={6} style={webStyle.cards}>
            <Box sx={webStyle.bgcolor}>
              <Box sx={webStyle.loginimg}>
                <img src={mainImage} style={webStyle.innerimg} />
              </Box>
              <Box sx={webStyle.contentWrapper}>
                <img src={logoMini} style={webStyle.logoImageMini} />
                <p style={webStyle.contentWrappertext}>
                Empower Learning with Engagemo: Engage, Inspire, Succeed!.
                </p>
              </Box>
            </Box>
          </Grid>

        </Grid>
        <UserSelectionModal isVisibleModal={this.state.isVisibleModal} hideModal={this.hideModal} handleSelectRole={this.handleSelectRole} selectedRole={this.state.selectedRole} handleSubmitRole={this.handleSubmitRole} source={this.state.source} />
      </ThemeProvider>
    );
  }
}

