import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _ from "lodash";
interface Link {
  id: number;
  s_no:number;
  link_name: string;
  link: string;
  created_at: string;
  updated_at: string;
  link_type: string;
}

interface Content {
  id: number;
  s_no:number;
  content: string;
  title: string;
  created_at: string;
  updated_at: string;
  image: string;
  link:string;
  link_name:string;
}
interface Banner {
  id: number;
  s_no:number;
  content: string;
  title: string;
  created_at: string;
  updated_at: string;
  image: string;
  link1:string;
  link1_name:string;
  link2:string;
  link2_name:string;
  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bannerPageCount: number;
  contentData: Content[];
  bannerData: Banner[];
  importantLinks1: Link[];
  importantLinks2: Link[];
  importantLinks3: Link[];
  nonImportantLinks: Link[];
  currentBanner: Banner;
  socialLinks: Link[];
  aboutUsLink: any;
  homeLink:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      bannerPageCount: 1,
      contentData: [],
      bannerData: [],
      importantLinks1: [],
      importantLinks2: [],
      importantLinks3: [],
      nonImportantLinks: [],
      socialLinks: [],
      currentBanner: {
        id: 0,
        s_no:0,
        content: "",
        created_at: "",
        updated_at: "",
        title: "",
        image: "",
        link1:"",
        link1_name:"",
        link2:"",
        link2_name:"",
      },
      aboutUsLink: "",
      homeLink:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiLandingPageCallId
      ) {
        if (responseJson) {
          await this.refactorApiResponse(responseJson);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiLandingPageCallId: string = "";
  async componentDidMount() {
    this.getLandingPageContent();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  nextBanner() {
    this.setState({ currentBanner: this.state.bannerData[(this.state.bannerPageCount-1)+ 1] });
    this.setState((prevState) => {
      return { bannerPageCount: prevState.bannerPageCount + 1 };
    });
  }
  prevBanner() {
    this.setState({ currentBanner: this.state.bannerData[(this.state.bannerPageCount-1) - 1] });
    this.setState((prevState) => {
      return { bannerPageCount: prevState.bannerPageCount - 1 };
    });
  }
  getLandingPageContent() {
    const header = {
      "Content-Type": configJSON.landingPageContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLandingPageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  async refactorApiResponse(response: any) {
    const data = response[0];
    const { third_party_data } = data;
    let nonImpLinks = [];
    let impLinks1 = [];
    let impLinks2 = [];
    let impLinks3 = [];
    let socialMediaLinks = [];
    const footerNonImportantLinks = [
      "Home",
      "About us",
      "Privacy policy",
      "Terms & Conditions",
      "Contact us",
    ];
    const sLinks = ["Facebook", "Instagram", "Twitter"];
    for (const link of third_party_data) {
      if (link.link_type === "other_link") {
        if (this.checkLinkType(footerNonImportantLinks, link.link_name)) {
          nonImpLinks.push(link);
        }
        else if(this.checkLinkType(sLinks, link.link_name)) {
          socialMediaLinks.push(link);
        } 
      }
      else{
        if (link.link_type === "important_link_1") {
          impLinks1.push(link);
        }
        else if (link.link_type === "important_link_2") {
          impLinks2.push(link);

        } else {
          impLinks3.push(link);
        }
      }
    }
    const aboutUs: Link = nonImpLinks.find(
      (obj) => obj.link_name === "About us"
    );
    const home: Link = nonImpLinks.find(
      (obj) => obj.link_name === "Home"
    );
    this.setState({
      contentData: this.sortBySerialNumber(data.content_data),
      bannerData: this.sortBySerialNumber(data.carousel_data),
      importantLinks1: this.sortBySerialNumber(impLinks1), 
      importantLinks2: this.sortBySerialNumber(impLinks2), 
      importantLinks3: this.sortBySerialNumber(impLinks3),
      nonImportantLinks: this.sortBySerialNumber(nonImpLinks),
      currentBanner: data.header_content_data[0],
      socialLinks: this.sortBySerialNumber(socialMediaLinks),
      aboutUsLink: aboutUs?.link,
      homeLink: home?.link,
    });
  }

  sortBySerialNumber(inputArray:any[]){
    return inputArray.sort((a:any,b:any)=>a.s_no-b.s_no)
  }

  checkLinkType(inputArray: string[], inputString: string) {
    if (
      _.map(inputArray, (str) => _.replace(_.toLower(str), " ", "")).includes(
        _.chain(inputString)
          .toLower()
          .replace(" ", "")
          .value()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  // Customizable Area End
}
