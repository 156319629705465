// App.js - WEB
import React, { Component } from 'react';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Teachers from '../../blocks/dashboard/src/SchoolAdmin/TeacherList/Teachers.web';
import Calendar from '../../blocks/dashboard/src/SchoolAdmin/TeacherCalendar/Calender.web';
import AssingnNewTask from '../../blocks/dashboard/src/Teacher/components/AssignNewTask.web';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLogin';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Analytics from '../../blocks/analytics/src/Analytics.web'
import HomePage from '../../blocks/landingpage/src/HomePage/HomePage.web';
import HomeScreen from '../../components/src/HomeScreen';
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2.web"
import Catalogue from "../../blocks/catalogue/src/Catalogue.web"
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement.web"
import CfCourseCreation23 from "../../blocks/CfCourseCreation23/src/CfCourseCreation23.web"
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming.web"
import Cflivestreamingsubfeature from "../../blocks/cflivestreamingsubfeature/src/Cflivestreamingsubfeature.web"
import TaskList from '../../blocks/tasks/src/TaskList.web'
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest.web'
import AssessmentQuizTest from '../../blocks/AssessmentTest/src/AssessmentQuizTest.web'
import AssessmentView from '../../blocks/AssessmentTest/src/AssessmentView.web'
import TeachersDashboard from "../../blocks/dashboard/src/Teacher/pages/TeacherCalendar.web"
import StudentDashboard from "../../blocks/dashboard/src/Student/pages/StudentCalendar.web"
import Download from "../../blocks/Download/src/Download.web"
import AssessmentCheck from '../../blocks/AssessmentTest/src/AssessmentTable.web'
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2.web"
import Notifications from "../../blocks/notifications/src/Notifications.web"
import AccessDenied from '../../components/src/AccessDenied.web';
import TaskPagesListDetails from '../../blocks/tasks/src/TaskPagesListDetails.web'
import Gsuiteintegration from '../../blocks/gsuiteintegration/src/Gsuiteintegration.web'
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web'
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web'
import AssessmentQuizTeacher from '../../blocks/AssessmentTest/src/pages/AssessmentQuizTeacher.web'

import './App.css';
import { setStorageData } from '../../framework/src/Utilities';
const currentLocation = window.location.pathname.split("/")
const currentPath= currentLocation[currentLocation.length-1];
const routeMap = {

  Home: {
    component: HomeScreen,
    path: '/admin',
    exact: true
  },
  HomePage: {
    component: HomePage,
    path: '/',
    exact: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/dashboard',
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgotPassword',
    exact: true
  },
  NewPassword: {
    component: NewPassword,
    path: '/newpassword',
    exact: true
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/accountgroups',
    exact: true
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: `/login` || `/login/signup`,
  },
  Teachers: {
    component: Teachers,
    path: '/teachers',
    exact: true
  },
  TeachersDashboard: {
    component: TeachersDashboard,
    path: '/teachers-calendar',
    exact: true
  },
  StudentDashboard: {
    component: StudentDashboard,
    path: '/students-calender',
    exact: true
  },
  Calendar: {
    component: Calendar,
    path: `/teachers/:id`,
    exact: true
  },
  AssingnNewTask: {
    component: AssingnNewTask,
    path: "/assigntasks",
    exact: true
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: `/profile` || `/profile/change` || `/profile/edit`,
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/peoplemanagement',
    exact: true
  },
  PeopleManagement3: {
    component: PeopleManagement2,
    path: '/peoplemanagement/students',
    exact: true
  },
  Catalogue: {
    component: Catalogue,
    path: ['/library', `/library/shared/:type/:id`],
    exact: true
  },
  TeacherLibrary: {
    component: Catalogue,
    path: "/TeacherLibrary",
    exact: true
  },
  TeacherLibraryPractice: {
    component: Catalogue,
    path: `/teacherlibrary/:type/:id`,
    exact: true
  },
  StudentLibrary: {
    component: Catalogue,
    path: "/StudentLibrary",
    exact: true
  },
  StudentLibraryPractice: {
    component: Catalogue,
    path: `/studentlibrary/:type/:id`,
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: `/usergroups`,
    exact: true
  },
  Analytics: {
    component: Analytics,
    path: `/reports`,
    exact: true
  },
  ContentManagement: {
    component: ContentManagement,
    path: `/classes/:data` || `/classes/myclasses`,
    exact: true
  },
  CfCourseCreation23: {
    component: CfCourseCreation23,
    path: `/course/:type/:id/:name`,
    exact: true
  },
  LiveStreaming: {
    component: LiveStreaming,
    path: `/live/:id`,
    exact: true
  },
  Cflivestreamingsubfeature: {
    component: Cflivestreamingsubfeature,
    path: `/joined/:id`,
    exact: true
  },
  CflivestreamingsubfeatureGuest: {
    component: Cflivestreamingsubfeature,
    path: `/guestJoined/:id`,
    exact: false
  },
  TaskList: {
    component: TaskList,
    path: `/task`,
    exact: true
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: `/assessmenttest/:id`,
    exact: true
  },
  QuizAssessementTest: {
    component: AssessmentQuizTest,
    path: '/assessmenttestquiz/:id',
    exact: true,
  },
  AssessmentView: {
    component: AssessmentView,
    path: `/assessmentview/:id`,
    exact: true
  },
  // MyDownloads: {
  //   component: Download,
  //   path: `/mydownloads`,
  //   exact: true
  // },
  AssessmentCheck: {
    component: AssessmentCheck,
    path: `/assessmentcheck/:student_id/:assignment_id`},
  Feedback: {
    component: MultipageForms2,
    path: `/feedback`,
    exact: true
  },
  Notifications: {
    component: Notifications,
    path: `/notifications`,
    exact: true
  },
  AccessDenied: {
    component: AccessDenied,
    path: `/accessdenied`,
  },
  FeedbackDetails: {
    component: TaskPagesListDetails,
    path: `/feedbackDetails/:id`,
    exact: true
  },
  PendingReviewDetails: {
    component: TaskPagesListDetails,
    path: `/pendingDetails/:id`,
    exact: true
  },
  QuizFeedbackDetails: {
    component: TaskPagesListDetails,
    path: `/feedbackSentQuiz/:id`,
    exact: true
  },
  QuizPendingReviewDetails: {
    component: TaskPagesListDetails,
    path: `/pendingsReviewQuiz/:id`,
    exact: true
  },
  QuizAssessementCheck: {
    component: AssessmentQuizTeacher,
    path: `/quizassessmentcheck/:student_id/:assignment_id`,
    exact: true
  },
};
const firebaseConfig = {
  apiKey: "AIzaSyADj2MO1ayerqP0BnVuVgdl_cQBhYd2GD4",
  authDomain: "iqlearn-2ada0.firebaseapp.com",
  projectId: "iqlearn-2ada0",
  storageBucket: "iqlearn-2ada0.appspot.com",
  messagingSenderId: "273787809764",
  appId: "1:273787809764:web:48c68a55c65a154d3ee1f1",
  measurementId: "G-F5K4FKLMB5"
}
firebase.initializeApp(firebaseConfig);

class App extends Component {
  isAuthenticate() {
    const path = window.location.pathname;
    const token = window.localStorage.getItem("authToken");
    const tokenRegex = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/;
    const values = ["/login", "/forgotPassword", "/aboutus", "/guest", "/newpassword"];
    const isValidToken = tokenRegex.test(token);
    const isMatch = values.some(value => (path.startsWith(value) || path == "/"));
    if ((token === null || !isValidToken) && !isMatch) {
      // Navigate to the home page
      setStorageData("shareRoute", path)
      window.history.pushState({}, "", "/login");
      // // Reload the app
      window.location.reload();
      return false;
    }
    return true;
  }

  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
    if(!this.isAuthenticate()) {
      return null;
    }
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </div>
    );
  }
}
export default App;
