export const view_minus = require("../assets/view_minus.png");
export const view_plus = require("../assets/view_plus.png");


export const group_delete1 = require("../assets/group_delete.svg");
export const group_highlight1 = require("../assets/group_highlight.svg");
export const group_move1 = require("../assets/group_move.svg");
export const group_pen1 = require("../assets/group_pen.svg");
export const group_redo1 = require("../assets/group_redo.svg");
export const group_shapes1 = require("../assets/shapeIcons.svg");
export const group_text1 = require("../assets/group_text.svg");
export const group_undo1 = require("../assets/group_undo.svg");

export const white_highlight1 = require("../assets/group_highlight_white.svg");
export const white_pen1 = require("../assets/group_pen_white.svg");
export const white_shapes1 = require("../assets/group_shapes_white.svg");

export const view_Triangle = require("../assets/view_Triangle.svg");
export const view_Polygon = require("../assets/view_Polygon.svg");
export const view_Star = require("../assets/view_Star.svg");
export const eraserIcon = require("../assets/eraserSmall.png");
