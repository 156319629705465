import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import {
  StyledMenu,
  StyledMenuItem,
} from "./StyledMenu.web";
import DeleteEvent from "../../blocks/dashboard/src/SchoolAdmin/TeacherCalendar/DeleteEvent.web";
import { styled } from "@material-ui/core/styles";
import { calendarIcon } from "../../blocks/dashboard/src/assets";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#757575",
  },
  teacherName: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    padding: "0px 35px",
    marginTop: "20px",
  },
  currentDateBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "0 35px",
  },
  currenDate: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#2C2C2E",
    paddingRight: "15px",
  },
  calendarView: {
    display: "grid",
    rowGap: "10rem",
  },
  weekHeader: {
    width: "93px",
    height: "111px",
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      width: "80px",
      height: "80px",
      paddingTop: "6px",
    },
  },
  weekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center",
    color: "#858585",
    marginBottom: "10px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px"
    },
  },
  weekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#000000",
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px"
    },
  },
  calendarBox: {
    padding: "50px 35px",
    "& .MuiBox-root-47": {
      "& .rbc-calendar": {
        "& .rbc-time-view": {
          "& .rbc-time-content": {
            "& .rbc-day-slot": {
              "& .rbc-events-container": {
                "& .rbc-event": {
                  left: '0% !important'
                }
              }
            }
          }
        }
      }
    }
  },
  eventBox: {
    borderRadius: "10px",
    // height: "100%",
    padding: "0px 10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid #979797",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eventTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#000000",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "2.2em",
    whiteSpace: "nowrap",
  },
  eventDueDate: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "13px",
    color: "#757575",
    overflow: "hidden",
    width: "150px",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    padding: 0,
    minWidth: 0,
  },
  highlightSelectedDate: {
    width: "93px",
    height: "111px",
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginBottom: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid #979797",
    borderRadius: "10px",
    paddingTop: "20px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "6px",
      width: "80px",
      height: "80px",
      justifyContent: "center",
    },
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center",
    color: "#FFF",
    marginBottom: "10px",
  },
  highlightedWeekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#FFF",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
  eventTitleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  goBackButton: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "50px",
    marginRight: "35px"
  }
}));

export const MyContextMenu = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    handleCloseContextMenu,
    handleOpenDeleteEvent,
    openDeleteEvent,
    handleCloseDeleteEvent,
    handleDeleteEvent,
    eventProps,
  } = props;
  return (
    <StyledMenu
      id="customized-menu"
      data-testid="customized-menu"
      anchorEl={anchorEl}
      open={openContextMenu}
      onClose={handleCloseContextMenu}
    >
      <StyledMenuItem>
        <ListItemText primary="View Details" color="#48484A" />
      </StyledMenuItem>
      <StyledMenuItem>
        <ListItemText
          data-testid="open-delete-menu-item"
          onClick={handleOpenDeleteEvent}
          primary="Delete"
          color="#48484A"
        />
        <Modal
          data-testid="delete-modal"
          open={openDeleteEvent}
          onClose={handleCloseDeleteEvent}
        >
          <DeleteEvent
            handleDeleteEvent={() => {
              handleDeleteEvent(
                eventProps?.event?.id,
                eventProps?.event?.type,
                () => {
                  handleCloseDeleteEvent();
                }
              );
            }}
            handleCancelDeleteEvent={handleCloseDeleteEvent}
            title={eventProps?.title}
          />
        </Modal>
      </StyledMenuItem>
    </StyledMenu>
  );
};
export const MyEvent = (eventProps: any) => {
  const { handleDeleteEvent } = eventProps;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteEvent, setOpenDeleteEvent] = React.useState(false);
  const [openContextMenu, setOpenContextMenu] = React.useState(false);
  const [anchorElFromIcon, setAnchorElFromIcon] = React.useState(null);
  const [openContextMenuFromIcon, setOpenContextMenuFromIcon] = React.useState(
    false
  );

  const truncatedTitle =eventProps.title; 

  const handleOpenContextMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };

  const handleCloseContextMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenContextMenu(false);
  };
  const handleOpenContextMenuFromIcon = (event: any) => {
    event?.preventDefault();
    setAnchorElFromIcon(event?.currentTarget);
    setOpenContextMenuFromIcon(true);
  };

  const handleCloseContextMenuFromIcon = (event: any) => {
    event?.preventDefault();
    setAnchorElFromIcon(null);
    setOpenContextMenuFromIcon(false);
  };

  const handleOpenDeleteEvent = () => {
    setOpenDeleteEvent(true);
  };

  const handleCloseDeleteEvent = () => {
    setOpenDeleteEvent(false);
  };

  return (
    <>
      {console.log(eventProps, 'eventProps')}

      <Box
        style={webStyle.eventBox}
        data-testid="open-customized-menu-box"
        className="unique-calendar"
      >
        <Box style={{
          backgroundColor: eventProps.handleColorEvents(eventProps?.event),
          height: '100%',
          width: '1.2%',
        }} />
        <EventMainCard>
          {!eventProps?.event?.overlap && <Box style={webStyle.eventTitleBox}>
            <EventTitle>
              {truncatedTitle}
            </EventTitle>
            
          </Box>}
          <EventCard>
            <Box>
              <Typography style={webStyle.eventDueDate}>
                Start Date & Time
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.startDate).format("DD/MM/YYYY")} {eventProps?.event?.startTime}</Typography>
              </Box>
            </Box>

            <Box>
              <Typography style={webStyle.eventDueDate}>
                Due Date & Time
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.endDate).format("DD/MM/YYYY")} {eventProps?.event?.endTime}</Typography>
              </Box>
            </Box>
          </EventCard>
        </EventMainCard>
      </Box>

    </>
  );
};
export const MyWeekHeader = (props: any) => {
  const { label, selectedDate } = props;
  const classes = useStyles();
  const [date, day] = label?.split(" ");
  const weekDate = date?.toString();
  const selectedWeekDate = selectedDate
    ?.getDate()
    ?.toString()
    ?.padStart(2, "0");
  return (
    <Box
      className={
        weekDate === selectedWeekDate
          ? classes.highlightSelectedDate
          : classes.weekHeader
      }
      data-testid='handleDateChange'
      onClick={() => props.handleDateChange(props?.date)}
    >
      <span
        className={
          weekDate === selectedWeekDate
            ? classes.highlightedWeekHeaderDayLabel
            : classes.weekHeaderDayLabel
        }
      >
        {day}
      </span>
      <span
        className={
          weekDate === selectedWeekDate
            ? classes.highlightedWeekHeaderDateLabel
            : classes.weekHeaderDateLabel
        }
      >
        {date}
      </span>
    </Box>
  );
};

const EventCard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down('xs')]: {
    alignItems: "center",
    gap: "10px",
    flexDirection: "column"
  },
}));

const EventMainCard = styled(Box)(({ theme }) => ({
  // height: "100%",
  width: "100%",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: '2px',
  position: "relative",
  [theme.breakpoints.down('xs')]: {
  },
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800 as 800,
  fontFamily: "Poppins",
  fontSize: "14px",
  overflow: "hidden" as "hidden",
  marginBottom: "4px",
  color: "#2B3D7D",
  textOverflow: "ellipsis" as "ellipsis",
  whiteSpace: "nowrap" as "nowrap",
  height: "2em",
  paddingTop: '5px',
  [theme.breakpoints.down('xs')]: {
    width: "100%",
    textAlign:"center"
  },
}));

const OptionIconBtn = styled(Button)(({ theme }) => ({
  minWidth: 0,
    transform: 'rotate(90deg)',
    padding: '4px',
    float:'right' as 'right',
    top:'4px',
    right:'22px',
  [theme.breakpoints.down('xs')]: {
    right: '2px !important',
  },
}));

const webStyle = {
  createEventBtn: {
    backgroundImage: 'linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)',
    color: "white",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "60px",
    marginRight: "32.5px",
    width: "10rem",
    fontSize: '15px',
    textTransform: 'capitalize' as 'capitalize'
  },
  errorTypo: {
    color: "red", paddingTop: "4px", fontSize: "14px", fontFamily: 'Poppins'
  },
  foldersStyle: {
    cursor: "pointer", position: "relative" as "relative", right: "-123px", left: "300px", top: "-45px"
  },
  heightWidth14px: {
    height: "14px", width: "14px"
  },
  countTypo: {
    color: "#2B3D7D", fontSize: "14px", fontFamily: "poppins"
  },
  inputDateStyle: {
    border: "none", fontFamily: 'Poppins', width: '300px',
  },
  inputDateMobileStyle: {
    border: "none", fontFamily: 'Poppins', width: '100%',
  },
  titleTypography: {
    fontFamily: "poppins", lineHeight: "32px", fontSize: "20px", letterSpacing: "0.2px", marginBottom: "10px"
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center" as "center",
    paddingTop: "35px",
  },
  listingClass: { height: "465px", overflow: "auto", display: "flex", flexWrap: "wrap" as "wrap", gap: "20px", paddingLeft: "35px", },
  borderDueTimeRadius: {
    borderBottomLeftRadius: "0px !important", borderTopLeftRadius: '0px !important', marginTop: '23px', fontFamily: 'Poppins'
  },
  borderStartTimeRadius: {
    borderBottomRightRadius: "0px !important", borderTopRightRadius: '0px !important', marginTop: '16px', fontFamily: 'Poppins'
  },
  buttonContainer: {
    display: "flex", position: "relative" as "relative", top: '2rem', justifyContent: "flex-end" as "flex-end", gap: "10px", alignItems: "center", padding: "30px 52px 0px 52px",
  },
  btnLinkBreadCrumb: {
    cursor: "pointer", textDecoration: "none",
    color:'rgba(0, 0, 0, 0.87)', fontWeight:"bold" as 'bold',
  },
  saveTimeTypo: {
    color: "#2C2C2E",
    fontFamily: 'Poppins'
  },
  title: {
    color: "#757575",
    fontWeight: 500 as 500,
    fontSize: "24px" as "24px",
    fontFamily: "Poppins",
  },
  teacherName: {
    color: "#000000",
    fontWeight: 500 as 500,
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    marginTop: "20px",
    padding: "0px 35px",
  },
  currentDateBox: {
    padding: "0 35px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  addButtonLayout: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  currenDate: {
    color: "rgb(43, 61, 125)",
    paddingRight: "15px",
    fontWeight: 600 as 600,
    fontSize: "22px",
    fontFamily: "Poppins",
  },
  calendarView: {
    rowGap: "10rem",
    display: "grid",
  },
  weekHeader: {
    paddingTop: "20px",
    flexDirection: "column" as "column",
    display: "flex",
    marginBottom: "8px",
    marginTop: "16px",
    width: "93px",
    height: "111px",
  },
  weekHeaderDayLabel: {
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500 as 500,
    color: "#858585",
    marginBottom: "10px",
    textAlign: "center" as "center",
  },
  weekHeaderDateLabel: {
    color: "#000000",
    lineHeight: "36px",
    fontSize: "24px",
    fontWeight: 400 as 400,
    fontFamily: "Poppins",
    textAlign: "center" as "center",
  },
  tabBefore:
  {
    color: "#2B3D7D",
    fontWeight: 500,
    fontSize: "20px",
    width: "218px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "4px",
    borderRight: "1px solid gray"
  },
  tabAfter: {
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
    backgroundColor: "#2B3D7D",
    fontSize: "20px",
    borderRadius: "4px",
    width: "218px",
    opacity: 1
  },
  calendarBox: {
    padding: "50px 35px",
    height: '70%'
  },
  eventBox: {
    whiteSpace: "nowrap" as "nowrap",
    border: "1px solid rgba(57, 198, 181, 0.26)",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    textOverflow: "ellipsis" as "ellipsis",
    overflow: "hidden" as "hidden",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },
  eventTitle: {
    fontWeight: 800 as 800,
    fontFamily: "Poppins",
    fontSize: "14px",
    overflow: "hidden" as "hidden",
    marginBottom: "14px",
    color: "#2B3D7D",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    height: "2em",
    paddingTop: '5px',
  },

  deleteEventTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "25px"
  },
  eventDueDate: {
    width: "150px",
    fontWeight: 500 as 500,
    color: "#757575",
    overflow: "hidden" as "hidden",
    whiteSpace: "normal" as "normal",
    fontSize: "13px",
    fontFamily: "Poppins",
    wordBreak: "break-word" as "break-word",
  },
  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    minWidth: 0,
    transform: 'rotate(90deg)',
    padding: '4px',
    float:'right' as 'right',
    top:'4px',
    right:'22px',
  },
  highlightSelectedDate: {
    display: "flex" as "flex",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    flexDirection: "column" as "column",
    width: "93px",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "111px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 500 as 500,
    textAlign: "center" as "center",
    marginBottom: "10px",
    lineHeight: "27px",
    fontSize: "24px",
  },
  highlightedWeekHeaderDateLabel: {
    color: "#FFF",
    lineHeight: "36px",
    fontWeight: 400,
    textAlign: "center" as "center",
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  link: {
    textDecoration: "none",
  },
  eventTitleBox: {
    justifyContent: "space-between",
    display: "flex",
  },
  goBackButton: {
    justifyContent: "end",
    display: "flex",
    marginRight: "35px",
    marginBottom: "50px",
  },


  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px",
    minHeight: "275px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    marginRight: "24px",
    flexDirection: "column",
  },
  actionName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginTop: "12px",
  },
  actionDescription: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
    textAlign: "center",
  },
  listItemImage: {
    width: "120px",
    height: "120px",
  },
  countBox: {
    display: "flex",
    justifyContent: "start",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    minHeight: "121px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    maxWidth: "210px",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "#FFF3EB",
  },
  displayCount: {
    fontFamily: "Poppins",
    fontSize: "40px",
    color: "#2B3D7D",
    fontWeight: 600,
  },
  countHeading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  imageOption: {
    marginBottom: "10px",
  },
  popupContentOption: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
  },
  container: {
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px 30px",
    minHeight: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    flexDirection: "column" as "column",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212"
    }
  },
  paper: {
    position: "absolute" as 'absolute',
    width: '600px',
    backgroundColor: 'white',
    borderRadius: "10px" as "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: '2px 4px 3px',
  },
  popupContent: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#000000",
    fontWeight: 700 as 700,
    marginBottom: "15px"
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    marginBottom: "5px"
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around"
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px" as '10px',
    width: "270px",
    padding: "12px",
    textTransform: "none" as 'none',
    fontFamily: "Poppins",
    fontSize: "18px"
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px" as '10px',
    width: "270px",
    padding: "12px",
    textTransform: "none" as 'none',
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF"
    }
  },
  titleOption: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "40px",
  },
  optionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  },
  paperOption: {
    position: "absolute" as "absolute",
    width: "740px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
  },
  selectedCard: {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },

  crossIconOption: {
    position: "absolute" as "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  picker: {
    fontFamily: 'Poppins',
    position: "relative" as "relative",
    "& .MuiInputAdornment-root": {
      marginRight: 0,
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      left: 0,
      padding: "10px",
    },
    "& .MuiInputBase-input": {
      paddingLeft: "40px", // Adjust padding to make space for the icon
    },
  },
}