import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { COURSES_PAGES,getStageType } from "../../../../../components/src/types";

const useStyles = makeStyles(() => ({
  stageBox: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "12px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    padding: "10px",
    cursor: "pointer",
  },
  headingLayout: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  heading: {
    fontSize: "12px",
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  mainLayout: {
    width: "168px",
    marginBottom: "20px",
  },
  subMainLayout:{
    maxWidth:"80%",
    position:"relative",
    left:"20%"
  },
  title: {
    textAlign: "center",
    fontSize: "16px",
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 600,
    overflow:"hidden",
    textOverflow:"ellipsis",
    maxWidth:"134px",
    wordWrap:"break-word"
  },
  moreIcon:{
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "12px",
    cursor:"pointer",
    color: "#2B3D7D",
  }
}));

const stripHtmlTags = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const checkForText = (html: string): boolean => {
  const strippedText = stripHtmlTags(html).trim();
  return strippedText == '';
};



const Stage = (props: any) => { 
  const { stage, selectStage , index ,handleMenu,allStages,
    handleDragStart, handleDragOver, handleDrop , draggable , selectedView
  } = props;
  const classes = useStyles();
  const subStages = allStages ? allStages.filter((curStage:any)=>
    curStage.stageNumber.toString().match(/^\d+/)[0] == stage.stageNumber
    && curStage.stageNumber != stage.stageNumber
  ) : []
  
  const getUpdatetTitleifEmpty = (title:string,stage:{attributes:{lesson_stage_name:string,assignment_stage_name:string,quiz_stage_name:string}}) => {
    let stageTitle = title
    const isTextEmpty = stageTitle?checkForText(stageTitle):true
    if(isTextEmpty){
      switch(selectedView){
        case COURSES_PAGES.LESSON:
          stageTitle = getStageType(stage.attributes.lesson_stage_name)
          break;
        case COURSES_PAGES.ASSIGNMENT:
          stageTitle = getStageType(stage.attributes.assignment_stage_name)
          break;
        case COURSES_PAGES.QUIZ:
          stageTitle = getStageType(stage.attributes.quiz_stage_name)
          break;
          
      }
    }
    return stageTitle
  }
  const getStageDesign = (stage:any,curIndex:number,isSubStage = false) =>{
    let result = '';
    if(isSubStage){
      result = calculateSubStageResult(curIndex);
    }    
    result = `${index+1}${result}`
    let shouldDrag:boolean = (draggable ?? false)
    return (
      <Box className={`${classes.mainLayout} ${isSubStage && classes.subMainLayout}`} 
          draggable={shouldDrag}
          onDragStart={(event)=>shouldDrag ? handleDragStart(event,stage,index) : undefined}
          onDragOver={(event)=>shouldDrag ? handleDragOver(event,stage,index) : undefined}
          onDrop={shouldDrag?handleDrop:undefined}
          data-test-id={`reorderStage-${stage.id}`}
          >
            <Box className={classes.headingLayout}>
          <Typography className={classes.heading}>
            Stage {result}
          </Typography>
          {handleMenu && <MoreVertIcon className={classes.moreIcon} onClick={(e)=>handleMenu(e,stage,isSubStage)} data-test-id={`ellipsesIcon-${stage.id}`}/>}
        </Box>
        <Box
          className={classes.stageBox}
          style={{backgroundColor:stage.newStage?"#F0F0F0":"unset"}}
          onClick={(e) => selectStage(stage.id)}
          data-testId={`loadStage-${stage.id}`}
        >
          <Box className={classes.title} dangerouslySetInnerHTML={{ __html: getUpdatetTitleifEmpty(stage.title,stage) }}/>  
        </Box>
      </Box>
    )
  }
  const calculateSubStageResult = (curIndex: number) => {
    let result = '';
    let num = curIndex + 1;

    while (num > 0) {
        let remainder = num % 26;
        if (remainder === 0) {
            remainder = 26;
        }
        result = String.fromCharCode(64 + remainder) + result;
        num = Math.floor((num - remainder) / 26);
    }

    return result;
  };
  return (    
    <>
      {getStageDesign(stage,index)}
      {subStages.map((substage:any,subIndex:number)=>getStageDesign(substage,subIndex,true))}
    </>
  );
};

export default Stage;
