import React, { useCallback, useEffect, useState } from "react";
import { Box, Modal, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon, multimediaIcon, orLineBreak, audioImg } from "../../assets";
import { useDropzone } from 'react-dropzone';
import { extractVideoId } from "../../../../../components/src/Utils.web";
import { VideoThumbnailGenerator } from "browser-video-thumbnail-generator";

const useStyles = makeStyles(() => ({
  modalStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "750px",
    maxHeight: "90vh",
    background: "#fff",
    border: "none",
    boxShadow: "24",
    borderRadius: "15px",
    padding: "24px",
    overflowX: "hidden",
  },
  modalHeadText: {
    fontFamily: 'Poppins',
    fontSize: "28px",
    fontWeight: 700,
    color: "#2B3D7D",
    textAlign: "center",
  },
  inputHead: {
    fontFamily: 'Poppins',
    fontSize: "28px",
    margin: "15px 0",
    fontWeight: 700,
    color: "#2B3D7D",
    textAlign: "center",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  dragIcon: {
    width: "60px",
  },
  dragUploadBox: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    border: "2px solid #e5e5e5",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "400px",
    borderRadius: "15px",
    cursor: "pointer"
  },
  dragDropheading: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000",
    fontWeight: 600,
    lineHeight: '30px',
    textAlign: "center",
  },
  dragDropSubheading: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F526D",
    fontWeight: 400,
    lineHeight: '30px',
    textAlign: "center",
  },
  inputStyle: {
    display: "flex",
    gap: "10px",
    justifyContent: "end",
    alignItems: "center",
    position:"relative",
    marginTop:"8px",
  },
  inputlabel: {
    fontFamily: "Poppins",
  },
  customInput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "10px 15px",
    fontSize: "16px",
    height:"44px",
    '.MuiInputBase-input': {
      background: 'red'
    },
  },
  embedbtn: {
    background: "#2b3d7d",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    cursor: "pointer",
  },
  previewLabel: {
    fontFamily: 'Poppins',
    fontSize: "18px",
    fontWeight: 400,
    color: "#2B3D7D",
  },
  previewWrapper: {
    display: "flex",
    alignItems: "start",
    overflowX: "auto",
    flexWrap: "nowrap",
    gap: "10px",
    marginTop: "10px"
  },
  previewBox: {
    backgroundColor: "#fff",
    width: "32%",
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  previewTitile: {
    padding: "0 12px",
    fontFamily:"Poppins"
  },
  previewImg: {
    width: "100%",
    height: "220px",
  },
  browseLink: {
    textDecoration: "underline"
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginTop: "10px",
    display: "block",
    fontFamily: "Poppins",
    "&.custom-error-msg":{
      position:"absolute",
      left: "0",
      top: "80%",
    }
  },
}));

const MultimediaModal = (props: any) => {
  const { open, onClose, addMultiMediaStage,selectedView } = props;
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [youtubeURL, setYoutubeURL] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');
  const [thumbnailUrl, setThumbnailUrl] = useState<string[]>([]);

  const getThumbnail = (newFiles: File[]) => {
    newFiles.forEach(async (file)=>{
      if (file && file.type.startsWith('video/')) {
        try {
          const videoSrc = URL.createObjectURL(file);
  
          const generator = new VideoThumbnailGenerator(videoSrc);
  
          const { thumbnail: generatedThumbnail } = await generator.getThumbnail();
  
          setThumbnailUrl((prevThumbs)=>[...prevThumbs,generatedThumbnail]);
  
        } catch (error) {
          console.error('Error generating thumbnail:', error);
          setThumbnailUrl((prevThumbs)=>[...prevThumbs,""]);
        }
      }
    })   

  }

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: { file: File; errors: { code: string; message: string }[] }[]) => {
      if (fileRejections.length > 0) {
        setInputError("Please upload files in one of the following formats: PNG, GIF, JPEG, MP4, or MP3");
      } else {
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        getThumbnail(acceptedFiles)
        setInputError("");
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "accept": [".png",".gif",".jpeg",".mp4", ".mp3", ".jpg"]
    },
  });

  const handleYoutubeEmbed = () => {
    const videoId = extractVideoId(youtubeURL)
    if (videoId) {
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`;

      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: `YouTube Video Thumbnail ${prevFiles.length + 1}`,
          thumbnailUrl,
          type: 'youtube',
        },
      ] as File[]);
      setThumbnailUrl((prevThumbs)=>[...prevThumbs,""]);
    }
    setYoutubeURL("")
    setInputError("")
  }

  const handleSubmit = () => {
    if (files.length === 0) {
      setInputError("Please add a file or enter a YouTube URL")
      return false
    }
    
    addMultiMediaStage(files,selectedView,'save')
    onClose()
  }

  const clearFiles = () => {
    setFiles([]);
    setThumbnailUrl([])
    setInputError("")
  };

  useEffect(() => {
    if (!open) {
      clearFiles();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalStyles}>
        <img className={classes.closeIcon} src={closeIcon} onClick={onClose} />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.modalHeadText}>
              Upload files
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box {...getRootProps()} className={classes.dragUploadBox}>
              <input {...getInputProps()} />
              <img src={multimediaIcon} className={classes.dragIcon} />
              <Typography className={classes.dragDropheading}>
                Drag & Drop file or <span className={classes.browseLink}>Browse</span>
              </Typography>
              <Typography className={classes.dragDropSubheading}>
                Supported formats: JPEG, PNG, GIF, MP4, MP3, JPG
              </Typography>
            </Box>
          </Grid>
          <img src={orLineBreak} alt='continue with' />
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.inputHead}>
              Embed Youtube Videos
            </Typography>
            <label className={classes.inputlabel}>Video URL</label>
            <Box className={classes.inputStyle}>
              <Box display={'flex'} flexDirection={"column"} flex={'1'}>
                <input
                  data-test-id="inputYoutubeUrl"
                  placeholder={"https://youtu.be/Abk7L9zmbG4"}
                  className={classes.customInput}
                  value={youtubeURL}
                  onChange={(e) => {
                     setYoutubeURL(e.target.value)
                    }}
                />
                <label data-test-id="inputError" className={`${classes.error} custom-error-msg`}>{inputError}</label>
              </Box>
              <button
                data-test-id="embedBtn"
                className={classes.embedbtn}
                onClick={handleYoutubeEmbed}
              >
                Embed & Proceed
              </button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {files.length > 0 && <Typography variant="h5" className={classes.previewLabel}>
              Preview
            </Typography>}
            <Box className={classes.previewWrapper}>
              {files.map((file: any, index) => {
                let content = null;
                if (file.type.startsWith('audio')) {
                    content = (
                      <img src ={audioImg} className={classes.previewImg}/>
                    )
                  }
                else if (file.type.startsWith('image')) {
                  content = (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Thumbnail ${index}`}
                      className={classes.previewImg}
                    />
                  );
                } else if (file.type === 'youtube') {
                  content = (
                    <img
                      src={file.thumbnailUrl}
                      alt={`YouTube Thumbnail ${index}`}
                      className={classes.previewImg}
                    />
                  );
                } else if (file.type.startsWith('video/')) {
                  // Display video thumbnail
                  content = (
                    <img
                      src={thumbnailUrl[index]}
                      alt={`Video Thumbnail ${index}`}
                      className={classes.previewImg}
                      key={index}
                    />
                  );
                }

                return (
                  <div key={index} className={classes.previewBox}>
                    {content}
                    <span className={classes.previewTitile}>{file.name}</span>
                  </div>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <button
              data-test-id="submitBtn"
              className={classes.embedbtn}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MultimediaModal;
