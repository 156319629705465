import React from "react";
import { Box, Typography, Button,Modal,Switch  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trashIcon } from "./assets";
import { closeModalIcon,downloadIcon } from "./assets";
const useStyles = makeStyles((theme) => ({
  
  paper: {
    position: "absolute",
    width: "500px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"48px 24px",
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    }
  },
  modal: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "75%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    outline: "none",
    padding: "37px 48px 63px",
    position: "absolute",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 12%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    minWidth:"448px",
    maxWidth:"448px",
  },
}));
const SubStageResultsModal = (props: any) => {
  const { open,children } = props;
  const classes = useStyles();
  
  return (
    <Modal
        open={open}
        className={classes.modal}
        disablePortal={true}
        BackdropProps={{style: {position: 'absolute'}}}
    >
      <Box className={classes.modalContent}>
        {children}
      </Box>
    </Modal>
  )
};

export default SubStageResultsModal;
