import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import Sidebar from "../../../../../components/src/Sidebar.web";
import TeacherListController, { Props } from "./TeacherListController";
import SearchSection from "../components/SearchSection.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
import Loader from "../../../../../components/src/Loader";
import AppHeaderWeb from "../../../../../components/src/AppHeader.web";
import ItemList from "../../../../../components/src/ItemList.web";
const round = {
  borderRadius: "50%",
};
export default class Teachers extends TeacherListController {
  constructor(props: Props) {
    super(props);
    this.getTeacherCalendar = this.getTeacherCalendar.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  renderGridItem() {
    if (this.state.isSmallScreen) {
      return null;
    }
  
    return (
      <Grid xs={2} item>
        <Sidebar />
      </Grid>
    );
  }
  render() {
    return (
      <>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          {this.renderGridItem()}

          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ height: "100%", overflowY: "scroll" }}>
            <Grid item md={12}>
              <AppHeaderWeb title={"Calendar"} />
            </Grid>
            {this.state.loading ? (
              <Loader loading={this.state.loading} />
            ) : (
              <>
                <Grid item md={12}>
                  <Box sx={{ px: "35px", mb: "20px" }}>
                    <SearchSection
                      searchItem={"Teachers"}
                      searchInput={this.state.searchInput}
                      handleSearchInput={this.handleSearchInput}
                    />
                  </Box>
                </Grid>
                {this.state.teachersList.length === 0 ? (
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    No records found
                  </Typography>
                ) : (
                  <>
                    <Box sx={{ px: "20px" }}>
                      <ItemList
                        dataList={this.state.teachersList}
                        handleClick={this.getTeacherCalendar}
                        imageShape={round}
                      />
                    </Box>
                    <Grid item md={12}>
                      <Box sx={{ px: "35px", py: "50px" }}>
                        <BasicPagination
                          handlePageChange={this.handlePageChange}
                          pageLimit={this.state.pageLimit}
                          pageNo={this.state.pageNo}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
