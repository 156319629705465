import React, { useEffect, useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Box,
    Modal
} from '@material-ui/core';
import { greenCheck, redCross } from "../assets";
import { calenderIconActive } from "../../../../components/src/assets";
import { makeStyles } from "@material-ui/core/styles";
import ExportModal from "./ExportModal.web";

type TextTransformProperty = "capitalize"
type TextAlignProperty = 'center'

const useStyles = makeStyles((theme) => ({

    datatable: {
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
        margin: '0px 15px',
        overflow: "scroll", scrollbarColor: "#2B3D7D white", width: "100%"
    },
    tHead: {
        backgroundColor: '#fdf4dc',
    },
    tHeading: {
        fontFamily: "Poppins",
        color: '#202020',
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'center' as TextAlignProperty
    },
    exportBtn: {
        cursor: 'pointer', margin: '10px 0px', padding: '10px 30px',
        border: "none",
        borderRadius: '8px', 
        color: '#fff', 
        fontSize: '18px', 
        fontWeight: 'bold', 
        fontFamily: 'Poppins',
        [theme.breakpoints.down('xs')]: {
           width:"90%"
          },
    },
    tDetail: {
        fontFamily: "Poppins",
        color: '#2c2c2e',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'center' as TextAlignProperty,
        textTransform: "capitalize" as TextTransformProperty
    },
    imageCheck: {
        display: 'flex', width: '100%', justifyContent: 'center'
    },
    dateContainer: {
        display: "flex",
        background: "#F4F7FF",
        padding: "5px",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center"
    },
    createdOn: {
        color: "#757575",
        fontFamily: "Poppins",
        fontSize: '16px',
        margin: "0px 10px",
    },
    date: {
        color: "#2B3D7D",
        fontFamily: "Poppins",
        fontSize: '16px',
        fontWeight: "bold",
        background: "#CED8FC",
        padding: "5px",
        margin: "5px",
        borderRadius: "8px",
    },
    dateWrapper:{
        margin: '-10px 10px 20px 20px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
     "@media (max-width: 600px)": {
      flexDirection:"column"
    },

    }
    
}));

const SummeryReportListing = (props: any) => {
    const classes = useStyles();
    const { summaryReportList, handleCloseModal, openModal, handleOpenModal, csvSucessShow, getExportReportApicall } = props

    const columns: { label: string }[] = [
        { label: "Student Name" },
        { label: "Multiple Choice Questions" },
        { label: "Open Ended Questions" },
        { label: "Polling Questions" },
        { label: "Word Cloud" },
        { label: "Graphic Organizers" },
        { label: "Matching Questions" },
    ];
    const formatDate = (date: any) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };
    const todayDate = formatDate(new Date());

    return (
        <div>
            <Box className={classes.dateWrapper}>
                <Box className={classes.dateContainer}>
                    <img src={calenderIconActive} />
                    <p className={classes.createdOn}>Created on</p>
                    <p className={classes.date}>{todayDate}</p>
                </Box>
                <button className={classes.exportBtn} data-testid="ExportBtn"
                    onClick={handleOpenModal} type="submit" style={{ background: 'linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A' }} >
                    Export
                </button>
            </Box>
            {summaryReportList && <TableContainer className={classes.datatable} component={Paper} style={summaryReportList?.length === 0 ? { maxHeight: "auto" } : { maxHeight: "55vh" }}>
                <Table >
                    <TableHead className={classes.tHead}>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index} className={classes.tHeading}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summaryReportList?.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length + 2} style={{ textAlign: 'center' }}>
                                    No records found
                                </TableCell>
                            </TableRow>
                        ) : (
                            summaryReportList?.map((row: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell className={classes?.tDetail}>{row.student_name}</TableCell>
                                    <TableCell className={classes?.tDetail}>{row.mcq_marks}/{row.total_mcq_marks}</TableCell>
                                    <TableCell >
                                        <Box className={classes?.imageCheck}>
                                            {row.open_ended_question ? < img src={greenCheck} /> : < img src={redCross} />}</Box>
                                    </TableCell>
                                    <TableCell >
                                        <Box className={classes?.imageCheck}>
                                            {row.polling_question_marks ? < img src={greenCheck} /> : < img src={redCross} />}</Box>
                                    </TableCell>
                                    <TableCell className={classes?.tDetail}>{row.sum_word_cloud}</TableCell>
                                    <TableCell className={classes?.tDetail}>{row.graphic_organisation_marks}</TableCell>
                                    <TableCell className={classes?.tDetail}>{row.sum_matching_question_marks}/{row.total_matching_question_marks}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>}
            <Modal open={openModal} onClose={handleCloseModal}>
                <ExportModal
                    data-test-id="option-modal"
                    handleCloseModal={handleCloseModal}
                    csvSucessShow={csvSucessShow}
                    getExportReportApicall={getExportReportApicall}
                />
            </Modal>
        </div>
    );
};

export default SummeryReportListing;
