import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { notificationMockImg } from "../../../components/src/assets";
import Sidebar from "../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import { deleteIcon } from "./assets";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
          <Grid container style={{ overflowX: "hidden", height: "100%" }}>
            <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
             {!this.state.isSmallScreen && <Sidebar />}
            </Grid>
            <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
              <Grid item md={12}>
                <AppHeaderWeb title={configJSON.headerTitle} />
              </Grid>
              <Grid item md={12}>
                <Box style={webStyle.mainBox}>
                  <Box style={webStyle.notificationTopBox}>
                    <Typography style={webStyle.allNotification} >{configJSON.allNotificationTxt}</Typography>
                    <Typography data-test-id='btnClearAll' style={webStyle.clearAll} onClick={() => this.deleteAllNotifications()} >{configJSON.clearAllTxt}</Typography>
                  </Box>
                  {
                    this.state.data && this.state.data.length > 0 && this.state.data.map((element, index)=> (
                      <Box 
                        style={{
                          ...webStyle.notificationDetailsMain,
                          ...(!element.attributes.is_read && webStyle.notificationDetailsMainUnread)
                        }}
                        key={index}>
                        <img style={webStyle.notificationImg} src={notificationMockImg} alt="" />
                        <Box style={webStyle.notificationDetailsBox}>
                          <Box style={webStyle.notificationDetailTitle}>
                            <Typography style={webStyle.notificationTitle}>{element.attributes.headings}</Typography>                            
                            <Typography style={webStyle.notificationTime} >{this.timeSince(element.attributes.created_at)}</Typography>
                          </Box>           
                          <Typography style={webStyle.notificationDesc}>{element.attributes.contents}</Typography>               
                          <Box style={webStyle.notificationBottomLink}>
                            <Typography data-test-id='btnReadUnreadNotif' onClick={()=> this.markAsRead(element.id)} style={webStyle.notificationMarkAsRead}>{element.attributes.is_read ? configJSON.markAsUnread : configJSON.markAsRead}</Typography>
                            <img data-test-id='btnDeleteNotif' onClick={()=> this.deleteNotifications(element.id)} style={webStyle.deleteIcon} src={deleteIcon} alt="" />
                          </Box>                          
                        </Box>                                                
                      </Box>
                    ))
                  }
                  {
                    (!this.state.data || this.state.data.length <= 0) && <Box style={webStyle.noNotificationBox}>
                      {configJSON.noNotificationTxt}
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainBox: {
    width: '760px',
    margin: '32px auto 32px auto',
  },
  notificationTopBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  allNotification: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    fontFamily: 'Poppins',
    color: '#282829',
  },
  clearAll: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#2B3D7D',
    cursor: 'pointer',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
  },
  closeNotificationIcon: {
    cursor: 'pointer',
  },
  notificationDetailsMain: {
    display: 'flex',
    position: 'relative' as 'relative',
    marginBottom: '8px',
    padding: '16px 28px',
    borderRadius: '8px',
    'box-shadow': '0px 1px 0px 0px #00000014',
    boxSizing: 'border-box' as 'border-box',
  },
  notificationDetailsMainUnread: {
    backgroundColor: '#2B3D7D0F',
  },
  notificationImg: {
    height: '80px',
    width: '80px',
    boxShadow: '0px 1px 3px 0px #00000014',
    borderRadius: '4px',
    marginRight: '14px',
  },
  notificationTitle: {
    color: '#282829',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '16px',
    flex: 1,
    marginRight: "10px",
  },
  notificationDesc: {
    color: '#282829',
    marginTop: '8px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    'overflow': 'hidden',
    'word-wrap': 'break-word',
    'white-space': 'normal',
  },
  notificationMarkAsRead: {
    fontSize: '12px',
    color: '#2B3D7D',
    width: 'fit-content',
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    marginTop: '8px',
    cursor: 'pointer',
  },
  notificationTime: {
    color: '#282829',
    position: 'absolute' as 'absolute',
    right: 24,
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  deleteIcon: {
    marginLeft: "10px",
    cursor: 'pointer',
  },
  noNotificationBox: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: 'Poppins',
    color: '#282829',
    textAlign: 'center' as 'center',
    marginTop: '150px',
  },
  notificationDetailTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationBottomLink: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationDetailsBox: {
    flex: 1,
  }
};
// Customizable Area End
