import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import AssignmentsList from "../components/AssignmentsList.web";
import SearchInput from "../../../../components/src/SearchInput.web";
import QuizzeList from "../components/QuizzeList.web";
import { setStorageData } from "../../../../framework/src/Utilities";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const useStyles = makeStyles(() => ({
    tabs: {
        "&.MuiTabs-root": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
            borderRadius: "8px",
            border: "0.2px solid #2B3D7D",
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "&.MuiTabs-flexContainer": {
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
        },
    },
    tab: {
        "&.MuiTab-root": {
            color: "#2B3D7D",
            fontWeight: 500,
            fontSize: "20px",
            width: "218px",
            textTransform: "capitalize",
            borderRadius: "8px",
        },
        "&.MuiTab-root.Mui-selected": {
            fontWeight: 600,
            color: "#FFFFFF",
            textTransform: "capitalize",
            backgroundImage:
                "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            fontSize: "20px",
            borderRadius: "8px",
            width: "218px",
        },
    },
    searchBarLayout: {
        display: "flex",
    },
    tabBar: {
        marginTop: "30px",
        display: "flex",
    },
    addButtonLayout: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "20px",
        marginBottom: "20px",
    },
}));
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const StudentTaskList = (props: any) => {
    const {
        getTaskLists,
        setName,
        Name,
        taskLists,
        handlePageChange,
        page,
        perPage,
        loading,
        getQuizelistApiCall,
        setQuizeName,
        quizList,
        handleQuizePageChange,
        isBackFromQuiz,
        setIsBackFromQuizFalse,
        quizName
    } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        if(newValue === 0){
            setStorageData('studentTaskListTab', 'assignment')
            setIsBackFromQuizFalse(false);
        }else{
            setStorageData('studentTaskListTab', 'quiz')
        }
    };

    useEffect(() => {
        if (value) {
            setQuizeName("")
        } else {
            setName("")
        }
        if(isBackFromQuiz){
            setValue(1)
        }
    }, [value, isBackFromQuiz])

    return (
        <Box>
            <Box className={classes.tabBar} sx={{ px: "35px" }}>
                <Tabs
                    data-test-id='TaskHandleTabChange'
                    value={value}
                    onChange={handleTabChange}
                    aria-label="simple tabs example"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "unset",
                        },
                    }}
                    className={classes.tabs}
                >
                    <Tab className={classes.tab} label="Assignments" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Quizzes" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <Box className={classes.addButtonLayout} sx={{ px: "35px" }}>
                <Box className={classes.searchBarLayout}>
                    <SearchInput
                        placeholder={"Search by name"}
                        handleSearchInput={value == 1 ? setQuizeName : setName}
                        searchInput={value == 1 ? quizName : Name}
                    />
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <AssignmentsList
                    getTaskLists={getTaskLists}
                    taskLists={taskLists}
                    handlePageChange={handlePageChange}
                    page={page}
                    perPage={perPage}
                    loading={loading}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <QuizzeList
                    getQuizelistApiCall={getQuizelistApiCall}
                    quizList={quizList}
                    handleQuizePageChange={handleQuizePageChange}
                    page={page}
                    perPage={perPage}
                    loading={loading}
                />
            </TabPanel>
        </Box>
    );
};

export default StudentTaskList;
