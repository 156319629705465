import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { arrowImage } from "../../../../assets";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  question: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#282829",
    fontWeight: 600,
    marginBottom: "20px",
  },
  questionData: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#282829",
    fontWeight: 600,
    marginBottom: "20px"
  },
  stageName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "15px",
  },
  matchingBoxA: {
    padding: "16px 12px",
    width: "170px",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
  },
  matchingBoxB: {
    padding: "16px 12px",
    width: "170px",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
  },
  correctmatchingBox: {
    padding: "16px 12px",
    width: "170px",
    borderRadius: "10px",
    backgroundColor: "#34c759",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#ffffff",
    fontWeight: 400
  },
  pairLayout: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    gap:"10px",
    alignItems: "start"
  },
  pairLayoutData: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    alignItems: "start"
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "21px",
    color: "#282829",
    fontWeight: 700,
    width: "170px",
  },
  headingLayout: {
    display: "flex",
    marginBottom: "15px",
    justifyContent:"space-between",
    textAlign:"center"
  },
  arrowIcon: {
    height: "12px",
    width: "33%",
    marginRight: "3px",
    marginTop: '20px',
  },
  contebtBoxMain:{
    padding:"80px 30px 30px",
    position:"relative",
    // matching
    "&.matching-box":{
      padding:"0",
    },
  }
}))
const MatchingQuestion = (props: any) => {
  const classes = useStyles();
  const { currentStage, isMatching } = props;
  const [height,setHeight] = useState(0)
  const pairs =  currentStage.attributes.pairs ?? currentStage.attributes.matching_pairs

  const hasCanvasData = currentStage.attributes.canvas_data;

  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      jsonCanvas = parsedData.canvas;
      canvasSize = parsedData.canvasSize;
  }
  
  return (
    <Box className={`${!isMatching?classes.contebtBoxMain:"matching-box"}`}>
      <Typography className={classes.stageName}>Matching Question</Typography>
      {  jsonCanvas && <Box style={{position:"absolute",width:"90%",height:"100%",}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
        </Box>}
      <Typography className={ isMatching ? classes.question : classes.questionData} 
      dangerouslySetInnerHTML={{
        __html: currentStage?.attributes.title,
      }}>
      </Typography>
      <Box style={{marginTop:`${height}px`}}>
        <Box className={classes.headingLayout}>
          <Box className={classes.heading}>A</Box>
          <Box className={classes.heading}>B</Box>
        </Box>
        {pairs.map((pair: any) => (
          <Box className={isMatching ? classes.pairLayoutData : classes.pairLayout}>
            <Box className={classes.matchingBoxA}>{pair.pair_question}</Box>
            {isMatching && <img className={classes.arrowIcon} src={arrowImage} />}
            {isMatching ? <Box className={classes.correctmatchingBox}>{pair.pair_correct_answer}</Box> :
              <Box className={classes.matchingBoxB}>{pair.pair_answer}</Box>
            }
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MatchingQuestion;
