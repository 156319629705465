import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { navLogo } from "./assets";
import JoinClassModal from "./JoinClassModal.web";
const useStyles = makeStyles((theme) => ({
  nav: {
    height: "120px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    [theme.breakpoints.down("sm")]: {
      height: "90px",
    },
  },
  navMenu: {
    alignItems: "center",
    display: "flex",
    flexFlow: "row nowrap",
    height: "100%",
    margin: 0,
    padding: 0,
    position: "relative",
    marginLeft: "auto",
  },
  logo: {
    padding: 0,
    width: "80px",
    marginTop: "4px",
    maxHeight: "70px",
    fontSize: 0,
    display: "inline-block",
  },
  mobileLogo: {
    marginLeft:"15px",
    display: "inline-block",
  },
  logoImage: {
    display: "block",
  },
  buttonBg: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    marginRight: "20px",
    color: "#FFF",
    borderRadius: "10px",
    width: "190px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  normalBtn: {
    borderRadius: "10px",
    width: "190px",
    padding: "12px",
    textTransform: "none",
    borderColor: "#3F526D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  links: {
    marginRight: "15px",
    textTransform: "none",
    color: "#323548",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  navLayout: {
    boxShadow: "0px 15px 10px -15px #F3F3F3",
  },
  linkFont: {
    fontFamily: "Poppins",
    color: "#323548",
    fontSize: "18px",
  },
  mobileNavLink: {
    fontFamily: "Poppins",
    color: "#323548",
    fontSize: "18px",
    textDecoration: "none",
  },
}));
type Anchor = "left";
const Navbar = ({ aboutUsLink, homeLink }: any) => {
  const navigationLinks = [
    { name: "Home", link: homeLink },
    { name: "About us", link: aboutUsLink },
    { name: "Login", link: "/login" },
    { name: "Register", link: "/login/signup"},
    { name: "Join a class", link: "/login"},
  ];

  const [openModal, setOpenModal] = React.useState(false);
  
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    const token = [...Array(40)].map(() => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('');

    window.localStorage.setItem("authToken", token)
    setOpenModal(true)
  }

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navigationLinks.map((navLink, index) => (
          <ListItem button key={index}>
            <a href={navLink.link} className={classes.mobileNavLink}>
              <ListItemText
                className={classes.linkFont}
                primary={navLink.name}
              />
            </a>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box className={classes.navLayout}>
      <Container>
        {isMobile ? (
          <Box className={classes.nav}>
            <React.Fragment key={"left"}>
              <Button onClick={toggleDrawer("left", true)}>
                <MenuIcon />
                <a className={classes.mobileLogo} href="/">
                  <img className={classes.logoImage} src={navLogo} />
                </a>
              </Button>
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
              >
                {list("left")}
              </Drawer>
            </React.Fragment>
          </Box>
        ) : (
          <Box className={classes.nav}>
            <a className={classes.logo} href="/">
              <img className={classes.logoImage} src={navLogo} />
            </a>

            <Modal open={openModal} onClose={handleCloseModal}>
              <JoinClassModal
                data-test-id="option-modal"
                handleCloseModal={handleCloseModal}
                name={'Lesson'}
                isGuest={true}
              />
            </Modal>

            <Box className={classes.navMenu}>
              <Button className={classes.links} href={homeLink}>
                Home
              </Button>
              <Button className={classes.links} href={aboutUsLink}>
                About Us
              </Button>
              <Button className={classes.links} href={"/login"}>
                Login
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonBg}
                href={"/login/signup"}
              >
                Register
              </Button>
              <Button
                variant="outlined"
                className={classes.normalBtn}
                onClick={handleOpenModal}
                // href={"/login"}
              >
                Join Live Session
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default withRouter(Navbar);
