import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface Profile{
  fullName: string;
  email: string;
  gender: string;
  institution: string;
  summary: string;
  currentPassword: string;
  image:File | string | null;
  id:string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading:boolean;
  headerTitle:string;
  dataLoading: boolean,
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  isVisibleModal: boolean;
  isVisibleErrorModal: boolean;
  profile:Profile;
  isMobile:boolean;
  logoutOpenModal:boolean;
  isSamePass:boolean;
  errorMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postChangePasswordApiCallId:string = "";
  apiProfileDataCallId:string = "";
  apiUpdateProfileDataCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      loading:false,
      headerTitle:configJSON.profileSettings,
      // Customizable Area Start
      dataLoading: false,
      showPassword: true,
      showNewPassword: true,
      showConfirmPassword: true,
      isVisibleModal: false,
      isVisibleErrorModal: false,
      profile:{
        fullName: "",
        email: "",
        gender: "",
        institution: "",
        summary: "",
        currentPassword: "",
        image:"",
        id:""
    },
    isMobile:window.innerWidth <= 600,
    logoutOpenModal:false,
    errorMessage:"",
    isSamePass:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetProfileDataResponse(apiRequestCallId,responseJson,errorReponse)
    this.handleUpdateProfileDataResponse(apiRequestCallId,responseJson,errorReponse)
    this.handleUpdatePasswordDataResponse(apiRequestCallId,responseJson,errorReponse)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
    }
    if(window.location.pathname.includes('/profile/edit')) {
      this.redirect(configJSON.editProfile)
    }
    if(window.location.pathname.includes('/profile/change')) {
      this.redirect(configJSON.changePassword)
    }
  }

  redirect (optionSelected:string){
    this.setState({headerTitle:optionSelected})
    this.getProfileData()
  }
  handleLogOut = () => {
    this.setState({
      logoutOpenModal:true
    })
  }
  doLogout(){
    window.localStorage.removeItem("authToken");
    window.location.replace("login")
  }
  handleClickLoadData = () => {
    this.setState({
      dataLoading: !this.state.dataLoading,
    });
  }
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }
  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }
  hideModal = () => {
    this.setState({ isVisibleModal: false });
  }

  hideErrorModal = () => {
    this.setState({ isVisibleErrorModal: false });
  }

  handleCancel = () => {
    window.location.reload();
  }

  getProfileData(){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  handleChangePassword = (currentPassword: string, newPassword: string) =>  {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const httpChangePasswordBody = {
      data: {
        current_password: currentPassword,
        new_password: newPassword,
      },
    };

    const requestChangePasswordMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postChangePasswordApiCallId = requestChangePasswordMessage.messageId;

    requestChangePasswordMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );

    requestChangePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestChangePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpChangePasswordBody)
    );

    requestChangePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestChangePasswordMessage.id, requestChangePasswordMessage);

    return true;
  }
  updateProfileData(data:any){
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData= new FormData()
    formData.append("full_name", data.fullName); 
    formData.append("email", data.email); 
    formData.append("gender", data.gender); 
    formData.append("summary", data.summary); 
    formData.append("new_password", data.currentPassword); 
    formData.append("institution", data.institution); 
    if(data.image)
      formData.append("image", data.image); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.apiUpdateProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateProfileDataUrl}?account_id=${data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCloseLogoutModal=()=> {
    this.setState({
      logoutOpenModal:false
    })
  }
  handleGetProfileDataResponse(apiRequestCallId:string,responseJson:any,errorReponse:string){
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiProfileDataCallId
    ) {
      if (responseJson) {
        const {id,attributes} = responseJson.data
        this.setState({
          profile:{
            id:id,
            fullName:attributes.full_name||"",
            email:attributes.email||"",
            gender:attributes.gender||"",
            summary:attributes.summary||"",
            currentPassword:attributes.password||"",
            institution:attributes.institution||"",
            image:attributes.image||""
          }
        
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleUpdateProfileDataResponse(apiRequestCallId:string,responseJson:any,errorReponse:string){
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiUpdateProfileDataCallId
    ) {
      if (responseJson) {
        this.getProfileData();
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleUpdatePasswordDataResponse(apiRequestCallId:string,responseJson:any,errorReponse:string){
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.postChangePasswordApiCallId
    ) {
      if (responseJson.data) {
        this.setState({ isVisibleModal: true,isSamePass:false,errorMessage:"" });
      } else {
        let errorMsg =  "";
        if(responseJson.errors && responseJson.errors.length > 0){
          errorMsg = responseJson.errors[0].password 

        }
        this.setState({ isVisibleErrorModal: true,errorMessage: errorMsg, isSamePass: errorMsg.includes("must be different")});
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleResizeWindow = () => {
    this.setState({ isMobile: window.innerWidth <= 600 });
  };


  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow);
  }
  
  // Customizable Area End
}


  