import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Roles } from "../../../components/src/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeState: boolean;
  searchByName: string;
  myDownloadsHeader: string[];
  classDropDownDefault: string;
  allTypeDropDownDefault: string;
  role: string;
  authToken: string;
  myDownloadsData: {
    name: string;
    size: number;
    class: string;
    downloadedOn: string;
    type: string;
  }[];
  isSmallScreen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDownloadApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeState: false,
      searchByName: '',
      myDownloadsHeader: [
        'Name', 'Size', 'Class', 'Type', 'Downloaded On'
      ],
      classDropDownDefault: 'Class 1A Maths',
      allTypeDropDownDefault: 'All Type',
      role: '',
      authToken: "",
      myDownloadsData: [],
      isSmallScreen: window.innerWidth <= 600,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
     
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId === this.getDownloadApiCallId) {
      this.getDownloadApiCallIdReceive(responseJson, errorReponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const currentRole = await getStorageData("role")
    
    if(currentRole === Roles.TEACHER){
      this.setState({role: 'teacher'})
    }else{
      this.setState({role: 'student'})
    }
    const authToken = await getStorageData("authToken");

    this.setState({
      authToken: authToken
    })
    this.handleGetDownloadData();

    window.addEventListener('resize', this.handleResize);
  }

  handleSearchInCloud = (value: string) => {
    this.setState( { searchByName: value }, () => {
      setTimeout(() => {
        this.handleGetDownloadData();
      }, 1000);
    } )
  }

  handleTabs = (status: boolean) => {
    this.setState( { activeState: status } )
  }

  handleGetDownloadData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken,
    };

    const getDownloadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDownloadApiCallId = getDownloadMsg.messageId;

    getDownloadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDownloadEndPoint}${this.state.searchByName}`
    );

    getDownloadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDownloadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getDownloadMsg.id, getDownloadMsg);
  }

  getDownloadApiCallIdReceive = (responseJson: any, errorReponse: any) => {    
    type DownloadAttributes = {
      lesson_name?: string;
      lesson_objective?: string;
      account_id?: number;
      teacher_classes_id: number;
      start_time?: string;
      end_time?: string;
      start_date?: string;
      end_date?: string;
      lesson_code?: string;
      lesson_status?: string;
      status: string;
      live_lesson?: boolean;
      shared?: boolean;
      shared_library_name?: string | null;
      shared_library_type?: string | null;
      created_at: string;
      updated_at: string;
      lesson_attachment?: string | null;
      total_stages?: number;
      images?: string | null;
      object_type: string;
      assignment_name?: string;
      assignment_status?: string;
      details?: string | null;
      assignment_stage_name?: string | null;
      sub_stage_name?: string | null;
      assignment_attachment?: string | null;
      class_name: string;
      days_left?: number;
      quiz_name?: string;
      quiz_status?: string;
      add_to_calendar?: boolean;
      due_date?: number;
      quiz_attachment?: string | null;
    };

    type ApiResponse = {
      lessons: {
        data: Array<{
          id: string;
          type: string;
          attributes: DownloadAttributes;
        }>
      };
      assignments: {
        data: Array<{
          id: string;
          type: string;
          attributes: DownloadAttributes;
        }>
      };
      quizzes: {
        data: Array<{
          id: string;
          type: string;
          attributes: DownloadAttributes;
        }>
      };
    };

    if (errorReponse) {
      return;
    }

    const transformData = (data: Array<{ id: string; type: string; attributes: any }>, type: string): S['myDownloadsData'] => {
      
      return data.map(item => {
        const downloadedOn = new Date(item.attributes.updated_at).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/,/, '');

        return {
            name: item.attributes.lesson_name || item.attributes.assignment_name || item.attributes.quiz_name || '',
            size: item.attributes.size, 
             class: item.attributes.class_name,
            downloadedOn: downloadedOn,
            type: item.attributes.object_type,
            startTime: item.attributes.start_time || '',
            endTime: item.attributes.end_time || '',
            startDate: item.attributes.start_date || '',
            endDate: item.attributes.end_date || '',
            status: item.attributes.status,
            shared: item.attributes.shared || false,
            created_at: item.attributes.created_at,
            updated_at: item.attributes.updated_at,
        };
    });
    };

    const lessons = transformData(responseJson.lessons.data, 'lesson');
    const assignments = transformData(responseJson.assignments.data, 'assignment');
    const quizzes = transformData(responseJson.quizzes.data, 'quiz');

    const allDownloads = [...lessons, ...assignments, ...quizzes];

    this.setState({ myDownloadsData: allDownloads });
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };


  // Customizable Area End
}
