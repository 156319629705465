import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import StaticCanvas from "../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    marginBottom: "20px",
    fontSize: "18px",
    color: "#2C2C2E",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  titleCanvas: {
    paddingTop:"60px"
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
  },
  addTextLayout: {
    display: "flex",

    padding: "30px 60px",
    marginBottom: "60px"
  },
  canvasContainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    position:"relative"
  },
  addTextLayoutD: {
    padding: "30px 60px",
  },
  descWithCanvas: {
    paddingTop:"90px",
    paddingLeft: "25px"
  }
}));
const AddTextLive = (props: any) => {
  const { stage } = props;
  const classes = useStyles();

  const iscanvasExist = stage.attributes.canvas_data
  let hasCanvasDataTitle = undefined; 
  let hasCanvasDataDesc = undefined; 
  let canvasSize = undefined; 
  let canvasDescSize = undefined; 

  if(iscanvasExist){
    const parsedData = typeof iscanvasExist == "object"?iscanvasExist:JSON.parse(iscanvasExist)
    hasCanvasDataTitle = parsedData.canvas
    hasCanvasDataDesc = parsedData.AddTextCanvas
    if(parsedData.canvasSize)
    {
      canvasSize = JSON.parse(parsedData.canvasSize)
    }
    if(parsedData.addTextCanvasSize)
    {
      canvasDescSize = JSON.parse(parsedData.addTextCanvasSize)
    }
  }
  return (
    <>
    {  hasCanvasDataTitle && <Box style={{position:"absolute",width:"100%",height:"100%",}}><StaticCanvas canvasData={hasCanvasDataTitle} id="canvasId" canvasSize={canvasSize}/></Box>}
    <Box className={classes.addTextLayout}>
      <Box>
        <Box
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: stage.attributes.title }}
        />
      </Box>
    </Box>
    <Box style={{position:"relative",width:"100%",height:"100%"}}>
    {  hasCanvasDataDesc && <Box style={{position:"absolute",width:"100%",height:"100%",}}>
      <StaticCanvas canvasData={hasCanvasDataDesc} id="canvasId2" canvasSize={canvasDescSize} isFull={true}/>
      </Box>}
    <Box className={`${classes.addTextLayoutD} ${hasCanvasDataDesc && classes.descWithCanvas}`}>
      <Box>
        <Box
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: stage.attributes.description }}
        />
      </Box>
    </Box>
    </Box>
  </>
  );
};

export default AddTextLive;
