import React, { useState } from "react";
import { Box, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ItemList from "../../../../../../components/src/ItemList.web";
import SearchInput from "../../../../../../components/src/SearchInput.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import Loader from "../../../../../../components/src/Loader.web";
import Gsuiteintegration from "../../../../../gsuiteintegration/src/Gsuiteintegration.web";
const useStyles = makeStyles((theme) => ({
  tabBarStyle: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "start",
    marginLeft: "35px",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      marginLeft:0
        },
  },
  tabsStyle: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #2B3D7D",
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
  tabStyle: {
    "&.MuiTab-root": {
      color: "#2B3D7D",
      fontWeight: 500,
      fontSize: "20px",
      width: "218px",
      textTransform: "capitalize",
      [theme.breakpoints.down('xs')]: {
        width: "133px",
        fontSize: "18px",

      },
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
        fontSize: "20px",
        width: "218px",
      [theme.breakpoints.down('xs')]: {
        fontSize: "18px",
        width: "127px",

      },
    },
  },
 
  searchbarTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#2B3D7D",
    marginTop: "30px",
    marginBottom: "25px",
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
}));

interface TabsPanelProps {
  index: any;
  value: any;
  children?: React.ReactNode;
}

export function TabsPanel(props: TabsPanelProps) {
  const { 
    children, 
    value, 
    index, 
    ...other }
     = props;

  return (
    <div
    id={`simple-tabpanel-${index}`}
      role="tabpanel"
      hidden={value !== index}
      {...other}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StudentClassList = (props: any) => {
  const classes = useStyles();
  const {
    searchInput,
    handleSearchInput,
    callback,
    classList,
    pageLimit,
    pageNo,
    handleTeacherClassPageChange,
    loading,
    navigation
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

 
  return (
    <>
   <Box className={classes.tabBarStyle}>
     <Tabs
       value={selectedTab}
       onChange={handleTabChange}
       aria-label="simple tabs example"
       TabIndicatorProps={{
         style: {
           backgroundColor: "unset",
         },
       }}
       className={classes.tabsStyle}
     >
       <Tab className={classes.tabStyle} label="My Library" />
       <Tab className={classes.tabStyle} label="Go to Drive" />
     </Tabs>
   </Box>
   <TabsPanel value={selectedTab} index={0}>

      <Grid item md={12}>
        <Box sx={{ px: "35px", mb: "20px" }}>
          <Typography className={classes.searchbarTitle}>Classes</Typography>
          <SearchInput
            handleSearchInput={handleSearchInput}
            searchInput={searchInput}
            placeholder={"Search by class name"}
          />
        </Box>
      </Grid>
      {loading ? (
        <Loader loading />
      ) : (<>
        {classList &&
          classList.length === 0 ? (
          <Typography className={classes.noRecords}>No records found</Typography>
        ) : (<>
          <Box sx={{ px: "20px", pt: "5px" }}>
            <ItemList dataList={classList} handleClick={callback} />
          </Box>
          <Box sx={{ px: "35px", py: "50px" }}>
            <BasicPagination
              pageLimit={pageLimit}
              pageNo={pageNo}
              handlePageChange={handleTeacherClassPageChange}
            />
          </Box>
        </>)}
      </>)}
    </TabsPanel>
    <TabsPanel value={selectedTab} index={1}>
            <Box style={{width:"90%", paddingLeft:"3%"}}>
            <Gsuiteintegration
            data-test-id="gdriveIntegration"
            navigation={navigation}
            isCheckboxNeeded={false}
            id={""} 
            revertColumns={true}
            headerLabel={"Downloaded On"}
            googleBtnStyle={{marginTop:"-120%"}}
            advanceOptions

            />
    </Box>
            </TabsPanel>
    </>
  );
};

export default StudentClassList;
