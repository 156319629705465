import React from "react";
import { Typography, Breadcrumbs, Box, Link } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  history: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#757575",
    cursor:"pointer",
    "&:hover": {
      textDecoration:"none"
    },
  },
  destination: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    padding: "0px 35px",
    marginTop: "30px",
  },
  link: {
    textDecoration: "none",
  },
}));
const CustomBreadcrum = (props: any) => {
  const { locations, destination, redirect } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.breadcrumbBox}>
        <Breadcrumbs
          className={classes.navigationIcon}
          separator={<NavigateNextIcon fontSize="large" />}
        >
          {locations.map((location: any) => (
            <Link
              key={location.renderView}
              className={classes.history}
              color="inherit"
              onClick={() => redirect(location.renderView)}
            >
              {location.breadcrumbDisplayName}
            </Link>
          ))}
          <Typography className={classes.destination} color="textPrimary">
            {destination}
          </Typography>
        </Breadcrumbs>
      </Box>
    </>
  );
};

export default CustomBreadcrum;
