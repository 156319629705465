import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContentEditable from "react-contenteditable";
const useStyles = makeStyles(() => ({
  editArea: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    paddingBottom:"40px"
  },
  instructionBox: {
    display: "flex",
    alignItems: "center",
    padding: "0 25px",
    marginBottom: "20px",
    marginTop: "15px",
  },
  instructionPlaceholder: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 400,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
    "& :*":{
      whiteSpace:"break-spaces"
    }
  },
  topic: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#636366",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
    },
    color: "#636366",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "none",
    },
  },
  topicBox: {
    height: "502px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    padding: "20px 15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    borderRadius: "5px",
  },
  topicHeading: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
    marginTop:"auto",
    marginBottom:"auto"
  },
  topicHeadingBox: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    paddingLeft: "15px",
    marginBottom: "20px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    height: "75px",
    display: "flex",
    borderRadius: "5px",
  },
  instructionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "5px",
  },
}));

const Chart = (props: any) => {
  const { setChartState, chartState } = props;
  const classes = useStyles();

  const mdValue =
    chartState.description.length === 4 || chartState.description.length === 2
      ? 6
      : 4;
  const handleInputChange = (id: string, event: any) => {
    if (id === "question") {
      setChartState({ ...chartState, question: event?.target?.value });
    } else if (id.startsWith("topic-heading-")) {
      const topicIndex = parseInt(id.split("-")[2]) - 1;
      const updatedDescription = chartState.description.map(
        (item: any, index: number) => {
          if (index === topicIndex) {
            return { ...item, topic: event?.target?.value };
          }
          return item;
        }
      );

      setChartState({
        ...chartState,
        description: updatedDescription,
      });
    } else if (id.startsWith("topic-")) {
      const topicIndex = parseInt(id.split("-")[1]) - 1;
      const updatedDescription = chartState.description.map(
        (item: any, index: number) => {
          if (index === topicIndex) {
            return { ...item, answer: event?.target?.value };
          }
          return item;
        }
      );

      setChartState({
        ...chartState,
        description: updatedDescription,
      });
    }
  };
  const handleBeforeInput = (id: string, event:React.FormEvent<HTMLDivElement>) => {
   if (id.startsWith("topic-heading-")) {
    const currentText = event.currentTarget.textContent?.length??0;
    if(currentText>=50) event.preventDefault()

    } 
  };
  return (
    <Box className={classes.editArea}>
      <Box className={classes.instructionBox}>
        <Typography className={classes.instructionTitle}>
          Instructions:
        </Typography>
        <ContentEditable
          className={classes.instructionPlaceholder}
          html={chartState ? chartState.question : ""}
          disabled={false}
          onChange={(e) => handleInputChange(`question`, e)}
          data-placeholder="Enter instructions or question here"
          data-test-id="instruction"
        />
      </Box>
      <Box sx={{ px: "25px" }}>
        <Grid container spacing={2}>
          {chartState.description.map((element: any, index: number) => (
            <Grid item md={mdValue}>
              <Box className={classes.topicHeadingBox}>
                <ContentEditable
                  className={classes.topicHeading}
                  html={element?.topic ? element.topic : ""}
                  disabled={false}
                  onChange={(e) =>
                    handleInputChange(`topic-heading-${index + 1}`, e)
                  }
                  data-placeholder="Enter your topic"
                  data-test-id="topic-heading"
                  onBeforeInput={(e) =>
                    handleBeforeInput(`topic-heading-${index + 1}`, e)
                  }
                />
              </Box>
              <Box
                className={classes.topicBox}
                style={{
                  height:
                    chartState.description.length === 4 ||
                    chartState.description.length === 6
                      ? "180px"
                      : "502px",
                }}
              >
                <ContentEditable
                  className={classes.topic}
                  html={element?.answer ? element.answer : ""}
                  disabled={true}
                  onChange={(e) => handleInputChange(`topic-${index + 1}`, e)}
                  data-placeholder="Enter your answer"
                  data-test-id="topic"
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Chart;
