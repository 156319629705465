import React from "react";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import SearchSection from "../../../../../../components/src/SearchSection.web";
import CustomTable from "./CustomTable.web";
import Loader from "../../../../../../components/src/Loader.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
});
const SelectedPractice = (props: any) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {
    locations,
    currentLocation,
    tableSearchInput,
    handleSearchInputTable,
    redirect,
    practiceData,
    loading,
    handlePracticePageChange,
    pageLimitPractice,
    deleteTableData,
    pageNoPractice,
    role,
    generateLink,
    shareLink,
    downloadLesson,
    selectedLessons,
    handleCloseNoMobileModal,
    openNoMobileModal,
    handleOpenNoMobileModal
  } = props;
  return (
    <>
      <Box sx={{ pl: "35px", mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
      </Box>
      <Grid container= {isSmallScreen ? false: true}>
        <Grid item md={12}>
          <Box sx={{ px: "35px", mb: "20px" }}>
            <SearchSection
              searchInput={tableSearchInput}
              handleSearchInput={handleSearchInputTable}
              placeholder={"Search by name"}
            />
          </Box>
        </Grid>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            {!practiceData.data.length ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Grid item md={12}>
                  <Box sx={{ px: "35px", mb: "20px", mt: "10px" }}>
                    <CustomTable
                      practiceData={practiceData}
                      deleteTableData={deleteTableData}
                      role={role}
                      generateLink={generateLink}
                      shareLink={shareLink}
                      handleOpenNoMobileModal={handleOpenNoMobileModal}
                      openNoMobileModal={openNoMobileModal}
                      handleCloseNoMobileModal={handleCloseNoMobileModal}
                      downloadLesson={downloadLesson}
                      selectedLessons={selectedLessons}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box sx={{ px: isSmallScreen ? 0 : "35px", my: "30px" }}>
                    <BasicPagination
                      handlePageChange={handlePracticePageChange}
                      pageLimit={pageLimitPractice}
                      pageNo={pageNoPractice}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default SelectedPractice;
