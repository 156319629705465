import React from "react";
import DashboardController, { Props } from "./DashboardController";
import SchoolAdminDashboard from "./SchoolAdmin/SchoolAdminDashboard.web";
import TeacherDashboard from "./Teacher/TeacherDashboard.web";
import { Roles } from "../../../components/src/types";
import StudentDashboard from "./Student/StudentDashboard.web";
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";

class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    this.handlePageChangeClassList = this.handlePageChangeClassList.bind(this);
    this.handleSearchClassName = this.handleSearchClassName.bind(this);
    this.createLesson = this.createLesson.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.redirectToClassDetailView = this.redirectToClassDetailView.bind(this)
    this.getFormClassList = this.getFormClassList.bind(this);
    this.createAssignment= this.createAssignment.bind(this);
    this.createQuiz = this.createQuiz.bind(this);
  }
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.SUB_ADMIN) {
      return (
        <SchoolAdminDashboard
          navigation={this.isPlatformWeb() ? null : this.props.navigation}
          id={"Dashboard"}
        />
      );
    }
    if (role === Roles.TEACHER) {
      return (
        <TeacherDashboard
          teacherState={this.state.teacherState}
          handlePageChange={this.handlePageChangeClassList}
          handleSearchClassName={this.handleSearchClassName}
          pageNo={this.state.pageNo}
          pageLimit={this.state.pageLimit}
          createLesson={this.createLesson}
          setSelectedOption={this.setSelectedOption}
          selectedOption={this.state.selectedOption}
          redirectToClassDetailView={this.redirectToClassDetailView}
          redirectToLibrary={this.redirectToLibrary}
          getFormClassList={this.getFormClassList}
          formClassList={this.state.formClassList}
          createAssignment={this.createAssignment}
          createQuiz={this.createQuiz}
          redirectToLibraryassignment={this.redirectToLibraryassignment}
        />
      );
    }
    if (role === Roles.STUDENT) {
      return (
        <StudentDashboard
          classList={this.state.studentClassListData}
          handleSearchClassName={this.handleSearchClassNameForStudents}
          redirectToClassDetailView={this.redirectToClassDetailView}
          studentActionItems={this.state.studentActionItems}
          searchInputClassName={this.state.teacherState.searchInputClassName}
        />
      );
    }
  }

  render() {
    return <>
    <SuccessSnackbar
        message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
        open={this.state.isLimitModalOpen} 
        onClose={this.handleCloseLimitSnackbar} 
        type={'error'}
    />
    {this.renderViewBasedOnRole(this.state.role)}
    </>;
  }
}
export default Dashboard;
