import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";
import OptionLayout from "./OptionLayout.web";
import MyProfile from "./MyProfile/MyProfile.web";
import ChangePassword from "./ChangePassword/ChangePassword.web";
import LogoutModal from "./LogoutModal.web";
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";
import Loader from "../../../components/src/Loader.web";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.redirect = this.redirect.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.updateProfileData = this.updateProfileData.bind(this);
    this.handleCloseLogoutModal = this.handleCloseLogoutModal.bind(this);
    this.doLogout = this.doLogout.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderSwitch(selectedOption: string) {
    switch (selectedOption) {
      case configJSON.editProfile:
        return this.state.profile.email ? (
          <MyProfile
            profile={this.state.profile}
            configJSON={configJSON}
            updateProfileData={this.updateProfileData}
            redirect={this.redirect}
          />
        ) : (
          <Loader loading={true} />
        );
      case configJSON.changePassword:
        return <ChangePassword navigation='' id='' />;
      default:
        return (
          <OptionLayout
            redirect={this.redirect}
            handleLogOut={this.handleLogOut}
            configJSON={configJSON}
          />
        );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Grid container style={{ overflowX: "hidden", height: "100%", }}>
          <Grid item {...(!this.state.isMobile && { xs: 2 })} >
           {!this.state.isMobile && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isMobile && { xs: 10 })} style={{ overflowY: "scroll", height: "100%", }}>
            <Grid item md={12} style={{textAlign:'center'}}>
              <AppHeaderWeb title={this.state.headerTitle} />
            </Grid>
            <Grid item md={12} style={{height: "calc(100% - 78px)"}}>
              {this.renderSwitch(this.state.headerTitle)}
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={this.state.logoutOpenModal}
          onClose={this.handleCloseLogoutModal}
        >
          <LogoutModal
            configJSON={configJSON}
            handleCloseLogoutModal={this.handleCloseLogoutModal}
            doLogout={this.doLogout}
          />
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
