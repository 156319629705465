// This function uses fetch rather than RunEngine for the network request 
// It is done because the RestApiClientBlock does not handle google api responses
export async function createAndUploadJsonFile(
  jsonResponse: any,
) {
  
  let fileName;
  const jsonString = JSON.stringify(jsonResponse);
  const blob = new Blob([jsonString], { type: "application/json" });
  const randomNum = Math.floor(Math.random() * 900) + 100; 
  if (jsonResponse.type === 'assignments') {
    fileName = `${randomNum}_${jsonResponse.attributes.assignment_name}`;
  } else if (jsonResponse.type === 'quizzes') {
    fileName = `${randomNum}_${jsonResponse.attributes.quiz_name}`;
  } else {
    fileName = `${randomNum}_${jsonResponse.attributes.lesson_name}`;
  }
  const fileMetadata = {
    name: `${fileName}.json`,
    mimeType: "application/json",
  };

  const formData = new FormData();
  formData.append(
    "metadata",
    new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
  );
  formData.append("file", blob);

  const accessToken = gapi.auth.getToken().access_token;

   await fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
      {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
        body: formData,
      }
    );

}
