import React,{useEffect, useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { extractVideoIdFromThumbnailUrl,constructYoutubeUrlFromVideoId } from "../../../../components/src/Utils.web";
import { audioImage } from "../assets";

const useStyles = makeStyles(() => ({
    addTextLayout: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    imgSizing: {
        maxWidth:"95%",
        maxHeight:"95%",
    },
    audioStyle: {
        width: "50%"
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent:"space-between",
      width: "100%",
      maxHeight:"70%",
      alignItems:"center"
    },
    imageStyle: {
      display:"block",
      width:"auto",
      maxWidth: '20%',

    },
    audioStyleDis:{
      width: "100%",
      height: "30px",
      marginTop: "20px"
    },
}));
const MultiMediaLive = (props: any) => {
    const { stage,handleMedia,isStudent,isPlaying,seconds } = props;
    const audioRef = useRef<HTMLAudioElement>(null)
    const { attributes } = stage;
    const imageUrl = attributes?.images && attributes?.images?.length > 0 && attributes?.images[0]?.url
    const videoUrl = attributes?.videos && attributes?.videos?.length > 0 && attributes?.videos[0]?.url
    const videoID = attributes?.videos_url && attributes?.videos_url[0] != null && extractVideoIdFromThumbnailUrl(attributes?.videos_url[0])
    const classes = useStyles();
    const youtubeUrl = videoID && constructYoutubeUrlFromVideoId(videoID);

    const handledMediaChange = (e:React.SyntheticEvent<HTMLAudioElement,Event>) => {
        !isStudent && handleMedia(e,audioRef.current?.currentTime)
    }

    useEffect(()=>{
      if (audioRef.current) { 
        if(seconds!==undefined) audioRef.current.currentTime = seconds;
        if (isPlaying) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
      }
    },[isPlaying])

    return (
        <Box className={classes.addTextLayout}>
            {imageUrl  && (
            imageUrl.includes(".mp3") ? (
                <Box className={classes.container}>
                <img src={audioImage} alt="Description of the image" className={classes.imageStyle} />
                  <audio ref={audioRef} controls  className={classes.audioStyle}  onPlay={handledMediaChange} onPause={handledMediaChange}>
                    <source src={imageUrl} data-test-id="multi-audio"/>
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              ) : (
                <img
                    data-test-id="img-preview"
                    src={imageUrl}
                    width="auto"
                    height="auto"
                    className={classes.imgSizing}
                />
            )
        )}
            {videoUrl &&
                <video
                    data-test-id="video"
                    width="100%"
                    height="100%"
                    controls
                >
                    <source src={videoUrl} type="video/mp4" />
                </video>
            }
            {videoID &&
                <iframe
                    data-test-id="youtube"
                    width="100%"
                    height="100%"
                    src={youtubeUrl.replace('watch?v=', 'embed/')}
                    title="YouTube Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            }
        </Box>
    );
};

export default MultiMediaLive;