import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import StudentTaskList from "./pages/StudentTaskList.web";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
           {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
            <Grid item md={12}>
              <AppHeaderWeb title={"My Tasks"} />
            </Grid>
            <Grid item md={12} data-testid="renderSwitch">
              <StudentTaskList 
              isBackFromQuiz={this.state.isBackFromQuiz}
              setIsBackFromQuizFalse={this.setIsBackFromQuizFalse}
              getTaskLists={this.getTasklistApiCall} 
              setName={this.setName} 
              Name={this.state.name} 
              taskLists={this.state.taskLists}
              handlePageChange={this.handlePageChange} 
              page={this.state.page} 
              perPage={this.state.perPage} 
              loading={this.state.loading} 
              getQuizelistApiCall={this.getQuizelistApiCall} 
              setQuizeName={this.setQuizeName} 
              quizList={this.state.quizList}
              handleQuizePageChange={this.handleQuizePageChange} 
              quizName={this.state.quizName}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
};
// Customizable Area End
