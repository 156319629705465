import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface BasicCardProps {
  data: any;
  handleClick: (event: React.MouseEvent, data: any) => void;
}

const useStyles = makeStyles(() => ({
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px 11px",
    padding: "15px",
    minHeight:"135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    "&:hover": {
      background: "#ebf8fe",
      borderColor:"#3093DDFF",
      color: "#121212"
    }
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600
  },
  listItemImage: {
    width: "100%",
    height: "100%"
  },
  imageBox:{
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding:"8px",
    backgroundColor:"#fff"
  }
}));
const BasicCardList = (props: BasicCardProps) => {
  const { data, handleClick } = props;
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              data-testid="class-item"
              className={classes.listItemBox}
              onClick={(event)=>handleClick(event, data)}
            >
            <Box className={classes.imageBox}>
              <img
                className={classes.listItemImage}
                src={data?.attributes?.attachment}
              />
            </Box>  
              <Typography
                data-testid={"teacher-fullname"}
                className={classes.listItemFont}
              >
                {data?.attributes?.class_name}
              </Typography>
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
};

export default BasicCardList;
