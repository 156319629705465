Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.feedbackEndPoint = "bx_block_multipageforms2/feedbacks";
exports.feedbackMethodType = "POST";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms2";
exports.labelBodyText = "MultipageForms2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.feedbackFormTxt = "Feedback Form";
exports.hyphenTxt = "-";
exports.feedbackFormTitle = "Lesson 1";
exports.feedbackDescTxt = " We want to know what you thought of your experience at engagemo. so we'd love to hear your feedback.";
exports.overAllRatingTxt = "Overall Experience Rating";
exports.yourNameInputLabel = "Your Name";
exports.emailIdInputLabel = "Email ID";
exports.overallCommentInputLabel = "Overall Comments (Optional)";
exports.enterCommentsPlaceholder = "Enter comments";
exports.thingsLikeLabel = "Things I like about Engagemo";
exports.improvementLabel = "Suggestions for Improvement";
exports.submitTxt = "Submit";
exports.thanksForSubmitForm = "Thanks for submitting the form!";
exports.responseRecorded = "Your response has been recorded.";
exports.backToHome = "Back to Home";
// Customizable Area End