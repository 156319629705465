import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Roles } from "../../../components/src/types";
import StudentLiveStreaming from "./Student/StudentLiveStreaming.web";
import { Link } from "react-router-dom";
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import LeaveLessonModal from "../../../components/src/LeaveLessonModal.web";
// Customizable Area End

import CflivestreamingsubfeatureController, {
  Props,
  configJSON,
} from "./CflivestreamingsubfeatureController";

export default class Cflivestreamingsubfeature extends CflivestreamingsubfeatureController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getStudentDetails=this.getStudentDetails.bind(this)
    this.addChartSubmitData=this.addChartSubmitData.bind(this)
    this.showSuccessMessage=this.showSuccessMessage.bind(this)
    this.hideSuccessMessage=this.hideSuccessMessage.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.STUDENT || this.state.isGuest) {
      return (
        <StudentLiveStreaming
        currentStage={this.state.currentStage}
        parentStage={this.state.parentStageStudent}
        messages={this.state.messages}
        stages={this.state.stages}
        room={this.state.room}
        studentName={this.state.studentName}
        studentProfileImage={this.state.studentProfileImage}
        getStudentDetails={this.getStudentDetails}
        lessonName={this.state.lessonName}
        className={this.state.className}
        showQuestion={this.state.showQuestion}
        addChartSubmitData={this.addChartSubmitData}
        success={this.state.success}
        setSuccess={this.showSuccessMessage}
        hideSuccessMessage={this.hideSuccessMessage}
        leaveLesson={this.modalOpen}
        studentId={this.state.studentId}
        showResult={this.state.showResult}
        pollingResult={this.state.pollingResult}
        screen={this.state.screen}
        setScreen={this.setScreen}
        studentAnswer={this.state.studentAnswer}
        wordCloudAnswer={this.state.wordCloudAnswer}
        stageAnswers={this.state.stageAnswers ?? []}
        lessonStudentStartTime={this.state.lessonStudentStartTime}
        isQuizStudent={this.state.isQuizStudent}
        isNewStage={this.state.isNewStage}
        redirectToPage={this.redirectToPage}
        seconds={this.state.seconds}
        isPlaying={this.state.isPlaying}
        nextStage={this.nextStage}
        prevStage={this.prevStage}
        sendChatMessage={this.sendChatMessage}
        scoreData={this.state.scoreData}
        createQuizStudentLibrary={this.createQuizStudentLibrary}
        isGuest={this.state.isGuest}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <SuccessSnackbar
        type={'error'}
        open={this.state.isDataLimitModal} 
        onClose={this.handleCloseDataLimitModal} 
        message={'Your 200MB storage limit has been reached. Please delete few files to refresh your limit.'} 
        />
        <LeaveLessonModal 
          data-test-id = "leaveLessonModal"
          open={this.state.leaveLessonModal} 
          exitLesson={()=>this.redirectToPage()} 
          exitAndSaveLesson={(checked:boolean) => this.createStudentLibrary(checked)} 
          handleClose={this.modalClose}
          isQuiz={this.state.isQuizStudent}
          isGuest={this.state.isGuest}
          checkSignIn={this.signInSwitch}
        />
        {this.renderViewBasedOnRole(this.state.role)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
