import React from "react";
import { Menu, MenuItem, ListItemText, Popper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledFolderListItem = withStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: "Poppins"
    },
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);
const StyledFilterList = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const DateSortList = (props: any) => {
  const {
    anchorEl,
    open,
    onClose,
    handleDateFilter,
    filterType,
    handleStatusFilter,
    handleCloseMainFilter
  } = props;

  const handleApplyFilter = (e: any, value: string) => {
    if (filterType === "Date") {
      handleDateFilter(value)
    } else if (filterType === "Status") {
      handleStatusFilter(value)
    }
    onClose(e)
    handleCloseMainFilter(e)
  }

  return (
    <Popper open={open} anchorEl={anchorEl} placement="right-start">
      <StyledFilterList
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {filterType === "Date" ? (<>
          <StyledFolderListItem >
            <ListItemText
              data-test-id="most-recent-option"
              primary="Most Recent"
              color="#48484A"
              onClick={(e) => handleApplyFilter(e, "most_recent")} />
          </StyledFolderListItem>
          <StyledFolderListItem>
            <ListItemText
              data-test-id="week-option"
              primary="This Week"
              color="#48484A"
              onClick={(e) => handleApplyFilter(e, "this_week")} />
          </StyledFolderListItem>
          <StyledFolderListItem>
            <ListItemText
              data-test-id="month-option"
              primary="This Month"
              color="#48484A"
              onClick={(e) => handleApplyFilter(e, "this_month")} />
          </StyledFolderListItem>
        </>)
          : (<>
            <StyledFolderListItem>
              <ListItemText
                data-test-id="complete-option"
                primary="Complete"
                color="#48484A"
                onClick={(e) => handleApplyFilter(e, "complete")} />
            </StyledFolderListItem>
            <StyledFolderListItem>
              <ListItemText
                data-test-id="incomplete-option"
                primary="Incomplete"
                color="#48484A"
                onClick={(e) => handleApplyFilter(e, "incomplete")} />
            </StyledFolderListItem>
          </>)}
      </StyledFilterList>
    </Popper>
  );
};

export default DateSortList;
