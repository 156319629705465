import React, { useCallback} from "react";
import { Box, Modal, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon, multimediaIcon } from "../../assets";
import { useDropzone } from 'react-dropzone';


const useStyles = makeStyles(() => ({
  modalStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    maxHeight: "90vh",
    background: "#fff",
    border: "none",
    boxShadow: "24",
    borderRadius: "15px",
    padding: "24px",
    overflowX: "hidden",
  },
  modalHeadText: {
    fontFamily: 'Poppins',
    margin:"20px 0",
    color: "#2B3D7D",
    fontSize: "28px",
    fontWeight: 700,
    textAlign: "center",
  },
  inputHead: {
    fontFamily: 'Poppins',
    fontSize: "28px",
    margin: "15px 0",
    fontWeight: 700,
    color: "#2B3D7D",
    textAlign: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    cursor: "pointer",
    right: "20px",
  },
  dragIcon: {
    width: "60px"
  },
  dragUploadBox: {
    display: "flex",
    gap: "2px",
    border: "2px solid #e5e5e5",
    flexDirection: "column",
    padding: "60px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px",
    minWidth: "400px",
    cursor: "pointer"
  },
  dragDropheading: {
    fontFamily: "Poppins",
    color: "#000",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: '30px',
    textAlign: "center",
  },
  dragDropSubheading: {
    fontFamily: "Poppins",
    color: "#3F526D",
    lineHeight: '30px',
    fontWeight: 400,
    fontSize: "14px",
    textAlign: "center",
  },
  inputStyle: {
    gap: "10px",
    justifyContent: "end",
    display: "flex",
    alignItems: "end"
  },
  inputlabel: {
    fontFamily: "Poppins"
  },
  customInput: {
    border: "1px solid #C0C0C0",
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 15px",
    '.MuiInputBase-input': {
      background: 'red'
    },
  },
  embedbtn: {
    border: "none",
    background: "#2b3d7d",
    borderRadius: "5px",
    color: "#fff",
    padding: "10px",
    fontFamily: "Poppins",
    fontSize: "16px",
    cursor: "pointer"
  },
  previewLabel: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: "#2B3D7D",
  },
  previewWrapper: {
    display: "flex",
    overflowX: "auto",
    alignItems: "start",
    gap: "10px",
    flexWrap: "nowrap",
    marginTop: "10px"
  },
  previewBox: {
    flex: "0 0 auto",
    backgroundColor: "#fff",
    width: "32%",
    flexDirection: "column",
    gap: "10px",
    display: "flex",
  },
  previewTitile: {
    padding: "0 12px"
  },
  previewImg: {
    height: "220px",
    width: "100%",
  },
  browseLink: {
    textDecoration: "underline",
  },
  error: {
    color: "red",
    marginTop: "10px",
    display: "block",
    fontSize: "14px",
    fontFamily: "Poppins"
  },
}));

const McqModal = (props: any) => {
  const { open, onClose, setfile} = props;
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleSubmit(acceptedFiles);
    },
    [setfile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
   

  const handleSubmit = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return false;
    }    
    setfile(acceptedFiles[0]);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalStyles}>
        <img className={classes.closeIcon} src={closeIcon} onClick={onClose} />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.modalHeadText}>
              Add Image Background
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box {...getRootProps()} className={classes.dragUploadBox}>
              <input {...getInputProps()} accept="image/*" />
              <img src={multimediaIcon} className={classes.dragIcon} />
              <Typography className={classes.dragDropheading}>
                Drag & Drop Image or <span className={classes.browseLink}>Browse</span>
              </Typography>
              <Typography className={classes.dragDropSubheading}>
                Supported formats: JPEG, PNG , GIF
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default McqModal;
