import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  coloredBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    [theme.breakpoints.down('xs')]: {
      flexDirection:'column',
      gap:20
     },
  },
  normalBtn: {
    borderRadius: "10px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    marginRight: "20px",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
}));
interface ButtonProps {
  label: string;
  handleClick: (event: React.MouseEvent) => void;
  type: "COLORED" | "NORMAL";
  customStyle: any;
  disabled?: boolean;
}
const GenericButton = (props: ButtonProps) => {
  const { label,   handleClick, type, customStyle, disabled } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const combinedStyles = {
    ...customStyle,
    ...(isSmallScreen ? { width: '90%' } : {}),
  };

  return (
    <>
      <Button
        variant="outlined"
        className={(type === "COLORED" && !disabled) ? classes.coloredBtn : classes.normalBtn}
        onClick={  handleClick}
        disabled={disabled}
        style={combinedStyles}
        data-test-id={`createClassBtn-${label}`}
      >
        {label}
      </Button>
    </>
  );
};

export default GenericButton;
