// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const assignmentIcon = require("../assets/assignmentIcon.svg");
export const clockIcon = require("../assets/clockIcon.svg");
export const viewQuizzes = require("../assets/view_Quizzes.svg");
export const Object = require("../assets/Object.svg");
export const back = require("../assets/group_icon.svg");


// Customizable Area End