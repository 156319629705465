import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  Typography,
  OutlinedInput,
  Modal,
  NativeSelect,
  Chip,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { BootstrapInput } from "../../../../CustomisableUserProfiles/src/MyProfile/MyProfile.web";
import { errorAlert } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
const configJSON = require("../../config");
import CreateStudentSuccessModal from "../components/CreateStudentSuccessModal.web";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  studentPasswordTitle: {
    fontSize: "32px",
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
     fontSize: "28px"
    },
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  btnBox:{
    [theme.breakpoints.down("sm")]: {
      width: "100%"
     },
  },
  studentBtnLayout: {
    display: "flex",
    justifyContent: "end",
    padding: "50px 0",
  },
  matched: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "green",
    fontWeight: 400,
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
  inputLabelError: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#FE575A",
  },
  errorAlertIcon: {
    width: "18px",
    height: "18px",
    marginRight: "10px",
  },
  errorLayout: {
    display: "flex",
    marginTop: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    background: "none",
  },
  chip: {
    margin: "5px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },

  inputLabelBlack: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1C1E",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#3F526D",
  },
  inputValueMatched: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#3F526D",
    border: "1px solid green",
  },
  inputx: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #C0C0C0",
    fontSize: "18px",
    fontFamily: "Poppins",
    padding: "10px 26px 10px 12px",
    minHeight: "37px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#2B3D7D",
      backgroundColor: "#FFF",
    },
    "&:hover": {
      borderBottom: "none",
    },
    backgroundColor: "#FFF",
  },

  inputValueBlack: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  container: {
    padding: "0 35px",
  },
  halfLayout: {
    width: "48.5%",
  },
  multiSelect: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "250px"
    },
  },
  customChip: {
    margin: theme.spacing(0.5),
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
  classOptions: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
}));
const genderOptions = ["Male", "Female", "Other"];
const profileOptions = ["Complete", "Incomplete"];
const AddEditStudent = (props: any) => {
  const {
    studentDetails,
    showStudentListView,
    createStudent,
    updateStudent,
    formClassList,
    setCreateUpdateStudentModal,
    studentApiError
  } = props;
  const initialClassNames = studentDetails?.classes?.map((item: any) => ({
    id: item.id,
    name: item.name,
  }));
  const isUpdateRequest = Object.keys(studentDetails).length;
  const classes = useStyles();
  const [errors, setErrors] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>(studentDetails);
  const [selectedOptions, setSelectedOptions] = React.useState(
    initialClassNames ? initialClassNames : []
  );
  const [isMatched, setIsMatched] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] =
    React.useState<boolean>(false);
  const [showCurrentPassword, setCurrentShowPassword] =
    React.useState<boolean>(false);

  const handleInputChange = (_: any, value: any) => {
    if (value.length === 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["class"]: "Invalid input",
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors["class"];
        return newErrors;
      });
    }
    setSelectedOptions(value);
  };
  const handleCloseModal = () => {
    setCreateUpdateStudentModal(false);
    window.location.replace("/classes/myclasses");
  };
  const renderClassBasedOnErrors = (fieldName: string) => {
    return Boolean(errors[fieldName])
      ? classes.inputLabelError
      : classes.inputLabel;
  };
  const handlePasswordConfirmation = (e: any) => {
    const { name, value } = e.target;
    if (formData.fieldPassword && name === "confirmPassword") {
      if (formData.fieldPassword !== value) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: "Invalid input",
        }));
        setIsMatched(false);
      } else {
        setErrors((prevErrors: any) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
        setIsMatched(true);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePasswordChange = (e: any) => {
    const { name, value } = e.target;
    const fieldPasswordValidation= /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/
    handleChange(e);
    if (formData.confirmPassword && name === "fieldPassword") {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors["confirmPassword"];
        return newErrors;
      });
   
      if (formData.confirmPassword !== value) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["confirmPassword"]: "Invalid input",
        }));
        setIsMatched(false);
      }
      else if(formData.confirmPassword === value && !value.match(fieldPasswordValidation)){
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fieldPassword"]: "Invalid input",
        }));
        setIsMatched(false);
      } else {
        setErrors((prevErrors: any) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
        setIsMatched(true);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChange = (date: any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    handleChange({ target: { name: "birthDate", value: formattedDate } });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const validationRules: any = {
      fullName: /^.+$/,
      birthDate: /^.+$/,
      profileStatus: /^.+$/,
      email: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]+/,
      fieldPassword:
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
    };
    if (name === "fieldPassword") {
      setIsMatched(false);
    }
    if (!value.match(validationRules[name])) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Invalid input",
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const isNonPasswordChangeFieldsPresent = () => {
    if (
      formData.fullName &&
      formData.email &&
      formData.profileStatus &&
      selectedOptions.length > 0
    ) {
      return true;
    } else {
      if (!formData.fullName) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fullName"]: "Invalid input",
        }));
      }
      if (!formData.email) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["email"]: "Invalid input",
        }));
      }
      if (!formData.profileStatus) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["profileStatus"]: "Invalid input",
        }));
      }
      if (selectedOptions.length === 0) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["class"]: "Invalid input",
        }));
      }
      return false;
    }
  };
  const checkForPasswordChangeFieldErrors = () => {
    if (!formData.fieldPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["fieldPassword"]: "Invalid input",
      }));
    }
    if (!formData.confirmPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["confirmPassword"]: "Invalid input",
      }));
    }
    if (!formData.currentPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["currentPassword"]: "Invalid input",
      }));
    }
  };
  const isEditMandatoryFieldsPresent = () => {
    if (
      formData.fieldPassword === undefined &&
      formData.confirmPassword === undefined &&
      formData.currentPassword === undefined
    ) {
      return isNonPasswordChangeFieldsPresent();
    } else if (
      !(
        formData.fieldPassword &&
        formData.confirmPassword &&
        formData.currentPassword
      )
    ) {
      checkForPasswordChangeFieldErrors();
      return false;
    } else {
      return true;
    }
  };
  const isCreateMandatoryFieldsPresent = () => {
    if (
      formData.fullName &&
      formData.email &&
      formData.fieldPassword &&
      formData.profileStatus &&
      selectedOptions.length > 0
    ) {
      return true;
    } else {
      if (!formData.fullName) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fullName"]: "Invalid input",
        }));
      }
      if (!formData.email) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["email"]: "Invalid input",
        }));
      }
      if (!formData.fieldPassword) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fieldPassword"]: "Invalid input",
        }));
      }
      if (!formData.profileStatus) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["profileStatus"]: "Invalid input",
        }));
      }
      if (selectedOptions.length === 0) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["class"]: "Invalid input",
        }));
      }
      return false;
    }
  };
  const isMandatoryFieldsPresent = () => {
    if (isUpdateRequest) {
      return isEditMandatoryFieldsPresent();
    } else {
      return isCreateMandatoryFieldsPresent();
    }
  };
  const createOrUpdateStudent = (e: any) => {
    if (!isMandatoryFieldsPresent()) return;
    if (Object.keys(errors).length) return;
    const student: any = {
      fullName: formData.fullName,
      email: formData.email,
      gender: formData.gender,
      phone: formData.phone,
      birthDate: formData.birthDate,
      fieldPassword: formData.fieldPassword,
      profileStatus: formData.profileStatus,
      classId: selectedOptions.map((item: any) => item.id),
    };
    if (isUpdateRequest) {
      student["id"] = studentDetails.id;
      student["currentPassword"] = formData.currentPassword;
      student["fieldPassword"] = formData.fieldPassword;
      student["confirmPassword"] = formData.confirmPassword;
      updateStudent(student);
    } else {
      createStudent(student);
    }
  };
  const handleCancel = (e: any) => {
    showStudentListView();
  };
  const renderPassowordLabel = () => {
    return isUpdateRequest ? "Change Password" : "Profile Password";
  };
  const renderSubmitBtnText = () => {
    return isUpdateRequest ? "Save" : "Add";
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const renderVisibilityIcon = (fieldType: boolean) => {
    if (fieldType) {
      return <Visibility />;
    } else {
      return <VisibilityOff />;
    }
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };
  const handleClickShowCurrentPassword = () => {
    setCurrentShowPassword(!showCurrentPassword);
  };
  const renderPasswordType = (fieldType: boolean) => {
    if (fieldType) {
      return "text";
    } else {
      return "password";
    }
  };
  const handleDeleteOption = (optionToDelete: any) => {
    const updatedOptions = selectedOptions.filter(
      (option: any) => option.id !== optionToDelete.id
    );
    setSelectedOptions(updatedOptions);
  };
  const renderMatchedStatus = () => {
    return isMatched ? classes.inputValueMatched : classes.inputValue;
  };
  const initBirthDate = () => {
    return formData.birthDate
      ? moment(formData.birthDate, "DD/MM/YYYY", true).toDate()
      : undefined;
  };
  return (
    <Box sx={{ px: "35px", mt: "20px" }}>
      <form>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("fullName")}
                    htmlFor="fullName"
                  >
                    Full Name*
                  </label>
                  <TextField
                    id="fullName"
                    data-test-id="fullname"
                    name="fullName"
                    data-testid={"fullName"}
                    fullWidth
                    placeholder="Enter Your Name"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.fullName}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                  />
                  {Boolean(errors.fullName) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.fullName,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("birthDate")}
                    htmlFor="birthDate"
                  >
                    Date of Birth (optional)
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        id="birthDate"
                        name="birthDate"
                        variant="dialog"
                        data-test-id={"birthDate"}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        value={initBirthDate()}
                        labelFunc={() =>
                          formData.birthDate || "Enter Birth Date"
                        }
                        fullWidth
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  {Boolean(errors.birthDate) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.birthDate,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label className={classes.inputLabel} htmlFor="gender">
                    Gender (optional)
                  </label>
                  <NativeSelect
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    {!formData.gender && <option value="">Select</option>}
                    {genderOptions.map((option) => (
                      <option
                        key={option}
                        value={option.toLowerCase()}
                        className={classes.inputValue}
                      >
                        {option}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label className={classes.inputLabel} htmlFor="profileStatus">
                    Profile Status*
                  </label>
                  <NativeSelect
                    id="profileStatus"
                    name="profileStatus"
                    value={formData.profileStatus}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    {!formData.profileStatus && (
                      <option value="">Select</option>
                    )}
                    {profileOptions.map((option) => (
                      <option
                        key={option}
                        value={option.toLowerCase()}
                        className={classes.inputValue}
                      >
                        {option}
                      </option>
                    ))}
                  </NativeSelect>
                  {Boolean(errors.profileStatus) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.profileStatus,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("email")}
                    htmlFor="email"
                  >
                    Email ID*
                  </label>
                  <TextField
                    id="email"
                    name="email"
                    data-testid={"email"}
                    fullWidth
                    placeholder="Enter Email"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.email}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                  />
                  {Boolean(errors.email) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.email,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("phone")}
                    htmlFor="phone"
                  >
                    Mobile Number (optional)
                  </label>
                  <TextField
                    id="phone"
                    name="phone"
                    data-testid={"phone"}
                    fullWidth
                    placeholder="Enter Your Phone"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.phone}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                  />
                  {Boolean(errors.phone) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.phone,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label className={classes.inputLabel} htmlFor="class">
                    Class*
                  </label>
                  <Autocomplete
                    id="class"
                    data-test-id="class-input"
                    multiple
                    fullWidth
                    options={formClassList}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedOptions}
                    onChange={handleInputChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option: any, index) => (
                        <Chip
                          data-test-id="class-input-chip"
                          label={option.name}
                          {...getTagProps({ index })}
                          onDelete={() => handleDeleteOption(option)}
                          className={classes.customChip}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Classes"
                        className={classes.multiSelect}
                      />
                    )}
                    renderOption={(option: any, { selected }) => (
                      <React.Fragment>
                        <Typography className={classes.classOptions}>
                          {option.name}
                        </Typography>
                      </React.Fragment>
                    )}
                    PopperComponent={(props) => (
                      <div {...props} style={{ zIndex: 2000 }} />
                    )}
                  />
                  {Boolean(errors.class) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.class,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.studentPasswordTitle}>
              {renderPassowordLabel()}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={5}>
              {isUpdateRequest ? (
                <>
                  <Grid item md={6}>
                    <FormControl fullWidth variant="outlined">
                      <label
                        className={renderClassBasedOnErrors("currentPassword")}
                        htmlFor="currentPassword"
                      >
                        Current Password
                      </label>
                      <OutlinedInput
                        id="currentPassword"
                        name="currentPassword"
                        data-testid={"currentPassword"}
                        fullWidth
                        placeholder="Enter Your Current Password"
                        onChange={handleChange}
                        value={formData.currentPassword}
                        className={classes.inputValue}
                        type={renderPasswordType(showCurrentPassword)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id={"show-current-password"}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassword}
                              edge="end"
                            >
                              {renderVisibilityIcon(showCurrentPassword)}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(errors.currentPassword) && (
                        <Box className={classes.errorLayout}>
                          <img
                            className={classes.errorAlertIcon}
                            src={errorAlert}
                          />
                          <Typography
                            className={classes.error}
                            dangerouslySetInnerHTML={{
                              __html: configJSON.formErrors.currentPassword,
                            }}
                          />
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("fieldPassword")}
                    htmlFor="fieldPassword"
                  >
                    Enter Password
                  </label>
                  <OutlinedInput
                    id="fieldPassword"
                    name="fieldPassword"
                    data-test-id={"fieldPassword"}
                    fullWidth
                    placeholder="Enter Password"
                    onChange={handlePasswordChange}
                    value={formData.fieldPassword}
                    className={renderMatchedStatus()}
                    type={renderPasswordType(showPassword)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id={"show-password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {renderVisibilityIcon(showPassword)}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.fieldPassword) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.fieldPassword,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    className={renderClassBasedOnErrors("confirmPassword")}
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <OutlinedInput
                    id="confirmPassword"
                    data-test-id="password-confirmation"
                    name="confirmPassword"
                    fullWidth
                    placeholder="Confirm password"
                    onChange={handlePasswordConfirmation}
                    value={formData.confirmPassword}
                    className={renderMatchedStatus()}
                    type={renderPasswordType(showConfirmPassword)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id={"show-confirm-password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {renderVisibilityIcon(showConfirmPassword)}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {isMatched && !Boolean(errors.fieldPassword) ? (
                    <Typography
                      className={classes.matched}
                      dangerouslySetInnerHTML={{
                        __html: configJSON.fieldPasswordMatched,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {Boolean(errors.confirmPassword) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.confirmPassword,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.btnBox}>
            <Box className={classes.studentBtnLayout}>
              <GenericButton
                type="NORMAL"
                label="Cancel"
                data-test-id="cancel-btn"
                handleClick={(e) => handleCancel(e)}
                customStyle={{ width: "165px" }}
              />
              <GenericButton
                type="COLORED"
                data-test-id="save-add-btn"
                label={renderSubmitBtnText()}
                handleClick={(e) => createOrUpdateStudent(e)}
                customStyle={{ width: "165px" }}
              />

              <Modal open={studentApiError} onClose={handleCloseModal}>
                <CreateStudentSuccessModal
                  fullName={formData.fullName}
                  isUpdateRequest={isUpdateRequest}
                  data-test-id="success-modal"
                  handleCloseModal={handleCloseModal}
                />
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddEditStudent;
