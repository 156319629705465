import { Box, Typography, Switch } from "@material-ui/core";
import React from "react";
import { viewAnnotation, viewDownload,closeIcon } from "../assets";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import GenericButton from "../../../../components/src/GenericButton.web";

const ToggleSwitch = withStyles((theme) => ({
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      backgroundColor:"white"
    },
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      "& .MuiSwitch-track":{
        boxSizing: "border-box"
      }
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "grey",
    },
    checked: {},
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          boxSizing: "border-box"
        },
      },
    },
  }))(Switch);
const useStyles = makeStyles((theme) => ({
    popupContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom:"12px"
    },
    closeIcon: {
      position: "absolute",
      right: "20px",
      top: "20px",
      cursor: "pointer"
    },
    paper: {
      position: "absolute",
      width: "510px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      padding: "0px 8px",
      display: "flex",
      justifyContent: "center",
      aignItems: "center",
      flexDirection:"column"
    },
    title: {fontFamily:"Poppins",color:"#2C2C2E",fontSize:"20px"},
    boxStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #E5E5EA",
        borderRadius: "10px",
        padding: "10px",
        width: "100%",
      },
    boxLayout: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
        gap:"20px"
      },
    btnLayout: {
      display: "flex",
      justifyContent: "end",
      marginTop: "20px"
    },
    mainLayout:{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        background: "#fff",
        borderRadius: "20px solid gray",
        height: "50px",
        width: "100%",
      }
  }));
  function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  interface Props{
    onClose:()=>void,
    handleHighlightText:(data:string)=>void,
    type:string,
    stagesData?: any,
    handleSaveClick: Function,
    checkSignIn: Function
  }
const PopUp = (props:Props)=> {
    const {onClose,type}=props;
    const [modalStyle] = React.useState(getModalStyle);
    const [checked, setChecked] = React.useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.checkSignIn()
    setChecked(event.target.checked);
  };
  const classes = useStyles();
    return (
        <Box sx={modalStyle} className={classes.paper}>
        <Box
          className={classes.mainLayout}
        >
          <img src={closeIcon} onClick={onClose} className={classes.closeIcon}/>
        </Box>
        <Box
          className={classes.boxLayout}
        >
          <Box
            className={classes.boxStyle}
          >
            <Typography style={{fontFamily:"Poppins",color:"#2B3D7D"}}>Save to Drive</Typography>
            <Box style={{display:"flex",flexDirection:"row",gap:"20px"}}>
            <ToggleSwitch data-test-id="toggle" checked={checked} onChange={handleSwitchChange} name="checkedA" />
            </Box>
          </Box>
          <img src={viewAnnotation}/>
          <Box className={classes.popupContent}>
            <Typography className={classes.title}>
              Are you sure you want to save the changes to the document?
            </Typography>
            <Box className={classes.btnLayout}>
              <GenericButton
                type="NORMAL"
                label="Cancel"
                handleClick={onClose}
                customStyle={{ width: "150px" }}
              />
              <GenericButton
                data-test-id="move-btn"
                type="COLORED"
                label="Save"
                handleClick={() => props.handleSaveClick(type,checked)}
                customStyle={{ width: "150px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
}

export default PopUp;