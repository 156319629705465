import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import CFWordCloudController, {
  Props,
  configJSON,
} from "./CFWordCloudController";
import { Roles } from "../../../components/src/types";
import AddWordCloud from "./Teacher/components/AddWordCloud.web";

// Customizable Area End

export default class CFWordCloud extends CFWordCloudController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER) {
      return (
        <AddWordCloud
          setAddWordCloudState={this.props.setAddWordCloudState}
          addWordCloudState={this.props.addWordCloudState}
          enteriesCount={this.props.enteriesCount}
          setEnteriesCount={this.props.setEnteriesCount}
          preview={this.props.preview} 
          setPreview={this.props.setPreview}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.renderViewBasedOnRole(this.state.role)}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
