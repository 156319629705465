import React from "react";
import { Box, Typography } from "@material-ui/core";
import SearchInput from "../../../../../components/src/SearchInput.web";
import { makeStyles } from "@material-ui/core/styles";
import ActionItems from "../components/ActionItems.web";
import ClassList from "../components/ClassList.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
const useStyles = makeStyles(() => ({
  searchbarTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#2B3D7D",
    marginTop: "30px",
    marginBottom: "25px",
  },
}));
const TeacherInfo = (props: any) => {
  const {
    classList,
    handleSearchClassName,
    searchInputClassName,
    handlePageChange,
    pageLimit,
    totalStudentCount,
    pageNo,
    createLesson,
    setSelectedOption,
    selectedOption,
    redirectToClassDetailView,
    redirectToLibrary,
    getFormClassList,
    formClassList,
    createQuiz,
    createAssignment,
    redirectToLibraryassignment
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "44px" }}>
          <ActionItems
            totalStudentCount={totalStudentCount}
            createLesson={createLesson}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            getFormClassList={getFormClassList}
            formClassList={formClassList}
            createAssignment={createAssignment}
            createQuiz={createQuiz}
            redirectToLibrary={redirectToLibrary}
            redirectToLibraryassignment={redirectToLibraryassignment}
          />
        </Box>
        <Typography className={classes.searchbarTitle}>My Classes</Typography>
        <SearchInput
          placeholder={"Search by class name"}
          handleSearchInput={handleSearchClassName}
          searchInput={searchInputClassName}
        />
      </Box>
      <Box sx={{ px: "25px", mt: "20px" }}>
        <ClassList dataList={classList} isTeacher handleClick={redirectToClassDetailView}/>
      </Box>
      <Box sx={{ px: "35px", py: "50px" }}>
        <BasicPagination
          handlePageChange={handlePageChange}
          pageLimit={pageLimit}
          pageNo={pageNo}
        />
      </Box>
    </>
  );
};

export default TeacherInfo;
