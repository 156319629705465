import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { debounce } from "lodash";
interface ClassAttributes {
    class_name: string;
    class_code: string;
    start_date: string;
    end_date: string;
    class_status: string;
    created_at: string;
    updated_at: string;
    attachment: string | null;
    full_name: string | null;
    student_count: number | null;
    teacher_id: number;
}

interface TeacherClass {
    id: string;
    type: string;
    attributes: ClassAttributes;
}

interface AccountAttributes {
    email: string;
    full_name: string;
    activated: boolean;
    gender: string;
    date_of_birth: string;
    institution: string | null;
    summary: string | null;
    school_admin: string | null;
    grade_id: string | null;
    course: string | null;
    teacher_id: number;
    full_phone_number: string;
    first_name: string | null;
    last_name: string | null;
    account_type: string | null;
    country_code: string | null;
    profile_status: string;
    role: string;
    password: string;
    phone_number: string | null;
    image: string | null;
    date_created: string;
    student_count: number;
    teacher_count: number;
    classes: TeacherClass[];
    updated_at: string;
}

interface Account {
    id: string;
    type: string;
    attributes: AccountAttributes;
}

interface Meta {
    current_page: number;
    total_pages: number;
    next_page: number | null;
    privacy_page: number | null;
    per_page_count: string;
}

interface ApiResponse {
    data: Account[];
    meta: Meta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    taskDetailList: Account[],
    perPage: number | string;
    totalPages: number | string;
    currentPage: number;
    isPendingReviewPage: boolean;
    isQuizPendingReviewPage: boolean;
    className: string;
    daysLeft: number;
    assignmentName: string;
    assignmentId: string | number;
    searchValue: string;
    isQuizPage: boolean;
    currentPagePath: string;
    currentPageId: string | number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DownloadController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFeedbackAndPendingReviewApiCallId: string = "";
    getQuizFeedbackAndPendingReviewApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            taskDetailList: [],
            perPage: 1,
            totalPages: 1,
            currentPage: 1,
            isPendingReviewPage: false,
            isQuizPendingReviewPage: false,
            className: '',
            daysLeft: 0,
            assignmentName: '',
            assignmentId: 0,
            searchValue: '',
            isQuizPage: false,
            currentPagePath: '',
            currentPageId: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
    
        let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        
        if (apiRequestCallId === (this.getFeedbackAndPendingReviewApiCallId || this.getQuizFeedbackAndPendingReviewApiCallId)) {
            if(!responseJson?.errors && responseJson?.data){
                this.handleStudentDetailsRes(responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        let taskAssignmentData = await getStorageData("taskAssignmentData")
        taskAssignmentData = JSON.parse(taskAssignmentData);
        
        if(taskAssignmentData && (taskAssignmentData?.attributes?.assignment?.data?.attributes || taskAssignmentData?.attributes?.quiz?.data?.attributes) ){
            if(!window.location.pathname.includes('feedbackSentQuiz') && !window.location.pathname.includes('pendingsReviewQuiz')){
                const assignment = taskAssignmentData?.attributes?.assignment?.data?.attributes;
                if(assignment) {
                    this.setState({
                        daysLeft: assignment.days_left,
                        assignmentName: assignment.assignment_name,
                        assignmentId: taskAssignmentData.attributes.assignment_id,
                        className: assignment.class_name
                    })
                }
            }else {
                this.setState({
                    daysLeft: taskAssignmentData.attributes.quiz.data.attributes.due_date,
                    assignmentName: taskAssignmentData.attributes.quiz.data.attributes.quiz_name,
                    assignmentId: Number(taskAssignmentData.attributes.quiz.data.id),
                    className: taskAssignmentData.attributes.quiz.data.attributes.class_name,
                    isQuizPage: true,
                })
            }
        }
        
        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const student_id = parts[2];
        this.setState({ currentPageId: student_id })

        if(window.location.pathname.includes('feedbackDetails')){
            this.getFeedbackAndPendingReview(this.state.currentPage);  
            this.setState({ currentPagePath: 'FeedbackDetails' })          
        } else if(window.location.pathname.includes('feedbackSentQuiz')){
            this.getQuizFeedbackAndPendingReview(this.state.currentPage)
            this.setState({ currentPagePath: 'QuizFeedbackDetails' })          

        } else if(window.location.pathname.includes('pendingsReviewQuiz')){
            this.setState({isQuizPendingReviewPage: true, currentPagePath: 'QuizPendingReviewDetails'}, () => {
                this.getQuizFeedbackAndPendingReview(this.state.currentPage);
            })
        } else {
            this.setState({isPendingReviewPage: true}, () => {
                this.getFeedbackAndPendingReview(this.state.currentPage);
                this.setState({ currentPagePath: 'PendingReviewDetails' })          
            })
        }
    }

    handleStudentDetailsRes = (responseJson: ApiResponse)=>{
        if(responseJson && responseJson.data){
            this.setState({
                taskDetailList: responseJson.data,
                totalPages: responseJson.meta.total_pages,
                currentPage: responseJson.meta.current_page,
                perPage: responseJson.meta.per_page_count,
            });
        }
    }
    
    getFeedbackAndPendingReview = (currentPage: number) => {
        const feedbackStatus = this.state.isPendingReviewPage ? 'pending' : 'completed'
        const header = {
            "Content-Type": configJSON.apiContentType,
            token:window.localStorage.getItem("authToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFeedbackAndPendingReviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_tasks/tasks/student_assignment_task_list?id=${this.state.assignmentId}&feedback_status=${feedbackStatus}&per_page=10&page=${currentPage}&search=${this.state.searchValue}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getQuizFeedbackAndPendingReview = (currentPage: number) => {
        const feedbackStatus = this.state.isQuizPendingReviewPage ? 'pending' : 'completed'
        const header = {
            "Content-Type": configJSON.apiContentType,
            token:window.localStorage.getItem("authToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuizFeedbackAndPendingReviewApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_tasks/tasks/student_task_list?id=${this.state.assignmentId}&feedback_status=${feedbackStatus}&per_page=10&page=${currentPage}&type=quiz&search=${this.state.searchValue}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    handlePageChange = (event: any, currentPage: number) => {
        this.setState({
            currentPage: currentPage,
        });
        this.getFeedbackAndPendingReview(currentPage);
    }

    viewAnswers  = (studentId: string | number) => {
        setStorageData("prevPagePath", this.state.currentPagePath)
        setStorageData("prevPageId", this.state.currentPageId)
        setStorageData("isOpenViewPage", JSON.stringify((this.state.isPendingReviewPage || this.state.isQuizPendingReviewPage) ? false : true))

        this.props.navigation.navigate(this.state.isQuizPage ? 'QuizAssessementCheck' : 'AssessmentCheck', {student_id: studentId, assignment_id: this.state.assignmentId})
    }

    goToAssignTask = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "AssingnNewTask");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    handleSearch = (value: string) => {
        this.setState({searchValue: value}, () => {
            this.debouncedShowFolderSearch();
        })
    }

    debouncedShowFolderSearch = debounce(() => {
        if(!this.state.isQuizPage){
            this.getFeedbackAndPendingReview(this.state.currentPage)
        }else{
            this.getQuizFeedbackAndPendingReview(this.state.currentPage);
        }
    }, 1000); 
    // Customizable Area End
}
