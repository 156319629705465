import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trashImageIcon } from "../../../assets";
import { Roles } from "../../../../../../components/src/types";

const configJSON = require("../../../config");
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('xs')]: {
      width: '70%',
      padding: theme.spacing(1, 2, 2),
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "10px",
    marginTop: "25px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "24px",
    },
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#202020",
    marginBottom: "5px",
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
      textAlign: 'center'
    },
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#202020",
    fontWeight: 400,
    marginBottom: "35px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
      marginBottom: "20px",
      textAlign: "center"
    },
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF",
    },
    [theme.breakpoints.down('xs')]: {
      width: "45%",
      padding: "10px",
      fontSize: "16px",
    },
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    [theme.breakpoints.down('xs')]: {
      width: "45%",
      padding: "10px",
      fontSize: "16px",
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const DeleteConfirmationModal = (props: any) => {
  const { handleCloseModal,  contextMenuData,deleteLessonFolder,deleteStudentLibrary } = props;  
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const showHeading=()=>{
    if(contextMenuData.itemType==="lesson"){
      return "Lesson"
    }else if(contextMenuData.itemType==="folder"){
      return "Folder"
    }else if(contextMenuData.itemType==="quiz"){
      return "Quiz"
    }else{
      return "Assignment"
    }
  }
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.popupContent}>
        <img src={trashImageIcon} />
        <Typography className={classes.heading}>
          Delete {showHeading()}
        </Typography>
        <Typography className={classes.confirmation}>
          {configJSON.deleteConfirmation}
        </Typography>
        <Typography className={classes.title}>
          {contextMenuData.itemType} "{contextMenuData.name}"?
        </Typography>
      </Box>
      <Box className={classes.btnLayout}>
        <Button
          data-testid="cancelBtn"
          className={classes.cancelBtn}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          data-testid="deleteBtn"
          className={classes.deleteBtn}
          onClick={() => {
            handleCloseModal();
            if(window.localStorage.getItem("role")===Roles.TEACHER){
            deleteLessonFolder(contextMenuData.id,contextMenuData.itemType)
            }else{
            deleteStudentLibrary(contextMenuData.lessonId,contextMenuData.itemType)
            }
          }}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteConfirmationModal;
