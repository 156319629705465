Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notes";
exports.labelBodyText = "Notes Body";

exports.btnExampleTitle = "CLICK ME";
exports.POST="POST";
exports.addNotesApi="bx_block_notes/notes"
exports.apiMethodTypeGet="GET";
exports.getNotesApiEndPoint="bx_block_notes/notes";
exports.PUT="PUT";
exports.DELETE="DELETE";
exports.deleteNoteApiEndPoint="bx_block_notes/notes/delete"
// Customizable Area End