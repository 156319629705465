import React from "react";
import { Box } from "@material-ui/core";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";;
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import DetailReport from "../../../../analytics/src/components/DetailReport.web";

const DetailReportList = (props: any) => {
    const { destination, locations, showStudentListView, redirect } = props;
    const handleClick = () => {
        showStudentListView({
            view: MY_CLASSES_VIEWS.REPORT,
            breadcrumbDisplayName: destination,
            destination: "Detail Report",
        });
    };

    return (
        <Box sx={{ px: "35px" }}>
            <Box sx={{ mt: "30px" }}>
                <CustomBreadcrumb
                    locations={locations}
                    destination={destination}
                    redirect={redirect}
                />
            </Box>
            <Box sx={{ mt: "30px" }}>
                <DetailReport navigation={() => { }} id={"Analytics"} handleClick={handleClick} />
            </Box>
        </Box>
    );
};

export default DetailReportList;
