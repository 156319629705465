import React from "react";
import { Box, Typography } from "@material-ui/core";
import { deleteNotesImage, close_icon } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      right: "10px",
      top: "10px",
    },
  },
  paper: {
    position: "absolute",
    width: "490px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "28px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding: "20px 10px",
    },
  },
  lessonName: {
    borderRadius: "6px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    border: "1px solid rgba(43, 61, 125, 0.12)",
    width: "330px",
    height: "64px",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    marginBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      fontSize: "16px",
      height: "50px",
    },
  },
  deleteheading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#202020",
    fontWeight: 400,
    lineHeight: '28px',
    marginBottom: "20px",
    textAlign: "center",
    width: "399px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
      width: "100%",
    },
  },
  image: {
    marginBottom: "15px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
      width:"100%"
    },
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const DeleteNotesModal = (props: any) => {
  const { handleCloseDeleteModal, deleteNoteApi,lessonName,itemType} = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <>
      <Box sx={modalStyle} className={classes.paper}>
        <img className={classes.closeIcon} src={close_icon} onClick={handleCloseDeleteModal} />
        <Box >
          <Box className={classes.popupContent}>
            <img className={classes.image} src={deleteNotesImage} />
            <Typography className={classes.deleteheading}>
             {`Are you sure you want to delete the notes from this ${itemType}?`}
            </Typography>
            <span className={classes.lessonName}>
              {lessonName}
            </span>
            <Box className={classes.btnLayout}>
              <GenericButton
                type="NORMAL"
                label="Cancel"
                handleClick={handleCloseDeleteModal}
                customStyle={{ width: "150px" }}
              />
              <GenericButton
                data-test-id="delete-btn"
                type="COLORED"
                label="Delete"
                handleClick={deleteNoteApi}
                customStyle={{ width: "150px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};


export default DeleteNotesModal;