import React from "react";
import { Box, Typography } from "@material-ui/core";
import { searchIcon } from "./assets";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: "flex",
    gap: "4px",
    position: "relative",
    marginTop: "20px",
  },
  searchiconbox: {
    position: "absolute",
    top: "13px",
    left: "10px",
  },
  filterinput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      width:'100%'
     },
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "30px",
    color: "#000000",
  },
}));
const SearchSection = (props: any) => {
  const { searchItem, handleSearchInput, searchInput, placeholder } = props;
  const classes = useStyles();

  return (
    <>
      {searchItem && (
        <Typography className={classes.title}>{searchItem}</Typography>
      )}
      <Box className={classes.searchBox}>
        <Box className={classes.searchiconbox}>
          <img src={searchIcon} alt="Search Icon" />
        </Box>
        <input
          className={classes.filterinput}
          placeholder={placeholder ? placeholder : "Search by teacher name"}
          onChange={handleSearchInput}
          value={searchInput}
        />
      </Box>
    </>
  );
};

export default SearchSection;
