import React from "react";
// Customizable Area Start
// Customizable Area End

import LandingPageController, { Props } from "../LandingPageController";
import Banner from "./Banner.web";
import Content from "./Content.web";
import Availabiity from "./Availabiity.web";
import Footer from "./Footer.web";
import Navbar from "../../../../components/src/Navbar.web";

export default class HomePage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.nextBanner = this.nextBanner.bind(this);
    this.prevBanner = this.prevBanner.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <>
        <Navbar aboutUsLink={this.state.aboutUsLink} homeLink={this.state.homeLink} />
        <Banner
          pageCount={this.state.bannerPageCount}
          nextBanner={this.nextBanner}
          prevBanner={this.prevBanner}
          bannerData={this.state.bannerData}
          currentBanner={this.state.currentBanner}
        />
        <Content contentData={this.state.contentData} />
        <Availabiity />
        <Footer
          importantLinks1={this.state.importantLinks1}
          importantLinks2={this.state.importantLinks2}
          importantLinks3={this.state.importantLinks3}
          nonImportantLinks={this.state.nonImportantLinks}
          socialLinks={this.state.socialLinks}
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
