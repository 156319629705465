import React from "react";
import { Box, Typography, Button,Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trashIcon } from "./assets";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: theme.spacing(2, 4, 3),
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "10px",
    marginTop: "25px",
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#202020",
    marginBottom: "5px",
    fontWeight: 400,
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#202020",
    fontWeight: 400,
    marginBottom: "35px",
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF",
    },
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around",
  },
  
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const CustomDeleteModal = (props: any) => {
  const { open, handleClose,contextMenuData,callback,deleteMsg,confirmMsg } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const showHeading=()=>{
    if(contextMenuData.itemType==="stage"){
      return "Stage"
    }
    else if(contextMenuData.itemType==="class"){
      return "Class"
    }
  }
  return (
    <Modal
        open={open}
    >
        <Box style={modalStyle} className={classes.paper}>
        <Box className={classes.popupContent}>
            <img src={trashIcon} />
            <Typography className={classes.heading}>
            Delete {showHeading()}
            </Typography>
            <Typography className={classes.confirmation}>
             {confirmMsg} 
            </Typography>
            <Typography className={classes.confirmation}>
            {deleteMsg}
            </Typography>
            <Typography className={classes.title}>
            {contextMenuData.itemType} " {contextMenuData.name} " ?
            </Typography>
        </Box>
        <Box className={classes.btnLayout}>
            <Button
            data-testid="cancelBtn"
            className={classes.cancelBtn}
            onClick={handleClose}
            >
            Cancel
            </Button>
            <Button
            data-test-id="deleteModalBtn"
            className={classes.deleteBtn}
            onClick={() => {
                handleClose();
                callback(contextMenuData.id,contextMenuData.itemType)
            }}
            >
            Delete
            </Button>
        </Box>
        </Box>
    </Modal>
  );
};

export default CustomDeleteModal;
