Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveStreaming";
exports.labelBodyText = "LiveStreaming Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitle = "CLICK ME";
exports.getLessonStagesApi="/bx_block_cfcoursecreation23/lesson_markups/lesson_markup_tools"
exports.GET="GET"
exports.PUT="PUT"
exports.PATCH = "PATCH";
exports.endLiveLession = "bx_block_cfcoursecreation23/lesson_markups/update_live_lesson"
exports.getStudentNameEndPoint = "bx_block_livestreaming/live_lesson/show_students_name";
exports.showResults = "Show Results";
exports.showNames = "Show Names";
exports.studentName = "Student Name";
exports.question = "Question:";
exports.answer = "Answer:";
exports.kWLChartQuestion = "KWL Chart Question:";
exports.matchingQuestion = "Matching Question";
exports.getWordclouAnswersApiEndPoint="bx_block_livestreaming/live_lesson/word_cloud_submitted_answers"
exports.fetchPollingAnswerApiEndPoint="bx_block_livestreaming/live_lesson/show_polling_question_answers"
exports.getQuizStagesApi = "bx_block_questionbank/quiz_markups/quiz_markup_tools";
exports.getQuizStudentNamesApi ="bx_block_livestreaming/live_quiz/show_name_with_result"
exports.fetchQuizPollingAnswerApiEndPoint="bx_block_content_management/quizs/show_quiz_polling_question_answers"
exports.endLiveQuiz = "bx_block_questionbank/quiz_markups/update_live_quiz"
exports.WebAPIKey=  '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'
exports.WebClienctID = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.webClientId = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.putMethod="PUT"
// Customizable Area End