export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const googleIcon = require("../assets/google.svg")
export const gSlides = require("../assets/gSlides.svg")
export const gSheets = require("../assets/gSheets.svg")
export const folderSvg = require("../assets/gFolder.svg")
export const gArchive = require("../assets/gArchive.svg")
export const gNotes = require("../assets/gNotes.svg")
export const gImage = require("../assets/gImage.svg")
export const gVideo = require("../assets/gVideo.svg")
export const gDocument = require("../assets/gDocument.svg")
export const gMusic = require("../assets/gMusic.svg")
export const gPpt = require("../assets/gPpt.svg")
export const gJson = require("../assets/gJson.svg")
export const gDriveIcon = require("../assets/drive.svg");