import React from "react";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import { Box, Grid } from "@material-ui/core";
import Lesson from "../Teacher/pages/Lesson.web";
import { COURSES_PAGES } from "../../../../components/src/types";
const TeacherCourseCreation = (props: any) => {
  const { handleMcqClick,  handleOpenEndedClick, handleOpenEndedChange, openEndedState, selectedView, redirectToDashboard, stages, addTextStage,addChartStage,lessonName, addMultiMediaStage, updateMultiMediaStage, addWordCloudStage, addMcqStage,addPollStage,addOpenEndedStage,addMatchingQStage,loading,deleteStageCallBack,reorderStage,buttonType,saveModalOpen,getStages,navigation} = props;
  const renderSwitch = (selectedView: string) => {
    if (selectedView === COURSES_PAGES.LESSON || selectedView === COURSES_PAGES.ASSIGNMENT || selectedView === COURSES_PAGES.QUIZ) {
      return (    
        <Lesson
          redirectToDashboard={redirectToDashboard}
          stages={stages}
          addTextStage={addTextStage}
          addMultiMediaStage={addMultiMediaStage}
          addChartStage={addChartStage}
          lessonName={lessonName}
          updateMultiMediaStage={updateMultiMediaStage}
          addWordCloudStage={addWordCloudStage}
          addMcqStage={addMcqStage}
          addPollStage={addPollStage}
          addOpenEndedStage={addOpenEndedStage}
          selectedView={selectedView}
          addMatchingQStage={addMatchingQStage}
          loading={loading}
          deleteStageCallBack={deleteStageCallBack}
          reorderStage={reorderStage}
          buttonType={buttonType}
          saveModalOpen={saveModalOpen}
          getStages={getStages}
          navigation={navigation}
          handleActiveSiled = {props.handleActiveSiled}
          isActive={props.isActive}
          handleOpenEndedChange={handleOpenEndedChange}
          openEndedState={openEndedState}
          handleOpenEndedClick={handleOpenEndedClick}
          isDoublePointActve={props.isDoublePointActve}
          handleDoublePointActive={props.handleDoublePointActive}
          handleMcqClick={handleMcqClick}
          handleMcqChange={props.handleMcqChange}
          mcqSettingState={props.mcqSettingState}
          handleMatchChange={props.handleMatchChange}
          handleMatchClick={props.handleMatchClick}
          handlePollClick={props.handlePollClick}
          handlePollChange={props.handlePollChange}
          matchSetState={props.matchSetState}
          pollSettingState={props.pollSettingState}
          updateQuizMusic={props.updateQuizMusic}
          isMusic={props.isMusic}
        />
      );
    } else {
      return <></>;
    }
  };
  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <AppHeaderWeb title={`Create ${selectedView===COURSES_PAGES.LESSON && "Lesson" || selectedView===COURSES_PAGES.ASSIGNMENT && "Assignment" || selectedView===COURSES_PAGES.QUIZ && "Quiz"}`}  />
          </Grid> 
          <Grid item md={12}>
            {renderSwitch(selectedView)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherCourseCreation;
