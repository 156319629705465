import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { gapi } from 'gapi-script';
import { defaultImage,lessonIcon,quizIcon,assignmentIcon, noClassImg } from "./assets";
import { debounce } from "lodash";
import { CLASS_OPTIONS, COURSES_PAGES, Roles } from "../../../components/src/types";
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider";

interface Class{
  id:string;
  name:string;
  image:any;
}

interface Student {
  message: string;
  data: {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      activated: boolean;
      course: string | null;
      email: string;
      gender: string | null;
      full_phone_number: string;
      teacher_id: number;
      school_admin: string | null;
      grade_id: string | null;
      user_password: string;
      account_type: string | null;
      updated_at: string;
      date_of_birth: string;
      role: string;
      classes: TeacherClass[];
      profile_status: string;
      date_created: string;
    };
  };
  meta: {
    in_progress_classes_count: number;
    completed_classes_count: number;
    in_progress_assignments_count: number;
  };
}

interface TeacherClass {
  id: string;
  type: string;
  attributes: {
    class_name: string;
    class_code: string;
    start_date: string | null;
    end_date: string | null;
    class_status: string;
    created_at: string;
    updated_at: string;
    attachment: string | null;
    full_name: string | null;
    student_count: number | null;
    teacher_id: number;
  };
}

interface Practice {
  id: string;
  name: string;
  status: string;
  stages: number;
  createdOn: string;
  updatedOn: string;
}
interface PracticeData {
  type: string;
  data: Practice[];
}
interface SharedData {
  iconType:string;
  type: string;
  data: Practice[];
}
interface FolderData{
  name:string;
  type:string;
  id:string;
}
interface SuccessBarData{
  message:string;
  type:string;
}

interface CommentData{
  id: string;
  commentDesc: string;
 hozPos:string;
 verPos:string;
}

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  teachersList: any[];
  pageNo: number;
  id:number;
  searchInput: string;
  pageLimit: number;
  loading: boolean;
  currentLocation: string;
  classes: Class[];
  render:string;
  selectedClassId:string;
  selectedTeacherId:string|null;
  practiceData:PracticeData;
  tableSearchInput:string;
  pageNoPractice:number;
  pageLimitPractice:number;
  role:string|null;
  pageNoTeacherClasses:number;
  teacherId:string|null;
  pageNoTeacherClassesLimit:number;
  notesList:any[];
  folderName:FolderData;
  openAddFolderModal:boolean;
  openFolderList:boolean;
  folderAnchorEl:any;
  folderList:any[];
  openMoveConfirmationPopup:boolean;
  folderId:string;
  openMoveSuccessPopup:boolean;
  folderSearchInput:string;
  isLoading:boolean;
  lessons:any[];
  openDeleteConfirmationModal:boolean;
  openNoMobileModal:boolean,
  pageNoShowFolder:number;
  pageNoShowFolderLimit:number;
  showFolderLessonSearch:string;
  showMoveOutOption:boolean;
  stages:any[],
  stagesData: any[],
  stageType:string,
  pageNoStage:number,
  pageStageLimit:number,
  currentStage:any,
  isAnnotate:boolean;
  isAuthenticated:string;
  shareLink:string;
  selectedTab:number;
  sharedData:SharedData
  openAnnotationConfirmation:boolean;
  highlights:any[];
  highlightedData:any[]
  openSuccessBar:boolean;
  successBarData:SuccessBarData;
  showLessons: any;
  isSmallScreen: boolean;
  commentData:CommentData[];
  editLessonName: string;
  editLessonNameValidate: boolean;
  editClassValidate: boolean;
  editLessonObjective: string;
  classList: any[];
  editClassName: string;
  editStartDate: string;
  editDueDate: string;
  editValidStartDateEvent: boolean;
  editValidDueDateEvent: boolean;
  isValidateEditLibrary: boolean;
  openEditModal: boolean;
  editUploadIcon: File;
  isstartDateMax: boolean;
  isCommentModified:boolean;
  isSignedIn:boolean;
  openFileUploadSuccess: boolean;
  isLimitModalOpen: boolean;
  uploading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiTeacherDataCallId: string = "";
  apiTeacherClassesCallId: string = "";
  apiAssignmentsCallId: string = ""
  apiLessonsCallId: string = ""
  apiQuizzesCallId: string = ""
  apiDeleteAssignmentCallId: string = "";
  apiDeleteLessonCallId: string = "";
  apiDeleteQuizCallId: string = "";
  locations: any[];
  getProductApiCallId: any;
  apiGetTeacherLibraryCallId:string="";
  apiGetNotesDataCallId:string="";
  apiCreateLessonFolderCallId:string="";
  apiFolderListApiCallId:string="";
  apiMoveLessonCallId:string="";
  getLessonFolderListApiCallId:string="";
  apiDeleteLessonFolderCallId:string="";
  apiDeleteStudentLessonCallId:string="";
  apiDeleteStudentQuizCallId:string="";
  getLessonListByFolderApiCallId:string="";
  apiMoveOutLessonCallId:string="";
  apiStudentClassListCallId:string="";
  apiGetStagesForLesson:string="";
  apiSharedLessonListingCallId:string="";
  apiSharedQuizListingCallId:string="";
  apiSharedAssignmentListingCallId:string="";
  apiLessonsForStudentCallId:string="";
  apiQuizzesForStudentCallId:string="";
  apiAssignmentsForStudentCallId:string="";
  apiHighlightToTextCallId:string="";
  getQuizFolderListApiCallId:string="";
  apiCreateQuizFolderCallId:string="";
  getAssignmentFolderListApiCallId:string="";
  apiCreateAssignmentFolderCallId:string="";
  deliverLessonApiCallId:string="";
  apiShowLessonCallId:string="";
  apiUpdateLessonCallId:string="";
  apiDownloadPracticeCallId:string="";
  apiDownloadLessonCallIdAdmin:string=""
  apiFoldersForQuizzesApiCallId:string="";
  apiMoveQuizCallId:string="";
  apiFoldersforAssignmentsCallId:string="";
  apiMoveAssignmentCallId:string="";
  apiShowAssignmentFolderCallId:string="";
  apiShowQuizFolderCallId:string="";
  apiMoveOutAssignmentCallId:string="";
  apiMoveOutQuizCallId:string="";
  apiDeleteQuizForTeacherCallId:string="";
  apiDeleteAssignmentForTeacherCallId:string="";
  apiUpdateQuizCallId:string="";
  apiUpdateAssignmentCallId:string="";
  apiShowQuizCallId:string="";
  apiShowAssignmentCallId:string="";
  apiGetStagesForAssignment:string="";
  apiGetStagesForQuiz:string="";
  apiDeleteQuizFolderCallId:string="";
  apiDeleteAssignmentFolderCallId:string="";
  deliverQuizApiId:string="";
  mydownloadApiId:string="";
  apiUpdateQuizFolderCallId:string="";
  apiUpdateAssignmentFolderCallId:string="";
  apiUpdateLessonFolderCallId:string="";
  apiHighlightsForLesson:string="";
  apiHighlightsForQuiz:string="";
  apiHighlightsForAssignment:string="";
  getCommentDataCallId:string="";
  apiEditLibraryCallId:string = "";
  getClassesDataApiCallId: string = ''
  checkAccessForLesson: string = ''
  checkAccessForQuiz: string = ''
  checkAccessForAssignment: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      teachersList: [],
      pageNo: 1,
      searchInput: "",
      id:0,
      pageLimit: 1,
      pageNoPractice: 1,
      pageLimitPractice: 1,
      loading: true,
      currentLocation: "Teachers",
      classes: [],
      render: "Teachers",
      selectedClassId: "",
      selectedTeacherId: "",
      practiceData: {
        type: "",
        data: []
      },
      tableSearchInput:"",
      role:"",
      pageNoTeacherClasses:1,
      teacherId:"",
      pageNoTeacherClassesLimit:1,
      notesList:[],
      folderName:{
        name:"",
        type:"",
        id:""
      },
      openAddFolderModal:false,
      openFolderList:false,
      folderAnchorEl:null,
      folderList:[],
      openMoveConfirmationPopup:false,
      folderId:"",
      openMoveSuccessPopup:false,
      folderSearchInput:"",
      isLoading:false,
      lessons:[],
      openDeleteConfirmationModal:false,
      openNoMobileModal:false,
      pageNoShowFolder:1,
      pageNoShowFolderLimit:1,
      showFolderLessonSearch:"",
      showMoveOutOption:false,
      stages:[],
      stagesData: [],
      stageType:"",
      pageNoStage:0,
      pageStageLimit:1,
      currentStage:{},
      isAnnotate:false,
      isAuthenticated:"false",
      shareLink:"",
      selectedTab:0,
      sharedData: {
        iconType:"",
        type:"",
        data:[]
      },
      openAnnotationConfirmation:false,
      highlights:[],
      highlightedData:[],
      openSuccessBar:false,
      successBarData:{
        message:"",
        type:""
      },
      showLessons: {},
      editLessonName: '',
      editLessonObjective: '',
      classList: [],
      editClassName: 'Select Class',
      editStartDate: '',
      isSmallScreen: window.innerWidth <= 600,
      commentData:[],
      editDueDate: '',
      editLessonNameValidate:false,
      editValidStartDateEvent: false,
      editClassValidate: false,
      isValidateEditLibrary: false,
      isstartDateMax: false,
      editUploadIcon: new File([],''),
      openEditModal: false,
      editValidDueDateEvent: false,
      isCommentModified:false,
      isSignedIn:false,
      openFileUploadSuccess:false,
      isLimitModalOpen: false,
      uploading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.locations = [];
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleClientLoad();
    window.localStorage.removeItem("studentLibraryClassId");
    window.localStorage.removeItem("studentLibraryPracticeType");
    window.localStorage.removeItem("teacherLibraryClassId");
    window.localStorage.removeItem("teacherLibraryPracticeType");
    const currentPath = window.location.pathname;
    const teacherPathRegex = /^\/teacherlibrary\/(Lessons|Quizzes|Assignments)\/(\d+)$/;
    const studentPathRegex = /^\/studentlibrary\/(Lessons|Quizzes|Assignments)\/(\d+)$/;
    const sharedPathRegex = /^\/library\/shared\/(Lessons|Quizzes|Assignments)\/(\d+)$/;
    const match = currentPath.match(sharedPathRegex);
    // Shared Match
    if (match) {
        const item = match[1]
        const id = match[2];
        window.localStorage.setItem("sharedId", id);
        window.localStorage.setItem("item",item)
        const contentID = await getStorageData("sharedId")
        const contentType = await getStorageData("item")
        this.checkPermissionStatus(contentID,contentType)
        this.selectedSharedPractice(contentType)
    }
    // Student Library Match
    const studentLibraryMatch = currentPath.match(studentPathRegex);
    if (studentLibraryMatch) {
      const type = studentLibraryMatch[1]
      const id = studentLibraryMatch[2];
      window.localStorage.setItem("studentLibraryClassId", id);
      window.localStorage.setItem("studentLibraryPracticeType", type);
    }
    // Teacher Library Match
    const teacherLibraryMatch = currentPath.match(teacherPathRegex);
    if (teacherLibraryMatch) {
      const type = teacherLibraryMatch[1]
      const id = teacherLibraryMatch[2];
      const searchInput = window.localStorage.getItem("searchInputClassName") || "";
      window.localStorage.setItem("teacherLibraryClassId", id);
      window.localStorage.setItem("teacherLibraryPracticeType", type);
      this.setState({ searchInput: searchInput });
    }
    // Others
    window.localStorage.removeItem('annotateItems')
    if (window.localStorage.getItem("authToken") === null) {
      window.localStorage.setItem("isShare", this.state.isAuthenticated)
      this.props.navigation.navigate("EmailAccountLogin");
      return;
    }

    let cameFromDashboard = await getStorageData("cameFromDashboard");
    cameFromDashboard = JSON.parse(cameFromDashboard)
    
    if(window.localStorage.getItem("role")===Roles.SUB_ADMIN){
      this.getTeacherList();
      if(cameFromDashboard){
        this.setState({
          selectedTab:1,
          selectedTeacherId: cameFromDashboard.attributes.teacher_id,
          selectedClassId: cameFromDashboard.id,
        })
        this.getTeacherClasses(cameFromDashboard.attributes.teacher_id)
        const loc: any = {
          renderView: "Library",
          breadcrumbDisplayName: cameFromDashboard.attributes.full_name,
        };
        this.pushLocation(loc)
  
        const loc2: any = {
          renderView: "Classes",
          breadcrumbDisplayName: "Classes",
        };
        this.pushLocation(loc2)
  
        this.setState({
          currentLocation: cameFromDashboard.attributes.class_name,
          render:"Selected Class",
          pageNoPractice:1
        });
      }
    }
    if(window.localStorage.getItem("role")===Roles.TEACHER){
      if(window.localStorage.getItem("sharedId")!==null){
        this.setState({
          teacherId:window.localStorage.getItem("id")})
      }else{
        this.setState({selectedTab:0,
          render:"Classes",
          teacherId:window.localStorage.getItem("id")
        })
      }
      this.getTeacherLibraryData(this.state.pageNoTeacherClasses)
    }
    if(window.localStorage.getItem("role")===Roles.STUDENT){
      this.setState({
        render:"My Library",
      })
      this.getStudentClassList()
    }
    this.setState({
      role:window.localStorage.getItem("role")
    })
    this.getClassesData()
    // Customizable Area End
  }

  // Customizable Area Start
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleTeacherListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleTeacherClassesResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleLessonsTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleAssignmentsTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleQuizzesTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeleteTableEntryResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetTeacherLibraryDataResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleNotesForLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleCreateLessonFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleFolderListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleMoveLessonToFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetLessonFolderListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeleteLessonFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeletStudentLesson(apiRequestCallId,responseJson)
      this.handleDeletStudentQuiz(apiRequestCallId,responseJson)
      this.handleGetLessonListByFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleMoveLOutLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleStudentClassListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetStageResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleSharedLessonListingResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleSharedQuizListingResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleSharedAssignmentListingResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleLessonsForStudentTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleQuizzesForStudentTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleAssignmentsForStudentTableResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleAddHighlightToTextResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleQuizFolderListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleAssignmentFolderListResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeliverLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDownloadLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDownloadLessonResponseAdmin(apiRequestCallId,responseJson,errorReponse)
      this.handleDownloadResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetAssignmentListByFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetQuizListByFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeleteQuizResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleDeleteQuizAssignmentFolderResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleHighlightsForLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleCommentDataResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleShowLessonResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleGetClasslistData(apiRequestCallId,responseJson,errorReponse)
      this.handleShowQuizResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleEditMyLibraryAssignment(apiRequestCallId,responseJson,errorReponse)
      this.handleEditMyLibraryQuiz(apiRequestCallId,responseJson,errorReponse)
      this.handleShowAssignmentResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleEditMyLibrary(apiRequestCallId,responseJson,errorReponse)
      this.permissionResponseHandler(responseJson, errorReponse,apiRequestCallId);
      this.setState({
        loading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getTeacherList(page = 1): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTeacherDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetUrl}?page=${page}&per_page=9&full_name=${this.state.searchInput}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTeacherListResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiTeacherDataCallId
    ) {
      if (responseJson.errors) {
        this.setState({
          teachersList: [],
          pageLimit: 1,
        });
        return
      }
      if (responseJson) {
        this.setState({
          teachersList: responseJson.data.map((teacher: any) => {
            return {
              id: teacher.id,
              name: teacher.attributes.full_name,
              image: teacher.attributes.image ? teacher.attributes.image : defaultImage
            }
          }),
          pageLimit: responseJson.meta.total_page,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
 
  handlePageChange(event: any, page: number) {
    this.setState({
      pageNo: page,
    });
    this.getTeacherList(page);
  }
  handlePracticePageChange(event: any, page: number) {
    this.setState({
      pageNoPractice: page,
    });
    if(this.state.role===Roles.SUB_ADMIN){
      this.getPracticeTableData(this.state.currentLocation, page);
    } else{
      this.getPracticeTableDataForStudent(this.state.currentLocation,page)
    }
  }
  
  debouncedSearch = debounce(() => {
    // Perform the search operation here (e.g., make an API request)
    this.getTeacherList();
  }, 1000); // 1000ms delay (adjust as needed)
  handleSearchInput(event: any) {
    this.setState(
      {
        searchInput: event.target.value,
        pageNo: 1,
      },
      () => {
        this.debouncedSearch();
      }
    );
  }
  tableDebouncedSearch = debounce(() => {
    // Perform the search operation here (e.g., make an API request)
    if(this.state.role===Roles.SUB_ADMIN){
      this.getPracticeTableData(this.state.currentLocation);
    } else if(this.state.role===Roles.STUDENT){
    this.getPracticeTableDataForStudent(this.state.currentLocation);

    }
  }, 1000); // 1000ms delay (adjust as needed)
  handleSearchInputTable(event: any) {
    this.setState(
      {
        tableSearchInput: event.target.value,
        pageNoPractice: 1,
      },
      () => {
        this.tableDebouncedSearch();
      }
    );
  }
  
  getTeacherClasses(id: string) {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTeacherClassesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherClasses}?teacher_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  
  handleTeacherClassesResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiTeacherClassesCallId
    ) {
      if (responseJson) {
        if (responseJson.data) {
          const classes = responseJson.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.class_name,
              image: data.attributes.attachment ? data.attributes.attachment : defaultImage,
            };
          });
          this.setState({
            classes: classes,
          });
        } else {
          this.setState({
            classes: [],
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  getClasses(event: any, teacher: any) {
    this.getTeacherClasses(teacher.id)
    const loc: any = {
      renderView: "Library",
      breadcrumbDisplayName: teacher.name,
    };
    this.pushLocation(loc);
    this.setState({
      currentLocation: "Classes",
      render: "Classes",
      selectedTeacherId: teacher.id
    });
  }
  selectedClass(event: any, selectedClass: any) {
    const loc: any = {
      renderView: this.state.role === Roles.SUB_ADMIN ? "Classes" : "My Library",
      breadcrumbDisplayName: this.state.role === Roles.SUB_ADMIN ? "Classes" : "My Library",
    };
    this.pushLocation(loc)
    this.setState({
      currentLocation: selectedClass.name,
      render: "Selected Class",
      selectedClassId: selectedClass.id
    });
  }
  getPracticeTableData(selectedPracticeName: string, page = 1) {
    this.setState({
      loading: true,
      practiceData: { type: "", data: [] },
      pageLimitPractice: 1
    });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (selectedPracticeName === "Lessons") {
      this.apiLessonsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getLessons}?teacher_id=${this.state.selectedTeacherId}&class_id=${this.state.selectedClassId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
      );
    } else if (selectedPracticeName === "Quizzes") {
      this.apiQuizzesCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getQuizzes}?teacher_id=${this.state.selectedTeacherId}&class_id=${this.state.selectedClassId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
      );
    } else {
      this.apiAssignmentsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAssignments}?teacher_id=${this.state.selectedTeacherId}&class_id=${this.state.selectedClassId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleLessonsTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiLessonsCallId
    ) {
      if (responseJson) {
        if (responseJson.data) {
          const data: Practice[] = responseJson.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.lesson_name,
              status: data.attributes.status || "Incomplete",
              stages: data.attributes.total_stages,
              createdOn: data.attributes.created_at,
              updatedOn: data.attributes.updated_at
            };
          });
          const practiceData: PracticeData = {
            type: CLASS_OPTIONS.LESSONS,
            data: data
          }
          this.setState({
            practiceData: practiceData,
            pageLimitPractice: responseJson?.meta.total_page,
          });
        } else {
          this.setState({
            practiceData: { type: "", data: [] },
            pageLimitPractice: 1
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleAssignmentsTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiAssignmentsCallId
    ) {
      if (responseJson) {
        if (responseJson.data) {
          const data: Practice[] = responseJson.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.assignment_name,
              status: data.attributes.status || "Incomplete",
            };
          });
          const practiceData: PracticeData = {
            type: CLASS_OPTIONS.ASSIGNMENTS,
            data: data
          }
          this.setState({
            practiceData: practiceData,
            pageLimitPractice: responseJson.meta.total_page,
          });
        } else {
          this.setState({
            practiceData: { type: "", data: [] },
            pageLimitPractice: 1
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleQuizzesTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiQuizzesCallId
    ) {
      if (responseJson) {
        if (responseJson.data) {
          const data: Practice[] = responseJson.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.quiz_name,
              status: data.attributes.status || "Incomplete",
            };
          });
          const practiceData: PracticeData = {
            type: CLASS_OPTIONS.QUIZZES,
            data: data
          }
          this.setState({
            practiceData: practiceData,
            pageLimitPractice: responseJson.meta.total_page,
          });
        } else {
          this.setState({
            practiceData: { type: "", data: [] },
            pageLimitPractice: 1
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  selectedPractice(selectedPracticeName: string) {
    if(this.state.role===Roles.SUB_ADMIN){
      this.getPracticeTableData(selectedPracticeName)
    } else{
      this.getPracticeTableDataForStudent(selectedPracticeName)
      this.getNotesForLesson();
    }
    const loc: any = {
      renderView: "Selected Class",
      breadcrumbDisplayName: this.state.currentLocation,
    };
    this.pushLocation(loc)
    this.setState({
      currentLocation: selectedPracticeName,
      render: "Selected Practice",
    });
  }
  redirect(renderView: string) {
    const loc = this.peekLocation()
    while (renderView != this.peekLocation().renderView) {
      this.popLocation()
    }
    this.popLocation()
    this.setState({
      currentLocation: loc.breadcrumbDisplayName,
      render: renderView
    });
    this.setState({
      render: renderView,
      tableSearchInput: ""
    });
  }
  pushLocation(location: any) {
    this.locations.push(location);
  }
  popLocation() {
    if (this.locations.length == 0) return "Underflow";
    return this.locations.pop();
  }
  peekLocation() {
    return this.locations[this.locations.length - 1];
  }
  deleteTableData(id: string) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (this.state.currentLocation === CLASS_OPTIONS.LESSONS) {
      this.apiDeleteLessonCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteLesson}?id=${id}`
      );
    } else if (this.state.currentLocation === CLASS_OPTIONS.QUIZZES) {
      this.apiDeleteQuizCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteQuiz}?id=${id}`
      );
    } else {
      this.apiDeleteAssignmentCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteAssignment}?id=${id}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDeleteTableEntryResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiDeleteAssignmentCallId ||
        apiRequestCallId === this.apiDeleteLessonCallId ||
        apiRequestCallId === this.apiDeleteQuizCallId)
    ) {
      if (responseJson.id) {
        const { data } = this.state.practiceData;
        const updatedData = data.filter(item => parseInt(item.id) !== responseJson.id);
        this.setState({
          practiceData: {
            ...this.state.practiceData,
            data: updatedData,
          }
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  getTeacherLibraryData(page:number) {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTeacherLibraryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCurrentUserClasses}?per_page=9&class_name=${this.state.searchInput}&page=${page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGetTeacherLibraryDataResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiGetTeacherLibraryCallId
      ){
        if(responseJson.message){
          this.setState({
              classes:[],
              pageNoTeacherClassesLimit: 0
          });
          return
        }
        if(responseJson.data[0]){
          const classList = responseJson.data.map((data: any)=>{
            return{
              id:data.id,
              name:data.attributes.class_name,
              image:data.attributes.attachment?data.attributes.attachment:noClassImg,
            }
          });
          this.setState({
            classes:classList,
            pageNoTeacherClassesLimit: responseJson.meta.total_page
          }, () => {
            this.handleNavigatePractice();
          });
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
       }
    }
    handleTeacherClassPageChange(event: any, page: number) {
      this.setState({
        pageNoTeacherClasses: page,
      });
      this.getTeacherLibraryData(page);
    }
    selectedPracticeForTeacherRole(event:any,selectedClass:any){
      const loc: any = {
        renderView: "Library",
        breadcrumbDisplayName: "Library",
      };
      this.pushLocation(loc)
      const loc2: any = {
        renderView: "Library",
        breadcrumbDisplayName: "My Library",
      };
      this.pushLocation(loc2)
      this.setState({
        currentLocation: selectedClass.name,
        render:"Selected Class",
        selectedClassId:selectedClass.id
      });
  window.localStorage.setItem("teacherId",selectedClass.id)
  window.localStorage.setItem("assignmentteacherId",selectedClass.id)
  }
  redirectForTeacherRole(renderView:string){
    const [loc3, loc2, loc1] = this.locations.slice(-3);

    if(renderView==="Library"){
      this.locations=[]
      this.setState({
        render:"Classes",
        selectedTab:0
      });
    } 
    if (renderView === "Selected Class") {
      if (renderView !== loc1.renderView && loc1.renderView !== "folder view") {
        const tempLoc = this.locations.slice(0, 2);
        this.locations = tempLoc
        this.setState({
          currentLocation: loc2.breadcrumbDisplayName,
          render: renderView
        });
      } else if (loc1.renderView === "folder view") {
        const tempLoc = this.locations.slice(0, 2);
        this.locations = tempLoc
        this.setState({
          currentLocation: loc3.breadcrumbDisplayName,
          render: renderView
        })
      }
        else{
          const loc = this.peekLocation()
          this.popLocation()
          this.setState({
            currentLocation: loc.breadcrumbDisplayName,
            render:renderView
          });
    
          this.setState({
            render:renderView,
            tableSearchInput:""
          });
        }
      }
      if(renderView==="Selected Practice"){
        if(renderView!==loc1.renderView){
          const tempLoc=this.locations.slice(0,3);
          this.locations=tempLoc
          this.setState({
            currentLocation: loc2.breadcrumbDisplayName,
            render:renderView
          });
        }else{
          const loc = this.peekLocation()
          this.popLocation()
          this.setState({
            currentLocation: loc.breadcrumbDisplayName,
            render:renderView
          });
          this.removeComment()
          this.setState({
            render:renderView,
            tableSearchInput:"",
            commentData:[]
          });
        }
      }
      if(renderView==="Public Library"){
        this.locations=[]
        this.setState({
          render:"Classes",
          selectedTab:1,
          tableSearchInput:""
        });
      }
      if(renderView==="folder view"){
        const loc = this.peekLocation()
        this.popLocation()
        this.setState({
          currentLocation: loc.breadcrumbDisplayName,
          render:"Selected folder"
        });
      }
     
  }
  removeComment = () => {
    const iconElement = document.getElementById('canvasContainer')
      if (iconElement) {
        iconElement.remove();
      }
  }
  selectedPracticeForTeacher(selectedPracticeName:string){
      this.getLessonFolderListByClass(selectedPracticeName)
      this.getNotesForLesson()
    const loc: any = {
      renderView: "Selected Class",
      breadcrumbDisplayName: this.state.currentLocation,
    };
    this.pushLocation(loc)
      this.setState({
        currentLocation: selectedPracticeName,
        render:"Selected Practice",
      });
  }
  
  selectedSharedPractice(selectedPracticeName:string){
    this.getSharedPracticeListing(selectedPracticeName)
    const loc: any = {
      renderView: "Library",
      breadcrumbDisplayName: "Library",
    };
    this.pushLocation(loc)
    const loc2: any = {
      renderView: "Public Library",
      breadcrumbDisplayName: "Public Library",
    };
    this.pushLocation(loc2)
      this.setState({
        currentLocation: selectedPracticeName,
        render:"SharedSelectedPractice",
        pageNoPractice:1
      });
    
  }
  debouncedClassSearch = debounce(() => {
    this.getTeacherLibraryData(this.state.pageNoTeacherClasses);
  }, 1000);
  handleClassSearchInput = (value:any) => {
    this.setState({
          searchInput:value,
        pageNoTeacherClasses: 1,
      },
      () => {
        this.debouncedClassSearch();
      }
    )
};
handleLessonPageChange(event: any, page: number) {
  this.setState({
    pageNoPractice: page,
  });
  this.getLessonFolderListByClass(this.state.currentLocation,page);
}
debouncedSearchPractice = debounce(() => {
  this.getLessonFolderListByClass(this.state.currentLocation);
}, 1000); 
handleSearchInputTableForTeacher(value:any) {
  this.setState(
    {
      tableSearchInput: value,
      pageNoPractice: 1,
    },
    () => {
      this.debouncedSearchPractice();
    }
  );
}
getNotesForLesson(): boolean {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetNotesDataCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getNotesApiEndPoint}`
  );

  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleNotesForLessonResponse(apiRequestCallId:string,responseJson:any,errorReponse:any){
  if (
apiRequestCallId != null &&
apiRequestCallId === this.apiGetNotesDataCallId
) {

if (responseJson) {
  this.setState({
    notesList: responseJson.data.map((note:any)=>{
      return {
        id:note.id,
        lessonId:note.attributes.lesson_id,
        note:note.attributes.note,
      }
    },
    ),
  });
} else {
  this.parseApiErrorResponse(responseJson);
}
this.parseApiCatchErrorResponse(errorReponse);
}
}
handleShowNotesIcon=()=>{
  this.getNotesForLesson()
}

handleChangeFolderInput=(data:any)=>{
  this.setState(
    (prevState) => ({
      folderName: {
        ...prevState.folderName,
        name: data
      },
    })
  );
}

handleOpenAddFolderModal=(name:string,type:string,id:string)=>{
  this.setState({
    openAddFolderModal:true,
    folderName:{
      name:name,
      type:type,
      id:id
    }
  })
}
handleCloseAddFolderModal=()=>{
  this.setState({
    openAddFolderModal:false,
    folderName:{
      name:"",
      type:"",
      id:""
    }
  })
}
createLessonFolder(data:string) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(this.state.currentLocation===CLASS_OPTIONS.LESSONS){
    const body = {
      folder_name:data,
      class_id:this.state.selectedClassId
    }
    
    this.apiCreateLessonFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createLessonFolder}`
    );
  }else if(this.state.currentLocation===CLASS_OPTIONS.QUIZZES){
    const body = {
      folder_name:data,
      teacher_class_id:this.state.selectedClassId
    }
    this.apiCreateQuizFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createQuizFolder}`
    );
  } else{
    const body = {
      folder_name:data,
      teacher_class_id:this.state.selectedClassId
    }
    this.apiCreateAssignmentFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createAssignmentFolder}`
    );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.POST
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleCreateLessonFolderResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiCreateLessonFolderCallId || apiRequestCallId === this.apiCreateQuizFolderCallId || apiRequestCallId===this.apiCreateAssignmentFolderCallId || apiRequestCallId===this.apiUpdateQuizFolderCallId || apiRequestCallId===this.apiUpdateAssignmentFolderCallId||apiRequestCallId===this.apiUpdateLessonFolderCallId)
  ) {
    if (responseJson.data) {
     this.handleCloseAddFolderModal()
     this.getLessonFolderListByClass(this.state.currentLocation)
    } else if(responseJson.error === 'Request size exceeds the 2 GB limit.'){
      this.enableLimitSnackbar();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleOpenFolderList=(event:any)=>{
  this.setState({
    openFolderList:true,
    folderAnchorEl:event.currentTarget
  })
  this.getFolderList()
}
handleCloseFolderList=()=>{
  this.setState({
    openFolderList:false
  })
}
getFolderList() {
  this.setState({
    isLoading: true,
  });
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(this.state.currentLocation==="Lessons"){
    this.apiFolderListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFolderList}?class_id=${this.state.selectedClassId}&search=${this.state.folderSearchInput}`
    );
  } else if(this.state.currentLocation==="Quizzes"){
    this.apiFoldersForQuizzesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFoldersforQuizzes}?class_id=${this.state.selectedClassId}&search=${this.state.folderSearchInput}`
    );
  } else{
    this.apiFoldersforAssignmentsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.getFoldersforAssignments}?class_id=${this.state.selectedClassId}&search=${this.state.folderSearchInput}`
);
  }
 
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleFolderListResponse(apiRequestCallId:string,responseJson:any,errorReponse:any){
  if (
apiRequestCallId != null &&
(apiRequestCallId === this.apiFolderListApiCallId || apiRequestCallId === this.apiFoldersForQuizzesApiCallId || apiRequestCallId===this.apiFoldersforAssignmentsCallId)
) {
  this.setState({
    isLoading:false
  })
if (responseJson.data) {
  this.setState({
    folderList: responseJson.data.map((folder:any)=>{
      return {
        id:folder.id,
        name:folder.attributes.folder_name,
      }
    }),
  });
} else {
  this.parseApiErrorResponse(responseJson);
}
this.parseApiCatchErrorResponse(errorReponse);
}
}

handleOpenMoveConfirmationPopUp = ()=>{
  this.setState({
    openMoveConfirmationPopup:true
  })
}
handleCloseMoveConfirmationPopUp = ()=>{
  this.setState({
    openMoveConfirmationPopup:false,
    folderId:""
  })
}
handleSetFolderId=(data:string)=>{
this.setState({
  folderId:data
})
}
handleOpenSuccessPopup=()=>{
  this.setState({
    openMoveSuccessPopup:true
  })
}
handleCloseSuccessPopup=()=>{
  this.setState({
    openMoveSuccessPopup:false
  })
  if(this.state.currentLocation==="Documents"){
    this.redirectForTeacherRole("Selected Practice")
  }else{
    this.handleCloseFolderList()
    this.getLessonFolderListByClass(this.state.currentLocation)
  }
}
moveLessonToFolder(folderId:any,lessonId:any) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(this.state.currentLocation==="Lessons"){
  const body = {
    lesson_folder_id:folderId,
    lesson_id:lessonId
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
    this.apiMoveLessonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveLessonToFolder}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
  }else if(this.state.currentLocation==="Quizzes"){
    const body = {
      quiz_folder_id:folderId,
      quiz_id:lessonId
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiMoveQuizCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveQuizToFolder}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
  }else{
    const body = {
      assignment_folder_id:folderId,
      assignment_id:lessonId
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiMoveAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveAssignmentToFolder}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
  }
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleMoveLessonToFolderResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiMoveLessonCallId || apiRequestCallId === this.apiMoveQuizCallId || apiRequestCallId ===this.apiMoveAssignmentCallId)
  ) {
    if (responseJson.data) {
     this.handleCloseMoveConfirmationPopUp()
     this.handleOpenSuccessPopup()
    } else if(responseJson.error === 'Request size exceeds the 2 GB limit.'){
      this.enableLimitSnackbar();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
getLessonFolderListByClass(selectedPracticeName:string,page=1) {
  this.setState({
    loading: true,
    practiceData: { type: "", data: [] },
    pageLimitPractice: 1
  });
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(selectedPracticeName==="Lessons"){
  this.getLessonFolderListApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getLessonFolderList}?class_id=${this.state.selectedClassId}&teacher_id=${this.state.teacherId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
  );
  }else if(selectedPracticeName==="Quizzes"){
    this.getQuizFolderListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuizFolderList}?class_id=${this.state.selectedClassId}&teacher_id=${this.state.teacherId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
    );
    }else{
      this.getAssignmentFolderListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssignmentFolderListing}?class_id=${this.state.selectedClassId}&teacher_id=${this.state.teacherId}&search=${this.state.tableSearchInput}&page=${page}&per_page=10`
    );
    }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleGetLessonFolderListResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.getLessonFolderListApiCallId)
  ) {
    if(responseJson.errors){
      this.setState({
        practiceData:{
          type:"",
          data:[]
        },
        pageLimitPractice: 0
      });
      return
    }
    if (!responseJson.errors && responseJson) {
      const folders = responseJson.lesson_folders.data.map((folder:any) => ({
        itemType: 'folder',
        id: folder.id,
        name: folder.attributes.folder_name,
        createdAt: folder.attributes.created_at,
        updatedAt: folder.attributes.updated_at
    }));
    
    const lessons = responseJson.lessons.data.map((lesson:any) => ({
        itemType: 'lesson',
        id: lesson.id,
        name: lesson.attributes.lesson_name,
        totalStage:lesson.attributes.total_stages,
        createdAt: lesson.attributes.created_at,
        updatedAt: lesson.attributes.updated_at,
        shared:lesson.attributes.shared
    }));
    
   
    const combinedArray = [...folders, ...lessons];
    const practiceData:PracticeData={
      type:CLASS_OPTIONS.LESSONS,
      data:combinedArray
    }
    this.setState({
      practiceData: practiceData,
      pageLimitPractice: responseJson.meta.total_pages,
    });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
folderNameDebouncedSearch = debounce(() => {
  this.getFolderList();
}, 1000); 
handleFolderNameSearchInput(event: any) {
  this.setState(
    {
      folderSearchInput: event.target.value,
    },
    () => {
      this.folderNameDebouncedSearch();
    }
  );
}
deleteLessonFolder(id:string,type:string){
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(type==="lesson" || type==="folder" && (this.state.currentLocation==="Lessons")){
    this.apiDeleteLessonFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteLessonFolder}?id=${id}&type=${type}`
    );
  }else if(type==="quiz"){
      this.apiDeleteQuizForTeacherCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteQuiz}?id=${id}`
      );
  }else if(type==="assignment"){
    this.apiDeleteAssignmentForTeacherCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAssignment}?id=${id}`
    );
}else if(type==="folder" && this.state.currentLocation==="Quizzes"){
  this.apiDeleteQuizFolderCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteFolderForQuiz}?quiz_folder_id=${id}`
  );
}else if(type==="folder" && this.state.currentLocation==="Assignments"){
  this.apiDeleteAssignmentFolderCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteAssignmentFolder}?assignment_folder_id=${id}`
  );
}
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.DELETE
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}


deleteStudentLibrary(lessonId:string,type:string){
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(type==="lesson" || type==="folder" && (this.state.currentLocation==="Lessons")){
      this.apiDeleteStudentLessonCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteStudentLesson}?id=${lessonId}`
      );
    }else if(type==="quiz"){
      this.apiDeleteStudentQuizCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteStudentQuiz}?id=${lessonId}`
      );
  }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
handleDeleteLessonFolderResponse(apiRequestCallId:string,responseJson:any,errorReponse:any){
  if (
    apiRequestCallId != null && (apiRequestCallId === this.apiDeleteLessonFolderCallId)
  ) {
    if (responseJson.message==="Delete successfully") {
      if(this.state.currentLocation==="Lessons"){
        this.setState({
          openSuccessBar:true,
          // pageNoPractice:1,
          // page:
          tableSearchInput:"",
          successBarData:{
            message:"Deleted successfully!",
            type:"success"
          }
        })
        this.getLessonFolderListByClass(this.state.currentLocation,this.state.pageNoPractice)
      }else{
        this.setState({
          pageNoShowFolder:1,
          showFolderLessonSearch:""
        })
        this.getLessonListByFolder(this.state.folderId,1)
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

handleDeletStudentLesson(apiRequestCallId:string,responseJson:Student){
  if (
     (apiRequestCallId === this.apiDeleteStudentLessonCallId)
  ) {
        this.getPracticeTableDataForStudent(this.state.currentLocation)
        
    
  }
}


handleDeletStudentQuiz(apiRequestCallId:string,responseJson:Student){
  if (
     (apiRequestCallId === this.apiDeleteStudentQuizCallId)
  ) {    
     this.getPracticeTableDataForStudent(this.state.currentLocation)
        
  }
}


showLessonsForSelectedFolder(folderName:string,id:string){
  this.getLessonListByFolder(id,1)
  this.getNotesForLesson()
  const loc: any = {
    renderView: "Selected Practice",
    breadcrumbDisplayName: this.state.currentLocation,
  };
  this.pushLocation(loc)
  this.setState({
    currentLocation: folderName,
    render:"Selected folder",
    showMoveOutOption:true
  });
}
getLessonListByFolder(id:string,page:number) {
  this.setState({
    loading: true,
  });
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(this.state.practiceData.type==="Lessons"){
    this.getLessonListByFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLessonListByFolder}?lesson_folder_id=${id}&search=${this.state.showFolderLessonSearch}&page=${page}&per_page=10`
    );
  }else if(this.state.practiceData.type==="Assignments"){
    this.apiShowAssignmentFolderCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.showAssignmentFolder}?assignment_folder_id=${id}&search=${this.state.showFolderLessonSearch}&page=${page}&per_page=10`
  );
  }else{
    this.apiShowQuizFolderCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.showQuizFolder}?quiz_folder_id=${id}&search=${this.state.showFolderLessonSearch}&page=${page}&per_page=10`
  );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleGetLessonListByFolderResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.getLessonListByFolderApiCallId)
  ) {
    if (responseJson) {
    const lessons = responseJson.data.attributes.lessons.data.map((lesson:any) => ({
        itemType: 'lesson',
        id: lesson.id,
        name: lesson.attributes.lesson_name,
        totalStage:lesson.attributes.total_stages,
        createdAt: lesson.attributes.created_at,
        updatedAt: lesson.attributes.updated_at,
    }));    
    this.setState({
      lessons: lessons,
      folderId: responseJson.data.id,
      pageNoShowFolderLimit:responseJson.meta.total_page
    });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
moveOutLessonApi(id:any,type:string) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(type==="lesson"){
    const body = {
      lesson_folder_id:this.state.folderId,
      lesson_id:id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiMoveOutLessonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveOutLesson}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
  } else if(type==="quiz"){
    const body = {
      quiz_folder_id:this.state.folderId,
      quiz_id:id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiMoveOutQuizCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveOutQuiz}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
  }else{
    const body = {
      assignment_folder_id:this.state.folderId,
      assignment_id:id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiMoveOutAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.moveOutAssignment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
  }
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleMoveLOutLessonResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiMoveOutLessonCallId || apiRequestCallId===this.apiMoveOutQuizCallId || apiRequestCallId===this.apiMoveOutAssignmentCallId)
  ) {
    if (responseJson.data) {
      this.redirectForTeacherRole("Selected Practice")
      this.getLessonFolderListByClass(this.state.currentLocation)
    } else if(responseJson.error === 'Request size exceeds the 2 GB limit.'){
      this.enableLimitSnackbar();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleCloseDeleteModal = () => {
  this.setState({
    openDeleteConfirmationModal:false
  })
};
handleOpenDeleteModal = () => {
  this.setState({
    openDeleteConfirmationModal:true
  })
};

handleCloseNoMobileModal = () => {
  this.setState({
    openNoMobileModal:false
  })
};
handleOpenNoMobileModal = () => {
  this.setState({
    openNoMobileModal:true
  })
};

handleShowFolderPageChange(event: any, page: number) {
  this.setState({
    pageNoShowFolder: page,
  });
  this.getLessonListByFolder(this.state.folderId,page);
}
debouncedShowFolderSearch = debounce(() => {
  this.getLessonListByFolder(this.state.folderId,this.state.pageNoShowFolder);
}, 1000); 
handleFolderLessonSearchInput(value: any) {
  this.setState(
    {
      showFolderLessonSearch: value,
      pageNoShowFolder: 1,
    },
    () => {
      this.debouncedShowFolderSearch();
    }
  );
}
getStudentClassList(): boolean {
  this.setState({
    isLoading:true
  })
  let id = window.localStorage.getItem("id")
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiStudentClassListCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getStudentClasslist}?student_id=${id}&search=${this.state.searchInput}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleStudentClassListResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiStudentClassListCallId
  ) {
    if(responseJson.message){
      this.setState({
          classes:[],
      });
      return
    }
    if(responseJson.data){
        const classList = responseJson.data.attributes.classes.map((data: any) => {
          return {
            id:data.id,
            name:data.attributes.class_name,
            image:data.attributes.attachment?data.attributes.attachment:noClassImg,
          }
        });
        this.setState({
          classes:classList,
          selectedTeacherId:responseJson.data.attributes.teacher_id
        }, () => {
          this.handleNavigatePractice();
        });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
selectedLessons(id:string,value:boolean,type:string){
  this.setState({stageType:"",currentStage:{},pageNoStage:0,pageStageLimit:1})
  this.getStages(id,type)
  this.getHighlights(type)
  let location;
  if(this.state.render==="Selected folder"){
     location = {
      renderView: "folder view",
      breadcrumbDisplayName: this.state.currentLocation,
    };
  }else{
    location = {
    renderView: "Selected Practice",
    breadcrumbDisplayName: this.state.currentLocation,
  }
  };
  this.pushLocation(location)
  this.setState({
    currentLocation: "Documents",
    render:"Selected Lesson",
    isAnnotate:value
  });
}
getStages(id:string,type:string){
  this.setState({
    loading:true
  })
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(type==="lesson"){
    this.apiGetStagesForLesson = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStagesApi}?lesson_id=${id}`
    );
  }else if(type==="assignment"){
    this.apiGetStagesForAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssignmentStages}?assignment_id=${id}`
    );
  }else{
    this.apiGetStagesForQuiz=requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuizStages}?quiz_id=${id}`
    );
  }
    
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
 requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

convertArray = (arr:any) => {
  let count = 1;
  let suffixes:any = {};

  return arr.map((item:any) => {
    const baseNumber = item[0];
    const suffix = item.slice(1);

    if (suffix && isNaN(suffix)) {
      if (!suffixes[baseNumber]) {
        suffixes[baseNumber] = 0;
      }
      const newSuffix = String.fromCharCode('A'.charCodeAt(0) + suffixes[baseNumber]);
      suffixes[baseNumber]++;
      return `${count - 1}${newSuffix}`;
    } else {
      suffixes[baseNumber] = 0;
      return (count++).toString();
    }
  });
};


handleGetStageResponse( apiRequestCallId: string,
  responseJson: any,
  errorReponse: string){
    if(apiRequestCallId != null && (apiRequestCallId === this.apiGetStagesForLesson || apiRequestCallId === this.apiGetStagesForAssignment || apiRequestCallId === this.apiGetStagesForQuiz) ){
     
      if (responseJson.data) {
        const updatedStageNumArr=responseJson.data.map((obj:any)=>obj.attributes.stage_number.toString())
        const stages = responseJson.data.map((obj:any,index:number) => ({
          id: obj.id,
          title: obj.attributes?.title,
          stageNumber: this.convertArray(updatedStageNumArr)[index],
          stageType: obj.attributes?.lesson_stage_name || obj.attributes?.assignment_stage_name || obj.attributes?.quiz_stage_name,
          attributes: obj.attributes,
          type: obj.type,
        }));
        this.setState({
          stages:stages,
          stagesData: responseJson
        }, () => {          
          if (this.state.stages.length > 0) {
            this.previewCurrentStage(0); 
          }
        });
      }
     
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
}

 removeCommentIcon = (val: any, currentPage:number) => {
  const iconElement = document.querySelector(`.stage-${val.stageId}`)
    if (iconElement) {
      iconElement.remove();
    }
}

nextStage() {
  if (this.state.pageNoStage < this.state.stages.length - 1) {
    this.setState((prevState) => ({
      pageNoStage: prevState.pageNoStage + 1,
    }), () => {
      this.state.commentData.forEach((obj) => this.removeCommentIcon(obj, this.state.pageNoStage));
      this.previewCurrentStage(this.state.pageNoStage);
    });
  }
}

prevStage() {
  if (this.state.pageNoStage > 0) {
    this.setState((prevState) => ({
      pageNoStage: prevState.pageNoStage - 1,
    }), () => {
      this.state.commentData.forEach((obj) => this.removeCommentIcon(obj, this.state.pageNoStage));
      this.previewCurrentStage(this.state.pageNoStage);
    });
  }
}

previewCurrentStage(stageIndex:number) {
  const stage = this.state.stages[stageIndex];
  if (stage) {
    this.setState({
      currentStage: stage,
      stageType: stage.stageType,
    });
    this.getCommentData(stage.id);
  }
}

generateLink = (id:string) => {
  const baseUrl = `${window.location.protocol}//${window.location.hostname}/library/shared/${this.state.currentLocation}/${id}`;
  this.setState({
    shareLink:baseUrl
  })
}
getSharedPracticeListing(selectedPracticeName:string,page = 1): boolean {
  this.setState({
    loading: true,
  });
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(selectedPracticeName==='Lessons'){
    this.apiSharedLessonListingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSharedListing}?search=${this.state.tableSearchInput}&lesson[per_page]=10&lesson[page]=${page}`
    );
  }else if (selectedPracticeName === "Quizzes") {
    this.apiSharedQuizListingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSharedListing}?search=${this.state.tableSearchInput}&quiz[per_page]=10&quiz[page]=${page}`
    );
  } else {
    this.apiSharedAssignmentListingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSharedListing}?search=${this.state.tableSearchInput}&assignment[per_page]=10&assignment[page]=${page}`
    );
  }

  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleSharedLessonListingResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiSharedLessonListingCallId
  ) {
    if (responseJson) {
        if(responseJson?.lessons){
          const data: Practice[] = responseJson?.lessons.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.lesson_name,
              createdOn: data.attributes.created_at,
              type:"lesson"
            };
          });
          const practiceData: SharedData = {
            iconType:lessonIcon,
            type: CLASS_OPTIONS.LESSONS,
            data: data
          }
          this.setState({
            sharedData: practiceData,
            pageLimitPractice: responseJson.lessons.meta.total_pages,
          });
        }else {
          this.setState({
            sharedData: { iconType:"",type: "", data: [] },
            pageLimitPractice: 1
          });
        }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleSharedQuizListingResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiSharedQuizListingCallId
  ) {
    if (responseJson) {
        if(responseJson?.quizzes){
          const data: Practice[] = responseJson?.quizzes.data.map((data: any) => {
            return {
              id: data.id,
              name: data.attributes.quiz_name,
              createdOn: data.attributes.created_at,
              type:"quiz"
            };
          });
          const practiceData: SharedData = {
            iconType: quizIcon,
            type: CLASS_OPTIONS.QUIZZES,
            data: data
          }
          this.setState({
            sharedData: practiceData,
            pageLimitPractice: responseJson.quizzes.meta.total_pages,
          });
        }else {
          this.setState({
            sharedData: { iconType:"",type: "", data: [] },
            pageLimitPractice: 1
          });
        }
      
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleSharedAssignmentListingResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiSharedAssignmentListingCallId
  ) {
    if (responseJson) {
          if(responseJson?.assignments){
            const data: Practice[] = responseJson?.assignments.data.map((data: any) => {
              return {
                id: data.id,
                name: data.attributes.assignment_name,
                createdOn: data.attributes.created_at,
                type:"assignment"
              };
            });
            const practiceData: SharedData = {
              iconType:assignmentIcon,
              type: CLASS_OPTIONS.ASSIGNMENTS,
              data: data
            }
            this.setState({
              sharedData: practiceData,
              pageLimitPractice: responseJson.assignments.meta.total_pages,
            });
          }else {
            this.setState({
              sharedData: { iconType:"",type: "", data: [] },
              pageLimitPractice: 1
            });
          }
      
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
debouncedSharedSearchPractice = debounce(() => {
  this.getSharedPracticeListing(this.state.currentLocation);
}, 1000); 
handleSharedSearchInputTableForTeacher(value:string) {
  this.setState(
    {
      tableSearchInput: value,
      pageNoPractice: 1,
    },
    () => {
      this.debouncedSharedSearchPractice();
    }
  );
}
handleSharedtablePageChange(event: any, page: number) {
  this.setState({
    pageNoPractice: page,
  });
  this.getSharedPracticeListing(this.state.currentLocation,page);
}
getPracticeTableDataForStudent(selectedPracticeName: string, page = 1) {
  this.setState({
    loading: true,
    sharedData: { iconType:"",type: "", data: [] },
    pageLimitPractice: 1
  });
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if (selectedPracticeName === "Lessons") {
    this.apiLessonsForStudentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLessonsForStudent}?class_id=${this.state.selectedClassId}&per_page=10&search=${this.state.tableSearchInput}&page=${page}`
    );
  } else if (selectedPracticeName === "Quizzes") {
    this.apiQuizzesForStudentCallId = requestMessage.messageId;
      requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuizzesForStudent}?teacher_classes_id=${this.state.selectedClassId}&per_page=10&search=${this.state.tableSearchInput}&page=${page}`
      );
  }else {
    this.apiAssignmentsForStudentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssignmentsForStudent}?teacher_classes_id=${this.state.selectedClassId}&per_page=10&search=${this.state.tableSearchInput}&page=${page}`
    );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleLessonsForStudentTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiLessonsForStudentCallId
  ) {
    if (responseJson) {
      if (responseJson.data) {
        const data: Practice[] = responseJson.data.map((data: any) => {
          return {
            itemType: 'lesson',
            lessonId: data.id,
            shared:data.attributes.lesson.data.attributes.shared,
            id: data.attributes.lesson.data.id,
            name: data.attributes.lesson.data.attributes.lesson_name,
            stages: data.attributes.lesson.data.attributes.total_stages,
            createdOn: data.attributes.lesson.data.attributes.created_at,
            updatedOn: data.attributes.lesson.data.attributes.updated_at
          };
        });
        const practiceData: SharedData = {
          iconType:lessonIcon,
          type: CLASS_OPTIONS.LESSONS,
          data: data
        }
        this.setState({
          sharedData: practiceData,
          pageLimitPractice: responseJson.meta.total_page,
        });
      } else {
        this.setState({
          sharedData: { iconType:"",type: "", data: [] },
          pageLimitPractice: 1
        });
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleQuizzesForStudentTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiQuizzesForStudentCallId
  ) {
    if (responseJson) {
      if (responseJson.data) {
        const data: Practice[] = responseJson.data.map((data: any) => {
          return {
            itemType: 'quiz',
            id: data.attributes.quiz.data.id,
            lessonId: data.id,
            name: data.attributes.quiz.data.attributes.quiz_name,
            stages: data.attributes.quiz.data.attributes.total_stage,
            createdOn: data.attributes.quiz.data.attributes.created_at,
            updatedOn: data.attributes.quiz.data.attributes.updated_at
          };
        });
        const practiceData: SharedData = {
          iconType:quizIcon,
          type: CLASS_OPTIONS.QUIZZES,
          data: data
        }
        this.setState({
          sharedData: practiceData,
          pageLimitPractice: responseJson.meta.total_page,
        });
      } else {
        this.setState({
          sharedData: { iconType:"",type: "", data: [] },
          pageLimitPractice: 1
        });
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleAssignmentsForStudentTableResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiAssignmentsForStudentCallId
  ) {
    if (responseJson) {
      if (responseJson.data) {
        const data: Practice[] = responseJson.data.map((data: any) => {
          return {
            itemType: 'assignment',
            id: data.attributes.assignment.data.id,
            name: data.attributes.assignment.data.attributes.assignment_name,
            stages: data.attributes.assignment.data.attributes.total_stage,
            createdOn: data.attributes.assignment.data.attributes.created_at,
            updatedOn: data.attributes.assignment.data.attributes.updated_at
          };
        });
        const practiceData: SharedData = {
          iconType:assignmentIcon,
          type: CLASS_OPTIONS.ASSIGNMENTS,
          data: data
        }
        this.setState({
          sharedData: practiceData,
          pageLimitPractice: responseJson.meta.total_page,
        });
      } else {
        this.setState({
          sharedData: { iconType:"",type: "", data: [] },
          pageLimitPractice: 1
        });
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

addHighlightToText(data:any,type:string) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let body;
    if(type === "lesson_markup_tool"){
      body = {
        lesson_markup_tools_id:data.lesson_markup_tools_id,
        highlight_text: data.highlight_text,
        colour_code: data.colour_code,
        position_data:[{
          offset: data.offset,
          containerId: data.containerId,
          container: data.container,
          end_offset: data.end_offset,
          end_containerId: data.endend_containerIdId,
          end_container: data.end_container,
        }]
        }
    } else if(type === "quiz_markup_tool"){
      body = {
        quiz_markup_tool_id:data.lesson_markup_tools_id,
        highlight_text: data.highlight_text,
        colour_code: data.colour_code,
        position_data:[{
          offset: data.offset,
          containerId: data.containerId,
          container: data.container,
          end_offset: data.end_offset,
          end_containerId: data.endend_containerIdId,
          end_container: data.end_container,
        }]
        }
    }else{
      body = {
        assignment_markup_tool_id:Number(data.lesson_markup_tools_id),
        highlight_text: data.highlight_text,
        colour_code: data.colour_code,
        position_data:[{
          offset: data.offset,
          containerId: data.containerId,
          container: data.container,
          end_offset: data.end_offset,
          end_containerId: data.endend_containerIdId,
          end_container: data.end_container,
        }]
        }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiHighlightToTextCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addHighlightToText}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
  
  );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  handleAddHighlightToTextResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiHighlightToTextCallId)
    ) {
      if (responseJson.data) {
       window.localStorage.removeItem('annotateItems')
       this.handleCloseAnnotationConfirmation()
       this.handleOpenSuccessPopup()
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
  handleHighlightText(type:string){
    const items=JSON.parse(localStorage.getItem('annotateItems') as string);
    if(items && items.length>0){
      items.forEach((element:any) => {
        this.addHighlightToText(element,type)
      });
    }
  }
  handleQuizFolderListResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.getQuizFolderListApiCallId)
    ) {
      if(responseJson.errors){
        this.setState({
          practiceData:{
            type:"",
            data:[]
          },
          pageLimitPractice: 0
        });
        return
      }
      if (!responseJson.errors && responseJson) {
        const folders = responseJson.quiz_folders.data.map((folder:any) => ({
          itemType: 'folder',
          id: folder.id,
          name: folder.attributes.folder_name,
          createdAt: folder.attributes.created_at,
          updatedAt: folder.attributes.updated_at
      }));
      
      const quizzes = responseJson.quizzes.data.map((quiz:any) => ({
          itemType: 'quiz',
          id: quiz.id,
          name: quiz.attributes.quiz_name,
          totalStage:quiz.attributes.total_stage,
          createdAt: quiz.attributes.created_at,
          updatedAt: quiz.attributes.updated_at,
          shared:quiz.attributes.shared
      }));
      
     
      const combinedArray = [...folders, ...quizzes];
      const practiceData:PracticeData={
        type:CLASS_OPTIONS.QUIZZES,
        data:combinedArray
      }
      this.setState({
        practiceData: practiceData,
        pageLimitPractice: responseJson.meta.total_pages,
      });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
  handleAssignmentFolderListResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.getAssignmentFolderListApiCallId)
    ) {
      if(responseJson.errors){
        this.setState({
          practiceData:{
            type:"",
            data:[]
          },
          pageLimitPractice: 0
        });
        return
      }
      if (!responseJson.errors && responseJson) {
        const folders = responseJson.assignment_folders.data.map((folder:any) => ({
          itemType: 'folder',
          id: folder.id,
          name: folder.attributes.folder_name,
          createdAt: folder.attributes.created_at,
          updatedAt: folder.attributes.updated_at
      }));
      
      const assignments = responseJson.assignments.data.map((assignment:any) => ({
          itemType: 'assignment',
          id: assignment.id,
          name: assignment.attributes.assignment_name,
          totalStage:assignment.attributes.total_stage,
          createdAt: assignment.attributes.created_at,
          updatedAt: assignment.attributes.updated_at,
      }));
      
     
      const combinedArray = [...folders, ...assignments];
      const practiceData:PracticeData={
        type:CLASS_OPTIONS.ASSIGNMENTS,
        data:combinedArray
      }
      this.setState({
        practiceData: practiceData,
        pageLimitPractice: responseJson.meta.total_pages,
      });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
deliverLessonApi = (id:number) => {
  this.setState({
    isLoading:true
  })
 
  const header = {
    "Content-Type":configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const body={
      "id": id,
      "live_lesson": true
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deliverLessonApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deliverLesson}`
  );
      
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PUT
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
deliverQuizApi = (id:number) => {
  this.setState({
    isLoading:true
  })
 
  const header = {
    "Content-Type":configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const body={
      "quiz_id": id,
      "live_quiz": true
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deliverQuizApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deliverQuiz}`
  );
      
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PATCH
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
myDownload = (id:number,lesson:any) => {
  this.setState({id:id})
  this.setState({
    isLoading:true
  })
 
  const header = {
    "Content-Type":configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  
  if(lesson==='quizzes')
    {
      const body = {
        object_type:'quiz',
        id: this.state.id
      };
      this.mydownloadfunctionlity(body,header)

    }
   else if(lesson==="assignments") 
    {
      const body = {
        object_type:'assignment',
        id: this.state.id
      };
      this.mydownloadfunctionlity(body,header)
    }
    else{
      const body = {
        object_type:lesson,
        id: id
      };
      this.mydownloadfunctionlity(body,header)
    }
 

}
mydownloadfunctionlity=(body:any,header:any)=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.mydownloadApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.downloadendpoint}`
  );
      
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PATCH
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
deliverPractice=(id:number,type:string)=>{
  if(type==="lesson"){
    this.deliverLessonApi(id)
  }else{
    this.deliverQuizApi(id)
  }
}

handleDeliverLessonResponse=(apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=>{
    if (apiRequestCallId != null && apiRequestCallId === this.deliverLessonApiCallId) {
      if(responseJson.data){
        if(responseJson.data.attributes.live_lesson){
          this.props.navigation.navigate("LiveStreaming", {
            id:responseJson.data.attributes.lesson_code
          });
        }         
      }
    }
    if (apiRequestCallId != null && apiRequestCallId === this.deliverQuizApiId) {
      if(responseJson.data){
        if(responseJson.data.attributes.live_quiz){
          this.props.navigation.navigate("LiveStreaming", {
            id:responseJson.data.attributes.quiz_code
          });
        }         
      }
    }
}

downloadPractice(type:string,id:string) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiDownloadPracticeCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.downloadLesson}?type=${type}&id=${id}`
  );

  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

downloadLessonAdmin(type:string,id:string) {
  
  
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiDownloadLessonCallIdAdmin = requestMessage.messageId;
  if(type=='Lessons')
    {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.downloadLesson}?type=lesson&id=${id}`
      );
    }
  else if(type=="Quizzes")
  {
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.downloadLesson}?type=quiz&id=${id}`
    );
  }
  else if(type=="Assignments")
    {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.downloadLesson}?type=assignment&id=${id}`
      );
    }

  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleDownloadLessonResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiDownloadPracticeCallId)
  ) {
    if (responseJson.data) {
      this.downloadFile(responseJson.data)
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleDownloadLessonResponseAdmin(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiDownloadLessonCallIdAdmin)
  ) {
    if (responseJson.data) {
      this.downloadFile(responseJson.data)

    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleDownloadResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.mydownloadApiId)
  ) {
    if (responseJson) {
    
      
     
        const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "MyDownloads");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
      
    
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    
  }
}


 downloadFile = (data: any) => {
  let filename;
  if(data.type==="lesson"){
    filename = `${data.attributes.lesson_name}.json`
  }else if(data.type==="quizzes"){
    filename = `${data.attributes.quiz_name}.json`
  }else{
    filename = `${data.attributes.assignment_name}.json`
  }
  const jsonStr = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonStr], { type: "application/json" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
handleGetAssignmentListByFolderResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiShowAssignmentFolderCallId)
  ) {
    if (responseJson) {
    const lessons = responseJson.data.attributes.assignments.data.map((assignment:any) => ({
        itemType: 'assignment',
        id: assignment.id,
        name: assignment.attributes.assignment_name,
        totalStage:assignment.attributes.total_stage,
        createdAt: assignment.attributes.created_at,
        updatedAt: assignment.attributes.updated_at,
    }));    
    this.setState({
      lessons: lessons,
      folderId: responseJson.data.id,
      pageNoShowFolderLimit:responseJson.meta.total_page
    });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

handleGetQuizListByFolderResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiShowQuizFolderCallId)
  ) {
    if (responseJson) {
    const lessons = responseJson.data.attributes.quizzes.data.map((quiz:any) => ({
        itemType: 'quiz',
        id: quiz.id,
        name: quiz.attributes.quiz_name,
        totalStage:quiz.attributes.total_stage,
        createdAt: quiz.attributes.created_at,
        updatedAt: quiz.attributes.updated_at,
    }));    
    this.setState({
      lessons: lessons,
      folderId: responseJson.data.id,
      pageNoShowFolderLimit:responseJson.meta.total_page
    });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

handleDeleteQuizResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiDeleteQuizForTeacherCallId || apiRequestCallId === this.apiDeleteAssignmentForTeacherCallId)
  ) {
    if (responseJson.id) {
      if(this.state.currentLocation==="Quizzes" || this.state.currentLocation === "Assignments"){
        this.setState({
          pageNoPractice:1,
          tableSearchInput:"",
          successBarData:{
            message:"Deleted successfully",
            type:"success"
          }
        })
        this.getLessonFolderListByClass(this.state.currentLocation)
      }else{
        this.setState({
          pageNoShowFolder:1,
          showFolderLessonSearch:""
        })
        this.getLessonListByFolder(this.state.folderId,1)
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

handleDeleteQuizAssignmentFolderResponse(apiRequestCallId:string,responseJson:any,errorReponse:any){
  if (
    apiRequestCallId != null && (apiRequestCallId === this.apiDeleteAssignmentFolderCallId || apiRequestCallId === this.apiDeleteQuizFolderCallId)
  ) {
    if (responseJson.message==="Folder deleted successfully") {
      if(this.state.currentLocation==="Quizzes" || this.state.currentLocation==="Assignments"){
        this.setState({
          pageNoPractice:1,
          tableSearchInput:""
        })
        this.getLessonFolderListByClass(this.state.currentLocation)
      }else{
          this.setState({
            pageNoShowFolder:1,
            showFolderLessonSearch:""
          })
          this.getLessonListByFolder(this.state.folderId,1)
        }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
editPractice=(id:string,name:string,itemType:string)=>{
  if(itemType==="lesson"){
    storage.set("pageTypeData", "Lesson");
    this.props.navigation.navigate("CfCourseCreation23", {
      type:COURSES_PAGES.LESSON,
      id:id,
      name:encodeURIComponent(name)
    });
  }else if(itemType==="quiz"){
    storage.set("pageTypeData", "quizName"); 
    this.props.navigation.navigate("CfCourseCreation23", {
      type:COURSES_PAGES.QUIZ,
      id:id,
      name:encodeURIComponent(name)
    });
  }else{
    storage.set("pageTypeData", "Assignment");
    this.props.navigation.navigate("CfCourseCreation23", {
      type:COURSES_PAGES.ASSIGNMENT,
      id:id,
      name:encodeURIComponent(name)
    });
  }
}
updateFolder(id:string,data:string) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(this.state.currentLocation===CLASS_OPTIONS.QUIZZES){
    const body = {
      quiz_folder_id:id,
      folder_name:data
    }
    this.apiUpdateQuizFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateQuizFolder}`
    );
  } else{
    const body = {
      assignment_folder_id:id,
      folder_name:data
    }
    this.apiUpdateAssignmentFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAssignmentFolder}`
    );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PATCH
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
updateLessonFolder(id:string,data:string){
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
    const body = {
      folder_id:id,
      folder_name:data
    }
    this.apiUpdateLessonFolderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateLessonFolder}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
addEditFolder(id:string,data:string){
 if(this.state.folderName.type==="Add"){
  this.createLessonFolder(data)
 }else{
  if(this.state.currentLocation==="Lessons"){
    this.updateLessonFolder(id,data)
  }else{
    this.updateFolder(id,data)
  }
 }
}
handleOpenAnnotationConfirmation=()=>{
  this.setState({
    openAnnotationConfirmation:true
  })
}
handleCloseAnnotationConfirmation=()=>{
  this.setState({
    openAnnotationConfirmation:false
  })
}
handleCloseGdriveModal=()=>{
  this.setState({
    openFileUploadSuccess:false
  })
  this.redirectForTeacherRole("Selected Practice")
  this.handleCloseAnnotationConfirmation()

}
handleOpenGdriveModal=()=>{
  this.unsetUploadingState()
  this.setState({
    openFileUploadSuccess:true
  })
}

setUploadingState = () => {
  this.setState({ uploading: true });
};

unsetUploadingState = () => {
  this.setState({ uploading: false });
};
getHighlights(type:string){
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  if(type==="lesson"){
    this.apiHighlightsForLesson = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHighlightsForLesson}`
    );
  }else if(type==="quiz"){
    this.apiHighlightsForQuiz = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHighlightsForQuiz}`
    );
  }else{
    this.apiHighlightsForAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHighlightsForAssignments}`
    );
  }
   
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
 requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleHighlightsForLessonResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiHighlightsForLesson || apiRequestCallId === this.apiHighlightsForQuiz||apiRequestCallId === this.apiHighlightsForAssignment)
  ) {
    if (responseJson.data) {
      this.setState({highlights:responseJson.data})
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}

handleUploadCheck = (responseJson:any) =>{
  return responseJson?.data?.attributes?.lesson_attachment===null?new File([],''):responseJson?.data?.attributes?.lesson_attachment
}

handleShowLessonResponse = (
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiShowLessonCallId
  ) {
    if (responseJson.data) {
      this.setState({showLessons:responseJson.data,
        editLessonName:responseJson?.data?.attributes?.lesson_name, 
        editLessonObjective: responseJson?.data?.attributes?.lesson_objective,
        editClassName:responseJson?.data?.attributes?.teacher_class?.data?.id,
        editUploadIcon: this.handleUploadCheck(responseJson),
        editStartDate:responseJson?.data?.attributes?.start_date,
        editDueDate:responseJson?.data?.attributes?.end_date,
      })
    }
  }
}


handleShowAssignmentResponse = (
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiShowAssignmentCallId
  ) {
    if (responseJson.data) {
      this.setState({showLessons:responseJson.data,
        editLessonName:responseJson?.data?.attributes?.assignment_name, 
        editLessonObjective: responseJson?.data?.attributes?.details,
        editClassName:responseJson?.data?.attributes?.teacher_classes_id,
        editStartDate:responseJson?.data?.attributes?.start_date,
        editDueDate:responseJson?.data?.attributes?.end_date,
        editUploadIcon: responseJson?.data?.attributes?.assignment_attachment,
      })
    }
  }
}

handleShowQuizResponse = (
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiShowQuizCallId
  ) {
    if (responseJson.data) {
      this.setState({showLessons:responseJson.data,
        editLessonName:responseJson?.data?.attributes?.quiz_name, 
        editClassName:responseJson?.data?.attributes?.teacher_classes_id,
        editStartDate:responseJson?.data?.attributes?.start_date,
        editDueDate:responseJson?.data?.attributes?.end_date,
        editLessonObjective:responseJson?.data?.attributes?.details,
        editUploadIcon: responseJson?.data?.attributes?.quiz_attachment,
      })
    }
  }
}

handleEditMyLibraryAssignment = (apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiUpdateAssignmentCallId
  ) {
    if (responseJson) {
      this.setState({openEditModal: false,},()=>{
        this.getLessonFolderListByClass('Assignments')
      })
    } 
    if(responseJson.error === 'Request size exceeds the 2 GB limit.'){
      this.enableLimitSnackbar();
    }
  }
}

handleEditMyLibraryQuiz = (apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiUpdateQuizCallId
  ) {
    if (responseJson) {
      this.setState({openEditModal: false,},()=>{
        this.getLessonFolderListByClass('Quizzes')
      })
    }
  }
}

handleGetClasslistData = (apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.getClassesDataApiCallId
  ) {
    if (responseJson.data) {
      this.setState({classList:responseJson.data})
    }
  }
  
}

handleEditMyLibrary = (apiRequestCallId: string,
  responseJson: any,
  errorReponse: string)=> {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiEditLibraryCallId
  ) {
    if (responseJson) {
      this.setState({openEditModal: false,},()=>{
        this.getLessonFolderListByClass('Lessons')
      })
    }
  }
}
findTextNode(parentNode: any, offset: number) {
  if(!parentNode) return null;
  let currentOffset = 0;

  const traverseNodes = function (node: any): any {
      if (node.nodeType === Node.TEXT_NODE) {
      const nextOffset = currentOffset + node.textContent.length;
      if (offset >= currentOffset && offset <= nextOffset) {
        return { node: node, offset: offset - currentOffset };
      }
      currentOffset = nextOffset;
    } else {
      for (let child of node.childNodes) {
        const result = traverseNodes(child);
        if (result) return result;
      }
    }
    return null;
  };
  return traverseNodes(parentNode);
  
}
handleHighlightedTextData(data:any){
  this.setState({
    highlightedData:data
  })
}

applyHighlightDirectly(highlight:any) {
  const contentElement = document.getElementById("content");
    const startTextNode = this.findTextNode(contentElement, highlight.attributes.position_data[0].offset);
    const endTextNode = this.findTextNode(contentElement, highlight.attributes.position_data[0].offset + highlight.attributes.highlight_text.length);

    if (startTextNode && endTextNode) {
      const range = document.createRange();
      range.setStart(startTextNode.node, startTextNode.offset);
      range.setEnd(endTextNode.node, endTextNode.offset);
      
      const highlightSpan = document.createElement('span');
      highlightSpan.style.backgroundColor = highlight.attributes.colour_code;
     
      highlightSpan.setAttribute('data-highlight-id', highlight.id);
      highlightSpan.textContent=range.toString()
      range.deleteContents();
      range.insertNode(highlightSpan);
  }
};
 redoHighlight = (highlight:any) => {
    const startNode = this.findTextNode(document.getElementById("content"), highlight.offset);
    const endNode = this.findTextNode(document.getElementById("content"), highlight.offset+highlight.highlight_text.length);
    if (startNode && endNode) {
        const range = document.createRange();
        range.setStart(startNode.node, startNode.offset);
        range.setEnd(endNode.node, endNode.offset);
        const extractedContents = range.extractContents();
        const highlightSpan = document.createElement('span');
        highlightSpan.style.backgroundColor = highlight.colour_code;
        highlightSpan.setAttribute('data-highlight-id', highlight.id);
        if (startNode.node === endNode.node) {
          highlightSpan.textContent = startNode.node.textContent.substring(startNode.offset,  startNode.offset+highlight.highlight_text.length);
        } else {
         highlightSpan.appendChild(extractedContents);
        }
        range.insertNode(highlightSpan);
    }
  };

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };
  
  debouncedStudentClassSearch = debounce(() => {
    this.getStudentClassList();
  }, 1000);
  handleSearchStudentClass(value:string) {
    this.setState(
      {
        searchInput: value,
        pageNoTeacherClasses: 1,
      },
      () => {
        this.debouncedStudentClassSearch();
      }
    );
  }

  handleLessonNameChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({editLessonName: event.target.value})
  }

  handleEditUploadIcon = (icon:any) =>{
    this.setState({editUploadIcon: icon})
  }

  handleLessonObjectiveChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({editLessonObjective: event.target.value})
  }

  handleEditClassChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({editClassName: event.target.value})
  }

  handleEditDueDateChange = (date:any) =>{
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({editDueDate: formattedDate})
  }

  handleEditStartDateChange = (date:any) =>{
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({editStartDate: formattedDate})
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleCloseDeleteSuccess=()=>{
    this.setState({
      openSuccessBar:false
    })
  }
  getCommentData(id:number) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentData}?lesson_markup_tools_id=${id}`
    );
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet)
      runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
  }

  viewMyLibraryLesson = (id:string)=> {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShowLessonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showLesson + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  viewMyLibraryAssignment = (id:string)=> {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShowAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showAssignment + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  viewMyLibraryQuiz = (id:string)=> {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShowQuizCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showQuiz + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editFuncMyLibraryAssignment =(id:string)=> {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    if(this.validateEditFuncLibrary()){
      this.setState({isValidateEditLibrary: true})
      return;
    }

    const httpBody = {   
      "start_date": this.state.editStartDate, 
      "teacher_classes_id": this.state.editClassName,
      "details": this.state.editLessonObjective,
      "end_date": this.state.editDueDate, 
      "assignment_name": this.state.editLessonName,
      "assignment_attachment": this.state.editUploadIcon,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateAssignmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAssignment + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editFuncMyLibraryQuiz =(id:string)=> {
    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.dashboarContentType,
    };

    if(this.validateEditFuncLibrary()){
      this.setState({isValidateEditLibrary: true})
      return;
    }

    const httpBody = {
      "teacher_classes_id": this.state.editClassName,
      "end_date": this.state.editDueDate, 
      "quiz_name": this.state.editLessonName,
      "details": this.state.editLessonObjective,
      "start_date": this.state.editStartDate, 
      "quiz_attachment": this.state.editUploadIcon,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateQuizCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateQuiz + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCommentDataResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.getCommentDataCallId)
    ) {
      if (responseJson.data[0]) {
        const comment = responseJson.data.map((data:any) => ({
          id: data.id,
          commentDesc: data.attributes.comment_description,
          hozPos:data.attributes.horizontal,
          verPos:data.attributes.vertical,
          name:data.attributes.account.data.attributes.full_name,
          createdAt:data.attributes.created_at,
          stageId:data.attributes.lesson_markup_tools_id
        }));
        this.setState({
          commentData:comment
        })   
      }
    }
  }

  handleOpenEditModal = ()=>{
    this.setState({openEditModal:true})
  }

  handleCloseEditModal = () =>{
    this.setState({openEditModal:false,editValidDueDateEvent:false,editLessonNameValidate: false,editClassValidate: false,editValidStartDateEvent: false,isstartDateMax: false,editUploadIcon:new File([],'')})
  } 
  validateEditFuncLibrary = () =>{
    let flag = false;
    if(!this.state.editLessonName.length){
      this.setState({editLessonNameValidate: true})  
      flag = true
    } else if (this.state.editClassName === 'Select Class') {
      this.setState({ editClassValidate: true });
      flag = true;
    } else if (!this.state.editStartDate?.length) {
      this.setState({ editValidStartDateEvent: true });
      flag = true;
    } else if(!this.state.editDueDate.length){
      this.setState({editValidDueDateEvent: true})  
      flag = true
    }
    return flag;
  }

  editFuncMyLibraryLesson =(id:string)=> {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    if(this.validateEditFuncLibrary()){
      this.setState({isValidateEditLibrary: true})
      return;
    }

    const formData = new FormData()
    formData.append("lesson_name", this.state.editLessonName)
    formData.append("lesson_objective", this.state.editLessonObjective)
    formData.append('lesson_attachment', this.state.editUploadIcon)
    formData.append('start_date',this.state.editStartDate)
    formData.append('end_date',this.state.editDueDate)
    formData.append('teacher_classes_id', this.state.editClassName)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditLibraryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateLesson + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getClassesData = () => {
    const header = {
      token: window.localStorage.getItem("authToken"),
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClassesDataApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

   handleSaveClick = async(type:any,checked:boolean) => {
    this.handleHighlightText(type);
    if (checked) {      
      await this.createAndUploadJsonFile(this.state.stagesData)
    }
  };

   createAndUploadJsonFile = async (
    jsonResponse: any
  ) => {
    this.setUploadingState()
    const randomNum = Math.floor(Math.random() * 900) + 100;
  
    let fileName: string;
    if (jsonResponse.meta.assignment_name) {
      fileName = `${randomNum}_${jsonResponse.meta.assignment_name}`;
    } else if (jsonResponse.meta.quiz_name) {
      fileName = `${randomNum}_${jsonResponse.meta.quiz_name}`;
    } else {
      fileName = `${randomNum}_${jsonResponse.meta.lesson_name}`;
    }
  
    const jsonString = JSON.stringify(jsonResponse, null, 2); 
    const blob = new Blob([jsonString], { type: "application/json" });
  
    const fileMetadata = {
      name: `${fileName}.json`,
      mimeType: "application/json",
    };
  
    const formData = new FormData();
    formData.append(
      "metadata",
      new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
    );
    formData.append("file", blob);
  
    const accessToken = gapi.auth.getToken().access_token;
  
    await fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
      {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
        body: formData,
      }
    );
    this.handleOpenGdriveModal()

  };
  
  

  handleAuthClick = () => {
    if(!this.state.isSignedIn){
      gapi.auth2.getAuthInstance().signIn();
    }
  };

  handleClientLoad = () => {
    gapi.load('client:auth2', this.initClient);
  };

  delay = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  handleNavigatePractice = async () => {
    /**
     * Teacher Flow
     */
    const teacherLibraryClassId = window.localStorage.getItem("teacherLibraryClassId");
    const teacherLibraryPracticeType = window.localStorage.getItem("teacherLibraryPracticeType");
    if (teacherLibraryClassId && teacherLibraryPracticeType) {
      // Navigate to selected class
      const classItem: any = this.state.classes.filter((classItem: any) => (classItem.id === teacherLibraryClassId))[0];
      // Navigate to selected practice
      await this.delay(10);
      this.selectedPracticeForTeacherRole({}, classItem);
      this.selectedPracticeForTeacher(teacherLibraryPracticeType);
    }
    /**
     * Student Flow
     */
    const studentLibraryClassId = window.localStorage.getItem("studentLibraryClassId");
    const studentLibraryPracticeType = window.localStorage.getItem("studentLibraryPracticeType");
    if (studentLibraryClassId && studentLibraryPracticeType) {
      // Navigate to selected class
      const classItem: any = this.state.classes.filter((classItem: any) => (classItem.id === studentLibraryClassId))[0];
      this.selectedClass({}, classItem);
      // Navigate to selected practice
      await this.delay(10);
      this.selectedPractice(studentLibraryPracticeType);
    }
  }

  initClient = () => {
    gapi.client.init({
      apiKey: configJSON.WebAPIKey,
      clientId: configJSON.WebClienctID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(() => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
      this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };

  updateSigninStatus = (isSignedIn: boolean) => {
    if (isSignedIn) {
      this.setState({ isSignedIn });
    }
  };

  checkPermissionStatus =  (id: string | null, item:string) => {
    if (!id || !item) {
      return;
    }
    const header = {
      token: window.localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    if (item === "Lessons") {
      this.checkAccessForLesson = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.showLesson}?id=${id}`
      );
    } else if (item === "Quizzes") {
      this.checkAccessForQuiz = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.showQuiz}?id=${id}`
      );
    } else {
      this.checkAccessForAssignment = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.showAssignment}?id=${id}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

 async  permissionResponseHandler(responseJson: any, errorReponse: any,apiRequestCallId:any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.checkAccessForLesson 
      || apiRequestCallId === this.checkAccessForQuiz 
      || apiRequestCallId ===this.checkAccessForAssignment
    ) {
    if (responseJson.error || responseJson.permission_errors) {
      const newMessage = new Message(getName(MessageEnum.NavigationMessage));
      newMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AccessDenied");
      newMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(newMessage);
    } 
  }
  }
  handleCloseLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: false
    })
  }

  enableLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: true,
    })
  }
  
  // Customizable Area End
}
