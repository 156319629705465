import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface IconPos {
   x: number,
   y: number, 
  show: boolean 
  }
  interface Comment{
    id: string;
    commentDesc: string;
   hozPos:string;
   verPos:string;
   name:string;
   createdAt:string;
  }

  interface CommentPos{
    x:number,
    y:number
  }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  tooltipPos:any;
  stageId:string;
  setOpenComment:any;
  setAnchorEl:any;
  comment:Comment;
  getCommentData:any;
  anchorEl:any;
  anchorEll:any;
  setAnchorEll:any;
  removeCommentIconn:any;
  commentArr:any;
  removeCommentIcon:any;
  handleRemoveComment:any;
  enableLimitSnackbar:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  role: string|null
  commentDesc:string;
  selectionRange: Range | null,
  iconPos: IconPos,
  isDelete:boolean,
  commentPos:CommentPos;
  isEdit:boolean,
  commentId:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";
  apiCreateCommentCallId: string = "";
  apiDeleteCommentCallId:string="";
  updateCommentCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      role:"",
      commentDesc:"",
      selectionRange: null,
      iconPos: { x: 0, y: 0, show: false },
      isDelete:false,
      commentPos:{x:0,y:0},
      isEdit:false,
      commentId:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start
    this.setState({
      role:window.localStorage.getItem("role")
    })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false,
          });
        } else {
          this.setState({
            commentData: responseJson.data,
            errorMsg: "",
            loading: false,
          });
        }
      } else {
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false,
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false,
          });
        }
      }
      if (apiRequestCallId === this.createCommentId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.props.navigation.goBack();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.handleDeleteCommentResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleCreateCommentResponse(apiRequestCallId,responseJson,errorReponse)
      this.handleUpdateCommentResponse(apiRequestCallId,responseJson,errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCommentData(): boolean {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCommentItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setComment = (comment: string) => {
    this.setState({ comment });
  };

  createComment = (id?: any, postId?: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };

    const data = {
      attributes: {
        post_id: postId ? postId : 1,
        comment: this.state.comment,
      },
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }
  
  addComment(data:string) {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      lesson_markup_tools_id: this.props.stageId,
      comment_description: data,
      horizontal: this.props.comment.id?this.props.comment.hozPos:(this.state.commentPos.x-280).toString(),
      vertical: this.props.comment.id?this.props.comment.verPos:(this.state.commentPos.y-240).toString()
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiCreateCommentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addComment}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateCommentResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiCreateCommentCallId)
    ) {
      if (responseJson.data) {
        this.props.setOpenComment(false)
        this.props.setAnchorEl(null)
        this.props.removeCommentIconn(this.props.stageId)
        this.props.handleRemoveComment()
      } else if(responseJson.error && responseJson.error === 'Request size exceeds the 2 GB limit.'){
        this.props.enableLimitSnackbar();
      }else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
   
deleteComment(id: string) {
  const header = {
    "Content-Type": configJSON.commentsContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
    this.apiDeleteCommentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteComment}?comment_id=${id}`
    );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.DELETE
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleDeleteCommentResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.apiDeleteCommentCallId)
  ) {
    if (responseJson.message) {
      this.props.setAnchorEl(null);
      this.props.removeCommentIconn(this.props.stageId)
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleCloseDelete=()=>{
  this.setState({
    isDelete:false
  })
}
updateCommentApi = (id:string) => {
  const header = {
    "Content-Type":configJSON.commentsContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const body={
      comment_description: this.state.commentDesc
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updateCommentCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.updateComment}?comment_id=${id}`
  );
      
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PUT
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

componentDidUpdate = (prevProps: Props, prevState: S) => {
  if (this.state.selectionRange !== prevState.selectionRange) {
    if (!this.state.selectionRange) return;
    const rect = this.state.selectionRange.getBoundingClientRect();
    const scrollContainer = document.getElementById("canvasContainer");
    const scrollX = scrollContainer ? scrollContainer.scrollLeft : 0;
    const scrollY = scrollContainer ? scrollContainer.scrollTop : 0;

    const tooltipX = rect.left + scrollX;
    const tooltipY = rect.top + scrollY;

    this.setState({ 
      commentPos: { 
        x: tooltipX, 
        y: tooltipY 
      },
    });
  }
};
handleUpdateCommentResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.updateCommentCallId)
  ) {
    if (responseJson.data) {
      this.props.setOpenComment(false)
      this.props.setAnchorEl(null)
      this.props.getCommentData(this.props.stageId)
    } else if(responseJson.error && responseJson.error === 'Request size exceeds the 2 GB limit.'){
      this.props.enableLimitSnackbar();
    }else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
  // Customizable Area End
}
