import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BasicPagination from "../../../../components/src/BasicPagination.web";
import ProfileInfoTable from "./ProfileInfoTable.web";
import Searchbar from "./Seachbar.web";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../../components/src/Loader.web";
const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
});
const ProfileInfoList = (props: any) => {
  const classes = useStyles();
  const {
    redirect,
    handlePageChange,
    pageLimit,
    handleSearchInput,
    searchInput,
    role,
    profileData,
    deleteProfile,
    isLoading,
    isDeleteSuccess,
    deleteProfileError,
    deleteCleanup,
    pageNo
  } = props;
  const changePage = (event: any, page: number) => {
    handlePageChange(event, page, role);
  };
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Box sx={{ px: "35px", my: "30px" }}>
            <Searchbar
              role={role}
              searchInput={searchInput}
              handleSearchInput={handleSearchInput}
            />
          </Box>
        </Grid>
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <>
            {profileData.length === 0 ? (
              <Grid item md={12}>
                <Typography className={classes.noRecords}>
                  No records found
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item md={12}>
                  <Box sx={{ px: "35px" }}>
                    <ProfileInfoTable
                      profileData={profileData}
                      redirect={redirect}
                      deleteProfile={deleteProfile}
                      role={role}
                      isDeleteSuccess={isDeleteSuccess}
                      deleteProfileError={deleteProfileError}
                      deleteCleanup={deleteCleanup}
                      pageNo={pageNo}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box sx={{ px: "35px", my: "30px" }}>
                    <BasicPagination
                      handlePageChange={changePage}
                      pageLimit={pageLimit}
                      pageNo={pageNo}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ProfileInfoList;
