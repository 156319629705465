import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { constructYoutubeUrlFromVideoId, extractVideoIdFromThumbnailUrl } from "../../../../../components/src/Utils.web";
import Loader from "../../../../../components/src/Loader.web";
import {audioImg} from "../../assets"
const useStyles = makeStyles(() => ({
  editArea: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
  },
  audioStyle: {
    width: "100%"
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"space-between",
    width: "100%",
  },
  imageStyle: {
    width: '70%', 
    alignSelf:'center'
  },
  imgSize:{
    maxWidth: "100%",
    maxHeight: "95%",
    height: "auto"
  }
}));
const MultiMedia = (props: any) => {
  const { multiMediaData, loading } = props;
  const classes = useStyles();
  
  const videoId = multiMediaData?.videos_url?.length > 0 && extractVideoIdFromThumbnailUrl(multiMediaData?.videos_url[0]);
  const imageUrl = multiMediaData?.images
  const videoUrl = multiMediaData?.videos
 
  const youtubeUrl = videoId && constructYoutubeUrlFromVideoId(videoId);
  return (
    <Box className={classes.editArea}>
      {loading?(
        <Loader loading/>
      ):(
<>
            {imageUrl && imageUrl.length > 0 && imageUrl[0].url && (
              imageUrl[0].url.includes(".mp3") ? (
                <Box className={classes.container}>
                  <Box></Box>
                <img src={audioImg} alt="Description of the image" className={classes.imageStyle} />
                  <audio controls data-test-id="audioTestId" className={classes.audioStyle}>
                    <source src={imageUrl[0].url} />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              ) : (
                <img
                  data-test-id="img-preview"
                  src={imageUrl[0].url}
                  width="100%"
                  height="100%"
                  className={classes.imgSize}
                />
              )
            ) }
      {videoUrl && videoUrl.length > 0 && (
        <video
          data-test-id="video"
          width="100%"
          height="100%"
          controls
        >
          <source src={videoUrl[0]?.url} type="video/mp4" />
        </video>
      )}
      {youtubeUrl && youtubeUrl.length > 0 && <iframe
        data-test-id="youtube"
        width="100%"
        height="100%"
        src={youtubeUrl.replace('watch?v=', 'embed/')}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>}
</>
      )}
      
    </Box>
  );
};

export default MultiMedia;
