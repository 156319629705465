Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "WhiteboardCollaboration";
exports.labelBodyText = "WhiteboardCollaboration Body";
exports.labelSelect = "select-tools";
exports.labelSocketConnect = "Socket is connected";
exports.labelSocketDisconnect = "Socket is disconnected, SomeThing went Wrong";

exports.socketPath = "http://localhost:5000";
exports.webViewpath = "http://localhost:3000/WhiteboardCollaboration";

exports.webScaleX = 13;
exports.webScaleY = 7;
exports.mobileScaleX = 4;
exports.mobileScaleY = 2;
exports.canvasScale = 2;
exports.canvasColor = 'round';
exports.canvaslineCap = '#000000';
exports.canvasZoom = 5;
exports.canvasDefaultScale = 1;
exports.canvasPoint = 0;
exports.canvasZoomScale = 1;
exports.canvasTopPosition = 25;
exports.shapeTypeReactangle = 1;
exports.shapeTypeLine = 2;
exports.shapeTypeArrow = 3;
exports.shapeTypeCircle = 4;
// Customizable Area End