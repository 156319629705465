import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import moment from "moment";
import StudentCustomContextMenu from "./components/SelectedPractice/StudentCustomContextMenu.web";
import { notesIcon } from "./../assets";

interface ContextMenuListData {
  id: string;
  type: string;
  name: string;
  itemType: string;
  shared: boolean;
  lessonId:number
}

interface AddNotesModalState {
  show: boolean;
  type: string;
  id:number;
  name:string;
  itemType:string
}

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor:  "#FDF4DC",
    color:  "#202020",
    fontFamily:  "Poppins",
    fontWeight:  500,
    fontSize:  "16px",
  },
  body:  {
    fontSize:  "14px",
    color:  "#3F526D",
    fontFamily:  "Poppins",
    fontWeight:  400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  tableStyle: {
    minWidth: 700,
  },
  noteIcon: {
    width: "28px",
    height: "28px",
    marginLeft: "30px",
    cursor:"pointer",
  },
  practiceImageStyle: {
    width: "28px",
    height: "28px",
    marginRight: "30px",
  },
  practiceNameColumnBoxStyle: {
    display: "flex",
    alignItems: "center",
  },
});

type NotesData = number[];


export default function DataTable(props: any) {
  const { practiceData,handleOpenDeleteModal,notesList,handleCloseDeleteModal,
    deleteLessonFolder,handleShowNotesIcon,handleCloseNoMobileModal,openDeleteConfirmationModal,
    handleOpenNoMobileModal,openNoMobileModal,selectedLessons,downloadLesson,editPractice,handleOpenAddFolderModal,deleteStudentLibrary } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] =  React.useState<HTMLElement | SVGSVGElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = React.useState(false);
  const [contextMenuListData, setContextMenuListData] =  React.useState<ContextMenuListData>({
    id: '',
    type: '',
    name: '',
    itemType: '',
    shared: false,
    lessonId:0})
  const [notesData, setNotesData] = React.useState<NotesData>([]);
  const [openAddNotesModal, setOpenAddNotesModal] = React.useState<AddNotesModalState>({ show: false, type: "",id:0,name:"",itemType:"" });
  const handleOpenContextMenuBox = (
    event:  React.MouseEvent<SVGSVGElement, MouseEvent>,
    id: string,
    name: string,
    type: string,
    itemType:string,
    shared:boolean,
    lessonId:number
  ) => {
    event.preventDefault();
    setContextMenuListData({
      id,
      type,
      name,
      itemType,
      shared,
      lessonId
    });
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };

 

  const handleCloseAddNotesModal = () => {
    setOpenAddNotesModal({ show: false, type: "",id:0,name:"",itemType:"" });
  };

  const handleOpenAddNotesModal = (value: string, newId:number, name:string, itemType:string ) => {
    setOpenAddNotesModal({ show: true, type: value,id:newId, name:name, itemType:itemType });
  };

  const showIcon=()=>{
    const list = notesList.map((objLesson:{lessonId:number})=>objLesson.lessonId)
    setNotesData(list)
  }

  useEffect(()=>{
    showIcon()
  },[notesList])
  
  const  handleCloseContextMenu = (event: any) => {
      event.preventDefault();
      setAnchorEl(null);
      setOpenContextMenu(false);
  };
  
  const dateTimeFormat = (date: any) => {
    return date
      ? moment(date).format("DD/MM/YYYY HH:MM A")
      : undefined;
  }


  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.tableStyle} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">{practiceData?.type}</StyledTableCell>
            <StyledTableCell align="center">Stages</StyledTableCell>
            <StyledTableCell align="center">Created On</StyledTableCell>
            <StyledTableCell align="center">Last Updated On</StyledTableCell>
            <StyledTableCell  align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {practiceData?.data.map((data: any) => (
            <StyledTableRow  key={data.id}>
              <StyledTableCell   component="th" scope="row">
                <Box   className={classes.practiceNameColumnBoxStyle}>
                  <img
                    className={classes.practiceImageStyle}
                    src={practiceData.iconType}
                  />{" "}
                  {data.name}
                  {notesData.find((objLesson:{})=>objLesson===Number(data.id)) && (
                      <img 
                      onClick={()=>handleOpenAddNotesModal("Edit",data.id, data.name, data.itemType)}
                        className={classes.noteIcon}
                        src={notesIcon}
                      />
                    )}
                </Box>
              </StyledTableCell>
              <StyledTableCell  align="center">{data.stages}</StyledTableCell>
              <StyledTableCell  align="center">{dateTimeFormat(data.createdOn)}</StyledTableCell>
              <StyledTableCell  align="center">{dateTimeFormat(data.updatedOn)}</StyledTableCell>
              <StyledTableCell  align="center">
                <MoreVertIcon  data-test-id="action-icon"
                style={{cursor:"pointer"}}
                  onClick={(event:React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                    handleOpenContextMenuBox( event,
                      data.id,
                      data.name,
                      practiceData.type,
                      data.itemType,
                      data?.shared,
                      data.lessonId)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      <StudentCustomContextMenu
        data-test-id="context-menu"
        anchorEl={anchorEl}
        openContextMenu={openContextMenu}
        handleCloseContextMenu={handleCloseContextMenu}
        handleOpenNoMobileModal={handleOpenNoMobileModal}
        handleCloseNoMobileModal={handleCloseNoMobileModal}
        openNoMobileModal={openNoMobileModal}
        selectedLessons={selectedLessons}
        editPractice={editPractice}
        contextMenuData={contextMenuListData}
        handleOpenAddFolderModal={handleOpenAddFolderModal}
        downloadLesson={downloadLesson}
        openAddNotesModal={openAddNotesModal}
        handleCloseAddNotesModal={handleCloseAddNotesModal}
        handleShowNotesIcon={handleShowNotesIcon}
        handleOpenAddNotesModal={handleOpenAddNotesModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        deleteLessonFolder={deleteLessonFolder}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        noteData={notesData}
        deleteStudentLibrary={deleteStudentLibrary}
      />
    </>
  );
}
