import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const noMobile = require('./noMobile.svg');


const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            padding: theme.spacing(1, 2, 2),
        },
    },
    image: {
        backgroundColor: "#eef0f5",
        borderRadius: 60, 
        padding: 20 
    },
    heading: {
        fontFamily: "Poppins",
        fontSize: "28px",
        color: "#000000",
        fontWeight: 700,
        marginBottom: "20px",
        marginTop:"20px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "19px",
            textAlign: "center"
        },
    },
    confirmation: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#202020",
        marginBottom: "15px",
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: "16px",
            textAlign: 'center'
        },
    },
    title: {
        fontFamily: "Poppins",
        fontSize: "24px",
        color: "#202020",
        fontWeight: 400,
        marginBottom: "35px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "16px",
            marginBottom: "20px",
            textAlign: 'center',
            wordSpacing: '1px',
            marginTop:"20px"
        },
    },
    doneBtn: {
        backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",        
        color: "#FFF",
        borderRadius: "10px",
        width: "270px",
        padding: "12px",
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "18px",
        [theme.breakpoints.down('xs')]: {
            width: "45%",
            padding: "10px",
            fontSize: "16px",
        },
    },
    cancelBtn: {
        border: "1px solid #FE575A",
        color: "#3F526D",
        borderRadius: "10px",
        width: "270px",
        padding: "12px",
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "18px",
        [theme.breakpoints.down('xs')]: {
            width: "45%",
            padding: "10px",
            fontSize: "16px",
        },
    },
    popupContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    btnLayout: {
        display: "flex",
        justifyContent: "space-around",
        [theme.breakpoints.down('xs')]: {
            alignItems: "center",
        },
    },
}));
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const NotForMobile = (props: any) => {
    const { handleCloseModal } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    return (
        <Box style={modalStyle} className={classes.paper}>
            <Box className={classes.popupContent}>
                <Typography className={classes.heading}>
                Optimal Device Required!
                </Typography>
                <Box className={classes.image}>
                    <img src={noMobile} />
                </Box>

                <Typography className={classes.title}>
                {`Hey there! It looks like you're trying to access the ${props.moduleName} module, but it's optimized for larger screens like desktops and laptops. For the best experience, please switch to a higher-resolution device.`}
                </Typography>

                <Typography className={classes.confirmation}>
                    Thank you for your understanding!
                </Typography>
            </Box>
            <Box className={classes.btnLayout}>
                <Button
                    className={classes.doneBtn}
                    onClick={() => {
                        handleCloseModal();
                    }}
                >
                    Done
                </Button>
            </Box>
        </Box>
    );
};

export default NotForMobile;
