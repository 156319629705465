import React from "react";
import { Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteConfirmation from "./DeleteConfirmation.web";
import DeleteSuccess from "./DeleteSuccess.web";
import { getModalStyle, useStyles } from "./utils";

const DeleteProfileModal = (props: any) => {
  const { handleCloseModal, isDeleteSuccess, deleteStudent, userData } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        {isDeleteSuccess && (
          <ClearIcon
            onClick={(e) => handleCloseModal(e, userData.id)}
            className={classes.crossIcon}
          />
        )}

        <Box className={classes.popupContent}>
          {isDeleteSuccess ? (
            <DeleteSuccess fullName={userData.name} />
          ) : (
            <DeleteConfirmation
              deleteStudent={deleteStudent}
              userData={userData}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProfileModal;
