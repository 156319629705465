import React from "react";
import { Box, Typography, Grid, useMediaQuery } from "@material-ui/core";
import SearchInput from "../../../../../components/src/SearchInput.web";
import { makeStyles } from "@material-ui/core/styles";
import ClassList from "../components/ClassList.web";
import ActionItems from "../components/ActionItems.web";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  searchbarTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#2B3D7D",
  },
  viewAll: {
    fontFamily: "Poppins",
    color: "#2B3D7D",
    fontSize: "16px",
    marginTop: "5px",
  },
  overvirwBox: {
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    },
  },
  classList: {
    marginTop: "30px",
    marginBottom: "25px",
  },
  link: {
    textDecoration: "none"
  }
}));
const StudentInfo = (props: any) => {
  const classes = useStyles();
  const {
    handleSearchClassName,
    searchInputClassName,
  } = props;

  return (
    <>
      <Box sx={{ px: "32px" }} className={classes.overvirwBox}>
        <Box sx={{ mt: "44px" }}>
          <ActionItems studentActionItems={props.studentActionItems} />
        </Box>
        <Grid container justifyContent="space-between" className={classes.classList}>
          <Grid item>
            <Typography className={classes.searchbarTitle}>My Classes</Typography>
          </Grid>
          <Grid item>
            <Link to={"/classes/myclasses"} className={classes.link}><Typography className={classes.viewAll} >View All</Typography></Link>
          </Grid>
        </Grid>
        <SearchInput
          placeholder={"Search by class name"}
          handleSearchInput={handleSearchClassName}
          searchInput={searchInputClassName}
        />
      </Box>
      <Box sx={{ px: "25px", mt: "20px" }}>
        <ClassList dataList={props.classList} longView={false} handleClick={props.redirectToClassDetailView} />
      </Box>
    </>
  );
};

export default StudentInfo;
