import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Picker, { EmojiClickData } from "emoji-picker-react";

const useStyles = makeStyles((theme) => ({
  pickerWrapper: {
    position: "absolute",
    bottom:"60px",
    height:"50%",
    width:"100%",
    zIndex:1
  },
  picker: {
    width: "100%", 
  },
}));

interface CustomEmojiPickerProps {
  open: boolean;
  onEmojiClick: (emojiData: EmojiClickData) => void;
}

const CustomEmojiPicker = (props:CustomEmojiPickerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.pickerWrapper}>

        <div className={classes.picker}>
          <Picker 
            height="100%" 
            width="24%" 
            onEmojiClick={props.onEmojiClick} 
            open={props.open} 
            skinTonesDisabled 
            previewConfig={{showPreview:false}}
            lazyLoadEmojis
            data-test-id="mainPicker"
          />
        </div>
    </div>
  );
};

export default CustomEmojiPicker;
