import React from "react";
import { Box, Typography, Button,Modal,Switch  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeModalIcon,downloadIcon,savedIcon } from "./assets";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "472px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"48px 24px 46px",
    "&.saved-lesson-popup":{
      width:"309px",
      padding:"48px 15px",
      "& .image-wrapper":{
        marginBottom:"20px",
      },
      "& .popupContent":{
        margin:0,
      },
      "& .saveHeading":{
        fontSize:"16px",
        fontWeight:"400",
        color:"#282829",
        margin:"0",
      },
    },
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    },
    "& .cross-icon":{
      cursor:"pointer"
    }
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#2C2C2E",
    fontWeight: 500,
    lineHeight:"1.5",
    textAlign:"center",
    padding:"0 15px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .image-wrapper":{
      marginBottom:"15px",
    }
  },
  btnLayout: {
    display: "flex",
    justifyContent: "center",
    "& button + button":{
      marginLeft:"16px",
    }
  },
  siteBtn:{
    height: "52px",
    lineHeight: "52px",
    border: "1px solid #2B3D7D",
    color: "#2B3D7D",
    minWidth: "52px",
    width: "auto",
    padding: "0 15px",
    fontSize: "16px",
    fontWeight: 500,
    transition:"all 0.5s",
    textTransform:"unset",
    "&:hover":{
      backgroundColor:"#1e2b58",
      color:"#FFF"
    },
    "&.fill-btn":{
      color:"#FFF",
      backgroundColor:"#2B3D7D",
      "&:hover":{
        backgroundColor:"#1e2b58",
      },
    }
  },
  
  saveHeading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight:"1.6",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"10px",
  },
  saveHeadingSub:{
    fontFamily: "Poppins",
    fontSize: "28px",
    lineHeight:"1.6",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"10px",
    fontWeight:700
  },
  imgSize:{
    fontSize:"50px",
    color:"red"
  }
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const CommonModal = (props: any) => {
  const { open, handleClose,errorMsg } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  
  return (
    <Modal
        open={open}
    >
      <Box style={modalStyle} 
       className={`${classes.paper} 'saved-lesson-popup'`}
      >
        <a data-test-id="closeSaveModal" onClick={handleClose} title="close modal" className="close-modal"><img src={closeModalIcon} alt="Modal close icon" className="cross-icon"/></a>
        <Box className={`${classes.popupContent} popupContent`}>
          <Box className="image-wrapper">
           <CancelIcon className={classes.imgSize}/>
          </Box>
          <Typography className={`${classes.saveHeadingSub}`}>
            Class not deleted
          </Typography>
          <Typography className={`${classes.saveHeading}`}>
             {errorMsg}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonModal;
