Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PeopleManagement2";
exports.labelBodyText = "PeopleManagement2 Body";
exports.deleteProfile={
  teacher:{
    title:"Are you sure you want to delete the",
    subTitle:"teacher profile?"
  },
  student:{
    title:"Are you sure you want to delete the",
    subTitle:"student profile?"
  }
}
exports.deleteProfileSuccess={
  teacher:{
    title:"Profile Deleted Successfully!",
    subTitle:"You have successfully deleted teacher profile of"
  },
  student:{
    title:"Profile Deleted Successfully!",
    subTitle:"You have successfully deleted student profile of"
  }
}
exports.deleteProfileError="This teacher contains classes and students"
exports.noRecords="No records found"
exports.btnExampleTitle = "CLICK ME";
exports.GET="GET"
exports.getMethod="GET"
exports.DELETE="DELETE"
exports.getTableData="bx_block_peoplemanagement2/people_managements/get_teacher_student_accounts"
exports.deleteTeacherProfile="bx_block_peoplemanagement2/people_managements/delete_teacher"
exports.deleteStudentProfile="account_block/add_users/delete_account"
exports.getTeacherClasses="bx_block_content_management/teacher_flows/teacher_class_index"
exports.getStudentClasses="bx_block_peoplemanagement2/people_managements/student_classes_list"
exports.getTeacherStudents="bx_block_peoplemanagement2/people_managements/get_students_under_teacher_class"
exports.deleteStudentAccount="account_block/add_users/delete_account"
exports.createStudent = "account_block/add_users/create_student_by_teacher"
exports.POST="POST";
exports.getFormClassList = "bx_block_content_management/teacher_flows/current_user_classes_list"
exports.formErrors={
  fullName:"Please enter full name.",
  email:"Please enter a valida email ID.",
  phone:"Please enter phone number.",
  class:"Please select class.",
  fieldPassword:`Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)`,
  confirmPassword:"The password and confirm password do not match.",
  currentPassword:"The current password should not be empty if you are trying to change the password.",
  profileStatus:"Please select profile status."
}
exports.updateStudent = "account_block/accounts/update_student_by_teacher"
exports.PUT="PUT"
// Customizable Area End