Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email is required!";
exports.errorPasswordNotValid = "Password is required!";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "Login With Google";
exports.labelOr = "Or continue with";
exports.labelTitle = "Hi! Welcome to";
exports.signupLabelTitle = "Join us now!";
exports.labelSubTitle = "Enter your credentials to log in";
exports.subTitle = "Enter your credentials to log in";
exports.signupSubTitle = "Enter below details to create an account."
exports.errorEnterCredentials= "Email and password are required"
exports.errorNameRequired= "Name is required"
exports.errorEmailRequired= "Email is required"
exports.errorPasswordRequired= "Password is required"
exports.errorConfirmPasswordRequired= "Confirm Password is required"
exports.errorEmailInvalid= "Invalid email format"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.signupAPiEndPoint = "account_block/accounts";
exports.clientID =
  "736706773581-hjp6ns0gias2ifc5bc8tc6bkcm1h3ltg.apps.googleusercontent.com";
exports.googleLoginUrl = "bx_block_login/logins"
exports.checkAccountUrl = "bx_block_login/logins/check_account_with_access_token"
exports.checkAccessForLesson = "bx_block_content_management/teacher_flows/show_lesson"
exports.checkAccessForQuiz = "bx_block_content_management/teacher_flows/show_quiz"
exports.checkAccessForAssignment = "bx_block_content_management/assignments"
exports.rememberMeTxt = "Remember Me"
// Customizable Area End