import React from "react";
import { Box, Modal, Tooltip, Typography } from '@material-ui/core';
import { calenderIconActive, crossicon1,   } from '../../../../components/src/assets';
import { download, greenCheck, redCross } from "../assets";
import DownloadModal from "./DownloadModal.web";
import AnalyticsController from "../AnalyticsController.web";
import { styled } from "@material-ui/styles";

const StudentText = styled(Typography)({
  padding: "8px 10px",
  color: "#1e252b",
  "&:hover": {
    backgroundColor: "#2b3d7d",
    color: "white !important",
    borderRadius: "10px",
  },
  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
});


const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '-10px 10px 20px 20px',
});


const StyledRedCrossImage = styled('span')({
  display:'flex',
  '@media (max-width: 768px)': {
    whiteSpace:'nowrap',
    alignItems:'center'
  },
});

const StyledStudentBlock = styled('div')({
  padding: '10px 20px',
  background: '#f5f5f5',
  width: '100%',
  borderRadius: '12px',
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    width: '100%',
    padding: 0
  },
});

const StyledStudentDetailContainer = styled('div')({
  width: '100%',
  height: '100vh',
  overflow: 'scroll',
  scrollbarColor: '#2B3D7D white',
  '@media (max-width: 768px)': {
    height: 'auto',
    marginBottom: '20px',
  },
});

const StyledStudentDetail = styled('div')({
  borderRadius: '12px',
  border: '1px solid #cccccc',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  width: '100%',
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

const StyledStudentName = styled('p')({
  margin: '0',
  fontSize: '18px',
  color: '#e3e7ea',
  background: '#2b3d7d',
  padding: '10px',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  fontWeight: 700,
  fontFamily: 'HelveticaNeue',
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
});

const StyledBlockAnswer = styled('div')({
  margin: '0',
  fontSize: '18px',
  background: '#f5f5f5',
  padding: '10px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
});



class DetailReport extends AnalyticsController {


  formatDate1 = (date: Date) => {
    const day1 = date.getDate();
    const month1 = date.getMonth() + 1;
    const year1 = date.getFullYear();
    return `${month1}/${day1}/${year1}`;
  };
   GraphicCardsUI = ( item:any[], name:any ) => {
    return (
      <>
       {item.length > 0 && (
        <div style={webStyle.blockContainer}>
          <p style={webStyle.blockTitle1}>{name}</p>
          {item.map((data, index) => (
            <div key={index} style={webStyle.blockAnswer}>
              <div style={webStyle.QContainer}>
                <p style={webStyle.Question}>
                  Question {index + 1}: {data.question}
                </p>
              </div>
              <li style={webStyle.graphicTitle}>Answers grouped per title</li>
              {data.student_answer &&
                data.student_answer.map((aitem:any, idx:any) => (
                  <div key={idx}>
                    <p style={webStyle.Question}>{aitem.question}</p>
                    <p style={webStyle.answer}>
                      {aitem.topic ? `${aitem.topic}:` : ""} {aitem.answer}
                    </p>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
      </>
    );
  };
  
  McqAnswer = (mcq_answers: any[]) => {
    return (
      <>
        {mcq_answers.length > 0 && (
          <div style={webStyle.blockContainer}>
            <p style={webStyle.blockTitle1}>Multiple Choice Questions</p>
            {mcq_answers.map((item, index) => (
              <div key={index} style={webStyle.blockAnswer}>
                <div style={webStyle.QContainer}>
                  <p style={webStyle.Question}>
                    <span style={{ fontWeight: 700 }}>Question {index + 1}:</span> {item.question}
                  </p>
                  <img
                    src={item.student_answer === item.answer ? greenCheck : redCross}
                    alt="check or cross"
                  />
                </div>
                <p style={webStyle.answer}>Answer: {item.answer}</p>
                <p
                  style={
                    item.student_answer === item.answer ? webStyle.answerGreen : webStyle.answerRed
                  }
                >
                  Student answer:{" "}
                  {item.student_answer ? (
                    item.student_answer
                  ) : (
                    <span style={{ color: 'red' }}>
                      No Answer <img src={redCross} alt="no answer" />
                    </span>
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };
  
   MatchingAnswer = ( item :any[]) => {
    return (
      <>
              {item.length > 0 && (
        <div style={webStyle.blockContainer}>
          <p style={webStyle.blockTitle1}>Matching Questions</p>
          <div style={{...webStyle.blockAnswer,borderBottomLeftRadius:0,borderBottomRightRadius:0}}>

               <div style={webStyle.QContainer}>
                <p style={webStyle.Question}>
                  Question {1} : {"No specific question provided"}
                </p>
              </div>
          </div>
          {item.map((data, index) => (
            <div
              key={index}
              data-test-id={`pair-${index + 1}`}
              style={{...webStyle.blockAnswer,paddingBottom:0,paddingTop:0,borderBottomLeftRadius:0,borderBottomRightRadius:0}}
            >              
            <div style={webStyle.answerContainer}>
                <p style={webStyle.answer}>
                  Pair {index + 1}: {data.question} - {data.student_answer}
                </p>
                <img
                  src={data.correct_answer ? greenCheck : redCross}
                  alt={data.correct_answer ? 'Correct' : 'Incorrect'}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      </>
    );
  };
  StudnetSelect = (index: number) => {
    this.handleSelection(index)
    const myElement = document.getElementById(`stundent-${index}`);
    const topPos = myElement ? myElement.offsetTop : 0;
    const element = document.getElementById('abc');
    if (topPos && element) {
      element.scrollTop = topPos - 230;
    }
  }
   PollingAnswer = (item:any[] ) => {
    return (
      <>
 {item.length > 0 && (
        <div style={webStyle.blockContainer}>
          <p style={webStyle.blockTitle1}>Polling</p>
          {item.map((data, index) => (
            <div key={index} style={webStyle.blockAnswer}>
              <div style={webStyle.QContainer}>
                <p style={webStyle.Question}>
                  Question {index + 1}: {data.question}?
                </p>
              </div>
              <p style={webStyle.answer}>
                Student Answer:
                <span> </span>
                {data.student_answer ? (
                  data.student_answer
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    No Answer
                    <span style={{ marginLeft: '5px' }}> </span>
                    <img src={redCross} alt="Red Cross" />
                  </span>
                )}
              </p>
            </div>
          ))}
        </div>
      )}
      </>
    );
  };
   WordCloudAnswer = (item:any[] ) => {
    return (
      <>
        {item.length > 0 && (
        <div style={webStyle.blockContainer}>
          <p style={webStyle.blockTitle1}>Word Cloud</p>
            {item.map((data, groupIndex) => (
            <div key={groupIndex} style={webStyle.blockAnswer}>
              <div style={webStyle.QContainer}>
                <p style={webStyle.Question}>
                  Question {groupIndex + 1} : {data.question || "No specific question provided"}
                </p>
              </div>
              {data.student_answer &&
                data.student_answer.map((ans:any, ansIndex:any) => (
                  <p key={ansIndex} style={webStyle.answer}>
                    Answer {ansIndex + 1} : {ans.answer}
                  </p>
                ))}
            </div>
          ))}
        </div>
      )}
      </>
    );
  };
   OpenEndedAnswer = ( item :any[]) => {
    return (
      <>
        {item.length > 0 && (
          <div style={webStyle.blockContainer}>
            <p style={webStyle.blockTitle1}>Open Ended Question</p>
            {item.map((data, index) => (
              <div key={index} style={webStyle.blockAnswer}>
                <div style={webStyle.QContainer}>
                  <p style={webStyle.Question}>
                    Question {index + 1}: {data.question} 
                  </p>
                </div>
                <p style={webStyle.answer}>
                  Student answer:{' '}
                  {data.student_answer ? 
                    data.student_answer
                 : 
                 (<StyledRedCrossImage >No Answer<span style={{marginLeft: '5px' }}>{" "}  </span>  <img src={redCross} /></StyledRedCrossImage>)}
                  
                </p>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };
  
  StudentSection = (detailReportList: any[]) => {
    
    return (
      <Box sx={webStyle.studentBlock}>
        <div style={webStyle.studentTitle}>Students</div>
        {detailReportList?.map((item: any, index: number) => (
          <StudentText
            key={index}
            data-test-id="student-text"
            style={this.state.selectedStudent === index ?
              { cursor: 'pointer', color: "white", backgroundColor: "#2b3d7d", borderRadius: "10px" } :
              { cursor: 'pointer', color: "#2b3d7d" }} onClick={() => this.StudnetSelect(index)}
          >
            {index >= 10 ? index + 1 : `0${index + 1}`} {item?.student_name}
          </StudentText>
        ))}
      </Box>
    )
  }
  detailreportdata=()=>{
    return(
      <>
      {this.StudentSection(this.state.detailReportList)}
      <div style={webStyle.studentDetailContainer} ref={this.studentsDetailReportRef} id="abc" data-test-id="students-detail-report-container" onScroll={this.handleScroll}>
        {this.state.detailReportList.map((item, index) => (<div style={webStyle.studentDetail} id={`stundent-${index}`} key={`stundent-${index}`}>
          <p style={webStyle.studentName}> {index >= 10 ? index + 1 : `0${index + 1}`} {item.student_name}</p>
          {item.mcq_answers && this.McqAnswer(item.mcq_answers)}
          {item.open_ended_answers && this.OpenEndedAnswer(item.open_ended_answers)}
          {item.word_cloud_answers && this.WordCloudAnswer(item.word_cloud_answers)}
          {item.kwl_chart_answers && this.GraphicCardsUI(item.kwl_chart_answers, "KWL Chart")}
          {item.venn_diagram_answers && this.GraphicCardsUI(item.venn_diagram_answers, "Venn Diagram")}
          {item.wh_chart_answer && this.GraphicCardsUI(item.venn_diagram_answers, "WH-Chart")}
          {item.seed_discussion_answer && this.GraphicCardsUI(item.seed_discussion_answer, "Seed Discussion")}
          {item.t_chart_answers && this.GraphicCardsUI(item.t_chart_answers, "T-Chart")}
          {item.matching_question_answers && this.MatchingAnswer(item.matching_question_answers)}
          {item.polling_question_answers && this.PollingAnswer(item.polling_question_answers)}
        </div>
      ))}
      </div></>
    )
    
  }
  detailReportData=()=>{
    return(
      <>
      
      {this.state.detailReportList.length === 0 ? (
          <Typography style={webStyle.noRecords}>No records found</Typography>
        ) :(
          this.detailreportdata()
        )
          
          }
          </>
    )
  }
  renderStudentDetail = (item: any, index: number) => (
    <StyledStudentDetail id={`stundent-${index}`}>
      <StyledStudentName>{index >= 10 ? index + 1 : `0${index + 1}`} {item.student_name}</StyledStudentName>
      {item.mcq_answers && <StyledBlockAnswer>{this.McqAnswer(item.mcq_answers)}</StyledBlockAnswer>}
      {item.open_ended_answers && <StyledBlockAnswer>{this.OpenEndedAnswer(item.open_ended_answers)}</StyledBlockAnswer>}
      {item.word_cloud_answers && <StyledBlockAnswer>{this.WordCloudAnswer(item.word_cloud_answers)}</StyledBlockAnswer>}
      {item.kwl_chart_answers && <StyledBlockAnswer>{this.GraphicCardsUI(item.kwl_chart_answers, "KWL Chart")}</StyledBlockAnswer>}
      {item.venn_diagram_answers && <StyledBlockAnswer>{this.GraphicCardsUI(item.venn_diagram_answers, "Venn Diagram")}</StyledBlockAnswer>}
      {item.wh_chart_answer && <StyledBlockAnswer>{this.GraphicCardsUI(item.venn_diagram_answers, "WH-Chart")}</StyledBlockAnswer>}
      {item.seed_discussion_answer && <StyledBlockAnswer>{this.GraphicCardsUI(item.seed_discussion_answer, "Seed Discussion")}</StyledBlockAnswer>}
      {item.t_chart_answers && <StyledBlockAnswer>{this.GraphicCardsUI(item.t_chart_answers, "T-Chart")}</StyledBlockAnswer>}
      {item.matching_question_answers && <StyledBlockAnswer>{this.MatchingAnswer(item.matching_question_answers)}</StyledBlockAnswer>}
      {item.polling_question_answers && <StyledBlockAnswer>{this.PollingAnswer(item.polling_question_answers)}</StyledBlockAnswer>}
    </StyledStudentDetail>
  );

  renderDetailReport = () => (
    <StyledStudentDetailContainer id='abc'>
      {this.state.detailReportList.map((item, index) => this.renderStudentDetail(item, index))}
    </StyledStudentDetailContainer>
  );

  renderStudentSection = () => (
    <StyledStudentBlock>
      {this.state.detailReportList.length === 0 ? (
        <Typography style={webStyle.noRecords}>No records found</Typography>
      ) : (
        <div style={{ padding: "10px" }}>
          <div style={webStyle.studentTitle}>Students</div>
          {this.state.detailReportList.map((item, index) => this.renderStudentText(item, index))}
        </div>
      )}
    </StyledStudentBlock>
  );

  renderStudentText = (item: any, index: number) => (
    <StudentText
      data-test-id="student-text"
      style={this.state.selectedStudent === index ?
        { cursor: 'pointer', color: "white", backgroundColor: "#2b3d7d", borderRadius: "10px" } :
        { cursor: 'pointer', color: "#2b3d7d" }}
      onClick={() => this.StudnetSelect(index)}
    >
      {index >= 10 ? index + 1 : `0${index + 1}`} {item?.student_name}
    </StudentText>
  );
  render() {
    const isDownloadBtnDisabled = !this.state.detailReportList.length;
    const toolTipText = isDownloadBtnDisabled ? "No report could be found for students, please add one to download" : "";

    return (
      <div>
        <StyledContainer>
          <Box style={webStyle.dateContainer1}>
            <img src={calenderIconActive} alt="calendar icon" />
            <p>Created on</p>
            <p style={webStyle.date1}>{this.formatDate1(new Date())}</p>
          </Box>

          <Tooltip title={toolTipText} data-test-id="tooltip-text">
            <img
              data-test-id="download-btn"
              src={download}
              style={{ opacity: isDownloadBtnDisabled ? 0.5 : 1}}
              onClick={isDownloadBtnDisabled ? () => {} : this.handleOpenModal}
              alt="download icon"
            />
          </Tooltip>
        </StyledContainer>
    
        <div style={{
            margin: "-10px 10px 20px 20px",
            display: "flex",
            justifyContent: "space-around",
        }}
        >{this.detailReportData()}
        </div>
       
     
        <Modal open={this.state.openModal} onClose={this.handleCloseModal}>
          <DownloadModal
            data-test-id="option-modal"
            handleCloseModal={this.handleCloseModal}
            csvSucessShow={this.state.csvSucessShow}
            getExportReportApicall={this.handleDownloadPDF}
          />
        </Modal>
      </div>
    );
  }
};

export default DetailReport;

const webStyle = {
  dateContainer1: {
    display: "flex",
    background: "#F4F7FF",
    padding: "5px",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center"
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  } as React.CSSProperties,
  datatable: {
    padding: '0px',
    boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
    borderRadius: '8px',
    margin: '0px 15px',
    overflow: "scroll", scrollbarColor: "#2B3D7D white", width: "100%",
  },
  createdOn1: {
    color: "#757575",
    fontFamily: "Poppins",
    fontSize: '16px',
    margin: "0px 10px",
  },
  exportBtn: {
    cursor: 'pointer', margin: '10px 0px', padding: '10px 30px',
    borderRadius: '8px', color: '#fff', fontSize: '18px', fontWeight: 'bold', fontFamily: 'Poppins',
    border: "none",
  },
  date1: {
    color: "#2B3D7D",
    fontSize: '16px',
    fontFamily: "Poppins",
    fontWeight: "bold",
    padding: "5px",
    background: "#CED8FC",
    margin: "5px",
    borderRadius: "8px",
  } as React.CSSProperties,
  studentTitle: {
    width: "100%",
    color: "#1e252b",
    fontSize: "18px",
    marginBottom: "5px",
    paddingBottom: "10px",
    borderBottom: "2px solid #e3e5ea",
  },
  studentBlock: {
    padding: "10px 20px",
    background: "#f5f5f5",
    width: "15%",
    height: "fit-content",
    borderRadius: "12px",
    "@media (max-width: 600px)": {
     padding: 0,
     width:"max-content"
    },
  },
  studentDetail: {
    borderRadius: "12px",
    border: "1px solid #cccccc",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    width: "100%",
  },
  studentDetailContainer: {
    width: "70%",
    height: "100vh",
    overflow: "scroll",
    scrollbarColor: "#2B3D7D white",
    scrollBehavior: "smooth" as const
  },
  studentName: {
    margin: "0",
    fontSize: "18px",
    color: "#e3e7ea",
    background: "#2b3d7d",
    padding: "10px",
    borderTopRightRadius: "12px",
    borderTopLeftRadius: "12px",
    fontWeight:700,
    fontFamily: 'HelveticaNeue'
  },
  blockContainer: {
    padding: "10px",
  },
  blockTitle1: {
    margin: "0",
    fontSize: "18px",
    background: "#d5d8e5",
    padding: "10px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    color:'#000000',
    fontWeight:700
  },
  blockAnswer: {
    margin: "0",
    fontSize: "18px",
    background: "#f5f5f5",
    padding: "10px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  },
  QContainer: {
    background: "white",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    display: "flex",
    gap: "10px"
  },
  Question: {
    margin: "0",
  },
  answerContainer: {
    display: "flex",
    gap: "10px"
  },
  answer: {
    fontSize: "16px",
    padding: "10px",
    display:'flex',
    alignItem:'center'
  },
  answerGreen: {
    fontSize: "16px",
    padding: "10px",
    color: "#00cab5",
    fontWeight:500
  },
  answerRed: {
    fontSize: "16px",
    padding: "10px",
    color: "#fe575a",
  },
  graphicTitle: {
    fontSize: "18px",
    padding: "10px",
    color: "#2b3d7d",
    fontWeight:700
  }
}


