import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, TextField, Grid, Button, Typography } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CustomWordCloud from "../../../../../components/src/CustomWordCloud.web";
import StaticCanvas from "../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  wcTitle: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "10px",
  },
  wcQuestion: {
    fontSize: "18px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "20px",
  },

  wcMainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    padding: "30px 60px",
    '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
      height:"auto !important"
    },
  },
  wcMainLayoutAns: {
    display: "flex",
    height: "100%",
    paddingTop: "10%",
    paddingLeft:"15px",
    paddingRight:"15px",
  },
  wcContentBox: {
    width: "620px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "30px 60px",
    position:"relative"
  },
  wcContentBoxAns: {
    width: "100%",
    backgroundColor: "#FFF",
    display: "flex",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "80px 30px 30px 30px",
    position:"relative"
  },
  wcInputValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  wcSubmitBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  wcSubmitBtnLayout: {
    marginTop: "25px",
    cursor:"pointer"
  },
  wcDisabledBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    opacity:"0.5",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  optionWC: {
    display: "flex",
    width: "initial",
    justifyContent: "space-between",
    borderRadius: "50px",
    border: "1px solid rgba(43, 61, 125, 24%)",
    fontFamily: "Poppins",
    fontSize: "14px",
    marginBottom: "15px",
    padding: "5px 10px",
    "& img":{
      width:"28px",
      height:"28px",
      borderRadius:"50%",
    },
    alignItems:"center",
    cursor:"pointer",
    "&.submited":{
      backgroundColor:"rgba(63, 82, 109, 0.06)"
    }
  },
  optionTitleWC: {
      padding: "5px 10px"
  },
  optionLayoutWC: {
    width: "100%",
    marginBottom: "10px",
  },
  wordCanvas:{
    paddingTop:"60px"
  }
}));
const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "50px",
      },
    },
  },
});

interface answerObj {
  id:number,
  lesson_markup_tools_id:number,
  answers:[{answer:string}]
}

const showStudentAnswers = (props: any,classes:any) => {
  const { studentAnswer,stage} = props;
  const thisStageAnswer = studentAnswer.find((answerObj:answerObj)=>answerObj.lesson_markup_tools_id == stage.id) as answerObj;
  
  return (thisStageAnswer && 
    <Box className={classes.optionLayoutWC}>
    
          {thisStageAnswer.answers.map((answer,index) => (
            <Box                  
                key={`${answer.answer}-${index}`}
                className={`${classes.optionWC} submited`}
            >
                <Typography className={classes.optionTitleWC}>{answer.answer}</Typography>
            </Box>
          ))}
    </Box>)
}

const showSubmitButton=(canAnswer:boolean,showQuestion:boolean,classes:any,handleSubmit:any)=>{
  return(
    canAnswer && <Box className={classes.wcSubmitBtnLayout}>
            <Button  className={!showQuestion? classes.wcDisabledBtn :classes.wcSubmitBtn} onClick={showQuestion && handleSubmit}>Submit</Button>
          </Box>
  )
}
const StudentWordCloud = (props: any) => {
  const { stage, showQuestion,showResult,showAnswers,wordCloudAnswer,stageAnswers, isGuest } = props;
  const hasCanvasDataTitle = stage?.attributes?.canvas_data?.canvas
  const canAnswer = (stageAnswers??[]).length<=0 && !isGuest;
  const [height,setHeight] = useState(0);
  const hasCanvasData = stage?.attributes?.canvas_data;
  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }

  const classes = useStyles();
  const [wordCloudState,setWordCloudState]=React.useState(() => stage?.attributes.description || [])
  const mdValue = stage?.attributes.description.length >= 6 ? 6 : 12;
  const backgroundImage=stage?.attributes.background_image?.url
  const handleInputChange = (id: string, event: any) => {
    const index = parseInt(id.split("-")[1]) - 1;
    const updatedState = wordCloudState.map((item:any, idx:number) => {
      if (idx === index) {
        return { ...item, answer: event.target.value };
      }
      return item;
    });
    setWordCloudState(updatedState);
  };

  const handleSubmit = () => {
    const submissionData = wordCloudState.map((item:any) => ({
      answer: item.answer
    }));

    props.addChartSubmitData(submissionData);
  };



  return (
    <>
    <Box
      className={showAnswers?classes.wcMainLayoutAns:classes.wcMainLayout}
      style={backgroundImage && { 
        backgroundImage: `url("${backgroundImage}")`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
       }}
    >
      <Box className={showAnswers?classes.wcContentBoxAns:classes.wcContentBox}>
        <Typography className={classes.wcTitle}>{showResult?"Word Cloud Question Results":"Word Cloud"}</Typography>
        <Box>
          {  jsonCanvas &&
        <Box style={{position:"absolute",width:"90%",height:"100%",}}>
          <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}
          callbackCanvas={setHeight}
          />
          </Box>}
          <Box
            className=  {`${classes.wcQuestion} ${hasCanvasDataTitle && classes.wordCanvas}`}
            dangerouslySetInnerHTML={{
              __html: stage?.attributes.title,
            }}
            style={{marginBottom:`${height}px`}}
          />
        </Box>
        {showAnswers && showStudentAnswers(props,classes)}
        {!showAnswers && 
          (showResult ? <CustomWordCloud words={wordCloudAnswer}/> : 
          <>
          <Grid container spacing={2}>
            {stage?.attributes.description.map((item: any, index: number) => (
              <Grid key={index} item md={mdValue}>
                <FormControl fullWidth variant="outlined">
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      id={`answer-${index + 1}`}
                      name={`answer-${index + 1}`}
                      data-test-id={"option-name"}
                      fullWidth
                      placeholder={`Answer ${index+1}`}
                      variant="outlined"
                      onChange={(e) => handleInputChange(`topic-${index + 1}`, e)}
                      className={classes.wcInputValue}
                      inputProps={{ style: { borderRadius: "50px" } }}
                      value={wordCloudState[index].answer}
                      disabled={!canAnswer}
                    />
                  </MuiThemeProvider>
                </FormControl>
              </Grid>
            ))}
          </Grid>
          { showSubmitButton(canAnswer,showQuestion,classes,handleSubmit)}
          </>
          )
        }        
      </Box>
    </Box>
    </>
  );
};

export default StudentWordCloud;
