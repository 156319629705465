import AnalyticsController from "../AnalyticsController.web";
import React from "react";
import TeacherClassStudentsListing from "./ClassStudentsListing.web";
import { getStorageData } from "../../../../framework/src/Utilities";

interface Props {
    navigation: () => void;
    id: string;
    handleClick: () => void;
}
export default class TeacherMain extends AnalyticsController {

    constructor(props: Props) {
        super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);

    }
    // Customizable Area Start
    async componentDidMount() {
        let id:any=window.localStorage.getItem("id")
        let classId:any=window.localStorage.getItem("classId")
        let startDate = await getStorageData("startDateRange")
        let endDate = await getStorageData("endDateRange")
        this.setState({
            teacherId:id,
            classId:classId,
            startDate,
            endDate
        })
        this.getStudentListData(id, classId,startDate,endDate);
        
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <TeacherClassStudentsListing 
            handleClick={this.props.handleClick}
            handleSubmitSelectStudent={this.handleSubmitSelectStudent}
            reportType={this.state.reportType}
            pageLimitStudents={this.state.pageLimitStudents}
            handleStudentsPageChange={this.handleTeacherStudentsPageChange}
            handleSearchInput={this.handleTeachersStudentSearchInput}
            studentListData={this.state.studentListData}
            classTitle={this.state.classTitle}
            getSummaryReportListDataApicall={this.getSummaryReportListData}
            getDetailReportListData={this.getDetailReportListData}
            pageNoStudents={this.state.pageNoStudents}
            handleChange={this.handleChange}
            handleSelectAll={this.handleSelectAll}
            data={this.state.studentListData}
            selectAll={this.state.selectAll}
            selectedIds={this.state.selectedIds}
            />
            // Customizable Area End
        );
    }
}