import React, { useEffect, useState } from "react";
import Checkbox from "../components/Checkbox.web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box
} from '@material-ui/core';
import BasicPagination from "../../../accountgroups/src/BasicPagination.web";
import { SearchSection } from "../../../dashboard/src/SchoolAdmin/SchoolAdminDashboard.web";
import { MY_CLASSES_VIEWS } from "../../../../components/src/types";
import { makeStyles } from "@material-ui/core/styles";

type TextTransformProperty = "capitalize"
type TextAlignProperty = 'center'

const webStyles = {
  dataStyle: {
    padding: '0px',
    borderRadius: '8px',
    boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
    margin: '0px 15px',
    width: "auto"
  },
  tableHead: {
    backgroundColor: '#fdf4dc',
  },
  tHeading: {
    fontFamily: "Poppins",
    color: '#202020',
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center' as TextAlignProperty
  },
  tableDetail: {
    fontFamily: "Poppins",
    color: '#2c2c2e',
    fontSize: '14px',
    textAlign: 'center' as TextAlignProperty,
    fontWeight: 400,
    textTransform: "capitalize" as TextTransformProperty
  },
}

const useStyles = makeStyles((theme) => ({
  searchLayout: {
      margin: '-10px 10px 20px 20px', 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
          margin: 0,
          flexDirection: "column"
        },
  },
  btnStyle: {
    cursor: 'pointer',
    margin: '10px 0px', 
    padding: '15px', 
    borderRadius: '8px', 
    color: '#fff', 
    fontSize: '18px', 
    fontWeight: 'bold', 
    fontFamily: 'Poppins', 
    background: 'linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A',
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      fontSize: "14px",
      marginBottom: 30
    },
  },
  tHeading: {
    fontFamily: "Poppins",
    color: '#202020',
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center' as TextAlignProperty,
    [theme.breakpoints.down('xs')]: {
      whiteSpace: "nowrap"
    },
  },
}));
interface Student {
  id: string;
  selected: boolean;
}
const TeacherClassStudentsListing = (props: any) => {
  const classes = useStyles();
let [report,setReport]=useState(window.localStorage.getItem("reportTypeSummary"))
  const { handleClick, studentListData, handleSearchInput,
    handleStudentsPageChange, pageLimitStudents, pageNoStudents, getSummaryReportListDataApicall,
    handleChange, data, selectAll, selectedIds, handleSelectAll,getDetailReportListData } = props;
  let teacherName = window.localStorage.getItem("fullName");
  let classTitle = window.localStorage.getItem("classTitle");
  const columnsLabel: { label: string; }[] = [
    { label: "Student Name" },
    { label: "Class" },
    { label: "Teacher" },
  ];
  const pageNo = 1
  useEffect(()=>{
    setReport(window.localStorage.getItem("reportTypeSummary"))
  })
  return (
    <div>
      <div className={classes.searchLayout}>
        <SearchSection handleSearchInput={handleSearchInput} placeholder='Search by student name' />
        <button
          data-test-id="btnAddGroupvalue" type="submit"
          onClick={(e) => {
            handleClick(e, report=="true" ?{
              view: MY_CLASSES_VIEWS.REPORT,
              breadcrumbDisplayName: "Reports",
              destination: "Summary Report",
            }:{
              view: MY_CLASSES_VIEWS.REPORT,
              breadcrumbDisplayName: "Reports",
              destination: "Detailed Report",
            })
           {
            report=="true"?getSummaryReportListDataApicall({
              student_ids: selectedIds?.length > 0 ? selectedIds :
                studentListData?.map((item: any) => { return item.id })
            }):getDetailReportListData({
              student_ids: selectedIds?.length > 0 ? selectedIds :
                studentListData?.map((item: any) => { return item.id })
           } )}
          }}
          className={classes.btnStyle}>
          {report=="true" ? "Generate Summary Report" : " Generate Detailed Report"}
        </button>
      </div>
      <TableContainer style={webStyles.dataStyle} component={Paper}>
        <Table>
          <TableHead style={webStyles.tableHead}>
            <TableRow>
              <TableCell style={webStyles.tHeading}>S.No.</TableCell>
              {columnsLabel.map((column, index) => (
                <TableCell key={index} style={webStyles.tHeading}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell data-test-id="selectButton" onClick={handleSelectAll}
                className={classes.tHeading} ><div style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }} >Select All <Checkbox variant="green" checked={selectAll} /></div></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columnsLabel.length + 2} style={{ textAlign: 'center' }}>
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell style={webStyles?.tableDetail}>{(pageNo - 1) * 10 + index + 1}</TableCell>
                  <TableCell style={webStyles?.tableDetail}>{row?.attributes?.full_name}</TableCell>
                  <TableCell style={webStyles?.tableDetail}>{classTitle}</TableCell>
                  <TableCell style={webStyles?.tableDetail}>{teacherName}</TableCell>
                  <TableCell >
                    <Box data-testid="selecOption" style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }} onClick={() => handleChange(row)}>
                      <Checkbox checked={row.selected} variant="green" />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        marginBottom: "50px",
        marginTop: "100px",
      }}>
        <BasicPagination
          pageLimit={pageLimitStudents}
          pageNo={pageNoStudents}
          handlePageChange={handleStudentsPageChange}
        />
      </div>
    </div>
  );
};

export default TeacherClassStudentsListing;
