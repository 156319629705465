import React, { useState } from "react";
import { Box, Typography, Grid, Modal } from "@material-ui/core";
import { defaultImage, calendarIcon, CloseIcon } from "../assets";
import Checkbox from "./Checkbox.web";
import Calendar from "react-calendar";
import { styled } from "@material-ui/styles";
import dayjs from "dayjs";
import ClearIcon from "@material-ui/icons/Clear";
import { removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { userDefaultIcon1 } from "../../../dashboard/src/assets";
const classes = {
  closeIcon: {
    cursor: "pointer",
  },
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
  },
  innerModal:{
    padding: "20px 40px",
    "@media (max-width: 600px)": {
      padding: "20px 0px"
  },
  },
  dateRange:{
    color: "#254050",
    fontSize: "14px",
    cursor: "pointer",
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      fontSize: "13px"
  },
  },
  innerModalText:{
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#000000D9",
    "@media (max-width: 600px)": {
        fontSize: "18px",
        textAlign:"center",
        marginLeft:"20px"  
    },
  },
  submitBtn:{
    width: "100%",
    cursor: "pointer",
    margin: "40px 0px",
    padding: "15px",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    background:
      "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
    fontFamily: "Poppins",
  },
  listItemBoxSelected: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #338A9C",
    borderRadius: "10px",
    margin: "20px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "0px 0px 10px 0px rgba(49, 122, 149, 0.18)",
    cursor: "pointer",
    background: "rgba(147, 221, 255, 0.19)",
    color: "#121212",
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  listItemImage: {
    width: "100%",
    height: "100%",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#fff",
  },
  modalsStyles: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "16px",
    "@media (max-width: 600px)": {
      maxWidth: "90%", 
      padding: "10px",  
      minWidth: 0
    },
    "@media (max-width: 430px)": {
      maxWidth: "90%",  
      padding: "8px",
      minWidth: 0
    },
  
  },
  crossIconOption: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
};

interface ClassListSelectedProps {
  classList: any;
  selected: string;
  handleModalClose: any;
  handleCloseVisible: any;
  visiblevalue: any;
  getClassDetails: any;
  internalDate:any;
  setInternalDate: any;
  fetchDatesAndGetDetails:Function
}

const StyledBox = styled(Box)({
  "@media (max-width: 430px)": {
    minWidth: "298px",
  },
  "@media (min-width: 431px)": {
    minWidth: "415px",
  },
  "@media (min-width: 451px)": {
    minWidth: "415px",
  },
  "@media (min-width: 525px)": {
    minWidth: "500px",
  },
});

const ClassListSelected: React.FC<ClassListSelectedProps> = ({
  classList,
  selected,
  handleModalClose,
  visiblevalue,
  handleCloseVisible,
  getClassDetails,
  internalDate,
  setInternalDate,
  fetchDatesAndGetDetails
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(true);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [reportTypeSummary, setReportTypeSummary] = useState(true);

  const handleClick = async () => {
    await fetchDatesAndGetDetails();

    
    handleModalClose({
      reportType: reportTypeSummary ? "summary" : "detailed",
      dateRange: "",
    });
    
    setIsVisibleModal(false);
    setInternalDate(null)
  };
  

  return (
    <div>
      <Grid container>
        {classList?.map((teacher: any) => (
          <Grid item md={4} sm={6} xs={12}>
            <Box
              data-testid="class-item"
              sx={
                teacher.attributes.class_name === selected
                  ? classes.listItemBoxSelected
                  : classes.listItemBox
              }
              onClick={() =>
                getClassDetails(teacher.attributes.class_name, teacher.id)
              }
            >
              <Box style={classes.imageBox}>
                <img
                  style={classes.listItemImage}
                  src={
                    teacher.attributes.attachment
                      ? teacher.attributes.attachment
                      : userDefaultIcon1
                  }
                />
              </Box>
              <Typography
                data-testid={"teacher-fullname"}
                style={classes.listItemFont}
              >
                {teacher.attributes.class_name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {visiblevalue ? (
        <>
          <Modal open={isVisibleModal} data-testid="modal" onClose={handleCloseVisible}>
            {!openCalendarModal ? (
              <Box sx={classes.modalsStyles}>
                <StyledBox sx={classes.modalsStyles}>
                  <div
                    style={{
                      padding: "20px 24px",
                      borderBottom: "1px solid #D7E1EA",
                    }}
                    data-testid="modalBox"
                  >
                    <div
                      style={{
                        color: " #2c3f58",
                        fontSize: "24px",
                        fontWeight: 700,
                        lineHeight: "36px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Generate Report{" "}
                    </div>
                    <Box sx={classes.crossIconOption}>
                      <ClearIcon onClick={handleCloseVisible} />
                    </Box>
                  </div>
                  <div
                    style={{
                      padding: "15px 24px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      borderBottom: "1px solid #D7E1EA",
                    }}
                  >
                    <div
                      style={{
                        color: " #778699",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select Report
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "13px 17px",
                        border: reportTypeSummary
                          ? "1px solid #2B3D7D"
                          : "1px solid rgba(43, 61, 125, 0.39)",
                        borderRadius: "8px",
                      }}
                      data-testid="checkBoxContainer"
                      onClick={() => setReportTypeSummary(!reportTypeSummary)}
                    >
                      <Checkbox
                        checked={reportTypeSummary}
                        data-testid="checkBox"
                      />
                      <div
                        style={{
                          color: "#254050",
                          alignItems: "center",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                        }}
                        data-testid="checkBoxTitle"
                      >
                        Summary Report
                      </div>
                    </div>
                    <div
                      style={{
                        marginBottom: "5px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "13px 17px",
                        border: reportTypeSummary
                          ? "1px solid rgba(43, 61, 125, 0.39)"
                          : "1px solid #2B3D7D",
                        borderRadius: "8px",
                      }}
                      data-testid="checkBoxContainer2"
                      onClick={() => setReportTypeSummary(!reportTypeSummary)}
                    >
                      <Checkbox checked={!reportTypeSummary} />
                      <div
                        data-testid="checkBoxTitle"
                        style={{
                          color: "#254050",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Detailed Report
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "15px 24px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    data-testid="rangeContainer"
                  >
                    <div
                      data-testid="rangeHeading"
                      style={{
                        color: " #778699",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select date range
                    </div>
                    <div
                      data-testid="rangeButton"
                      onClick={() => setOpenCalendarModal(true)}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "13px 17px",
                        border: "1px solid rgba(43, 61, 125, 0.39)",
                        borderRadius: "8px",
                      }}
                    >
                      <img src={calendarIcon} />
                      <div
                        style={{
                          color: "#254050",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontFamily: "Poppins",
                        }}
                        data-testid="rangeButtonTitle"
                      >
                        {internalDate !== null
                          ? `${dayjs(internalDate[0]).format(
                              "DD/MM/YYYY"
                            )} - ${dayjs(internalDate[1]).format("DD/MM/YYYY")}`
                          : "Select range"}
                      </div>
                    </div>
                    <button
                      data-testid="submitButton"
                      onClick={handleClick}
                      type="submit"
                      style={{
                        cursor: "pointer",
                        margin: "10px 0px",
                        padding: "15px",
                        borderRadius: "8px",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        background:
                          "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select Students
                    </button>
                  </div>
                </StyledBox>
              </Box>
            ) : (
              <Box sx={classes.modalsStyles}>
                <Box sx={classes.innerModal}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      margin: "25px 0px",
                    }}
                  >
                    <Box sx={classes.innerModalText}
                    >
                      Select Dates to Generate Report
                    </Box>
                    <img
                      style={classes.closeIcon}
                      src={CloseIcon}
                      onClick={() => {
                        setOpenCalendarModal(false);
                        setInternalDate(null);
                        removeStorageData("adminStartDate")
                        removeStorageData("adminEndDate")
                      }}
                      data-testid="closeButton"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      alignItems: "center",
                      padding: "13px 17px",
                      border: "1px solid rgba(43, 61, 125, 0.39)",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={classes.dateRange}
                    >
                      {internalDate !== null
                        ? `${dayjs(internalDate[0]).format(
                            "DD/MM/YYYY"
                          )} - ${dayjs(internalDate[1]).format("DD/MM/YYYY")}`
                        : "Select range"}
                    </Box>
                    <img src={calendarIcon} />
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Calendar
                        onChange={setInternalDate}
                        value={internalDate}
                        returnValue={"range"}
                        selectRange={true}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      if (internalDate) {
                        const formattedStartDate = dayjs(internalDate[0]).format("DD/MM/YYYY");
                        const formattedEndDate = dayjs(internalDate[1]).format("DD/MM/YYYY");
                        setStorageData("adminStartDate", formattedStartDate);
                        setStorageData("adminEndDate", formattedEndDate);
                      }
                      setOpenCalendarModal(false);
                    }}
                    data-test-id="btnAddGroup"
                    type="submit"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      margin: "40px 0px",
                      padding: "15px",
                      borderRadius: "8px",
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "bold",
                      background:
                        "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
                      fontFamily: "Poppins",
                    }}
                  >
                    Done
                  </button>
                </Box>
              </Box>
            )}
          </Modal>
        </>
      ) : null}
    </div>
  );
};

export default ClassListSelected;
