import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { userDefaultIcon1 } from "../../assets";
const useStyles = makeStyles(() => ({
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column"
  },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
    "@media (max-width: 600px)": {
      textAlign: "center"
  },
  },
  listItemImage: {
    width: "100%",
    height: "100%",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#fff",
  },
}));
const ClassList = (props: any) => {
  const { classList, getClassDetails, handleOpenVisible } = props;
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        {classList?.map((teacher: any) => (
          <Grid item md={4} sm={6} xs={12}>
            <Box
              data-testid="class-item"
              className={classes.listItemBox}
              onClick={() =>{
                !!handleOpenVisible ? handleOpenVisible(teacher.attributes.class_name, teacher.id) :
                getClassDetails(teacher.attributes.class_name, teacher.id, teacher)
              }}
            >
              <Box className={classes.imageBox}>
                <img
                  className={classes.listItemImage}
                  src={
                    teacher.attributes.attachment
                      ? teacher.attributes.attachment
                      : userDefaultIcon1
                  }
                />
              </Box>
              <Typography
                data-testid={"teacher-fullname"}
                className={classes.listItemFont}
              >
                {teacher.attributes.class_name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ClassList;
