import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { deleteIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    right: "20px",
    top: "20px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      right: "10px",
      top: "10px",
    },
  },
  headerContainer: {
    display: "flex",
    marginBottom: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  paper: {
    position: "absolute",
    width: "380px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "10px 14px",
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding: "10px",
    },
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Poppins",
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  title: {
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: "bold",
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    },
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
      gap: '9px'
    },
  },
  textareaLayout: {
    "&::placeholder": {
      color: "#2C2C2E",
      fontSize: "14px",
      fontFamily: "Poppins",
      [theme.breakpoints.down('xs')]: {
        fontSize: "12px",
      },
    },
    width: "100%",
    height: "309px",
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #C0C0C0",
    resize: "none",
    fontFamily: "Poppins",
    [theme.breakpoints.down('xs')]: {
      height: "250px",
    },
  },
  imgLayout: {
    display: "flex",
    width: "16%",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      justifyContent: "end",
    },
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const AddEditNotesModal = (props: any) => {
  const {
    handleChangeNotesInput,
    openDeleteModal,
    addNotes,
    notesList,
    data,
    updateNoteData,
    handleCloseAddEditModal
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [error,setError] = React.useState<string>("")

  const handleSave = (id: any, notes: string) => {
    const pattern = /^(?!\s*$).+/;
    if (!pattern.test(notes)) {
      setError("Please enter a valid note");
      return;
    }
    setError("")
    if (data.type === "Add") {
      addNotes(id, notes)
    } else if (data.type === "Edit") {
      updateNoteData(notesList.id, notes)
    }
  }

  return (
    <>
      <Box style={modalStyle} className={classes.paper} >
        <Box className={classes.headerContainer}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.heading}>{data.name}</Typography>
            <Typography className={classes.title}>{data.type} Notes</Typography>
          </Box>
          <Box className={classes.imgLayout}>
            {data.type === "Edit" && (
              <Box className={classes.iconStyle} onClick={openDeleteModal} >
                <img src={deleteIcon} style={{ width: "20px", height: "20px" }} />
              </Box>
            )}
            <Box className={classes.iconStyle} onClick={handleCloseAddEditModal}>
              <ClearIcon
                data-test-id="close-modal"
              />
            </Box>
          </Box>

        </Box>
        <textarea
          className={classes.textareaLayout}
          placeholder="Add your notes here"
          value={notesList.note}
          data-test-id="change-input"
          onChange={(e) => { 
            setError("")
            handleChangeNotesInput(e.target.value) }}
        ></textarea>
        {error && (
          <Typography color="error" style={{ marginBottom: '10px',fontFamily:"Poppins" }}>
            {error}
          </Typography>
        )}
        <Box className={classes.btnLayout}>
          <GenericButton
            type="NORMAL"
            label="Cancel"
            handleClick={handleCloseAddEditModal}
            customStyle={{ width: "150px" }}
          />
          <GenericButton
            data-test-id="save-btn"
            type="COLORED"
            label="Save Notes"
            handleClick={() => handleSave(data.id, notesList.note)}
            customStyle={{ width: "150px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AddEditNotesModal;
