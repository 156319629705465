import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { profileCreated, profileUpdated } from "../../assets";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "./utils";
const configJSON = require("../../config");
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "5px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  subTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#3F526D",
    fontWeight: 400,
    width: "390px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      width: "100%",
      padding: "0 10px",
    },
  },
  image: {
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
    },
  },
  paper: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "20px 0",
    },
  },
  crossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    [theme.breakpoints.down("sm")]: {
      top: "5px",
      right: "5px",
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    position: "relative",
  },
}));

const CreateStudentSuccessModal = (props: any) => {
  const { handleCloseModal, fullName, isUpdateRequest } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <ClearIcon onClick={handleCloseModal} className={classes.crossIcon} />
        <Box className={classes.popupContent}>
          <img
            className={classes.image}
            src={isUpdateRequest ? profileUpdated : profileCreated}
          />
          <Typography className={classes.title}>
            {isUpdateRequest
              ? configJSON.profileUpdatedTitle
              : configJSON.profileCreatedTitle}
          </Typography>
          <Typography className={classes.subTitle}>
            {isUpdateRequest
              ? `Changes made to student profile named ${fullName} has been successfully saved.`
              : `Student named ${fullName}  has been successfully added.`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateStudentSuccessModal;
