export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainImage= require("../assets/main_image1.svg")
export const logoIcon= require("../assets/logo1.svg")
export const logoTitle= require("../assets/logo2.svg")
export const logo= require("../assets/logo.svg")
export const star= require("../assets/star.png")
export const left_arrow= require("../assets/left_arrow.svg")
export const right_arrow= require("../assets/right_arrow.svg")
export const googleIcon= require("../assets/google.svg")
export const errorIcon= require("../assets/erroe.svg")
export const greenTickIcon= require("../assets/greenTick.png")
export const crossIcon= require("../assets/close-icon.svg")
export const logoMini= require("../assets/logo-white.svg")
export const continueWith = require("../assets/continue-with.svg")
