import AnalyticsController from "../AnalyticsController.web";
import React from "react";
import SummeryReportListing from "./SummeryReportList.web";

interface Props {
    navigation: () => void;
    id: string;
    handleClick: () => void;
}

export default class SummeryReportMain extends AnalyticsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <SummeryReportListing
                summaryReportList={this.state.summaryReportList}
                openModal={this.state.openModal}
                handleCloseModal={this.handleCloseModal}
                handleOpenModal={this.handleOpenModal} 
                csvSucessShow={this.state.csvSucessShow}
                getExportReportApicall={this.getExportReportApicall}
            />
        );
    }
}