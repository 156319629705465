export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const fullscreenLogo = require("../assets/fullscreen.svg");
export const participants = require("../assets/participants.svg");
export const question = require("../assets/question.svg");
export const comments = require("../assets/comments.svg");
export const back = require("../assets/back.svg");
export const front = require("../assets/front.svg");
export const copy = require("../assets/copy.svg");
export const timer = require("../assets/timer.svg");
export const defaultQuestion = require("../assets/question_default.svg");
export const venn = require("../assets/venn.svg");
export const blueTick = require("../assets/blueTick.png");
export const greenTick = require("../assets/greenTick.png");
export const defaultImage = require("../assets/default_image.jpg");
export const trueTick = require("../assets/true.png");
export const falseTick = require("../assets/false.png");
export const studentProfile = require("../assets/sprofile.png");
export const arrowImage = require("../assets/arrow1.png");
export const viewFullscreenLogo = require("../assets/view_fullscreen.svg");
export const audioImage = require("../assets/audio.png");
export const quizMusic = require("../assets/EngagemoQuizMusic.wav")

