import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { defaultImage, imgPasswordInVisible, imgPasswordVisible, quizMusic } from "./assets";
import firebase from "firebase";
import { ACTIONS, Roles } from "../../../components/src/types";
import storage from "../../../framework/src/StorageProvider";
import { gapi } from 'gapi-script';


interface StageAnswer {
  correct_answer: string | null,
  answers : {answer:string}[],
  message?: string
}

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  room: string;
  lessonId:string;
  role: string|null;
  messages:any[];
  stages:any[];
  currentStage:any;
  showQuestion:boolean
  stageIndex:number;
  studentName:string;
  studentProfileImage:string;
  lessonName:string;
  className:string;
  parentStageStudent:any;
  success:boolean;
  leaveLessonModal:boolean;
  studentId:string;
  showResult:boolean;
  pollingResult:  {
    "options_id": number,
    "option": string,
    "percentage": number
  }[];
  screen:string,
  studentAnswer:any[]
  wordCloudAnswer:{text:string,value:number}[]
  stageAnswers:StageAnswer[],
  lessonStudentStartTime:string,
  isQuizStudent:boolean,
  isNewStage:boolean,
  scoreData:{
    current_stage_score:number,
    total_score:number
  }
  seconds:number,
  isPlaying:boolean,
  studentSubStages:any[],
  currentSubStageIndex:number,
  attachMentFiles:{fileName:string,url:string}[],
  chatData:{id:string,room:string,name:string,profileImage:string,message:string}|undefined,
  isDataLimitModal: boolean,
  isGuest:boolean,
  isSignedIn: boolean,
  saveRepsonses: any,
  checked: boolean,
  redirectToDashboard: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CflivestreamingsubfeatureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  unsubscribeFunctions: any[]=[]
  apiGetStudentDetails:string=""
  apiSubmitAnswerForChartStage:string=""
  createLibraryApiId:string=""
  pollingQuestionApiId:string=""
  studentAnswerApiId:string=""
  wordCloudAnswerApiId:string=""
  stageAnswerApiId:string=""
  quizScoreApiId:string=""
  uploadFileApiId:string=""
  createQuizLibraryApiId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      role:"",
      room:'',
      lessonId:"",
      messages:[],
      stages:[],
      currentStage:null,
      stageIndex:0,
      showQuestion:false,
      studentProfileImage:"",
      studentName:"",
      lessonName:"",
      className:"",
      parentStageStudent:null,
      success:false,
      leaveLessonModal:false,
      studentId:"",
      showResult:false,
      pollingResult:[],
      screen:"Lesson",
      studentAnswer:[],
      wordCloudAnswer:[],
      stageAnswers:[],
      lessonStudentStartTime:"",
      isQuizStudent:false,
      isNewStage:false,
      scoreData:{
        current_stage_score:0,
        total_score:0
      },
      seconds:0,
      isPlaying:false,
      studentSubStages:[],
      currentSubStageIndex:0,
      attachMentFiles:[],
      chatData:undefined,
      isGuest:false,
      isDataLimitModal: false,
      isSignedIn: false,
      saveRepsonses: [],
      checked: false,
      redirectToDashboard: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let cfResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let cfError= message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
    const cfCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    ); 
    this.handleGetStudentDetailsResponse(cfCallId,cfResponse,cfError)
    this.handleChartSubmitAnswerResponse(cfCallId,cfResponse,cfError)
    this.handleCreateLibraryResponse(cfCallId,cfResponse,cfError)
    this.handleAnswerResponse(cfCallId,cfResponse,cfError)
    this.pollWordResponse(cfCallId,cfResponse)
    this.uploadFileResponse(cfCallId,cfResponse)
    this.handlequiCreateLibrary(cfCallId,cfResponse,cfError)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let isGuest = window.location.href.includes("guestJoined")
    const guestName = await storage.get("GuestName")
    const guestId = await storage.get("GuestId")
    if (window.localStorage.getItem("authToken") === null && !isGuest) {
      this.props.navigation.navigate("EmailAccountLogin");
    }
    if(isGuest){
      if(!guestName || !guestId){
        this.props.navigation.navigate("EmailAccountLogin");
      }
      storage.set("role",Roles.STUDENT)
    }
    this.setState({
      role:isGuest?Roles.STUDENT:window.localStorage.getItem("role"),
      isGuest:isGuest
    })
    const id = this.props.navigation.getParam("id")
    const isQuizStudent = id.includes('quiz')
    this.setState({
      lessonId:id,
      room:`LIVE_${id}`,
      isQuizStudent: isQuizStudent
    })
    if(isGuest){
      this.sendStudentInfoToFirebase(guestId,guestName,defaultImage)
      this.setState({
       studentName:guestName,
       studentProfileImage:defaultImage 
      })
    }
    else{
      this.getStudentDetails() 
    }
    this.initFirebase()
    this.loadClientInfo()

  }
  
  async componentWillUnmount() {
    this.unsubscribeFunctions.forEach(unsubscribe=>unsubscribe())
  }
  getParentStageStudent = (curStageStudent?:any)=>{
    let parentStageStudent = this.state.parentStageStudent
    if(curStageStudent && curStageStudent?.attributes?.stage_number){
      let stageNum = curStageStudent?.attributes?.stage_number;
      if(/^\d+$/.test(stageNum))
      {
        parentStageStudent = curStageStudent
      }
      else{
        stageNum = stageNum.toString().match(/^\d+/)[0];        
        parentStageStudent = this.state.stages.find((curStageStudent:any)=>
        curStageStudent.attributes.stage_number == stageNum
        )
      }
    }
    return parentStageStudent
  }
  getStudentSubStages = (parentString:string) => {
    return this.state.stages.filter((curStage:any)=>
      curStage.attributes.stage_number.toString().match(/^\d+/)[0] == parentString
      && curStage.attributes.stage_number != parentString
    )
  }
  
  async awaitStateUpdate() {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (this.state.stages.length>0) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
  }
  async initFirebase(){
    const id = this.props.navigation.getParam("id")
    const roomId=`LIVE_${id}`
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyADj2MO1ayerqP0BnVuVgdl_cQBhYd2GD4",
        authDomain: "iqlearn-2ada0.firebaseapp.com",
        projectId: "iqlearn-2ada0",
        storageBucket: "iqlearn-2ada0.appspot.com",
        messagingSenderId: "273787809764",
        appId: "1:273787809764:web:48c68a55c65a154d3ee1f1",
        measurementId: "G-F5K4FKLMB5"
      });
    }

    const db = firebase.firestore();

    const unsubscribeLesson = db
    .collection("lesson")
    .where("room", "==", roomId)
    .onSnapshot((querySnapshot) => {
      const lessonStages = querySnapshot.docs.map((doc) => doc.data());
      if(lessonStages.length){
        const sortedLessonStages = lessonStages[0].data.sort(
          (a: any, b: any) =>
            a.attributes.stage_number - b.attributes.stage_number
        );
        this.setState({stages:sortedLessonStages,lessonStudentStartTime:lessonStages[0].lessonStartTime??"",screen:"Lesson"})
      }
    }); 
    
    await this.awaitStateUpdate();
    this.unsubscribeFunctions=[
      unsubscribeLesson
    ]
    this.addFireBaseListener(db,roomId)
  }

  addFireBaseListener = (db:firebase.firestore.Firestore,roomId:string) => {
    const unsubcribeCurrentStage = db
    .collection("currentStage")
    .where("room", "==", roomId)
    .onSnapshot((querySnapshot) => {
      
      this.setState({studentAnswer:[],stageAnswers:[]})
      const currentStageData = querySnapshot.docs.map((doc) => doc.data());
      const curStage = currentStageData[0]?.stage;
      const stageNum = curStage?.attributes?.stage_number;
      if(!curStage) return
      const stageType = this.state.isQuizStudent?curStage?.attributes.quiz_stage_name as ACTIONS:curStage?.attributes.lesson_stage_name as ACTIONS
      if(stageType==ACTIONS.MULTIMEDIA){
        this.setState({
          seconds:currentStageData[0]?.seconds ?? 0,
          isPlaying:currentStageData[0]?.isPlaying ?? false
        })
      }
      if(!this.state.isQuizStudent && this.state.currentStage && stageNum && (this.state.showQuestion == currentStageData[0]?.showQuestion)&& stageNum.match(/^\d+/)[0]==this.state.currentStage.attributes.stage_number.match(/^\d+/)[0] && !(/^\d+$/.test(stageNum)))
      {
        if(currentStageData[0]?.showResult != this.state.showResult && stageType!=ACTIONS.OPEN_ENDED_QUESTION){
          this.goToParticularSubstage(curStage.id,currentStageData[0]?.showResult)
        }
        return
      }
      
      if(currentStageData[0]){
        this.getAnswersAndSetStage(currentStageData[0],stageType)
      }
    });
    

    const unsubscribeMessage = db
      .collection("messages")
      .where("room", "==", roomId)
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        const updatedComments = querySnapshot.docs.map((doc) => doc.data());
        this.setState({messages:updatedComments.filter(msg=>msg.id==this.state.studentId)})
      });

      this.unsubscribeFunctions=[
        ...this.unsubscribeFunctions,
        unsubcribeCurrentStage,
        unsubscribeMessage,
      ]
  }

  getDataForResults = (curStage:any,stageType:string) => {
    if(this.state.isGuest) return
    if(stageType== ACTIONS.POLLING_Q){
      this.getPollingQuestionResult(curStage.id)
    }

    if(this.state.isQuizStudent){
      this.getQuizScore(curStage?.id)
      this.getStudentAnswer(this.state.studentId,curStage.id)
    }
    else{  
      this.getStudentAnswer(this.state.studentId,curStage.id)
      if(curStage.attributes.lesson_stage_name == ACTIONS.ADD_WORD_CLOUD){
        this.getWordCloudAnswer(curStage.id)
      }
    }
  }
  getAnswersAndSetStage = (currentStageData:any,stageType:string) => {
    const curStage = currentStageData.stage;
    const parentStageStudent = this.getParentStageStudent(curStage)
    if(currentStageData.showResult){
      this.getDataForResults(curStage,stageType)
    }
    else if(curStage.id){
      this.getStudentAnswer(this.state.studentId,curStage.id,false)
    }
    let isNewStage = true;
    if(this.state.currentStage){
      isNewStage = this.state.currentStage.id != curStage.id
    }
    let subStages=this.getStudentSubStages(curStage.attributes.stage_number)
    if(!isNewStage && subStages.length>0 && !this.state.showQuestion && currentStageData.showQuestion)
    {
      this.setState({
        showQuestion:true,
        isNewStage:isNewStage,
        parentStageStudent:parentStageStudent,
      },this.nextStage)
    }
    else{
      this.setState({
        currentStage:currentStageData.stage,
        lessonName:currentStageData.lessonName,
        className:currentStageData.className,
        showQuestion:currentStageData.showQuestion,
        showResult:currentStageData.showResult ?? false,
        parentStageStudent:parentStageStudent,
        screen:currentStageData.screen ?? "Lesson",
        isNewStage:isNewStage,
        studentSubStages:subStages,
        currentSubStageIndex:0
      })
    }
  }

  checkFirebaseForUserRemove = (roomId:string) => {
    const db = firebase.firestore();
    const unsubscribeUsers = db
      .collection("users")
      .where("room", "==", roomId)
      .onSnapshot((querySnapshot) => {
        const curParticpants = querySnapshot.docs.map((doc) => doc.data());
        const curStudent = curParticpants.find(stud=>stud.id==this.state.studentId)
        if(!curStudent){
          this.redirectToPage()
        }
      });
      this.unsubscribeFunctions=[...this.unsubscribeFunctions,unsubscribeUsers]
  }

  goToParticularSubstage = (id:string,showResult:boolean) => {
    const stageIndex = this.state.studentSubStages.findIndex(stage=>stage.id==id)
    const curStage = this.state.studentSubStages[stageIndex]
    this.getStudentAnswer(this.state.studentId,curStage.id,showResult)
    this.setState({
      currentStage:curStage,
      currentSubStageIndex:stageIndex+1,
      showResult:showResult
    })
  }
  nextStage=()=>{
    if(!this.state.showQuestion) return
    const nextInd=this.state.currentSubStageIndex
    if(this.state.studentSubStages.length>nextInd){
      const curStage = this.state.studentSubStages[nextInd]
      this.getStudentAnswer(this.state.studentId,curStage.id,false)
      this.setState({
        currentStage:curStage,
        currentSubStageIndex:nextInd+1,
        showResult:false
      })
    }
  }
  prevStage=()=>{
    if(!this.state.showQuestion) return
    const prevInd=this.state.currentSubStageIndex-2
    if(prevInd>=0 && this.state.studentSubStages.length>0){
      const curStage = this.state.studentSubStages[prevInd]
      this.getStudentAnswer(this.state.studentId,curStage.id,false)
      this.setState({
        currentStage:curStage,
        currentSubStageIndex:prevInd+1,
        showResult:false
      })
    }
  }
  
  getStudentDetails(){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetStudentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentDetails}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async sendStudentInfoToFirebase(id:string,name:string,profileImage:string){
    const db = firebase.firestore();
    this.setState({studentId:id})
     try {
       // Check if the user with the given id already exists in the room
       const querySnapshot = await db.collection("users")
                                     .where("id", "==", id)
                                     .where("room", "==", this.state.room)
                                     .get();
   if (querySnapshot.empty) {
     // If no matching document found, add the new user entry
     const userEntry = {
       room: this.state.room,
       id: id,
       name: name,
       profileImage: profileImage,
       createdAt: firebase.firestore.FieldValue.serverTimestamp()
     };
     await db.collection("users").add(userEntry);
     console.log("New user added successfully");
   } else {
     console.log("User with ID already exists in the room");
   }
   this.checkFirebaseForUserRemove(this.state.room)
     } catch (error) {
       console.error("Error adding user:", error);
     }
  }
  handleGetStudentDetailsResponse(apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if(apiRequestCallId != null && apiRequestCallId === this.apiGetStudentDetails){
        console.log("Akash",responseJson)
        if(responseJson?.errors?.length>0){
          return
        }
        if (responseJson.data) {
         this.sendStudentInfoToFirebase(responseJson.data.id,responseJson.data.attributes.full_name,responseJson.data.attributes.image)
         this.setState({
          studentName:responseJson.data.attributes.full_name,
          studentProfileImage:responseJson.data.attributes.image ? responseJson.data.attributes.image: defaultImage 
         })
        } 
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }
  addChartSubmitData(data:any){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    
      const body=this.state.isQuizStudent?{
        quiz_id: this.state.currentStage.attributes.quiz_id,
        quiz_markup_tools_id: this.state.currentStage.id,
        answers: data
        }:{
      lesson_id: this.state.currentStage.attributes.lessons_id,
      lesson_markup_tools_id: this.state.currentStage.id,
      answers: data
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      this.apiSubmitAnswerForChartStage = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.isQuizStudent?`${configJSON.submitQuizAnswerApiEndPoint}`:`${configJSON.submitAnswerApiEndPoint}`
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChartSubmitAnswerResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiSubmitAnswerForChartStage)
    ) {
      if (responseJson) {
        if(responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB'){
          this.setState({
            isDataLimitModal: true
          })
        }
        else
        {
        this.showSuccessMessage()
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    else if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.quizScoreApiId)
    ) {
      if (responseJson) {
        this.setState({
          scoreData:responseJson
        })
      } 
    }
  }
  handlequiCreateLibrary(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.createQuizLibraryApiId)
    ) {
      if (responseJson) {
        if(responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB'){
          this.setState({
            isDataLimitModal: true,
            redirectToDashboard: true
          })
        }
        else
        {
        this.redirectToPage()
        }
      } 
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }

  
  pollWordResponse = (apiRequestCallId: string,
    responseJson: any) => {
      if (
        apiRequestCallId != null &&
        (apiRequestCallId === this.pollingQuestionApiId)
      ) {
        if (responseJson) {
          this.setState({pollingResult:responseJson ?? []})
        } 
      }
      else if (
        apiRequestCallId != null &&
        (apiRequestCallId === this.wordCloudAnswerApiId)
      ) {
        if (responseJson) {
          let wordsArray:{text:string,value:number}[] = [];
          responseJson.forEach((element:{text:string,value:number}) => {
            wordsArray.push(element)
          });
          this.setState({wordCloudAnswer:wordsArray ?? []})

        } 
      }
    }
  uploadFileResponse = (apiRequestCallId: string,
    responseJson: any) => {
      if (
        apiRequestCallId != null &&
        (apiRequestCallId === this.uploadFileApiId)
      ) {
        if (responseJson) {
          if(responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB'){
            this.setState({
              isDataLimitModal: true
            })
            return
          }
          this.setState({
            attachMentFiles:[{
              fileName:this.state.attachMentFiles[0].fileName,
              url:responseJson.url
            }]
          },()=>{
            this.state.chatData && this.sendChatMessage(this.state.chatData,[],true)
          })
        } 
      }

  }
  handleCreateLibraryResponse = (apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) => {
      if (
        apiRequestCallId != null &&
        (apiRequestCallId === this.createLibraryApiId)
      ) {
        if (responseJson) {
          if(this.state.checked){    
            this.uploadJsonToDrive(responseJson)
          }
          this.redirectToPage("Catalogue")
        } 
      }
      else if (
        apiRequestCallId != null &&
        (apiRequestCallId === this.studentAnswerApiId)
      ) {
        if (responseJson?.message){
          this.setState({studentAnswer:[]})
        }
        else{
          const thisLessonAns=responseJson.filter((x:any)=>x.lesson_id==this.state.currentStage.attributes.lessons_id)
          this.setState({studentAnswer:thisLessonAns ?? [],stageAnswers:thisLessonAns})
        } 
      }
      
  }
  handleAnswerResponse = (apiRequestCallId: string,
    responseJson: {message?:string | StageAnswer[] },
    errorReponse: string) => {
      if (apiRequestCallId != null && (apiRequestCallId === this.stageAnswerApiId)
      ) {
        if (responseJson?.message || !responseJson){
          this.setState({studentAnswer:[],stageAnswers:[]})
        }
        else{
          const response = responseJson as StageAnswer[] ;
          const thisLessonAns= response.filter((x:any)=>x.lesson_id==this.state.currentStage.attributes.lessons_id)
          this.setState({studentAnswer:thisLessonAns ?? []})
          this.setState({stageAnswers:response ?? []})
        } 
      }
      
  }
  showSuccessMessage(fetchAnswers:boolean=true,showDataLimitError:boolean=false){
    if(showDataLimitError){ 
      this.setState({
        isDataLimitModal:true
      })
      return
    }
    this.setState({success:true})
    if(fetchAnswers){
      this.getStudentAnswer(this.state.studentId,this.state.currentStage?.id,false)
    }
  }
  hideSuccessMessage(){
    this.setState({success:false})
  }
  modalClose=()=>
  {
    this.setState({leaveLessonModal:false})
  }
  modalOpen=()=>
  {
    this.setState({leaveLessonModal:true})
  }

  redirectToPage = (pageName:string="Dashboard") => {
    if(this.state.isGuest){
      pageName="HomePage"
    }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    const db = firebase.firestore();
    db
    .collection('users')
    .where("id", "==", this.state.studentId)
    .where("room", "==", this.state.room)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.delete();
      })
    })
  }

  uploadJsonToDrive = async (
    jsonResponse: any
  ) => {
    
    const randomNum = Math.floor(Math.random() * 900) + 100;
  
    let fileName: string;   
    fileName = `${randomNum}_Live_${jsonResponse.data.attributes.lesson.data.attributes.lesson_name}`;
  
    const jsonString = JSON.stringify(jsonResponse, null, 2); 
    const blob = new Blob([jsonString], { type: "application/json" });
  
    const fileMetadata = {
      name: `${fileName}.json`,
      mimeType: "application/json",
    };
  
    const formData = new FormData();
    formData.append(
      "metadata",
      new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
    );
    formData.append("file", blob);
  
    const accessToken = gapi.auth.getToken().access_token;
  
    await fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
      {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
        body: formData,
      }
    );

  };

  createStudentLibrary = (checked:boolean) => {
    if(checked){
      this.setState({checked})
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const body={
      lesson_id: this.state.currentStage.attributes.lessons_id
    }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      this.createLibraryApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.studentLibraryCreateApiEndPoint}`
      );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPollingQuestionResult=(toolId:number)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pollingQuestionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isQuizStudent?`${configJSON.quizPollingAnswerApiEndPoint}?quiz_markup_tools_id=${toolId}`:`${configJSON.studentPollingQuetionApiEndPoint}?lesson_markup_tools_id=${toolId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setScreen=(screenName:string)=>{
    this.setState({screen:screenName})
  }
  getStudentAnswer = (studentId: string,lessonMarkupToolId:string,forResult: boolean = true) => {
    if(this.state.isGuest) return
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    if (forResult) {
      this.studentAnswerApiId = requestMessage.messageId;
    } else {
      this.stageAnswerApiId = requestMessage.messageId;
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isQuizStudent?`${configJSON.quizAnswerApiEndPoint}?quiz_markup_tools_id=${lessonMarkupToolId}`:`${configJSON.studentGetAnswerApiEndPoint}?account_id=${studentId}&lesson_markup_tools_id=${lessonMarkupToolId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  getWordCloudAnswer=(stageId:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wordCloudAnswerApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.wordclouAnswersApiEndPoint}?lesson_markup_tools_id=${stageId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getQuizScore = (toolId:number) => {
    if(this.state.isGuest) return
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.quizScoreApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quizScoreApiEndPoint}?quiz_markup_tool_id=${toolId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  uploadFileFromChat = (files:File[])=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData= new FormData();
    formData.append("file",files[0])
    this.setState({
      attachMentFiles:[{
        fileName:files[0].name,
        url:""
      }]
    })
    this.uploadFileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.uploadChatFileEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    const header = {
      token: window.localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  initClient = () => {
    gapi.client.init({
      clientId: configJSON.WebClienctID,
      apiKey: configJSON.WebAPIKey,
      scope: SCOPES,
      discoveryDocs: DISCOVERY_DOCS,
    }).then(() => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
      this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };
  
  sendChatMessage = async (messageData:{id:string,room:string,name:string,profileImage:string,message:string},attachFiles:File[],hasAttachUrl:boolean=false) => {
    if(attachFiles.length>0) {
      this.setState({
        chatData:messageData
      })
      this.uploadFileFromChat(attachFiles)
      return;
    }
    const db = firebase.firestore();
    
    const messageEntry = {
      ...messageData,
      attachments: hasAttachUrl?this.state.attachMentFiles:[],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    try {
      await db.collection("messages").add(messageEntry);
    } catch (error) {
    }
  }
  createQuizStudentLibrary = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const body={
      quiz_id: this.state.currentStage.attributes.quiz_id
    }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      this.createQuizLibraryApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.quizLibraryCreateApiEndPoint}`
      );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCloseDataLimitModal= () => {
    this.setState({
      isDataLimitModal: false
    })
    if(this.state.redirectToDashboard){
      this.redirectToPage()
    }
  }

  loadClientInfo = () => {
    gapi.load('client:auth2', this.initClient);
  };

 
  updateSigninStatus = (isSignedIn: boolean) => {
    if (isSignedIn) {
      this.setState({isSignedIn: true})
    }
  };

  signInSwitch = () => {
    if(!this.state.isSignedIn){
      gapi.auth2.getAuthInstance().signIn();
    }
  };

  // Customizable Area End
}
