export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const addMatchingQModal = require("../assets/addMatchingQModal.png");
export const addMatchingQShuffleicon  = require("../assets/shuffle.png");
export const addMatchingQAddicon = require("../assets/adoption.png")
export const closeIcon= require("../assets/image_Close.svg");
export const addBackground  =  require("../assets/addBackground.png")
export const pointsIcon  =  require("../assets/pointsIcon.png")
export const timer = require("../assets/timer.png");
export const whiteAddBackground = require("../assets/whiteAddBackground.png");
export const whitePoints = require("../assets/whitePoints.png");
export const whiteDoublePoint = require("../assets/whiteDoublePoint.png");
export const whiteMusic = require("../assets/whiteMusic.png");
export const blueTimer = require("../assets/blueTimer.png");
export const blueDoublePoint = require("../assets/blueDoublePoint.png");
export const blueMusicBtn = require("../assets/blueMusicBtn.png");
export const settingBtn  =  require("../assets/settingBtn.png");
export const cancelBtn  =  require("../assets/cancelBtn.png");
export const whiteSetTimer = require("../assets/whiteSetTimer.png");