import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Modal,useMediaQuery } from "@material-ui/core";
import {
  createActionImage,
  deliverActionImage,
  assessActionImage,
  assignActionImage,
  viewStudentCount,
  assignments,
  quizzes
} from "../../assets";
import OptionsModal from "../components/OptionModal.web";
import CreationModal from "../components/CreationModal.web";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
import AssignNewTask from "./AssignNewTask.web";
import NotForMobile from "../../../../../components/src/NotForMobile.web";
const configJSON = require("../../config");
const useStyles = makeStyles(() => ({
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px",
    minHeight: "275px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    marginRight: "24px",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      width: "100%",
      minHeight: "208px",

    },
  },
  actionName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginTop: "12px",
  },
  actionDescription: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
    textAlign: "center",
  },
  listItemImage: {
    width: "120px",
    height: "120px",
  },
  countBox: {
    display: "flex",
    justifyContent: "start",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    minHeight: "121px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    maxWidth: "210px",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "#FFF3EB",
  },
  displayCount: {
    fontFamily: "Poppins",
    fontSize: "40px",
    color: "#2B3D7D",
    fontWeight: 600,
  },
  countHeading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  imageOption: {
    marginBottom: "10px",
  },
  popupContentOption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px 30px",
    minHeight: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    flexDirection: "column",
    "&:hover": {
      background: "#ebf8fe",
      borderColor:"#3093DDFF",
      color: "#121212"
    }
  },
  titleOption: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "40px",
  },
  optionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  },
  paperOption: {
    position: "absolute",
    width: "740px",
    backgroundColor: "white",// theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },

  crossIconOption: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor:"pointer"
  },
}));
const actionItems = [
  {
    name: "Create",
    description: "Create a new lesson, quiz or assignment",
    image: createActionImage,
    background: "#C2EAF9",
    link: "/course",
  },
  {
    name: "Deliver",
    description: "Deliver a lesson from the library",
    image: deliverActionImage,
    background: "#FFC2BD",
    link: "/course",
  },
  {
    name: "Assess",
    description: "Start a quiz from the library",
    image: assessActionImage,
    background: "#FFE598",
    link: "/course",
  },
  {
    name: "Assign",
    description: "Assign content as individual study material",
    image: assignActionImage,
    background: "#8CF9D4",
    link: "/course",
  },
  {
    name: "Students",
    description: "",
    image: viewStudentCount,
    background: "#FEECE0",
    link: "/course",
  },
];
const ActionItems = (props: any) => {
  const {
    totalStudentCount,
    createLesson,
    setSelectedOption,
    selectedOption,
    getFormClassList,
    formClassList,
    createQuiz,
    createAssignment,
    redirectToLibrary,
    redirectToLibraryassignment
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openAssignModal, setOpenAssignModal] = React.useState<boolean>(false);
  const [label, setLabel] = React.useState<string>("");
  const [openNoMobileModal, setOpenNoMobileModal] = React.useState(false);

  const handleOpenNoMobileModal = () => {
    setOpenNoMobileModal(true);
  };

  const handleCloseNoMobileModal = () => {
    setOpenNoMobileModal(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenAssignModal(false)
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleAssignModal = (values: string) => {    
    if(values == "Create"){
      if (isSmallScreen) {
        handleOpenNoMobileModal();
        setLabel('Create');
    } else {
        handleOpenModal();
    }
    }
    if(values == "Assign"){
      redirectToLibraryassignment()
    
    }
    if (values == "Deliver"||values == "Assess"){ 
      redirectToLibrary()
    }
  }

  const [openCreateModal, setCreateModal] = React.useState<boolean>(false);
  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };
  const handleCreateOpenModal = (selectedOption: string) => {
    getFormClassList()
    setSelectedOption(selectedOption);
    handleCloseModal();
    setCreateModal(true);
  };

  const handleModalData = (name:string) =>{
    // return(
      // <AssignNewTask id="" navigation={"assigntasks"} name={name} />
      // this.props.navigation.navigate("assigntasks")
    // )
  }

  const options = [
    {
      name: "Assignments",
      image: assignments,
      action:"Assignment"
    },
    {
      name: "Quizzes",
      image: quizzes,
      action:"Quiz"
    },
  ];

  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ px: "75px" , pr: isSmallScreen ? "104px" : "75px"}}>
      <Grid container style={{justifyContent:isSmallScreen ? 'center': '',gap:isSmallScreen? 22: 0}}>
        {actionItems.map((data: any) => (
          <Grid item md key={data.name}>
            <Box
            data-testId="AssignModalId"
              className={classes.listItemBox}
              style={{ background: data.background }}
              onClick={()=> handleAssignModal(data.name)}
            >
              <img className={classes.listItemImage} src={data.image} />
              <Typography className={classes.actionName}>
                {data.name}
              </Typography>
              {data.description ? (
                <Typography className={classes.actionDescription}>
                  {data.description}
                </Typography>
              ) : (
                <Typography className={classes.displayCount}>
                  {totalStudentCount}
                </Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <OptionsModal
          data-test-id="option-modal"
          handleCloseModal={handleCloseModal}
          handleCreateOpenModal={handleCreateOpenModal}
        />
      </Modal>
      <Modal open={openCreateModal} onClose={handleCreateCloseModal}>
        <CreationModal
          data-test-id="create-modal"
          handleCloseModal={handleCreateCloseModal}
          createLesson={createLesson}
          selectedOption={selectedOption}
          formClassList={formClassList}
          createAssignment={createAssignment}
          createQuiz={createQuiz}
        />
      </Modal>
      <Modal open={openAssignModal} onClose={handleCloseModal}>
      <Box style={modalStyle} className={classes.paperOption}>
      <Box className={classes.container}>
        <ClearIcon
          onClick={handleCloseModal}
          className={classes.crossIconOption}
        />
        <Box className={classes.popupContentOption}>
          <Typography className={classes.titleOption}>
            {configJSON.selectAssignOption}
          </Typography>
          <Box className={classes.optionsLayout}>
            {options.map((option: any) => (
              <Box key={option.name}>
                <Box
                  data-test-id="action-button1"
                  onClick={(e) => handleModalData(option.action)}
                  className={classes.itemBox}
                >
                  <img src={option.image} className={classes.imageOption} />
                  <Typography className={classes.optionTitle}>
                    {option.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
      </Modal>
      <Modal onClose={handleCloseNoMobileModal} open={openNoMobileModal} data-testid="nomobile-modal" >
             <NotForMobile 
             handleCloseModal={handleCloseNoMobileModal} 
             moduleName={label} />
      </Modal>
    </Box>
  );
};

export default ActionItems;
