import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../ContentManagement/src/Teacher/components/utils";
import GenericButton from "../../../../components/src/GenericButton.web";
import { csvSucessIcon } from "../assets";

const useStyles = makeStyles((theme) => ({
    popupContentOption: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "50px 60px",
        paddingBottom: "20px"
    },
    imageConatiner: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px'
    },

    container: {
        width: "100%",
    },
    paperOption: {
        position: "absolute",
        width: "450px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        display: "flex",
        justifyContent: "center",
        aignItems: "center",
        paddingBottom: "10px"
    },
    inputLabel: {
        fontFamily: "Poppins",
        color: "#2C3F58",
        fontSize: "24px",
        fontWeight: 500,
    },
    crossIconOption: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
    },
    btnWrapper: {
        display: 'flex',
    },
    cancelsBtn: {
        border: "1px solid #2C4881",
        backgroundColor: "#fff",
        borderRadius: "5px",
        color: "#2C4881",
        padding: "10px",
        width: "50%",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        marginRight: "15px",
        cursor: "pointer"
    },
}));


const ExportModal = (props: any) => {
    const { handleCloseModal, csvSucessShow, getExportReportApicall } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    return (
        <Box style={modalStyle} className={classes.paperOption}>
            <Box className={classes.container}>
                <ClearIcon
                    onClick={handleCloseModal}
                    className={classes.crossIconOption}
                />
                <Box className={classes.popupContentOption}>
                    {!csvSucessShow && <Box style={{ width: "100%" }}>
                        <Box style={{
                            marginBottom: "15px",
                            textAlign: "center"
                        }}>
                            <label className={classes.inputLabel}>Do you want to export the summary report?</label>
                        </Box>
                        <Box className={classes.btnWrapper}>
                            <button data-test-id="btnCancel" type="button" className={classes.cancelsBtn} onClick={() => {
                                handleCloseModal()
                            }}>
                                {"Cancel"}
                            </button>
                            <GenericButton
                                data-test-id="submit-btn"
                                type="COLORED"
                                label={`Export Now`}
                               
                                handleClick={() =>  getExportReportApicall()}
                                customStyle={{ width: "50%", fontSize: "18px", }}
                            />
                        </Box>
                    </Box>}
                    {csvSucessShow &&
                        <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <img src={csvSucessIcon} /></Box>
                            <Box style={{
                                marginBottom: "15px",
                                textAlign: "center"
                            }}>
                                <label className={classes.inputLabel}>Your CSV sheet has been exported</label>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
};
export default ExportModal;
