import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { profileUpdated } from "../assets";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#2C2C2E",
    fontWeight: 600,
    marginBottom: "15px",
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#48484A",
    fontWeight: 400,
    width: "390px",
    textAlign: "center",
  },
  image: {
    marginBottom: "15px",
  },
  container: {
    positiobe: "relative",
  },
  crossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ProfileUpdatedModal = (props: any) => {
  const { configJSON, handleCloseModal } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <ClearIcon onClick={handleCloseModal} className={classes.crossIcon} />
        <Box className={classes.popupContent}>
          <img className={classes.image} src={profileUpdated} />
          <Typography className={classes.title}>
            {configJSON.profileUpdated.title}
          </Typography>
          <Typography className={classes.subtitle}>
            {configJSON.profileUpdated.subTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileUpdatedModal;
