import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core";
import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  contebtBoxMain:{
    padding:"80px 30px 30px",
    position:"relative"
  },
  stageName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "10px",
  },
  question: {
    fontFamily: "Poppins",
    fontSize: "21px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "30px",
  },
  multiline: {
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiInputBase-inputMultiline': {
      padding: "5px 10px",
    },
  },
  questionImage: {
    width: "100%",
    height: "70px",
    marginBottom: "30px",
  },
  questionResult: {
    fontWeight: 600,
    fontSize: "16px",
  }
}));
const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "50px",
      },
    },
  },
});
const McqQuestion = (props: any) => {
  const classes = useStyles();
  
  const { currentStage, isPolling, rightAnswerTick, isDescHide } = props;
  const [height,setHeight] = useState(0)

  const hasCanvasData = currentStage.attributes.canvas_data;
  let canvasSize: string | undefined;
  let jsonCanvas: object | undefined;

  if (hasCanvasData) {

      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;

  }
  return (
    <Box className={` ${classes.contebtBoxMain} mcq-result-content-box`}>
      <Typography className={classes.stageName}>
        {isPolling?"Polling Question":"Multiple Choice Question"}
      </Typography>

{  jsonCanvas && <Box style={{position:"absolute",width:"90%",height:"max-content"}}>
  <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
  </Box>}
      <Typography className={`${classes.question} ${isDescHide && classes.questionResult}`} dangerouslySetInnerHTML={{
              __html: currentStage?.attributes?.questions[0]?.question?.question,
             }}>
      </Typography>
      <Grid container spacing={2} style={{marginTop:`${height}px`}}>
        {currentStage?.attributes?.questions[0]?.options.map(
          (option: any, index: number) => {
            const isMultiline = option.answer.length > 43;
            return (
            <Grid key={index} item md={12}>
              <FormControl fullWidth variant="outlined">
                <MuiThemeProvider theme={theme}>
                  <TextField
                    id={`answer-${index + 1}`}
                    name={`answer-${index + 1}`}
                    data-test-id={"option-name"}
                    fullWidth
                    placeholder="Answers"
                    variant="outlined"
                    onChange={() => {}}
                    value={option.answer}
                    disabled={true}
                    multiline={isMultiline}
                    minRows={isMultiline ? 2 : 1}
                    className={`form-control ${classes.multiline} ${isDescHide && option.marked_as_right_answer && 'correct-answer-input'}`}
                    inputProps={{
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: isDescHide ? (isDescHide && option.marked_as_right_answer && "#ffffff") : (rightAnswerTick && option.marked_as_right_answer && "#ffffff"),
                        padding: "15px 20px",
                        background: isDescHide ? (isDescHide && option.marked_as_right_answer && "#34c85a") : (rightAnswerTick && option.marked_as_right_answer && "#34c85a"),
                        borderRadius: "50px",
                        border: isDescHide && option.marked_as_right_answer? "1px solid #34c85a" :"",
                      },
                    }}
                  />
                </MuiThemeProvider>
              </FormControl>
            </Grid>
            )
          }
        )}
      </Grid>
    </Box>
  );
};

export default McqQuestion;
