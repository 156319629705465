export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteProfileImage = require("../assets/delete_teacher_profile_image.svg");
export const deleteProfileSuccessImage = require("../assets/delete_teacher_profile_success.svg");
export const searchIcon = require("../assets/searchIcon.png");
export const defaultImage = require("../assets/default_image.jpg");
export const filterIcon = require("../assets/filterIcon.svg");
export const deleteOptionActive = require("../assets/delete_option_active.svg");
export const deleteOptionInactive = require("../assets/delete_option_inactive.svg");
export const editOptionActive = require("../assets/edit_option_active.svg");
export const editOptionInactive = require("../assets/edit_option_inactive.svg");
export const passwordOptionActive = require("../assets/preview_option_active.svg");
export const passwordOptionInactive = require("../assets/preview_option_inactive.svg");
export const viewDetailOptionActive = require("../assets/view_option_active.svg");
export const viewDetailOptionInactive = require("../assets/view_option_inactive.svg");
export const errorAlert = require("../assets/error_alert.svg");
export const profileCreated = require("../assets/profile_created.svg");
export const profileUpdated = require("../assets/profile_updated.svg");
export const failedCross = require("../assets/failedCross.svg")
