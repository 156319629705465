export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const defaultImage = require("../assets/default_image.jpg");
export const filterIcon = require("../assets/filterIcon.svg");
export const studentsOption = require("../assets/students.svg");
export const assignmentsOption = require("../assets/assignments.svg");
export const reportsOption = require("../assets/reports.svg");
export const classLibraryOption = require("../assets/class_library.svg");
export const errorAlert = require("../assets/error_alert.svg");
export const profileCreated = require("../assets/profile_created.svg");
export const profileUpdated = require("../assets/profile_updated.svg");
export const deleteConfirmationImage = require("../assets/delete_profile_confirmation.svg");
export const deleteSuccessImage = require("../assets/delete_profile_success.svg");
export const reminderSent = require("../assets/reminder_sent.svg");
export const reminderConfirmation = require("../assets/reminder_confirmation.svg");
export const fileDelete = require("../assets/file_delete.svg");
export const fileTypePng = require("../assets/file_type_png.svg");
export const viewOptionActive = require("../assets/view_option_active.svg")
export const viewOptionInactive = require("../assets/view_option_inactive.svg")
export const editOptionActive = require("../assets/edit_option_active.svg")
export const editOptionInactive = require("../assets/edit_option_inactive.svg")
export const deleteOptionActive = require("../assets/delete_option_active.svg")
export const deleteOptionInactive = require("../assets/delete_option_inactive.svg")
export const previewOptionActive = require("../assets/preview_option_active.svg")
export const previewOptionInactive = require("../assets/preview_option_inactive.svg")
export const sendReminderOptionActive = require("../assets/send_reminder_option_active.svg")
export const sendReminderOptionInactive = require("../assets/send_reminder_option_inactive.svg")
export const classDefault1 = require("../assets/class_default_1.svg")
export const classDefault2 = require("../assets/class_default_2.svg")
export const joinClass = require("../assets/join_class.png")
export const previousLessons = require("../assets/previous_lesson.png")
export const lessons = require("../assets/lessons_icon.svg")
export const quizzes = require("../assets/quizzes_icon.svg")
export const contentCopy = require("../assets/content_copy.svg")
export const assignmentsIcon = require("../assets/assignments.svg")
export const lessons_icon = require("../assets/lessons.svg")
export const newLessonIcon = require("../assets/group_lesson_icon.svg")
export const quizIcon = require("../assets/quiz_icon.svg")