export const navLogo = require("../assets/nav_logo.png")
export const bannerImage = require("../assets/banner.png")
export const contentImage1 = require("../assets/content_image_1.png")
export const contentImage2 = require("../assets/content_image_2.png")
export const footerLogo = require("../assets/footer_logo.png")
export const laptop = require("../assets/laptop.png")
export const mobile = require("../assets/mobile.png")
export const tablet = require("../assets/tablet.png")
export const leftBtn = require("../assets/left.png")
export const rightBtn = require("../assets/right.png")
export const instagram = require("../assets/instagram.png")
export const facebook = require("../assets/facebook.png")
export const twitter = require("../assets/twitter.png")
export const linkIcon = require("../assets/link_icon.png")

