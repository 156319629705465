import React from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
import { ACTIONS } from "../../../../../components/src/types";
export const Assets = require("../../assets");
export const configJSON = require("../../config");
const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  paperSuccessModal: {
    position: "absolute",
    width: "502px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    padding: "20px 30px",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "60px",
    flexDirection: "column",
    padding: "0 20px",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#2B3D7D",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "10px",
  },

  containerSuccessModal: {
    position: "relative",
    width: "100%",
  },
  crossLayout: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  content: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 400,
    textAlign: "center",
  },
}));
const ChartAddedSuccessModal = (props: any) => {
  const { successModal, handleCloseSuccessModal, action, lessonName } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  
  const getModalText =()=>{
    switch (action) {
      case ACTIONS.KWL_CHART:
        return configJSON.KWLChartModalText;
      case ACTIONS.T_CHART:
        return configJSON.TChartModalText;
      case ACTIONS.WH_CHART:
        return configJSON.WHChartModalText;
      case ACTIONS.SEED_DISCUSSION:
        return configJSON.SeedDiscussionModalText;
      case ACTIONS.VENN_DIAGRAM:
        return configJSON.VennDiagramModalText;
      default:
        return {};
    }
  }
  const modalText= getModalText()
  return (
    <Modal open={successModal} onClose={handleCloseSuccessModal}>
      <Box style={modalStyle} className={classes.paperSuccessModal}>
        <Box className={classes.containerSuccessModal}>
          <Box className={classes.popupContent}>
            <Box className={classes.crossLayout}>
              <ClearIcon
                onClick={handleCloseSuccessModal}
                data-test-id="close-modal"
              />
            </Box>
            <Box className={classes.header}>
              <img src={Assets[`${action}`]} style={{ marginBottom: "10px" }} />
              <Typography className={classes.title}>
                {modalText.title}
              </Typography>
              <Typography className={classes.content}>
                {`${modalText.content} ${lessonName}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChartAddedSuccessModal;
