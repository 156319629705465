import React from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon } from "../../assets";
import { ACTIONS } from "../../../../../components/src/types";

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";

const useStyles = makeStyles(() => ({
  modalStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "475px",
    background: "#fff",
    border: "none",
    boxShadow: "24",
    borderRadius: "15px",
    padding: "24px",
    overflowX: "hidden",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  modalBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "350px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    cursor: "pointer"
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#202020",
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: "34px",
    marginTop:"14px",
    width:"360px",
    textAlign: "center"
  },
}));
interface Prop{
  open:boolean;
  onClose: ()=>void;
  handleActionChange:(data:any)=>void;
  setMcqfile: (data:any)=>void;
  setPollfile: (data:any)=>void;
}

const QuestionTypeSelectionModal = (props: Prop) => {
  const { open, onClose, handleActionChange,setMcqfile,setPollfile } = props;
  const classes = useStyles();

  const handleMcqSubmit=()=>{
    handleActionChange(ACTIONS.ADD_MCQ)
    setMcqfile(null);
    onClose()
  }
  const handlePollSubmit=()=>{
    handleActionChange(ACTIONS.ADD_POLL)
    setPollfile(null);
    onClose()
  }
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalStyles}>
        <img data-test-id="close-icon" className={classes.closeIcon} src={closeIcon} onClick={onClose} />
        <Typography className={classes.heading}>
          Please choose your preferred Question Type
        </Typography>
        <button className={classes.modalBtn} onClick={handleMcqSubmit} data-test-id="mcq">
          Multiple Choice Question
        </button>
        <button className={classes.modalBtn} onClick={handlePollSubmit} data-test-id="poll">
          Polling Question
        </button>
      </Box>
    </Modal>
  );
};

export default QuestionTypeSelectionModal;
