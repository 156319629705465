import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Modal,
} from "@material-ui/core";
import StudentListController, { Props } from "./StudentCalendarController.web";

import ClearIcon from "@material-ui/icons/Clear";

import { ClockIcon, calendarIcon, calendarPickerIcon, dateIcon } from "../../assets";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomDatePicker from "../../SchoolAdmin/TeacherCalendar/CustomDatePicker.web";
import Loader from "../../../../../components/src/Loader";
import AppHeaderWeb from "../../../../../components/src/AppHeader.web";
import Sidebar from "../../../../../components/src/Sidebar.web";
import { styled } from "@material-ui/core/styles";
import "../../Teacher/pages/style/React-big-calendar.css"
import StudentLiveJoinModal from "../../../../../components/src/LiveLessonQuiz";


const CurrentDateBox = styled(Box)(({ theme }) => ({
  padding: "0 35px",
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  [theme.breakpoints.down('xs')]: {
    padding: "0",
    width: "100%",
    justifyContent: "center"
  },
}));

const ViewAssignmentBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "20px",
  width: "50%",
  [theme.breakpoints.down("xs")]: {
  width:"90%"
  },
}));

const AssignmentTime = styled(Box)(({ theme }) => ({
  display:"flex",
  alignItems:"baseline",
  [theme.breakpoints.down("xs")]: {
  flexDirection:"column",
  gap:"10px"
  },
}));


const OptionIcon = styled(Button)(({ theme }) => ({
  minWidth: 0,
  textTransform: 'capitalize' as "capitalize",
  padding: '0px 5px',
  color: 'rgb(43, 61, 125)',
  border: '1px solid rgb(43, 61, 125)',
  borderRadius: '10px',
  float:'right' as 'right',
  top:'4px',
  right:'22px',
  [theme.breakpoints.down('xs')]: {
    right: 0,
    left: "10px"
  },
}));

const EventBox = styled(Box)(({ theme }) => ({
  borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    height: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid rgba(57, 198, 181, 0.26)",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    display:"flex",
  [theme.breakpoints.down('xs')]: {
    paddingRight: "20px"
  },
}));



const EventCard = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"space-between",
  [theme.breakpoints.down('xs')]: {
    alignItems:"center",
    gap:"10px",
    flexDirection: "column"
  },
}));

const WeekDate = styled(Box)(({ theme }) => ({
  "&.clicked": {
    width: "93px",
    border: "1px solid #979797",
    borderRadius: "10px",
    height: "111px",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    flexDirection: "column",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "6px",
      width: "80px",
      height: "80px",
      justifyContent: "center",
    },
  },

  "&.unclicked": {
    display: "flex",
    flexDirection: "column",
    width: "93px",
    paddingTop: "20px",
    marginBottom: "8px",
    marginTop: "16px",
    height: "111px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      width: "80px",
      height: "80px",
      paddingTop: "6px",
    },
  },
}));


const CalendarBox = styled(Box)(({ theme }) => ({
  padding: "50px 35px",
  height: '70%',
  [theme.breakpoints.down('xs')]: {
    padding: "19px 0px"
  },
}));

const WeekText = styled("span")(({ theme }) => ({

  "&.selectedWeekHeaderDayLabel": {
    marginBottom: "10px",
    lineHeight: "27px",
    fontSize: "24px",
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 500 as 500,
    textAlign: "center" as "center",
      [theme.breakpoints.down('xs')]: {
        lineHeight:"10px",
        fontSize:"20px",
      },
  },

  "&.headerDayLabel": {
    fontWeight: 500 as 500,
    color: "#858585",
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontSize: "24px",
    marginBottom: "10px",
    textAlign: "center" as "center",
    [theme.breakpoints.down('xs')]: {
      lineHeight:"10px",
      fontSize:"20px",
    },
  },
}));

class Students extends StudentListController {
  constructor(props: Props) {
    super(props);
  }
  private localizer = momentLocalizer(moment);

  handleColorEvents = (event: any) => {
    if (event?.type === 'lesson') {
      return "#007AFF"
    } else if (event?.type === 'quiz') {
      return "#39C6B5"
    }else{
      return "#FF9500"
    }
  }

  handleBrowserCheck = () => {
    if (navigator.userAgent.includes('Chrome') || navigator.userAgent.includes('Edge') || navigator.userAgent.includes('Safari')) {
      return true
    } else {
      return false
    }
  }

  handleMyEvents = (eventProps: any) => {
    return (
      <>
        <EventBox
          data-testid="open-customized-menu-box"
          className="unique-calendar"
        >
          <Box style={{
            backgroundColor: this.handleColorEvents(eventProps?.event),
            height: '100%',
            width: '1.2%',
          }} />
          <Box height={"100%"} width={'100%'} px={'10px'} style={{ position: "relative", paddingTop: '2px' }}>
            {!eventProps?.event?.overlap && <Box style={webStyle.eventTitleBox}>
              <Typography style={{ ...webStyle.eventTitle, marginBottom: '4px' }}>
                {eventProps.title}
              </Typography>
              <Box style={{
                position: 'absolute',
                zIndex: 99999,
                top: '0px',
                right: "0px",
                height: this.handleBrowserCheck() ? '-webkit-fill-available' : "100%",
                width: this.handleBrowserCheck() ? '-webkit-fill-available' : "100%"
              }} onClick={this.handleOpenAssignment.bind(this, eventProps)}>
                <Button
                  aria-haspopup="true"
                  data-testid="open-customized-menu-btn"
                  style={webStyle.optionIconBtn}

                >
                  {this.handleEditEventTypeCheck(eventProps)}
                </Button>
              </Box>
            </Box>} 
            <EventCard>
              <Box>
                <Typography style={webStyle.eventDueDate}>
                  Start Date & Time
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                  <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.startDate).format("DD/MM/YYYY")} {eventProps?.event?.startTime}</Typography>
                </Box>
              </Box>

              <Box pr={this.state.isSmallScreen? "" : "2rem"}>
                <Typography style={webStyle.eventDueDate}>
                  Due Date & Time
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarIcon} style={{ height: "17px", width: "17px", marginRight: '2px', marginLeft: '-2px' }} />
                  <Typography style={{ color: 'black', display: 'flex', fontSize: '14px' }}>{moment(eventProps?.event?.endDate).format("DD/MM/YYYY")} {eventProps?.event?.endTime}</Typography>
                </Box>
              </Box>

            </EventCard>
          </Box>
        </EventBox>
      </>
    );
  };

  myWeekHeader = (props: any) => {
    const { label, selectedDate } = props;
    const [date, day] = label.split(" ");
    const weekDate = date.toString();
    const selectedWeekDate = selectedDate
      .getDate()
      .toString()
      .padStart(2, "0");


    return (
      <WeekDate
      className={weekDate === selectedWeekDate ? 'clicked' : 'unclicked'}
        data-testid="weekHeaderClick"
        onClick={this.handleStudentDateChange.bind(this, props?.date)}
      >
       <WeekText
          className={weekDate === selectedWeekDate ? 'selectedWeekHeaderDayLabel' : 'headerDayLabel'}
        >
          {day}
        </WeekText>
        <span
          style={
            weekDate === selectedWeekDate
              ? webStyle.highlightedWeekHeaderDateLabel
              : webStyle.weekHeaderDateLabel
          }
        >
          {date}
        </span>
      </WeekDate>
    );
  };

  handleStudentEventCalendar = (props: any) => (
    <this.handleMyEvents {...props} />
  )

  handleStudentWeekHeader = (props: any) => (
    <this.myWeekHeader {...props} selectedDate={this.state.selectedDate} />
  )

  handleRenderCalendar = () => ({
    event: this.handleStudentEventCalendar,
    week: {
      header: this.handleStudentWeekHeader,
    },
  })
 

   renderCurrentDateBox = () => {
    const optionsTime: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };    

    return (<CurrentDateBox>
      <Typography style={webStyle.currenDate}>
        {new Date(this.state.selectedDate).toLocaleDateString("en-US", optionsTime)}
      </Typography>
      <Button data-testid="handleStudentOpen" onClick={this.handleStudentOpen}>
        <img src={calendarPickerIcon} />
      </Button>
    </CurrentDateBox>)
   };

   renderAssignmentModal = () => (
    <Modal open={this.state.openAssignment} data-testid="closeAssignment" onClose={this.closeAssignment}>
      <Box style={{ display: 'flex', width: '100%', height: "100%", justifyContent: "center", alignItems: "center" }}>
        <ViewAssignmentBox>
          <ClearIcon
            onClick={this.closeAssignment}
            style={{
              ...webStyle.crossIconOption,
              paddingTop: this.state.isSmallScreen ? "1rem" : webStyle.crossIconOption.paddingTop,
              paddingRight: this.state.isSmallScreen ? "1rem" : webStyle.crossIconOption.paddingRight,
            }}          />
          <Typography style={{ fontFamily: "poppins", color: "#2C2C2E", fontSize: '24px', marginBottom: "18px", marginTop: "38px", marginLeft: "30px", fontWeight: 800 }}>
            View Assignment
          </Typography>
          <Box display="flex" alignItems={"baseline"} ml={'2rem'} justifyContent={"space-between"} width={'fit-content'}>
            <Box mb={"1rem"}>
              <Typography style={{ ...webStyle.typographyViewEvent, fontSize: this.state.isSmallScreen ? '0.9rem' : undefined }}>Assignment Name</Typography>
              <Typography style={{ ...webStyle.typographyViewEvent, paddingTop: '12px', fontWeight: 600, fontSize: this.state.isSmallScreen ? '0.9rem' : undefined }}>{this.state.eventPropsObj.event.title}</Typography>
            </Box>
            <Box ml={'2rem'}>
              <Typography style={webStyle.typographyViewEvent}>Class</Typography>
              <Typography style={{ ...webStyle.typographyViewEvent, paddingTop: '12px', fontWeight: 600, fontSize: this.state.isSmallScreen ? '0.9rem' : undefined }}>{this.state.eventPropsObj.event.assignment.data.attributes.class_name}</Typography>
            </Box>
          </Box>
          <Box ml={'2rem'} mb={'2rem'}>
            <Typography style={webStyle.typographyViewEvent}>Objective</Typography>
            <Typography style={{ paddingTop: '12px', fontWeight: 600, ...webStyle.typographyViewEvent }}>{this.state.eventPropsObj.event.assignment.data.attributes.details}</Typography>
          </Box>
          <AssignmentTime>
            {this.renderDateTime('Start Date & Time', this.state.eventPropsObj.event.startDate, this.state.eventPropsObj.event.startTime)}
            {this.renderDateTime('Due Date & Time', this.state.eventPropsObj.event.endDate, this.state.eventPropsObj.event.endTime)}
          </AssignmentTime>
          <Box style={{ ...webStyle.buttonContainer, paddingRight: this.state.isSmallScreen ? '13px' : '35px', paddingLeft: this.state.isSmallScreen ? '13px' : '' }}>
            <Button
              style={{
                width: "150px", height: "52px", border: "0.5px solid #3F526D", borderRadius: "8px", color: "#3F526D",
                fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textAlign: "center",
                textTransform: "capitalize"
              }}
              data-testid="handleSaveEditCancelEventModal"
              onClick={this.closeAssignment.bind(this)}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "234px", height: "52px", borderRadius: "8px", textAlign: "center", color: "white",
                fontFamily: "poppins", lineHeight: "16px", fontSize: "18px", letterSpacing: "0.11px", textTransform: "capitalize",
                backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
              }}
              data-testid="editTeacherCalendarEvents"
              onClick={()=>this.navigateToAssessment(this.state.eventPropsObj.event.assignment.data.id,this.state.eventPropsObj.event.status)}
            >
              Open Assignment
            </Button>
          </Box>
        </ViewAssignmentBox>
      </Box>
    </Modal>
  );

   renderDateTime = (label: string, date: string, time: string) => (
    <Box display="flex" alignItems={"baseline"} ml={'2rem'}>
      <div>
        <Typography style={webStyle.typographyViewEvent}>{label}</Typography>
        <Box display={'flex'} style={{ fontWeight: 600 }} pt={'12px'}>
          <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <img src={dateIcon} style={{ paddingRight: '12px' }} />
              <Typography style={{ ...webStyle.typographyViewEvent, paddingRight: this.state.isSmallScreen ? '44px' : '25px', fontWeight: 600 }}>{date}</Typography>
            </Box>
          </Box>
          <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <img src={ClockIcon} style={{ filter: "brightness(0)", paddingRight: '12px' }} />
              <Typography style={{ ...webStyle.typographyViewEvent, fontWeight: 600 }}>{time}</Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );

  renderJoinLiveModal= () =>{
    return this.state.isLiveModalActive&&<StudentLiveJoinModal handleCloseModal={this.handleCloseLiveModal} isLiveModalActive={this.state.isLiveModalActive} sessionResp={this.state.sessonLive}/>
  }
  

  render() {
    const { isSmallScreen } = this.state;
    return (
      <>
       <Box>
        <Grid container={isSmallScreen ? false : true} style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item {...(!isSmallScreen && { xs: 2, md:2 })}>
            {!isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item style={{height:'100%', width:'83.3%', overflowY:'auto'}}>
            <Grid item md={12} style={{ textAlign: isSmallScreen ? "center" : "initial" }}>
              <AppHeaderWeb title={"My Calendar"} />
            </Grid>
            {this.state.loading ? (
              <Loader loading={this.state.loading} />
            ) : (
            <Grid item>
              <Grid item>
                {this.renderCurrentDateBox()}
                <CustomDatePicker
                  selectedDate={this.state.selectedDate}
                  data-testid="custom-date-picker"
                  open={this.state.setOpen}
                  handleClose={this.handleStudentClose}
                  handleDateChange={this.handleStudentDateChange}
                />
                <CalendarBox>
                  <Calendar
                    localizer={this.localizer}
                    defaultView="week"
                    date={this.state.selectedDate}
                    components={this.handleRenderCalendar.call(this)}
                    toolbar={false}
                    events={this.state.calendarData.events}
                    views={["week"]}
                    data-testid="calendar"
                  />
                </CalendarBox>
                {this.renderAssignmentModal()}
                {this.renderJoinLiveModal()}
              </Grid>
            </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      </>
    );
  }
}

const webStyle = {
  title: {
    fontFamily: "Poppins",
    fontWeight: 500 as 500,
    fontSize: "24px" as "24px",
    color: "#757575",
  },
  crossIconOption: {
    float: 'right' as 'right',
    cursor: "pointer",
    paddingTop: '2rem',
    paddingRight: '2rem'
  },
  typographyViewEvent: {
    color: "#2C2C2E",
    fontFamily: 'Poppins'
  },
  inputDateStyle: {
    border: "none", fontFamily: 'Poppins', width: '300px',
  },
  borderDueTimeRadius: {
    borderBottomLeftRadius: "0px !important", borderTopLeftRadius: '0px !important', marginTop: '23px', fontFamily: 'Poppins'
  },
  picker: {
    position: "relative" as "relative",
    fontFamily: 'Poppins',
  },
  borderStartTimeRadius: {
    borderBottomRightRadius: "0px !important", borderTopRightRadius: '0px !important', marginTop: '16px', fontFamily: 'Poppins'
  },
  teacherName: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 500 as 500,
    fontFamily: "Poppins",
  },
  buttonContainer: {
    display: "flex", position: "relative" as "relative", top: '2rem', justifyContent: "flex-end" as "flex-end", gap: "10px", alignItems: "center", padding: "30px 52px 0px 52px", marginBottom: "60px",
  },
  navigationIcon: {
    breadcrumbBox: {
      padding: "0px 35px",
      marginTop: "20px",
    },
    marginTop: "20px",
  },
    currenDate: {
    paddingRight: "15px",
    fontFamily: "Poppins",
    fontWeight: 600 as 600,
    color: "rgb(43, 61, 125)",
    fontSize: "22px",
  },

  calendarView: {
    display: "grid",
    rowGap: "10rem",
  },
  weekHeader: {
    flexDirection: "column" as "column",
    marginTop: "16px",
    marginBottom: "8px",
    height: "111px",
    display: "flex",
    paddingTop: "20px",
    width: "93px",
  },

  currentDateBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "0 35px",
  },
  weekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500 as 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center" as "center",
    color: "#858585",
    marginBottom: "10px",
  },
  weekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center" as "center",
    color: "#000000",
  },
  calendarBox: {
    padding: "50px 35px",
  },
  eventDueDate: {
    fontFamily: "Poppins",
    fontWeight: 500 as 500,
    fontSize: "13px",
    color: "#757575",
    overflow: "hidden" as "hidden",
    width: "150px",
    whiteSpace: "normal" as "normal",
    wordBreak: "break-word" as "break-word",
  },
  eventBox: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    height: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid rgba(57, 198, 181, 0.26)",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    display: "flex",
  },


  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    minWidth: 0,
    textTransform: 'capitalize' as "capitalize",
    padding: '0px 5px',
    color: 'rgb(43, 61, 125)',
    border: '1px solid rgb(43, 61, 125)',
    borderRadius: '10px',
    float: 'right' as 'right',
    top: '4px',
    right: '22px',
  },
  highlightSelectedDate: {
    width: "93px",
    height: "111px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    marginTop: "16px",
    marginBottom: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    paddingTop: "20px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
  },
  eventTitle: {
    fontFamily: "Poppins",
    fontWeight: 800 as 800,
    fontSize: "14px",
    color: "rgb(43, 61, 125)",
    marginBottom: "10px",
    textOverflow: "ellipsis" as "ellipsis",
    overflow: "hidden" as "hidden",
    height: "2.2em",
    whiteSpace: "nowrap" as "nowrap",

  },

  highlightedWeekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center" as "center",
    color: "#FFF",
  },
  eventTitleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
  },
  goBackButton: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "50px",
    marginRight: "35px"
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500 as 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center" as "center",
    color: "#FFF",
    marginBottom: "10px",
  },
}


export default Students