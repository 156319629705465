Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.landingPageContentType="application/json";
exports.landingPageEndPoint="/landing_page_data";
exports.landingPageApiMethod="GET";
exports.bannerContentType="application/json";
exports.bannerEndPoint="/show_header_content_image";
exports.bannerApiMethod="GET";

// Customizable Area End