Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_notifications/notifications";
exports.getNotificationsEndPoint = "bx_block_notifications/notifications";
exports.clearAllEndPoint = "bx_block_notifications/notifications/clear_all_notification?notifications_ids=";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.leftBracket = "["
exports.rightBracket = "]"
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.headerTitle = "Notifications";
exports.allNotificationTxt = "All Notifications";
exports.clearAllTxt = "Clear All";
exports.markAsRead = "Mark as read";
exports.markAsUnread = "Mark as unread";
exports.noNotificationTxt = "No Notifications";
// Customizable Area End