import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Button,
    DialogContent,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CheckIcon from '@material-ui/icons/Check';

import AssessmentQuizTestController, {
    Props,
    configJSON,
} from "./AssessmentQuizTestController.web";
import { RightSideIcon, editIcon, greenTick, leftSideIcon, studentSubmittedIcon } from "./assets";
import { BootstrapDialog, IconBtn } from "./AssessmentTest.web";
import { closeIcon, tickIcon} from "../../MultipageForms2/src/assets";
import { redCross } from "../../CfCourseCreation23/src/assets";
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";
interface QuizMarkupTool {
    id: string;
    attributes: Attributes;
    type: string;
}

interface Attributes {
    quiz_id: number;
    quiz_stage_id: number;
    canvas_data: null;
    description: string | null;
    stage_number: number;
    point: boolean;
    questions: QuestionItem[];
    double_point: boolean;
    countdown_timer: string;
    quiz_stage_name: string;
    title: string | null;
    background_image: null;
    videos: null;
    images: null;
    multiple: boolean;
    music: null;
    matching_pairs: MatchingPair[];
}

interface MatchingPair {
    pair_question: string;
    id: number;
    order_no: number;
    pair_correct_answer: string;
    pair_answer: string;
    lesson_markup_tools_id: number | null;
    created_at: string;
    quiz_markup_tools_id: number;
    assignment_markup_tools_id: number | null;
    updated_at: string;
}

interface QuestionItem {
    options: Option[];
    question_background_image: string | null;
    question: Question;
}

interface Question {
    lesson_markup_tools_id: number;
    id: number;
    created_at: string;
    updated_at: string;
    question: string;
}

interface Option {
    marked_as_right_answer: boolean | null;
    id: number;
    lesson_mcq_questions_id: number;
    created_at: string;
    answer: string;
    order_no: number | null;
    updated_at: string;
}
// Customizable Area End

export default class AssessmentQuizTest extends AssessmentQuizTestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    displayAnswer = (data: QuizMarkupTool, answerData: any) => {
        let isCorrect = false;
        let currentStage = this.handleQuesValidation(data);
        currentStage.forEach((value) => {
            if (this.checkIsCorrect(answerData, value.answer)) { isCorrect = true }
        });
    
        const msgs = isCorrect ? 'Correct' : 'Incorrect';
        const icon = isCorrect ? tickIcon : redCross;
    
        return (
            <Grid container alignItems="center" style={{ padding: '8px' }}>
                <Grid item>
                    <img style={webStyle.tickIconSmall} src={icon} alt="" />
                </Grid>
                <Grid item>
                    <Typography style={webStyle.Ansmessage}>Your answer is <span style={webStyle.AnsmessageSpan}>{msgs}</span></Typography>
                </Grid>
            </Grid>
        );
    }

    multiSelectPollingQuestion = (isMultiple: boolean,currentData: QuizMarkupTool, answerData: any) => {
        return (
            <>
            {currentData.attributes.questions && currentData.attributes.questions[0].options.map((value: any, index: any) => (
                <Grid
                    style={this.getOptionStyleForMultiple(value.answer, answerData, currentData.attributes.quiz_stage_name, true) as React.CSSProperties}
                    onClick={() => { this.selectAnswerForMultiple(currentData, value.answer) }}
                    key={index}
                    data-test-id="selectAnswerMultiple"
                >
                    {value.answer}
                    {(answerData.answers && answerData.answers[0].answer.includes(value.answer)) &&
                        <Grid style={webStyle.containerForIconStudent as React.CSSProperties}>
                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.studentIcon as React.CSSProperties} />
                        </Grid>
                    }
                    {
                        this.state.slectedOptionForMultiple.answers &&
                        this.state.slectedOptionForMultiple.answers.includes(value.answer) &&
                        !this.state.slectedOptionForMultiple.isSubmit &&
                        (<Grid style={webStyle.containerForIcon as React.CSSProperties}><CheckIcon style={webStyle.icoStyle} /></Grid>)
                    }
                </Grid>
            ))}
            {
                this.state.quizStatus === 'complete' ? <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Button
                                onClick={() => this.viewFeedback(currentData.id)}
                                style={webStyle.submitButtonActive as React.CSSProperties}
                                data-test-id="mcqFeedbackMulti"
                            >Feedback</Button>
                        </Grid>
                    </Grid>
                    : <Grid container alignItems="center" justifyContent="space-between">
                        {!this.state.slectedOptionForMultiple.isSubmit ? (
                            <Grid item>
                                <Button
                                    data-test-id="submitAnswerPolling"
                                    style={((this.state.slectedOptionForMultiple.answers && this.state.slectedOptionForMultiple.answers.length !== 0)) ? webStyle.submitButtonActive as React.CSSProperties : webStyle.submitButtonBeforeClick as React.CSSProperties}
                                    disabled={(this.state.slectedOptionForMultiple.answers && this.state.slectedOptionForMultiple.answers.length === 0)}
                                    onClick={() => this.submitAnswer(currentData.id, currentData.attributes.quiz_stage_name, currentData.id, currentData.attributes.quiz_id)}
                                >Submit</Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item>
                                    <Grid container>
                                        <Grid item style={webStyle.submitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid>
                                        <Typography style={webStyle.submitedAndEditText as React.CSSProperties}>Submitted</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid data-test-id="editDataPolling" onClick={() => this.editDataMultiplePoll()} container justifyContent="center" style={webStyle.editBtnContainer}>
                                        <img src={editIcon} />
                                        <Typography style={webStyle.submitedAndEditText}>Edit</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
            }
            </>
        )
    }

    renderOpenEndedQuestion(currentData: QuizMarkupTool, answerData: any) {
        return (
            <Box style={webStyle.openEndedWrapper}>
                <Box style={webStyle.openEndedMainBoxStage}>
                    <Grid style={webStyle.firstTextGrid}><Typography style={webStyle.firstHedingText}>Open-Ended Question</Typography></Grid>
                    <Grid><Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.title) }} /></Grid>
                    <Grid item>
                        <>
                            <textarea rows={10} placeholder="Answers"
                                style={{
                                    ...webStyle.questionTextfield,
                                    padding: '5px 0 0 5px',
                                }}
                                onChange={(event) => { this.checkOpenEndedOnchange(event, currentData.attributes.title) }}
                                disabled={(this.state.openEndedText.isSubmit || this.state.quizStatus !== 'pending') ? true : false}
                                value={(answerData && answerData?.student_answer) || this.state.openEndedText.answer}
                                data-test-id="handelOpenEnded"
                            />
                        </>
                    </Grid>
                    {
                        this.state.quizStatus === 'complete'
                            ? <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Button
                                        data-test-id="openEndedFeedback"
                                        onClick={() => this.viewFeedback(currentData.id)}
                                        style={webStyle.submitButtonActive as React.CSSProperties}
                                    >Feedback
                                    </Button>
                                </Grid>
                            </Grid>

                            : <Grid container justifyContent="space-between" alignItems="center">

                                {this.state.openEndedText.isSubmit ?
                                    <>
                                        <Grid item><Grid container><Grid item style={webStyle.submitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid><Typography style={webStyle.submitedAndEditText}>Submitted</Typography></Grid></Grid>
                                        <Grid item>
                                            <Grid container data-test-id="editDataOpenEnded" onClick={() => this.editData()} style={webStyle.editBtnContainer as React.CSSProperties} justifyContent="center">
                                                <img src={editIcon} />
                                                <Typography style={webStyle.submitedAndEditText}>Edit</Typography>
                                            </Grid>
                                        </Grid>
                                    </> : <Grid item>
                                        <Button data-test-id="submitAnsweropenended" style={this.state.openEndedText.answer ? webStyle.submitButtonActive as React.CSSProperties : webStyle.submitButtonBeforeClick as React.CSSProperties}
                                            disabled={!this.state.openEndedText.answer}
                                            onClick={() => this.submitAnswer(currentData.id, currentData.attributes.quiz_stage_name, currentData.id, currentData.attributes.quiz_id)}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                    }

                </Box>
            </Box>
        );
    }

    renderMCQorPollingQuestion(currentData: QuizMarkupTool, answerData: any) {
        const isMultiple = currentData.attributes.multiple;
        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>
                    <Grid item style={webStyle.mainContainerWidth}>
                        <Grid style={webStyle.mainWrapperForWordCloud}>
                            <Grid style={webStyle.firstTextGrid}><Typography style={webStyle.firstHedingText}>{this.checkIsMcqOrPolling(currentData.attributes.quiz_stage_name)}</Typography></Grid>
                            <Grid>
                                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.questions[0].question.question) }} />
                            </Grid>
                            {
                                isMultiple 
                                ? this.multiSelectPollingQuestion(isMultiple, currentData, answerData)
                            :
                            <>
                            
                            {currentData.attributes.questions && currentData.attributes.questions[0].options.map((value: any, index: any) => (
                                
                                <Grid
                                    data-test-id="selectAnswerMcq"
                                    style={this.getOptionStyle(value.answer, answerData, currentData.attributes.quiz_stage_name) as React.CSSProperties}
                                    key={index}
                                    onClick={() => { this.checkSubmitAnswerMcqOrPolling(currentData, value.answer) }}
                                >
                                    {value.answer}
                                    {value.answer === (answerData.answers && answerData.answers[0].answer) &&
                                        <Grid style={webStyle.containerForIconStudent as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.studentIcon as React.CSSProperties} />
                                        </Grid>
                                    }
                                    
                                    {
                                        currentData.attributes.quiz_stage_name === 'MCQ' && value.answer === (answerData && answerData.student_answer) &&
                                        <Grid style={webStyle.containerForIconStudent as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.studentIcon as React.CSSProperties} />
                                        </Grid>
                                    }
                                    {
                                        this.state.slectedOption.answer === value.answer && !this.state.slectedOption.isSubmit &&
                                        (<Grid style={webStyle.containerForIcon as React.CSSProperties}><CheckIcon style={webStyle.icoStyle} /></Grid>)
                                    }
                                </Grid>
                            ))}
                            {this.state.quizStatus === 'complete' && currentData.attributes.quiz_stage_name === "MCQ" && this.displayAnswer(currentData, answerData)}
                            {
                                this.state.quizStatus === 'complete'
                                    ? <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Button
                                                data-test-id="mcqFeedback"
                                                style={webStyle.submitButtonActive as React.CSSProperties}
                                                onClick={() => this.viewFeedback(currentData.id)}
                                            >Feedback
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    : <Grid container alignItems="center" justifyContent="space-between">
                                        {this.state.slectedOption.isSubmit ? (
                                            <>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item style={webStyle.submitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid>
                                                        <Typography style={webStyle.submitedAndEditText as React.CSSProperties}>Submitted</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid data-test-id="editDataMcq" onClick={() => this.editData()} container justifyContent="center" style={webStyle.editBtnContainer}>
                                                        <img src={editIcon} />
                                                        <Typography style={webStyle.submitedAndEditText}>Edit</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item>
                                                <Button
                                                    data-test-id="submitAnswerMcq"
                                                    style={this.state.slectedOption.answer ? webStyle.submitButtonActive as React.CSSProperties : webStyle.submitButtonBeforeClick as React.CSSProperties}
                                                    disabled={!this.state.slectedOption.answer}
                                                    onClick={() => this.submitAnswer(currentData.id, currentData.attributes.quiz_stage_name, currentData.id, currentData.attributes.quiz_id)}
                                                >Submit</Button>
                                            </Grid>
                                        )}
                                    </Grid>
                            }
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderMatchingQuestion(currentData: QuizMarkupTool, answerData: any) {
        return (
            <PropertyDeveloperCss>
                {this.setData(currentData.attributes.matching_pairs)}
                <Box sx={webStyle.mainWrapper}>
                    <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>
                        <Grid style={webStyle.mainContainerWidthDrag}>
                            <Typography style={webStyle.firstHedingText} >{configJSON.matchingQuestionTitle}</Typography>
                            <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.title) }} />
                            <Box>
                                <Box className="headingLayout">
                                    <Box className="heading">A</Box>
                                    <Box className="heading">B</Box>
                                </Box>
                                <Grid container justifyContent="space-between" spacing={3}>
                                    <Grid item md={6}>
                                        {currentData.attributes.matching_pairs.map((question: any, index: any) => (<Box key={index} className="matchingBoxA">{question.pair_question}</Box>))}
                                    </Grid>
                                    <Grid item md={6}>
                                        {
                                            this.state.quizStatus === 'complete'
                                            ? answerData?.map((question: any, index: any) => {
                                                return <Box
                                                    data-test-id="dragDrop"
                                                    key={index}
                                                    className={question?.answers?.[question?.answers.length - 1]?.student_answer === question?.answers?.[question?.answers.length - 1]?.teacher_correct_answer ? 'matchingBoxBCorrect' : 'matchingBoxBWrong'}
                                                >
                                                    {question?.answers?.[question?.answers.length - 1]?.student_answer}
                                                </Box>
                                            })
                                            : this.state.rightArray.map((question, index) => (
                                            <Box
                                                data-test-id="dragDrop"
                                                key={index}
                                                className={this.state.dragSomethingSubmit ? `matchingBoxBActive` : 'matchingBoxB'}
                                                draggable={!this.state.dragSomethingSubmit}
                                                onDragStart={() => this.handleDragStart(index)}
                                                onDragEnter={() => this.handleDragEnter(index)}
                                                onDragEnd={this.handleSort}
                                            >
                                                {question}
                                            </Box>
                                        ))}
                                    </Grid>
                                </Grid>
                                {
                                    this.state.quizStatus === 'complete'
                                        ? <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Button
                                                    onClick={() => this.viewFeedback(currentData.id)}
                                                    style={webStyle.submitButtonActive as React.CSSProperties}
                                                    data-test-id="matchingFeedback"
                                                >
                                                    Feedback
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                {this.state.dragSomethingSubmit ? (
                                                    <>
                                                        <Grid item>
                                                            <Grid container>
                                                                <Grid item style={webStyle.submitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid>
                                                                <Typography style={webStyle.submitedAndEditText}>Submitted</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container justifyContent="center" style={webStyle.editBtnContainer} data-test-id="editDataMcqsdrage" onClick={() => this.editData()}>
                                                                <img src={editIcon} />
                                                                <Typography style={webStyle.submitedAndEditText}>Edit</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (<Grid item>
                                                    <Button
                                                        data-test-id="submitAnswerdrop"
                                                        disabled={!this.state.dragSomething}
                                                        onClick={() => this.submitAnswer(currentData.id, currentData.attributes.quiz_stage_name, currentData.id, currentData.attributes.quiz_id)}
                                                        style={this.state.dragSomething
                                                            ? webStyle.submitButtonActive as React.CSSProperties
                                                            : webStyle.submitButtonBeforeClick as React.CSSProperties}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Grid>)}
                                            </Grid>
                                        </Grid>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </PropertyDeveloperCss>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { quizName, className, dueDate, quizStatus, isQuizLoading, answerData, answerDataMatchingQuestion } = this.state
        const currentData = this.state.allData[this.state.currentStep];
        const dataType = currentData?.attributes?.quiz_stage_name;

        let content;

        switch (dataType) {
            case 'open_ended_question':
                content = this.renderOpenEndedQuestion(currentData, answerData);
                break;
            case 'MCQ':
            case 'polling_question':
                content = this.renderMCQorPollingQuestion(currentData, answerData);
                break;
            case 'matching_question':
                content = this.renderMatchingQuestion(currentData, answerDataMatchingQuestion);
                break;
            default:
                content = null;
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                <SuccessSnackbar
                    message={'Request size exceeds the maximum allowed size of 200 MB.'} 
                    open={this.state.isLimitModalOpen} 
                    onClose={this.handleCloseSnack} 
                    type={'error'}
                />
                <Box style={webStyle.navbar as React.CSSProperties}>
                    <Box style={webStyle.leftNav}>
                        <ArrowBackIosIcon data-test-id="btnBack" onClick={this.backToTaskList} style={webStyle.backIcon} />
                        <Typography style={webStyle.quizName}>{quizName}</Typography>
                    </Box>
                    <Box style={webStyle.rightNav}>
                        <Box style={webStyle.rightNavSmalBox}>
                            <Typography style={webStyle.rightNavTopTxt}>{configJSON.classTxt}</Typography>
                            <Typography style={webStyle.rightNavBottomTxt}>{className}</Typography>
                        </Box>
                        <Box style={webStyle.rightNavSmalBox}>
                            <Typography style={webStyle.rightNavTopTxt}>{quizStatus === 'pending' ? configJSON.dueDateTxt : configJSON.headerFeedbackTxt}</Typography>
                            <Typography style={webStyle.dueDate} >
                                {quizStatus === 'pending' && <QueryBuilderIcon style={webStyle.timeIcon} />}
                                {quizStatus === 'pending' ? `${dueDate} ${configJSON.daysLeft}` : this.state.allData.length}
                            </Typography>
                        </Box>
                        <Box style={webStyle.rightNavSmalBox}>
                            <Typography style={webStyle.rightNavTopTxt}>{configJSON.statusTxt}</Typography>
                            <Typography style={webStyle.rightNavBottomTxt}>{quizStatus === 'complete' ? 'Completed' : quizStatus}</Typography>
                        </Box>
                        {
                            quizStatus === 'pending' && <button data-test-id='btnMarkAsCompleted' 
                            onClick={() => {
                                if(this.state.submittedAnswerCount === this.state.allData.length)
                                this.handleMarkQuizAsCompleted();
                            }} 
                            style={(this.state.submittedAnswerCount === this.state.allData.length) ? webStyle.markAsCompletedActive as React.CSSProperties : webStyle.markAsCompleted as React.CSSProperties}
                            >
                                {configJSON.markAsCompleted}
                        </button>
                            
                        }
                    </Box>
                </Box>

                {content}

                {
                    !isQuizLoading && <Grid container alignItems="center" justifyContent="center" style={webStyle.pginationGrid as React.CSSProperties}>
                        <Grid item><img src={leftSideIcon} style={webStyle.cursorPointer} data-test-id="handleBack" onClick={this.handleBack} /></Grid>
                        <Grid item style={webStyle.paginationText}>{`${this.state.allData[this.state.currentStep]?.attributes?.stage_number}/${this.state.allData.length}`}</Grid>
                        <Grid item>
                            <img src={RightSideIcon} style={webStyle.cursorPointer} data-test-id="handelNext"
                                onClick={() => {
                                    if (this.state.currentStep < this.state.allData.length - 1) {
                                        this.handelNext();
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                <BootstrapDialog
                    aria-labelledby="customized-dialog-title"
                    onClose={this.handleCloseModal}
                    open={this.state.isModalOpen}
                >
                    <IconBtn onClick={this.handleCloseModal} data-test-id='btnCloseModal' aria-label="close" >
                        <img src={closeIcon} alt="close_icon" />
                    </IconBtn>

                    <DialogContent dividers>
                        <img style={webStyle.greenTick} src={greenTick} alt="" />
                        <Typography style={webStyle.modalText} >{configJSON.modalText}</Typography>
                    </DialogContent>
                </BootstrapDialog>

                <Modal
                    data-test-id="btnloseFeedbackModal"
                    onClose={this.handleCloseFeedbackModal}
                    open={this.state.isFeedbackModalOpen}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={this.state.isFeedbackModalOpen}>
                        <div
                            style={{
                                width: '25%',
                                borderRadius: '8px',
                                position: 'fixed',
                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                bottom: '20px',
                                right: '20px',
                                padding: '16px',
                                outline: 'none',
                                backgroundColor: 'white',
                            }}
                        >
                            <div>
                                <h2>Feedback</h2>
                            </div>
                            <textarea
                                rows={18}
                                style={{ width: "100%", resize: "none", padding: "3%", margin: "0px 0px 10px" }}
                                disabled
                                value={this.state.feedbackText}
                            >
                            </textarea>
                        </div>
                    </Fade>
                </Modal>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const PropertyDeveloperCss = styled(Box)({
    '& .question': {
        fontFamily: "Poppins",
        fontSize: "18px",
        color: "#282829",
        fontWeight: 500,
        marginBottom: "20px",
    },
    "& .instruction": {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        fontWeight: 500,
        marginBottom: "15px",
    },
    "& .matchingBoxA": {
        padding: "16px 12px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        borderRadius: "10px",
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        fontWeight: 500,
        marginBottom: "10px",
    },
    "& .matchingBoxB": {
        padding: "16px 12px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        borderRadius: "10px",
        backgroundColor: "rgba(43, 61, 125, 0.08)",
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        fontWeight: 500,
        marginBottom: "10px",
        cursor: "move",
    },
    "& .matchingBoxBActive": {
        padding: "16px 12px",
        border: "1px solid #2B3D7D",
        borderRadius: "10px",
        backgroundColor: "#2B3D7D",
        fontFamily: "Poppins",
        marginBottom: "10px",
        cursor: "move",
        fontSize: "14px", fontWeight: 500, color: "#fff",
    },
    "& .matchingBoxBCorrect": {
        fontSize: "14px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        padding: "16px 12px",
        borderRadius: "10px",
        cursor: "move",
        background: "#00A52C",
        marginBottom: "10px",
        color: "#282829",
        fontWeight: 500,
        fontFamily: "Poppins",
    },
    "& .matchingBoxBWrong": {
        cursor: "move",
        background: "#FF0000",
        borderRadius: "10px",
        padding: "16px 12px",
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        marginBottom: "10px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
    },
    "& .heading": {
        fontFamily: "Poppins",
        fontSize: "21px",
        color: "#282829",
        fontWeight: 700,
    },
    "& .headingLayout": {
        display: "flex",
        marginBottom: "15px",
        justifyContent: "space-around",
        textAlign: "center",
    },

    "& .mainLayout": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "30px 60px",
    },
    "& .contentBox": {
        width: "380px",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0px 0px 10px 0px #F1F5F9",
        borderRadius: "10px",
        flexDirection: "column",
        padding: "30px 60px",
    },
    "& .submitBtn": {
        width: "107px",
        height: "44px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
        borderRadius: "50px",
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#2B3D7D",
        },
    },
    "& .disabledBtn": {
        width: "107px",
        height: "44px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
        borderRadius: "50px",
        opacity: "0.5",
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#2B3D7D",
        },
    },
    "& .submitBtnLayout": {
        marginTop: "25px",
        cursor: "pointer"
    },
})

const webStyle = {
    mainBox: {
        minHeight: '100vh',
        background: "#F1F5F9",
        paddingTop: '60px',
    },
    navbar: {
        height: '60px',
        width: '100%',
        boxSizing: 'border-box' as 'border-box',
        padding: '0 24px 0 24px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        'box-shadow': '0px 1px 3px 0px #00000014',
        position: "fixed" as "fixed",
        top: '0',
        zIndex: '1000',
    },
    leftNav: {
        display: 'flex',
        alignItems: 'center',
        width: '30%',
        height: '100%',
    },
    backIcon: {
        fontSize: "14px", marginRight: '16px', cursor: 'pointer'
    },
    quizName: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        color: '#282829',
    },
    markAsCompleted: {
        border: 'none',
        width: "fit-content",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        opacity: "0.5",
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none" as "none",
        padding: "8px 10px",
        fontWeight: 700
    },
    markAsCompletedActive: {
        fontWeight: 700,
        border: 'none',
        color: "#fff",
        cursor: 'pointer',
        width: "fit-content",
        textTransform: "none" as "none",
        fontSize: "14px",
        borderRadius: "25px",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        fontFamily: "Poppins",
        padding: "8px 10px",
    },
    rightNav: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '70%',
        height: '100%',
    },
    rightNavSmalBox: {
        // width: '100%',
        marginRight: '60px',
    },
    rightNavTopTxt: {
        fontFamily: 'Poppins',
        fontSize: '11px',
        fontWeight: 400,
        color: '#282829',
    },
    rightNavBottomTxt: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        color: '#282829',
        textTransform: 'capitalize' as 'capitalize',
    },
    dueDate: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#282829',
        fontWeight: 500,
    },
    timeIcon: {
        width: '16px',
        height: '16px',
        marginRight: '6px',
    },
    openEndedWrapper: {
        minHeight: "92%",
        background: "#F1F5F9",
        paddingTop: "30px",
        paddingBottom: "30px",
        backrgroundColor: "#B2F1F5F9",
    },
    questionTextfield: {
        fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 600, width: "100%",
        borderRadius: "4px", border: "1px solid #5C2B3D7D", resize: "none" as 'none', margin: "2% 0px 5%"
    },
    openEndedMainBoxStage: {
        height: "auto",
        width: '34%',
        margin: 'auto',
        padding: "24px 24px 24px 24px",
        backgroundColor: "#fff",
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflowY: 'scroll' as 'scroll',
        msOverflowStyle: 'none' as 'none',
        boxSizing: 'border-box' as 'border-box',
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    submitIcon: {
        backgroundColor: "#2B3D7D",
        borderRadius: "50%",
        padding: "3px"
    },
    submitedAndEditText: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#3F526D",
        fontWeight: 700,
        marginLeft: "10px"
    },
    iconStyle: {
        color: "#fff",
        fontSize: "15px",
    },
    editBtnContainer: {
        cursor: "pointer",
        border: "2px solid #2B3D7D",
        borderRadius: "25px",
        width: "100px",
        padding: "9px 0px"
    },
    submitButtonBeforeClick: {
        width: "145%",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)', opacity: "0.5",
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none",
        padding: "14px 10px",
        fontWeight: 700,
        marginTop: "10px",
        marginBottom: '10px'
    },
    submitButtonBeforeClickDrag: {
        width: "30%",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        opacity: "0.5",
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none",
        padding: "14px 10px",
        fontWeight: 700,
        marginTop: "10px"
    },
    submitButtonActive: {
        width: "145%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none",
        padding: "14px 10px",
        fontWeight: 700,
        marginTop: "10px",
        marginBottom: '10px'
    },
    firstTextGrid: { paddingTop: "20px" },
    firstHedingText: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#282829",
        fontFamily: "Poppins",
        textTransform: 'capitalize' as 'capitalize'
    },
    pginationGrid: {
        width: "120px",
        position: "fixed",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        padding: "4px",
        left: "0",
        right: "0",
        bottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        background: '#fff',
        borderRadius: "11px",
    },
    paginationText: {
        margin: "0px 8px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#3F526D",
        fontFamily: "Poppins"
    },
    cursorPointer: { cursor: 'pointer' },
    textStyle2: {
        fontSize: "21px",
        fontWeight: 500,
        color: "#282829",
        fontFamily: "Poppins",
        margin: "20px 0px"
    },
    mainWrapper: {
        display: "flex",
        height: "91.2%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F1F5F9",
        marginTop: "1px",
        zIndex: '1',
        fontFamily: "Roboto-Medium",
    },
    mainContainerWidthDrag: {
        width: "40%",
        backgroundColor: "#fff",
        padding: "3%",
        borderRadius: "20px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    mainContainerWidth: {
        width: "40%",
        overflow: "auto",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    mainWrapperForWordCloud: {
        height: "100%",
        borderRadius: "9px",
        padding: "4% 7% 7%",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    containerForIcon: {
        position: "absolute",
        top: "9px",
        right: "12px",
        backgroundColor: "#2B3D7D",
        borderRadius: "50%",
        padding: "3px"
    },
    icoStyle: {
        color: "#fff",
        fontSize: "20px"
    },
    containerForIconStudent: { position: "absolute", top: "9px", right: "12px", borderRadius: "50%", padding: "3px" },

    studentIcon: {
        color: "#fff",
        fontSize: "20px",
        backgroundColor: 'none',
    },
    mainWrapperContainer: {
        height: "100%",
        padding: "44px 44px 44px 44px",
        position: "relative" as "relative",
        width: "100%",
        overflow: "auto",
    },
    greenTick: {
        height: '60px',
        width: '60px',
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
    },
    Ansmessage: {
        color: '#3F526D',
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400,
    },
    tickIconSmall: {
        display: 'block',
        height: '20px',
        width: '20px',
        margin: 'auto',
        marginRight: '8px',
    },
    AnsmessageSpan: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
    },
    modalText: {
        textAlign: 'center' as 'center',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#282829',
        lineHeight: '24px',
    },
};
// Customizable Area End