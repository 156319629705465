import React, { useRef, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { comments, question, participants } from "../assets";
import { makeStyles } from "@material-ui/core/styles";
import { CHAT } from "../../../../components/src/types";
const useStyles = makeStyles(() => ({
  optionImage: {
    width: "16px",
    height: "16px",
    marginRight: "5px",
  },
  optionImageSelected: {
    width: "16px",
    height: "16px",
    marginRight: "5px",
    color: "#2B3D7D",
  },
  optionName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
  },
  optionNameSelected: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
  },
  headerLayout: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid rgba(229,229,229)",
    position: "fixed",
    top: "0",
    right: "0",
    width: "25%",
    backgroundColor:"#FFF",
    zIndex:1,
  },
  optionLayout: {
    all:"unset",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: 0,
    padding: "20px",
  },
  line: {
    position: "absolute",
    top: "60px",
    left: 0,
    width: "33.33%",
    backgroundColor: "#2B3D7D",
    height: "5px",
    borderRadius: "10px",
    transition: "all .3s ease-in-out",
  },
}));
const ChatSectionHeader = (props: any) => {
  const { handleChatHeaderOption } = props;
  const [selected, setSelected] = useState<string>(CHAT.QUESTION);
  const lineRef = useRef<any>();
  const options = [
    {
      name: CHAT.QUESTION,
      image: question,
    },
    {
      name: CHAT.PARTICIPANTS,
      image: participants,
    },
    {
      name: CHAT.COMMENTS,
      image: comments,
    },
  ];
  const handleChatOptionClick = (e: any, option: string) => {
    handleChatHeaderOption(option);
    setSelected(option);
    lineRef.current.style.width = e.currentTarget.offsetWidth + "px";
    lineRef.current.style.left = e.currentTarget.offsetLeft + "px";
  };

  const classes = useStyles();
  return (
    <Box className={classes.headerLayout}>
      {options.map((option) => (
        <button
          key={option.name}
          data-test-id="chat-option"
          className={classes.optionLayout}
          onClick={(e) => handleChatOptionClick(e, option.name)}
        >
          <img
            src={option.image}
            className={
              selected === option.name
                ? classes.optionImageSelected
                : classes.optionImage
            }
          />
          <span
            className={
              selected === option.name
                ? classes.optionNameSelected
                : classes.optionName
            }
          >
            {option.name}
          </span>
        </button>
      ))}
      <div ref={lineRef} className={classes.line} />
    </Box>
  );
};

export default ChatSectionHeader;
