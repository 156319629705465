import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QuestionPayload, StudentAttribute } from "../../../../LiveStreamingController";
import { ACTIONS,getQuestionHeader } from "../../../../../../../components/src/types";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  contebtBoxMain:{
    padding:"80px 30px 30px",
    position:"relative"
  },
  contebtBoxMainResult:{
    padding:"25px",
    position:"relative"
  },
  topicBox: {
    height: "240px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    padding: "20px 15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    borderRadius: "5px",
  },
  topicBoxAnswer:{
    maxHeight: "50vh",
    minHeight: "240px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    padding: "20px 15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    borderRadius: "5px",
  },
  topicHeadingBox: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    paddingLeft: "15px",
    marginBottom: "10px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    height: "150px",
    wordBreak: "break-word",
    display: "flex",
    borderRadius: "5px",
    backgroundColor: "#F0F0F0",
    "&.heading-inner":{
      border: "1px solid rgba(43, 61, 125, 0.12)",
      backgroundColor: "rgba(43, 61, 125, 0.08)",
      overflowY:"auto",
    }
  },
  question: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "20px",
  },
  topicHeading: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "none",
    },
  },
  title: {
    fontSize: "16px",
    fontFamily: "Poppins",
    marginBottom: "20px",
    color: "#282829",
    fontWeight: 500,
    "&.heading-bold":{
      marginTop:"5px",
      fontWeight:"600",
    }
  },
  topic: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#636366",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "none",
    },
  },
  inCorrectImage: {
    height: "20px",
    width: "20px",
    marginLeft: "5px"
  },
  correctKwlImage: {
    height: "20px",
    width: "20px",
    marginLeft: "5px"
  },
  questionType:{
    color: "#282829",
    fontSize: "14px",
    fontFamily: "Poppins",
    marginBottom: "15px",
    fontWeight: 500,
    "& .title-top":{
      marginBottom:"10px"
    },
  },
  answerItem:{
    marginBottom:"15px"
  }
}));
const ChartQuestion = (props: any) => {
  const classes = useStyles();
  const { currentStage, studentResultList, rightKwlTick } = props;
  const [height,setHeight] = React.useState(0);
  const hasCanvasData = currentStage.attributes.canvas_data;
  let jsonCamvas =  undefined
  let canvasSize =  undefined
  if(hasCanvasData)
  {
    const parsedData = typeof hasCanvasData == "object"?hasCanvasData:JSON.parse(hasCanvasData); 
    jsonCamvas = parsedData.canvas;
    canvasSize = parsedData.canvasSize 
  }
  
  const getTypeQuestion=()=> {
    return getQuestionHeader(currentStage?.attributes.lesson_stage_name ?? "" as ACTIONS)
  }
  const mdValue =
    currentStage.attributes.description.length === 4 ||
      currentStage.attributes.description.length === 2
      ? 6
      : 4;
  return (
    <Box className={rightKwlTick?classes.contebtBoxMainResult:classes.contebtBoxMain}>
      <Box>
        <Typography className={`${classes.questionType} title-top`}>{getTypeQuestion()}</Typography>
        {  jsonCamvas && <Box style={{position:"absolute",width:"90%",height:"100%",}}>
          <StaticCanvas canvasData={jsonCamvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
          </Box>}
        <Box
          className={`${classes.title} heading-bold`}
          dangerouslySetInnerHTML={{ __html: currentStage.attributes.title }}
        />
      </Box>
      <Box>
        <Grid container spacing={2} style={{marginTop:`${height}px`}}>
          {!rightKwlTick ? currentStage.attributes.description.map(
            (element: any, index: number) => (
              <Grid key={index} item md={mdValue}>
                <Box className={`${classes.topicHeadingBox} heading-inner`}>
                  <Box
                    className={classes.topicHeading}
                    dangerouslySetInnerHTML={{
                      __html: element.topic,
                    }}
                  />
                </Box>
                <Box className={classes.topicBox}>
                  <Box
                    data-placeholder="Answers"
                    className={classes.topic}
                    dangerouslySetInnerHTML={{
                      __html: element.answer,
                    }}
                  />
                </Box>
              </Grid>
            )
          ):
            studentResultList?.questions?.map((item: QuestionPayload, indexKwlData: number) => {
              return (
                <Grid item md={mdValue} key={indexKwlData}>
                  <Box className={classes.topicHeadingBox}>
                    <Typography className={classes.topicHeading} dangerouslySetInnerHTML={{
                    __html:  studentResultList.type==ACTIONS.VENN_DIAGRAM?`Answer ${indexKwlData+1}`:item.question
                     }}/>
                  </Box>
                  <Box className={classes.topicBoxAnswer}>
                    {studentResultList.attribute?.map((answerItem: StudentAttribute) => {
                      const ansItem = answerItem.student_answer[indexKwlData];
                      return (

                        ansItem?.answer != "" &&
                        <Typography className={classes.answerItem}>{ansItem?.answer}</Typography>
                      )

                    })
                    }
                  </Box>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default ChartQuestion;
