import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Breadcrumbs } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
type OverflowXProperty = "hidden";
type TextAlignProperty = "center";
import AppHeader from "../../../components/src/AppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { filterIcon } from "./assets";
import { SearchSection } from "../../dashboard/src/SchoolAdmin/SchoolAdminDashboard.web";
import BasicPagination from "../../../components/src/BasicPagination.web";
import ItemList from "../../../components/src/ItemList.web";
import ClassListSelected from "./components/ClassListSelected.web";
import ClassStudentsListing from "./components/ClassStudentsListing.web";
import ClassList from "../../dashboard/src/SchoolAdmin/components/ClassList.web";
import SummeryReportListing from "./components/SummeryReportListing.web";
import DetailReport from "./components/DetailReport.web";
import { Header, RenderBreadCrumbs } from "../../../components/src/Analytics/AnalyticsComp.web";

// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderClassList = () => {
    return (
      <>
        {this.state.classListData.length === 0 ? (
          <div style={webStyles.noRecordText}>No records found</div>
        ) : (
          <ClassList
            classList={this.state.classListData}
            handleOpenVisible={this.handleOpenVisible}
          />
        )}
      </>
    );
  };

  renderClassDetails = () => {
    return (
      <>
        <ClassListSelected
          classList={this.state.classListData}
          selected={this.state.classTitle}
          handleModalClose={this.handleModalClose}
          handleCloseVisible={this.handleCloseVisible}
          visiblevalue={this.state.visiblevalue}
          getClassDetails={this.getClassDetails}
          internalDate={this.state.internalDate}
          setInternalDate={this.setInternalDate}
          fetchDatesAndGetDetails = {this.fetchDatesAndGetDetails}
        />
      </>
    );
  };
//
  renderStudentListing = () => {
    return (
      <>
        <ClassStudentsListing
          handleSubmitSelectStudent={this.handleSubmitSelectStudent}
          reportType={this.state.reportType}
          pageLimitStudents={this.state.pageLimitStudents}
          handleStudentsPageChange={this.handleStudentsPageChange}
          handleSearchInput={this.handleStudentSearchInput}
          studentListData={this.state.studentListData}
          teacherName={this.state.selectedTeacher?.attributes?.full_name}
          classTitle={this.state.classTitle}
          pageNoStudents={this.state.pageNoStudents}
          getSummaryReportListDataApicall={this.getSummaryReportListData}
          handleChange={this.handleChange}
          handleSelectAll={this.handleSelectAll}
          data={this.state.studentListData}
          selectAll={this.state.selectAll}
          selectedIds={this.state.selectedIds}
          getDetailReportListData={this.getDetailReportListData}
        />
      </>
    );
  };

  renderReportsView = () => {
    return <SummeryReportListing 
    summaryReportList={this.state.summaryReportList}
    openModal={this.state.openModal}
    handleCloseModal={this.handleCloseModal}
    handleOpenModal={this.handleOpenModal}
    csvSucessShow={this.state.csvSucessShow}
    getExportReportApicall={this.getExportReportApicall}
    />
};

renderDetailReportsView = () => {
  return <DetailReport navigation={""} id="Analytics" handleClick={()=>{}}/>  };

  renderDefaultView=()=>{
    interface Data {
      id:string;
      image: string;
      name: string;
    }
    const dataList:Data[] = this.state.teachersList.map((teacher:any)=>{
      return{
        id:teacher.id,
        image:teacher.attributes.image,
        name:teacher.attributes.full_name
      }
    })
    const round ={
      borderRadius:"50%"
    }
    return (
      <>
        {this.state.teachersList.length === 0 ? (
            <div style={webStyles.noRecordText}>
              No records found
            </div>
                ) : (
              <ItemList dataList={dataList} handleClick={this.getClassesList} imageShape={round}/>
            )}
      </>
    );
  }

  renderSwitch(selectedOption: string) {
    switch (true) {
      case selectedOption === "classList":
        return this.renderClassList();
      case selectedOption === "classDetails":
        return this.renderClassDetails();
      case selectedOption === "studentDetails":
        return this.renderStudentListing();
      case selectedOption === "reportsView":
        return this.renderReportsView();
        case selectedOption === "detailReportsView":
          return this.renderDetailReportsView();
      default:
        return this.renderDefaultView();
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={webStyles.container}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
          {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={webStyles.rightWrapper}>
            <Header reportType={this.state.reportType} view={this.state.view} />
            <Box paddingX={3} marginTop={5}>
              {this.state.dashboardLoading ? (
                <Box style={webStyles.loaderStyle}>
                  <CircularProgress style={{ color: "#2B3D7D" }} />
                </Box>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box style={webStyles.breadcrumbBox}>
                      <RenderBreadCrumbs
                        reportsHeading={
                          this.state.reportType === "summary"
                            ? "Summary Report"
                            : "Detailed Report"
                        }
                        view={this.state.view}
                        handleBreadCrumb={this.handleBreadCrumb}
                        classTitle={this.state.classTitle}
                        teacherName={
                          this.state.selectedTeacher?.attributes?.full_name
                        }
                      />
                    </Box>

                    {this.state.view === "teacherList" && (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <SearchSection
                          handleSearchInput={this.handleSearchInput}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderSwitch(this.state.view)}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {this.state.view === "teacherList" && (
                      <Box sx={{ px: "15px", py: "50px" }}>
                        <BasicPagination
                          handlePageChange={this.handlePageChange}
                          pageLimit={this.state.pageLimit}
                          pageNo={this.state.pageNo}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyles = {
  filterIcon: {
    cursor: "pointer",
    marginTop: "24px",
  },
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  headText: {
    fontSize: "24px",
    // color: "#2B3D7D",
    letterSpacing: "0.32px",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "30px 30px 0px",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#757575",
    cursor: "pointer",
  },
  boldTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#000000",
  },
  boldTitleDefault: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "0px",
  },
  breadcrumbBox: {
    padding: "0px 11px",
    marginTop: "0px",
  },
  noRecordText: {
    textAlign: "center" as TextAlignProperty,
    fontFamily: "Poppins",
  },
  rightWrapper: {
    height: "100%",
    overflowY: "scroll" as OverflowXProperty,
  },
  paginationBox: {
    marginTop: "100px",
    marginBottom: "50px",
  },
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
};
// Customizable Area End
