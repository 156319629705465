import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { debounce } from "lodash";
import { Roles } from "../../../components/src/types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeState:boolean;
  tabValue:number
  taskListPageNo?: number;
  taskListPageLimit?: number;
  setTaskPageNo?: Function;
  setTaskPageLimit?: Function;
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  taskLists: any[];
  page:number,
  perPage:number,
  loading: boolean,
  quizList: any[];
  quizName: string;
  role:string|null;
  value:number;

  isSmallScreen:boolean;
  searchValue: string;
  authToken: string;
  isBackFromQuiz: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  getQuizListsApiCallId = "";
  getPendingReviewApiCallId: string = "";
  getQuizPendingReviewApiCallId: string = "";
  getFeedbacksSentApiCallId: string = "";
  getQuizFeedbacksSentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",     
      taskLists: [],
      page:1,
      perPage:1,
      loading:false,
      quizList: [],
      quizName: "",
      role:"",
      value:0,

      isSmallScreen: window.innerWidth <= 600,
      searchValue: '',
      authToken: '',
      isBackFromQuiz: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getTasklistApiCall = this.getTasklistApiCall.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getQuizelistApiCall = this.getQuizelistApiCall.bind(this);
    this.handleQuizePageChange = this.handleQuizePageChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(window.localStorage.getItem("role")===Roles.STUDENT){
      this.setState({role:Roles.STUDENT})
    }
    if(window.localStorage.getItem("role")===Roles.TEACHER){
      this.setState({role:Roles.TEACHER})
    }

    let authToken = await getStorageData('authToken');
    this.setState({authToken: authToken});

    let studentTaskListTab = await getStorageData('studentTaskListTab');
    if(studentTaskListTab === 'quiz'){
      this.setIsBackFromQuizFalse(true);
    }
  }

  setIsBackFromQuizFalse = (value: boolean) => {
    this.setState({
      isBackFromQuiz: value
    })
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    // Condition for fetching task list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTaskListsApiCallId != null &&
      this.getTaskListsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.data) {
        this.setState({
          taskLists: responseJson?.data,
          loading:false,
          perPage: responseJson.meta.total_page
        });
      } else {
        this.setState({ taskLists: [], loading:false });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQuizListsApiCallId != null &&
      this.getQuizListsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.data) {
        this.setState({
          quizList: responseJson?.data,
          loading:false,
          perPage: responseJson.meta.total_pages
        });
      } else {
        this.setState({ quizList: [], loading:false });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPendingReviewApiCallId != null &&
      this.getPendingReviewApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.data) {
        this.handlePendingReviewResponse(responseJson)
        
      } else {
        this.setState({ taskLists: [], loading:false });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFeedbacksSentApiCallId != null &&
      this.getFeedbacksSentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.data) {
        this.handleFeedbacksSentResponse(responseJson)
      
      } else {
        this.setState({ taskLists: [], loading:false });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQuizPendingReviewApiCallId != null &&
      this.getQuizPendingReviewApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.data) {
        this.handlePendingReviewResponse(responseJson)
      } else {
        this.setState({ 
          taskLists: [], 
          loading:false 
        });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQuizFeedbacksSentApiCallId != null &&
      this.getQuizFeedbacksSentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handlePendingReviewResponse(responseJson)
        
      } else {
        this.setState({ taskLists: [], 
          loading:false });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

      }}
  };

  debouncedGetTaskList = debounce(() => {
    this.getTaskLists();
  }, 1000);

  setName =(value:any)=>{
    this.setState(
      {
        name: value ,
        page: 1
      },
      () => {
        this.debouncedGetTaskList();
      }
    );  
  }

   handleInputName = (name: string) => {
    this.setState({ name });
  };

  // Function to fetch task list from the API
  getTaskLists = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignmentList}page=${this.state.page}&per_page=10&search=${this.state.name}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   handlePageChange(event: any, page: number) {
    this.setState({  page: page, loading: true },()=>{
      
      this.debouncedGetTaskList();
    })
  }

  getTasklistApiCall(){
    this.setState({  page: this.state.page, loading: true },()=>{
      this.debouncedGetTaskList();
    })
  }
  //quize API Call
  debouncedGetQuizeList = debounce(() => {
    this.getQuizeLists();
  }, 1000);
  
  setQuizeName =(value:any)=>{
    this.setState(
      {
        quizName : value ,
        page: 1
      },
      () => {
        this.debouncedGetQuizeList();
      }
    );  
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getQuizeLists = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quizeList}page=${this.state.page}&per_page=10&search=${this.state.quizName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   handleQuizePageChange(event: any, page: number) {
    this.setState({  page: page, loading: true },()=>{      
      this.debouncedGetQuizeList();
    })
  }
  getQuizelistApiCall(){
    this.setState({  page: this.state.page, loading: true },()=>{
      this.debouncedGetQuizeList();
    })
  }  
  handleQuizInputName = (name: string) => {
    this.setState({ quizName:name });
  };
  getPendingReviews = (type:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  if(type==="assignment"){
   this.getPendingReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pendingReview}?type=${type}&pending_review=true&search=${this.state.searchValue}&per_page=10&page=${this.props.taskListPageNo}`
    );
  }else{
  this.getQuizPendingReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pendingReview}?type=${type}&pending_review=true&search=${this.state.searchValue}&per_page=10&page=${this.props.taskListPageNo}`
    );
   }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlePendingReviewResponse=(responseJson:any)=>{
    this.setState({
      taskLists: responseJson.data,
      loading:false,
      perPage: responseJson.meta.total_pages
    });
    this.props.setTaskPageLimit && this.props.setTaskPageLimit(responseJson.meta.total_pages);
  }

  getFeedbacksSent = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:window.localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFeedbacksSentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pendingReview}?type=assignment&feedback_sent=true&search=${this.state.searchValue}&per_page=10&page=${this.props.taskListPageNo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuizFeedbacksSent = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizFeedbacksSentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pendingReview}?type=quiz&feedback_sent=true&search=${this.state.searchValue}&per_page=10&page=${this.props.taskListPageNo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFeedbacksSentResponse=(responseJson:any)=>{
    this.setState({
      taskLists: responseJson.data,
      loading:false,
      perPage: responseJson.meta.total_pages,
    });
    this.props.setTaskPageLimit && this.props.setTaskPageLimit(responseJson.meta.total_pages);
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };

  handleGoToDetailsPage = (data: any) => {
    setStorageData("taskAssignmentData", JSON.stringify(data))
  }

  handleSeachChange = (value: string) => {
    this.setState( { searchValue: value }, () => {
      setTimeout(() => {
        this.handleCheckTab();
        this.props.setTaskPageNo && this.props.setTaskPageNo(1);
      }, 1000);
    } )
  }

  handleFeedbackPageChange(event: any, page: number) {
    this.props.setTaskPageNo && this.props.setTaskPageNo(page);
    this.setState({ loading: true }, () => this.handleCheckTab())
  }

  handleCheckTab = () => {
    if(!this.props.activeState && this.props.tabValue === 1){
      this.getFeedbacksSent();
    }else if(!this.props.activeState && this.props.tabValue === 2){
      this.getPendingReviews('assignment')
    }else if (this.props.tabValue === 1 && this.props.activeState){
      this.getQuizFeedbacksSent();
    }else {
      this.getPendingReviews('quiz')
    }
  }
  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (this.props.tabValue !== prevProps.tabValue || this.props.activeState!==prevProps.activeState) {
      this.setState({ 
        searchValue:""
      });
    }
  };
  // Customizable Area End
}
