import React, { useEffect } from "react";
import { Box,Modal,Popover } from "@material-ui/core";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { makeStyles } from "@material-ui/core/styles";
import { zoomIn, zoomOut, downloadLesson, save, drag, redo, undo, nextPage, backPage,activeDragIcon,commentIcon, moreIcon, highlighterIcon } from "../../../assets";
import PreviewStagePage from "./PreviewScreens/PreviewStagesPage.web";
import { ACTIONS } from "../../../../../../components/src/types";
import Loader from "../../../../../../components/src/Loader.web";
import MultimediaPreviewScreen from "./PreviewScreens/MultimediaPreviewScreen.web";
import OpenEndedPreview from "./PreviewScreens/OpenEndedPreview.web";
import McqPreviewPage from "./PreviewScreens/McqPreviewPage.web";
import PollPreviewScreen from "./PreviewScreens/PollPreviewScreen.web";
import WordCloudPreview from "./PreviewScreens/WordCloudPreview.web";
import MatchingQuesPreview from "./PreviewScreens/MatchingQuesPreview.web";
import ChartPreview from "./PreviewScreens/ChartPreview.web";
import VennDiagramPreview from "./PreviewScreens/VennDiagramPreview.web";
import { v4 as uuidv4 } from 'uuid';
import MoveSuccessPopup from "./MoveSuccessPopup.web";
import PopUp from "../../SaveAnnotationPopup.web";
import HighlighterList from "../../../../../annotations/src/Teacher/components/HighlighterList.web";
import Comments from "../../../../../comments/src/Comments.web";

const useStyles = makeStyles(() => ({
  previewArea: {
    fontFamily: "Poppins",
    height: "784px",
    width: "100%",
    marginTop: "20px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    flexDirection: "column",
    gap: "25px",
    backgroundColor:"#FFFFFF"
  },
  toolbar: {
    height: "52px",
    width: "100%",
    backgroundColor: "#FDF4DC",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexShrink:0
  },
  imgList: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
    marginRight: "35px",
    alignItems:"center"
  },
  paginationLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "64px"
  },
  pageNo: {
    backgroundColor: "#FFFFFF",
    width: "75px",
    display: "flex",
    justifyContent: "center",
    gap: "12px",
    margin:"10px"
  },
  canvasContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  annotationContainer: {
    position: 'absolute',
    transform: 'translateY(10px)',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    borderRadius: '6px',
    paddingTop:'6px',
    paddingBottom:'6px'
  },
  annotationItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '& img': {
      marginRight: '8px',
    }
  }
}));
interface Position{
  x:number,
  y:number
}
const LessonPreviewPage = (props: any) => {
  const {
    locations,
    currentLocation,
    redirect,
    stageType,
    nextStage,
    prevStage,
    currentStage,
    loading,
    isAnnotate,
    handleHighlightText,
    handleOpenAnnotationConfirmation,
    handleCloseAnnotationConfirmation,
    openAnnotationConfirmation,
    handleCloseSuccessPopup,
    openMoveSuccessPopup,
    highlights,
    handleHighlightedTextData,
    highlightedData,
    applyHighlightDirectly,
    redoHighlight,
    commentData,
    getCommentData,
    removeCommentIcon,
    stages,
    stagesData,
    handleSaveClick,
    checkSignIn,
    handleCloseGdriveModal,
    openFileUploadSuccess,
    uploading,
    enableLimitSnackbar
  } = props;
  const classes = useStyles();
  const [zoomLevel, setZoomLevel] = React.useState<number>(1);
  const [position, setPosition] = React.useState<Position>({ x: 0, y: 0 });
  const [dragging, setDragging] = React.useState<boolean>(false);
  const [isDrag,setIsDrag]= React.useState<boolean>(false);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = React.useState({ x: 0, y: 0 });
  const [highlightId,setHighlightId]=React.useState(0)
  const [anchorEl,setAnchorEl] = React.useState<any>(null);
  const [comment,setComment]=React.useState<any>({})
  const [anchorEll,setAnchorEll]=React.useState<any>({})
  const [commentArr,setCommentArr]=React.useState([])
  

  const posRef = React.useRef(position);
  posRef.current = position;
  const handleZoomIn = () => {
    if(!(currentStage.attributes.videos_url[0])  && (currentStage.attributes.videos===null)){
      setZoomLevel(zoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.1 && !(currentStage.attributes.videos_url[0])  && (currentStage.attributes.videos===null)) 
    setZoomLevel(zoomLevel - 0.1);
  };
  
  const toggleDrag=()=>{
    if(!(currentStage.attributes.videos_url[0])  && (currentStage.attributes.videos===null)){
      setIsDrag(!isDrag)
    }
    
  }
  const startDrag = React.useCallback((event) => {
    if (!isDrag) return;
    event.preventDefault();
    setDragging(true);
    const baseX = event.clientX - posRef.current.x;
    const baseY = event.clientY - posRef.current.y;

    const handleMouseMove = (moveEvent:any) => {
      const newX = moveEvent.clientX - baseX;
      const newY = moveEvent.clientY - baseY;
      setPosition({ x: newX, y: newY });
    };

    const stopDrag = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', stopDrag);
      setDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', stopDrag);
  }, [isDrag]);
  
  const clearHighlights = () => {
    document.querySelectorAll('span[style*="background-color"]').forEach(span => {
      const parent = span.parentNode;
      if (parent) {
        parent.replaceChild(document.createTextNode(span.textContent || ""), span);
      }
    });
  };
  const handleNextPage=()=>{
    clearHighlights()
    setZoomLevel(1)
    setPosition({x: 0, y: 0})
    setIsDrag(false)
    nextStage()
  }
  const handlePrevPage=()=>{
    clearHighlights()
    setZoomLevel(1)
    setPosition({x: 0, y: 0})
    setIsDrag(false)
    prevStage()
  }
  const renderLayout = () => {
    if (stageType === ACTIONS.ADD_TEXT) {
      return (
        <PreviewStagePage currentStage={currentStage} />
      );
    } else if (stageType === ACTIONS.MULTIMEDIA || stageType === "add_multimedia") {
      return (
        <MultimediaPreviewScreen multiMediaData={currentStage} loading={loading}/>
      )
    } else if (stageType === ACTIONS.OPEN_ENDED_QUESTION) {
      return (
        <OpenEndedPreview currentStage={currentStage} />
      )
    } else if (stageType === ACTIONS.MCQ) {
      return (
        <McqPreviewPage currentStage={currentStage} />
      )
    } else if (stageType === "polling_question") {
      return (
        <PollPreviewScreen currentStage={currentStage} />
      )
    } else if (stageType === ACTIONS.ADD_WORD_CLOUD) {
      return (
        <WordCloudPreview currentStage={currentStage} />
      )
    } else if (stageType === ACTIONS.ADD_MATCHING_Q) {
      return (<MatchingQuesPreview currentStage={currentStage} />)
    } else if (stageType === ACTIONS.KWL_CHART ||
      stageType === ACTIONS.T_CHART ||
      stageType === ACTIONS.SEED_DISCUSSION ||
      stageType === ACTIONS.WH_CHART) {
      return (<ChartPreview currentStage={currentStage} />)
    } else if(stageType===ACTIONS.VENN_DIAGRAM){
      return(
        <VennDiagramPreview currentStage={currentStage} />
      )
    }

    else {
      return (<></>)
    }
  };
  
    const handleMouseUp = (e:any) => {
      const selection = window.getSelection();
      if (!selection || selection.isCollapsed) {
        setShowTooltip(false);
        return;
      }
      const text = selection.toString();
      if (text) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const scrollContainer = document.querySelector(`.${classes.canvasContainer}`);
        const scrollX = scrollContainer ? scrollContainer.scrollLeft : 0;
        const scrollY = scrollContainer ? scrollContainer.scrollTop : 0;
        const tooltipWidth = 640; 
        let tooltipX = rect.left + window.scrollX - tooltipWidth + scrollX;
        let tooltipY = rect.top + window.scrollY - 200 + scrollY;
        const windowWidth = window.innerWidth;
        if (tooltipX + tooltipWidth > windowWidth) {
          tooltipX = windowWidth - tooltipWidth - 10;
        }
    
        if (tooltipX < 0) {
          tooltipX = 10;
        }
    
        setShowTooltip(true);
        setTooltipPos({ 
          x: tooltipX, 
          y: tooltipY
        });
      } else {
        setShowTooltip(false);
      }
    };
    React.useEffect(() => {
      const handleMouseUpOutside = (event:any) => {
        handleMouseUp(event);
      };
      document.addEventListener('mouseup', handleMouseUpOutside);
      return () => {
        document.removeEventListener('mouseup', handleMouseUpOutside);
      };
    }, []);
    

function assignUUID(node:any) {
    node.id = uuidv4();
    return node.id;
}
function calculateGlobalOffset(node:any, localOffset:any) {
  let globalOffset = localOffset;
  while (node.previousSibling) {
      node = node.previousSibling;
      globalOffset += node.textContent.length;
  }
  return globalOffset;
}
const getNodeIndex = (node:any) => {
  let index = 0;
  while (node = node.previousSibling) {
    index++;
  }
  return index;
};

  const applyHighlight = (color:string) => {

      const selection = window.getSelection();
      if (!selection ||!selection.rangeCount || selection.isCollapsed) {
        setShowTooltip(false);
        return;
      }
      const contentElement:any=document.getElementById("content")
      const text=selection.toString()
      const range = selection.getRangeAt(0);
      const preRange=document.createRange();
      preRange.setStart(contentElement,0)
      preRange.setEnd(range.startContainer, range.startOffset)
      const startOffset=preRange.toString().length;
      const endOffset=startOffset+ text.length
      const selectedContent = range.extractContents();
      const spans = selectedContent.querySelectorAll('span');
      const startContainer = range.startContainer;
      const endContainer = range.endContainer;
      const startParent:any| null = startContainer.nodeType === 3 ? startContainer.parentNode : startContainer;
      const endParent:any | null = endContainer.nodeType === 3 ? endContainer.parentNode : endContainer;
      const startId =assignUUID(startParent);
      const endId = assignUUID(endParent);
      spans.forEach((span:any) => {
        const parent = span.parentNode;
        const textNode = document.createTextNode(span.textContent);
        parent.insertBefore(textNode, span);
        parent.removeChild(span);
      });
      const startIndex = getNodeIndex(startParent);
      const endIndex = getNodeIndex(endParent);
      const highlightSpan = document.createElement('span');
      highlightSpan.style.backgroundColor = color;
      highlightSpan.appendChild(selectedContent);
      highlightSpan.setAttribute('data-highlight-id', `highlight-${highlightId}`);
      highlightSpan.appendChild(selectedContent);
      range.insertNode(highlightSpan);
      let data={
        id: `highlight-${highlightId}`,
        lesson_markup_tools_id: currentStage.id,
        highlight_text: text,
        colour_code: color,
        offset: startOffset,
        containerId: startId,
        container:startIndex,
        end_offset: endOffset,
        end_containerId: endId,
        end_container: endIndex,
      }
      updateHighlightStorage(data); 
      setHighlightId(highlightId+1)
      selection.removeAllRanges();
      setShowTooltip(false);
      setOpenPopover(false)
    };
    
    let undoStack:any = JSON.parse(window.localStorage.getItem('annotateItems') || '[]');
    let redoStack:any=[]
    const updateHighlightStorage = (data:any) => {
    let highlightItems:any=  window.localStorage.getItem('annotateItems');
    highlightItems = highlightItems ? JSON.parse(highlightItems) : [];
    highlightItems.push(data);
    window.localStorage.setItem('annotateItems', JSON.stringify(highlightItems));
    undoStack = [...highlightItems];
    redoStack = [];
  };
const handleUndo = () => {
  if (undoStack.length > 0) {
    const lastHighlight = undoStack.pop();
    const elements = document.querySelectorAll(`[data-highlight-id="${lastHighlight.id}"]`);
    elements.forEach((el:any) => {
      el.parentNode.replaceChild(document.createTextNode(el.textContent || ''), el);
    });
    window.localStorage.setItem('annotateItems', JSON.stringify(undoStack));
    redoStack.push(lastHighlight);
  }
};

React.useEffect(()=>{
  const findHighlight = highlights.filter((obj:any) => {
    const lessonIdMatch = obj.attributes.lesson_markup_tools_id === Number(currentStage.id);
    const quizIdMatch = obj.attributes.quiz_markup_tool_id === Number(currentStage.id);
    const assignmentIdMatch = obj.attributes.assignment_markup_tool_id === Number(currentStage.id);
    return lessonIdMatch || quizIdMatch || assignmentIdMatch;
  });
  handleHighlightedTextData(findHighlight);
},[highlights,currentStage])
React.useEffect(()=>{
  if(highlightedData && highlightedData.length>0){
    highlightedData.forEach((element:any) => {
      applyHighlightDirectly(element)
    });
  }
},[highlightedData])

const handleRedo = () => {
if (redoStack.length > 0) {
  const highlightToRedo = redoStack.pop();
  redoHighlight(highlightToRedo);
  undoStack.push(highlightToRedo);
  window.localStorage.setItem('annotateItems', JSON.stringify(undoStack));
}
};

const [openPopover, setOpenPopover] = React.useState<boolean>(false);
const [openCommentSection, setOpenComment]= React.useState<boolean>(false);
const handleOpenPopover = (e:any) => {
  setShowTooltip(true)
  e.preventDefault();
  setOpenPopover(true);
};
const handleMouseDown = (e:any) => {
  e.preventDefault(); 
};
const handleOpenComment=(e:any)=>{
  e.preventDefault();
  setTooltipPos({ x: e.clientX, y: e.clientY }); 
  setAnchorEll(e.currentTarget)
  setOpenComment(true)
  setShowTooltip(false)

}

  const textContainer1 =document.querySelector(`.${classes.canvasContainer}`);
  const handleClose=()=>{
    setAnchorEl(null)
    setAnchorEll(null)
  }
  const editComment=(id:string,index:number)=>{
      let result:any = Object.values(findSameComment);
      setCommentArr(result[index])
const commentIdData=commentData.find((obj:any)=>obj.id===id)
setComment(commentIdData)
  }
  const removeCommentIconn = (stageId: string) => {
    const iconElements = document.querySelectorAll(`.stage-${stageId}`);
  iconElements.forEach(iconElement => {
    iconElement.remove();
  });
    getCommentData(stageId)
  }
  const handleRemoveComment=()=>{
    setComment({})
  }
  const markTextWithIcon = (data:any, length:number,index:number) => {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.id = `comment-icon-${data.id}`;
    wrapperDiv.className = `stage-${data.stageId}`;
    wrapperDiv.draggable = true;
    wrapperDiv.style.backgroundColor = 'white';
    wrapperDiv.style.borderRadius = '50%';
    wrapperDiv.style.width = '35px';
    wrapperDiv.style.height = '35px';
    wrapperDiv.style.display = 'flex';
    wrapperDiv.style.alignItems = 'center';
    wrapperDiv.style.justifyContent = 'center';
    wrapperDiv.style.position = 'absolute';
    wrapperDiv.style.left = `${data.hozPos}px`;
    wrapperDiv.style.top = `${data.verPos}px`;
    wrapperDiv.style.boxShadow = 'rgba(52, 146, 159, 0.16) 0px 0px 3px 1px';
    wrapperDiv.style.cursor = "pointer";
  
    wrapperDiv.addEventListener('click', (e) => {
      setAnchorEl(e.target);
      editComment(data.id,index);
    });
    const initialPos = { x: data.hozPos, y: data.verPos };
    let offsetX = 0, offsetY = 0, isDragging = false;
  
    wrapperDiv.addEventListener('mousedown', (e) => {
      isDragging = true;
      offsetX = e.clientX - wrapperDiv.offsetLeft;
      offsetY = e.clientY - wrapperDiv.offsetTop;
    });
  
    document.addEventListener('mousemove', (e) => {
      if (isDragging) {
        wrapperDiv.style.left = `${e.clientX - offsetX}px`;
        wrapperDiv.style.top = `${e.clientY - offsetY}px`;
      }
    });
  
    document.addEventListener('mouseup', () => {
      if (isDragging) {
        isDragging = false;
        wrapperDiv.style.left = `${initialPos.x}px`;
        wrapperDiv.style.top = `${initialPos.y}px`;
      }
    });
  
    const wrapperBadgeDiv = document.createElement('div');
    wrapperBadgeDiv.style.backgroundColor = '#2B3D7D';
    wrapperBadgeDiv.style.borderRadius = '50%';
    wrapperBadgeDiv.style.width = '18px';
    wrapperBadgeDiv.style.height = '18px';
    wrapperBadgeDiv.style.top = '0';
    wrapperBadgeDiv.style.right = '0';
    wrapperBadgeDiv.style.position = 'absolute';
    wrapperBadgeDiv.style.display = 'flex';
    wrapperBadgeDiv.style.alignItems = 'center';
    wrapperBadgeDiv.style.justifyContent = 'center';
    wrapperBadgeDiv.style.fontSize = '10px';
    wrapperBadgeDiv.style.color = '#fff';
  
    if (length > 1) {
      wrapperBadgeDiv.innerHTML = length.toString();
    }else{
      wrapperBadgeDiv.innerHTML = "1"
    }
  
    const img = document.createElement('img');
    img.src = commentIcon;
    img.style.width = '15px';
    img.style.height = '15px';
    img.style.position = 'relative';
    wrapperDiv.appendChild(img);
    wrapperDiv.appendChild(wrapperBadgeDiv);
  
    document.body.appendChild(wrapperDiv);
    const textContainer = document.querySelector(`.${classes.canvasContainer}`);
    if (textContainer) {
      textContainer.appendChild(wrapperDiv);
    }
  };
  const findSameComment = commentData.reduce((acc:any, obj:any) => {
    let key = `${obj.hozPos}-${obj.verPos}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  
  useEffect(() => {
let result = Object.values(findSameComment);
    if (textContainer1 !== null && result.length > 0 ) {
      result.forEach((arr:any,index:number) => {
        if (arr.length > 0) {
          markTextWithIcon(arr[0], arr.length,index);
        }
      });
    }
  }, [textContainer1, commentData]);
  const getMainStagesLength = () => {
    return stages.filter((mainStage:any)=>/^\d+$/.test(mainStage.attributes.stage_number)).length
  }
 
  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={currentLocation}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.previewArea}>
          <Box className={classes.toolbar}>
            <Box className={classes.paginationLayout}>
              Page
              <div className={classes.pageNo}>
                <img
                  src={backPage}
                  onClick={handlePrevPage}
                  style={{ cursor: "pointer" }}
                  data-test-id="prev-page" 
                />
                {currentStage?.stageNumber}
                <img 
                src={nextPage} 
                onClick={handleNextPage} 
                style={{ cursor: "pointer" }}
                data-test-id="next-page" 
                />
              </div>
              of {getMainStagesLength()}
            </Box>
            <Box className={classes.imgList}>
              <img
               src={zoomOut} 
               onClick={handleZoomOut}
               data-test-id="zoom-out"
               style={{cursor:"pointer"}}
               />
              <img 
               src={zoomIn}
               onClick={handleZoomIn}
               data-test-id="zoom-in"
               style={{cursor:"pointer"}}
               />
              <img 
               src={isDrag?activeDragIcon:drag} 
               onClick={toggleDrag}
               data-test-id="toggle-drag"
               style={{cursor:"pointer"}}
               />
              {/* <img src={undo} data-test-id="undo-option" onClick={handleUndo} style={{cursor:"pointer"}} />
              <img src={redo} data-test-id="redo-option" onClick={handleRedo} style={{cursor:"pointer"}} /> */}
              <img src={save} style={{cursor:"pointer"}} onClick={handleOpenAnnotationConfirmation}/>
            </Box>
          </Box>
          <Box style={{overflow: 'hidden',height:"100%"}}>
          {loading ? (
            <Loader loading />
          ) : (
            <div 
            className={classes.canvasContainer} 
            id="canvasContainer"
          >
            <div
              style={{
                transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                transition: dragging ? 'none' : 'transform 0.3s ease-out',
                transformOrigin: '0 0', 
                cursor: isDrag?"grab":"default",
              }}
              data-test-id="start-drag"
              onMouseDown={startDrag}
            >
          <Box 
          data-test-id="open-annotation"
          onMouseUp={(e)=>handleMouseUp(e)}>
            {showTooltip && isAnnotate && 
            (
              <div
        className={classes.annotationContainer}
        style={{
          left: `${tooltipPos.x}px`,
          top: `${tooltipPos.y}px`,
          zIndex:2
        }}
      >
        <div 
         data-test-id='open-colorList'
         className={classes.annotationItem}
         onClick={(e)=>handleOpenPopover(e)} 
         onMouseDown={handleMouseDown}
        >
          <img src={highlighterIcon} alt="Highlight"/>
          Add Highlight
          <img src={moreIcon} alt='more' style={{marginLeft:"10px"}}/>
        </div>
        <div className={classes.annotationItem} data-test-id='add-comment' onClick={(e)=>handleOpenComment(e)} onMouseDown={handleMouseDown}>
          <img src={commentIcon} alt="Comment"/>
          Add Comment
        </div>
      </div>)
            }
            {openPopover && <HighlighterList
            data-test-id="annotation"
              applyHighlight={applyHighlight}
              tooltipPos={tooltipPos}
             />}
             {openCommentSection && 
             (<Comments
             data-test-id="comment"
             id="" 
             navigation={undefined} 
             tooltipPos={tooltipPos}
             stageId={currentStage.id}
             setOpenComment={setOpenComment}
             setAnchorEl={setAnchorEl}
             comment={comment}
             getCommentData={getCommentData}
             anchorEl={anchorEl}
             anchorEll={anchorEll}
             setAnchorEll={setAnchorEll}
             removeCommentIcon={removeCommentIcon}
             commentArr={commentArr}
             removeCommentIconn={removeCommentIconn}
             handleRemoveComment={handleRemoveComment}
             enableLimitSnackbar={enableLimitSnackbar}
             />)}
             <Popover
             data-test-id="comment-popover"
          anchorEl={anchorEl}
          open={anchorEl!=null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 8,  
            horizontal: 25,  
          }}
          transformOrigin={{          
            vertical: "top",     
            horizontal: "right", 
          }}
        >
          <Comments 
             id="" 
             navigation={undefined} 
             tooltipPos={tooltipPos}
             stageId={currentStage.id}
             setOpenComment={setOpenComment}
             setAnchorEl={setAnchorEl}
             comment={comment}
             getCommentData={getCommentData}
             anchorEl={anchorEl}
             anchorEll={anchorEll}
             setAnchorEll={setAnchorEll}
             removeCommentIconn={removeCommentIconn}
             commentArr={commentArr}
             removeCommentIcon={removeCommentIcon}
             handleRemoveComment={handleRemoveComment}
             enableLimitSnackbar={enableLimitSnackbar}
             />
          </Popover>  
            {renderLayout()}
          </Box>
          </div>
      </div>)}
          </Box>
        </Box>
      </Box>
      <Modal open={openAnnotationConfirmation} onClose={handleCloseAnnotationConfirmation}>
        <PopUp onClose={handleCloseAnnotationConfirmation} handleHighlightText={handleHighlightText} type={currentStage?.type} stagesData={stagesData} handleSaveClick={handleSaveClick} checkSignIn={checkSignIn}/>
      </Modal>
      <Modal open={openMoveSuccessPopup} onClose={handleCloseSuccessPopup}>
        <MoveSuccessPopup
          handleClose={handleCloseSuccessPopup}
          type={"Annotations"}
        />
      </Modal>
      <Modal open={openFileUploadSuccess} onClose={handleCloseGdriveModal}>
      <MoveSuccessPopup
          handleClose={handleCloseGdriveModal}
          type={"Gdrive"}
        />
      </Modal>
      <Modal
          open={uploading}
          closeAfterTransition
        >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '110px',
            height: '70px',
            backgroundColor: '#fff', 
            borderRadius: '8px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            padding: '20px',
          }}
        >
            <Loader loading={uploading} top="34px" left="58px"/>
          </Box>
        </Modal>
    </>
  );
};

export default LessonPreviewPage;
