import React from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Box,
  Typography,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Modal,
  NativeSelect,
  InputBase,
} from "@material-ui/core";
import { imageUpload, errorAlert } from "../assets";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import ProfileUpdatedModal from "./ProfileUpdatedModal.web";

const useStyles = makeStyles(() => ({
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
  inputLabelError: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#FE575A",
  },
  inputLabelBlack: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1C1E",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#3F526D",
  },
  inputValueBlack: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  container: {
    padding: "0 35px",
  },
  // halfLayout: {
  //   width: "48.5%",
  // },
  passwordTitle: {
    fontSize: "32px",
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  coloredBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "165px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  uploadBtn: {
    width: "191px !important",
    margin: "15px 0",
  },
  normalBtn: {
    borderRadius: "10px",
    width: "165px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    marginRight: "20px",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    padding: "50px 0",
  },
  setImage: {
    '@media (max-width: 420px)': {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  profileImageLayout: {
    borderRadius: "50%",
    marginRight: "10px",
    width: "160px",
    height: "160px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: "rgba(51,141,157,0.1)",
  },
  uploadLayout: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
    // backgroundColor:'pink'
  },
  setImageFlex:{
    display: "flex",
    '@media (max-width: 550px)': {
      display: 'block', // Example: change to block on screens 600px or smaller
    },
  },
  uploadBtnLayout: {
    marginLeft: "40px",
  },
  constraints: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#282829",
    fontWeight: 400,
  },
  uploadImage: {
    width: "44px",
    height: "44px",
    cursor: "pointer",
  },
  uploadedImage: {
    width: "100%",
    cursor: "pointer",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  errorAlertIcon: {
    width: "18px",
    height: "18px",
    marginRight: "10px",
  },
  errorLayout: {
    display: "flex",
    marginTop: "10px",
  },
}));

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #C0C0C0",
      fontSize: "18px",
      fontFamily: "Poppins",
      padding: "10px 26px 10px 12px",
      minHeight: "37px",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#2B3D7D",
        backgroundColor: "unset",
      },
    },
  })
)(InputBase);
const genderOptions = ["Male", "Female", "Other"];
const MyProfile = (props: any) => {
  const classes = useStyles();
  const { profile, configJSON, updateProfileData, redirect } = props;
  const [isEditable, setIsEditable] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const fileInputRef = React.useRef<any>(null);
  const [selectedImage, setSelectedImage] = React.useState<any>(imageUpload);
  const [imagePreview, setImagePreview] = React.useState<string | undefined>(
    undefined
  );
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>(profile);
  const [imageUploadErrorMessage, setImageUploadErrorMessage] = React.useState<
    string
  >("");
  const [errors, setErrors] = React.useState<any>({});
  const handleChange = (e: any) => {

    const { name, value } = e.target;
    const validationRules: any = {
      fullName: /^.+$/,
      email: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]+/,
      currentPassword: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
      institution: /^.+$/,
    };
    if (!value.match(validationRules[name])) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Invalid input",
      }));

    } else {
      if(name == "institution" && value.trim()==""){
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: "Invalid input",
        }));
      }
      else{
        setErrors((prevErrors: any) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    window.location.replace("/profile/edit")
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };
  const handleSubmit = () => {
    if (imageUploadErrorMessage) return;
    const institute = formData.institution;
    if(!institute || institute.trim()=="") {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        "institution": "Invalid input",
      }));
    }
    if (!(formData.fullName && formData.email && formData.currentPassword && formData.institution)) return;
    if (Object.keys(errors).length) return
    setShowPassword(false);
    if (!imagePreview) {
      setImagePreview(formData.image);
      // If image is not changed, not need to again upload it
      if (formData.image) delete formData.image;
    } else {
      // If image is changed, upload the new image
      formData.image = selectedImage;
    }
    setIsEditable(!isEditable);
    updateProfileData(formData);
    setOpenModal(true);
  };
  const handleSelectImage = () => {
    if (!isEditable) return;
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleCancel = () => {
    setImageUploadErrorMessage("");
    setErrors({});
    setImagePreview(undefined);
    setIsEditable(false);
    setShowPassword(false);
    setFormData(profile);
  };
  const handleImageUpload = (event: any) => {
    const selectedFile = event.target.files[0]; // Get the first selected file
    if (selectedFile) {
      if (selectedFile.size > 50 * 1024) {
        setImageUploadErrorMessage(configJSON.errorMessage.imageSize);
      } else if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        setImageUploadErrorMessage(configJSON.errorMessage.imageFormat);
      } else {
        // Clear any previous error messages
        setImageUploadErrorMessage("");
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(selectedFile);
        setSelectedImage(selectedFile);
      }
    }
  };
  const executeAction = () => {
    if (isEditable) {
      handleSubmit();
    } else {
      toggleEditable();
    }
  };
  const renderBtn = () => {
    if (isEditable) {
      return "Save Profile";
    } else {
      return "Edit Profile";
    }
  };
  const renderVisibilityIcon = () => {
    if (showPassword) {
      return <Visibility />;
    } else {
      return <VisibilityOff />;
    }
  };
  const renderPasswordType = () => {
    if (showPassword) {
      return "text";
    } else {
      return "password";
    }
  };
  const renderCancelBtn = () => {
    return (
      <Button
        data-testid={"cancel-btn"}
        variant="outlined"
        className={classes.normalBtn}
        onClick={!isEditable ? () => redirect("Profile Settings") : handleCancel}
      >
        Cancel
      </Button>
    )
  };
  const renderImageClass = () => {
    return formData.image ? classes.uploadedImage : classes.uploadImage
  }
  const renderClassBasedOnErrors = (fieldName: string) => {
    return Boolean(errors[fieldName]) ? classes.inputLabelError : classes.inputLabel
  }
  const renderClassBasedOnPasswordError = () => {
    return Boolean(errors.currentPassword) ? classes.inputLabelError : classes.inputLabelBlack
  }
  return (
    <Box className={classes.container}>
      <form>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Grid item md={6}>

              <Grid container spacing={2} className={classes.uploadLayout}>

                <Grid item xs={12} md={6}>

                  <input
                    data-testid={"image-upload-input"}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                  />
                  <div className={classes.setImageFlex}>
                    <div >
                      <Box
                        data-testid={"select-image-circle-layout"}
                        className={classes.profileImageLayout}
                        onClick={handleSelectImage}
                      >
                        {imagePreview ? (
                          <img src={imagePreview} alt="Image Preview" width={"100%"} />
                        ) : (
                          <img
                            alt="Default Image"
                            src={formData.image ? formData.image : imageUpload}
                            className={renderImageClass()}
                          />
                        )}
                      </Box>

                    </div>

                    <div >
                      <Box className={classes.uploadBtnLayout}>
                        <Button
                          className={`${classes.coloredBtn} ${classes.uploadBtn}`}
                          onClick={handleSelectImage}
                        >
                          Upload Photo
                        </Button>
                        {imageUploadErrorMessage && (
                          <Box className={classes.errorLayout}>
                            <img className={classes.errorAlertIcon} src={errorAlert} alt="Error Icon" />
                            <Typography className={classes.error}>
                              {imageUploadErrorMessage}
                            </Typography>
                          </Box>
                        )}
                        <Typography className={classes.constraints}>
                          {configJSON.imageConstraints}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </Grid>
              </Grid>

            </Grid>

          </Grid>


          <Grid item md={6} xs={12}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <label
                className={renderClassBasedOnErrors("fullName")}
                htmlFor="fullName"
              >
                Full Name*
              </label>
              <TextField
                id="fullName"
                name="fullName"
                data-testid={"fullName"}
                fullWidth
                placeholder="Enter Your Name"
                variant="outlined"
                onChange={handleChange}
                value={formData.fullName}
                disabled={!isEditable}
                InputProps={{
                  className: classes.inputValue,
                }}
              />
              {Boolean(errors.fullName) && (
                <Box className={classes.errorLayout}>
                  <img className={classes.errorAlertIcon} src={errorAlert} />
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: configJSON.errorMessage.fullName,
                    }}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
              <label className={classes.inputLabel} htmlFor="gender">
                Gender (optional)
              </label>
              <NativeSelect
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                disabled={!isEditable}
                input={<BootstrapInput />}
              >
                {!formData.gender && <option value="">Select</option>}
                {genderOptions.map((option) => (
                  <option
                    key={option}
                    value={option.toLowerCase()}
                    className={classes.inputValue}
                  >
                    {option}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <label
                className={renderClassBasedOnErrors("email")}
                htmlFor="email"
              >
                Email ID*
              </label>
              <TextField
                id="email"
                name="email"
                data-testid={"email"}
                fullWidth
                placeholder="Enter Email"
                variant="outlined"
                onChange={handleChange}
                value={formData.email}
                disabled={!isEditable}
                InputProps={{
                  className: classes.inputValue,
                }}
              />
              {Boolean(errors.email) && (
                <Box className={classes.errorLayout}>
                  <img className={classes.errorAlertIcon} src={errorAlert} />
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: configJSON.errorMessage.email,
                    }}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined">
              <label
                className={renderClassBasedOnErrors("institution")}
                htmlFor="institution"
              >
                Institution*
              </label>
              <TextField
                id="institution"
                name="institution"
                data-testid={"institution"}
                fullWidth
                placeholder="Enter Institution"
                variant="outlined"
                onChange={handleChange}
                disabled={!isEditable}
                value={formData.institution}
                InputProps={{
                  className: classes.inputValue,
                }}
              />
              {Boolean(errors.institution) && (
                <Box className={classes.errorLayout}>
                  <img className={classes.errorAlertIcon} src={errorAlert} />
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: configJSON.errorMessage.institution,
                    }}
                  />
                </Box>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <label className={classes.inputLabel} htmlFor="summary">
                  Summary (Optional)
                </label>
                <TextField
                  id="summary"
                  name="summary"
                  fullWidth
                  placeholder="Enter Summary"
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={handleChange}
                  value={formData.summary}
                  disabled={!isEditable}
                  InputProps={{
                    className: classes.inputValue,
                  }}
                />
              </FormControl>

            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>

            <Grid item md={6} xs={12}>
              <Typography className={classes.passwordTitle}>
                Password
              </Typography>
              <FormControl fullWidth margin="normal" variant="outlined">
                <label
                  className={renderClassBasedOnPasswordError()}
                  htmlFor="currentPassword"
                >
                  Current Password*
                </label>
                <OutlinedInput
                  id="currentPassword"
                  name="currentPassword"
                  data-testid={"currentPassword"}
                  type={renderPasswordType()}
                  placeholder="Enter Password"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  disabled={!isEditable}
                  className={classes.inputValueBlack}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!isEditable}
                        data-testid={"show-password"}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {renderVisibilityIcon()}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {Boolean(errors.currentPassword) && (
                  <Box className={classes.errorLayout}>
                    <img className={classes.errorAlertIcon} src={errorAlert} />
                    <Typography
                      className={classes.error}
                      dangerouslySetInnerHTML={{
                        __html: configJSON.errorMessage.password,
                      }}
                    />
                  </Box>
                )}
              </FormControl>
            </Grid>

          </Grid>
          <Grid item xs={10} md={12}>

            <Box className={classes.btnLayout}>

              {renderCancelBtn()}
              <Button
                data-testid={"edit-save-button"}
                variant="outlined"
                className={classes.coloredBtn}
                onClick={executeAction}
              >
                {renderBtn()}
              </Button>
            </Box>

            <Modal open={openModal} onClose={handleCloseModal}>
              <ProfileUpdatedModal
                data-testid={"profile-update-modal"}
                configJSON={configJSON}
                handleCloseModal={handleCloseModal}
              />
            </Modal>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default MyProfile;
