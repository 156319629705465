Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.getUserGroupsApiEndPoint = "account_block/user_groups";
exports.getClassesListApiEndPoint = "/account_block/user_groups/show_user_group_class";
exports.getStudentListApiEndPoint = "/account_block/user_groups/usergroup_student_list";
exports.getApiMethod = "GET";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getAssignmentsApiEndPoint = "bx_block_content_management/assignments/get_current_user_assignments";
exports.getStudentsApiEndPoint = "account_block/user_groups/student_list";
exports.getClassesApiEndPoint = "bx_block_content_management/teacher_flows/current_user_classes_list";
exports.getLessonsApiEndPoint = "bx_block_content_management/teacher_flows/get_current_user_lessons";
exports.getQuizesApiEndPoint = "bx_block_content_management/teacher_flows/get_current_user_quizzes";
exports.addUserGroupApiMethod = "POST";
exports.addUserGroupApiEndPoint = "account_block/user_groups";
exports.getTeacherUserGroupsApiEndPoint = "account_block/user_groups";
exports.editUserGroupApiEndPoint = "account_block/user_groups";
exports.editUserGroupApiMethod = "PUT";
exports.deleteUserGroupApiEndPoint = "account_block/user_groups/delete";
exports.deleteUserGroupApiMethod = "DELETE"
exports.viewStudent = "account_block/accounts/show"
exports.getMethod = "GET"
// Customizable Area End
