import React from "react";
import ClassList from "../../../../dashboard/src/Teacher/components/ClassList.web";
import { Box, Modal, Typography } from "@material-ui/core";
import SearchInput from "../../../../../components/src/SearchInput.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { makeStyles } from "@material-ui/core/styles";
import CreateClassModal from "../components/CreateClassModal.web";
const useStyles = makeStyles((theme) => ({
  searchBarLayout: {
    display: "flex",
    paddingLeft: "35px",
    marginTop: "30px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      paddingLeft: "20px",
      alignItems: "center",
      gap: "12px"
    },
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    fontSize: "18px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      paddingTop: "20px",
    },
  },
  btnBox: {
    [theme.breakpoints.down('sm')]: {
      marginRight: "16px",
      width: "95%"
    },
    [theme.breakpoints.up(400)]: {
      width: "75%",
      marginRight: "16px",
    },
  }
}));

const btnStyle = {
  height: "52px",
};
const MyClasses = (props: any) => {
  const classes = useStyles();
  const {
    classList,
    handleSearchClassName,
    searchInputClassName,
    handlePageChangeClassList,
    pageNo,
    showClassDetailView,
    createClass,
    pageLimit,
    deleteClassCallback,
    deleteClassErrorMsg,    
    showError,
    handleCloseError
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  return (
    <>
      <Box className={classes.searchBarLayout}>
        <Box sx={{ mr: "20px" }}>
          <SearchInput
            placeholder={"Search by class name"}
            handleSearchInput={handleSearchClassName}
            searchInput={searchInputClassName}
          />
        </Box>
        <Box className={classes.btnBox}>
        <GenericButton
          type="COLORED"
          label="Create Class"
          handleClick={handleOpenModal}
          customStyle={btnStyle}
          />
          </Box>
      </Box>
      {classList?.length === 0 ? (
        <Typography className={classes.noRecords}>No records found</Typography>
      ) : (
        <Box>
          <Box sx={{ px: "25px", mt: "20px" }}>
            <ClassList dataList={classList} isTeacher handleClick={showClassDetailView} showMore={true} deleteClassCallback={deleteClassCallback} deleteClassErrorMsg={deleteClassErrorMsg} showError={showError} handleCloseError={handleCloseError}/>
          </Box>
          <Box sx={{ px: "35px", py: "50px" }}>
            <BasicPagination
              handlePageChange={handlePageChangeClassList}
              pageLimit={pageLimit}
              pageNo={pageNo}
            />
          </Box>
        </Box>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <CreateClassModal
          handleCloseModal={handleCloseModal}
          createClass={createClass}
        />
      </Modal>
    </>
  );
};

export default MyClasses;
