import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { eSymbol, engagemoIcon, errorIcon, groupTick } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getModalStyle } from "../../blocks/ContentManagement/src/Teacher/components/utils";
import GenericButton from "./GenericButton.web";
import storage from "../../framework/src/StorageProvider";

var host = require('../../framework/src/config')

const useStyles = makeStyles((theme) => ({
    popupContentOption: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px 60px",
        paddingBottom: "20px",
        [theme.breakpoints.down('xs')]: {
            padding: "50px 20px"
           },      
    },
    imageConatiner: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px'
    },
    engagemoIcon: {
        width: "100%"
    },
    eSymbol: {
        height: '70px',
        width: '80px'
    },
    errorContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: '10px 0'
    },
    errorIcon: {
        width: "15px",
        height: "15px",
        marginRight: "5px"
    },
    error: {
        color: "red",
        fontSize: "12px",
        display: "block",
        fontFamily: "Poppins",
    },
    container: {
        width: "100%",
    },
    paperOption: {
        position: "absolute",
        maxWidth:'550px',
        minWidth:'300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        display: "flex",
        justifyContent: "center",
        aignItems: "center",
        paddingBottom: "10px"  ,
        [theme.breakpoints.down('xs')]: {
            width: "80%"
           },      
        },
    inputLabel: {
        fontFamily: "Poppins",
        color: "#1C1C1E"
    },
    marginTop: {
        marginTop:"50px"
    },
    custominput: {
        borderRadius: "8px",
        border: "1px solid #C0C0C0",
        padding: "8px 15px",
        marginTop: "10px",
        display: "block",
        width: "100%",
        minHeight: "50px",
        fontSize: "16px",
    },
    crossIconOption: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
    },
    waitText: {
        color: "#747474",
        fontSize: "16px",
        fontFamily: "Poppins",
        marginBottom: "5px"
    },
    waitTextWidth: {
        width: "90%",
        textAlign: "center"
    },
    addedText: {
        fontSize: "22px",
        fontFamily: "Poppins",
        marginBottom: "10px",
        marginTop: "20px"
    },
    loaderColor: {
        color: "#2B3D7D",
    },
}));

const PopUpStage = {
    default: "Default",
    sucess: "Sucess",
    join: "Join",
    noJoin: "No Join"
}
const NameList = {
    class: "Class",
    lesson: "Lesson",
    quiz: "Quiz"
}

const JoinClassModal = (props: any) => {
    const { name, sessionCode, isGuest, handleCloseModal, onJoinResponse } = props;
    const [popUpShowScreen, setPopUpShowScreen] = useState('Default')
    const [error, setError] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorMessage, setErrorMessage] = useState(`Please enter a valid ${name} code`);
    const [modalStyle] = React.useState(getModalStyle);
    const [value, setValue] = React.useState('');
    const [nameValue, setNameValue] = React.useState('');
    const [currentSession,setCurrentSession] = React.useState('');
    const [joinClassApiResponse, setjoinClassApiResponse] = useState<any>(null);
    const classes = useStyles();
    const alreadyInside = "You are already in this class"
    // ComponentDidMount Hook
    useEffect(() => {
        if(sessionCode) {
            setValue(sessionCode);
            JoinApiCall(sessionCode);
        }
    }, [sessionCode]);

    const joinWithGuest = () => {
        const newUUid = Date.now()
        storage.set("GuestName",nameValue)
        storage.set("GuestId",newUUid)
        setPopUpShowScreen(PopUpStage.join)
                    setTimeout(() => {
                        window.location.href = `/guestJoined/${value}`
                    }, 3000);
    }
    useEffect(() => {
        if (joinClassApiResponse) {
            if (joinClassApiResponse.message && joinClassApiResponse.message.includes('already')) {
                setPopUpShowScreen(PopUpStage.default)
                setError(true)
                setErrorMessage(joinClassApiResponse.message)
            } 
            else if (joinClassApiResponse.message) {
                if (name == NameList.class) {
                    setPopUpShowScreen(PopUpStage.sucess)
                } else if (currentSession == NameList.lesson) {
                    //  panding due to api dependecy
                    if (!joinClassApiResponse.live_lesson) {
                        setPopUpShowScreen(PopUpStage.join)
                        setTimeout(() => {
                            setPopUpShowScreen(PopUpStage.noJoin)
                        }, 5000);
                    } else {
                        if(isGuest){
                            joinWithGuest()
                        }
                        else{
                            setPopUpShowScreen(PopUpStage.join)
                            setTimeout(() => {
                                window.location.href = `/joined/${value}`
                            }, 3000);
                        }
                    }
                } else if (currentSession == NameList.quiz) {
                    if (!joinClassApiResponse.live_quiz) {
                        setPopUpShowScreen(PopUpStage.noJoin)
                    } else {
                        if(isGuest){
                            joinWithGuest()
                        }
                        else{
                            setPopUpShowScreen(PopUpStage.join)
                            setTimeout(() => {
                                window.location.href = `/joined/${value}`
                            }, 3000);
                        }
                    }
                }
            } 
            if (joinClassApiResponse.error) {
                setPopUpShowScreen(PopUpStage.default)
                setError(true)
                setErrorMessage(joinClassApiResponse.error)
            }
        }
    }, [joinClassApiResponse])

    const handleChange = (event: any) => {
        if (name == NameList.class) {
            // let newValue = event.target.value.replace(/[^0-9 ]/g, '');
            let newValue = event.target.value;
            if (newValue.length <= 19) {
                newValue = newValue.replace(/\s/g, '').match(/.{1,4}/g)?.join(' ') || '';
                setValue(newValue);
            }
        } else {
            setValue(event.target.value);
        }
    };

    const handleChangeName = (event: any) => {
        const newNameValue = event.target.value;
        setNameValue(newNameValue);
        if (newNameValue.trim() !== "") {
            setErrorName(false);
        }
    }

    const JoinApiCall = (value: string = "") => {
        const authToken = localStorage.getItem('authToken');
        const headers = new Headers();
        headers.append('token', authToken!);

        if(isGuest && nameValue.trim()=="") {
            setErrorName(true)
            return
        }
        else{
            setErrorName(false)
        }

        if(value.trim()==""){
            setError(true)
            setErrorMessage("Please Enter Code")
            return
        }
        let endpoint = "";
        if (name == NameList.class) {
            setCurrentSession(NameList.class)
            endpoint = `/bx_block_livestreaming/class/join_live_class?class_code=${value.replace(/\s/g, "")}`;
        } else if (value.includes(NameList.lesson.toLowerCase())) {
            setCurrentSession(NameList.lesson)
            endpoint = `/bx_block_livestreaming/live_lesson/join_live_lesson?lesson_code=${value}`;
        } else if (value.includes(NameList.quiz.toLowerCase())) {
            setCurrentSession(NameList.quiz)
            endpoint = `/bx_block_livestreaming/live_quiz/join_live_quiz?quiz_code=${value}`;
        }

        fetch(`${host.baseURL}${endpoint}`, {
            method: 'get',
            headers: headers
        })
        .then(response => response.json())
        .then(resData => {            
            setjoinClassApiResponse(resData)
            onJoinResponse(resData);
        });
    }

    return (
        <Box style={modalStyle} className={classes.paperOption}>
            <Box className={classes.container}>
                <ClearIcon
                    onClick={() => {
                        handleCloseModal()
                        if (joinClassApiResponse && joinClassApiResponse.message !== alreadyInside && !joinClassApiResponse.error && name == NameList.class) {
                            window.location.href = '/dashboard';
                        }
                    }}
                    className={classes.crossIconOption}
                />
                <Box className={classes.popupContentOption}>
                    <Box className={classes.imageConatiner}>
                        <img className={classes.eSymbol} src={eSymbol} />
                        <img className={classes.engagemoIcon} src={engagemoIcon} />
                    </Box>
                    {popUpShowScreen == PopUpStage.default && <Box style={{ width: "100%" }}>
                        {isGuest &&
                                <>
                                    <Box className={classes.marginTop}>
                                        <label className={classes.inputLabel}>Your Name</label>
                                        <input
                                            value={nameValue}
                                            onChange={handleChangeName}
                                            className={classes.custominput}
                                        />
                                    </Box>
                                    <Box className={classes.errorContainer}>
                                    {errorName && <label className={classes.error}>Please enter your Name</label>}
                                    </Box>
                                </>
                            }
                        <Box className={!isGuest ? classes.marginTop:""}>
                            <label className={classes.inputLabel}>Session Code </label>
                            <input
                                value={value}
                                onChange={handleChange}
                                className={classes.custominput}
                            />
                        </Box>
                        <Box className={classes.errorContainer}>
                            {error && <><img src={errorIcon} className={classes.errorIcon} />
                                <label className={classes.error}>{errorMessage}</label></>}</Box>
                        <GenericButton
                            data-test-id="submit-btn"
                            type="COLORED"
                            label={isGuest ? 'Join Now' : `Join a ${name}`}
                            handleClick={() => JoinApiCall(value)}
                            customStyle={{ width: "100%", fontSize: "18px", }}
                        />
                    </Box>}
                    {popUpShowScreen == PopUpStage.sucess && <Box className={classes.popupContentOption}>
                        <img src={groupTick} />
                        <Typography className={classes.addedText}>Joined a class successfully!</Typography>
                    </Box>}
                    {popUpShowScreen == PopUpStage.join && <Box className={classes.popupContentOption}>
                        <CircularProgress className={classes.loaderColor} />
                        <Typography className={classes.addedText}>{`Joining a live session`}</Typography>
                        <Box className={classes.waitTextWidth}>
                            <Typography className={classes.waitText}>Please wait while we connect you to the live session.</Typography>
                        </Box>
                    </Box>}
                    {popUpShowScreen == PopUpStage.noJoin && <Box className={classes.popupContentOption}> <Box className={classes.waitTextWidth}>
                        <Typography className={classes.addedText}>There is no live {currentSession} for this {currentSession} currently</Typography>
                    </Box></Box>}
                </Box>
            </Box>
        </Box>
    );
};
export default JoinClassModal;

