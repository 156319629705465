import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Divider,Popover } from "@material-ui/core";
import Stage from "./Stage.web";
import { ACTIONS } from "../../../../../components/src/types";
import CustomDeleteModal from "../../../../../components/src/CustomDeleteModal.web";
const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";

const useStyles = makeStyles(() => ({
  stageLayout: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    boxShadow: "rgba(149, 157, 165, 0.2) 1px 0px 0px 0px",
    height: "100%",
  },
  heading: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "25px",
    marginLeft: "15px",
  },
  divider: {
    margin: "20px 0",
  },
  stageBoxLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow:"auto",
    height:"160vh",
  },
  dropdownmore: {
    display: "block",
    borderRadius: "6px",
    width: "max-content",
    minWidth:"135px",
    border:"1px solid #EBEBEB",
    boxShadow:"0 0 14px 0 rgba(49, 121, 149, 0.58)",
    padding:"7px 0",
    marginTop:"10px",
    position:"relative",
    backgroundColor:"#FFF",
    "&::before":{
      top: "-10px",
      right: "12px",
      width: "0",
      height: "0",
      content: "' '",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #fff",
    }

  },
  moreBtnMenuActive: {
    background: gradientBackground,
    fontSize: '14px',
    color: '#fff',
    fontWeight: 400,
    display: 'flex',
    border: 'none',
    width: '100%',
    padding: '7px 12px',
    cursor: 'pointer',
    fontFamily: "Poppins",
  },
  moreBtnMenus: {
    fontSize: '14px',
    color: '#1c1c1e',
    fontWeight: 400,
    display: 'flex',
    backgroundColor:"transparent",
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '7px 12px',
    cursor: 'pointer',
    fontFamily: "Poppins",
  },
  menuPopOver:{
    "& .MuiPopover-paper":{
      boxShadow:"none !important",
      backgroundColor:"transparent",
    },
  }
}));
const StageLayout = (props: any) => {
  const [activeButton, setActiveButton] = useState<number | null>(null); 
  const [deleteStage , setDeleteStage] = useState<{id:number,itemType:string,name:string,show:boolean}>({id:0,itemType:"stage",name:"",show:false})
  const [stageNumber, setStageNumber] = useState<string>("");
  const [anchorEl,setAnchorEl] = useState<Element | null>(null);
  const [showAddOptions,setShowAddOptions] = useState(false)
  const { stages, selectStage,handleActionChange,deleteStageCallBack,reorderStage,selectedView,handleDoublePointActive } = props;
  const [dragStage,setDragStage] = useState<{stage:any,index:number}>({stage:null,index:0});
  const [dropStage,setDropStage] = useState<{stage:any,index:number}>({stage:null,index:0});
  const classes = useStyles();
  const newStage={
    id:undefined,
    stageNumber:stages?.length+1,
    newStage:true,
    title:""
  }
  const openMenu=(e:any,stage:any,isSub:boolean = false)=>{
    let stageId = stage?.attributes?.lesson_stage_id ?? 0;
    if(stageId == 0){
      stageId = stage?.attributes?.assignment_stage_id ?? 0
      if (stageId == 0) {
        stageId = stage.id ?? 0;
      }
    }
    setDeleteStage({...deleteStage,id:stageId,name:stage?.title ?? ""})
    setStageNumber(stage?.stageNumber ?? "")
    setAnchorEl(e.target)
    setShowAddOptions((ACTIONS.ADD_TEXT == stage.stageType || ACTIONS.MULTIMEDIA == stage.stageType) && !isSub)
  }
 const handleClose =()=>{
  setAnchorEl(null)
 }
 const handleButtonHover = (buttonId: number | null) => {
  setActiveButton(buttonId);
} 

const openEndedQuestion = () => {
  handleActionChange(ACTIONS.OPEN_ENDED_QUESTION,stageNumber)
  handleClose()
}

const closeDeleteModal = () => {
  setDeleteStage({...deleteStage,show:false})
}

const openMCQ = () => {
  handleActionChange(ACTIONS.ADD_MCQ,stageNumber)
  handleClose()
}

const getMainStages = () => {
  return stages?.filter((stage:any)=>/^\d+$/.test(stage.stageNumber))
}

const handleDragStart = (event: React.DragEvent<HTMLDivElement>,stage:any,index: number) =>{
  setDragStage({stage:stage,index:index})
};

const handleDragOver = (event: React.DragEvent<HTMLDivElement>,stage:any,index: number) => {
  event.preventDefault();
  setDropStage({stage:stage,index:index})
};

const getSubStages = (parentString:string) => {
  return stages.filter((curStage:any)=>
    curStage.stageNumber.toString().match(/^\d+/)[0] == parentString
    && curStage.stageNumber != parentString
  )
}
const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  let isMainStageDrag = /^\d+$/.test(dragStage.stage.stageNumber);
  let isMainStageDrop = /^\d+$/.test(dropStage.stage.stageNumber);
  if(isMainStageDrag != isMainStageDrop) return
  let oldStages = getMainStages();
  if(!isMainStageDrag){
    const parentStageNumber = dragStage.stage.stageNumber.toString().match(/^\d+/)[0];
    oldStages = getSubStages(parentStageNumber);
  }   
  const newStages = [...oldStages]
  const dragDiv = newStages.find((eachStage:any) => eachStage.id ==dragStage.stage.id);
  const dragIndex = newStages.findIndex((eachStage:any) => eachStage.id ==dragStage.stage.id);
  const dropIndex = newStages.findIndex((eachStage:any) => eachStage.id ==dropStage.stage.id);
  newStages.splice(dragIndex, 1);
  newStages.splice(dropIndex, 0, dragDiv);
  for (let index = 0; index < newStages.length; index++) {
    const newStage = newStages[index];
    const oldStage = oldStages[index]
    if(newStage.stageNumber != oldStage.stageNumber){
      reorderStage(newStage,oldStage.stageNumber,isMainStageDrag)
    }
  }
};


  return (
    <>
      <Box className={classes.stageLayout}>
        <Typography className={classes.heading}>Stages</Typography>
        <Divider className={classes.divider} />
        <Box className={classes.stageBoxLayout}>
          {getMainStages()?.map((stage: any,index:number) => (
            <Box key={index}>
              <Stage handleDoublePointActive={handleDoublePointActive}  handleActiveSiled={props.handleActiveSiled}  stage={stage} index={index} selectStage={selectStage} handleMenu={openMenu} allStages={stages}
                draggable={true} handleDragStart={handleDragStart} handleDragOver={handleDragOver} handleDrop={handleDrop} selectedView={selectedView}
              />
            </Box>
          ))}
           <Box >
              <Stage stage={newStage} index={getMainStages()?.length} selectStage={selectStage} allStages={[]}/>
            </Box>
        <Popover
          anchorEl={anchorEl}
          open={anchorEl!=null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 8,  
            horizontal: 25,  
          }}
          transformOrigin={{          
            vertical: "top",     
            horizontal: "right", 
          }}
          className={classes.menuPopOver}
        >
            <div className={classes.dropdownmore}>
              {showAddOptions &&
              <>
              <button
                data-test-id="btnAddOpenEndedQ"
                onMouseEnter={() => handleButtonHover(1)}
                onMouseLeave={() => handleButtonHover(null)}
                className={activeButton === 1 ? classes.moreBtnMenuActive : classes.moreBtnMenus}
                onClick={openEndedQuestion}
              >
                Open Question
              </button>
              <button
                data-test-id="btnAddMCQ"
                onMouseEnter={() => handleButtonHover(2)}
                onMouseLeave={() => handleButtonHover(null)}
                className={activeButton === 2 ? classes.moreBtnMenuActive : classes.moreBtnMenus}
                onClick={openMCQ}
              >
                Multiple Choice
              </button>
              </>
              }
              {deleteStage.id>0 &&
              <button
                data-test-id="btnDeleteStage"
                onMouseEnter={() => handleButtonHover(3)}
                onMouseLeave={() => handleButtonHover(null)}
                className={activeButton === 3 ? classes.moreBtnMenuActive : classes.moreBtnMenus}
                onClick={()=>{
                  setDeleteStage({...deleteStage,show:true})
                  handleClose()
                }}
              >
                Delete Stage
              </button>
          }
            </div>
        </Popover>
        <CustomDeleteModal 
          open={deleteStage.show}
          handleClose={closeDeleteModal}
          contextMenuData={deleteStage}
          callback={deleteStageCallBack}
          deleteMsg={"Are you sure you want to delete this"}
        />
        </Box>
      </Box>
    </>
  );
};

export default StageLayout;
