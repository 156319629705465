import React from "react";
import { Box, Typography,Grid,FormControlLabel,Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "180px",
    whiteSpace: "pre-wrap"
  },
  optionWrapper: {
    border: "1px dashed rgba(149, 157, 165)",
    borderRadius: "10px",
    marginBottom: "20px",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "start",
  },
  optionbody: {
    display: "flex",
    gap: "10px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 30px)"
  },
  optionMark: {
    borderRadius: "3px",
    padding: "0px 10px",
    textTransform: "none",
    border: "1px solid #ccc",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    height: "56px",
    display: "flex",
    flex: "1 0 0",
    alignItems: "center",
    justifyContent: "center",
  },
  correctOption: {
    backgroundColor: "green",
    color: "white",
  },
  optioninputCorrect: {
    flex:"2 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 800,
    paddingLeft:"15px"
  },
  optioninputIncorrect: {
    flex:"2 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 400,
    paddingLeft:"15px"
  },
  optionHead: {
    fontFamily:"Poppins",
    padding: "15px"
  },
}));

const McqPreviewPage = (props: any) => {
  const {currentStage}=props
  const classes = useStyles();
  const hasCanvasData = currentStage.attributes.canvas_data;
  let canvasSize: string | undefined;
  let jsonCanvas: object | undefined;
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${currentStage.attributes.questions[0]?.question_background_image})`,
  };
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;

  }
  
  return (
    <div id="content" >
      
      <div className={classes.titleBox} >
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: currentStage.attributes.questions[0].question.question,
           }}/>
      </div>
      {  jsonCanvas && <Box style={{position:"absolute",width:"90%"}}>
  <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
  </Box>}
      <Box className={classes.stageTitle} style={backgroundStyle}>
      <Grid container spacing={2} style={{paddingTop:"20px"}}>
        {currentStage?.attributes?.questions[0]?.options.map(
          (option: any, index: number) => (
            <Box className={classes.optionWrapper}>
                <span className={classes.optionHead}>{`Option ${index + 1}`}</span>
                <div className={classes.optionbody} key={option.id}>
                  <FormControlLabel
                    data-test-id="radio-btn"
                    value={option.answer}
                    control={<Radio style={{ color: "#2B3D7D" }}/> }
                    label=""
                    disabled
                    checked={option.marked_as_right_answer}
                  />
                  <input
                    className={(option.marked_as_right_answer)?classes.optioninputCorrect:classes.optioninputIncorrect}
                    type="text"
                    value={option.answer}
                    disabled
                  />
                  <Box className={`${classes.optionMark} ${option.marked_as_right_answer ? classes.correctOption : ''}`}
                  >
                    Mark as right answer
                  </Box>
                </div>
              </Box>
          )
        )}
      </Grid>
      </Box>
      
    </div>
  );
};

export default McqPreviewPage;
