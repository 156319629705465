import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";;
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import TeacherMain from "../../../../analytics/src/Teacher/ClassStudentsListingMain.web";

const ReportList = (props: any) => {
  const { destination, locations,  redirect, showSummaryReportListView, classTitle } = props;
  useEffect(() => {
    window.localStorage.setItem("classTitle2", classTitle)
  }, [])
  return (
    <Box sx={{ px: "35px" }}>
      <Box sx={{ mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={destination}
          redirect={redirect}
        />
      </Box>
      <TeacherMain navigation={() => { }} id={"123"} handleClick={showSummaryReportListView} />
    </Box>
  );
};

export default ReportList;
