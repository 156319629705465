import React from "react";
import { Box, Typography, Grid,TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "180px",
    whiteSpace: "pre-wrap",
    height: "740px",
  },
  instructionBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "15px",
  },
  instructionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "5px",
  },
  instructionPlaceholder: {
    "&:empty:before": {
      content: "attr(placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 400,
    },
    color: "#9FA1A4",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
  },
  topicHeadingBox: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    paddingLeft: "15px",
    marginBottom: "20px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    height: "75px",
    alignItems: "center",
    display: "flex",
    borderRadius: "5px",
  },
  topic: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
    },
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "none",
    },
  },
  topicHeading: {
    "&:empty:before": {
      content: "attr(placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
  },
  topicBox: {
    height: "502px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    padding: "20px 15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    borderRadius: "5px",
  },
}));

const ChartPreview = (props: any) => {
  const { currentStage } = props
  const classes = useStyles();
  const mdValue =
    currentStage.attributes.description.length === 4 || currentStage.attributes.description.length === 2
      ? 6
      : 4;
  const hasCanvasData = currentStage.attributes.canvas_data;
  let jsonCamvas =  undefined
  let canvasSize =  undefined
  if(hasCanvasData)
  {
    const parsedData = typeof hasCanvasData == "object"?hasCanvasData:JSON.parse(hasCanvasData); 
    jsonCamvas = parsedData.canvas;
    canvasSize = parsedData.canvasSize 
  }
  return (
    <div id="content" >
      <Box className={classes.titleBox}>
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}/>
      </Box>
      {  jsonCamvas && <Box style={{position:"absolute",width:"90%"}}>
          <StaticCanvas canvasData={jsonCamvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
          </Box>}
      <div className={classes.stageTitle} >
        <Box className={classes.instructionBox} sx={{ px: "25px" }}>
          <Typography className={classes.instructionTitle}>
            Instruction:
          </Typography>
          <Box
            className={classes.instructionPlaceholder}
            dangerouslySetInnerHTML={{
              __html: currentStage.attributes.question?currentStage.attributes.question:"Enter instructions or question here",
             }}
          />
        </Box>
        <Box sx={{ px: "25px" }}>
          <Grid container spacing={2}>
            {currentStage.attributes.description.map((element: any, index: number) => (
              <Grid item md={mdValue}>
                <Box className={classes.topicHeadingBox}>
                <Box
            className={classes.topicHeading}
            dangerouslySetInnerHTML={{
              __html: element.topic?element.topic:"Enter your topic",
             }}
          />
                </Box>
                <Box
                  className={classes.topicBox}
                  style={{
                    height:
                      currentStage.attributes.description.length === 4 ||
                        currentStage.attributes.description.length === 6
                        ? "180px"
                        : "502px",
                  }}
                >
                  <TextField
                    className={classes.topic}
                    value={element?.answer ? element.answer : ""}
                    disabled={true}
                    placeholder="Enter your answer"
                    data-test-id="topic"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>

    </div>
  );
};

export default ChartPreview;
