import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {Roles} from "../../../components/src/types"
import SchoolAdminPeopleManagement from "./SchoolAdmin/SchoolAdminPeopleManagement.web";
import TeacherPeopleManagement from "./Teacher/TeacherPeopleManagement.web";
// Customizable Area End

import PeopleManagement2Controller, {
  Props,
  configJSON,
} from "./PeopleManagement2Controller";

export default class PeopleManagement2 extends PeopleManagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
      this.showAddStudentForm=this.showAddStudentForm.bind(this)
      this.handleTeacherStudentsPageChange=this.handleTeacherStudentsPageChange.bind(this)
      this.handleTeacherStudentsSearchInput=this.handleTeacherStudentsSearchInput.bind(this)
      this.deleteStudentProfileApi=this.deleteStudentProfileApi.bind(this)
      this.handleCloseDeleteSuccessModal=this.handleCloseDeleteSuccessModal.bind(this)
      this.handleOpenDeleteSuccessModal=this.handleOpenDeleteSuccessModal.bind(this)
      this.handleOpenDeleteProfileModal=this.handleOpenDeleteProfileModal.bind(this)
      this.handleCloseDeleteProfileModal=this.handleCloseDeleteProfileModal.bind(this)
      this.createStudentByTeacher=this.createStudentByTeacher.bind(this)
      this.updateStudentByTeacher=this.updateStudentByTeacher.bind(this)
      this.handleCloseAddEditStudentView=this.handleCloseAddEditStudentView.bind(this)
      this.handleCloseCreateStudentSuccess=this.handleCloseCreateStudentSuccess.bind(this);
      this.handleDateFilter=this.handleDateFilter.bind(this)
      this.handleStatusFilter=this.handleStatusFilter.bind(this)
      this.handleResetError=this.handleResetError.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.SUB_ADMIN) {
      return (
        <SchoolAdminPeopleManagement
          navigation={this.isPlatformWeb() ? null : this.props.navigation}
          id={"PeopleManagement2"}
        />
      );
    }
    if (role === Roles.TEACHER) {
      return (
        <TeacherPeopleManagement
        selectedView={this.state.render}
        showAddStudentForm={this.showAddStudentForm}
        loading={this.state.isLoading}
        students={this.state.students}
        pageNoStudent={this.state.pageNoStudent}
        pageLimitStudent={this.state.pageLimitStudent}
        handlePageChange={this.handleTeacherStudentsPageChange}
        handleSearchInput={this.handleTeacherStudentsSearchInput}
        searchInput={this.state.searchInput}
        deleteStudentProfileApi={this.deleteStudentProfileApi}
        isDeleteSuccess={this.state.isDeleteSuccess}
        handleCloseDeleteSuccessModal={this.handleCloseDeleteSuccessModal}
        handleOpenDeleteSuccessModal={this.handleOpenDeleteSuccessModal}
        handleOpenDeleteProfileModal={this.handleOpenDeleteProfileModal}
        handleCloseDeleteProfileModal={this.handleCloseDeleteProfileModal}
        openDeleteStudentModal={this.state.openDeleteStudentModal}
        createStudentByTeacher={this.createStudentByTeacher}
        classList={this.state.classList}
        studentDetails={this.state.studentDetails}
        updateStudentByTeacher={this.updateStudentByTeacher}
        handleCloseAddEditStudentView={this.handleCloseAddEditStudentView}
        handleCloseCreateStudentSuccess={this.handleCloseCreateStudentSuccess}
        createSuccessModal={this.state.createSuccessModal}
        handleDateFilter={this.handleDateFilter}
        handleStatusFilter={this.handleStatusFilter}
        passwordError={this.state.passwordError}
        handleResetError={this.handleResetError}
        emailError={this.state.emailError}
        limitExceeded= {this.state.limitExceeded}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>{this.renderViewBasedOnRole(this.state.role)}</>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
