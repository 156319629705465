import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { savedNotesIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  container: {
    position: "absolute",
    width: "280px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "9px 0px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
  },
  header: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#2C2C2E"
  },
  title: {
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#282829"
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const SaveNotesPopup = (props: any) => {
  const { handleCloseSavedSuccessPopUp,lessonName } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  return (
    <Box style={modalStyle} className={classes.container} >
      <Box className={classes.closeIcon} onClick={handleCloseSavedSuccessPopUp} >
        <ClearIcon
          data-test-id="close-modal"
        />
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "14px", gap: "5px" }}>
        <img src={savedNotesIcon} style={{ width: "45px", height: "45px" }} />
        <Typography className={classes.header}>{lessonName}</Typography>
        <Typography className={classes.title}>Saved Notes Successfully</Typography>
      </Box>
    </Box>
  );
};

export default SaveNotesPopup;
