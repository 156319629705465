import React, { useEffect, useState } from 'react';
import { Box, Modal, Backdrop, Fade, makeStyles, Typography, IconButton } from '@material-ui/core';
import { GetApp as DownloadIcon,ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: "transparent",
    boxShadow: 'none',
    outline: 'none',
  },
  modalHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "16px 28px",
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',    
    gap: "10px",
    color: "#FFFFFF",
  },
  imageName: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FFFFFF",
    letterSpacing: "normal",
  },
  topRight: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FFFFFF",
    padding: "0",
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    objectFit: 'contain',
  },
  backDropModal: {
    background: "rgba(0, 0, 0, 0.7)",
    mixBlendMode: "normal",
  },
  imageWrap: {
    maxWidth: "1000px",
    width: "100%",
    margin: "0 auto",
    maxHeight: "calc(100vh - 100px)",
  },
  pdfWrapper: {
    height:"95%",
    width:"70%",
    paddingLeft:"40px",
    paddingRight:"40px",
    marginLeft:"auto",
    marginRight:"auto"
  },
  arrowBack:{
    cursor:"pointer"
  }
}));
interface ImageModalProps {
  open: boolean;
  handleClose: () => void;
  fileUrl: string;
  filename: string;
}

const FileViewerModal = (props:ImageModalProps) => {
  const classes = useStyles();
  const {open,handleClose,fileUrl,filename}=props
  const [fileType,setFileType] = useState("image")
  const handleDownload = async () => {

    try {
      const response = await fetch(fileUrl, { mode: 'cors' });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.target = "_blank"
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(()=>{
    if(filename!="" && filename){
      const extension = filename.split('.');
      if(extension.length>0){
        setFileType(extension.pop()?.toLowerCase() == "pdf"?"pdf":"image")
      }
    }
  },[filename])
  return (
    <Modal
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        classes: {
          root: classes.backDropModal
        }
      }}      
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Box className={classes.modalHeading}>
            <Box className={classes.topLeft}>
              <ArrowBack onClick={handleClose} className={classes.arrowBack}/>
              <Typography variant="h6" className={classes.imageName}>
                {filename}
              </Typography>
            </Box>            
            <IconButton className={classes.topRight} onClick={handleDownload}>
              <DownloadIcon /> Download
            </IconButton>
          </Box>  
          <Box className={fileType=="image"?classes.imageWrap:""}>
            {fileType=="image"?
              <img src={fileUrl} alt={filename} className={classes.image} />:
              <div className={classes.pdfWrapper}>
                  <iframe src={fileUrl} width="100%" height="85%"/>
              </div>
            }
          </Box>                            
        </div>
      </Fade>
    </Modal>
  );
};

export default FileViewerModal;
