import React from "react";
// Customizable Area Start
import {
  Box,
  Grid
} from "@material-ui/core";
import CatalogueController, { Props } from "../CatalogueController";
import ClassList from "./components/Classes/ClassList.web";
import SelectedClass from "./components/SelectedClass/SelectedClass.web";
import SelectedPractice from "./components/SelectedPractice/SelectedPractice.web";
import TeacherListPage from "./components/TeacherList/TeacherListPage.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import LessonPreviewPage from "../Teachers/components/TeacherSelectedPractice/LessonPreviewPage.web";
// Customizable Area End

export default class SchoolAdminCatalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.getClasses = this.getClasses.bind(this);
    this.selectedClass = this.selectedClass.bind(this);
    this.selectedPractice = this.selectedPractice.bind(this);
    this.redirect = this.redirect.bind(this);
    this.redirectForTeacherRole = this.redirectForTeacherRole.bind(this)
    this.handleSearchInputTable = this.handleSearchInputTable.bind(this);
    this.deleteTableData = this.deleteTableData.bind(this);
    this.downloadLessonAdmin=this.downloadLessonAdmin.bind(this)
    this.handlePracticePageChange = this.handlePracticePageChange.bind(this);
    this.generateLink=this.generateLink.bind(this)
    this.selectedLessons=this.selectedLessons.bind(this);
    this.handleHighlightedTextData=this.handleHighlightedTextData.bind(this)
    this.nextStage=this.nextStage.bind(this);
    this.prevStage=this.prevStage.bind(this);
    this.handleLessonPageChange = this.handleLessonPageChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderSwitch(selectedOption: string) {
    switch (selectedOption) {
      case "Classes":
        return (
          <ClassList
            currentLocation={"Classes"}
            locations={this.locations}
            teacherClasses={this.state.classes}
            callback={this.selectedClass}
            redirect={this.redirect}
            loading={this.state.loading}
          />
        );
      case "Selected Class":
        return (
          <SelectedClass
            callback={this.selectedPractice}
            currentLocation={this.state.currentLocation}
            locations={this.locations}
            redirect={this.redirect}
            role={this.state.role}

          />
        );
      case "Selected Practice":
        return (
          <SelectedPractice
            currentLocation={this.state.currentLocation}
            locations={this.locations}
            practiceData={this.state.practiceData}
            tableSearchInput={this.state.tableSearchInput}
            handleSearchInputTable={this.handleSearchInputTable}
            redirect={this.redirect}
            loading={this.state.loading}
            handlePracticePageChange={this.handlePracticePageChange}
            pageLimitPractice={this.state.pageLimitPractice}
            deleteTableData={this.deleteTableData}
            pageNoPractice={this.state.pageNoPractice}
            role={this.state.role}
            generateLink={this.generateLink}
            shareLink={this.state.shareLink}
            handleOpenNoMobileModal={this.handleOpenNoMobileModal}
            handleCloseNoMobileModal={this.handleCloseNoMobileModal}
            openNoMobileModal={this.state.openNoMobileModal}
            downloadLesson={this.downloadLessonAdmin}
            selectedLessons={this.selectedLessons}
            handleLessonPageChange={this.handleLessonPageChange}
          />
        );
        case "Selected Lesson":
          return(
            <LessonPreviewPage
            currentLocation={this.state.currentLocation}
            locations={this.locations}
            redirect={this.redirectForTeacherRole}
            stageType={this.state.stageType}
            nextStage={this.nextStage}
            prevStage={this.prevStage}
            currentStage={this.state.currentStage}
            loading={this.state.loading}
            isAnnotate={this.state.isAnnotate}
            handleHighlightText={this.handleHighlightText}
            handleOpenAnnotationConfirmation={this.handleOpenAnnotationConfirmation}
            highlights={this.state.highlights}
            handleCloseAnnotationConfirmation={this.handleCloseAnnotationConfirmation}
            openAnnotationConfirmation={this.state.openAnnotationConfirmation}
            openMoveSuccessPopup={this.state.openMoveSuccessPopup}
            handleHighlightedTextData={this.handleHighlightedTextData}
            handleCloseSuccessPopup={this.handleCloseSuccessPopup}
            highlightedData={this.state.highlightedData}
            handleOpenSuccessPopup={this.handleOpenSuccessPopup}
            applyHighlightDirectly={this.applyHighlightDirectly}
            commentData={this.state.commentData}
            redoHighlight={this.redoHighlight}
            checkSignIn={this.handleAuthClick}
            getCommentData={this.getCommentData}
            removeCommentIcon={this.removeCommentIcon}
            uploading={this.state.uploading}
            stages={this.state.stages}
            handleSaveClick={this.handleSaveClick}
            openFileUploadSuccess={this.state.openFileUploadSuccess}
            handleCloseGdriveModal={this.handleCloseGdriveModal}
            />
          )
      default:
        return (
          <TeacherListPage
            searchInput={this.state.searchInput}
            teachersList={this.state.teachersList}
            pageLimit={this.state.pageLimit}
            handlePageChange={this.handlePageChange}
            handleSearchInput={this.handleSearchInput}
            getClasses={this.getClasses}
            loading={this.state.loading}
            pageNo={this.state.pageNo}
          />
        );
    }
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <Box>
        <Grid style={{ overflowX: "hidden", height: "100%" }}  container={this.state.isSmallScreen ? false : true} >
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
          {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item  style={{ overflowY: "scroll", height: "100%" }} {...(!this.state.isSmallScreen && { xs: 10 })}>
            <Grid item md={12}>
              <AppHeaderWeb title={"Library"} />
            </Grid>
            <Grid item md={12}>
              {this.renderSwitch(this.state.render)}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End