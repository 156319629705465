import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { ACTIONS } from "../../../../components/src/types";
const useStyles = makeStyles(() => ({
  title: {
    fontSize: "24px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 600,
  },

  mainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "30px 60px",
  },
  contentBox: {
    width: "620px",
    height: "210px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
  },
}));
const StageWithQuestionLive = (props: any) => {
  const { currentStage, showQuestion, stageType } = props;
  const renderStageTitle=()=>{
    switch(stageType){
      case ACTIONS.MCQ: return "Multiple Choice Question"
      case ACTIONS.KWL_CHART: return "KWL Chart Question"
      case ACTIONS.T_CHART: return "T Chart Question"
      case ACTIONS.SEED_DISCUSSION: return "Seed Discussion Question"
      case ACTIONS.VENN_DIAGRAM: return "Venn Diagram Question"
      case ACTIONS.WH_CHART: return "WH Chart Question"
      case ACTIONS.OPEN_ENDED_QUESTION: return "Open-Ended Question"
      case ACTIONS.ADD_WORD_CLOUD: return "Word Cloud Question"
      case ACTIONS.ADD_MATCHING_Q: return "Matching Question"
      case ACTIONS.POLLING_Q: return "Polling Question"
      default: return "Not Supported Stage"
    }
  }
  const renderBackgroundStyle=()=>{
    if(!showQuestion) return "";
    if([ACTIONS.ADD_WORD_CLOUD,ACTIONS.ADD_MATCHING_Q,ACTIONS.OPEN_ENDED_QUESTION].includes(stageType)) 
    return `url("${currentStage.attributes.background_image?.url}")`
    else if([ACTIONS.MCQ,ACTIONS.POLLING_Q].includes(stageType))
    return `url("${currentStage?.attributes.questions[0].question_background_image}")`
  }
  const classes = useStyles();
  return (
    <Box className={classes.mainLayout}
    style={{ backgroundImage: renderBackgroundStyle(),backgroundSize:"cover",backgroundPosition:"center",height:"100vh"}}
    >
      <Box className={classes.contentBox}>
        <Typography className={classes.title}>
          {renderStageTitle()}
        </Typography>
      </Box>
    </Box>
  );
};

export default StageWithQuestionLive;
