import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomContextMenu from "./CustomContextMenu.web";
import { Teacher, Student } from "./types";
import ArrayDisplayField from "./ArrayDisplayField.web";
type Data = Teacher | Student;
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ProfileInfoTable(props: any) {
  const {
    redirect,
    role,
    deleteProfile,
    profileData,
    isDeleteSuccess,
    deleteProfileError,
    deleteCleanup,
    pageNo
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContextMenu, setOpenContextMenu] = React.useState(false);
  const [openDeleteProfileModal, setOpenDeleteProfileModal] = React.useState(
    false
  );
  const [contextMenuUserData, setContextMenuUserData] = React.useState<any>({});
  const handleOpenContextMenu = (event: any, id: string, name: string) => {
    event.preventDefault();
    setContextMenuUserData({
      id,
      name,
      pageNo
    });
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };
  const handleCloseContextMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenContextMenu(false);
  };
  const handleCloseDeleteProfileModal = () => {
    deleteCleanup(role);
    setOpenDeleteProfileModal(false);
  };
  const handleOpenDeleteProfileModal = () => {
    setOpenDeleteProfileModal(true);
  };
  const teacherTableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="center">S No.</StyledTableCell>
        <StyledTableCell align="center">Full Name</StyledTableCell>
        <StyledTableCell align="center">Email ID</StyledTableCell>
        <StyledTableCell align="center">Institution</StyledTableCell>
        <StyledTableCell align="center">No of Classes</StyledTableCell>
        <StyledTableCell align="center">Date Created</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    );
  };
  const studentTableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="center">S No.</StyledTableCell>
        <StyledTableCell align="center">Full Name</StyledTableCell>
        <StyledTableCell align="center">Email ID</StyledTableCell>
        <StyledTableCell align="center">Phone Number</StyledTableCell>
        <StyledTableCell align="center">Class</StyledTableCell>
        <StyledTableCell align="center">Date Created</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    );
  };
  const tableBody = () => {
    return profileData.map((data: Data, index:number) => (
      <StyledTableRow key={data.id}>
        <StyledTableCell component="th" scope="row" align="center">
        {(pageNo - 1) * 10 + index + 1}
        </StyledTableCell>
        <StyledTableCell align="center">{data.fullName}</StyledTableCell>
        <StyledTableCell align="center">{data.email}</StyledTableCell>
        <StyledTableCell align="center">
          {"institution" in data ? data.institution : data.phone}
        </StyledTableCell>
        <StyledTableCell align="center">
          {"classCount" in data ? data.classCount : <ArrayDisplayField data={data.classes}/> }
        </StyledTableCell>
        <StyledTableCell align="center">{data.creationDate}</StyledTableCell>
        <StyledTableCell align="center">
          <MoreVertIcon
            onClick={(e) => handleOpenContextMenu(e, data.id, data.fullName)}
          />
        </StyledTableCell>
      </StyledTableRow>
    ));
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            {role === "teacher" ? teacherTableHead() : studentTableHead()}
          </TableHead>
          <TableBody>{tableBody()}</TableBody>
        </Table>
      </TableContainer>
      <CustomContextMenu
        textMenu
        anchorEl={anchorEl}
        openContextMenu={openContextMenu}
        handleCloseContextMenu={handleCloseContextMenu}
        handleOpenDeleteProfileModal={handleOpenDeleteProfileModal}
        handleCloseDeleteProfileModal={handleCloseDeleteProfileModal}
        openDeleteProfileModal={openDeleteProfileModal}
        redirect={redirect}
        deleteProfile={deleteProfile}
        contextMenuUserData={contextMenuUserData}
        role={role}
        isDeleteSuccess={isDeleteSuccess}
        deleteProfileError={deleteProfileError}
      />
    </>
  );
}
