import React from "react";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Link
} from "@material-ui/core";
import { viewDenied, groupLogo, backtohome } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const useStyles = makeStyles(() => ({
  wrapper: { 
    display: "flex", 
    flexDirection: "column", 
    marginTop: "110px" 
  },
  mainDiv: { 
    display: "flex", 
    flexDirection: "column", 
    marginTop: "30px", 
    justifyContent: "center", 
    alignItems: "center" 
  },
  viewDenied: { display: "flex", justifyContent: "center", borderRadius: "50%", backgroundColor: "rgb(255, 68, 80, 0.08)", height: "200px", width: "200px", alignItems: "center" },
  layout: { display: "flex", flexDirection: "column", marginTop: "60px", justifyContent: "center", alignItems: "center", gap: "20px" },
  message: { fontSize: "18px", fontFamily: "Poppins" },
  msgBox: { display: "flex", flexDirection: "column", alignItems: "center" },
  title: { color: "#FF4452", fontSize: "36px", fontWeight: "bold", fontFamily: "Poppins" }
}));
const AccessDenied = (props: any) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" className={classes.wrapper}>
        <Box className={classes.mainDiv}>
          <Box>
            <img src={groupLogo} />
          </Box>
          <Box className={classes.layout}>
            <Box className={classes.viewDenied}>
              <img src={viewDenied} />
            </Box>
            <Typography className={classes.title}>Access Denied</Typography>
            <Box className={classes.msgBox}>
              <Typography className={classes.message}>You do not have access to this page.</Typography>
              <Typography className={classes.message}>Please contact with the administrator.</Typography>
            </Box>
            <Link href={'/dashboard'}>
            <img src={backtohome} />
            </Link>
          </Box>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default AccessDenied;
