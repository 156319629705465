import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import SearchSection from "../../../../../../components/src/SearchSection.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import Loader from "../../../../../../components/src/Loader.web";
import { makeStyles } from "@material-ui/core/styles";
import ItemList from "../../../../../../components/src/ItemList.web";
const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  tabsStyle: {
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #2B3D7D",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
  tabStyle: {
    "&.MuiTab-root": {
      color: "#2B3D7D",
      fontWeight: 500,
      fontSize: "20px",
      width: "218px",
      textTransform: "capitalize",
      borderRadius: "8px",
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      fontSize: "20px",
      borderRadius: "8px",
      width: "218px",
    },
  },
  tabBarStyle: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "start",
    marginLeft: "35px"
  },
});

const round = {
  borderRadius: "50%",
};

const TeacherListPage = (props: any) => {
  const classes = useStyles();
  const {
    searchInput,
    handleSearchInput,
    teachersList,
    handlePageChange,
    pageLimit,
    getClasses,
    loading,
    pageNo,
    searchItem,
  } = props;
  return (
    <>
      <Grid item md={12}>
        <Box sx={{ px: "35px", mb: "20px" }}>
          <SearchSection
            searchItem={searchItem}
            searchInput={searchInput}
            handleSearchInput={handleSearchInput}
          />
        </Box>
      </Grid>
      {loading ? (
        <Loader loading />
      ) : (
        <>
          {!teachersList.length ? (
            <Typography className={classes.noRecords}>
              No records found
            </Typography>
          ) : (
            <>
              <Box sx={{ px: "20px" }}>
                <ItemList
                  dataList={teachersList}
                  handleClick={getClasses}
                  imageShape={round}
                />
              </Box>
              <Grid item md={12}>
                <Box sx={{ px: "35px", my: "30px" }}>
                  <BasicPagination
                    handlePageChange={handlePageChange}
                    pageLimit={pageLimit}
                    pageNo={pageNo}
                  />
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TeacherListPage;
