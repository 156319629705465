import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  withStyles,
  Select,
  MenuItem,
  Paper,
  TableContainer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import SearchInput from "../../../components/src/SearchInput.web";
import { assignmentIcon, dropDownIcon, lessonIcon, quizIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DownloadController, {
  Props,
  configJSON,
} from "./DownloadController";
import { searchIcon } from "../../../components/src/assets";

export default class Download extends DownloadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getIcon = (type: string) => {
    switch (type) {
        case 'lesson':
            return lessonIcon;
        case 'quiz':
            return quizIcon;
        default:
            return assignmentIcon;
    }
  };

  calculatePercentage = (downloads: {name: string; size: number; class: string; downloadedOn: string; type: string;}[], totalSpace: number) => {
    let usedSpace = 0;

    downloads.forEach(download => {
      if (typeof download.size === 'number' && download.size > 0) {
        usedSpace += download.size;
      }
    });

    const percentageUsed = (usedSpace / totalSpace) * 100;

    return percentageUsed;
  };

  calculateRemainingSize = (downloads: {name: string; size: number; class: string; downloadedOn: string; type: string;}[], totalSpace: number) => {
    const usedSize = downloads.reduce((acc, item) => acc + item.size, 0);
    const adjustedSize = totalSpace - usedSize;

    if (adjustedSize >= 1024) {
      const remainingGB = adjustedSize / 1024;
      return `${remainingGB.toFixed(2)} GB`;
    } else {
      return `${adjustedSize.toFixed(0)} MB`;
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
            <Grid item md={12}>
              <AppHeaderWeb title="My Downloads" />
            </Grid>
            <Grid item md={12} data-testid="renderSwitch">
              <MainBox>
                <Box style={webStyle.tabMainBox}>
                  {!this.state.activeState ?
                    <>
                      <Tab style={webStyle.tabAfter} label={configJSON.cloudTitle} />
                      <Tab data-test-id='setTabs' style={webStyle.tabBefore} label={configJSON.googleDriveTitle} onClick={()=>this.handleTabs(true)}/> </> : 
                    <>
                      <Tab data-test-id='setTabs' style={webStyle.tabBefore} label={configJSON.cloudTitle} onClick={()=> this.handleTabs(false) } />
                      <Tab style={webStyle.tabAfter} label={configJSON.googleDriveTitle} />
                    </>
                  }
                </Box>
                <Box style={webStyle.searchBox}>
                  <Box style={webStyle.searchbarBox}>
                    <Box style={webStyle.searchbarIconbox}> <img src={searchIcon} alt="Search Icon" /></Box>
                    <input
                      data-test-id='inputSearch'
                      placeholder={configJSON.searchPlaceholder}
                      style={webStyle.searchFilterinput}
                      value={this.state.searchByName}
                      onChange={(e) => {
                        this.handleSearchInCloud(e.target.value)
                      }}
                    />
                  </Box>
                  <Box style={webStyle.dropDownMainBox} >
                    <DropDown
                      value={this.state.classDropDownDefault}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      variant="outlined"
                      style={webStyle.firstSelect}
                      IconComponent={() => (
                        <img src={dropDownIcon} style={webStyle.dropDownIcon} />
                      )}
                    >
                      <MenuItem style={webStyle.menuItem} value={this.state.classDropDownDefault}>
                        <em>{this.state.classDropDownDefault}</em>
                      </MenuItem>
                    </DropDown>

                    <DropDown
                      value={this.state.allTypeDropDownDefault}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      variant="outlined"
                      style={webStyle.filterSelect}
                      IconComponent={() => (
                        <img src={dropDownIcon} style={webStyle.dropDownIcon} />
                      )}
                    >
                      <MenuItem style={webStyle.menuItem} value={this.state.allTypeDropDownDefault}>
                        <em>{this.state.allTypeDropDownDefault}</em>
                      </MenuItem>
                    </DropDown>
                  </Box>
                </Box>
                {
                  !this.state.activeState && <>
                    <TableContainer style={webStyle.datatableWrapper} component={Paper}>
                      <Table style={webStyle.table} >
                        <TableHead style={webStyle.tHead}>
                          <TableRow> 
                            <TableCell style={webStyle.tHeading}></TableCell>
                            {this.state.myDownloadsHeader.map((element, index) => (
                              <TableCell key={index} style={{
                                ...webStyle.tHeading,
                                ...(index === 0 ? webStyle.firstTableCellPadding : {})
                              }}>
                                {element}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.myDownloadsData.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={this.state.myDownloadsHeader.length + 2} style={{ textAlign: 'center' }}>
                                {configJSON.noRecordFoundText}
                              </TableCell>
                            </TableRow>
                          ) : (
                            this.state.myDownloadsData.map((element, index) => (
                              <TableRow key={index}>
                                <TableCell style={webStyle.tDetail}>
                                  <img src={this.getIcon(element.type)} alt="" />
                                </TableCell>
                                <TableCell style={{...webStyle.tDetail, ...webStyle.firstTableCellPadding}}>{} {element.name}</TableCell>
                                <TableCell style={webStyle.tDetail}>{element.size} {configJSON.mbTxt}</TableCell>
                                <TableCell style={webStyle.tDetail}>{element.class}</TableCell>
                                <TableCell style={webStyle.tDetail}>{element.type}</TableCell>
                                <TableCell style={webStyle.tDetail}>{element.downloadedOn}</TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box style={webStyle.bottomMainBox} >
                      <Typography style={webStyle.totalTypo} >{configJSON.totalTxt} {this.state.myDownloadsData.length} {configJSON.filesTxt} </Typography>
                      {
                        this.state.role === 'teacher' 
                        ? <Box style={webStyle.bottomRightBox}>

                            <Typography style={webStyle.freeMb}>{this.calculateRemainingSize(this.state.myDownloadsData, 2048)} {configJSON.mbfreeTxt}&nbsp;</Typography>
                                <Typography style={webStyle.totalMb}>{configJSON.ofTxt} {configJSON.twoDigitTxt} {configJSON.gbTxt}&nbsp;</Typography>

                            <Box width="200px" mr={'10px'} ml={'10px'}>

                                <GradientLinearProgress variant="determinate" value={Number(this.calculatePercentage(this.state.myDownloadsData, 2048))} />
                            </Box>
                            <Typography style={webStyle.totalMb}>{Math.floor(this.calculatePercentage(this.state.myDownloadsData, 2048))}{configJSON.percentLogo}</Typography>
                          </Box>
                        : <Box style={webStyle.bottomRightBox}>
                            <Typography style={webStyle.freeMb}>{this.calculateRemainingSize(this.state.myDownloadsData, 200)} {configJSON.mbfreeTxt}&nbsp;</Typography>
                            <Typography style={webStyle.totalMb}>{configJSON.ofTxt} {configJSON.doubleHundredTxt} {configJSON.mbTxt}&nbsp;</Typography>
                            <Box width="200px" mr={'10px'} ml={'10px'}>
                              <GradientLinearProgress variant="determinate" value={Number(this.calculatePercentage(this.state.myDownloadsData, 200))} />
                            </Box>
                            <Typography style={webStyle.totalMb}>{Math.floor(this.calculatePercentage(this.state.myDownloadsData, 200))}{configJSON.percentLogo}</Typography>
                          </Box>
                      }
                      
                    </Box>
                  </>
                }
                
              </MainBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  tabMainBox: {
    width: 'fit-content',
    border: '1px solid black',
    borderRadius: '4px',
  },
  tabBefore:{
    color: "#2B3D7D", 
    fontWeight: 500, 
    fontSize: "20px", 
    width: "218px",
    textTransform: "none" as "none",
    borderRadius: "4px",
    cursor: 'pointer',
    fontFamily: "Poppins",
  },
  tabAfter: {
    cursor: 'pointer',
    textTransform: "none" as "none",
    backgroundColor:"#2B3D7D",
    color: "#FFFFFF",
    fontWeight: 600,
    borderRadius: "4px",
    fontSize: "20px",
    width: "218px",
    opacity:1,
    fontFamily: "Poppins",
  },
  searchBox: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  firstSelect: {
    minWidth: '200px',
    marginRight: '20px',
    fontFamily: "Poppins",
    color: '#2B3D7D',
    fontSize: '16px',
    fontWeight: 500,
  },
  menuItem: {
    fontFamily: "Poppins",
    color: '#2B3D7D',
    fontSize: '16px',
    fontWeight: 500,
  },
  filterSelect: {
    minWidth: '200px',
    fontFamily: "Poppins",
    color: '#2B3D7D',
    fontSize: '16px',
    fontWeight: 500,
  },
  dropDownMainBox: {

  },
  datatableWrapper: {
    marginTop: '30px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  datatable: {
    padding: '0px',
    boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
    width: "auto",
    borderRadius: '8px',
    overflow: 'hidden',
  },
  table: {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  },
  tHead: {
    backgroundColor: '#fdf4dc',
  },
  tHeading: {
    fontFamily: "Poppins",
    color: '#202020',
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'left' as 'left',
  },
  tDetail: {
    fontFamily: "Poppins",
    color: '#2c2c2e',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    textTransform: "capitalize" as "capitalize"
  },
  firstTableCellPadding: {
    paddingLeft: '0px',
    width: '40%',
  },
  bottomMainBox: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalTypo: {
    fontFamily: "Poppins",
    color: '#202020',
    fontSize: '16px',
    fontWeight: 500,
  },
  freeMb: {
    fontFamily: "Poppins",
    color: '#202020',
    fontSize: '16px',
    fontWeight: 600,
  },
  totalMb: {
    fontSize: '16px',
    fontFamily: "Poppins",
    color: '#202020',
    fontWeight: 500,
  },
  bottomRightBox: {
    display: 'flex', 
    alignItems: 'center',
  },
  dropDownIcon: {
    marginRight: '10px',
  },
  searchbarBox: {
    position: "relative" as "relative",
    gap: "4px",
    display: "flex",
  },
  searchbarIconbox: {
    position: "absolute" as "absolute",
    left: "10px",
    top: "13px",
  },
  searchFilterinput: {
    height: "52px",
    width: "538px",
    fontFamily: "Poppins",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    fontWeight: 400,
    paddingLeft: "40px",
    fontSize: "16px",
    borderRadius: "8px",
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  
};

const MainBox = styled(Box)({
  margin: '30px 30px 30px 30px',
});

const GradientLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #2B3D7D, #4E6FE3)',
  },
})(LinearProgress);

const DropDown = styled(Select)({
  height: "52px",
  borderRadius: '8px',
});
// Customizable Area End
