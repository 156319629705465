import React, { useEffect, useState } from "react";
import { Box, Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { eSymbol, engagemoIcon, groupTick } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getModalStyle } from "../../blocks/ContentManagement/src/Teacher/components/utils";

const useStyles = makeStyles((theme) => ({
  popupContentOption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 60px",
    paddingBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      padding: "50px 20px"
    },
  },
  imageConatiner: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px'
  },
  engagemoIcon: {
    width: "100%"
  },
  eSymbol: {
    height: '70px',
    width: '80px'
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: '10px 0'
  },
  errorIcon: {
    width: "15px",
    height: "15px",
    marginRight: "5px"
  },
  error: {
    color: "red",
    fontSize: "12px",
    display: "block",
    fontFamily: "Poppins",
  },
  container: {
    width: "100%", display: 'flex', height: "100%", justifyContent: "center", alignItems: "center"
  },
  paperOption: {
    position: "absolute",
    maxWidth: '550px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    zIndex: 99999,
    justifyContent: "center",
    aignItems: "center",
    paddingBottom: "10px",
    [theme.breakpoints.down('xs')]: {
      width: "80%"
    },
  },
  inputLabel: {
    fontFamily: "Poppins",
    color: "#1C1C1E"
  },
  marginTop: {
    marginTop: "50px"
  },
  custominput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
  },
  crossIconOption: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  waitText: {
    color: "#747474",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginBottom: "5px"
  },
  waitTextWidth: {
    width: "90%",
    textAlign: "center"
  },
  addedText: {
    fontSize: "22px",
    fontFamily: "Poppins",
    marginBottom: "10px",
    marginTop: "20px"
  },
  loaderColor: {
    color: "#2B3D7D",
  },
}));

const PopUpStage = {
  default: "Default",
  sucess: "Sucess",
  join: "Join",
  noJoin: "No Join"
}
const NameList = {
  lesson: "lesson",
  quiz: "quiz"
}

const StudentLiveJoinModal = (props: any) => {
  const { sessionResp, handleCloseModal, isLiveModalActive } = props;
  const [popUpShowScreen, setPopUpShowScreen] = useState('Default')
  const [modalStyle] = React.useState(getModalStyle);
  const [value, setValue] = React.useState('');
  const classes = useStyles();

  // ComponentDidMount Hook
  useEffect(() => {
    if (sessionResp?.lessonCode) {
      setValue(sessionResp?.lessonCode);
    } else if (sessionResp?.quizCode) {
      setValue(sessionResp?.quizCode);
    }
  }, [sessionResp?.lessonCode, sessionResp?.quizCode]);

  useEffect(() => {
    if (sessionResp?.type == NameList.lesson) {
      //  panding due to api dependecy
      if (!sessionResp.lessonLive) {
        setPopUpShowScreen(PopUpStage.join)
        setTimeout(() => {
          setPopUpShowScreen(PopUpStage.noJoin)
        }, 5000);
      }
    }
    if (sessionResp?.type == NameList.quiz) {
      if (!sessionResp.quizLive) {
        setPopUpShowScreen(PopUpStage.join)
        setTimeout(() => {
          setPopUpShowScreen(PopUpStage.noJoin)
        }, 5000);
      }
    }
  }, [])

  return (
    <Modal open={isLiveModalActive} onClose={() => { handleCloseModal() }}>
      <Box className={classes.container}>
        <Box className={classes.paperOption}>
          <ClearIcon
            onClick={() => {
              handleCloseModal()
            }}
            className={classes.crossIconOption}
          />
          <Box padding={"50px 60px 40px 60px"}>
            <Box className={classes.imageConatiner}>
              <img className={classes.eSymbol} src={eSymbol} />
              <img className={classes.engagemoIcon} src={engagemoIcon} />
            </Box>
            {popUpShowScreen == PopUpStage.join && <Box className={classes.popupContentOption}>
              <CircularProgress className={classes.loaderColor} />
              <Typography className={classes.addedText}>{`Joining a live session`}</Typography>
              <Box className={classes.waitTextWidth}>
                <Typography className={classes.waitText}>Please wait while we connect you to the live session.</Typography>
              </Box>
            </Box>}
            {popUpShowScreen == PopUpStage.noJoin && <Box className={classes.popupContentOption}> <Box className={classes.waitTextWidth}>
              <Typography className={classes.addedText}>There is no live {sessionResp?.type} for this {sessionResp?.type} currently</Typography>
            </Box></Box>}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default StudentLiveJoinModal;

