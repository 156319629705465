import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog,
  DialogContent,
  Grid,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CheckIcon from '@material-ui/icons/Check';
import { leftSideIcon, RightSideIcon, editIcon, greenTick } from './assets'
import { closeIcon } from "../../MultipageForms2/src/assets";
import { constructYoutubeUrlFromVideoId, extractVideoIdFromThumbnailUrl } from "../../../components/src/Utils.web";
interface AssignmentMarkupTool {
  id: string;
  type: string;
  attributes: {
    assignments_id: number;
    stage_number: string;
    assignment_stage_id: number;
    title: string | null;
    question: string | null;
    videos_url: (string | null)[];
    description: (string | { topic: string; answer: string })[] | null;
    assignment_sub_stage_name: string[] | null;
    countdown_timer: number | null;
    assignment_stage_name: string;
    pairs: Pair[];
    background_image: string | null;
    questions: Question[] | null;
    videos: string | null;
    images: (string | { id: number; url: string }[]) | null;
  };
}

interface Pair {
  id: number;
  pair_question: string;
  pair_answer: string;
  pair_correct_answer: string;
  order_no: number;
  lesson_markup_tools_id: number | null;
  created_at: string;
  updated_at: string;
  quiz_markup_tools_id: number | null;
  assignment_markup_tools_id: number;
}
interface Question {
  
  options: Option[];
  question_background_image: string | null;
  question: {
    id: number;
    updated_at: string;
    assignment_markup_tools_id: number;
    created_at: string;
    question: string;
};
};

interface Option {
  id: number;
  answer: string;
  created_at: string;
  marked_as_right_answer: boolean;
  order_no: number | null;
  updated_at: string;
  assignment_mcq_questions_id: number;
}


interface AssignmentAttributes {
  assignment_stage_id: number;
  assignments_id: number;
  stage_number: string;
  title: string | null;
  question: string | null;
  videos_url: string[];
  description: string[] | null;
  countdown_timer: number | null;
  assignment_stage_name: string;
  assignment_sub_stage_name: string[];
  pairs: any[];
  questions: any[];
  background_image: string | null;
  images: { id: number; url: string }[] | null;
  videos: string[] | null;
}

interface Assignment {
  id: string;
  type: string;
  attributes: AssignmentAttributes;
}

interface ProcessedData {
  base: Assignment;
  subStage?: Assignment;
}


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";
// Customizable Area End

import AssessmentTestController, {
  Props,
  configJSON,
} from "./AssessmentTestController";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  textQuestion = (data: any) => {
    
    return (
      <>
        <Box sx={webStyle.mainWrapper}>
          <Grid container style={webStyle.mainWrapperContainer as React.CSSProperties}>
          
            <Grid style={webStyle.mainWrapperRightSide}>
              <Typography style={webStyle.textQuestionTitle} dangerouslySetInnerHTML={{
                __html: data.base.attributes.title,
              }}/>
              <br />
              <Typography style={webStyle.textQuestionTitle} dangerouslySetInnerHTML={{
                __html: data.base.attributes.description
              }}/>
            </Grid>
            {
              data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" && <Box style={webStyle.addMultimediaMcq}>
                <Grid style={webStyle.firstTextGrid}>
                  <Typography style={webStyle.firstHedingText}>
                    {configJSON.txtPsgQuestion}</Typography>
                </Grid>
                <Grid>
                  <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                    __html: data?.subStage.attributes?.questions?.[0]?.question?.question
                  }}/>
                </Grid>
                {data.subStage.attributes?.questions?.[0]?.options.map((value:any, index:any) => (
                  <Grid
                  style={this.getOptionStyle(value.answer) as React.CSSProperties}
                    key={index}
                    onClick={() => {
                      !this.state.slectedOption.isSubmit && this.selectAnswer(value.answer, data.subStage.attributes.questions[0].question.question, data.id)
                    }}
                    data-test-id="selectAnswer"
                  >
                    {value.answer}
                    {this.state.slectedOption.answer === value.answer && !this.state.slectedOption.isSubmit && (
                      <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.icoStyle as React.CSSProperties} />
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Grid container  justifyContent="space-between" alignItems="center">
                  {this.state.slectedOption.isSubmit ? (
                    <>
                      <Grid item>
                        <Grid container>
                          <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                            <CheckIcon style={webStyle.iconStyle as React.CSSProperties} />
                          </Grid>
                          <Typography style={webStyle.submitedText as React.CSSProperties}>Submitted</Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          style={webStyle.editBtnContainer}
                          onClick={() => this.editData()}
                          data-test-id="editData"
                          justifyContent="center"
                        >
                          <img src={editIcon} />
                          <Typography style={webStyle.submitedText as React.CSSProperties}>Edit</Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (<Grid item>
                        <Button
                          data-test-id="submitAnswer"
                          disabled={!this.state.slectedOption.answer}
                          style={this.state.slectedOption.answer ? webStyle.submitButtonActive as React.CSSProperties : webStyle.submitButtonBeforeClick as React.CSSProperties}
                          onClick={()=>this.submitAnswer(data.subStage.id, data.subStage.attributes.assignment_stage_name,data.subStage.attributes.assignment_stage_id,data.subStage.attributes.assignments_id)}
                        >Submit</Button>
                    </Grid>)}
                </Grid>
            </Box>
            }
            {
              data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && 
              this.openEndedQuestion(data.subStage, true)
            }
          </Grid>
        </Box>
      </>
    );
  };

  wordCloud = (data: any) => {
    return (
      <Box sx={webStyle.mainWrapper}>
        <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>

          <Grid item style={webStyle.mainContainerWidth as React.CSSProperties}>
            <Grid style={webStyle.mainWrapperChartQuestion as React.CSSProperties}>
              <Grid style={webStyle.firstTextGrid}>
                <Typography style={webStyle.firstHedingText as React.CSSProperties}>Word Cloud</Typography>
              </Grid>
              <Typography style={webStyle.textStyle2 as React.CSSProperties} dangerouslySetInnerHTML={{
                __html: data.attributes.title
              }}/>
              {data.attributes.description && data.attributes.description.map((value:any, index:any) => (
                <Grid item key={index}>
                  {Object.keys(value).map((keys, subIndex) => (
                    <input
                      key={subIndex}
                      style={webStyle.optionForInput}
                      placeholder={`Answer ${index+1}`}
                      value={this.state.inputValues[keys] || ''}
                      onChange={(event) => !this.state.isSubmitWordCloud && this.wordCloudHandel(event, keys)}
                      data-test-id="wordCloudHandel"
                    />
                  ))}
                </Grid>
              ))}

              <Grid container justifyContent="space-between" alignItems="center">
                {this.state.isSubmitWordCloud ? <>
                  <Grid item>
                    <Grid container>
                      <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.iconStyle} />
                      </Grid>
                      <Typography style={webStyle.submitedText as React.CSSProperties}>Submitted</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container style={webStyle.editBtnContainer as React.CSSProperties} justifyContent="center"  data-test-id="editDataMcqword" onClick={() => this.editData()}>
                      <img src={editIcon} />
                      <Typography style={webStyle.submitedText as React.CSSProperties}>Edit
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <Grid item>
                  <Button style={Object.values(this.state.inputValues).find(item => item !== "") ? webStyle.submitButtonActive as React.CSSProperties
                    : webStyle.submitButtonBeforeClick as React.CSSProperties}  disabled={!Object.values(this.state.inputValues).find(item => item !== "")} 
                    onClick={() => this.submitAnswer(data.id, data.attributes.assignment_stage_name, data.attributes.assignment_stage_id,
                     data.attributes.assignments_id)} data-test-id="submitAnswerword" >Submit</Button>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>

      </Box>
    )
  }


  chartQuestion = (data: any) => {
    let dataType = data.attributes.assignment_stage_name
    

    let type = this.setTypeForChart(dataType)
    
    return (
      <Box sx={webStyle.mainWrapper}>
        <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>

          <Grid item style={webStyle.mainContainerWidth as React.CSSProperties}>
            <Grid style={webStyle.mainWrapperChartQuestion as React.CSSProperties}>
              <Grid style={webStyle.firstTextGrid as React.CSSProperties}>
                <Typography style={webStyle.firstHedingText}>{type}</Typography>
              </Grid>
              <Grid>
                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                  __html: data.attributes.title
                }}/>
              </Grid>
              
              {data.attributes.description && data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (
                <Grid item key={index}>
                  {typeof value !== 'string' &&
                    <>
                      <Typography style={webStyle.chartQuestion} dangerouslySetInnerHTML={{
                        __html: value.topic
                      }}/>
                      <textarea
                        rows={4}
                        placeholder="Answers"
                        style={webStyle.chartQuestionTextfield as React.CSSProperties}
                        // value={this.state.inputValuesForChart.find(item => item.topic === value.topic)?.answer || ''}
                        value={this.state.inputValuesForChart.find(item => item.topic === `Answer ${index + 1}`)?.answer || ''}
                        onChange={(event) => !this.state.isSubmitForChart && this.chartQuestionHandel(event, `Answer ${index + 1}`)}
                        data-test-id="chartQuestionHandel"
                      />
                    </>
                  }
                </Grid>
              ))}
              <Grid container justifyContent="space-between" alignItems="center">
                {this.state.isSubmitForChart ? <>
                  <Grid item>
                    <Grid container>
                      <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.iconStyle} />
                      </Grid>
                      <Typography style={webStyle.submitedText as React.CSSProperties}>Submitted</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="center"  data-test-id="editDataChart" onClick={() => this.editData()} style={webStyle.editBtnContainer} >
                      <img src={editIcon} />
                      <Typography 
                      style={webStyle.submitedText}>Edit
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <Grid item>
                  <Button disabled={this.state.inputValuesForChart.length === 0} style={this.state.inputValuesForChart.length > 0 ? webStyle.submitButtonActive as React.CSSProperties
                    : webStyle.submitButtonBeforeClick as React.CSSProperties} data-test-id="submitAnswerChart" 
                    onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)}>Submit</Button>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>

      </Box>
    )
  }

  handleOptionClick = (value: any, question: string, data: any, isMultiple: boolean) => {
    if (data.attributes.questions && data.attributes.questions.length > 0 && !this.state.slectedOption.isSubmit) {
      if (isMultiple) {
        this.selectMultipleAnswers(value.answer, question, isMultiple);
      } else {
        this.selectAnswer(value.answer, question, data.id);
      }
    }
  };

  
  mcqs = (data: any) => {
    const isMultiple = data.attributes.multiple
    const isDisabled = !(isMultiple ? this.state.selectedAnswers.length > 0 : this.state.slectedOption.answer);

        
    return (
      <Box sx={webStyle.mainWrapper}>
        <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>

          <Grid item style={webStyle.mainContainerWidth}>
            <Grid style={webStyle.mainWrapperForWordCloud}>
              <Grid style={webStyle.firstTextGrid}><Typography style={webStyle.firstHedingText}>{data.attributes.assignment_stage_name === "MCQ" ? "Multiple Choice Question" : "Polling Question"}</Typography></Grid>
              <Grid>
                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                  __html: data.attributes.questions && data.attributes.questions[0].question.question
                }}/>  
              </Grid>
              
              {data.attributes.questions && data.attributes.questions[0].options.map((value:any, index:any) => (
                <Grid
                  key={index}
                  style={this.getOptionStyle(value.answer) as React.CSSProperties}
                  data-test-id="selectAnswerMcq"
                  onClick={() => this.handleOptionClick(value, data.attributes.questions[0].question.question, data, isMultiple)}
                >
                  {value.answer}
                  {!isMultiple && this.state.slectedOption.answer === value.answer && !this.state.slectedOption.isSubmit && (
                    <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                      <CheckIcon style={webStyle.icoStyle} />
                    </Grid>
                  )}

                  {isMultiple && this.state.selectedAnswers.includes(value.answer) && (
                        <Grid style={{...webStyle.containerForIcon as React.CSSProperties}}>
                          <CheckIcon style={webStyle.icoStyle} />
                        </Grid>
                      )}
                </Grid>
              ))}
              <Grid container alignItems="center" justifyContent="space-between">
                {this.state.slectedOption.isSubmit ? (
                  <>
                    <Grid item>
                      <Grid container>
                        <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid>
                        <Typography style={webStyle.submitedText as React.CSSProperties}>Submitted</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid  data-test-id="editDataMcq" onClick={() => this.editData()} container justifyContent="center" style={webStyle.editBtnContainer}>
                        <img src={editIcon} />
                        <Typography style={webStyle.submitedText}>Edit</Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Button
                    disabled={isDisabled}
                      style={
                        this.state.slectedOption.answer || this.state.selectedAnswers.length > 0
                          ? webStyle.submitButtonActive as React.CSSProperties
                          : webStyle.submitButtonBeforeClick as React.CSSProperties
                      }
                      onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)}
                      data-test-id="submitAnswerMcq"
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
  
        </Grid>
      </Box>
    );
  };
  

  dragDrop = (data: any) => {
    
    const dataForPair = data.attributes.pairs
    return (
      <PropertyDeveloperCss>
        <Box sx={webStyle.mainWrapper}>
          <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>
            <Grid style={webStyle.mainContainerWidthDrag}><Typography style={webStyle.firstHedingText} >

              You can drag the options in column 'B' with the correct matching. Match column 'A' with its corrected pairs of column 'B'</Typography>
              <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                __html: data.attributes.title
              }}/> 
              <Box>
                <Box className="headingLayout">
                  <Box className="heading">A</Box>
                  <Box className="heading">B</Box>
                </Box>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item md={6}>
                    {dataForPair.map((question:any, index:any) => (
                      <Box key={index} className="matchingBoxA">
                        {question.pair_question}
                      </Box>
                    ))}
                  </Grid>
                  <Grid item md={6}>
                    {this.state.rightArray.map((question, index) => (
                      <Box
                        key={index}
                        className={this.state.dragSomethingSubmit ? `matchingBoxBActive`:'matchingBoxB'}
                        draggable={!this.state.dragSomethingSubmit}
                        data-test-id="dragDrop"
                        onDragStart={() => this.handleDragStart(index)}
                        onDragEnter={() => this.handleDragEnter(index)}
                        onDragEnd={this.handleSort}
                      >
                        {question}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                { this.state.dragSomethingSubmit ? (
                  <>
                    <Grid item>
                      <Grid container>
                        <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}><CheckIcon style={webStyle.iconStyle} /></Grid>
                        <Typography style={webStyle.submitedText}>Submitted</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="center" style={webStyle.editBtnContainer} data-test-id="editDataMcqsdrage" onClick={() => this.editData()}>
                        <img src={editIcon} />
                        <Typography style={webStyle.submitedText}>Edit</Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Button
                      disabled={!this.state.dragSomething}
                      style={
                        this.state.dragSomething
                          ? webStyle.submitButtonActive as React.CSSProperties
                          : webStyle.submitButtonBeforeClick as React.CSSProperties
                      }
                      data-test-id="submitAnswerdrop"
                      onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
                </Grid>
              </Box></Grid>
          </Grid>
        </Box>
      </PropertyDeveloperCss>
    )
  };

  whChartQuestion = (data: any) => {
    return (
      <Box sx={webStyle.mainWrapper}>
        <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>

          <Grid item style={webStyle.mainContainerWidthSec}>
            <Grid style={webStyle.mainWrapperChartQuestion}>
              <Grid style={webStyle.firstTextGrid}>
                <Typography style={webStyle.firstHedingText}>WH-Chart Question</Typography>
              </Grid>
              <Grid>
                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                  __html: data.attributes.title
                }}/>
              </Grid>
               

              <Box
                display="flex"
                flexWrap="wrap"
                m={-2}
              >
              {data.attributes.description && data.attributes.description.map((item:any, index:any) => {
          if (typeof item !== 'string') {
            return (
              <Box
                key={index}
                style={{
                  flexBasis: 'calc(50% - 16px)',
                  padding: 16,
                  boxSizing: 'border-box',
                  margin: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                }}
              >
                <Typography style={webStyle.chartQuestion as React.CSSProperties}
                    dangerouslySetInnerHTML={{ __html: item.topic}} 
                />
                <textarea
                  rows={4}
                  placeholder="Answers"
                  style={webStyle.chartQuestionTextfield as React.CSSProperties}
                  // value={this.state.inputValuesForChart.find(input => input.topic === item.topic)?.answer || ''}
                  value={this.state.inputValuesForChart.find(input => input.topic === `Answer ${index + 1}`)?.answer || ''}
                  onChange={(event) => !this.state.isSubmitForChart && this.chartQuestionHandel(event, `Answer ${index + 1}`)}
                  data-test-id="chartQuestionHandelSec"
                >
                </textarea>
              </Box>
            );
          }
          return null;
        })}
              </Box>


              <Grid container justifyContent="space-between" alignItems="center">
                {this.state.isSubmitForChart ? <>
                  <Grid item>
                    <Grid container>
                      <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.iconStyle} />
                      </Grid>
                      <Typography style={webStyle.submitedText}>Submitted</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid style={webStyle.editBtnContainer} container justifyContent="center"  data-test-id="editDataWhChart" onClick={() => this.editData()} >
                      <img src={editIcon} />
                      <Typography style={webStyle.submitedText as React.CSSProperties} >Edit
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <Grid item>
                  <Button data-test-id="submitAnswerwhChart" disabled={this.state.inputValuesForChart.length === 0} style={this.state.inputValuesForChart.length > 0 ? webStyle.submitButtonActive as React.CSSProperties
                    : webStyle.submitButtonBeforeClick as React.CSSProperties}  onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)}>Submit</Button>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </Box>
    )
  };

  openEndedQuestion = (data: any, isSubStage: boolean) => {
    return (
      <Box style={isSubStage ? webStyle.openEndedBlankWrapper : webStyle.openEndedWrapper} >
        <Box style={isSubStage ? webStyle.openEndedMainBoxSubStage : webStyle.openEndedMainBoxStage}>
        <Grid style={webStyle.firstTextGrid}>
                <Typography style={webStyle.firstHedingText}>Open-Ended Question</Typography>
              </Grid>
              <Grid>
                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                  __html: data.attributes.title
                }}/>
              </Grid>
              
              <Grid item>
                <>
                  <textarea
                    rows={10}
                    placeholder="Answers"
                    style={{
                      ...webStyle.chartQuestionTextfield,
                      padding: '5px 0 0 5px',
                    }}
                    onChange={(event)=>{
                      !this.state.openEndedText.isSubmit && this.handelOpenEnded(event,data.attributes.title)
                    }}
                    data-test-id="handelOpenEnded"
                    value={this.state.openEndedText.answer}
                  >
                  </textarea>
                </>
              </Grid>


              <Grid container justifyContent="space-between" alignItems="center">
                {this.state.openEndedText.isSubmit ? <>
                  <Grid item>
                    <Grid container>
                      <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.iconStyle} />
                      </Grid>
                      <Typography style={webStyle.submitedText}>Submitted</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container  data-test-id="editDataOpenEnded" onClick={() => this.editData()} style={webStyle.editBtnContainer as React.CSSProperties} justifyContent="center">
                      <img src={editIcon} />
                                            <Typography style={webStyle.submitedText}>
                                              Edit
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <Grid item>
                  <Button  data-test-id="submitAnsweropenended"  style={this.state.openEndedText.answer ? webStyle.submitButtonActive as React.CSSProperties
                    : webStyle.submitButtonBeforeClick as React.CSSProperties}onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)} 
                    disabled={!this.state.openEndedText.answer} >Submit</Button>
                </Grid>
                }
              </Grid>
        </Box>
      </Box>
    )
  };

  renderMediaContent = (data:any) => {
    const {attributes} = data;
    const imageUrl = attributes?.images && attributes?.images?.length > 0 && attributes?.images[0]?.url
    const videoUrl = attributes?.videos && attributes?.videos?.length > 0 && attributes?.videos[0]?.url
    const videoID = attributes?.videos_url && attributes?.videos_url[0] != null && extractVideoIdFromThumbnailUrl(attributes?.videos_url[0])
    const youtubeUrl = videoID && constructYoutubeUrlFromVideoId(videoID);
    
    return (
      <Box style={webStyle.addTextLayout} >
        {imageUrl &&
                <img
                    data-test-id="img-preview"
                    src={imageUrl}
                    width="100%"
                    height="100%"
                />}
            {videoUrl &&
                <video
                    data-test-id="video"
                    width="100%"
                    height="100%"
                    controls
                >
                    <source src={videoUrl} type="video/mp4" />
                </video>
            }
            {videoID &&
                <iframe
                    data-test-id="youtube"
                    width="100%"
                    height="100%"
                    src={youtubeUrl.replace('watch?v=', 'embed/')}
                    title="YouTube Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            }
      </Box>
    )
  };

  addMultimedia = (data: any) => {

    const renderOptions = () => {
      return data.subStage.attributes?.questions?.[0]?.options.map((value:any, index:any) => (
        <Grid
          key={index}
          style={this.getOptionStyle(value.answer) as React.CSSProperties}
          data-test-id="selectAnswerMultimedia"
          onClick={() => {
            !this.state.slectedOption.isSubmit && this.selectAnswer(value.answer, data.subStage.attributes.questions[0].question.question, data.id)
          }}
        >
          {value.answer}
          { !this.state.slectedOption.isSubmit && this.state.slectedOption.answer === value.answer &&  (
            <Grid 
            style={webStyle.containerForIcon as React.CSSProperties}>
              <CheckIcon style={webStyle.icoStyle as React.CSSProperties} />
            </Grid>
          )}
        </Grid>
      ));
    };
  
    return (
      <>
        <Box sx={webStyle.mainWrapper}>
          <Grid container style={webStyle.mainWrapperContainer as React.CSSProperties}>
            <Grid item lg={8} xs={12} sm={12} md={6} style={webStyle.mainWrapperRightSide}>
              {this.renderMediaContent(data.base)}
            </Grid>
  
          {
            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" && 
            <Box style={webStyle.addMultimediaMcq}>
                <Grid style={webStyle.firstTextGrid}>
                  <Typography style={webStyle.firstHedingText}>{configJSON.txtPsgQuestion}</Typography>
                </Grid>
                <Grid><Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{__html: data?.subStage.attributes?.questions?.[0]?.question?.question}}/></Grid>
                {renderOptions()}
                <Grid container  justifyContent="space-between" alignItems="center">
                  {this.state.slectedOption.isSubmit ? (
                    <>
                      <Grid item>
                        <Grid container>
                          <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                            <CheckIcon style={webStyle.iconStyle as React.CSSProperties} />
                          </Grid>
                          <Typography style={webStyle.submitedText as React.CSSProperties}>Submitted</Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          style={webStyle.editBtnContainer}
                          onClick={() => this.editData()}
                          data-test-id="editDatamultimedia"
                          justifyContent="center"
                        >
                          <img src={editIcon} />
                          <Typography style={webStyle.submitedText as React.CSSProperties}>Edit</Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Button
                        data-test-id="submitAnswerMultimedia"
                        disabled={!this.state.slectedOption.answer}
                        style={
                          this.state.slectedOption.answer
                            ? webStyle.submitButtonActive as React.CSSProperties
                            : webStyle.submitButtonBeforeClick as React.CSSProperties
                        }
                        onClick={()=>this.submitAnswer(data.subStage.id, data.subStage.attributes.assignment_stage_name,data.subStage.attributes.assignment_stage_id,data.subStage.attributes.assignments_id)}
                      >
                        Submit
                      </Button>
                    </Grid>
                  )}
                </Grid>
            </Box>
          }
          {data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && this.openEndedQuestion(data.subStage, true)}
          </Grid>
        </Box>
      </>
    );
  };
  


  vennDiagram = (data: any) => {
    
    return (
      <Box sx={webStyle.mainWrapper}>
        <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>

          <Grid item style={webStyle.mainContainerWidth}>
            <Grid style={webStyle.mainWrapperChartQuestion}>
              <Grid style={webStyle.firstTextGrid}>
                <Typography style={webStyle.firstHedingText}>Venn Diagram Question</Typography>
              </Grid>
              <Grid>
                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{
                  __html: data.attributes.title
                }}/>
              </Grid>
              {data.attributes.description && data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (
                <Grid item key={index}>
                  {typeof value !== 'string' &&
                    <>
                      <Typography style={webStyle.chartQuestion}>{`Answer ${index + 1}`}</Typography>
                      <textarea
                        rows={4}
                        style={webStyle.chartQuestionTextfield as React.CSSProperties}
                        data-test-id="chartQuestionHandelvennDiagram"
                        value={this.state.inputValuesForChart.find(item => item.topic === `Answer ${index + 1}`)?.answer || ''}
                        placeholder="Answers"
                        onChange={(event) => !this.state.isSubmitForChart && this.chartQuestionHandel(event, `Answer ${index + 1}`)}
                      />


                    </>
                  }
                </Grid>
              ))}


              <Grid alignItems="center" container justifyContent="space-between">
                {this.state.isSubmitForChart ? <>
                  <Grid item>
                    <Grid container>
                      <Grid item style={webStyle.containerSubmitIcon as React.CSSProperties}>
                        <CheckIcon style={webStyle.iconStyle as React.CSSProperties} />
                      </Grid>
                      <Typography 
                      style={webStyle.submitedText}
                      >Submitted</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="center"  data-test-id="editDataVenn" 
                    onClick={() => this.editData()} style={webStyle.editBtnContainer} >
                      <img src={editIcon} />
                      <Typography style={webStyle.submitedText}>Edit
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <Grid item>
                  <Button disabled={this.state.inputValuesForChart.length === 0} data-test-id="submitAnswervenn_diagram" style={this.state.inputValuesForChart.length >0 ? webStyle.submitButtonActive as React.CSSProperties
                    : webStyle.submitButtonBeforeClick as React.CSSProperties}  onClick={()=>this.submitAnswer(data.id, data.attributes.assignment_stage_name,data.attributes.assignment_stage_id,data.attributes.assignments_id)}>Submit</Button>
                </Grid>
                
                }
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>

      </Box>
    )
  }


  stepper = () => {
    const { allData, currentStep } = this.state;

    if (!allData.length) return true;
    
    const processData = (data: any[]): ProcessedData[] => {
      const getNumericPart = (stageNumber: string): number => parseInt(stageNumber.match(/\d+/)?.[0] || '0', 10);
  
      data.sort((a, b) => {
          const numA = getNumericPart(a.attributes.stage_number);
          const numB = getNumericPart(b.attributes.stage_number);
          return numA - numB;
      });
  
      const combinedData: { [key: string]: Assignment[] } = {};
  
      data.forEach((item) => {
          const { stage_number } = item.attributes;
          const numericPart = getNumericPart(stage_number).toString();
  
          if (!combinedData[numericPart]) {
              combinedData[numericPart] = [];
          }
  
          combinedData[numericPart].push(item);
      });
  
      const result: ProcessedData[] = [];
  
      for (const dataKey in combinedData) {
        
          const baseStages = combinedData[dataKey].filter(stage => Boolean(Number(stage.attributes.stage_number)));
          const subStages = combinedData[dataKey].filter(stage => !Boolean(Number(stage.attributes.stage_number)));
  
          baseStages.forEach(base => {
              result.push({
                  base: base
              });
  
              subStages.forEach(subStage => {
                  result.push({
                      base: base,
                      subStage: subStage
                  });
              });
          });
  
          if (subStages.length > 0 && baseStages.length === 0) {
              subStages.forEach(subStage => {
                  result.push({
                      base: subStage
                  });
              });
          }
      }
  
      return result;
  };
    
    const finalData = processData(allData);

    const currentData = finalData[currentStep];
    const dataType = currentData.base.attributes.assignment_stage_name;

    switch (dataType) {
      case "add_text":
        return this.textQuestion(currentData);
      case "polling_question":
        return this.mcqs(currentData.base);
      case "add_word_cloud":
        return this.wordCloud(currentData.base);
      case "matching_question":
        this.setData(currentData.base.attributes.pairs)
        return this.dragDrop(currentData.base);
      case "kwl_chart":
        return this.chartQuestion(currentData.base);
      case "t_chart":
        return this.chartQuestion(currentData.base);
      case "seed_discussion":
        return this.chartQuestion(currentData.base);
      case "wh_chart":
        return this.whChartQuestion(currentData.base);
      case "open_ended_question":
        return this.openEndedQuestion(currentData.base, false);
      case "add_multimedia":
        return this.addMultimedia(currentData);
      case "venn_diagram":
        return this.vennDiagram(currentData.base);
      case "MCQ":
        return this.mcqs(currentData.base);
      default:
        return true;
    }
  }


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SuccessSnackbar
          message={'Request size exceeds the maximum allowed size of 200 MB.'} 
          open={this.state.isLimitModalOpen} 
          onClose={this.handleCloseSnackbar} 
          type={'error'}
        />
        <Grid>
          <Grid container justifyContent="space-between" alignItems="center" style={webStyle.hedingContainer}>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item ><ArrowBackIosIcon data-test-id="btnBack" onClick={()=> this.handleBack()} style={webStyle.firstHedingIcon} /></Grid>
                <Grid item style={webStyle.firstHedingText}>{this.state.assignmentName}</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Box style={webStyle.hedingBlock}>
                  <Grid container direction="column">
                    <Grid item style={webStyle.secHedingText}>Class</Grid>
                    <Grid item style={webStyle.firstHedingText}>{this.state.className}</Grid>
                  </Grid>
                </Box>
                <Box style={webStyle.hedingBlock}>
                  <Grid container direction="column">
                    <Grid item style={webStyle.secHedingText}>Due date</Grid>
                    <Grid item><Grid container alignItems="center"><QueryBuilderIcon style={webStyle.firstHedingIcons} /><Typography style={webStyle.firstHedingText}>{this.state.dueDate} {configJSON.daysLeft}</Typography></Grid></Grid>
                  </Grid>
                </Box>
                <Box style={webStyle.hedingBlock}>
                  <Grid container direction="column">
                    <Grid item style={webStyle.secHedingText}>Status</Grid>
                    <Grid item style={webStyle.firstHedingText}>{this.state.status}</Grid>
                  </Grid>
                </Box>
                <Grid item>
                  <Button data-test-id='btnMarkAsCompleted' onClick={() => {
                    if(this.state.submittedAnswerCount === this.state.validAnswersLength){
                      this.handleMarkAsCompleted()
                    }
                  }} 
                  disabled={(this.state.submittedAnswerCount !== this.state.validAnswersLength)}
                  style={(this.state.submittedAnswerCount === this.state.validAnswersLength) ? webStyle.markAsCompActive as React.CSSProperties : webStyle.markAsCompDisabled as React.CSSProperties}
                  
                  >{configJSON.markAsCompleted}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {this.stepper()}
        </Grid>
        {
          !this.state.allDataLoading && 
          <Grid container alignItems="center" justifyContent="center" style={webStyle.pginationGrid as React.CSSProperties}>
            <Grid item><img src={leftSideIcon} style={webStyle.cursorPointer} data-test-id="handelBack" onClick={this.handelBack} /></Grid>
            <Grid item style={webStyle.paginationText}>{`${this.state.allData[this.state.currentStep]?.attributes?.stage_number}/${this.state.totalStep}`}</Grid>
            <Grid item>
              <img src={RightSideIcon} style={webStyle.cursorPointer} data-test-id="handelNext" 
                onClick={ () => {
                  if(this.state.currentStep < this.state.allData.length - 1){
                    this.handelNext();
                  }}} 
              />
            </Grid>
          </Grid>
        }
        
        <BootstrapDialog
                onClose={this.handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={this.state.isModalOpen}
            >
                <IconBtn
                    aria-label="close"
                    data-test-id='btnCloseModal'
                    onClick={this.handleCloseModal}
                >
                   <img src={closeIcon} alt="close_icon" />
                </IconBtn>
                
                <DialogContent dividers>
                  <img style={webStyle.greenTick} src={greenTick} alt="" />
                  <Typography style={webStyle.modalText} >{configJSON.modalText}</Typography>
                </DialogContent>
            </BootstrapDialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#F1F5F9",
    marginTop: "1px",
    height: "91.2%",
    backrgroundColor: "#B2F1F5F9",
  },
  openEndedMainBoxSubStage: {
    width: '100%',
  },
  openEndedWrapper: {
    background: "#F1F5F9",
    paddingTop: "30px",
    paddingBottom: "30px",
    backrgroundColor: "#B2F1F5F9",
    minHeight: "92%",
  },
  openEndedBlankWrapper: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    width: '32%',
    height: "100%",
    padding: "24px 24px 24px 24px",
    boxSizing: 'border-box' as 'border-box',
    overflowY: 'scroll' as 'scroll', 
    borderRadius: "9px",
    msOverflowStyle: 'none' as 'none' ,
    scrollbarWidth: 'none' as 'none',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
  },
  openEndedMainBoxStage: {
    height: "100%",
    width: '34%',
    margin: 'auto',
    padding: "24px 24px 24px 24px",
    overflowY: 'scroll' as 'scroll',
    backgroundColor: "#fff",
    borderRadius: "9px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    msOverflowStyle: 'none' as 'none' ,
    boxSizing: 'border-box' as 'border-box',
    scrollbarWidth: 'none' as 'none',
    '&::-webkit-scrollbar': {
        display: 'none'
    },
  },
  addMultimediaMcq: {
    height: "100%",
    width: '32%',
    padding: "24px 24px",
    borderRadius: "9px",
    backgroundColor: "#fff",
    boxSizing: 'border-box' as 'border-box',
    overflowY: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", 
    msOverflowStyle: 'none' as 'none' ,
    '&::-webkit-scrollbar': {
        display: 'none'
    },
  },
  firstHedingText: { fontSize: "14px", fontWeight: 500, color: "#282829", fontFamily: "Poppins", textTransform: 'capitalize' as 'capitalize' },
  textQuestionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '26px',
    fontSize: '16px',
    display: 'block',
  },
  secHedingText: { fontSize: "11px", fontWeight: 400, color: "#282829", fontFamily: "Poppins",  },
  firstHedingIcon: { fontSize: "14px", marginRight: '16px', cursor: 'pointer' },
  firstHedingIcons: { fontSize: "14px", marginRight: "6px" },
  hedingBlock: { marginRight: "60px" },
  hedingContainer: { padding: "1% 2%", 
  'box-shadow': '0px 1px 3px 0px #00000014', 
},
  mainWrapperContainer: { 
    height: "100%", 
    padding: "44px 44px 44px 44px",
    position: "relative" as "relative",
    width: "100%",
    overflow:"auto" ,
  },
  mainWrapperRightSide: { 
    height: "100%",
    overflow: "auto",
    width: "65%",
    marginRight: '5px',
    paddingRight: "2%",
    fontFamily: "Poppins",
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none' ,
    '&::-webkit-scrollbar': {
        display: 'none'
    },
  },
  mainWrapperLeftSide: { height: "100%", borderRadius: "9px", padding: "0px 5%", backgroundColor: "#fff",  boxShadow:"0px 0px 10px rgba(0,0,0,0.1)"},
  mainWrapperForWordCloud: { height: "100%", borderRadius: "9px", padding: "4% 7% 7%", backgroundColor: "#fff",boxShadow:"0px 0px 10px rgba(0,0,0,0.1)" },
  mainWrapperChartQuestion: {
    height: "fit-content",
    padding: "4% 7% 7% 7%",
    boxSizing: 'border-box' as 'border-box',
    backgroundColor: "#fff",
    borderRadius: "9px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  },
  textStyle2: { fontSize: "21px", fontWeight: 500, color: "#282829", fontFamily: "Poppins",  margin: "20px 0px" },
  markAsCompDisabled: {
    width: "100%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , opacity: "0.5", borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "8px 10px", fontWeight: 700
  },
  markAsCompActive: {
    width: "100%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "8px 10px", fontWeight: 700, cursor: 'pointer'
  },
  submitButtonBeforeClick: {
    width: "145%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , opacity: "0.5", borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
    marginTop: "10px", marginBottom: '10px'
  },
  submitButtonBeforeClickDrag: {
    width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , opacity: "0.5", borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
    marginTop: "10px"
  },
  submitButtonActive: {
    width: "145%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
    marginTop: "10px", marginBottom: '10px'
  },
  submitButtonActiveDrag: {
    width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
    , borderRadius: "25px", color: "#fff",
    fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
    marginTop: "10px"
  },
  submitedText: { fontSize: "14px", fontFamily: "Poppins", color: "#3F526D", fontWeight: 700, marginLeft: "10px" },
  optionBeforSelect: {
    width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
    margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  optionSelect: {
    width: "92%", border: "2px solid #2B3D7D", borderRadius: "20px", position: "relative",
    margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  optionAfterSelect: {
    width: "92%", background: "#2B3D7D", borderRadius: "20px", position: "relative",
    margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
    cursor: "pointer", border: "2px solid #2B3D7D",
    fontFamily: "Poppins",
  },
  containerForIcon: { position: "absolute", top: "9px", right: "12px", backgroundColor: "#2B3D7D", borderRadius: "50%", padding: "3px" },
  containerSubmitIcon: { backgroundColor: "#2B3D7D", borderRadius: "50%", padding: "3px" },
  icoStyle: { color: "#fff", fontSize: "20px" },
  iconStyle: { color: "#fff", fontSize: "15px" },
  firstTextGrid: { paddingTop: "20px" },
  pginationGrid: {
    position: "fixed", 
    width: "120px", 
    padding: "4px", 
    borderRadius: "11px",
    left: "0",
    right: "0",
    bottom: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow:"0px 0px 10px rgba(0,0,0,0.1)",
    background: '#fff'
  },
  styleOfImage: { width: "100%", height: "100%" },
  paginationText: { margin: "0px 8px", fontSize: "14px", fontWeight: 600, color: "#3F526D", fontFamily: "Poppins" },
  optionForInput: {
    width: "100%", border: "1px solid #2B3D7D", borderRadius: "20px",
    margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  editBtnContainer: { cursor: "pointer", border: "2px solid #2B3D7D", borderRadius: "25px", width: "100px", padding: "9px 0px" },
  mainContainerWidth: { width: "40%", overflow:"auto", boxShadow:"0px 0px 10px rgba(0,0,0,0.1)" },
  mainContainerWidthSec: { width: "60%" },
  mainContainerWidthDrag: { width: "40%", backgroundColor: "#fff", padding: "3%", borderRadius: "20px",boxShadow:"0px 0px 10px rgba(0,0,0,0.1)"  },
  chartQuestion: { fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 500 },
  chartQuestionTextfield: {
    fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 600, width: "100%",
    borderRadius: "4px", border: "1px solid #5C2B3D7D", resize: "none" as 'none', margin: "2% 0px 5%"
  },
  cursorPointer: {cursor: 'pointer'},
  greenTick: {
    width: '60px',
    height: '60px',
    display: 'block',
    margin: 'auto',
    marginBottom: '20px',
  },
  modalText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center' as 'center',
    color: '#282829',
  },
  addTextLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
};

export const PropertyDeveloperCss = styled(Box)({
  '& .question': {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .instruction": {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "15px",
  },
  "& .matchingBoxA": {
    padding: "16px 12px",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "10px",
  },
  "& .matchingBoxB": {
    padding: "16px 12px",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "10px",
    cursor: "move",
  },
  "& .matchingBoxBActive": {
    padding: "16px 12px",
    border: "1px solid #2B3D7D",
    borderRadius: "10px",
    backgroundColor: "#2B3D7D",
    fontFamily: "Poppins",
    marginBottom: "10px",
    cursor: "move",
    fontSize: "14px", fontWeight: 500, color: "#fff",
  },
  "& .heading": {
    fontFamily: "Poppins",
    fontSize: "21px",
    color: "#282829",
    fontWeight: 700,
  },
  "& .headingLayout": {
    display: "flex",
    marginBottom: "15px",
    justifyContent: "space-around",
    textAlign: "center",
  },

  "& .mainLayout": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "30px 60px",
  },
  "& .contentBox": {
    width: "380px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "30px 60px",
  },
  "& .submitBtn": {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  "& .disabledBtn": {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    opacity: "0.5",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  "& .submitBtnLayout": {
    marginTop: "25px",
    cursor: "pointer"
  },
})

export const BootstrapDialog = styled(Dialog) ({
  "& .MuiDialog-paper": {
    width: '339px',
    borderRadius: "12px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      paddingTop: '48px',
      paddingBottom: '48px',
  },
});

export const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});
// Customizable Area End
