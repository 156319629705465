export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const textImage = require("../assets/text.svg");
export const mcqImage = require("../assets/mcq.svg");
export const wordcloudImage = require("../assets/word_cloud.svg");
export const multimediaImage = require("../assets/multimedia.svg");
export const uploadImage = require("../assets/upload.svg");
export const importImage = require("../assets/import.svg");
export const whiteboardImage = require("../assets/whiteboard.svg");
export const matchingImage = require("../assets/matching.svg");
export const openEndedImage = require("../assets/open_ended.svg");
export const graphicImage = require("../assets/graphic.svg");
export const saveImage = require("../assets/save.svg");
export const previewImage = require("../assets/preview.svg");
export const textImageActive = require("../assets/text_active.svg");
export const mcqImageActive  = require("../assets/mcq_active.svg");
export const wordcloudImageActive  = require("../assets/word_cloud_active.svg");
export const multimediaImageActive  = require("../assets/multimedia_active.svg");
export const uploadImageActive  = require("../assets/upload_active.svg");
export const importImageActive  = require("../assets/import_active.svg");
export const whiteboardImageActive  = require("../assets/whiteboard_active.svg");
export const matchingImageActive  = require("../assets/matching_active.svg");
export const openEndedImageActive  = require("../assets/open_ended_active.svg");
export const graphicImageActive  = require("../assets/graphic_active.svg");
export const backImage = require("../assets/back.svg");
export const closeIcon= require("../assets/image_Close.svg");
export const multimediaIcon= require("../assets/image_upload_icon.svg");
export const orLineBreak= require("../assets/orLineBreak.svg");
export const kwlChart = require("../assets/kwl_chart.svg");
export const tChart = require("../assets/t_chart.svg");
export const whChart = require("../assets/wh_chart.svg");
export const seedDiscussion = require("../assets/seed_discussion.svg");
export const vennDiagram = require("../assets/venn_diagram.svg");
export const info = require("../assets/info.svg");
export const kwl_chart = require("../assets/kwl_success.svg");
export const t_chart = require("../assets/t_success.svg");
export const wh_chart = require("../assets/wh_success.svg");
export const seed_discussion = require("../assets/seed_discussion_success.svg");
export const venn_diagram = require("../assets/venn_diagram_success.svg");
export const venn = require("../assets/venn.svg");
export const mcqSuccess = require("../assets/mcq-success-image.svg");
export const settingsIcon = require("../assets/settings.svg");
export const AddCircleIcon = require("../assets/Add_Circle_Outline.jpg");
export const ImageSearchIcon = require("../assets/image_Vuesax.svg");
export const addMatchingQModal = require("../assets/addMatchingQModal.png");
export const addMatchingQShuffleicon  = require("../assets/shuffle.png");
export const addMatchingQAddicon = require("../assets/adoption.png")
export const Expand = require("../assets/expand.svg");
export const AlignLeft = require("../assets/AlignLeft.svg");
export const View_arrow = require("../assets/view_arrow.svg");
export const View_attachment = require("../assets/view_attachment.svg");
export const View_delete = require("../assets/view_del.svg");
export const View_duplicate = require("../assets/view_duplicate.svg");
export const View_highlight = require("../assets/view_highlight.svg");
export const View_line = require("../assets/view_line.svg");
export const View_Oval = require("../assets/view_Oval.svg");
export const View_pen = require("../assets/view_pen.svg");
export const View_Polygon = require("../assets/view_Polygon.svg");
export const View_redo = require("../assets/view_redo.svg");
export const View_undo = require("../assets/view_undo.svg");
export const View_rounded_Rectangle = require("../assets/view_rounded_Rectangle.svg");
export const View_shape = require("../assets/view_shape.svg");
export const View_Star = require("../assets/view_Star.svg");
export const View_Triangle = require("../assets/view_Triangle.svg");
export const Textlabel = require("../assets/textlabel_Open.svg");
export const ViewTextTool = require("../assets/view_texttool.svg");
export const AlignRight = require("../assets/AlignRight.svg");
export const AlignCenter = require("../assets/AlignCenter.svg");
export const TickRight = require("../assets/tickRight.svg");
export const Cross = require("../assets/cross.png");
export const settingBtn  =  require("../assets/settingBtn.png")
export const addBackground  =  require("../assets/addBackground.png")
export const points  =  require("../assets/pointsIcon.png")
export const timer = require("../assets/timer.png");
export const whiteAddBackground = require("../assets/whiteAddBackground.png");
export const whitePoints = require("../assets/whitePoints.png");
export const whiteDoublePoint = require("../assets/whiteDoublePoint.png");
export const whiteMusic = require("../assets/whiteMusic.png");
export const blueTimer = require("../assets/blueTimer.png");
export const blueDoublePoint = require("../assets/blueDoublePoint.png");
export const blueMusicBtn = require("../assets/blueMusicBtn.png");
export const whiteSetTimer = require("../assets/whiteSetTimer.png");
export const audioImg = require("../assets/audio.png");
export const whitePen = require("../assets/white_pen.svg")
export const assignmentBtn = require("../assets/assignmentBtn.png");
export const lessonBtn = require("../assets/lessonBtn.png");
export const quizBtn = require("../assets/quizBtn.png");
export const studentIcon = require("../assets/studentIcon.svg");
export const folderDefaultIcon = require("../assets/image_default_folder.svg");
export const groupLessonIcon = require("../assets/group_lesson_icon.svg");
export const swapIcon = require("../assets/swap_icon.svg");
export const swapIconActive = require("../assets/swap_iconactive.svg");
export const importLesson = require("../assets/importLesson.svg");
export const importAssignment = require("../assets/importAssignment.svg");
export const noClassImg = require("../assets/noClassImg.svg");
export const driveBtn = require("../assets/driveBtn.png");
export const imageUpload = require("../assets/imageUpload.png");
export const redCross = require("../assets/redCross.png");
export const backPage = require("../assets/arrow_down.svg");
export const zoomOut = require("../assets/view_zoomout.svg");
export const downloadLesson = require("../assets/image_download.svg");
export const save = require("../assets/image_save.svg");
export const drag = require("../assets/image_drag.svg");
export const redo = require("../assets/image_redo.svg");
export const undo = require("../assets/image_undo.svg");
export const nextPage = require("../assets/arrow_next.svg");
export const zoomIn = require("../assets/view_zoomin.svg");
export const activeDragIcon = require("../assets/group_hand_icon2_selected.svg");





