Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.googleGetAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "gsuiteintegration";
exports.labelBodyText = "gsuiteintegration Body";
exports.WebAPIKey=  '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'
exports.WebClienctID = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.webClientId = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.driveScope = 'https://www.googleapis.com/auth/drive'
exports.signInErrorMessage = "Sign in error"
exports.docsLinkErrorMessage = "Couldn't load file"
exports.documentPickerErrorMessage = "Picker cancelled"
exports.filesLoadError = "Faild to load files"
exports.uploadError  = "Error during file upload"
exports.uploadCompletedMsg  = "Upload completed"
exports.googleFileFields = 'files/id,files/name,files/webViewLink'
exports.mimeTypeGoogleDocs = "application/vnd.google-apps.document"
exports.mimeTypeGoogleSlides = "application/vnd.google-apps.presentation"
exports.upploadFileGoogleApi = "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart"
exports.googlePostAPiMethod = "POST";
exports.loginError = "Sign in faild please try again"

exports.textGoogleSiginButton = "Sign In with Google"
exports.textGoogleDocsButton = "View Google Docs"
exports.textGoogleDriveButton = "View Drive files"
exports.textGoogleSlidesButton = "View Google slides"

exports.textUploadGoogleDocs = "Upload google docs"
exports.textUploadGoogleDrive = "Upload drive"
exports.textUploadGoogleSlides = "Upload google slides"
exports.labelUpoading = "uploading..."



exports.iosClientId= "579516707200-f9s4l9jtggc0k6r2uea0fvn3gnoan8q7.apps.googleusercontent.com"


exports.queryParamsListGoogleDocs =  "mimeType='application/vnd.google-apps.document'"
exports.queryParamsListGoogleSlides =  "mimeType='application/vnd.google-apps.presentation'"

exports.base64 = 'base64'

exports.labelTextLink = "View"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End