import React from "react";
import Sidebar from "../../../../../components/src/Sidebar.web";
import TeacherCalendarController, { Props } from "./TeacherCalendarController";
import { Grid } from "@material-ui/core";
import TeacherCalendar from "./TeacherCalendar.web";
export default class Calendar extends TeacherCalendarController {
  constructor(props: Props) {
    super(props);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
  }
  render() {
    return (
      <>
        <Grid container style={{overflowX:"hidden", height:"100%"}}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{height: "100%", overflowY: "scroll"}}>
            <TeacherCalendar
              loading={this.state.loading}
              handleSetDate={this.handleSetDate}
              calendarData={this.state.calendarData}
              handleDeleteEvent={this.handleDeleteEvent}
              getCalendarEvents={this.getCalendarEvents}
              selectedDate={this.state.selectedDate}
              handleColorEvents={this.handleColorEvents}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
