import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { debounce } from "lodash";
export const configJSON = require("../../config");
import { defaultImage } from "../../assets";
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  teachersList: any[];
  pageNo: number;
  searchInput: string;
  pageLimit: number;
  loading: boolean;
  isSmallScreen: boolean;
}

interface SS {
  id: any;
}

export default class TeacherListController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiTeacherDataCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      teachersList: [],
      pageNo: 1,
      searchInput: "",
      pageLimit: 1,
      loading: true,
      isSmallScreen: window.innerWidth <= 600,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleTeacherDataResponse(
        apiRequestCallId,
        responseJson,
        errorReponse
      );

      this.setState({
        loading: false,
      });
    }
  }
  handleTeacherDataResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: any
  ) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiTeacherDataCallId
    ) {
      if (responseJson && responseJson.data) {
        this.setState({
          teachersList: responseJson.data.map((teacher: any) => {
            return {
              id: teacher.id,
              name: teacher.attributes.full_name,
              image: teacher.attributes.image
                ? teacher.attributes.image
                : defaultImage,
            };
          }),
          pageLimit: responseJson.meta.total_page,
        });
      }
      if (responseJson && responseJson.errors) {
        this.setState({
          teachersList: [],
          pageLimit: 1,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  async componentDidMount() {
    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
      return;
    }

    this.getTeachersData();
  }

  getTeacherCalendar(event: any, teacher: any) {
    window.location.replace(`teachers/${teacher?.id}`)
  }
  getTeachersData(page = 1): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTeacherDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetUrl}?page=${page}&per_page=9&full_name=${this.state.searchInput}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handlePageChange(event: any, page: number) {
    this.setState({
      pageNo: page,
    });
    this.getTeachersData(page);
  }
  debouncedSearch = debounce(() => {
    // Perform the search operation here (e.g., make an API request)
    this.getTeachersData();
  }, 1000); // 1000ms delay (adjust as needed)

  handleSearchInput(event: any) {
    this.setState(
      {
        searchInput: event.target.value,
        pageNo: 1,
      },
      () => {
        this.debouncedSearch();
      }
    );
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
