import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../ContentManagement/src/Teacher/components/utils";
import GenericButton from "../../../../components/src/GenericButton.web";
import { csvSucessIcon } from "../assets";

const useStyles = makeStyles((theme) => ({
    popupContent: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        padding: "50px 60px",
        justifyContent: "center",
        paddingBottom: "20px",
        [theme.breakpoints.down('xs')]: {
            padding: "20px 30px",
        },
    },
    Container: {
        width: "100%",
    },
    optionPaper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        width: "450px",
        borderRadius: "10px",
        alignItems: "center",
        display: "flex",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        justifyContent: "center",
        paddingBottom: "10px",
        [theme.breakpoints.down('xs')]: {
            transform: "translate(-53%, -50%) !important",
            width: "80%",
            padding: "10px",
            top: "unset",
            left: "unset",
            right: "unset",
            bottom: "unset",
            margin: "10px",
        },
    },
    label: {
        fontFamily: "Poppins",
        fontSize: "24px",
        color: "#2C3F58",
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: "18px",
        },
    },
    iconOption: {
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
    },
    buttonWrapper: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    cancelButton: {
        border: "1px solid #2C4881",
        borderRadius: "5px",
        backgroundColor: "#fff",
        color: "#2C4881",
        width: "50%",
        padding: "10px",
        marginRight: "15px",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        cursor: "pointer",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginBottom: "10px",
            marginRight: "0",
        },
    },
}));


const DownloadModal = (props: any) => {
    const { handleCloseModal, csvSucessShow,getExportReportApicall  } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    return (
        <Box style={modalStyle} className={classes.optionPaper}>
            <Box className={classes.Container}>
                <ClearIcon
                    className={classes.iconOption}
                    onClick={handleCloseModal}
                />
                <Box className={classes.popupContent}>
                    {!csvSucessShow && <Box style={{ width: "100%" }}>
                        <Box style={{
                            textAlign: "center",
                            marginBottom: "15px",
                        }}>
                            <label className={classes.label}>Are you sure you want to download this detailed report?</label>
                        </Box>
                        <Box className={classes.buttonWrapper}>
                            <button data-test-id="btnCancel"  className={classes.cancelButton} type="button" onClick={() => {
                                handleCloseModal()
                            }}>
                                {"Cancel"}
                            </button>
                            <GenericButton
                                data-test-id="submit-btn"
                                customStyle={{ width: "50%", fontSize: "18px", }}
                                type="COLORED"
                                handleClick={getExportReportApicall}
                                label={`Download`}
                            />
                        </Box>
                    </Box>}
                    {csvSucessShow &&
                        <Box style={{ width: "100%",  flexDirection: "column",display: "flex", }}>
                            <Box style={{ justifyContent: "center",display: "flex",  }}>
                                <img src={csvSucessIcon} />
                            </Box>
                            <Box style={{
                                textAlign: "center",
                                marginBottom: "15px",
                            }}>
                                <label className={classes.label}>Download Initiated</label>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
};
export default DownloadModal;
