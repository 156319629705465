import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    alignItems: "center",
    borderRadius: "10px",
    minWidth: "180px",
    minHeight: "200px",
    boxShadow: "0px 0px 10px 0px rgba(49, 122, 149, 0.18)",
    cursor: "pointer",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  image: {
    maxWidth: "100%",
    height: "100%",
  },
}));
const GenericStaticCard = (props: any) => {
  const { label, image, handleClick, imageStyle } = props;
  const classes = useStyles();
  return (
    <Box className={classes.cardBox} data-testid="handleClick" onClick={() => handleClick()}>
      <img className={classes.image} src={image} style={imageStyle}/>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default GenericStaticCard;
