import React from "react";
import { Box,Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { constructYoutubeUrlFromVideoId, extractVideoIdFromThumbnailUrl } from "../../../../../../../components/src/Utils.web";
const useStyles = makeStyles(() => ({
  editArea: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
    height: "740px",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));
const MultimediaPreviewScreen = (props: any) => {
  const { multiMediaData } = props;
  const classes = useStyles();
  const videoId = multiMediaData?.attributes?.videos_url?.length > 0 && extractVideoIdFromThumbnailUrl(multiMediaData?.attributes?.videos_url[0]);
  const imageUrl = multiMediaData?.attributes.images
  const videoUrl = multiMediaData?.attributes.videos
 
  const youtubeUrl = videoId && constructYoutubeUrlFromVideoId(videoId);
  return (
    <>
    <div className={classes.titleBox} id="content">
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: multiMediaData.title,
           }}/>
      </div>
    <Box className={classes.editArea} >
{imageUrl && imageUrl.length > 0 &&
        <img
          data-test-id="img-preview"
          src={imageUrl[0]?.url}
          width="100%"
          height="100%"
        />}
      {videoUrl && videoUrl.length > 0 && (
        <video
          data-test-id="video"
          width="100%"
          height="100%"
          controls
        >
          <source src={videoUrl[0]?.url} type="video/mp4" />
        </video>
      )}
      {youtubeUrl && youtubeUrl.length > 0 && <iframe
        data-test-id="youtube"
        width="100%"
        height="100%"
        src={youtubeUrl.replace('watch?v=', 'embed/')}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>}
    </Box></>
  );
};

export default MultimediaPreviewScreen;
