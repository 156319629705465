import React from "react";
import { MenuItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { blueIcon, orangeIcon, redIcon, violetIcon, yellowIcon, greenIcon, indigoIcon } from "../../assets";

const StyledStudentMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      backgroundColor: '#f4f4f4',
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);

const HighlighterList = (props: any) => {
  const {
    applyHighlight,
    tooltipPos
  } = props;
  return (<>
    <div style={{left: `${tooltipPos.x + 170}px`,
    top: `${tooltipPos.y+10}px`,
    position:"absolute",
    backgroundColor: '#ffffff',
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    fontFamily:"Poppins"}}>
      <StyledStudentMenuItem 
      data-test-id="apply-violet"
      onClick={() => applyHighlight('rgb(113,69,154,0.6)')}>
        <img
          src={violetIcon}
          style={{ marginRight: "10px" }} />
        <ListItemText primary="Violet" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem 
      data-test-id="apply-indigo"
      onClick={() => applyHighlight('rgb(58,67,154,0.6)')}>
        <img src={indigoIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Indigo" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem
      data-test-id="apply-blue"
        onClick={() => {
          applyHighlight('rgb(0,173,235,0.6)')
        }}>
        <img src={blueIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Blue" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem
      data-test-id="apply-green"
        onClick={() => {
          applyHighlight('rgb(68,181,25,0.6)')
        }}>
        <img src={greenIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Green" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem 
      data-test-id="apply-yellow"
      onClick={() => applyHighlight('rgb(255,237,0,0.6)')}>
        <img src={yellowIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Yellow" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem 
      data-test-id="apply-orange"
      onClick={() => applyHighlight('rgb(255,181,0,0.6)')}>
        <img src={orangeIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Orange" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem 
      data-test-id="apply-red"
      onClick={() => applyHighlight('rgb(252,61,39,0.6)')}>
        <img src={redIcon} style={{ marginRight: "10px" }} />
        <ListItemText primary="Red" color="#48484A" />
      </StyledStudentMenuItem>
    </div>
  </>
  );
};

export default HighlighterList;
