import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchSection from "../../../../../components/src/SearchSection.web";
import ClassList from "../../../../dashboard/src/Student/components/ClassList.web";

const useStyles = makeStyles(() => ({
    noRecords: {
        fontFamily: "Poppins",
        textAlign: "center",
        paddingTop: "35px",
    },
    listItemBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        border: "1px solid rgba(149, 157, 165, 0.2)",
        borderRadius: "10px",
        margin: "20px 10px",
        padding: "15px",
        minHeight: "135px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        "&:hover": {
            background: "#ebf8fe",
            borderColor: "#3093DDFF",
            color: "#121212",
            cursor: "pointer"
        },
    },
    idBar: {
        display: "flex",
        marginTop: "20px",
    },
    listItemFont: {
        fontFamily: "Poppins",
        fontSize: "20px",
        color: "#282829",
        fontWeight: 600,
    },    
    listItemImage: {
        width: "100%",
        height: "100%",
    },
    idText: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#2B3D7D",
        fontWeight: 400,
    },
    searchbarTitle: {
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 600,
        color: "#2B3D7D",
        marginTop: "30px",
        marginBottom: "25px",
    },
    countBarImage: {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        marginRight: "10px",
    },
    imageBox: {
        width: "90px",
        height: "90px",
        border: "1px solid rgba(149, 157, 165, 0.2)",
        marginRight: "10px",
        padding: "8px",
        borderRadius: "10px",
        backgroundColor: "#fff",
    },

}));
const MyClasses = (props: any) => {
    const { dataList, searchInput, handleSearchClassName, showClassDetailView } = props;
    const classes = useStyles();
    return (
        <Box sx={{ px: "35px" }}>
            <Typography className={classes.searchbarTitle}>My Classes</Typography>
            <SearchSection
              searchInput={searchInput}
              handleSearchInput={handleSearchClassName}
              placeholder={"Search by class name"}
            />
            <ClassList dataList={dataList} handleClick={showClassDetailView} longView={true} />
        </Box>
    );
};

export default MyClasses;
