export const extractVideoId = (url: string): string | null => {
  // Extract video ID from YouTube URL
  const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^"&?\/\s]{11})/);
  return match ? match[1] : null;
};

export  const generateThumbnail = (file: File): Promise<string> => {
return new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    // Use the loaded data URL as the thumbnail source
    resolve(event.target?.result as string);
  };

  // Read the first few bytes of the video file to generate a thumbnail
  reader.readAsDataURL(file.slice(0, 1024 * 1024)); // Reading the first MB of the file

  reader.onerror = () => {
    reject(new Error('Error reading file for thumbnail generation'));
  };
});
}

export const extractVideoIdFromThumbnailUrl = (thumbnailUrl: string): string | null => {
// Extract video ID from YouTube thumbnail URL
console.log('thumbnailUrl@#',thumbnailUrl)
const match = thumbnailUrl?.match(/\/vi\/([^\/]+)\/default\.jpg/);
console.log('match',match)
return match ? match[1] : null;
};

export const constructYoutubeUrlFromVideoId = (videoId: string): string => {
// Construct YouTube video URL from video ID
return `https://www.youtube.com/watch?v=${videoId}`;
};
