import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, TextField, Grid, Button,Typography } from "@material-ui/core";
import { getStorageData } from "../../../../../framework/src/Utilities";
import StaticCanvas from "../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "10px",
  },
  question: {
    fontSize: "18px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "20px",
  },

  mainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    padding: "30px 60px",
  },
  contentBox: {
    width: "620px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "30px 60px",
    position:"relative"
  },
  mainLayoutSub: {
    display: "flex",
  },
  contentBoxSub: {
    width: "620px",
    backgroundColor: "#FFF",
    display: "flex",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "80px 30px 30px",
    position:"relative"
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#282829",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  submitBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
    cursor:"pointer"
  },
  disabledBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    opacity: "0.5",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
    "&.MuiButton-root.Mui-disabled":{
      color:"white"
    }
  },
  submitBtnLayout: {
    marginTop: "25px",
    cursor:"pointer"
  },
  loginBtnLayout: {
    gap:"10px",
    marginTop: "25px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
  },
  submitStyle:{
    color:"#818181"
  },
}));

const StudentOpenEndedLive = (props: any) => {
  const { stage, showQuestion,isSubStage,stageAnswers,showResult,isQuizStudent,isQuizTimedOut,isGuest } = props;
  const [token, setToken] = useState("");
  const [answer, setAnswer] = useState("");
  const [height,setHeight] = useState(0);
  const canAnswer = (stageAnswers??[]).length<=0 && !isQuizTimedOut && !isGuest;
  const backgroundImage=stage.attributes.background_image?.url
  const hasCanvasData = stage.attributes.canvas_data;
  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }

  let config = require("../../../../../framework/src/config")
  const classes = useStyles();
  

  useEffect(() => {
    tokenValue();
  }, []);

  useEffect(()=>{
    if(stageAnswers && stageAnswers.length>0){
      setAnswer(isQuizStudent?stageAnswers[0].student_answer:stageAnswers[0].answers[0].answer)
    }
    else{
      setAnswer("")
    }
  },[stageAnswers])
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(event.target.value);
  };
  
  const tokenValue = async () => {
    let valToken = await getStorageData("authToken");
    setToken(valToken);
  };

  const submitAnswer = async () => {
    let baseURL = isQuizStudent?`${config.baseURL}/bx_block_content_management/quizs/submit_quiz_answer`:`${config.baseURL}/bx_block_livestreaming/live_lesson/submit_lesson_answer`
    let body = isQuizStudent?{
      quiz_id: stage.attributes.quiz_id,
      quiz_markup_tools_id: stage.id,
      question: stage.attributes.quiz_stage_name,
      answers: [
        {
          answer
        }
      ]
    }:{
      lesson_id: stage.attributes.lessons_id,
      lesson_markup_tools_id: stage.id,
      question: stage.attributes.lesson_stage_name,
      answers: [
        {
          answer
        }
      ]
    };
    let response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token
      },
      redirect: "follow",
      body: JSON.stringify(body)
    });
    if (response.ok) {
      const resp = await response.json()
      if(resp.error == "Request size exceeds the maximum allowed size of 200 MB"){
        props.setSuccess(false,true);
      }
      else{
        props.setSuccess()
      }
    }
  };
  const redirectToLoginPage=()=>{
    window.location.href= "/login"
  }
  return (
    <Box className={isSubStage?classes.mainLayoutSub:classes.mainLayout} style={backgroundImage && { 
      backgroundImage: `url("${backgroundImage}")`,
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
     }}>
      <Box className={isSubStage?classes.contentBoxSub:classes.contentBox}>
      <Typography className={classes.title}>
        Opend-Ended Question
      </Typography>
      <Box>
          <div
              className={classes.question}
              dangerouslySetInnerHTML={{ __html: stage?.attributes.title }}
            />
        {  jsonCanvas &&
        <Box style={{position:"absolute",width:"90%",height:"100%",}}>
          <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
          </Box>}
      </Box>
        

        <TextField
          id="text-area"
          multiline
          variant="outlined"
          rows={16}
          placeholder="Answer"
          onChange={handleChange}
          fullWidth
          value={answer}
          disabled={!canAnswer || showResult}
          data-testId='openEndedText'
          style={{marginTop:`${height}px`}}
        />
        { canAnswer && !showResult &&
        <Box className={classes.submitBtnLayout}>
          <Button onClick={submitAnswer} disabled={!answer || !showQuestion}
            className={!answer || !showQuestion? classes.disabledBtn : classes.submitBtn}
            data-testID='submit'
          >
            Submit
          </Button>
        </Box>}
        {isSubStage && isGuest &&
          <Box className={classes.loginBtnLayout}>
            <Typography className={classes.submitStyle}>Please Login to submit your answers</Typography>
            <Button 
              onClick={redirectToLoginPage}
              className={classes.submitBtn}
              data-testID='submit'
            >
              Login
            </Button>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default StudentOpenEndedLive;
