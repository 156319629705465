import React from 'react'
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import StudentList from './StudentList.web';
import AddStudent from './AddStudent.web';
const TeacherPeopleManagement = (props: any) => {
const {
  selectedView,
  showAddStudentForm,
  loading,
  students,
  pageNoStudent,
  pageLimitStudent,
  handlePageChange,
  handleSearchInput,
  searchInput,
  deleteStudentProfileApi,
  isDeleteSuccess,
  handleCloseDeleteSuccessModal,
  handleOpenDeleteSuccessModal,
  handleOpenDeleteProfileModal,
  handleCloseDeleteProfileModal,
  openDeleteStudentModal,
  createStudentByTeacher,
  classList,
  studentDetails,
  updateStudentByTeacher,
  handleCloseAddEditStudentView,
  handleCloseCreateStudentSuccess,
  createSuccessModal,
  handleDateFilter,
  handleStatusFilter,
  passwordError,
  handleResetError,
  emailError,
  limitExceeded
} = props
const headerTitle = () => {

  if (selectedView === "Add Student") {
    const isUpdateRequest = Object.keys(studentDetails).length;
    if (isUpdateRequest) return "Edit Profile";
    else return "Add New Member";
  }
  return "People Management";
};
  const renderSwitch = (selectedView: string) => {
    if (selectedView === "Add Student") {
      return (
        <AddStudent 
        createStudentByTeacher={createStudentByTeacher}
        classList={classList}
        studentDetails={studentDetails}
        updateStudentByTeacher={updateStudentByTeacher}
        handleCloseAddEditStudentView={handleCloseAddEditStudentView}
        handleCloseCreateStudentSuccess={handleCloseCreateStudentSuccess}
        createSuccessModal={createSuccessModal}
        passwordError={passwordError}
        handleResetError={handleResetError}
        emailError={emailError}
        limitExceeded={limitExceeded}
        />
      );
    } else {
      return (
        <StudentList
        showAddStudentForm={showAddStudentForm}
        loading={loading}
        students={students}
        pageNoStudent={pageNoStudent}
        pageLimitStudent={pageLimitStudent}
        handlePageChange={handlePageChange}
        searchInput={searchInput}
        handleSearchInput={handleSearchInput}
        deleteStudentProfileApi={deleteStudentProfileApi}
        isDeleteSuccess={isDeleteSuccess}
        handleCloseDeleteSuccess={handleCloseDeleteSuccessModal}
        handleOpenDeleteSuccessModal={handleOpenDeleteSuccessModal}
        handleOpenDeleteProfileModal={handleOpenDeleteProfileModal}
        handleCloseDeleteProfileModal={handleCloseDeleteProfileModal}
        openDeleteStudentModal={openDeleteStudentModal}
        handleDateFilter={handleDateFilter}
        handleStatusFilter={handleStatusFilter}
        />
      );
    }
  };
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Box>
    <Grid container style={{ overflowX: "hidden", height: "100%" }}>
      <Grid item {...(!isSmallScreen && { xs: 2 })}>
        {!isSmallScreen && <Sidebar />}
      </Grid>
      <Grid item {...(!isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
        <Grid item md={12}>
          <AppHeaderWeb title={headerTitle()} />
        </Grid>
        <Grid item md={12}>
          {renderSwitch(selectedView)}
        </Grid>
      </Grid>
    </Grid>
  </Box>
  )
}

export default TeacherPeopleManagement;
