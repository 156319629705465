import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { reminderSent } from "../../assets";
const configJSON = require("../../config");
const useStyles = makeStyles(() => ({
  reminderSuccessTitle: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "5px",
  },
  reminderSuccessSubtitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#3F526D",
    fontWeight: 400,
    textAlign: "center",
  },
  reminderSuccessImage: {
    marginBottom: "10px",
  },
}));
const ReminderSuccess = (props: any) => {
  const { userData } = props;
  const classes = useStyles();
  return (
    <>
      <img className={classes.reminderSuccessImage} src={reminderSent} />
      <Typography className={classes.reminderSuccessTitle}>
        {configJSON.reminderSentTitle}
      </Typography>
      <Typography className={classes.reminderSuccessSubtitle}>
        {`A reminder to complete Student profile named "${userData.name}” has been sent successfully. `}
      </Typography>
    </>
  );
};

export default ReminderSuccess;
