import React, { useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, MuiThemeProvider , makeStyles} from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  wcTitle: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "15px",
  },
  wcQuestion: {
    fontSize: "18px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "25px",
  },
  entries: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "25px",
  },
  contebtBoxMain:{
    padding:"80px 30px 30px",
    position:"relative"
  }
}));
const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "50px",
      },
    },
  },
});
const WordCloudQuestion = (props: any) => {
  const { currentStage } = props;
  const classes = useStyles();
  const mdValue = currentStage?.attributes.description.length >= 6 ? 6 : 12;

  const [height,setHeight] = useState(0)
  const hasCanvasData = currentStage.attributes.canvas_data;
 
  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }
  return (
    <Box className={classes.contebtBoxMain}>
      <Typography className={classes.wcTitle}>
        Word Cloud Question
      </Typography>
      {  jsonCanvas && 
      <Box style={{position:"absolute",width:"90%",height:"100%",}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
      </Box>}
      <Box
        className={classes.wcQuestion}
        dangerouslySetInnerHTML={{
          __html: currentStage?.attributes.title,
        }}
      />
      <Typography className={classes.entries} style={{marginTop:`${height}px`}}>
        {currentStage?.attributes.description.length} Entries per student
      </Typography>
      <Grid container spacing={2}>
        {currentStage?.attributes.description.map(
          (item: any, index: number) => (
            <Grid key={index} item md={mdValue}>
              <FormControl fullWidth variant="outlined">
                <MuiThemeProvider theme={theme}>
                  <TextField
                    id={`answer-${index + 1}`}
                    name={`answer-${index + 1}`}
                    data-test-id={"option-name"}
                    fullWidth
                    placeholder={`Answer ${index+1}`}
                    variant="outlined"
                    onChange={() => {}}
                    disabled={true}
                    inputProps={{ style:  {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#282829",
                      padding:"15px 20px"
                    } }}
                  />
                </MuiThemeProvider>
              </FormControl>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default WordCloudQuestion;
