import React from "react";
import { Menu, MenuItem} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
export const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:hover": {
        backgroundImage:
          "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
          fontFamily: "Poppins",
          fontSize: "16px",
        },
      },
      '&.MuiTypography-root':{
        fontFamily:'Poppins',
        fontSize: "16px",
      }
    },
  }))(MenuItem);
export const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      borderRadius: "6px",
    },
  })((props: any) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: props.anchorCenter ? "center": "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
