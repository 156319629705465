import React, { useState } from "react";
import { Menu, MenuItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateSortList from "./DateSortList.web";

const StyledFilterListItem = withStyles((theme) => ({
  root: {
    "& .MuiTypography-body1":{
      fontFamily: "Poppins"
    },
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
    width:'100%',
  },
}))(MenuItem);
const StyledFilterList = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const FilterContextMenu = (props: any) => {
  const {
    anchorEl,
    open,
    onClose,
    handleDateFilter,
    handleStatusFilter
  } = props;
const [openDateSortList,setOpenDateSortList]=useState(false)
const [dateAnchorEl,setDateAnchorEl]=useState(null)
const [filterType,setFilterType]=useState("")

const handleOpenDateSort=(event:any,type:string)=>{
  event.preventDefault();
  setFilterType(type)
setDateAnchorEl(event.target)
setOpenDateSortList(true)
}
const handleCloseDateSortList=(event:any)=>{
  event.preventDefault();
  setDateAnchorEl(null)
  setOpenDateSortList(false)
}
  return (
    <>
      <StyledFilterList
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <StyledFilterListItem 
        data-test-id="open-subMenu"
        onClick={(e)=>handleOpenDateSort(e,"Date")}>
          <ListItemText primary="Date Created" color="#48484A" />
        </StyledFilterListItem>
        <StyledFilterListItem
        data-test-id="open-statusMenu"
        onClick={(e)=>handleOpenDateSort(e,"Status")}
        >
          <ListItemText primary="Profile Status" color="#48484A" />
        </StyledFilterListItem>
      </StyledFilterList>
      <DateSortList
      data-test-id="action-subMenu"
      open={openDateSortList}
      onClose={handleCloseDateSortList}
      anchorEl={dateAnchorEl}
      handleDateFilter={handleDateFilter}
      filterType={filterType}
      handleStatusFilter={handleStatusFilter}
      handleCloseMainFilter={onClose}
      />
    </>
  );
};

export default FilterContextMenu;
