import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container, Grid } from "@material-ui/core";
import { mobile, laptop, tablet } from "../assets";
const useStyles = makeStyles({
  heading: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginBottom: "50px",
    alignItems: "center",
    fontSize:"42px"
  },
  deviceHeading: {
    color: "#2C2C2C",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginBottom: "30px",
    alignItems: "center",
    marginTop:"20px",
    fontSize:"28px"
  },
  imageLayout: {
    display: "flex",
    justifyContent: "space-around"
  },
  textCenter: {
    textAlign: "center"
  }
});
const devices = [
  {
    name: "Mobile",
    image: mobile
  },
  {
    name: "Tablet",
    image: tablet
  },
  {
    name: "Laptop",
    image: laptop
  }
];
const Availabiity = () => {
  const classes = useStyles();
  return (
    <Box sx={{ mt: "100px" }}>
      <Container>
        <Box className={classes.textCenter}>
          <Typography variant="h3" className={classes.heading}>
            Available for all devices
          </Typography>
          <Grid container>
            {devices.map((device, i) => (
              <Grid item md={4} xs={12} sm={6} key={i}>
                <img src={device.image} />
                <Typography variant="h4" className={classes.deviceHeading}>
                  {device.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Availabiity;
