import React from "react";
import { Modal, ListItemText, Popover } from "@material-ui/core";
import { StyledMenuItem } from "../../../../../components/src/StyledMenu.web";
import ViewDetailsModal from "./ViewDetailsModal.web";
import DeleteProfileModal from "./DeleteProfileModal.web";
import ReminderModal from "./ReminderModal.web";
import {
  viewOptionActive,
  viewOptionInactive,
  editOptionActive,
  editOptionInactive,
  deleteOptionActive,
  deleteOptionInactive,
  previewOptionActive,
  previewOptionInactive,
  sendReminderOptionActive,
  sendReminderOptionInactive,
} from "../../assets";
const StudentContextMenu = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    handleCloseContextMenu,
    contextMenuUserData,
    showAddStudentView,
    openViewDetailsModal,
    handleCloseViewDetailsModal,
    handleOpenViewDetailsModal,
    handleOpenDeleteProfileModal,
    handleCloseDeleteProfileModal,
    openDeleteProfileModal,
    selectedStudent,
    isDeleteSuccess,
    deleteStudent,
    openReminderModal,
    handleOpenReminderModal,
    handleCloseReminderModal,
    sendReminder,
    isReminderSuccess,
    togglePasswordVisibility,
    visiblePasswords,
  } = props;
  const viewImageRef = React.useRef<any>(null);
  const editImageRef = React.useRef<any>(null);
  const deleteImageRef = React.useRef<any>(null);
  const showPasswordImageRef = React.useRef<any>(null);
  const sendReminderImageRef = React.useRef<any>(null);
  return (
    <>
      <Popover
        id="customized-menu"
        anchorEl={anchorEl}
        open={openContextMenu}
        onClose={handleCloseContextMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledMenuItem
          onMouseEnter={(e) => viewImageRef.current && (viewImageRef.current.src = viewOptionActive)}
          onMouseLeave={(e) => viewImageRef.current && (viewImageRef.current.src = viewOptionInactive)}
          data-test-id="view-details-menu-item"
          onClick={(e) => {
            handleCloseContextMenu(e);
            handleOpenViewDetailsModal(contextMenuUserData.id);
          }}
        >
          <img
            src={viewOptionInactive}
            style={{ marginRight: "10px" }}
            ref={viewImageRef}
          />
          <ListItemText primary="View Details" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
          onMouseEnter={(e) => editImageRef.current && (editImageRef.current.src = editOptionActive)}
          onMouseLeave={(e) =>  editImageRef.current &&  (editImageRef.current.src = editOptionInactive)}
          data-test-id="edit-profile-menu-item"
          onClick={() => showAddStudentView("EDIT", contextMenuUserData.id)}
        >
          <img
            src={editOptionInactive}
            style={{ marginRight: "10px" }}
            ref={editImageRef}
          />
          <ListItemText primary="Edit Profile" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
          onMouseEnter={(e) =>
            deleteImageRef.current && (deleteImageRef.current.src = deleteOptionActive)
          }
          onMouseLeave={(e) =>
            deleteImageRef.current && (deleteImageRef.current.src = deleteOptionInactive)
          }
          data-test-id="delete-profile-menu-item"
          onClick={(e) => {
            handleCloseContextMenu(e);
            handleOpenDeleteProfileModal();
          }}
        >
          <img
            src={deleteOptionInactive}
            style={{ marginRight: "10px" }}
            ref={deleteImageRef}
          />
          <ListItemText primary="Delete Profile" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
          onMouseEnter={(e) =>
            showPasswordImageRef.current && (showPasswordImageRef.current.src = previewOptionActive)
          }
          onMouseLeave={(e) =>
            showPasswordImageRef.current && (showPasswordImageRef.current.src = previewOptionInactive)
          }
          data-test-id="show-password-menu-item"
          onClick={() => togglePasswordVisibility(contextMenuUserData.id)}
        >
          <img
            src={previewOptionInactive}
            style={{ marginRight: "10px" }}
            ref={showPasswordImageRef}
          />
          <ListItemText
            primary={
              visiblePasswords[contextMenuUserData.id]
                ? "Hide Password"
                : "Show Password"
            }
            color="#48484A"
          />
        </StyledMenuItem>
        {contextMenuUserData?.profileStatus?.toLowerCase() === "incomplete" && (
          <StyledMenuItem
            onMouseEnter={(e) =>
              sendReminderImageRef.current && (sendReminderImageRef.current.src = sendReminderOptionActive)
            }
            onMouseLeave={(e) =>
              sendReminderImageRef.current &&  (sendReminderImageRef.current.src = sendReminderOptionInactive)
            }
            data-test-id="send-reminder-menu-item"
            onClick={(e) => {
              handleCloseContextMenu(e);
              handleOpenReminderModal();
            }}
          >
            <img
              src={sendReminderOptionInactive}
              style={{ marginRight: "10px" }}
              ref={sendReminderImageRef}
            />
            <ListItemText primary="Send Reminder" color="#48484A" />
          </StyledMenuItem>
        )}
      </Popover>
      <Modal open={openViewDetailsModal} onClose={handleCloseViewDetailsModal}>
        <ViewDetailsModal
          handleCloseModal={handleCloseViewDetailsModal}
          selectedStudent={selectedStudent}
          contextMenuUserData={contextMenuUserData}
        />
      </Modal>
      <Modal
        open={openDeleteProfileModal}
        onClose={(e) =>
          handleCloseDeleteProfileModal(e, contextMenuUserData.id)
        }
      >
        <DeleteProfileModal
          isDeleteSuccess={isDeleteSuccess}
          handleCloseModal={handleCloseDeleteProfileModal}
          deleteStudent={deleteStudent}
          userData={contextMenuUserData}
        />
      </Modal>
      <Modal
        open={openReminderModal}
        onClose={(e) => handleCloseReminderModal(e, contextMenuUserData.id)}
      >
        <ReminderModal
          handleCloseModal={handleCloseReminderModal}
          sendReminder={sendReminder}
          userData={contextMenuUserData}
          isReminderSuccess={isReminderSuccess}
        />
      </Modal>
    </>
  );
};

export default StudentContextMenu;
