import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { calender, library, reports, students } from "../../assets";
const useStyles = makeStyles(() => ({
  listItemBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap:'8px',
    alignItems: "center",
    borderRadius: "10px",
    minWidth:"180px",
    minHeight:"200px",
    boxShadow: "0px 0px 10px 0px rgba(49, 122, 149, 0.18)",
    cursor: "pointer",
    // "&:hover": {
    //   background: "#ebf8fe",
    //   borderColor:"#3093DDFF",
    //   color: "#121212"
    // }
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600
  },
  listItemImage: {
    maxWidth: "100%",
    height: "100%"
  },
}));
const ClassDetails = (props: any) => {
  const { teacher, navigateToSection } = props;
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={4}>
          <Grid item>
            <Box
              data-testid="library-btn"
              className={classes.listItemBox}
              onClick={
                () => navigateToSection("library")
              }
            >
            <img
                className={classes.listItemImage}
                src={library}
              /> 
              <Typography
                data-testid="library-label"
                className={classes.listItemFont}
              >
                Library
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              data-testid="students-btn"
              className={classes.listItemBox}
              onClick={
                () => { 
                  localStorage.setItem('isFromDashboard', JSON.stringify(true))
                  navigateToSection("peoplemanagement/students")
                }
              }
            >
             <img
                className={classes.listItemImage}
                src={students}
              />
              <Typography
                data-testid="students-label"
                className={classes.listItemFont}
              >
                Students
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              data-testid="reports-btn"
              className={classes.listItemBox}
              onClick={
                () => navigateToSection("reports")
              }
            >
           <img
                className={classes.listItemImage}
                src={reports}
              />
              <Typography
                data-testid="reports-label"
                className={classes.listItemFont}
              >
                Reports
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              data-testid="calendar-btn"
              className={classes.listItemBox}
              onClick={
                () => navigateToSection(`teachers/${teacher?.id}`)
              }
            >
             <img
                className={classes.listItemImage}
                src={calender}
              /> 
              <Typography
                data-testid="calendar-label"
                className={classes.listItemFont}
              >
                Calendar
              </Typography>
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
};

export default ClassDetails;
