import React from "react";
import { Box, Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getModalStyle } from "../../../ContentManagement/src/Teacher/components/utils";
import GenericButton from "../../../../components/src/GenericButton.web";
const useStyles = makeStyles((theme) => ({
  vdheader: {
    alignItems: "center",
    width: "100%",
  },
  vdpopupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  vdtitle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    fontWeight: 400,
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  vdsubtitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#7E7D7D",
    fontWeight: 400,
    width: "180px",
    overflow: "hidden",
    whiteSpace: "normal",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      width: "100%",
    },
  },
  vdpaper: {
    position: "absolute",
    width: "891px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding: "20px 0",
    },
  },
  vdcontainer: {
    position: "relative",
  },
  vdcrossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      top: "5px",
      right: "5px",
    },
  },
  vdheading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#2B3D7D",
    fontWeight: 500,
    marginBottom: "20px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  container: {
   padding: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  closeBtn: {
    [theme.breakpoints.down('xs')]: {
      width: "80%",
    },
  },
}));

const ViewDetailsModal = (props: any) => {
    
  const { handleCloseModal, selectedStudent,Classdata} = props;
  const convertDateFormat=(inputDate:string) =>{
   if(inputDate){
    const dateParts = inputDate.split("-");
    const formattedDate = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`);
    
    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); 
    const year = formattedDate.getFullYear();
  
    return `${day}/${month}/${year}`;
   }
  }
  const dataList = [
    { title: "Student Name", value: selectedStudent.fullName },
     { title: "Class", value: Classdata.map((item:any)=>item.attributes.class_name).join(", ")},

    { title: "Date Created", value: selectedStudent.creationDate },
    { title: "Profile Status", value: selectedStudent.profileStatus },
    { title: "Gender", value: selectedStudent.gender },
    { title: "Date of Birth", value: convertDateFormat(selectedStudent.birthDate) },
    { title: "Contact Number", value: selectedStudent.phone },
    { title: "Email ID", value: selectedStudent.email },
  ];
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.vdpaper}>
      <Box className={classes.vdcontainer}>
        <Box className={classes.vdpopupContent}>
          <Box className={classes.vdheader}>
            <Typography className={classes.vdheading}>
              Student Detail
            </Typography>
            <Divider />
          </Box>

          <Box className={classes.container}>
            <Grid container spacing={3}>
              {dataList.map((data: any) => (
                <Grid item md={3}>
                  <Typography className={classes.vdtitle}>
                    {data.title}
                  </Typography>
                  <Typography className={classes.vdsubtitle}>
                    {data.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className={classes.closeBtn}>
          <GenericButton
            type="COLORED"
            label="Close"
            handleClick={(e) => handleCloseModal()}
            customStyle={{ width: "165px", height: "50px" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewDetailsModal;
