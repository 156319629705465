export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const defaultImage =require("../assets/default_image.jpg")
export const Error = require("../assets/Error.svg");
export const Success = require("../assets/Success.svg");
export const SuccessImage = require("../assets/SuccessImage.svg");
export const ErrorImage = require("../assets/ErrorImage.svg");
export const CloseIcon = require("../assets/Close.png")
export const imageUpload =require("../assets/image_upload.png")
export const errorAlert =require("../assets/error_alert.png")
export const profileUpdated =require("../assets/profile_updated.png")
export const editProfileIcon =require("../assets/profile.png")
export const changePasswordIcon =require("../assets/change_password.png")
export const logoutIcon =require("../assets/logout.png")
export const logoutModalImage =require("../assets/logout_modal.png")
