Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Download";
exports.labelBodyText = "Download Body";
exports.getDownloadEndPoint = "bx_block_download/download/get_downloaded_resources?search=";
exports.btnExampleTitle = "CLICK ME";
exports.cloudTitle = 'Cloud';
exports.googleDriveTitle = 'Google Drive';
exports.searchPlaceholder = 'Search by name';
exports.noRecordFoundText = 'No records found';
exports.totalTxt = 'Total';
exports.filesTxt = 'Files';
exports.mbfreeTxt = 'free';
exports.ofTxt = 'of';
exports.mbTxt = 'MB';
exports.gbTxt = 'GB';
exports.hundredTxt = '100';
exports.doubleHundredTxt = '200';
exports.twoDigitTxt = '2';
exports.twoThousandDigitTxt = '2000';
exports.percentLogo = '%';
// Customizable Area End