import React from "react";

function ArrayDisplayField(props: any) {
 const { data } = props;
  const displayCount = 2;
  return (
    <div>
      {data.slice(0, displayCount).map((item: any, index: number) => (
        <span key={index}>{item} {index!==data.length-1?", ":""}  </span>
      ))}
      {data.length > displayCount && <span>...</span>}
    </div>
  );
}

export default ArrayDisplayField;
