import React from "react";
import { Modal, Menu, MenuItem, ListItemText,useMediaQuery } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ShareLinkModal from "./ShareLinkModal.web";
import DeleteEntryModal from "./DeleteEntryModal.web";
import {
  deleteOption,
  previewOption,
  shareOption,
  downloadOption,
  previewOptionActive,
  downloadOptionActive,
  shareOptionActive,
  deleteOptionActive,
} from "../../../assets";
import NotForMobile from "../../../../../../components/src/NotForMobile.web";
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const CustomContextMenu = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    selectedLessons,
    handleCloseContextMenu,
    handleOpenShareLinkModal,
    handleCloseShareLinkModal,
    openShareLinkModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openDeleteModal,
    handleDeleteEntry,
    contextMenuData,
    role,
    generateLink,
    shareLink,
    handleCloseNoMobileModal,
    openNoMobileModal,
    downloadLesson,
  } = props;
  const previewImageRef = React.useRef<any>();
  const deleteImageRef = React.useRef<any>();
  const shareImageRef = React.useRef<any>();
  const downloadImageRef = React.useRef<any>();
  const [labelName, setLabelName] = React.useState<string>('')
  const isSmallScreen = useMediaQuery('(max-width:600px)');


  
  return (<>
    
      <StyledMenu
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={openContextMenu}
        onClose={handleCloseContextMenu}
      >
        <StyledMenuItem
          data-test-id="preview-option"
          onMouseEnter={(e) =>
            previewImageRef.current && (previewImageRef.current.src = previewOptionActive)
          }
          onMouseLeave={(e) =>  previewImageRef.current && (previewImageRef.current.src = previewOption)}
          onClick={() => selectedLessons(contextMenuData.id, false)}

        >
          <img
            src={previewOption}
            ref={previewImageRef}
            style={{ marginRight: "10px" }}
          />
          <ListItemText primary="Preview" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
        data-test-id="download-option"
          onMouseEnter={(e) =>
           downloadImageRef.current && (downloadImageRef.current.src = downloadOptionActive)
          }
          onMouseLeave={(e) => downloadImageRef.current && (downloadImageRef.current.src = downloadOption)}
          onClick={()=>{downloadLesson(contextMenuData.type,contextMenuData.id)}}
        >
          <img
            src={downloadOption}
            ref={downloadImageRef}
            style={{ marginRight: "10px" }}
          />
          <ListItemText primary="Download" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
        data-test-id="share-option"
          onClick={(e) => {
            generateLink(contextMenuData.id)
            handleOpenShareLinkModal();
            handleCloseContextMenu(e);
          }}
          onMouseEnter={(e) => shareImageRef.current && (shareImageRef.current.src = shareOptionActive)}
          onMouseLeave={(e) => shareImageRef.current && (shareImageRef.current.src = shareOption)}
        >
          <img
            src={shareOption}
            ref={shareImageRef}
            style={{ marginRight: "10px" }}
          />
          <ListItemText primary="Share" color="#48484A" />
        </StyledMenuItem>
        <StyledMenuItem
        data-test-id="delete-option"
          onClick={(e) => {
            handleOpenDeleteModal();
            handleCloseContextMenu(e);
          }}
          onMouseEnter={(e) =>
            deleteImageRef.current && (deleteImageRef.current.src = deleteOptionActive)
          }
          onMouseLeave={(e) => deleteImageRef.current && (deleteImageRef.current.src = deleteOption)}
        >
          <img
            src={deleteOption}
            ref={deleteImageRef}
            style={{ marginRight: "10px" }}
          />
          <ListItemText primary="Delete" color="#48484A" />
        </StyledMenuItem>
      </StyledMenu>
      <Modal
        data-testid="share-modal"
        open={openShareLinkModal}
        onClose={handleCloseShareLinkModal}
      >
        <ShareLinkModal handleCloseModal={handleCloseShareLinkModal} role={role} shareLink={shareLink}/>
      </Modal>
      <Modal
        data-testid="delete-modal"
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        <DeleteEntryModal
          handleCloseModal={handleCloseDeleteModal}
          handleDeleteEntry={handleDeleteEntry}
          contextMenuData={contextMenuData}
        />
      </Modal>
      <Modal onClose={handleCloseNoMobileModal} open={openNoMobileModal} data-testid="nomobile-modal" >
             <NotForMobile handleCloseModal={handleCloseNoMobileModal} moduleName={labelName} />
      </Modal>
    </>
  );
};

export default CustomContextMenu;
