import React from "react";
import { Box, Grid, Typography, Container, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { linkIcon } from "../assets";
const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginBottom: "30px",
    fontSize: "42px",
  },
  subHeading: {
    color: "#322F37",
    fontFamily: "Poppins",
    fontWeight: 400,
    marginBottom: "30px",
    fontSize: "14px",
  },
  secondContent: {
    background: "#2b3d7d0A",
    borderRadius: "25px",
    padding: "30px",
  },
  contentImage: {
    borderRadius: "10px",
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
  },
  rightContentBox: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  link: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
  },
  linkBox: {
    display: "flex",
  },
}));

interface ContentType {
  id: number;
  s_no:number;
  content: string;
  title: string;
  created_at: string;
  updated_at: string;
  image: string;
  link: string;
  link_name: string;
}
interface IProps {
  contentData: ContentType[];
}
const Content = ({ contentData }: IProps) => {
  const classes = useStyles();
  return (
    <Container>
      <Box sx={{ mt: "100px" }}>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item md={6} xs={12} sm={12}>
              <img
                className={classes.contentImage}
                src={contentData[0]?.image}
                width="100%"
              />
            </Grid>
          </Hidden>
          <Grid item md={6} xs={12} sm={12} className={classes.contentBox}>
            <Box className={classes.rightContentBox}>
              <Typography variant="h4" className={classes.heading}>
                {contentData[0]?.title}
              </Typography>
              <Typography variant="body1" className={classes.subHeading}>
                {contentData[0]?.content}
              </Typography>
              {contentData[0]?.link && (
                <Box className={classes.linkBox}>
                  <a className={classes.link} href={contentData[0]?.link}>
                    {contentData[0]?.link_name}
                  </a>
                  <img src={linkIcon} />
                </Box>
              )}
            </Box>
          </Grid>
          <Hidden mdUp>
            <Grid item sm={12}>
              <img src={contentData[0]?.image} width="100%" />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Box sx={{ mt: "100px" }} className={classes.secondContent}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sm={12} className={classes.contentBox}>
            <Box>
              <Typography variant="h4" className={classes.heading}>
                {contentData[1]?.title}
              </Typography>
              <Typography variant="body1" className={classes.subHeading}>
                {contentData[1]?.content}
              </Typography>
              {contentData[1]?.link && (
                <Box className={classes.linkBox}>
                  <a className={classes.link} href={contentData[1]?.link}>
                    {contentData[1]?.link_name}
                  </a>
                  <img src={linkIcon} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <img
              className={classes.contentImage}
              src={contentData[1]?.image}
              width="100%"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Content;
