import React from "react";
//@ts-ignore
import Tick1 from "./Tick1.web"

type CheckboxProps = {
  checked: boolean;
  onChange?: any;
  label?: string;
  variant?: string;
};

type PositionStyle = "relative";

const webStyle = {
  checkBox: {
    display: "flex",
    gap: "10px",
    margin: "5px",
    alignItems: "center",
  },
  checkBoxContainer: {
    maxWidth: "20px",
    maxHeight: "20px",
    cursor: "pointer",
    position: "relative" as PositionStyle,
    display: "flex",
  },
  checkBoxContainerInner: {
    width: "20px",
    height: "20px",
    border:
      "1px solid linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
    background:
      "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkBoxContainerGreen: {
    width: "20px",
    height: "20px",
    border: "1px solid #000",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkBoxContainerGreenChecked: {
    width: "20px",
    height: "20px",
    border: "1px solid #00e6e6",
    background: "#00e6e6",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Checkbox = ({ checked, onChange, label, variant }: CheckboxProps) => {
  return (
    <div style={webStyle.checkBox}>
      <div style={webStyle.checkBoxContainer} onClick={onChange}>
        {checked ? (
          <div
            style={
              variant === "green"
                ? webStyle.checkBoxContainerGreenChecked
                : webStyle.checkBoxContainerInner
            }
          >
            {checked && <Tick1 />}
          </div>
        ) : (
          <div
            style={
              webStyle.checkBoxContainerGreen
            }
          />
        )}
      </div>
      {label && <div>{label}</div>}
    </div>
  );
};

export default Checkbox;
