import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { emoji, send, attach, closeIcon } from "../../assets";
import firebase from "firebase";
import CustomEmojiPicker from "../../../../../components/src/CustomEmojiPicker.web";
import { EmojiClickData } from "emoji-picker-react";
import FileViewerModal from "../../../../../components/src/FileViewerModal.web";

const useStyles = makeStyles(() => ({
  mainLayout: {
    padding: "20px",
    position: "relative",
    top: "65px",
    height: "calc(100% - 65px)",
    overflow: "hidden",
    overflowY: "auto",
  },
  messagesWrapper: {
    height: "100%",
    overflowY: "scroll",
  },
  studentImage: {
    width: "36px",
    height: "36px",
    marginRight: "10px",
    borderRadius: "50%",
  },
  studentName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "5px",
  },
  commentsLayout: {
    display: "flex",
    marginBottom: "20px",
  },
  comments: {
    fontFamily: "Poppins",
    fontSize: "13px",
    color: "#282829",
    fontWeight: 400,
  },
  commentBox: {
    backgroundColor: "#F1F5F9",
    border: "1px solid #D4DCE5",
    borderRadius: "10px",
    padding: "10px",
  },
  timestamp: {
    fontFamily: "Poppins",
    fontSize: "11px",
    color: "#282829",
    fontWeight: 400,
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  bottomBox: {    
    width: "25%",
    bottom: "0",
    display: "flex",
    position: "fixed",
    right: "0",
    flexDirection:"column"
  },
  commentInputBox: {
    borderTop: "1px solid #e5e5e5",
    backgroundColor: "#FFF",
  },
  actionBtn:{
    cursor:"pointer"
  },
  fileListWrapper: {    
    padding: "0 20px",
  },
  fileList: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 400,
    padding: "12px 18px",
    alignItems: "center",
    background: '#F0F0F0',
    borderRadius: "10px",
    marginBottom: "10px",
  },
  fileInfo: {
    marginRight: "10px",
  },
  fileSize: {
    fontSize: "14px",
  },
  fileClose: {
    cursor: "pointer",
  },
  commentImage: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  commentImageWrap: {
    overflow: "hidden",
    height: "132px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    border: "0.5px solid #979797",
    width: "184px",
    background: "#D8D8D8",
  },
  pdfView:{
    cursor:"pointer"
  }
}));

const StudentComments = (props: any) => {
  const { messages, room, studentProfileImage, studentName ,studentId, sendChatMessage,isGuest} = props;
  const [open, setOpen] = useState(false);
  const [attachFiles, setAttachFiles] = useState<File[]>([])
  const classes = useStyles();
  const [message, setMessage] = useState<string>("");
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [openImage, setOpenImage] = useState(false);
  const [imageData, setimageData] = useState({url:"",fileName:""});
  const handleClose = () => {
    setOpenImage(false);
  };
  const handleOpen = (thisImageData:{url:string,fileName:string}) => {
    setOpenImage(true);
    setimageData(thisImageData)
  };

 

  function timeAgo(timestamp: any): string {
    if (!timestamp) return "just now";
    const intervals: string[] = [
      "year",
      "month",
      "day",
      "hour",
      "minute",
    ];
    const durations: number[] = [31536000, 2592000, 86400, 3600, 60];

    const seconds: number = Math.floor(
      (Date.now() - timestamp.seconds * 1000) / 1000
    );
    if (seconds < 5) return "just now";
    const interval: number = durations.findIndex(
      (duration: number) => seconds >= duration
    );

    return interval === -1
      ? 'few seconds ago'
      : `${Math.floor(seconds / durations[interval])} ${intervals[interval]}${
          Math.floor(seconds / durations[interval]) === 1 ? "" : "s"
        } ago`;
  }
  const handleChange = (e: any) => {
    const { value } = e.target;
    setMessage(value);
  };
  const sendMessage = () => {
    const hasAttachments = attachFiles.length>0
    if (message.trim() === "" && !hasAttachments) return;
    const filesToUpload = attachFiles
    setMessage("")
    setAttachFiles([])
    const messageEntry = {
      id:studentId,
      room: room,
      name: studentName,
      profileImage: studentProfileImage,
      message: message
    };
    sendChatMessage(messageEntry,filesToUpload)
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      sendMessage(); 
    }
  };
  const scrollIntoView = () => {
    const lastComment = document.getElementById(`comment-${(messages?.length ?? 1)-1}`);
    if (lastComment) {
      const bounding = lastComment.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        return;
      }
      lastComment.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(()=>{
    scrollIntoView()
  },[messages])

  const onEmojiClick = (event: EmojiClickData) => {
    const emoji = event.emoji;
    if (textFieldRef.current) {
      const input = textFieldRef.current;
      const start = input.selectionStart ?? 0;
      const end = input.selectionEnd ?? 0;

      setMessage((oldMessage)=>oldMessage.slice(0, start) + emoji + oldMessage.slice(end));

      setTimeout(() => {
        input.setSelectionRange(start + emoji.length, start + emoji.length);
        input.focus();
      }, 0);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const newFiles = []
    if(files && files.length>0){
      for (const file of files) {
        if (file.type.startsWith('image/') || file.type === 'application/pdf') {
          newFiles.push(file);
        }
      }
      setAttachFiles([...newFiles])
    }
  };

  const removeFile = (index:number)=>{
    const newFiles = attachFiles
    newFiles.splice(index,1)
    setAttachFiles(newFiles)
  }

  return (
    <>
      {open && <CustomEmojiPicker open={open} onEmojiClick={onEmojiClick} />}
    <Box className={classes.mainLayout}>
      <Box className={classes.messagesWrapper}>
        {messages.map((message: any, index: number) => {
          const hasAttachments = message.attachments && message.attachments.length>0
          let isImage = true;
          if(hasAttachments){            
            const filename = message.attachments[0].fileName
            const extension = filename.split('.');
            if(extension.length>0){
              isImage = extension.pop()?.toLowerCase() == "pdf"?false:true
            }
          }
          return(
          <Box className={classes.commentsLayout} id={`comment-${index}`} key={index}>
            <img src={message.profileImage} className={classes.studentImage} alt="Profile" />
            <Box className={classes.commentBox}>
              <Typography className={classes.studentName}>
                {message.name}{" "}
                <span className={classes.timestamp}>
                  {timeAgo(message.createdAt)}
                </span>
              </Typography>
              <Typography className={classes.comments}>
                {message.message}
              </Typography>
              { hasAttachments && (isImage ?
            <Box className={classes.commentImageWrap}>
                <img  src={message.attachments[0].url} data-test-id="studentImageView" className={classes.commentImage} onClick={()=>handleOpen(message.attachments[0])} height={200} width={200} />
            </Box>:
            <Typography className={classes.pdfView} onClick={()=>handleOpen(message.attachments[0])}  data-test-id="studentPdfView">
              {message.attachments[0].fileName}
            </Typography>
            )}
            </Box>
          </Box>
          )
})}
      </Box>
      <Box className={classes.bottomBox}>
        <Box className={classes.fileListWrapper}>
          {attachFiles.map((curFile,index)=>{
            return(
          <Box className={classes.fileList}>
            <Box className={classes.fileInfo}>
              <Box>{curFile.name}</Box>
              <Box className={classes.fileSize}>{(curFile.size/1024).toFixed(2)} KB</Box>
            </Box>
            <Box className={classes.fileClose}>
              <img src={closeIcon} data-test-id={`chatFileClose-${index}`} alt="close-icon" className={classes.fileClose} onClick={()=>removeFile(index)}/>
            </Box>
          </Box>
            )
          })}
        </Box>
        <FormControl className={classes.commentInputBox} fullWidth variant="outlined">
          <TextField
            id="message"
            name="message"
            data-test-id={"message"}
            fullWidth
            placeholder={`Write something..`}
            variant="outlined"
            onChange={handleChange}
            value={message}
            className={classes.inputValue}
            onKeyDown={handleKeyDown}
            inputRef={textFieldRef} 
            InputProps={{
              endAdornment: (
                <>
                  {!isGuest && <InputAdornment position="end">
                    <img src={attach} className={classes.actionBtn} alt="Attachment" data-test-id="attachIcon" onClick={() => fileInputRef.current?.click()} />
                  </InputAdornment>}
                  <InputAdornment position="end">
                    <img src={emoji} data-test-id="openEmojiPicker" className={classes.actionBtn} alt="Emoji" onClick={() => setOpen(!open)} />
                  </InputAdornment>
                  <InputAdornment position="end">
                    <img src={send} className={classes.actionBtn} alt="Send" onClick={() => sendMessage()}/>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </FormControl>
      </Box>
      <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.pdf"
          data-test-id="attachFileInput"
        />
    </Box>
    <FileViewerModal
        fileUrl={imageData.url}
        open={openImage}
        handleClose={handleClose}
        filename={imageData.fileName}
      />

    </>
  );
};

export default StudentComments;
