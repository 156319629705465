import React, { useEffect } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import BasicPagination from "../../../accountgroups/src/BasicPagination.web";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { clockIcon, viewQuizzes } from "../assets";
import Loader from "../../../../components/src/Loader.web";
import { setStorageData } from "../../../../framework/src/Utilities";
export interface Props {
    getQuizelistApiCall: () => {},
    handleQuizePageChange: () => {},
    page: string,
    perPage: String,
    quizList: any[],
    loading: boolean
}

const useStyles = makeStyles(() => ({

    table: {
        minWidth: 700,
    },
    assignmentName: {
        marginLeft: "10px"
    },
    assignmentContainer: {
        display: "flex",
        alignItems: 'center',
    },

    noRecords: {
        fontFamily: "Poppins",
        textAlign: "center",
        paddingTop: "35px",
    },
    tabs: {
        "&.MuiTabs-root": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
            borderRadius: "8px",
            border: "0.2px solid #2B3D7D",
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "&.MuiTabs-flexContainer": {
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
        },
    },
    action: {
        borderRadius: "8px",
        border: "0.2px solid #2B3D7D",
        color: "#2B3D7D",
        padding: "5px",
        width: 'fit-content',
        cursor: 'pointer',
    },
    link: {
        borderBottom: "0.2px solid #2B3D7D",
        color: "#2B3D7D",
        width: 'fit-content',
        cursor: 'pointer',
    },
    tab: {
        "&.MuiTab-root": {
            color: "#2B3D7D",
            fontWeight: 500,
            fontSize: "20px",
            width: "218px",
            textTransform: "capitalize",
            borderRadius: "8px",
        },
        "&.MuiTab-root.Mui-selected": {
            fontWeight: 600,
            color: "#FFFFFF",
            textTransform: "capitalize",
            backgroundImage:
                "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            fontSize: "20px",
            borderRadius: "8px",
            width: "218px",
        },
    },
}));
const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#FDF4DC",
        color: "#202020",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
    },
    body: {
        fontSize: "14px",
        color: "#3F526D",
        fontFamily: "Poppins",
        fontWeight: 400,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const tableHead = () => {
    return (
        <TableRow>
            <StyledTableCell align="left">Quizzes</StyledTableCell>
            <StyledTableCell align="left">Class</StyledTableCell>
            <StyledTableCell align="left">Due Date</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Action</StyledTableCell>
        </TableRow>
    );
};

const QuizzeList = (props: any) => {
    const classes = useStyles();
    const {
        getQuizelistApiCall,
        handleQuizePageChange,
        page,
        perPage,
        quizList,
        loading
    } = props;
    useEffect(() => {
        getQuizelistApiCall()
    }, [])

    const tableBody = () => {
        return quizList && quizList.map((data: any) => {
            const days_left = data && data.attributes && data.attributes.days_left;
            return <StyledTableRow key={data.id}>
                <StyledTableCell align="left">
                    <Box className={classes.assignmentContainer}>
                        <img src={viewQuizzes} />
                        <Typography className={classes.assignmentName}>{data?.attributes?.quiz_name}</Typography>
                    </Box>
                </StyledTableCell>
                <StyledTableCell align="left">{data?.attributes?.class_name}</StyledTableCell>
                <StyledTableCell align="left">
                    {days_left > 5 ?
                        <Box className={classes.assignmentContainer}>
                            <img src={clockIcon} />
                            <Typography className={classes.assignmentName}>{days_left} Days Left</Typography>
                        </Box>
                        : <Box className={classes.assignmentContainer}>
                            <img src={clockIcon} style={{ width: "20px", height: "24px" }} />
                            <Typography className={classes.assignmentName} style={{ color: "#E02020" }}>{days_left} Days Left</Typography>
                        </Box>}
                </StyledTableCell>
                <StyledTableCell align="left">{(data.attributes && data.attributes.quiz_status) === 'pending' ? "Pending" : "Completed"}</StyledTableCell>
                <StyledTableCell align="left" onClick={()=>setStorageData("quizData",JSON.stringify(data))}>
                    {data?.attributes?.quiz_status == "pending" ?
                    <Link href={`assessmenttestquiz/${data.id}`}>
                        <Typography className={classes.action}>Open Quiz</Typography>
                    </Link>
                        : <Link href={`assessmenttestquiz/${data.id}`} style={{ textDecoration: 'none' }}>
                            <Typography className={classes.link}>View Quiz</Typography >
                        </Link>
                    }
                </StyledTableCell>
            </StyledTableRow>
    });
    };
    return (
        <>
            <Box sx={{ px: "35px" }}>
                {loading ? (
                    <Loader loading />
                ) : (
                    <Box>
                        {quizList?.length === 0 ? (
                            <Typography className={classes.noRecords}>
                                No records found
                            </Typography>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            {tableHead()}
                                        </TableHead>
                                        <TableBody>{tableBody()}</TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ px: "35px", py: "50px" }}>
                                    <BasicPagination
                                        handlePageChange={handleQuizePageChange}
                                        pageLimit={perPage}
                                        pageNo={page}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>)}
            </Box>
        </>
    );
};

export default QuizzeList;
