import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  Breadcrumbs
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AppHeader from "../../../../components/src/AppHeader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CircularProgress from "@material-ui/core/CircularProgress";

type OverflowXProperty = "hidden";
type TextAlignProperty = "center";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./../EmailAccountRegistrationController";
import Sidebar from "../../../../components/src/Sidebar.web";
import BasicTable from "../../../../components/src/BasicTable.web";
import BasicCardList from "../../../../components/src/BasicCardList.web";
import BasicPagination from "../../../../components/src/BasicPagination.web";

export default class SchoolAdminUserGroups extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderClassList = () => {
    return (
      <BasicCardList
        data={this.state.classData}
        handleClick={this.getStudentList}
      />
    );
  };

  renderStudentList = () => {
    const columns: { label: string; key: any; subKey: any }[] = [
      {
        label: "Full Name",
        key: "attributes",
        subKey: "full_name"
      },
      { label: "Email ID", key: "attributes", subKey: "email" },
      { label: "Phone Number", key: "attributes", subKey: "full_phone_number" },
      { label: "Date Created", key: "attributes", subKey: "date_created" }
    ];
    return (
      <>
        {this.state.studentListLoading ? (
          <Box style={webStyles.loaderStyle}>
            <CircularProgress style={{ color: "#2B3D7D" }} />
          </Box>
        ) : (
          <>
            <BasicTable
              data={this.state.studentList}
              pageNo={this.state.pageNo}
              columns={columns}
              handleClick={() => {}}
              action={null}
            />
            {this.state.studentList.length !== 0 && (
              <Box style={webStyles.paginationBox}>
                <BasicPagination
                  data-test-id= "pagination"
                  handlePageChange={this.handlePageChange}
                  pageLimit={this.state.pageLimit}
                  pageNo={this.state.pageNo}
                />
              </Box>
            )}
          </>
        )}
      </>
    );
  };

  renderDefaultView = () => {
    const columns: { label: string; key: any; subKey: any }[] = [
      {
        label: "User Group Name",
        key: "attributes",
        subKey: "user_group_name"
      },
      { label: "Class Code", key: "attributes", subKey: "group_alias" },
      { label: "Teacher", key: "attributes", subKey: "teacher_name" },
      {
        label: "Number of Students",
        key: "attributes",
        subKey: "no_of_student"
      },
      { label: "Date Created", key: "attributes", subKey: "date_created" }
    ];
    const action = <p>View Details</p>;
    console.log(1111, this.state.userGroupsData)
    return (
      <>
        {this.state.userGroupsLoading ? (
          <Box style={webStyles.loaderStyle}>
            <CircularProgress style={{ color: "#2B3D7D" }} />
          </Box>
        ) : (
          <>
            <BasicTable
              data={this.state.userGroupsData}
              pageNo={this.state.pageNo}
              columns={columns}
              handleClick={this.getClassesList}
              action={action}
            />
            {this.state.userGroupsData.length !== 0 && (
              <Box style={webStyles.paginationBox}>
                <BasicPagination
                  data-test-id= "pagination"
                  handlePageChange={this.handlePageChange}
                  pageLimit={this.state.pageLimit}
                  pageNo={this.state.pageNo}
                />
              </Box>
            )}
          </>
        )}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const headerTitle = "User Groups";
    const isClassesList = this.state.view === "classList";
    const isStudentList = this.state.view === "studentList";
    let componentToRender;
    switch (true) {
      case isClassesList:
        componentToRender = this.renderClassList();
        break;
      case isStudentList:
        componentToRender = this.renderStudentList();
        break;
      default:
        componentToRender = this.renderDefaultView();
        break;
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={webStyles.container}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
          {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={webStyles.rightWrapper}>
            <AppHeader title={headerTitle} />
            <Box paddingX={3} marginTop={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box style={webStyles.breadcrumbBox}>
                    <Breadcrumbs
                      data-test-id="breadcrumb-section"
                      style={webStyles.navigationIcon}
                      separator={<NavigateNextIcon fontSize="large" />}
                    >
                      <Typography
                        data-test-id="breadcrumb-title"
                        style={
                          this.state.view === "classList" ||
                          this.state.view === "studentList"
                            ? webStyles.title          
                            : webStyles.boldTitle
                        }
                        onClick={() => this.handleBreadCrumb("usergroupList")}
                      >
                        User Groups
                      </Typography>
                      {(this.state.view === "classList" ||
                        this.state.view === "studentList") && (
                        <Typography
                          data-test-id="breadcrumb-title2"
                          style={
                            this.state.view === "studentList"
                              ? webStyles.title
                              : webStyles.boldTitle
                          }
                          onClick={() => this.handleBreadCrumb("classList")}
                        >
                          {this.state.userGroupName}
                        </Typography>
                      )}
                      {this.state.view === "studentList" && (
                        <Typography
                          data-test-id="breadcrumb-title3"
                          style={webStyles.boldTitle}
                        >
                          {this.state.className}
                        </Typography>
                      )}
                    </Breadcrumbs>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {componentToRender}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const webStyles = {
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  headText: {
    fontSize: "24px",
    // color: "#2B3D7D",
    letterSpacing: "0.32px",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "30px 30px 0px"
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#757575",
    cursor: "pointer"
  },
  boldTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#000000"
  },
  navigationIcon: {
    marginTop: "0px"
  },
  breadcrumbBox: {
    padding: "0px 11px",
    marginTop: "0px"
  },
  noRecordText: {
    textAlign: "center" as TextAlignProperty,
    fontFamily: "Poppins"
  },
  rightWrapper: {
    height: "100%",
    overflowY: "scroll" as OverflowXProperty
  },
  paginationBox: {
    marginTop: "100px",
    marginBottom: "50px"
  },
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "400px",
    justifyContent: "center",
    alignItems: "center"
  }
};
// Customizable Area End
