import React from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
import { mcqSuccess } from "../../assets";
import { ACTIONS } from "../../../../../components/src/types";
export const configJSON = require("../../config");
const useStyles = makeStyles((theme) => ({
  popupContent: {
      display: "flex",
      flexDirection: "column"
  },
  paperSuccessModal: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    width: "502px",
    borderRadius: "10px",
    padding: "20px 30px",
    display: "flex",
  },
  header: {
      justifyContent: "center",
      display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    padding: "0 20px",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Poppins",
    color: "#2B3D7D",
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: 600,
  },

  containerSuccessModal: {
      width: "100%",
    position: "relative",
  },
  crossLayout: {
      justifyContent: "end",
      marginBottom: "10px",
    display: "flex",
    cursor: "pointer"
  },
  content: {
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#202020",
    fontSize: "20px",
    textAlign: "center",
  },
}));
const McqAddedSuccessModal = (props: any) => {
  const { successModal, handleCloseSuccessModal, lessonName ,action} = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const successModalHeading = (action:any) => {
    switch(action){
      case ACTIONS.ADD_MCQ :
      case ACTIONS.MCQ :
         return "Multiple Choice Questions added successfully!";
      case ACTIONS.ADD_POLL :
      case ACTIONS.POLLING_Q:
         return "Poll Questions added successfully!";
      case ACTIONS.OPEN_ENDED_QUESTION :
          return "Open-Ended Question added successfully!";
      default:
        return "";
    }
  }

  const successModalSubHeading = (action:any) => {
    switch(action){
      case ACTIONS.ADD_MCQ :
         return `Your Multiple Choice Questions has been added successfully to ${lessonName}`;
      case ACTIONS.ADD_POLL :
         return `Your Poll Questions has been added successfully to ${lessonName}`;
      case ACTIONS.OPEN_ENDED_QUESTION :
          return `Your Open-Ended Questions has been added successfully to ${lessonName}`;
      default:
        return "";
    }
  }
  
  return (
    <Modal open={successModal} onClose={handleCloseSuccessModal}>
      <Box style={modalStyle} className={classes.paperSuccessModal}>
        <Box className={classes.containerSuccessModal}>
          <Box className={classes.popupContent}>
            <Box className={classes.crossLayout}>
              <ClearIcon
                onClick={handleCloseSuccessModal}
                data-test-id="close-modal"
              />
            </Box>
            <Box className={classes.header}>
              <img src={mcqSuccess} style={{ marginBottom: "10px" }} />
              <Typography className={classes.title} data-test-id="sucess">
                {successModalHeading(action)}
              </Typography>
              <Typography className={classes.content} data-test-id="sucess-detail">
              {successModalSubHeading(action)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default McqAddedSuccessModal;
