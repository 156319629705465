import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { comments, question } from "../../assets";
import { CHAT } from "../../../../../components/src/types";
import {esclamation,correct} from "../../assets"
const useStyles = makeStyles(() => ({
  optionImage: {
    width: "16px",
    height: "16px",
    marginRight: "5px",
  },
  optionImageSelected: {
    width: "16px",
    height: "16px",
    marginRight: "5px",
    color: "#2B3D7D",
  },
  optionName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
  },
  optionNameSelected: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
  },
  headerLayout: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid rgba(229,229,229)",
    position: "fixed",
    top: "0",
    right: "0",
    width: "25%",
    backgroundColor:"#FFF",
    zIndex: 1,
  },
  optionLayout: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: 0,
    padding: "20px",
    flex:"0 0 50%",
    maxWidth:"50%",
    width:"100%",
    "& .MuiButton-label":{
      pointerEvents:"none",
    },
    textTransform:"none"
  },
  line: {
    position: "absolute",
    top: "60px",
    // left: 0,
    width: "50% !important",
    backgroundColor: "#2B3D7D",
    height: "5px",
    borderRadius: "10px",
    transition: "all .3s ease-in-out",
  },
  exclamationBox : {
    justifyContent:'center'
    ,height:"400px",
    width:"400px",
    alignItems:"center",
    flexDirection:"column",
    display:"flex",
    '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
      width:"100%",
      height:"100%"
    },
  },
  multipleQuestionStyle:{
    padding:20,
    color:"#818181",
    fontSize:"15px"
  },
  exclamationIcon:{
    height:"70px",
    width:"80px"
  },
  submitStyle:{
    alignSelf:"center",
    color:"#818181",
    '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
      textAlign:"center"
    },
  },
  loginBtn:{
    color: " #FFFFFF",
    fontSize: "14px",
    padding: "10px 25px",
    marginTop:"10px",
    fontWeight: 500,
    borderRadius: "50px",
    fontFamily: "Poppins",
    textTransform: "none",
    border: "none",
    cursor:"pointer",
    backgroundColor: "#2B3D7D !important",
  },
  correctBoxStyle:{
    justifyContent:'center',
    height:"400px",
    width:"400px",
    alignItems:"center",
    flexDirection:"column",
    display:"flex",
    '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
      width:"100%",
      height:"100%"
    },
  },
  correctIcon:{
    height:"80px",
    width:"80px"
  },
  successTextStyle:{
    alignSelf:"center",
    color:"#2f2f30",
    '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
      textAlign:"center"
    },
  }
}));
const StudentChatHeader = (props: any) => {
  const { handleChatHeaderOption,isSubStage,isQuestionStage,questionType,showResult,isGuest } = props;
  const [selected, setSelected] = useState<string>(CHAT.QUESTION);
  const lineRef = useRef<any>();
  const options = [
    {
      name: "Question",
      image: question,
    },
    {
      name: "Ask Teacher",
      image: comments,
    },
  ];
  const message = isGuest ? "Please Login to submit your answers" : "Please submit your answers"
  const handleChatOptionClick = (e: any, option: string) => {
    handleChatHeaderOption(option);
    setSelected(option);
    lineRef.current.style.width = e.target.offsetWidth + "px";
    lineRef.current.style.left = e.target.offsetLeft + "px";
  };

  const getNoQuestionBlock = () => {
    if(selected === CHAT.QUESTION && !isSubStage && !isQuestionStage && !showResult){
      return (<Box className={classes.exclamationBox}>
        <img className={classes.exclamationIcon} src={esclamation} />
        <Typography className={classes.submitStyle}>No questions, yet.</Typography>
      </Box>)
    }
  }
  const classes = useStyles();

  const redirectToLOgin=()=>{
    window.location.href= "/login"
  }
  return (
    <Box>
    <Box className={classes.headerLayout}>
      {options.map((option) => (
        <Button
          key={option.name}
          data-test-id="chat-option"
          data-testId={`chatOption-${option.name}`}
          className={classes.optionLayout}
          onClick={(e) => handleChatOptionClick(e, option.name)}
        >
          <img
            src={option.image}
            className={
              selected === option.name
                ? classes.optionImageSelected
                : classes.optionImage
            }
          />
          <Typography
            className={
              selected === option.name
                ? classes.optionNameSelected
                : classes.optionName
            }
          >
            {option.name}
          </Typography>
        </Button>
      ))}
      <div ref={lineRef} className={classes.line} />
    </Box>
    {(((selected === "Question" && !isSubStage && isQuestionStage) || (props.success && selected === "Question")) && !showResult) &&
    <Box>
    <Typography className={classes.multipleQuestionStyle}>{questionType}</Typography>
        {props.success ?
          <Box className={classes.correctBoxStyle}>
            <img className={classes.correctIcon} src={correct} />
            <Typography className={classes.successTextStyle}>Answers Submitted Successfully</Typography>
          </Box> :
          <Box className={classes.exclamationBox}>
            <img className={classes.exclamationIcon} src={esclamation} />
            <Typography className={classes.submitStyle}>{message}</Typography>
            {isGuest && <Button 
                className={classes.loginBtn}
                onClick={redirectToLOgin}
              >
                Login
            </Button>}
          </Box>
        }
    </Box>}
    {getNoQuestionBlock()}
    </Box>
  );
};

export default StudentChatHeader;
