import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, Modal,styled,Typography,Tabs,Tab,createTheme,ThemeProvider, Grid, LinearProgress,Switch } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import GenericButton from "../../../../../components/src/GenericButton.web";
import {
  matchingImage,
  mcqImage,
  textImage,
  multimediaImage,
  uploadImage,
  importImage,
  whiteboardImage,
  wordcloudImage,
  openEndedImage,
  graphicImage,
  saveImage,
  previewImage,
  matchingImageActive,
  mcqImageActive,
  textImageActive,
  multimediaImageActive,
  uploadImageActive,
  importImageActive,
  whiteboardImageActive,
  wordcloudImageActive,
  openEndedImageActive,
  graphicImageActive,
  assignmentBtn,
  lessonBtn,
  redCross,
  folderDefaultIcon,
  studentIcon,
  importLesson,
  importAssignment,
  noClassImg,
  imageUpload,
  groupLessonIcon,
  blueMusicBtn,
} from "../../assets";
import { ACTIONS, COURSES_PAGES } from "../../../../../components/src/types";
import AddGraphicModal from "./AddGraphicModal.web";
import StudentCountModal from "./StudentCountModal.web";
import MultimediaModal from "./MultimediaModal.web";
import QuestionTypeSelectionModal from "./QuestionTypeSelectionModal.web";
import StorageProvider from "../../../../../framework/src/StorageProvider";
import { getStorageData } from "../../../../../framework/src/Utilities";
import Gsuiteintegration from "../../../../gsuiteintegration/src/Gsuiteintegration";

interface TabProps {
  btnTab: number;
  handleTabChange?: (event: React.ChangeEvent<{}>, value: number) => void;
}

interface RenderFunctions {
  renderMyLibrary: () => JSX.Element;
  renderSharedLibrary: () => JSX.Element;
}

interface NavigationProps {
  navigation: any;
  handleButtonClickInsideGsuite: any;
}

interface ImportProps {
  importPressed: boolean;
  setImportPressed: (value: React.SetStateAction<boolean>) => void;
  fileFromatErr: boolean;
  getFilesGdrive: (files: File | null) => void;
}

interface ImportButtonProps {
  isChecked: boolean;
  fileFromatErr: boolean;
  handleImport: Function;
}

interface NextButtonProps {
  handleNext: Function;
  selectedDrive: string | null;
  step: number;
  selectedClass: string | null;
  selectedUnit: string | null;
  selectedLessonLibrary: string | null;
}

interface RenderActionButtonProps {
  btnTab: number;
  importProps: ImportButtonProps;
  nextProps: NextButtonProps;
}

interface MyDrives {
  lesson: boolean;
  quizzes: boolean;
  assignment: boolean;
}

interface FolderList {
  type: string;
  id : string,
  attributes: {
    title:string;
    lesson_name:string;
    folder_name: string;
    lesson_attachment:string;
    assignment_name : string;
    total_stages?:string;
    total_stage?:string;
    lessons?: {
      data: any
    };
    assignments?: {
      data: any;
    };
  };
}

interface Lesson {
  id: "2060",
  type: "lesson",
  attributes: {
    lesson_name: "HELLO",
    lesson_objective: "",
    account_id: 262,
    teacher_classes_id: 132,
    teacher_class: {
      data: {
        id: "132",
        type: "teacher_class",
        attributes: {
          class_name: "testeddddd",
          class_code: "793006959254",
          start_date: null,
          end_date: null,
          class_status: "pending",
          created_at: "2024-05-20T06:31:50.401Z",
          updated_at: "2024-05-20T06:31:50.418Z",
          attachment: "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbW9IIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--34fc15e3bb712f026e1ac37f3b6bb666c5c9b7d8/Untitled.jpeg",
          full_name: null,
          student_count: null,
          teacher_id: 262,
        },
      },
    },
  },
}

interface Assignment {
  id: number;
  lessonId: number;
  name: string;
  description: string;
  dueDate: Date;
  submissionDeadline: Date;
  assignmentType: string; 
  points: number;
  created_at: Date;
  updated_at: Date;
}

interface Attributes {
  attachment: string;
  class_name: string;
  student_count: number;
}

interface Class {
  attributes: Attributes;
}



let config = require("../../../../../framework/src/config")


const renderLessonOrAssignmentUnits = (
  selectedDrive: string | null,
  lessonListData: FolderList[],
  selectedUnit: string | null,
  handleUnitSelection: (unitId: string, unitObj: FolderList,title: string) => void,
  handleAttachmentLesson: (unitObj: FolderList) => string
) => {
  return(
    <>
    {lessonListData.map((unitObj: FolderList , index: number) => (
      <Box data-test-id="handleUnitSelectionTesId"
      className={`unitPaperBox ${lessonListData !== null && selectedUnit === unitObj.id ? "unitActive" : ""}`} key={index} 
      onClick={() => handleUnitSelection(unitObj.id,unitObj,selectedDrive === "lessons" ?`Lesson ${index+1}`:`Assignment ${index+1}`)}>
        <Box>
          <img src={handleAttachmentLesson(unitObj)} alt="" className="unitDummyImg" />
        </Box>
        <Box className="unitInfoBox">
          <Box className="unitInfoTitleBox">
            <Box className="countBox">
            <Box>
              <PersonOutlineIcon className="personIcon" />
            </Box>
            {selectedDrive === "lessons" ?
              <Typography className="unitInfoTitle">Lesson {index+1} </Typography>:
              <Typography className="unitInfoTitle">Assignment {index+1}</Typography>}
            </Box>
            <Box>
            </Box>
          </Box>
          {selectedDrive === "lessons" ? 
          <Box>
            <Box>
              <Typography className="countTxt">{unitObj.attributes.lesson_name}</Typography>
            </Box>
          </Box> :
          <Box >
            <Box>
              <Typography className="countTxt">{unitObj.attributes.assignment_name}</Typography>
            </Box>
          </Box>}
        </Box>
      </Box>
    ))}
    </>
  )
};



const AssignmentData = (handelModal:() => void,selectedDrive:string|null,selectedStage:{title:string,detail:string}) => {
  return (
    <AssignmentImportedModal>
      <ModalHeaderBox>
        <ModelHeaderTitle></ModelHeaderTitle>
        <ModelHeaderCloseIcon>
          <CloseIcon data-test-id="handelModalTestId" onClick={handelModal} />
        </ModelHeaderCloseIcon>
      </ModalHeaderBox>
      <Box className="flexCenterBox">
        <img src={selectedDrive === "lessons" ?importLesson:importAssignment} className="assignmentImportedIcon" />
        <Typography className="importedTxt"> {selectedDrive === "lessons" ? "Lesson Imported!":"Assignment Imported!"}</Typography>
        <Typography className="importedDescriptionTxt">{selectedDrive === "lessons" ? "Lesson":"Assignment"}  "{selectedStage?.detail}" has been successfully imported.</Typography>
      </Box>
    </AssignmentImportedModal>
  )
}

const renderUploadedJson = (file:File | null,selectedView:string) => {
  return (
    <Box className="flexCenterBox">
      <img src={importLesson} className="assignmentImportedIcon" />
      <Typography className="importedTxt"> {selectedView == "LESSON" ? "Lesson Uploaded!" : "Assignment Uploaded!"}</Typography>
      {file && <Typography className="importedDescriptionTxt">{selectedView == "LESSON" ? "Lesson" : "Assignment"} "{file.name}" has been successfully Uploaded.</Typography>}
    </Box>
  )
}

const renderLessonUnits = (
  folderList: FolderList[],
  selectedUnit: string | null,
  handleUnitSelection: (unitId: string, unitObj: FolderList, title: string) => void
) => {
  return (
    <>
    {folderList.map((unitObj: FolderList, index: number) => (
      <Box data-test-id="selectedUnitTestId" 
      className={`unitPaperBox ${selectedUnit !== null && selectedUnit === unitObj.id ? "unitActive" : ""}`} 
      key={index} onClick={() => handleUnitSelection(unitObj.id.toString(), unitObj,"Lesson 1")}>
        <Box>
          <img src={folderDefaultIcon} alt="" className="unitDummyImg" />
        </Box>
        <Box className="unitInfoBox">
          <Box className="unitInfoTitleBox">
            <Box>
              <Typography className="unitInfoTitle">{unitObj.attributes.folder_name} </Typography>
            </Box>
            <Box>
            </Box>
          </Box>
          <Box className="countBox">
            <Box>
              <PersonOutlineIcon className="personIcon" />
            </Box>
            <Box>
              <Typography className="countTxt">Total lessons - {unitObj.attributes?.lessons?.data?.length}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      ))}
      </>
  )
};



const NextButtonTab = ( handleNext: Function,
  selectedDrive: string | null,
  step: number,
  selectedClass: string | null,
  selectedUnit: string | null,
  selectedLessonLibrary: string | null) =>{
    return(
      <Button data-test-id="handleNextTestId"
        style={NextButtonStyle}
        onClick={() => handleNext()}
        disabled={!((step === 0) || (step === 1 && selectedClass !== null) || (step === 2 && selectedUnit !== null) || (step === 3 && selectedLessonLibrary !== null) )}
      >
        Next
      </Button>
    )
  }

  const ImportButtonTab = (btnTab:number, isChecked: boolean, fileFromatErr: boolean,handleImport: Function) =>{
    
      return(
        <Button data-test-id="handleNextTestId"
          style={NextButtonStyle}
          onClick={() => handleImport()}
          disabled={(btnTab === 2 && !isChecked) || (btnTab === 2 && fileFromatErr)}
        >
          Import
        </Button>
      )
    }

    const SelectTab = (
      tabProps: TabProps,
      renderFunctions: RenderFunctions,
      navigationProps: NavigationProps,
      importProps: ImportProps
    ) => {
      const { btnTab, handleTabChange } = tabProps;
      const { renderMyLibrary, renderSharedLibrary } = renderFunctions;
      const { navigation, handleButtonClickInsideGsuite } = navigationProps;
      const { importPressed, setImportPressed, fileFromatErr, getFilesGdrive } = importProps;
    
  return(
    <>
    <ModalTabMain data-test-id="handleTabChangeTestId" indicatorColor="primary" textColor="inherit" value={btnTab} onChange={handleTabChange}>
      <ModalTabs label="My Library" className={btnTab === 0 ? "activeTab" : ""} />
      <ModalTabs label="Shared Library" className={btnTab === 1 ? "activeTab" : ""} />
      <ModalTabs label="Go to Drive" className={btnTab === 2 ? "activeTab" : ""} />
    </ModalTabMain>
    {btnTab === 0 && renderMyLibrary()}
    {btnTab === 1 && renderSharedLibrary()}
    {btnTab === 2 && renderGoToDrive(navigation,handleButtonClickInsideGsuite, importPressed,setImportPressed,fileFromatErr,getFilesGdrive)}
    </>
  )
}

const renderDriveSelectionBoxes = (
  selectedDrive: string | null,
  selectedView:string,
  handleDriveSelection: (drive: string) => void
) => {
  return (
    <>
     {selectedView == "LESSON"?
    <PaperBox data-test-id = "selectionTestId" onClick={() => handleDriveSelection("lessons")} className={`papperBox ${selectedDrive === "lessons" && "paperActive"}`}>
      <IconBox>
        <img src={lessonBtn} className="bookIcon" />
      </IconBox>
      <PaperBoxText>Lessons</PaperBoxText>
    </PaperBox>:
    <PaperBox data-test-id = "assignmemntSelectTestId" onClick={() => handleDriveSelection("assignments")} className={`papperBox ${selectedDrive === "assignments" && "paperActive"}`}>
      <IconBox>
        <img src={assignmentBtn} className="bookIcon" />
      </IconBox>
      <PaperBoxText>Assignments</PaperBoxText>
    </PaperBox>
}
  </>
  );
};

  const renderLessonSharedLibrary = (
    selectedSharedDrive: string | null,
    selectedView:string,
    handleSharedDrive: (drive: string) => void
  ) => {
    return (
      <>
      {selectedView == "LESSON"?
      <PaperBox data-test-id = "lessonsDriveTestId" onClick={() => handleSharedDrive("lessons")} 
      className={`papperBox ${selectedSharedDrive === "lessons" && "paperActive"}`}>
        <IconBox data-test-id="iconTextId">
          <img src={lessonBtn} className="bookIcon" />
        </IconBox>
        <PaperBoxText>Lessons</PaperBoxText>
      </PaperBox>:
      <PaperBox data-test-id = "assigneSharedTestId" onClick={() => handleSharedDrive("assignments")} 
      className={`papperBox ${selectedSharedDrive === "assignments" && "paperActive"}`}>
        <IconBox>
          <img src={assignmentBtn} className="bookIcon" />
        </IconBox>
        <PaperBoxText>Assignments</PaperBoxText>
      </PaperBox>}
    </>
    );
  };



const renderFolderData = (lessonListData:any,
  selectedUnit:string|null,
  selectedDrive:string|null,
  folderDataInfo:FolderList[],
  handleUnitSelection:(unitId: string, unitObj: FolderList, title: string) => void,
  handleAttacemnet:(unitObj: FolderList) => string) => {
  return(
    <>
    {folderDataInfo.map((unitObj: FolderList, index: number) => (
      <Box data-test-id="handleFolderTestId" className={`unitNextPaperBox ${lessonListData !== null && selectedUnit === unitObj.id ? "unitNextActive" : ""}`} key={index} 
      onClick={() =>
       handleUnitSelection(unitObj.id, unitObj, selectedDrive === "lessons" ? `Lesson ${index + 1}` : `Assignment ${index + 1}`)}>
        <Box>
          <img src={handleAttacemnet(unitObj)} alt="" className="unitNextDummyImg" />
        </Box>
        <Box className="unitNextInfoBox">
          <Box className="unitNextInfoTitleBox">
            <Box className="countNextBox">
              <Box>
                <PersonOutlineIcon className="personNextIcon" />
              </Box>
              {selectedDrive === "lessons" ?
                <Typography className="unitNextInfoTitle">Lesson {index + 1} </Typography> :
                <Typography className="unitNextInfoTitle">Assignment {index + 1}</Typography>}
            </Box>
            <Box>
            </Box>
          </Box>
          {selectedDrive === "lessons" ?
            <Box>
              <Box>
                <Typography className="countNextTxt">{unitObj.attributes.lesson_name}</Typography>
              </Box>
            </Box> :
            <Box >
              <Box>
                <Typography className="countNextTxt">{unitObj.attributes.assignment_name}</Typography>
              </Box>
            </Box>}
        </Box>
      </Box>
    ))}
    </>
  )
}

const renderClassBoxes = ( dataName: Class[], selectedClass: string | null, handleClassSelection: (className: string, classObj: Class) => void ) => {
  return (
    <ClassBoxStyle>
    {dataName.map((classObj: Class, index:  number ) => (
      <Box data-test-id = "handleClassTestIdTwo" className={`classPaperBox ${selectedClass !== null && parseInt(selectedClass) === index ? "classActive" : ""}`} key={index} onClick={() => handleClassSelection(index.toString(),classObj)}>
       <Box>
       <img src={classObj.attributes.attachment?classObj.attributes.attachment:noClassImg} alt="" className="classDummyImg" />
        </Box>
        <Box className="classInfoBox">
          <Box className="classInfoTitleBox">
            <Typography className="classInfoTitle">{classObj.attributes.class_name}</Typography>
          </Box>
          <Box className="studentCntBox">
            <Box>
              <img src={studentIcon} className="personIcon" />
            </Box>
            <Box>
              <Typography className="studentCntTxt">Student Count - {classObj.attributes.student_count}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    ))}
  </ClassBoxStyle>
  );
};

const renderAssignmentUnits = (
  folderAssignmenrList: FolderList[] | undefined, 
  selectedUnit: string | null,
  handleUnitSelection: (unitId: string, unitObj: FolderList, title : string) => void
) => {
  return(
    <>
    {folderAssignmenrList?.map((unitObj: FolderList, index: number) => (
      <Box data-test-id="folderUnitTestId" className={`unitPaperBox ${selectedUnit !== null &&
        selectedUnit === unitObj.id ? "unitActive" : ""}`} key={index} onClick={() => handleUnitSelection(unitObj.id.toString(), unitObj, "Lesson 1")}>
        <Box>
          <img src={folderDefaultIcon} alt="" className="unitDummyImg" />
        </Box>
        <Box className="unitInfoBox">
          <Box className="unitInfoTitleBox">
            <Box>
              <Typography className="unitInfoTitle">{unitObj.attributes.folder_name} </Typography>
            </Box>
            <Box>
            </Box>
          </Box>
        <Box className="countBox">
          <Box>
            <PersonOutlineIcon className="personIcon" />
          </Box>
          <Box>
            <Typography className="countTxt">Assignments -{unitObj.attributes?.assignments?.data?.length}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ))}
  </>
  )
};

const fetchData = async (setStep:(step: number) => void,step:number,token:string,setdataName:(data: Class[]) => void) => {
  let baseURL = `${config.baseURL}/bx_block_content_management/teacher_flows/current_user_classes_list`
  const response = await fetch(baseURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token
      }
    });
    const result = await response.json();
    if (result && result.data && result.data.length > 0) {
      setdataName(result.data);
    }
    setStep(step + 1)
    return result;
   };


const useStyles = makeStyles(() => ({
  normalBtn: {
    borderRadius: "10px",
    padding: "10px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "12px",
    marginBottom: "15px",
    fontWeight: 500,
    justifyContent: "flex-start",
    whiteSpace: "nowrap",
    display:"flex",
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#FFF",
    },
  },
  btnColor:{
    "& .Mui-disabled": {
      color: "#FFFFFF"
    },
  },
  finalBtns: {
    borderRadius: "10px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "15px",
    backgroundColor: "#2B3D7D",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#2B3D7D",
      color: "#FFFFFF",
    },
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "50px",
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "12px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "15px",
    alignItems: "center",
    maxWidth:"250px",
    "& .MuiButton-label":{
      whiteSpace:"break-spaces",
      textAlign:"left",
    }
  },
  image: {
    marginRight: "10px",
  },
  musicSwitchLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 9px",
    marginBottom: "15px",
    border: "2px solid #3F526D",
    borderRadius: "10px",
    "& em":{
      fontStyle: "Normal",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      fontFamily: "Poppins",
    },
    "& .switch-wrapper":{
      "& .MuiSwitch-root":{
        padding:"9px",
        "& .MuiSwitch-thumb":{
          width:"15px",
          height:"15px",
          backgroundColor:"#FFF",
        },
        "& .MuiSwitch-track":{
          borderRadius:"13px",
        },
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
          backgroundColor:"2B3D7D",
          opacity:"1",
        },
        "& .MuiSwitch-colorSecondary.Mui-checked":{
          color:"transparent",
        },
        "& .MuiSwitch-switchBase":{
          transform:"translateX(3px)",
        },
        "& .MuiSwitch-switchBase.Mui-checked":{
          transform:"translateX(20px)",
        },
        "& .MuiIconButton-label":{
          position:"relative",
          top:"2px",
        }
      }
    }
  }
}));
interface Props{
  handleActionChange:(data:any)=>void;
  addMultiMediaStage:any ;
  setMcqfile:(data:any)=>void;
  setPollfile:(data:any)=>void;
  setOpenEndedQuestionfile:(data:any)=>void;
  selectedView:any;
  saveModalOpen:()=>void
  getStages: any;
  navigation:any;
  setIsPreviewClicked:(data : boolean)=>void
  stages: []
  handleMcqClick:(options: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean
  }) => void;
  handlePollClick:(options: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean
  }) => void;
  handleMatchClick:(options: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean
  }) => void;
  handleDoublePointActive:(data : boolean)=>void;
  handleOpenEndedClick:(options: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean
  }) => void;
  updateQuizMusic?:(isMusic:boolean)=>void;
  isMusic?:boolean
}

interface AssignmentFolderData {
  id: string;
  attributes?: {
    teacher_id?: string;
  };
}

const ErrorMsg = styled(Typography)({
 textAlign: "center",
 color: "red"
});

export const renderGoToDrive = (navigation: any, handleButtonClickInsideGsuite: any, importPressed: boolean, setImportPressed: { (value: React.SetStateAction<boolean>): void; (value: React.SetStateAction<boolean>): void; },fileFromatErr:boolean,getFilesGdrive: { (files: File | null): void; (files: File | null): void; }) => { 
  return (
    <>
      <Gsuiteintegration 
      data-test-id="gdriveIntegration"
      navigation={navigation} 
      id={""} 
      onButtonClick={handleButtonClickInsideGsuite} 
      importPressed={importPressed} 
      setImportPressed={setImportPressed} 
      getFilesGdrive={getFilesGdrive}
      isCheckboxNeeded
      />
      {fileFromatErr && <ErrorMsg>The selected file is not supported. Please choose a JSON file</ErrorMsg>}
    </>
  );
};

const handleModal = (isOpen: boolean,setIsOpen:  React.Dispatch<React.SetStateAction<boolean>>, handleActionChange: (data: string) => void) => {
  setIsOpen(!isOpen);
  handleActionChange("IMPORT")
};

const CustomModal = styled(Modal)({
  height: "100vh",
  overflowY: "scroll",
});
const ModalInnerBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  background: "white",
  border: "none",
  width: "890px",
  alignItems: "center",
  transform: "translate(-50%, -50%)",
  borderRadius: "14px",
  "& .para": {
    fontSize: "20px",
  },
  overFlowY: "scroll",
});
const ModalInnerBox1 = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  background: "white",
  border: "none",
  height: "max-content",
  width: "max-content",
  alignItems: "center",
  transform: "translate(-50%, -50%)",
  borderRadius: "14px",
  "& .para": {
    fontSize: "20px",
  },
  overFlowY: "scroll",
});
const AssignmentImportedModal = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  background: "white",
  border: "none",
  width: "34%",
  transform: "translate(-50%, -50%)",
  borderRadius: "14px",
  padding: "20px",
  "& .flexCenterBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "10px 10px",
  },
  "& .assignmentImportedIcon": {
    width: "130px",
    height: "140px",
  },
  "& .importedTxt": {
    color: "#2B3D7D",
    fontWeight: 600,
    fontSize: "22px",
    fontFamily:"Poppins"
  },
  "& .importedDescriptionTxt": {
    fontWeight: 600,
    fontSize: "16px",
    marginTop: "8px",
    fontFamily:"Poppins"
  },
});
const ModalInnerBottomBox1 = styled(Box)({
  borderTop: "1px solid #f0f0f0",
  padding: "25px 30px",
  display: "flex",
  justifyContent: "flex-end",
  gap: 20,
  marginTop: "30px",
});

const UploadModalStyle = styled(Box)({
  width: "50%",
  maxWidth: "70%",
  backgroundColor: "white",
  overflowY: "scroll",
  position: "absolute",
  padding: "50px",
  borderRadius: "15px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "80%",
  "& .notificationHeading": {
    display: "flex",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  "& .uploadFile": {
    color: "#2B3D7D",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  "& .closeIcons": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .dragStyle": {
    border: "1px solid lightBlue",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "267px",
    borderStyle:"dashed"
  },
  "& .chosefileStyle": {
    margin: "15px 0px 0px 0px",
    fontSize: "18px",
    textAlign: "center",
    fontFamily: "Poppins"
  },
  "& .browserStyle": {
    textDecoration: "underline",
    textAlign: "center",
    cursor: 'pointer',
    fontFamily: "Poppins"
  },
  "& .supportFormat": {
    textAlign: "center",
    color:"747474",
    fontSize:"14px",
    margin:"15px 0px",
    fontFamily: "Poppins"
  },
  "& .uploadImage": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "60px"
  },
  "& .imageText": {
    height: "100px",
    width: "100px",
    margin: "10px",
  },
  "& .progessBar": {
    flex: 1,
    margin: "10px",
  },
  "& .styleProgressBar": {
    height: "10px",
    borderRadius: "10px",
  },
  "& .cancleIcon": {
    color: "red",
  },
  "& .percentage": {
    color: "lightGreen",
    cursor: 'pointer'
  },
  "& .buttonStyle": {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px",
  },
  "& .cancleBtn": {
    margin: "10px",
    border: "1px solid #2b3e7d",
    textTransform: "capitalize",
    color: "#2b3e7d",
    fontSize: "15px",
    width: "120px",
  },
  "& .nxtBtn": {
    margin: "10px",
    fontSize: "15px",
    color: "white",
    backgroundColor: "#2b3e7d",
    width: "120px",
  },
});

const ModalFirstStyle = styled(Box)({
  marginRight: "40px",
  backgroundColor: "#f2f8ff",
  position: "relative",
  padding: "20px",
  borderRadius: "15px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height:"413px",
  width:"414px",
  "& .notificationHeading": {
    display: "flex",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  "& .uploadFile": {
    color: "#2B3D7D",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  "& .closeIcons": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .dragStyle": {
    border: "1px solid lightBlue",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "267px",
    borderStyle:"dashed"
  },
  "& .supportFormat": {
    textAlign: "center",
    color:"747474",
    fontSize:"14px",
    margin:"15px 0px",
    fontFamily: "Poppins"
  },
  "& .chosefileStyle": {
    margin: "15px 0px 0px 0px",
    fontSize: "18px",
    textAlign: "center",
    fontFamily: "Poppins"
  },
  "& .browserStyle": {
    textDecoration: "underline",
    textAlign: "center",
    fontFamily: "Poppins",
    cursor: 'pointer'
  },
});
const UnitBoxStyle = styled(Box)({
  padding: "6px",
  overflowY: "scroll",
  height: "545px",
  gridTemplateColumns : "repeat(3, minmax(0, 1fr))",
  display: "grid",
  gap: 20,
  "& .unitInfoBox": {
    width:"100%"
  },
  "& .unitPaperBox": {
    boxShadow: "0px 0px 10px 0px rgba(49,122,149,0.5)",
    height: "130px",
    display: "flex",
    gap: 20,
    cursor: "pointer",
    width: "360px",
    borderRadius: "8px",
    padding: "20px",
    
    "& .unitDummyImg": {
      borderRadius: "8px",
      objectFit: "cover",
      height:"100px"
    },
    "&.unitActive:hover": {
      cursor: "pointer",
    },
    "&.unitActive": {
      border: "1px solid #528e9c",
      background: "#ecf8fe",
    },
  },
  "& .unitInfoTitle": {
    fontWeight: 600,
    fontSize: "20px",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    color: "#2B3D7D",
    lineHeight: "32px",
    fontFamily: "Poppins"
  },
  "& .countTxt": {
    fontWeight: 400,
    color: "black",
    lineHeight: "50px",
    fontSize:"20px"
  },
  "& .countBox": {
    display: "flex",
    gap: 10,
    marginTop: "10px",
  },
  "& .personIcon": {
    color: "#2B3D7D",
    background: "#D6DFFF",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
  },
  "& .unitInfoTitleBox": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .threeDotsIcon": {
    fontSize: "24px",
    cursor: "pointer",
  },
});


const UnitNextBoxStyle = styled(Box)({
  display: "grid",
  gap: 20,
  padding: "6px",
  overflowY: "scroll",
  height: "545px",
  gridTemplateColumns : "repeat(3, minmax(0, 1fr))",
  "& .unitNextInfoTitle": {
    fontWeight: 600,
    fontSize: "20px",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    color: "#2B3D7D",
    lineHeight: "32px",
    fontFamily: "Poppins"
  },
  "& .countNextTxt": {
    fontWeight: 400,
    color: "black",
    lineHeight: "50px",
    fontSize:"20px"
  },
  "& .unitNextInfoBox": {
    width:"100%"
  },
  "& .unitNextPaperBox": {
    width: "360px",
    boxShadow: "0px 0px 10px 0px rgba(49,122,149,0.5)",
    height: "130px",
    display: "flex",
    gap: 20,
    cursor: "pointer",
    borderRadius: "8px",
    padding: "20px",
    "&.unitNextActive": {
      border: "1px solid #528e9c",
      background: "#ecf8fe",
    },
    "& .unitNextDummyImg": {
      borderRadius: "8px",
      objectFit: "cover",
      height:"100px"
    },
    "&.unitNextActive:hover": {
      cursor: "pointer",
    },
  },
  "& .threeNextDotsIcon": {
    fontSize: "24px",
    cursor: "pointer",
  },
  "& .countNextBox": {
    display: "flex",
    gap: 10,
    marginTop: "10px",
  },
  "& .personNextIcon": {
    color: "#2B3D7D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#D6DFFF",
    width: "30px",
    height: "30px",
    padding: "4px",
    borderRadius: "50px",
  },
  "& .unitNextInfoTitleBox": {
    display: "flex",
    justifyContent: "space-between",
  },
});
const ClassBoxStyle = styled(Box)({
  display: "grid",
  gap: 20,
  height: "545px",
  padding: "6px",
  overflowY: "scroll",
  gridTemplateColumns : "repeat(3, minmax(0, 1fr))",
  "& .classPaperBox": {
    height: "135px",
    width: "360px",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0px 0px 10px 0px rgba(49,122,149,0.5)",
    display: "flex",
    gap: 20,
    cursor: "pointer",
    "&.classActive": {
      border: "1px solid #528e9c",
      background: "#ecf8fe",
    },
    "&.classActive:hover": {
      cursor: "pointer",
    },
    "& .classDummyImg": {
      width: "110px",
      height: "99px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  "& .classInfoTitle": {
    color: "#282829",
    lineHeight: "32px",
    fontWeight: 600,
    fontSize: "20px",
    fontFamily:"Poppins"
  },
  "& .studentCntTxt": {
    fontWeight: 400,
    color: "#2B3D7D",
    lineHeight: "21px",
    fontFamily:"Poppins"
  },
  "& .studentCntBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    marginTop: "18px",
  },
  "& .personIcon": {
    color: "#2B3D7D",
    background: "#D6DFFF",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
  },
  "& .classInfoBox": {},
});

const NextButtonStyle: React.CSSProperties = {
  width: "150px",
  height: "52px", 
  borderRadius: "8px", 
  textAlign: "center",
  color: "white",
  fontFamily: "Poppins", 
  lineHeight: "16px",
  fontSize: "18px",
  letterSpacing: "0.11px", 
  textTransform: "capitalize" as "capitalize", 
  backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)"
};

const PreviousButton: React.CSSProperties = {
  width: "150",
  height: "52",
  border: "0.5px solid #3F526D",
  borderRadius: "8px",
  color: "#3F526D",
  fontFamily: "Poppins",
  lineHeight: "16px",
  fontSize: "18px",
  letterSpacing: "0.11px",
  textAlign: "center",
  textTransform: "capitalize",
}

const StylesWrapper = styled(Box)({
  "& .styles": {
    color: "pink",
  },
});
const ModalInnerTopBox = styled(Box)({
  padding: "25px 50px",
  display: "flex",
  flexDirection: "column",
  gap: "10px"
});
const ModalHeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
const ModelHeaderTitle = styled(Typography)({
  color: "black",
  fontSize: "18px",
  fontWeight: 600,
});
const ModelHeaderCloseIcon = styled(Box)({
  color: "black",
  fontSize: "16px",
  fontWeight: 500,
  cursor: "pointer",
});
const ModalTabMain = styled(Tabs)({
  border: "1px solid darkblue",
  borderRadius: "8px",
  width: "max-content",
  marginTop: "10px",
  padding: 0,
  minHeight: "0px",
  height: "40px",
});
const ModalTabs = styled(Tab)({
  color: "rgba(43,60,125,1)",
  opacity: 1,
  backgroundColor: "white",
  textTransform: "capitalize",
  width: "max-content",
  fontSize: "16px",
  fontWeight: 500,
  minWidth: "0px",
  borderRight: "1px solid darkblue",
  margin: 0,
  padding: "8px 18px",
  minHeight: "0px",
  height: "40px",
  fontFamily: "Poppins",
  "&.activeTab": {
    background: "linear-gradient(129deg, rgba(43,60,125,1) 68%, rgba(48,106,142,1) 95%)",
    color: "white",
    fontFamily: "Poppins"
  },
});
const ModalPaperMainContainer = styled(Box)({
  display: "flex",
  gap: 20,
  marginTop: "24px",
});
const PaperBox = styled(Box)({
  height: "204px",
  width: "183px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
  boxShadow: "0px 0px 10px 0px rgba(49,122,149,0.5)",
  "&.paperActive": {
    border: "1px solid #528e9c",
    background: "#ecf8fe",
  },
  "&.papperBox:hover": {
    cursor: "pointer",
  },
});
const PaperBoxText = styled(Typography)({
  fontWeight: 500,
  fontSize: "18px",
  fontFamily:"Poppins"
});

const ModalInnerBottomBox = styled(Box)({
  borderTop: "1px solid #f0f0f0",
  padding: "25px 30px",
  display: "flex",
  justifyContent: "flex-end",
  gap: 20,
  marginTop:"30px"
});
const IconBox = styled(Box)({
  "& .bookIcon": {
    width: "113px",
    height: "98px",
  },
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#00008B",
      contrastText: "#3173E1",
    },
    secondary: {
      main: "#121212",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const isNextButtonDisabled = (
  step: number,
  btnTab: number,
  selectedDrive: any,
  selectedSharedDrive: any,
  isChecked: boolean,
  fileFromatErr: boolean
) => (
  (btnTab !== 2 && !(step === 0 && (selectedDrive || selectedSharedDrive))) || 
  (btnTab === 2 && !isChecked) || 
  (btnTab === 2 && fileFromatErr)
);

const getCaptitalizedTitle = (selectedView:string | undefined): string => {
  const showType = selectedView ? selectedView.charAt(0).toUpperCase() + selectedView.substring(1).toLowerCase() : ""
  return showType
}

export const handlePrevButton = (step: number,handlePrevious: React.MouseEventHandler<HTMLButtonElement> | undefined,handleCancel: React.MouseEventHandler<HTMLButtonElement> | undefined) => {
    if (step === 4) {
      return (
        <Button style={PreviousButton} data-test-id="handleCancelBtn" onClick={handlePrevious}>
          Previous
        </Button>
      );
    }
    return (
      <Button style={PreviousButton} onClick={handleCancel}>
        Cancel
      </Button>
    );
}


export const renderActionButton = ({
  btnTab,
  importProps,
  nextProps
}: RenderActionButtonProps) => {
  if (btnTab === 2) {
    return ImportButtonTab(btnTab, importProps.isChecked, importProps.fileFromatErr, importProps.handleImport);
  }
  return NextButtonTab(
    nextProps.handleNext,
    nextProps.selectedDrive,
    nextProps.step,
    nextProps.selectedClass,
    nextProps.selectedUnit,
    nextProps.selectedLessonLibrary
  );
};


const ButtonsLayout = (props: Props) => {
  let config = require("../../../../../framework/src/config")
  const { handleActionChange, addMultiMediaStage, setMcqfile, setPollfile,setOpenEndedQuestionfile,selectedView,saveModalOpen,getStages,navigation,setIsPreviewClicked,stages, handleDoublePointActive, handleOpenEndedClick,handleMcqClick,handlePollClick } = props;
  const classes = useStyles();
  const mcqImageRef = React.useRef<any>();
  const importImageRef = React.useRef<any>();
  const uploadImageRef = React.useRef<any>();
  const textImageRef = React.useRef<any>();
  const multimediaImageRef = React.useRef<any>();
  const whiteboardImageRef = React.useRef<any>();
  const graphicImageRef = React.useRef<any>();
  const wordCloudImageRef = React.useRef<any>();
  const macthingImageRef = React.useRef<any>();
  const openEndedImageRef = React.useRef<any>();
  const [modalOpen, setModalOpen] = React.useState<any>();
  const [selectionModalOpen, setSelectionModalOpen] = React.useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [btnTab, setTab] = useState<number>(0);
  const [token, setToken] = useState("");
  const [step, setStep] = useState(0);
  const [selectedDrive, setSelectedDrive] = useState<string | null>(null);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [selectedAssignment, setSelectedAssignment] = useState<string | null>(null);
  const [dataName, setdataName] = useState<Class[]>([]);
  const [folderList, setFolderList] = useState<FolderList[]>([]);
  const [folderAssignmenrList, setAssignmentList] = useState<FolderList[]>([]);
  const [lessonListData, setLessonListData] = useState<FolderList[]>([])
  const [oldUserId, setOldId] = useState(0);
  const [teachId, setTeachId] = useState(0);
  const [assignUserId, setAssignOldId] = useState(0);
  const [assignTeachId, setAssignTeachId] = useState(0);
  const [selectedStage, setSelectedStage] = useState<{title:string,detail:string}>({title:"",detail:""});
  const [addingModal, setAddingModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [gDrivefile, setgDrivefile] = useState<File | null>(null);

  const [modalMessage, setModalMessage] = useState(false);
  
  const [selectedSharedDrive, setSelectedSharedDrive] = useState<string | null>(null);
  const [selectedLessonLibrary, setSelectedLessonLibrary] = useState<string | null>(null);
  const [lessonLibraryList, setLessonLibraryList] = useState<FolderList[]>([]);
  const [isLessonLibrary, setIsLessonLibrary] = useState<boolean>(false);
  const [isStageLibrary, setIsStageLibrary] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [importPressed, setImportPressed] = useState<boolean>(false);
  const [fileFromatErr, setFileFromatErr] = useState<boolean>(false);
  const [gdriveModal,setGDriveModal] = useState<boolean>(false);
  const [isLessonInURL, setIsLessonInURL] = useState(false);
  const [typeError, setTypeError] = useState<string | null>(null);
  const [musicON,setmusicON] = React.useState(false);

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes('LESSON')) {
      setIsLessonInURL(true);
    }
  }, []);

  useEffect(()=>{
    setmusicON(props.isMusic??false)
  },[props.isMusic])
  const changeMusic = (isMusic:boolean) => {
    setmusicON(isMusic)
    props.updateQuizMusic && props.updateQuizMusic(isMusic)
  }

  const getButtonText = () => (btnTab === 2 ? "Import" : "Next");


  const handleButtonClickInsideGsuite = (isAnyChecked: boolean, allFilesAreJson:boolean) => {
    
    setIsChecked(isAnyChecked);
    if (!allFilesAreJson){
      setFileFromatErr(true)
      return;
    }
    setFileFromatErr(false)
  };
    const [isQuiz,setIsQuiz] = useState<boolean>(false)
  const [isThisFolder, setIsThisFolder] = useState<boolean>(false);
  const [folderDataInfo, setFolderDataInfo] = useState<FolderList[]>([])


  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleAttachmentLesson = (unitObj: FolderList ) => {
    return unitObj?.attributes?.lesson_attachment ? unitObj?.attributes?.lesson_attachment : groupLessonIcon
  }
  useEffect(() => {
    renderContent();
  }, [step]);


  const handleAttacemnet = (unitObj: FolderList) =>{
    return unitObj?.attributes?.lesson_attachment ? unitObj?.attributes?.lesson_attachment : groupLessonIcon
  }

  useEffect(()=>{
    teacherId()
  },[])

  useEffect(()=>{
    assignmentId()
  },[])

  const fetchSharedList = async () => {
    let baseURL = `${config.baseURL}/bx_block_content_management/shared_libraries/index_shared_resources`
    const response = await fetch(baseURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token
        }
      });
      const result = await response.json();
      if(selectedSharedDrive == "lessons"){
        setLessonLibraryList(result.lessons.data)
      }else{
        setLessonLibraryList(result.assignments.data)
      }
      setIsLessonLibrary(true)
      return result;
     };


  const teacherId = async () => {
    let newteacherId = await StorageProvider.get("teacherId")
    let oldLessonId = await StorageProvider.get("oldLessonId")
    setOldId(oldLessonId)
    setTeachId(newteacherId)
  }

const openDrive = () =>{
  setIsOpen(false)
  setModalMessage(false);
  setIsStageLibrary(false)
  handleCloseSelectionModal();
  handleCloseModal();
  setIsChecked(false)
  setFileFromatErr(false) 
}

const onCloseLesson = () => {
  setIsOpen(false)
  handleCloseSelectionModal();
  handleCloseModal();
  setGDriveModal(false)
  setIsChecked(false)
  setFileFromatErr(false) 
}


const assignmentId = async () => {
  let assignmentteacherId =  await StorageProvider.get("assignmentteacherId")
  let assignmentoldLessonId = await StorageProvider.get("assignmentoldLessonId")
  setAssignOldId(assignmentoldLessonId)
  setAssignTeachId(assignmentteacherId)
}

  const handleDriveSelection = (drive: string) => {
    setSelectedDrive(drive);
  };

  const handleSharedDrive = (drive: string) => {
    setSelectedSharedDrive(drive)
  }

  const handleClassSelection = (className: string, classObj : any) => {
    setSelectedClass(className);
    folderData(classObj)
  };

  const handleUnitSelection = (unit: string, unitObj: FolderList, title: string) => {
    fetchImportApi(unit)
    if(unitObj.type.includes("folder")){
      setIsThisFolder(true)
      if (selectedDrive === "lessons") {
        setFolderDataInfo(unitObj?.attributes?.lessons?.data)
        setSelectedStage({title:title,detail:unitObj?.attributes?.lesson_name})
        }else{
        setFolderDataInfo(unitObj?.attributes?.assignments?.data)
        setSelectedStage({title:title,detail:unitObj?.attributes?.assignment_name})
        }
    }else{
      setIsThisFolder(false)
      if (selectedDrive === "lessons") {
        setSelectedStage({title:title,detail:unitObj?.attributes?.lesson_name})
        }else{
        setSelectedStage({title:title,detail:unitObj?.attributes?.assignment_name})
        }
    }
    setSelectedUnit(unit);
    setSelectedLessonLibrary(unit);
    if (selectedDrive === "lessons" || selectedSharedDrive === "lessons") {
      setSelectedStage({ title: title, detail: unitObj?.attributes?.lesson_name })
    } else {
      setSelectedStage({ title: title, detail: unitObj?.attributes?.assignment_name })
    }
  };

  const handleNext = () => {
    if(step===2 && !isThisFolder){
      setStep(4)
    }else{
      setStep(step + 1);
    }
    if (selectedDrive === "lessons") {
      getStages(oldUserId, COURSES_PAGES.LESSON)
    } else {
      getStages(assignUserId, COURSES_PAGES.ASSIGNMENT)
    }
  };

  const handleImport = () => {
    setImportPressed(true)
  }

  const handleSharedNext = () => {
      if (selectedSharedDrive === "lessons") {
        getStages(oldUserId, COURSES_PAGES.LESSON)
      } else {
        getStages(assignUserId, COURSES_PAGES.ASSIGNMENT)
      }
      setIsLessonLibrary(false)
      setIsOpen(false)
      setIsStageLibrary(true)
    }

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handelModal = () => {
    setIsOpen(!isOpen);
    setStep(0);
    openDrive()
    setFolderList([])
    setAssignmentList([])
    setLessonListData([])
    setIsOpen(false);
    setStep(0);
    setSelectedDrive(null);
    setSelectedClass(null);
    setSelectedUnit(null);
    setSelectedAssignment(null);
    setSelectedSharedDrive(null);
    setSelectedLessonLibrary(null);
    setIsLessonLibrary(false)
  };

  const handleCancel = () => {
    setIsOpen(false);
    setStep(0);
    setSelectedDrive(null);
    setSelectedClass(null);
    setSelectedUnit(null);
    setSelectedAssignment(null);
    setSelectedSharedDrive(null);
    setSelectedLessonLibrary(null);
    setIsLessonLibrary(false)
    setFolderList([])
    setAssignmentList([])
    setLessonListData([])
  };

  const fetchImportApi = async (dataID : string) => {
    if (selectedDrive === "lessons"|| selectedSharedDrive === "lessons") {
      let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/lesson_markups/import_lesson`;
      const requestBody = {
        teacher_class_id: teachId,
        lesson_id: dataID,
        new_lesson_id: oldUserId
      };

      const response = await fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token
        },
        body: JSON.stringify(requestBody)
      });

      const result = await response.json();
      return result;
    } else {
      let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/assignment_markups/import_assignment`;
      const requestBody = {
        teacher_class_id: assignTeachId,
        assignemnt_id: dataID,
        new_assignemnt_id: assignUserId
      };

      const response = await fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token
        },
        body: JSON.stringify(requestBody)
      });

      const result = await response.json();
      return result;
    }
    
  };


  const renderSharedHeader = () => {
    return(
    <>
      <ModalHeaderBox>
        <ModelHeaderTitle>Import</ModelHeaderTitle>
        <ModelHeaderCloseIcon>
          <CloseIcon data-test-id="openDriveTestId" onClick={openDrive} />
        </ModelHeaderCloseIcon>
      </ModalHeaderBox>
      <ModalTabMain indicatorColor="primary" textColor="inherit" value={btnTab} onChange={handleTabChange}>
        <ModalTabs label="My Library" className={btnTab === 0 ? "activeTab" : ""} />
        <ModalTabs label="Shared Library" className={btnTab === 1 ? "activeTab" : ""} />
        <ModalTabs label="Go to Drive" className={btnTab === 2 ? "activeTab" : ""} />
      </ModalTabMain>
    </>
    )
  }

  const renderSharedLessonsList = () => {
    return (
      <ModalInnerBox1>
        <ModalInnerTopBox>
          {renderSharedHeader()}
          <UnitBoxStyle>
            {lessonLibraryList.map((item: FolderList, indexLibrary: number) => (
              <Box data-test-id=""
                className={`unitPaperBox ${selectedLessonLibrary !== null && selectedLessonLibrary === item.id ? "unitActive" : ""}`} 
                key={indexLibrary}
                onClick={() => handleUnitSelection(item.id,item,selectedDrive === 
                "lessons" ?`Lesson ${indexLibrary+1}`:`Assignment ${indexLibrary+1}`)}>
                <Box>
                  <img src={selectedDrive === "lessons" ? lessonBtn : assignmentBtn} alt="" className="unitDummyImg" />
                </Box>
                <Box className="unitInfoBox">
                  <Box className="unitInfoTitleBox">
                    <Box className="countBox">
                      <Box>
                        <PersonOutlineIcon className="personIcon" />
                      </Box>
                      {selectedSharedDrive==="lessons"?
                        <Typography className="unitInfoTitle">Lesson {indexLibrary + 1} </Typography> :
                        <Typography className="unitInfoTitle">Assignments {indexLibrary + 1} </Typography> }
                    </Box>
                    <Box>
                    </Box>
                  </Box> 
                  {selectedSharedDrive==="lessons"?
                    <Typography className="countTxt">{item.attributes.lesson_name}</Typography>:
                    <Typography className="countTxt">{item.attributes.assignment_name}</Typography>}
                </Box>
              </Box>
            ))}
          </UnitBoxStyle>
          {renderBottomShared()}
        </ModalInnerTopBox>
        </ModalInnerBox1>
    )
  };


  const renderBottomShared = () => {
    return (
      <ModalInnerBottomBox>
        <Button style={PreviousButton} data-test-id="" onClick={handleCancel}>Cancel</Button>
        <Button data-test-id="handleSharedNextTestId"
          style={NextButtonStyle}
          onClick={handleSharedNext}
          disabled={selectedLessonLibrary === null ? true : false}
        >
          Next
        </Button>
      </ModalInnerBottomBox>
    )
  }

  
  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            {renderDriveSelectionBoxes(selectedDrive,selectedView, handleDriveSelection)}
          </>
        );
      case 1:
        return (
          <>
             {renderClassBoxes(dataName, selectedClass, handleClassSelection)}
          </>
        );
      case 2:
        return (
          <UnitBoxStyle>
            {selectedDrive === "lessons" ?
              renderLessonUnits(folderList, selectedUnit, handleUnitSelection) :
              renderAssignmentUnits(folderAssignmenrList, selectedUnit, handleUnitSelection)
            }
            {renderLessonOrAssignmentUnits(selectedDrive, lessonListData, selectedUnit, handleUnitSelection,handleAttachmentLesson)}
          </UnitBoxStyle>
        );
        case 3:
        return(
          <UnitNextBoxStyle>
            {renderFolderData(lessonListData,selectedUnit,selectedDrive,folderDataInfo,handleUnitSelection,handleAttacemnet)}
          </UnitNextBoxStyle>
        )
      default:
        return null;
    }
  };
  const renderMyLibrary = () => <ModalPaperMainContainer>{renderContent()}</ModalPaperMainContainer>;


  const handleAddingModal = () => {
    setAddingModal(!addingModal);
  };

  const handleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;

    if (selectedFile && isValidJsonFile(selectedFile)) {
      setFile(selectedFile);
      processFile(selectedFile);
    } else {
      alert("Please upload a valid JSON file.");
    }
  
    event.target.value = "";
  };

  const isValidJsonFile = (file: File): boolean => {
    return file.type === "application/json" || file.name.endsWith('.json');
  };
  
  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        handleFileContent(event.target.result);
      }
    };
    reader.readAsText(file);
  };
  
  const handleFileContent = (fileContent: string) => {
    setUploadModal(true);
    const jsonData = JSON.parse(fileContent);
    const jsonType = jsonData.type?.toLowerCase();
    const currentUrl = window.location.href.toLowerCase();  
    validateJsonType(jsonType, currentUrl);
  };
  
  const validateJsonType = (jsonType: string, currentUrl: string) => {
    const typeUrlMap: { [key: string]: string } = {
      "assignments": "assignment",
      "lesson": "lesson",
      "quizzes": "quiz"
    };
  
    const singularJsonType = typeUrlMap[jsonType] || jsonType;
    const extractedWord = extractWordFromUrl(currentUrl);
  
    if (jsonType && currentUrl.includes(typeUrlMap[jsonType])) {
      setTypeError(null);
    } else {
      setTypeError(`You can't upload ${singularJsonType} file to ${extractedWord}. Please upload a valid ${extractedWord} file.`);
    }
  };
  
  const extractWordFromUrl = (url: string): string => {
    const urlParts = url.split('/');
    return urlParts[4];
  };

  const handleDeleteItem = () => {
    setFile(null);
    setUploadModal(false);
  };

  const addingModalRendering = () => {
    return (
      <>
        <ModalFirstStyle data-test-id="dropFunctionTestIf">
          <Box className="closeIcons">
            <CloseIcon onClick={handleAddingModal} />
          </Box>
          <Box className="notificationHeading">
            <Typography className="uploadFile">Upload Files</Typography>
          </Box>
          <Grid container spacing={3} style={{display:"flex", justifyContent:"center"}}>
            <Grid item xs={12} sm={6} md={6} lg={6} style={{maxWidth:"100%",flexBasis:"auto"}}>
              <Box
                data-test-id="handleDropTestId"
                className="dragStyle"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <img
                  src={imageUpload}
                />
                <label htmlFor="fileInput" className="uploads">
                  <Typography className="chosefileStyle">
                    Drag & Drop files or{" "}
                    <span className="browserStyle">Browse</span>
                  </Typography>
                  <Typography className="supportFormat">
                    Supported format JSON
                  </Typography>
                </label>
                <input
                  data-test-id="handleFileChangeTestId"
                  id="fileInput"
                  type="file"
                  accept=".json,application/json"
                  onChange={handleFileChange}
                  multiple 
                  style={{ display: "none" }}
                />
              </Box>
            </Grid>
          </Grid>
        </ModalFirstStyle>
      </>
    );
  };

  const uploadedDocumentRender = () => {
    return (
      <>
        <UploadModalStyle>
          <Box className="closeIcons">
            <CloseIcon onClick={handleUploadModal} />
          </Box>
          <Box className="notificationHeading">
            <Typography className="uploadFile">Upload Files</Typography>
          </Box>
          <Box
            className="dragStyle"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <img
              src={imageUpload}
            />
            <label htmlFor="fileInput" className="uploads">
              <Typography className="chosefileStyle">
                Drag & Drop files or{" "}
                <span className="browserStyle">Browser</span>
              </Typography>
              <Typography className="supportFormat">
                Supported format JSON
              </Typography>
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".json,application/json"
              onChange={handleFileChange}
              multiple 
              style={{ display: "none" }}
            />
          </Box>

          {file ? (
            <Box className="uploadImage">
              <img src={importLesson} className="imageText"/>
              <Box className="progessBar">
                <Typography style={{marginBottom:"17px"}}>{file.name}</Typography>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={100}
                  className="styleProgressBar"
                />
              </Box>
              <Box style={{marginTop:"15px",cursor: 'pointer'}}>
              <img src={redCross} style={{marginLeft:"15px"}}
                  data-test-id = "handleDeleteItemTestId"
                  onClick={handleDeleteItem}
                />
                <Typography className="percentage">100%</Typography>
              </Box>
            </Box>
          ) : (
            ""
          )}
          {!!typeError && (
                <Typography 
                data-test-id = "formatErr"
                style={{ 
                  color: 'red',
                  marginBottom: '60px', 
                  textAlign: "center", 
                  fontFamily:"Poppins" 
                  }}>
                  {typeError}
                </Typography>
              )}
            <Box className={classes.btnLayout}>
          <GenericButton
           data-test-id="cancelApiTest"
            type="NORMAL"
            label="Cancel"
            handleClick={handleUploadModal}
            customStyle={{ width: "150px" }}
          />
          <GenericButton
            disabled={!!typeError}
            data-test-id="uploadApiTest"
            type="COLORED"
            label="Next"
            handleClick={()=>fetchUploadApi(file)}
            customStyle={{ width: "150px" }}
          />
        </Box>
        </UploadModalStyle>
      </>
    );
  };

  const fetchUploadApi = async (file: File | null) => {
    if(selectedView == "LESSON"){
    let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/lesson_markups/upload_json`;
    const formData = new FormData();
    if (file) {
      formData.append("json_files[]", file);
    }
    formData.append("lesson_id", oldUserId.toString());
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        token
      },
      body: formData
    });
    getStages(oldUserId, COURSES_PAGES.LESSON)
    setAddingModal(false)
    setUploadModal(false)
    setModalMessage(true)

    const result = await response.json();
    return result
  }else{
    let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/assignment_markups/upload_assignment_json`;
    const formData = new FormData();
    if (file) {
      formData.append("json_files[]", file);
    }
    formData.append("assignment_id", assignUserId.toString());
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        token
      },
      body: formData
    });

    setAddingModal(false)
    setUploadModal(false)
    setModalMessage(true)
    getStages(assignUserId, COURSES_PAGES.ASSIGNMENT)

    const result = await response.json();
    return result
  }

  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
    setUploadModal(true);
  };



  const callToken = async () => {
    let tokenVal = await getStorageData("authToken");
    setToken(tokenVal);
  }

  const folderData = async (assignmentFolderdata : AssignmentFolderData) => {
    if(selectedDrive==="lessons"){
      let baseURL = `${config.baseURL}/bx_block_content_management/lessons/lesson_folder_list?class_id=${assignmentFolderdata.id}&teacher_id=${assignmentFolderdata.attributes?.teacher_id}`
      const response = await fetch(baseURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token
        }
      });
      const result = await response.json();
      setFolderList(result.lesson_folders.data || []);
      setLessonListData(result.lessons.data || [])
      return result;
  }else{
    let baseURL = `${config.baseURL}/bx_block_content_management/assignments/assignment_folder_list?class_id=${assignmentFolderdata.id}&teacher_id=${assignmentFolderdata.attributes?.teacher_id}`
    const response = await fetch(baseURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token
      }
    });
    const result = await response.json();
    setAssignmentList(result.assignment_folders.data || []);
    setLessonListData(result.assignments.data || [])
    return result;
  }
  };

  const renderSharedLibrary = () => <ModalPaperMainContainer>{renderLessonSharedLibrary(selectedSharedDrive,selectedView,handleSharedDrive)}</ModalPaperMainContainer>;

  useEffect(()=>{
    pageType()
    callToken();
  },[])

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const pageType = async () => {
    setIsQuiz(selectedView == COURSES_PAGES.QUIZ)
  }

  
  const getFilesGdrive = async (file: File | null) => { 
    if(selectedView == "LESSON"){
   
    let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/lesson_markups/upload_json`;
    const formData = new FormData();
    if (file) {
      setgDrivefile(file)
      
      formData.append("json_files[]", file);
    }
    formData.append("lesson_id", oldUserId.toString());
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        token
      },
      body: formData
    });
    getStages(oldUserId, COURSES_PAGES.LESSON)
    setAddingModal(false)
    setGDriveModal(true)
    setUploadModal(false)

    const result = await response.json();
    return result
  }else{
    let baseURL = `${config.baseURL}/bx_block_cfcoursecreation23/assignment_markups/upload_assignment_json`;
    const formData = new FormData();
    if (file) {
      formData.append("json_files[]", file);
    }
    formData.append("assignment_id", assignUserId.toString());
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        token
      },
      body: formData
    });

    setAddingModal(false)
    setGDriveModal(true)
    setUploadModal(false)
    getStages(assignUserId, COURSES_PAGES.ASSIGNMENT)

    const result = await response.json();
    return result
  }
  }
  

  const handleCloseModal = () => {
    setModalOpen(false);
  }
  const [openGraphicModal, setOpenGraphicModal] =
    React.useState<boolean>(false);
  const [openStudentCountModal, setOpenStudentCountModal] =
    React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<any>({});
  const handleOpenGraphicModal = () => {
    setOpenGraphicModal(true);
  };
  const handleCloseGraphicModal = () => {
    setOpenGraphicModal(false);
  };

  const handleOpenStudentCountModal = (data: any) => {
    setSelectedOption(data);
    setOpenStudentCountModal(true);
  };
  const handleCloseStudentCountModal = () => {
    setOpenStudentCountModal(false);
  };

  const handleOpenSelectionModal = () => {
    handleMcqClick({
      checkedA: false,
      checkedB: false,
      checkedC: false
    })
    handlePollClick({
      checkedA: false,
      checkedB: false,
      checkedC: false
    })
    handleDoublePointActive(false)
    setSelectionModalOpen(true);
  };

  const handleCloseSelectionModal = () => {
    setSelectionModalOpen(false);
  };

  const handleOpenEndedQuestion= () => {
    handleDoublePointActive(false)
    handleActionChange(ACTIONS.OPEN_ENDED_QUESTION); 
    setOpenEndedQuestionfile(null);
    handleOpenEndedClick({
      checkedA: false,
      checkedB: false,
      checkedC: false
    })
  }

  const handlegetStages = () => {
    setModalMessage(false)
  }

  const handleNextApi = () => {
    if(btnTab == 0){
      fetchData(setStep,step,token,setdataName)
    }else if (btnTab == 1){
      fetchSharedList()
    }else if (btnTab == 2){
      setImportPressed(true)
    }
  }

  const previewStage = () => {
    setIsPreviewClicked(true)
  }

      
  return (
    <Box sx={{ mr: "15px" }}>
      <Box sx={{ mb: "15px", mt: "90px" }} className={classes.btnColor}>
        <Button
         data-test-id = "previewStageTestId"
          variant="outlined"
          className={classes.finalBtns}
          onClick={previewStage}
          fullWidth
          disabled={stages?.length == 0}
        >
          <img src={previewImage} className={classes.image} />
          {`Preview ${getCaptitalizedTitle(selectedView)}`}
        </Button>
        <Button
          variant="outlined"
          className={classes.finalBtns}
          onClick={saveModalOpen}
          data-test-id="saveModalOpen"
          fullWidth
        >
          <img src={saveImage} className={classes.image} />
         {`Save ${getCaptitalizedTitle(selectedView)}`}
        </Button> 
        {isQuiz &&
        <div className={classes.musicSwitchLayout}>
          <em>
            <img src={ blueMusicBtn } />
            Music
          </em>
          <div className="switch-wrapper">
            <Switch name="checkedA"
                checked={musicON} 
                onChange={event=>changeMusic(event.target.checked)}
                data-test-id="switchMusic"
              />
          </div>
        </div>}
      </Box>
      
      <Box className={classes.optionsLayout}>
      {!isQuiz && 
        <Button
          data-test-id= "handleModalClose"
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) => (importImageRef.current.src = importImageActive)}
          onMouseLeave={(e) => (importImageRef.current.src = importImage)}
          onClick={()=>handleModal(isOpen,setIsOpen,handleActionChange)}  
        >
          <img
            ref={importImageRef}
            src={importImage}
            className={classes.image}
          />
          Import
        </Button>}
        {!isQuiz &&
        <Button
          data-test-id = "uploadTestId"
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) => (uploadImageRef.current.src = uploadImageActive)}
          onMouseLeave={(e) => (uploadImageRef.current.src = uploadImage)}
          onClick={handleAddingModal}
        >
          <img
            ref={uploadImageRef}
            src={uploadImage}
            className={classes.image}
          />
          Upload
        </Button>}
        {!isQuiz &&
        <Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) => (textImageRef.current.src = textImageActive)}
          onMouseLeave={(e) => (textImageRef.current.src = textImage)}
          onClick={() => handleActionChange(ACTIONS.ADD_TEXT)}
          data-testId="addTextBtn"
        >
          <img ref={textImageRef} src={textImage} className={classes.image} />
          Text
        </Button>}
        {!isQuiz &&<Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) =>
            (multimediaImageRef.current.src = multimediaImageActive)
          }
          onMouseLeave={(e) => (multimediaImageRef.current.src = multimediaImage)}
          onClick={handleOpenModal}
        >
          <img
            ref={multimediaImageRef}
            src={multimediaImage}
            className={classes.image}
          />
          Multimedia
        </Button>}
        {!isQuiz &&<Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) =>
            (wordCloudImageRef.current.src = wordcloudImageActive)
          }
          onMouseLeave={(e) => (wordCloudImageRef.current.src = wordcloudImage)}
          onClick={() => handleActionChange(ACTIONS.ADD_WORD_CLOUD)}
        >
          <img
            ref={wordCloudImageRef}
            src={wordcloudImage}
            className={classes.image}
          />
          Word Cloud
        </Button>}
        <Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) =>
            (openEndedImageRef.current.src = openEndedImageActive)
          }
          onMouseLeave={(e) => (openEndedImageRef.current.src = openEndedImage)}
          data-test-id="open-ended-question"
          onClick={handleOpenEndedQuestion}
        >
          <img
            ref={openEndedImageRef}
            src={openEndedImage}
            className={classes.image}
          />
          Open Question
        </Button>
        <Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) => (mcqImageRef.current.src = mcqImageActive)}
          onMouseLeave={(e) => (mcqImageRef.current.src = mcqImage)}
          onClick={handleOpenSelectionModal}
        >
          <img ref={mcqImageRef} src={mcqImage} className={classes.image} />
          Poll
          <br/>Multiple-Choice
        </Button>
        <Button
          variant="outlined"
          data-test-id="matching-q-button"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) =>
            (macthingImageRef.current.src = matchingImageActive)
          }
          onMouseLeave={(e) => (macthingImageRef.current.src = matchingImage)}
          onClick={() => handleActionChange(ACTIONS.ADD_MATCHING_Q)}
        >
          <img
            ref={macthingImageRef}
            src={matchingImage}
            className={classes.image}
          />
          Matching Question
        </Button>

        {!isQuiz &&<Button
          variant="outlined"
          className={classes.normalBtn}
          fullWidth
          onMouseEnter={(e) =>
            (graphicImageRef.current.src = graphicImageActive)
          }
          onMouseLeave={(e) => (graphicImageRef.current.src = graphicImage)}
          onClick={() => handleOpenGraphicModal()}
          data-test-id="graphic-organizers"
        >
          <img
            ref={graphicImageRef}
            src={graphicImage}
            className={classes.image}
          />
          Graphic Organizers
        </Button>}

    
      
      
      </Box>
      <MultimediaModal open={modalOpen} onClose={handleCloseModal} addMultiMediaStage={addMultiMediaStage} selectedView={selectedView}/>
      <AddGraphicModal
        data-test-id="choose-graphic-modal"
        openGraphicModal={openGraphicModal}
        handleCloseGraphicModal={handleCloseGraphicModal}
        handleOpenStudentCountModal={handleOpenStudentCountModal}
        handleActionChange={handleActionChange}
        selectedView={selectedView}
      />
      <StudentCountModal
        data-test-id="student-count-modal"
        openStudentCountModal={openStudentCountModal}
        handleCloseStudentCountModal={handleCloseStudentCountModal}
        selectedOption={selectedOption}
        handleActionChange={handleActionChange}
      />
      <QuestionTypeSelectionModal open={selectionModalOpen} onClose={handleCloseSelectionModal} handleActionChange={handleActionChange} setMcqfile={setMcqfile} setPollfile={setPollfile} />
         <ThemeProvider theme={theme}>
      <StylesWrapper>
        {step < 4 ? (
          <CustomModal open={isOpen} onClose={openDrive} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            {step === 0 ? (
              <ModalInnerBox>
                <ModalInnerTopBox>
                  <ModalHeaderBox>
                    <ModelHeaderTitle>Import</ModelHeaderTitle>
                    <ModelHeaderCloseIcon>
                      <CloseIcon onClick={openDrive} />
                    </ModelHeaderCloseIcon>
                  </ModalHeaderBox>
                  {SelectTab(
                  { btnTab, handleTabChange },
                  { renderMyLibrary, renderSharedLibrary },
                  { navigation, handleButtonClickInsideGsuite },
                  { importPressed, setImportPressed, fileFromatErr, getFilesGdrive }
                )}                
                </ModalInnerTopBox>
                <ModalInnerBottomBox>
                  <Button style={PreviousButton} data-test-id= "handleCancelBtn" onClick={handleCancel}>Cancel</Button>
                    <Button data-test-id="buttonClickTestId"
                      style={NextButtonStyle}
                      onClick={handleNextApi}
                      disabled={isNextButtonDisabled(step, btnTab, selectedDrive, selectedSharedDrive, isChecked, fileFromatErr)}
                    >
                     {getButtonText()}
                     </Button>
                </ModalInnerBottomBox>
              </ModalInnerBox>
            ) : (
              <ModalInnerBox1 style={{width: btnTab === 2 ? "900px": ""}}>
                <ModalInnerTopBox>
                  <ModalHeaderBox>
                    <ModelHeaderTitle>Import</ModelHeaderTitle>
                    <ModelHeaderCloseIcon>
                      <CloseIcon onClick={openDrive} />
                    </ModelHeaderCloseIcon>
                  </ModalHeaderBox>

                  {SelectTab(
                  { btnTab, handleTabChange },
                  { renderMyLibrary, renderSharedLibrary },
                  { navigation, handleButtonClickInsideGsuite },
                  { importPressed, setImportPressed, fileFromatErr, getFilesGdrive }
                )}               
                 </ModalInnerTopBox>
                <ModalInnerBottomBox1>
                {handlePrevButton(step,handlePrevious,handleCancel)}
                {renderActionButton({
                    btnTab,
                    importProps: {
                      isChecked,
                      fileFromatErr,
                      handleImport
                    },
                    nextProps: {
                      handleNext,
                      selectedDrive,
                      step,
                      selectedClass,
                      selectedUnit,
                      selectedLessonLibrary
                    }
                  })}               
                </ModalInnerBottomBox1>
              </ModalInnerBox1>
            )}
          </CustomModal>
        ) : (
          <CustomModal open={isOpen} onClose={openDrive} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            {AssignmentData(handelModal,selectedDrive,selectedStage)}
          </CustomModal>
        )}
      </StylesWrapper>
    </ThemeProvider>
      <Modal
        open={addingModal}
        onClose={handleAddingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {addingModalRendering()}
      </Modal>
      <Modal
        open={uploadModal}
        onClose={handleUploadModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {uploadedDocumentRender()}
      </Modal>
      <CustomModal data-test-id ="closModalTestId" open={modalMessage} onClose={openDrive} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
       <AssignmentImportedModal>
        <ModalHeaderBox>
          <ModelHeaderTitle></ModelHeaderTitle>
          <ModelHeaderCloseIcon>
            <CloseIcon data-test-id="handlegetStagesId" onClick={handlegetStages} />
          </ModelHeaderCloseIcon>
        </ModalHeaderBox>
        {renderUploadedJson(file,selectedView)}
      </AssignmentImportedModal>
      </CustomModal>

      <CustomModal data-test-id ="closeGdriveModal" open={gdriveModal} onClose={onCloseLesson} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
       <AssignmentImportedModal>
        <ModalHeaderBox>
          <ModelHeaderTitle></ModelHeaderTitle>
          <ModelHeaderCloseIcon>
            <CloseIcon data-test-id="" onClick={onCloseLesson} />
          </ModelHeaderCloseIcon>
        </ModalHeaderBox>
        <Box className="flexCenterBox">
          <img src={importLesson} className="assignmentImportedIcon" />
          <Typography className="importedTxt">{isLessonInURL ? "Lesson Imported!":"Assignment Imported!"}</Typography>
          {gDrivefile && <Typography className="importedDescriptionTxt">Your file named "{gDrivefile.name}" has been successfully Imported</Typography>}
        </Box>
      </AssignmentImportedModal>
      </CustomModal>


    <CustomModal open={isLessonLibrary} onClose={openDrive} >
        {renderSharedLessonsList()}
      </CustomModal> 
      <CustomModal open={isStageLibrary} onClose={openDrive}>
            {AssignmentData(handelModal,selectedSharedDrive,selectedStage)}
      </CustomModal>
    </Box>
  );
};



export default ButtonsLayout;
