import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  loading: boolean;
  color?:string;
  top?:string;
  left?:string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "60%",
    top: "50%",
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '20%',
      position:'inherit'
    },
  },
  loaderColor: {
    color: "#2B3D7D",
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
      <div className={classes.circularContainer} style={{top:props.top, left:props.left}}>
        <CircularProgress className={classes.loaderColor} style={{color:props.color,}} />
      </div>
  ) : (
    <div />
  );
}
