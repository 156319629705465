import React from "react";
import { Box, Modal, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
export const configJSON = require("../../config");
import { info } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  crossLayout: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2C2C2E",
    fontWeight: 600,
    marginBottom: "25px",
  },
  paper: {
    position: "absolute",
    width: "400px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    padding: "20px 30px",
  },
  container: {
    position: "relative",
    width: "100%",
  },
  optionItem: {
    width: "max-content",
    boxSizing: "border-box",
    padding: "15px",
    textAlign: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginBottom: "45px",
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },
  optionName: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "10px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#282829",
    marginTop: "10px",
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: 600,
    color: "#2C2C2E",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  errorLayout: {
    display: "flex",
    marginTop: "10px",
  },
}));
const StudentCountModal = (props: any) => {
  const {
    openStudentCountModal,
    handleCloseStudentCountModal,
    selectedOption,
    handleActionChange,
  } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [studentNumber, setStudentNumber] = React.useState<number>();
  const [error, setError] = React.useState<string>("");
  const handleChange = (event: any) => {
    const onlyNums = event.target.value.replace(/\D/g, "");
    const studentNo = parseInt(onlyNums, 10);

    if (!isNaN(studentNo)) {
      setStudentNumber(studentNo);
    } else {
      setStudentNumber(undefined);
    }
    if (studentNo > 50) {
      setError("Can't create KWL chart for students more than 50");
    } else {
      setError("");
    }
  };
  const isMandatoryFieldsPresent = () => {
    if (!studentNumber) {
      setError("Student number can't be empty");
      return false;
    } else {
      return true;
    }
  };
  const handleNext = () => {
    if (!isMandatoryFieldsPresent()) return;
    if(error) return
    handleCloseStudentCountModal();
    handleActionChange(selectedOption.actionName);
    setStudentNumber(undefined);
  };
  return (
    <Modal open={openStudentCountModal} onClose={handleCloseStudentCountModal}>
      <Box style={modalStyle} className={classes.paper}>
        <Box className={classes.container}>
          <Box className={classes.popupContent}>
            <Box className={classes.crossLayout}>
              <ClearIcon
                onClick={handleCloseStudentCountModal}
                data-test-id="close-modal"
              />
            </Box>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {configJSON.studentCountTitle}
              </Typography>
              <Box className={classes.optionItem}>
                <img
                  src={selectedOption.image}
                  style={{ marginBottom: "10px" }}
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography className={classes.optionName}>
                    {selectedOption.name}
                  </Typography>
                  <img src={info} />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mb: "50px" }}>
              <label htmlFor="studentNumber" className={classes.inputLabel}>
                Number of Students
              </label>
              <TextField
                id="studentNumber"
                name="studentNumber"
                data-test-id={"student-number"}
                fullWidth
                placeholder="Enter number of students"
                variant="outlined"
                onChange={handleChange}
                value={studentNumber}
                InputProps={{
                  className: classes.inputValue,
                }}
              />
              {Boolean(error) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `${error}`,
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: "40px" }}>
              <GenericButton
                data-test-id="next-btn"
                type="COLORED"
                label="Next"
                handleClick={(e) => handleNext()}
                customStyle={{ width: "150px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StudentCountModal;
