import React from "react";
import { Roles } from "../../../components/src/types";
import SchoolAdminUserGroups from "./SchoolAdmin/SchoolAdminUserGroups.web";
import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController";
import TeacherUserGroups from "./Teacher/TeacherUserGroups.web";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.SUB_ADMIN) {
      return (
        <SchoolAdminUserGroups
          navigation={this.isPlatformWeb() ? null : this.props.navigation}
          id={"Dashboard"}
        />
      );
    }
    if (role === Roles.TEACHER) {
      return (
        <TeacherUserGroups
        navigation={this.isPlatformWeb() ? null : this.props.navigation}
        id={"Dashboard"}
        />
      );
    }
  }
  render() {
    return <>{this.renderViewBasedOnRole(this.state.role)}</>;
  }
}
export default EmailAccountRegistration;
