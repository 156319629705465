import React from "react";
import {
  Box,
  Typography,
  Modal,
  TextField,
  FormControl,
  Grid,
  NativeSelect,
  InputBase,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { getModalStyle } from "../../../../../blocks/ContentManagement/src/Teacher/components/utils";

const BootstrapInputInactive = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #C0C0C0",
      fontSize: "18px",
      fontFamily: "Poppins",
      padding: "10px 26px 10px 12px",
      minHeight: "35px",
      color: "#C0C0C0",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#2B3D7D",
        backgroundColor: "unset",
      },
    },
  })
)(InputBase);

const BootstrapInputActive = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #C0C0C0",
      fontSize: "18px",
      fontFamily: "Poppins",
      padding: "10px 26px 10px 12px",
      minHeight: "35px",
      color: "currentColor",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#2B3D7D",
        backgroundColor: "unset",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2C2C2E",
    fontWeight: 600,
    marginBottom: "40px",
  },
  image: {
    marginBottom: "15px",
  },
  paper: {
    position: "absolute",
    width: "760px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },
  crossIcon: {
    position: "absolute",
    top: "30px",
    right: "20px",
    cursor:"pointer"
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    padding: "0 50px",
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  errorLayout: {
    display: "flex",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  attachmentBtn: {
    width: "100%",
    height: "180px",
    backgroundColor: "#C0C0C0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:"pointer"
  },
  uploadText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#2C2C2E",
    textTransform: "capitalize",
  },
  uploadSubText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    color: "#2C2C2E",
    textTransform: "capitalize",
  },
  btnTextLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "right",
    marginTop: "30px",
  },
  dateFont: {
    "& .MuiInputBase-input": {
      color: "currentColor",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  dateFontPlaceholder: {
    "& .MuiInputBase-input": {
      color: "#C0C0C0",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  previewImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

interface Props {
  open: boolean;
  state: any;
  lessonType?: string;
  handleClose: (data: any) => void;
  handleEditClassChange: (event:any)=> void;
  handleEditStartDateChange:(event:any)=>void;
  handleEditDueDateChange: (event:any)=>void;
  handleLessonObjectiveChange: (event:React.ChangeEvent<HTMLInputElement>)=>void;
  handleLessonNameChange:(event:React.ChangeEvent<HTMLInputElement>)=>void;
  handleEditAction: (id:string)=>void;
}

const timeOptions: any[] = [];
const startTime = new Date();
startTime.setHours(0, 0, 0, 0); // Set to midnight
for (let i = 0; i < 24 * 4; i++) {
  // 24 hours * 4 intervals/hour
  const time = new Date(startTime.getTime() + i * 15 * 60 * 1000); // Add 15 minutes
  timeOptions.push(time);
}

const EditModal = (props: Props) => {
  const {
    open,
    state,
    lessonType,
    handleClose,
    handleLessonNameChange,
    handleEditClassChange,
    handleEditStartDateChange,
    handleLessonObjectiveChange,
    handleEditDueDateChange,
    handleEditAction,
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const [defaultError, setDefaultError] = React.useState("");
  const classes = useStyles();

  const handleSubmit = () => {
    handleEditAction(state.editTaskId);
  }

  const handleValidationMessage = (val: string, isValidCheck: boolean, message: string) => {
    if (val === '' && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }

  const handleError = (error: string = "") => {
    setDefaultError(error);
  }

  const isDisabled = () => {
    return (
      !!defaultError ||
      state.editLessonName === "" ||
      state.editClassName === "Select Class" ||
      !state.editStartDate ||
      state.editStartDate == "Invalid date" ||
      !state.editDueDate ||
      state.editDueDate == "Invalid date"
    );
  }

  return (
    <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-testid="edit-assignment-modal"
        open={open}
        onClose={handleClose}
      >
        <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <Box className={classes.popupContent}>
          <Typography className={classes.title}>{`Edit ${lessonType}`}</Typography>
          <ClearIcon data-test-id="close-icon-btn" onClick={handleClose} className={classes.crossIcon} />

          <Grid container spacing={4}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label htmlFor="optionName" className={classes.inputLabel}>{`${lessonType} Name*`}</label>
                <TextField
                  id="optionName"
                  name="optionName"
                  data-test-id="assignment-name-input"
                  fullWidth
                  disabled
                  placeholder={`${lessonType} Name`}
                  variant="outlined"
                  onChange={handleLessonNameChange}
                  value={state?.editLessonName}
                  className={classes.inputValue}
                />
              </FormControl>
              <Typography style={webStyle.errorTypo}>{handleValidationMessage(state.editLessonName, state.isValidateEditLibrary, `Invalid ${lessonType} Name` )}</Typography>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label className={classes.inputLabel} htmlFor="class">
                  Class*
                </label>
                <NativeSelect
                  id="class"
                  name="class"
                  variant="outlined"
                  disabled
                  value={state?.editClassName}
                  onChange={handleEditClassChange}
                  data-test-id="class-name-input"
                  input={
                    state?.editClassName ? (
                      <BootstrapInputActive />
                    ) : (
                      <BootstrapInputInactive />
                    )
                  }
                >
                  <option value="Select Class">Select Class</option>
                  {state.classesData.map((teacherClass: any) => (
                    <option
                      key={teacherClass.id}
                      value={teacherClass.id}
                      className={classes.inputValue}
                    >
                      {teacherClass.attributes?.class_name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <Typography style={webStyle.errorTypo}>{handleValidationMessage(state.editClassName, state.isValidateEditLibrary, "Invalid Class")}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <label className={classes.inputLabel} htmlFor="summary">{`${lessonType} Objective`}</label>
                <TextField
                  id="objective"
                  data-test-id="assignment-details-input"
                  name="objective"
                  fullWidth
                  disabled
                  placeholder={`A short ${lessonType} objective`}
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={handleLessonObjectiveChange}
                  value={state.editLessonObjective}
                  InputProps={{
                    className: classes.inputValue,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label htmlFor="startDate" className={classes.inputLabel}>
                  Start Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="startDate"
                      name="startDate"
                      variant="dialog"
                      data-test-id="start-date-input"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      disabled
                      onChange={handleEditStartDateChange}
                      value={moment(state?.editStartDate, "DD/MM/YYYY", true).toDate()}
                      labelFunc={() =>
                        state?.editStartDate || "Select Start Date"
                      }
                      className={
                        state?.editStartDate
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                      onError={(error) => handleError(error as string)}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
         
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label
                  htmlFor="endDate"
                  className={classes.inputLabel}
                >
                  End Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="endDate"
                      name="endDate"
                      variant="dialog"
                      data-test-id="end-date-input"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      onChange={handleEditDueDateChange}
                      value={moment(state?.editDueDate, "DD/MM/YYYY", true).toDate()}
                      className={
                        state.editDueDate
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                      disabled={!state?.editStartDate}
                      minDate={moment(state?.editStartDate, "DD/MM/YYYY", true)}
                      onError={(error) => handleError(error as string)}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>

          <Box className={classes.buttonLayout}>
            <GenericButton
              type="NORMAL"
              label="Cancel"
              handleClick={handleClose}
              customStyle={{ width: "200px" }}
            />
            <GenericButton
              data-testId="edit-submit-btn"
              type="COLORED"
              label={`Save ${lessonType}`}
              handleClick={handleSubmit}
              customStyle={{ width: "200px", marginRight: '0' }}
              disabled={isDisabled()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
    </Modal>
  );
};

export default EditModal;

const webStyle = {
  createEventBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "white",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "60px",
    marginRight: "32.5px",
    width: "10rem",
    fontSize: "15px",
    textTransform: "capitalize" as "capitalize",
  },
  errorTypo: {
    color: "red",
    paddingTop: "4px",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
  foldersStyle: {
    cursor: "pointer",
    position: "relative" as "relative",
    right: "-123px",
    left: "300px",
    top: "-45px",
  },
  heightWidth14px: {
    height: "14px",
    width: "14px",
  },
  countTypo: {
    color: "#2B3D7D",
    fontSize: "14px",
    fontFamily: "poppins",
  },
  inputDateStyle: {
    border: "none",
    fontFamily: "Poppins",
    width: "300px",
  },
  titleTypography: {
    fontFamily: "poppins",
    lineHeight: "32px",
    fontSize: "20px",
    letterSpacing: "0.2px",
    marginBottom: "10px",
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center" as "center",
    paddingTop: "35px",
  },
  listingClass: {
    height: "465px",
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: "20px",
    paddingLeft: "35px",
  },
  borderDueTimeRadius: {
    borderBottomLeftRadius: "0px !important",
    borderTopLeftRadius: "0px !important",
    marginTop: "23px",
    fontFamily: "Poppins",
  },
  borderStartTimeRadius: {
    borderBottomRightRadius: "0px !important",
    borderTopRightRadius: "0px !important",
    marginTop: "16px",
    fontFamily: "Poppins",
  },
  buttonContainer: {
    display: "flex",
    position: "relative" as "relative",
    justifyContent: "flex-end" as "flex-end",
    gap: "10px",
    alignItems: "center",
    padding: "30px 52px 0px 52px",
  },
  btnLinkBreadCrumb: {
    cursor: "pointer",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "bold" as "bold",
  },
  saveTimeTypo: {
    color: "#2C2C2E",
    fontFamily: "Poppins",
  },
  title: {
    color: "#757575",
    fontWeight: 500 as 500,
    fontSize: "24px" as "24px",
    fontFamily: "Poppins",
  },
  teacherName: {
    color: "#000000",
    fontWeight: 500 as 500,
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    marginTop: "20px",
    padding: "0px 35px",
  },
  currentDateBox: {
    padding: "0 35px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  addButtonLayout: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  currenDate: {
    color: "rgb(43, 61, 125)",
    paddingRight: "15px",
    fontWeight: 600 as 600,
    fontSize: "22px",
    fontFamily: "Poppins",
  },
  calendarView: {
    rowGap: "10rem",
    display: "grid",
  },
  weekHeader: {
    paddingTop: "20px",
    flexDirection: "column" as "column",
    display: "flex",
    marginBottom: "8px",
    marginTop: "16px",
    width: "93px",
    height: "111px",
  },
  weekHeaderDayLabel: {
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500 as 500,
    color: "#858585",
    marginBottom: "10px",
    textAlign: "center" as "center",
  },
  weekHeaderDateLabel: {
    color: "#000000",
    lineHeight: "36px",
    fontSize: "24px",
    fontWeight: 400 as 400,
    fontFamily: "Poppins",
    textAlign: "center" as "center",
  },
  tabBefore: {
    color: "#2B3D7D",
    fontWeight: 500,
    fontSize: "20px",
    width: "218px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "4px",
    borderRight: "1px solid gray",
  },
  tabAfter: {
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
    backgroundColor: "#2B3D7D",
    fontSize: "20px",
    borderRadius: "4px",
    width: "218px",
    opacity: 1,
  },
  calendarBox: {
    padding: "50px 35px",
    height: "70%",
  },
  eventBox: {
    whiteSpace: "nowrap" as "nowrap",
    border: "1px solid rgba(57, 198, 181, 0.26)",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    textOverflow: "ellipsis" as "ellipsis",
    overflow: "hidden" as "hidden",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },
  eventTitle: {
    fontWeight: 800 as 800,
    fontFamily: "Poppins",
    fontSize: "14px",
    overflow: "hidden" as "hidden",
    marginBottom: "14px",
    color: "#2B3D7D",
    textOverflow: "ellipsis" as "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    height: "2em",
    paddingTop: "5px",
  },

  deleteEventTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "25px",
  },
  eventDueDate: {
    width: "150px",
    fontWeight: 500 as 500,
    color: "#757575",
    overflow: "hidden" as "hidden",
    whiteSpace: "normal" as "normal",
    fontSize: "13px",
    fontFamily: "Poppins",
    wordBreak: "break-word" as "break-word",
  },
  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    minWidth: 0,
    transform: "rotate(90deg)",
    padding: "4px",
    float: "right" as "right",
    top: "4px",
    right: "22px",
  },
  highlightSelectedDate: {
    display: "flex" as "flex",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    flexDirection: "column" as "column",
    width: "93px",
    border: "1px solid #979797",
    borderRadius: "10px" as "10px",
    height: "111px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 500 as 500,
    textAlign: "center" as "center",
    marginBottom: "10px",
    lineHeight: "27px",
    fontSize: "24px",
  },
  highlightedWeekHeaderDateLabel: {
    color: "#FFF",
    lineHeight: "36px",
    fontWeight: 400,
    textAlign: "center" as "center",
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  link: {
    textDecoration: "none",
  },
  eventTitleBox: {
    justifyContent: "space-between",
    display: "flex",
  },
  goBackButton: {
    justifyContent: "end",
    display: "flex",
    marginRight: "35px",
    marginBottom: "50px",
  },

  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px",
    minHeight: "275px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    marginRight: "24px",
    flexDirection: "column",
  },
  actionName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginTop: "12px",
  },
  actionDescription: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
    textAlign: "center",
  },
  listItemImage: {
    width: "120px",
    height: "120px",
  },
  countBox: {
    display: "flex",
    justifyContent: "start",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    minHeight: "121px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    maxWidth: "210px",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "#FFF3EB",
  },
  displayCount: {
    fontFamily: "Poppins",
    fontSize: "40px",
    color: "#2B3D7D",
    fontWeight: 600,
  },
  countHeading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },
  imageOption: {
    marginBottom: "10px",
  },
  popupContentOption: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
  },
  container: {
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px 30px",
    minHeight: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    flexDirection: "column" as "column",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
  },
  paper: {
    position: "absolute" as "absolute",
    width: "600px",
    backgroundColor: "white",
    borderRadius: "10px" as "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "2px 4px 3px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#000000",
    fontWeight: 700 as 700,
    marginBottom: "15px",
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    marginBottom: "5px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around",
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px" as "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none" as "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px" as "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none" as "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF",
    },
  },
  titleOption: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "40px",
  },
  optionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  },
  paperOption: {
    position: "absolute" as "absolute",
    width: "740px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "35px 0",
  },
  selectedCard: {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },

  crossIconOption: {
    position: "absolute" as "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  picker: {
    fontFamily: "Poppins",
    position: "relative" as "relative",
    "& .MuiInputAdornment-root": {
      marginRight: 0,
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      left: 0,
      padding: "10px",
    },
    "& .MuiInputBase-input": {
      paddingLeft: "40px", // Adjust padding to make space for the icon
    },
  },
};
