import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { deleteProfileImage } from "../assets";
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 600,
    marginBottom: "15px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "86%"
  },
  modalSubtitle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#48484A",
    fontWeight: 400,
    width: "390px",
    textAlign: "center",
  },
  modalImage: {
    marginBottom: "15px",
  },
  innerContainer: {
    positiobe: "relative",
  },
  cancelIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  actionButtonLayout: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "30px",
    paddingBotton: "10px",
  },
  coloredBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    marginLeft: "20px",
  },
  normalBtn: {
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #2B3D7D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const DeleteStudentModal = (props: any) => {
  const {
    handleCloseModal,
    contextMenuData,
    deleteStudentProfileApi
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  return (
    <Box style={modalStyle} className={classes.container}>
      <Box className={classes.innerContainer}>
        <ClearIcon onClick={handleCloseModal} className={classes.cancelIcon} />
        <Box className={classes.modalContent}>
          <img
            className={classes.modalImage}
            src={deleteProfileImage}
          />
          <Typography className={classes.modalTitle}>
            Are you sure you want to delete {contextMenuData.name} from student profile of your team?
          </Typography>
          <Box className={classes.actionButtonLayout}>
            <Button onClick={handleCloseModal} className={classes.normalBtn}>
              Cancel
            </Button>
            <Button
              onClick={() => {deleteStudentProfileApi(contextMenuData.id) }}
              data-testid="delete-profile-btn"
              className={classes.coloredBtn}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteStudentModal;
