import React from "react";
import { Box, Typography, Grid,TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
    height: "740px",
  },
  instructionTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#0A033C",
    fontWeight: 600,
    marginRight: "12px",
    marginBottom: "4px"
  },
  answerBox: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
    border: "1px solid rgb(149, 157, 165)",
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%"
  },
  instructionBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "15px",
    justifyContent: "space-between"
  },
  enteriesCount: {
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    border: "1px solid rgb(149, 157, 165)",
    borderRadius: "4px",
    backgroundColor: "#fff",
    "& .MuiInputBase-input": {
      color: "#282829",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      padding: "1px 5px"
    },
    width: "23px"
  },
}));

const WordCloudPreview = (props: any) => {
  const { currentStage } = props
  const classes = useStyles();
  
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${currentStage.attributes.background_image?.url})`,
  };
  const hasCanvasData = currentStage.attributes.canvas_data;
 
  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }
  return (
    <div >
      <div className={classes.titleBox} >
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}/>
      </div>
      {  jsonCanvas && 
      <Box style={{width:"90%"}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
      </Box>}
      <div className={classes.stageTitle} style={backgroundStyle} id="content">
      <Box className={classes.instructionBox} >
        <Box style={{ display: "flex", alignItems: "center", }}>
          <Typography className={classes.instructionTitle}>
            Enteries per Student:
          </Typography>
          <TextField
            className={classes.enteriesCount}
            value={currentStage.attributes.description.length}
            disabled
            data-test-id="instruction"
            InputProps={{ disableUnderline: true }}
          />
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Typography className={classes.instructionTitle}>Answers</Typography>
        <Grid container spacing={2}>
          {currentStage.attributes.description?.map((obj:any,index:any) =>
            <Grid item xs={12} md={6} key={index}>
              <input
                className={classes.answerBox}
                readOnly
                data-test-id="instruction2"
                placeholder={`Answer ${(index+1).toString()} here`}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      </div>
    </div>
  );
};

export default WordCloudPreview;
