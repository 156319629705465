import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  makeStyles,
} from "@material-ui/core/styles";
import { StudentAttribute } from "../../../../LiveStreamingController";
import { QuestionHeaders } from "../../../../../../../components/src/types";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  question: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "20px",
    "&.heading-bold":{
      fontWeight:"600",
      marginTop:"5px"
    }
  },
  stageName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "15px",
  },
  contebtBoxMain:{
    padding:"80px 30px 30px",
    position:"relative"
  },
  contebtBoxMainResult:{
    position:"relative",
    "& .title-top":{
      marginBottom:"10px"
    },
  },
  answersBox: {
    "& p":{
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#282829",
      fontWeight:'400',
      lineHeight:"1.71",
      wordBreak: "break-all",
      "&:not(:last-child)":{
        marginBottom:"20px",
      }
    },
  },

  singleAnswerBox:{
    margin:"0",
  }
  
}));
const OpenEndedQuestion = (props: any) => {
  const classes = useStyles();
  const { currentStage,showResult,studentResultList } = props;
  const [height,setHeight] = useState(0);

  const hasCanvasData = currentStage.attributes.canvas_data;

  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {

      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }
  return (
    <Box className={showResult?classes.contebtBoxMainResult:classes.contebtBoxMain}>
      <Typography className={`${classes.stageName} title-top`}>
        {QuestionHeaders.OPEN_ENDED_QUESTION}
      </Typography>
      {  jsonCanvas &&
       <Box style={{position:"absolute",width:"90%",height:"100%",}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
        </Box>}
      <Typography className={`${classes.question} heading-bold`} dangerouslySetInnerHTML={{
              __html:  currentStage?.attributes.title
             }}/>
        {showResult ? 
          <Box className={classes.answersBox} style={{marginTop:`${height}px`}}>
            {studentResultList.attribute?.map((answerItem: StudentAttribute) => {
              return <Typography className={classes.singleAnswerBox}>{answerItem.answer[0].answer}</Typography>
            })
            }
          </Box>
        :
        <TextField
          id="text-area"
          multiline
          variant="outlined"
          rows={8}
          placeholder="Answer"
          onChange={() => {}}
          disabled
          fullWidth
          style={{marginTop:`${height}px`}}
        />}
    </Box>
  );
};

export default OpenEndedQuestion;
