import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Checkbox from "../components/Checkbox.web";
import { makeStyles } from "@material-ui/core/styles";
import { CloseIcon, calendarIcon } from "../assets";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import { setStorageData } from "../../../../framework/src/Utilities";


const useStyles = makeStyles(theme => ({
    modalsStyles: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 500,
        backgroundColor: theme.palette.background.paper,
        border: "none",
        boxShadow: theme.shadows[24],
        borderRadius: "16px",
        [theme.breakpoints.down('xs')]: {
            minWidth: "90%"
        },
    },
    innerModal: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 500,
        backgroundColor: theme.palette.background.paper,
        border: "none",
        boxShadow: theme.shadows[24],
        borderRadius: "16px",
        [theme.breakpoints.down('xs')]: {
            minWidth: "0",
            maxWidth:"100%"
        },
    },
    closeIcon: {
        cursor: "pointer",
      },
    crossIconOption: {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
    },
    innerModalContainer: {
        padding: "20px 40px",
        [theme.breakpoints.down('xs')]: {
            padding:"20px 12px"
        },
    },
    dateRangeTitle: {
        fontSize: "22px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        color: "#000000D9",
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
            marginLeft: "20px"
        },
    }
}));

interface ClassListSelectedProps {
    showReportView:any;
    handleCloseModal: any;
    destinationData:any;
    internalDate:any,
    setInternalDate:any
}

const TeacherClassListSelectedModal: React.FC<ClassListSelectedProps> = ({ handleCloseModal ,showReportView,destinationData,internalDate,setInternalDate}) => {
    const [reportTypeSummary, setReportTypeSummary] = useState(true);
    const [openCalendarModal, setOpenCalendarModal] = useState(false);
    const classes = useStyles();
    return (
        <Box className={classes.modalsStyles} >
            <Box className={classes.crossIconOption}>
                <ClearIcon onClick={handleCloseModal} />
            </Box>
            <div
                style={{
                    borderBottom: "1px solid #D7E1EA",
                    padding: "20px 24px",
                }}
                data-testid="modalBox"
            >
                <div
                    style={{
                        color: " #2c3f58",
                        fontSize: "24px",
                        lineHeight: "36px",
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                    }}
                >
                    Generate Report{" "}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    padding: "15px 24px",
                    flexDirection: "column",
                    gap: "10px",
                    borderBottom: "1px solid #D7E1EA",
                }}
            >
                <div style={{ color: " #778699", fontSize: "14px", fontFamily: 'Poppins' }}>
                    Select Report
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "13px 17px",
                        border: reportTypeSummary
                            ? "1px solid #2B3D7D"
                            : "1px solid rgba(43, 61, 125, 0.39)",
                        borderRadius: "8px",
                    }}
                    data-testid="checkBoxContainer"
                    onClick={() => setReportTypeSummary(!reportTypeSummary)}
                >
                    <Checkbox
                        checked={reportTypeSummary}

                        data-testid="checkBox"
                    />
                    <div
                        style={{
                            color: "#254050",
                            alignItems: "center",
                            fontSize: "14px",
                            fontFamily: 'Poppins'
                        }}
                        data-testid="checkBoxTitle"
                    >
                        Summary Report
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: "5px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "13px 17px",
                        border: reportTypeSummary
                            ? "1px solid rgba(43, 61, 125, 0.39)"
                            : "1px solid #2B3D7D",
                        borderRadius: "8px",
                    }}
                    data-testid="checkBoxContainer"
                    onClick={() => setReportTypeSummary(!reportTypeSummary)}
                >
                    <Checkbox
                        checked={!reportTypeSummary}
                    />
                    <div data-testid="checkBoxTitle" style={{ color: "#254050", fontSize: "14px", fontFamily: 'Poppins' }}>
                        Detailed Report
                    </div>
                </div>
            </div>

               {openCalendarModal && (
                    <Box className={classes.innerModal}>
                        <Box className={classes.innerModalContainer}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "20px",
                                    margin: "25px 0px",
                                }}
                            >
                                <Typography className={classes.dateRangeTitle}
                                >
                                    Select Dates to Generate Report
                                </Typography>
                                <img
                                    data-test-id="closeButton"
                                    className={classes.closeIcon}
                                    src={CloseIcon}
                                    onClick={() => {
                                        setOpenCalendarModal(false);
                                        setInternalDate(null);
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                    alignItems: "center",
                                    padding: "13px 17px",
                                    border: "1px solid rgba(43, 61, 125, 0.39)",
                                    borderRadius: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#254050",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {internalDate !== null
                                        ? `${dayjs(internalDate[0]).format(
                                            "DD/MM/YYYY"
                                        )} - ${dayjs(internalDate[1]).format("DD/MM/YYYY")}`
                                        : "Select range"}
                                </div>
                                <img src={calendarIcon} />
                            </div>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Calendar
                                        onChange={setInternalDate}
                                        value={internalDate}
                                        returnValue={"range"}
                                        selectRange={true}
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    setOpenCalendarModal(false);
                                }}
                                data-test-id="btnAddGroup"
                                type="submit"
                                style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    margin: "40px 0px",
                                    padding: "15px",
                                    borderRadius: "8px",
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    background:
                                        "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
                                    fontFamily: "Poppins",
                                }}
                            >
                                Done
                            </button>
                        </Box>
                    </Box>
                )}
                
            <div style={{
                padding: "15px 24px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop:"100px"
            }}
                data-testid="rangeContainer">    
                 <div
                      data-testid="rangeHeading"
                      style={{
                        color: " #778699",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select date range
                    </div>
                    <div
                      data-testid="rangeButton"
                      onClick={() => setOpenCalendarModal(true)}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        padding: "13px 17px",
                        border: "1px solid rgba(43, 61, 125, 0.39)",
                        borderRadius: "8px",
                      }}
                    >
                      <img src={calendarIcon} />
                      <div
                        style={{
                          color: "#254050",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontFamily: "Poppins",
                        }}
                        data-testid="rangeButtonTitle"
                      >
                        {internalDate !== null
                          ? `${dayjs(internalDate[0]).format(
                              "DD/MM/YYYY"
                            )} - ${dayjs(internalDate[1]).format("DD/MM/YYYY")}`
                          : "Select range"}
                      </div>
                    </div>
                <button
                    data-testid="submitButton"
                    onClick={(e) => {
                        handleCloseModal()
                        if (internalDate && internalDate.length === 2) {
                            const formattedStartDate = dayjs(internalDate[0]).format("DD/MM/YYYY");
                            const formattedEndDate = dayjs(internalDate[1]).format("DD/MM/YYYY");
                            setStorageData("startDateRange",formattedStartDate)
                            setStorageData("endDateRange",formattedEndDate)
                        } 
                        showReportView(e,destinationData)
                        window.localStorage.setItem("reportTypeSummary",reportTypeSummary.toString())
                    }}
                    type="submit"
                    style={{
                        cursor: "pointer",
                        margin: "10px 0px",
                        padding: "15px",
                        borderRadius: "8px",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        background:
                            "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A",
                        fontFamily: 'Poppins'
                    }}
                >
                    Select Students
                </button>
                </div>
        </Box>
    );
};

export default TeacherClassListSelectedModal;
