import React from "react";
import { Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ReminderSuccess from "./ReminderSuccess.web";
import ReminderConfirmation from "./ReminderConfirmation.web";
import { getModalStyle, useStyles } from "./utils";
const ReminderModal = (props: any) => {
  const { handleCloseModal, isReminderSuccess, sendReminder, userData } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        {isReminderSuccess && (
          <ClearIcon
            onClick={(e) => handleCloseModal(e, userData.id)}
            className={classes.crossIcon}
          />
        )}

        <Box className={classes.popupContent}>
          {isReminderSuccess ? (
            <ReminderSuccess userData={userData} />
          ) : (
            <ReminderConfirmation
              sendReminder={sendReminder}
              userData={userData}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReminderModal;
