import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trashImageIcon } from "../../assets";
import ClearIcon from "@material-ui/icons/Clear";

const configJSON = require("../../config");
const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      padding: theme.spacing(1, 2, 2),
    },
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#000000",
    fontWeight: 700,
    marginBottom: "15px"
  },
  confirmation: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#000000",
    marginBottom: "5px"
  },
  eventTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#2B3D7D",
    fontWeight: 700,
    marginBottom: "25px"
  },
  deleteBtn: {
    backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#FFF"
    }
  },
  cancelBtn: {
    border: "1px solid #2B3D7D",
    color: "#3F526D",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px"
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
      gap:"20px"
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  crossIconOption:{
    position: "absolute" as "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  }
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
const DeleteEvent = (props: any) => {
  const { handleCancelDeleteEvent, handleDeleteEvent, title } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.popupContent}>
        <ClearIcon
          onClick={handleCancelDeleteEvent}
          className={classes.crossIconOption}
        />
        <Typography className={classes.heading}>Delete Event</Typography>
        <img src={trashImageIcon} />
        <Typography className={classes.confirmation}>
          {configJSON.deleteConfirmation}
        </Typography>
        <Typography className={classes.eventTitle}>{title}</Typography>
      </Box>
      <Box className={classes.btnLayout}>
        <Button
          data-testid="cancelBtn"
          className={classes.cancelBtn}
          onClick={handleCancelDeleteEvent}
        >
          Cancel
        </Button>
        <Button
          data-testid="deleteBtn"
          className={classes.deleteBtn}
          onClick={handleDeleteEvent}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteEvent;
