import React from "react";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const CustomDatePicker = (props: any) => {
  const { open, handleDateChange, selectedDate, handleClose } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          disableToolbar
          open={open}
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleDateChange}
          onClose={handleClose}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          style={{ display: "none" }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
