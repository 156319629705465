import React from "react";
import { Box,Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import {deleteProfileSuccessImage} from "../assets"

const useStyles = makeStyles((theme) => ({
    deleteSuccessImage: {
      marginBottom: "10px",
    },
    deleteSuccessTitle: {
      fontFamily: "Poppins",
      fontSize: "22px",
      color: "#2B3D7D",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "5px",
    },
    deleteSuccessSubtitle: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: "#3F526D",
      fontWeight: 400,
      textAlign: "center",
    },
    popupContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingRight:"30px",
        paddingLeft:"30px",
      },
      container: {
        positiobe: "relative",
      },
      crossIcon: {
        position: "absolute",
        top: "10px",
        right: "10px",
      },
      paper: {
        position: "absolute",
        width: "488px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        display: "flex",
        justifyContent: "center",
        aignItems: "center",
        padding: "35px 0",
      },
  }));
function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
const DeleteProfileSuccess = (props: any) => {
  const { handleCloseModal } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        
          <ClearIcon
            onClick={handleCloseModal}
            className={classes.crossIcon}
          />

        <Box className={classes.popupContent}>
        <img className={classes.deleteSuccessImage} src={deleteProfileSuccessImage} />
      <Typography className={classes.deleteSuccessTitle}>
        Profile Deleted Successfully from your team!
      </Typography>
      
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProfileSuccess;
