import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { reminderConfirmation } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
const useStyles = makeStyles(() => ({
  reminderConfirmationSubtitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 400,
    textAlign: "center",
  },
  reminderConfirmationImage: {
    marginBottom: "10px",
  },
}));
const ReminderConfirmation = (props: any) => {
  const { sendReminder, handleCloseModal, userData } = props;
  const classes = useStyles();
  const handleSendReminder = (e: any) => {
    sendReminder(userData.id);
  };
  return (
    <>
      <img
        className={classes.reminderConfirmationImage}
        src={reminderConfirmation}
      />
      <Typography className={classes.reminderConfirmationSubtitle}>
        {`Send a reminder to ${userData.name} to complete the Student Profile?`}
      </Typography>
      <Box sx={{ mt: "30px" }}>
        <GenericButton
          type="NORMAL"
          label="Cancel"
          handleClick={(e) => handleCloseModal(e, userData.id)}
          customStyle={{ width: "154px" }}
        />
        <GenericButton
          type="COLORED"
          label="Send"
          handleClick={(e) => handleSendReminder(e)}
          customStyle={{ width: "154px" }}
        />
      </Box>
    </>
  );
};

export default ReminderConfirmation;
