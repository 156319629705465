import React from "react";

// Customizable Area Start
import {Textlabel, View_Oval, View_rounded_Rectangle,
  View_shape } from "../../CfCourseCreation23/src/assets"

  import { SketchPicker } from "react-color";
  import {view_minus, view_plus,
    group_delete1,group_highlight1,group_move1,group_pen1,group_redo1,group_shapes1,group_text1,group_undo1,
    white_highlight1, white_pen1, white_shapes1, view_Triangle,view_Polygon,view_Star 
  } from "./assets";
  import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
// Customizable Area End

// Customizable Area Start
import { Roles } from "../../../components/src/types";
// Customizable Area End

import WhiteboardCollaborationController, {
  Props,
  configJSON,
} from "./WhiteboardCollaborationController";

export default class WhiteboardCollaboration extends WhiteboardCollaborationController {
  // Customizable Area Start
  showDeleteTools = () => {
    return (
      <>
      <PanToolOutlinedIcon onClick={this.handlePan} style={this.state.selectedTool === 'pan' ?webStyle.panToolSelected:webStyle.pantool} data-testId = "panIcon"/>
      <img src={this.state.selectedTool === 'eraser' ? white_highlight1:group_highlight1} style={this.state.selectedTool === 'eraser' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "addEraserId" onClick={()=>this.handleEraser()}/>
      <img src={group_delete1} id="deleteTool" style={this.state.selectedTool === 'delete' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "deleteId"  onClick={()=>this.deleteObject()}/>
      <img src={group_undo1} id="undo" style={this.state.selectedTool === 'undo' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "undoId"  onClick={()=>this.undo()}/>
      <img src={group_redo1} id="redo"style={this.state.selectedTool === 'redo' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "redoId"  onClick={()=> this.redo()}/>
      </> 
    )
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.canvas = undefined;
    this.textInput = React.createRef();
    this.defaultScaleX = configJSON.webScaleX;
    this.defaultScaleY = configJSON.webScaleY;
    this.canvasRef = React.createRef()
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <div style={localStorage.getItem("role")===Roles.STUDENT?{cursor:"not-allowed",position:"relative"}:{cursor:"pointer",position:"relative"}}>
       {this.state.colorPicker && 
       <div style={{ position:"absolute",zIndex:99999999,bottom: "10px",left:"90px"}}>
        <SketchPicker  color={this.state.color} data-testId="colorPicker" onChange={this.handleChange2}  />
      </div>}
    <div 
    // style={{display:"flex",flexWrap:"wrap",marginTop:"20px",gap:"20px"}}
    >
      <div style={{display:"flex",flexDirection:"column",position:"absolute",zIndex:999}}>        
        <div style={{display:"flex",flexWrap:"wrap",marginTop:"20px",marginLeft:"20px", paddingTop: "0",paddingBottom: "0"}}>
          <div style={{width:"30px",height:"auto",border:"0.5px solid #D4DCE5" , borderRadius:"4px", paddingTop: "5px",paddingBottom: "5px",display: "flex",flexDirection:"column",
          gap: "10px", alignItems: "center",background:"#FFFFFF"}}>
          <div style={{width:"12px",height:"12px",borderRadius:"50px", backgroundColor:this.state.color, border:"1px solid black", cursor:"pointer"}} data-testId="colorPickerState" onClick={()=> this.handleColorPicker()}></div>
            <img src={group_move1} id="ponterTool" style={this.state.selectedTool === 'pointer' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "pointerToolId" onClick={()=> this.handlePointer()} />
            <img src={group_text1} id="textTool" style={this.state.selectedTool === 'text' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "textToolId" onClick={()=> this.addText()}/>
            <img src={this.state.selectedTool === 'pen' ? white_pen1:group_pen1} id="highlighterTool"style={this.state.selectedTool === 'pen' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "highlighterId"  onClick={()=> this.activatePen()} />
            <img src={this.state.selectedTool === 'shape' ? white_shapes1:group_shapes1}  style={this.state.selectedTool === 'shape' ?webStyle.imgSizeSelected:webStyle.imgSize} data-testId = "addRectangleId"  onClick={()=>this.handleShape()}/>
            {this.showDeleteTools()}
          </div>
          {this.state.open && <div style={webStyle.divStyle2}>
            <img src={View_shape} id="rectangleTool" style={webStyle.imgSizeSmall} data-testId="squareRectId" onClick={()=> this.addRectangle()}/>
            <img src={View_rounded_Rectangle} id="addRoundedRectangle"  data-testId = "rectId"  style={webStyle.imgSizeSmall} onClick={()=>this.addRoundedRectangle()}/>
            <img src={View_Oval} id="circleTool"  data-testId = "circleId"  style={webStyle.imgSizeSmall} onClick={()=> this.addCircle()}/>
            <img src={view_Triangle} id="triangle" data-testId = "triangleId" style={webStyle.imgSizeSmall} onClick={()=>this.drawTriangle()}/>
            <img src={view_Star} id="star" data-testId = "starId" style={webStyle.imgSizeSmall} onClick={()=>this.drawStar()}/>
            <img src={view_Polygon} id="polygon" data-testId = "polyId" style={webStyle.imgSizeSmall} onClick={()=>this.drawPolygon()}/>
          </div>}
        </div>
      </div>
      <div style={{...webStyle.divstyleText,display:this.state.textModal?"flex":"none"}}>
          <select data-testId = "familyId"  onChange={(event) => this.handleChange(event.target?.value)} style={{background:"white",  border:"none",height:"36px", cursor: "pointer"}}>
            {this.state.allFontFamily.map((item:any)=><option key={item} value={item}>{item}</option>)}
          </select>
          <select  data-testId = "fontSizeId"  onChange={(event)=> this.handleFontSize(event.target?.value)} style={{background:"white", border:"none",height:"36px",cursor: "pointer"}}>
            {this.state.fontSizes.map((item:any)=>
            <option key={item} value={item}>{item}</option>
            )}
          </select>        
          <img src={Textlabel} id="boldTool" data-testId = "toggleBold" style={webStyle.imgSizeSmall} onClick={()=> this.toggleBold()}/>
    
      </div>
      {this.state.isStudent ? 
        <div className="canvas-container">
          <canvas ref={this.canvasRef} id="canvasId" /> 
        </div>     
      :
        <canvas ref={this.canvasRef} id="canvasId" />      
      }
      <div style={{ display:"flex",position:"absolute",zIndex:999,right:20,top:20,width:"fit-content",height:"fit-content"}}>
      <div style={{ background:" #ffffff", border:" 1px solid #D4DCE5", borderRadius: "10px",padding:"20px 10px"}} onClick={this.handleDecrement}>
        <img src={view_minus} style={{cursor:"pointer"}} />
      </div>
        <select 
          onChange={this.handleSelectChange}
          value={this.state.percentageValue}
          style={{color:"#3F526D",background:" #ffffff", border:" 1px solid #D4DCE5", borderRadius: "10px",padding:"10px 5px"}}>
            <option value="25">25%</option>
            <option value="50">50%</option>
            <option value="75">75%</option>
            <option value="100" selected>100%</option>
        </select>
        <div style={{ background:" #ffffff", border:" 1px solid #D4DCE5", borderRadius: "10px",padding:"15px 10px"}} onClick={this.handleIncrement}>
          <img src={view_plus}style={{cursor:"pointer"}} />
        </div>
      </div>
    </div>

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
 export const webStyle={
  imgSize:
  {
    cursor: "pointer",
    width:"100%"
  },
  imgSizeSelected:
  {
    width:"100%",
    cursor: "pointer"
  },
  imgSizeSmall:
  { height:"12px",
    width:"12px",
    cursor: "pointer"
  },
  divstyle: {
    border: "0.5px solid #D4DCE5",
    background:"#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    gap: "6px",
    alignItems: "center",
    padding:"5px 10px"
  }as React.CSSProperties,
  divstyleText: {
    border: "0.5px solid #D4DCE5",
    background:"#FFFFFF",
    borderRadius: "2px",
    gap: "6px",
    alignItems: "center",
    padding:"5px 10px",
    marginLeft:"20px",
    position:"absolute",
    zIndex:999,
    top:"275px"
  } as React.CSSProperties,
  divStyle2:{
    border: "0.5px solid #D4DCE5",
    background:"#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    gap: "26px",
    alignItems: "center",
    flexDirection:"column",
    padding:"5px 10px",
    height:"fit-content"
  } as React.CSSProperties,
  pantool: {
    color:"#2c3a7c",
    fontSize:"12px",
    padding:"7px 0",
    display:"block",
    width:"100%",
    cursor:"pointer"
  } as React.CSSProperties,
  panToolSelected: {
    backgroundColor:"#2B3D7D",
    fontSize:"12px",
    color:"white",
    display:"block",
    width:"100%",
    padding:"7px 0",
    cursor:"pointer"
  } as React.CSSProperties
  }
// Customizable Area End
