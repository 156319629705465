import React from "react";
import {
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
  Breadcrumbs,
  makeStyles
} from "@material-ui/core";
import { searchBarIcon, studentImage, teacherImage } from "../assets";
import Sidebar from "../../../../components/src/Sidebar.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicPagination from "../../../../components/src/BasicPagination.web";
import AppHeader from "../../../../components/src/AppHeader.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ClassList from "./components/ClassList.web";
import ClassDetails from "./components/ClassDetails.web";
import ItemList from "../../../../components/src/ItemList.web";
import DashboardController, { Props } from "../DashboardController";


const useStyles = makeStyles(() => ({
 
  filterInput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  searchBox: {
    display: "flex",
    gap: "4px",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  }
}));


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

type OverflowXProperty = "hidden";
type TextAlignProperty = "center";

interface DashboardBoxProps {
  bgcolor: string;
  title: string;
  count: string;
  imgSrc: string;
}

interface SearchSectionProps{
  handleSearchInput: any;
  placeholder?: string;
}

export const DashboardBox: React.FC<DashboardBoxProps> = ({
  bgcolor,
  title,
  count,
  imgSrc
}) => (
  <Box
    bgcolor={bgcolor}
    padding={3}
    borderRadius={8}
    sx={webStyle.dashboardbox}
  >
    <Box>
      <Typography style={webStyle.titleboxhead}>{title}</Typography>
      <h2 style={webStyle.dashcount}>{count}</h2>
    </Box>
    <Box sx={webStyle.dashboardboximg}>
      <img src={imgSrc} alt={title} />
    </Box>
  </Box>
);

export const SearchSection: React.FC<SearchSectionProps> = ({ handleSearchInput, placeholder }) => {
  const classes = useStyles();

  return (
    <Box className={classes.searchBox} marginTop={3}>
      <Box sx={webStyle.searchiconbox}>
        <img src={searchBarIcon} alt="Search Icon" />
      </Box>
      <input
        data-test-id="search-input"
        className={classes.filterInput}
        placeholder={placeholder || "Search by teacher name"}
        onChange={handleSearchInput}
      />
    </Box>
  );
};

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";

class SchoolAdminDashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  renderClassList=()=>{
    return (
      <>
        {this.state.classListData.length === 0 ? (
            <div style={webStyle.noRecordText}>
              No records found
            </div>
                ) : (
            <ClassList classList={this.state.classListData} getClassDetails={this.getClassDetails}/>
        )}
      </>
    );
  }

  renderClassDetails=()=>{
    return (
      <>
        <ClassDetails
          teacher={this.state.selectedTeacher}
          navigateToSection={this.navigateToSection}
        />
      </>
    );
  }

  renderDefaultView=()=>{
    interface Data {
      id:string;
      image: string;
      name: string;
    }
    const dataList:Data[] = this.state.teachersList.map((teacher:any)=>{
      return{
        id:teacher.id,
        image:teacher.attributes.image,
        name:teacher.attributes.full_name
      }
    })
    const round ={
      borderRadius:"50%"
    }
    return (
      <>
        {this.state.teachersList.length === 0 ? (
            <div style={webStyle.noRecordText}>
              No records found
            </div>
                ) : (
              <ItemList dataList={dataList} handleClick={this.getClassesList} imageShape={round}/>
            )}
      </>
    );
  }

  renderSidebar = () => {
    if (!this.state.isSmallScreen) {
      return (
        <Sidebar
          activeTab={this.state.activeTab}
          handleTabClick={this.handleTabClick}
        />
      );
    }
    return null;
  }

  renderDashboardBoxes = () => {
    if (this.state.view === "teacherList") {
      return (
        <>
          <Grid item md={6} xs={12}>
            <DashboardBox
              bgcolor="#EAF2FC"
              title="Teachers"
              count={`${this.state.teacherCount}`}
              imgSrc={teacherImage}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DashboardBox
              bgcolor="#FFF3EB"
              title="Students"
              count={`${this.state.studentCount}`}
              imgSrc={studentImage}
            />
          </Grid>
        </>
      );
    }
    return null;
  }
  
  

  render() {
    const isClassesList = this.state.view === "classList"
    const isClassDetails = this.state.view === "classDetails"
    const paginationStyle = this.state.isSmallScreen ? webStyle.pagination :undefined;
    let componentToRender
    switch(true){
      case isClassesList:
        componentToRender = this.renderClassList()
        break;
      case isClassDetails:
        componentToRender = this.renderClassDetails()
        break;  
      default:
        componentToRender = this.renderDefaultView()
        break;
    }
    return (
        <Grid container style={webStyle.container}>
          <Grid item md={2} xs={1}>
            {this.renderSidebar()}
          </Grid>

          <Grid item md={10} {...(!this.state.isSmallScreen && { xs: 10 })} style={webStyle.rightWrapper}>
            {this.state.activeTab === "Dashboard" && (
              <>
                <AppHeader title="Dashboard" />
                {this.state.dashboardLoading ? (
                  <Box style={webStyle.loaderStyle}>
                    <CircularProgress style={{ color: "#2B3D7D" }} />
                  </Box>
                ) : (
                  <Box paddingX={3} marginTop={5}>
                    <Grid container spacing={3}>
                      {this.renderDashboardBoxes()}
                      <Grid item xs={12}>
                      <Box style={webStyle.breadcrumbBox}>
                        <Breadcrumbs
                                data-test-id="breadcrumb-section"
                                style={webStyle.navigationIcon}
                                separator={<NavigateNextIcon fontSize="large" />}
                              >
                                <Typography  data-test-id="breadcrumb-title" style={(this.state.view  === "classList" || this.state.view  === "classDetails") ? webStyle.title : webStyle.boldTitle} onClick={()=>this.handleBreadCrumb("teacherList")}>Teachers</Typography>
                                {(this.state.view  === "classList" || this.state.view  === "classDetails") && <Typography data-test-id="breadcrumb-title2" style={this.state.view  === "classDetails" ? webStyle.title :webStyle.boldTitle} onClick={()=>this.handleBreadCrumb("classList")}>{this.state.selectedTeacher?.attributes?.full_name}</Typography>}
                                {this.state.view  === "classDetails" && <Typography data-test-id="breadcrumb-title3" style={webStyle.boldTitle}>{this.state.classTitle}</Typography>}
                        </Breadcrumbs>
                      </Box>
                          {this.state.view === "teacherList" && <SearchSection handleSearchInput={this.handleSearchInput} />}
                      </Grid>
                      <Grid item xs={12}>
                        {componentToRender}
                      </Grid>
                      <Grid item md={12} style={paginationStyle}>
                      {this.state.view === "teacherList" &&
                      <Box sx={{ px: "15px", py: "50px" }}>
                        <BasicPagination
                          handlePageChange={this.handlePageChange}
                          pageLimit={this.state.pageLimit}
                          pageNo={this.state.pageNo}
                        />
                      </Box>} 
                    </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
    );
  }
}

export default SchoolAdminDashboard;

const webStyle = {
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty
  },
  rightWrapper:{
    height: "100%",
    overflowY: "scroll" as OverflowXProperty
  },
  listul: {
    width: "85%"
  },
  listitem: {
    color: "#fff"
  },
  active: {
    backgroundColor: "#fff",
    borderRadius: "0 10px 10px 0",
    color: "#2B3D7D"
  },
  activeIcon: {
    color: "#2B3D7D",
    minWidth: "40px"
  },
  listicon: {
    color: "#fff",
    minWidth: "40px"
  },
  logo: {
    width: "260px"
  },
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center"
  },
  titleboxhead: {
    fontSize: "18px",
    color: "#282829",
    fontFamily: "poppins",
    fontWeight: 600,
    letterSpacing: "0.18px"
  },
  searchheadtext: {
    fontSize: "24px",
    color: "#282829",
    fontFamily: "poppins",
    fontWeight: 600,
    letterSpacing: "0.18px"
  },
  textColor: {
    color: "#2B3D7D"
  },
  dashboardbox: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    position: "relative"
  },
  dashcount: {
    color: "#2b3d7d",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 600,
    margin: "8px 0px"
  },
  dashboardboximg: {
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  searchBox: {
    display: "flex",
    gap: "4px",
    position: "relative"
  },
  filterinput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px"
  },
  searchiconbox: {
    position: "absolute",
    top: "13px",
    left: "10px"
  },
  addUserBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400
  },
  cancelBtn: {
    border: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#000",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "15px"
  },
  nextbtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "100%",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 3
  },
  boxrow: {
    display: "flex",
    gap: "10px",
    justifyContent: "space-evenly"
  },
  boxtech: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    width: "230px",
    height: "145px",
    borderRadius: "10px",
    border: "1px solid #dedde4",
    alignItems: "center",
    justifyContent: "center"
  },
  boxactive: {
    borderColor: "#2b3d7d",
    background: "#eef2ff"
  },
  modalheadtext: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: 700
  },
  boxicon: {
    width: "60px",
    height: "60px"
  },
  customInput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    ".MuiInputBase-input": {
      background: "red"
    }
  },
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    label: {
      fontFamily: "Roboto",
      fontSize: "14px"
    }
  },
  uploadrow: {
    display: "flex",
    gap: "20px",
    alignItems: "center"
  },
  uploadimg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    border: "1px solid #dedde4",
    background: "#eef2ff"
  },
  noRecordText: {
    textAlign: "center" as TextAlignProperty,
    fontFamily: "Poppins"
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#757575",
    cursor: "pointer"
  },
  boldTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "0px",
  },
  breadcrumbBox: {
    padding: "0px 15px",
    marginTop: "0px",
  },
  pagination: {
    width: '100%'   
  }
};
