import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "20px",
    whiteSpace: "pre-wrap"
  }
}));

const OpenEndedPreview = (props: any) => {
  const {currentStage}=props
  const classes = useStyles();
  const hasCanvasData = currentStage.attributes.canvas_data;

  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {

      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${currentStage.attributes.background_image?.url})`,
    marginTop:'30px'
  };
  return (
    <>
      <div className={classes.titleBox} id="content">
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}/>
      </div>
      <div style={backgroundStyle}>

      {  jsonCanvas &&
       <Box style={{position:"absolute",width:"90%",height:"100%",}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
        </Box>}
         </div>
    </>
  );
};

export default OpenEndedPreview;
