import React, { useEffect } from "react";
import GenericStaticCard from "../../../../../components/src/GenericStaticCard.web";
import { Box, Grid,Modal } from "@material-ui/core";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import { makeStyles } from "@material-ui/core/styles";
import {
  studentsOption,
  reportsOption,
  assignmentsOption,
  classLibraryOption,
} from "../../assets";
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import TeacherClassListSelectedModal from "../../../../analytics/src/Teacher/ClassListSelectedModal.web";
const staticOptions = [
  {
    label: "Class Library",
    image: classLibraryOption,
  },
  {
    label: "Reports",
    image: reportsOption,
  },
  {
    label: "Assignments",
    image: assignmentsOption,
  },
  {
    label: "Students",
    image: studentsOption,
  },
];

const useStyles = makeStyles((theme) => ({
  categoryList: {
    [theme.breakpoints.down('xs')]: {
     justifyContent:"center"
    },
  },
}));

const ClassDetails = (props: any) => {
  const { destination,
        locations,
        showStudentListView,
        redirect ,
        showReportView,
        openReportModal,
        closeReportModal,
        openReportModalFunc,
        internalDate,
        setInternalDate
      } = props;
  const classes = useStyles();


  useEffect(()=>{
      window.localStorage.setItem("classTitle", destination)
  },[])

  const handleClick = (label: string) => {
    showStudentListView({
      view: MY_CLASSES_VIEWS.CLASS_DETAILS,
      breadcrumbDisplayName: destination,
      destination: label,
    });
  };
  return (
    <Box sx={{ px: "35px" }}>
      <Box sx={{ mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={destination}
          redirect={redirect}
        />
      </Box>
      <Box sx={{ mt: "30px" }} >
        <Grid container spacing={4} className={classes.categoryList}>
          {staticOptions.map((option, index) => (
            <Grid item key={index}>
              <GenericStaticCard
                label={option.label}
                image={option.image}
                data-test-id={`card${option.label}`}
                handleClick={() => {
                  option.label=="Reports"?openReportModalFunc():
                  handleClick(option.label);
                }}
                imageStyle={{height:"123px",width:"107px"}}
              />
            </Grid>
          ))}
        </Grid>
        <Modal open={openReportModal} onClose={closeReportModal}>
          <TeacherClassListSelectedModal
            handleCloseModal={closeReportModal}
            showReportView={showReportView}
            destinationData={{
              view: MY_CLASSES_VIEWS.CLASS_DETAILS,
              breadcrumbDisplayName: destination,
              destination: "Reports",
            }}
            internalDate={internalDate}
            setInternalDate={setInternalDate}
          />
        </Modal>
      </Box>
    </Box>
  )
}
export default ClassDetails;