import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { debounce } from "lodash";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {Student,Teacher,ClassesData,UserInfo} from "./SchoolAdmin/types"
import {Roles} from "../../../components/src/types"
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  render: string;
  students: Student[];
  teachers: Teacher[];
  pageNo: number;
  pageLimit: number;
  searchInput: string;
  classesData: ClassesData;
  isLoading: boolean;
  isDeleteSuccess:boolean;
  deleteProfileError:boolean;
  tabSelected:number;
  selectedRole:string;
  role:string|null;
  deletedProfileId:number;
  pageNoStudent:number;
  pageLimitStudent:number;
  openDeleteStudentModal:boolean;
  classList:any[];
  studentDetails:any;
  createSuccessModal:boolean;
  passwordError:boolean;
  emailError:boolean;
  isSmallScreen:boolean;
  isFromDashboard: boolean;
  classIdForPeopleManagement: string | number;
  limitExceeded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeopleManagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiStudentDataCallId: string = "";
  apiTeacherDataCallId: string = "";
  apiDeleteTeacherProfileCallId: string = "";
  apiDeleteStudentProfileCallId: string = "";
  apiTeacherClassesCallId: string = "";
  apiStudentClassesCallId: string = "";
  apiTeacherStudentCallId:string = "";
  apiDeleteStudentProfileApiCallId:string="";
  createStudentByTeacherApiCallId:string="";
  getFormClassListApiCallId:string="";
  updateStudentByTeacherApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      render: "People Management",
      students: [],
      teachers: [],
      pageNo: 1,
      pageLimit: 1,
      searchInput: "",
      classesData: {
        name: "",
        classes: [],
      },
      isLoading:true,
      isDeleteSuccess:false,
      deleteProfileError:false,
      tabSelected:0,
      selectedRole:"Teacher",
      role:null,
      deletedProfileId:-1,
      pageNoStudent:1,
      pageLimitStudent:1,
      openDeleteStudentModal:false,
      classList:[],
      studentDetails: {},
      createSuccessModal:false,
      passwordError:false,
      emailError:false,
      isSmallScreen: window.innerWidth <= 600,
      isFromDashboard: false,
      classIdForPeopleManagement: 0,
      limitExceeded:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let error= message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleStudentsDataResponse(
      callId,
      response,
      error
    );
    this.handleTeachersDataResponse(
      callId,
      response,
      error
    );
    this.handleDeleteProfileResponse(
      callId,
      response,
      error
    );
    this.handleClassesResponse(
      callId,
      response,
      error
    )
    this.handleTeacherStudentsResponse(callId,response,error)
    this.handleDeleteStudentProfileResponse(callId,response,error)
    this.handleGetFormClassListResponse(callId,response,error)
    this.handleCreateStudentResponse(callId,response,error)
    // this.handleStudentClassesResponse(
    //   callId,
    //   response,
    //   error
    // )
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  redirect(renderView: string, userInfo: UserInfo, role:string) {
    if (renderView === "Classes") {
      this.setState({
        classesData: {
          name: userInfo.name,
          classes: [], 
        },
        selectedRole:role,
        pageNo:userInfo.pageNo
      });
      if(role==="teacher"){
        this.getTeacherClasses(userInfo.id)
      }else{
        this.getStudentClasses(userInfo.id)
      }
    }else{
      this.getTableData(this.state.selectedRole,this.state.pageNo)
    }
    this.setState({
      isLoading:true,
      render: renderView,
    });
  }
  getTableData(role: string, page = 1) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (role === "student") {
      this.apiStudentDataCallId = requestMessage.messageId;
    } else {
      this.apiTeacherDataCallId = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (this.state.isFromDashboard && role === 'student') ? `bx_block_content_management/teacher_flows/get_students_with_class?class_id=${this.state.classIdForPeopleManagement}&page=${page}&per_page=10&full_name=${
        this.state.searchInput
      }`
      :  configJSON.getTableData +
        `?role_name=${role}&page=${page}&per_page=10&full_name=${
          this.state.searchInput
        }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleStudentsDataResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string
  ) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiStudentDataCallId
    ) {
      if (responseJson) {
        const students = responseJson.data.map((student: any) => {
          return {
            id: student.id,
            fullName: student.attributes.full_name || "",
            email: student.attributes.email || "",
            phone: student.attributes.full_phone_number || "",
            classes: student.attributes.classes.map((classData:any)=>{
              return classData.attributes.class_name
            }) || [],
            creationDate: student.attributes.date_created || "",
          };
        });
        this.setState({
          students: students,
          pageLimit: responseJson.meta.total_page,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.setState({
      isLoading:false
    })
  }
  handleTeachersDataResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string
  ) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiTeacherDataCallId
    ) {
      if (responseJson) {
        const teachers = responseJson.data.map((teacher: any) => {
          return {
            id: teacher.id,
            fullName: teacher.attributes.full_name || "",
            email: teacher.attributes.email || "",
            institution: teacher.attributes.institution || "",
            classCount: teacher.attributes.no_of_classes || 0,
            creationDate: teacher.attributes.date_created || "",
          };
        });
        this.setState({
          teachers: teachers,
          pageLimit: responseJson.meta.total_page,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.setState({
      isLoading:false
    })
  }
  handlePageChange(event: any, page: number, role: string) {
    this.setState({
      pageNo: page,
    });
    this.getTableData(role, page);
  }
  debouncedSearch = debounce((role: string) => {
    // Perform the search operation here (e.g., make an API request)
    this.getTableData(role);
  }, 1000); // 1000ms delay (adjust as needed)

  handleSearchInput(event: any, role: string) {
    this.setState(
      {
        searchInput: event.target.value,
        pageNo: 1,
      },
      () => {
        this.debouncedSearch(role);
      }
    );
  }
  async componentDidMount() {

    let isFromDashboard = await getStorageData("isFromDashboard");
    let classIdForPeopleManagement = await getStorageData("classIdForPeopleManagement");

    this.setState({
      isFromDashboard: JSON.parse(isFromDashboard),
      classIdForPeopleManagement: classIdForPeopleManagement,
    })

    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
    }
    if(window.localStorage.getItem("role")===Roles.SUB_ADMIN){
      this.getTableData("teacher");
    }
    if(window.localStorage.getItem("role")===Roles.TEACHER){
      this.getTeacherStudents()
    }
    this.setState({
      role:window.localStorage.getItem("role")
    })

    const currentLocation = window.location.toString().split("/");
    const currentPath = currentLocation[currentLocation.length - 1];
    this.switchToView(currentPath === "students");
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };

  deleteProfile(id: string, role:string) {
    this.setState({
      isDeleteSuccess:false,
      deleteProfileError:false
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(role==="teacher"){
      this.apiDeleteTeacherProfileCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteTeacherProfile}?teacher_id=${id}`
      );
    }else{
      this.apiDeleteStudentProfileCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteStudentProfile}?account_id=${id}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDeleteProfileResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string
  ) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiDeleteTeacherProfileCallId || apiRequestCallId === this.apiDeleteStudentProfileCallId)
    ) {
      if (responseJson.id) {
        this.setState({
          deletedProfileId:responseJson.id,
          isDeleteSuccess:true,
          deleteProfileError:false
        });
      }
      if(responseJson.errors){
        this.setState({
          isDeleteSuccess:false,
          deleteProfileError:true
        })
      }
       else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  getTeacherClasses(id:string){
    this.setState({
      isLoading:true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTeacherClassesCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherClasses +`?teacher_id=${id}}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getStudentClasses(id:string){
    this.setState({
      isLoading:true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStudentClassesCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentClasses +`?student_id=${id}}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClassesResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string
  ) {
    if (
      apiRequestCallId != null &&
      ( apiRequestCallId === this.apiTeacherClassesCallId || 
        apiRequestCallId === this.apiStudentClassesCallId )
    ) {
      if (responseJson) {
        if(responseJson.data){
          this.checkAndAssignData(apiRequestCallId, responseJson)
        }
        else{
          this.setState((prevState) => ({
            classesData: {
              ...prevState.classesData, 
              classes: [], 
            },
          }));
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.setState({
      isLoading:false
    })
  }
  checkAndAssignData(apiRequestCallId:string, responseJson:any){
    let classes:any=[]
    if(apiRequestCallId === this.apiTeacherClassesCallId){
      classes = responseJson.data.map((data: any) => {
        return {
        name:data.attributes.class_name,
        image:data.attributes.attachment || ""
        };
      });
    }else{
      classes = responseJson.data.attributes.classes.map((data: any) => {
        return {
        name:data.attributes.class_name,
        image:data.attributes.attachment || ""
        };
      }); 
    }
    this.setState((prevState) => ({
      classesData: {
        ...prevState.classesData, 
        classes: classes, 
      },
    }));
  }

  deleteCleanup(role:string){
    if (role === "student") {
      const students = this.state.students.filter(
        (student) => parseInt(student.id) !== this.state.deletedProfileId
      );
      if (students.length === 0 && this.state.pageNo > 1) {
        this.setState((prevState) => ({
          deletedProfileId: -1,
          isDeleteSuccess: false,
          deleteProfileError: false,
          pageNo: prevState.pageNo - 1,
        }));
        this.getTableData("student", this.state.pageNo - 1);
      } else {
        this.setState((prevState) => ({
          students: prevState.students.filter(
            (student) => parseInt(student.id) !== this.state.deletedProfileId
          ),
          deletedProfileId: -1,
          isDeleteSuccess: false,
          deleteProfileError: false,
        }));
      }
    } else {
      const teachers = this.state.teachers.filter(
        (teacher) => parseInt(teacher.id) !== this.state.deletedProfileId
      );
      if (teachers.length === 0 && this.state.pageNo > 1) {
        this.setState((prevState) => ({
          deletedProfileId: -1,
          isDeleteSuccess: false,
          deleteProfileError: false,
          pageNo: prevState.pageNo - 1,
        }));
        this.getTableData("teacher", this.state.pageNo - 1);
      } else {
        this.setState((prevState) => ({
          teachers: prevState.teachers.filter(
            (teacher) => parseInt(teacher.id) !== this.state.deletedProfileId
          ),
          deletedProfileId: -1,
          isDeleteSuccess: false,
          deleteProfileError: false,
        }));
      }
    }
  }
  switchToTeacherView(){
    this.setState({
      isLoading:true,
      pageNo:1,
      tabSelected:0
    })
    this.getTableData("teacher",1);
   
  }
  switchToStudentView(){
    this.setState({
      isLoading:true,
      pageNo:1,
      tabSelected:1
    })
    this.getTableData("student",1);
  }
  switchToView(val: boolean){
    this.setState({
      isLoading:true,
      pageNo:1,
      tabSelected: Number(val)
    })
    this.getTableData(val ? "student" : "teacher", 1);
  }

  showAddStudentForm=(type:string, id:string)=>{
    this.getFormClassList()
    if(type=="EDIT"){
      const studentDetails = this.state.students.find(student => {
              return student.id === id
            })
            this.setState({
              render:"Add Student",
              studentDetails: studentDetails
            })
    }else{
      this.setState({
        render:"Add Student",
        studentDetails: {}
      })
    }
    
  }
  getTeacherStudents(page=1,value="",profileStatus=""){
    this.setState({
      isLoading:true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTeacherStudentCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherStudents}?full_name=${this.state.searchInput}&page=${page}&per_page=10&date_created=${value}&profile_status=${profileStatus}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleTeacherStudentsResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string
  ) {
    if (
      apiRequestCallId != null &&
      ( apiRequestCallId === this.apiTeacherStudentCallId )
    ) {
      if (responseJson) {
        if(responseJson.data){
          const students = responseJson.data.map((data: any) => {
            return {
              id: data.id,
              fullName: data.attributes.full_name,
              email: data.attributes.email,
              phone: data.attributes.full_phone_number || " ",
              password:data.attributes.user_password,
              profileStatus:data.attributes.profile_status,
              classes: data.attributes.classes.map((classData: any) => {
                return {
                  id: classData.id,
                  name: classData.attributes.class_name
                }
              }),
              dateCreated: data.attributes.date_created || " ",
              gender:data.attributes.gender || " ",
              birthDate:data.attributes.date_of_birth||""
            };
          });
          this.setState({
            students: students,
            pageLimitStudent: responseJson.meta.total_page,
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.setState({
      isLoading:false
    })
  }
  handleTeacherStudentsPageChange(event: any, page: number) {
    this.setState({
      pageNoStudent: page,
    });
    this.getTeacherStudents(page);
  }
  debouncedTeacherStudentsSearch = debounce(() => {
    this.getTeacherStudents();
  }, 1000);
  handleTeacherStudentsSearchInput = (value:any) => {
    this.setState({
          searchInput:value,
        pageNoStudent: 1,
      },
      () => {
        this.debouncedTeacherStudentsSearch();
      }
    )
};
deleteStudentProfileApi(id:string) {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
    this.apiDeleteStudentProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteStudentAccount}?account_id=${id}`
    );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.DELETE
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleDeleteStudentProfileResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    apiRequestCallId === this.apiDeleteStudentProfileApiCallId
  ) {
    if (responseJson.errors) {
      return;
    }
    if (responseJson.id) {
      this.handleCloseDeleteProfileModal()
      this.handleOpenDeleteSuccessModal()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleOpenDeleteSuccessModal(){
  this.setState({
    isDeleteSuccess:true
  })
}
handleCloseDeleteSuccessModal(){
  this.getTeacherStudents()
  this.setState({
    isDeleteSuccess:false
  })
}
handleOpenDeleteProfileModal(){
  this.setState({
    openDeleteStudentModal:true
  })
}
handleCloseDeleteProfileModal(){
  this.setState({
    openDeleteStudentModal:false
  })
}
getFormClassList(): boolean {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getFormClassListApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getFormClassList}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.GET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleGetFormClassListResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (apiRequestCallId != null && apiRequestCallId === this.getFormClassListApiCallId) {
    if (responseJson.data[0]) {
      const classList = responseJson.data.map((data: any) => {
        return {
          id: data.id,
          name: data.attributes.class_name,
        }
      })
      this.setState({
        classList:classList
      });
    }
    if (!responseJson.data[0]) {
      this.setState({
        classList:[]
      });
    }
    else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
createStudentByTeacher(data: any): boolean {
  const student = {
    full_name: data.fullName,
    date_of_birth: data.birthDate,
    gender: data.gender,
    email: data.email,
    full_phone_number: data.phone,
    new_password: data.fieldPassword,
    confirm_password: data.fieldPassword,
    class_id: data.classId,
    profile_status: data.profileStatus
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(student)
  );
  this.createStudentByTeacherApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.createStudent}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.POST
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
updateStudentByTeacher(data: any): boolean {
  const student: any = {
    full_name: data.fullName,
    date_of_birth: data.birthDate,
    gender: data.gender,
    profile_status: data.profileStatus,
    email: data.email,
    full_phone_number: data.phone,
    class_id: data.classId,
  }
  if (data.currentPassword && data.fieldPassword && data.confirmPassword) {
    student["current_password"] = data.currentPassword;
    student["new_password"] = data.fieldPassword;
    student["confirm_password"] = data.confirmPassword;
  }

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: window.localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(student)
  );

  this.updateStudentByTeacherApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.updateStudent}?id=${data.id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PUT
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleCreateStudentResponse(
  apiRequestCallId: string,
  responseJson: any,
  errorReponse: string) {
  if (
    apiRequestCallId != null &&
    (apiRequestCallId === this.createStudentByTeacherApiCallId || apiRequestCallId===this.updateStudentByTeacherApiCallId)
  ) {
    if(responseJson.error && responseJson.error==="Request size exceeds the 2 GB limit."){      
        this.setState({limitExceeded:true})
  } 
    if(responseJson.errors){
      if(responseJson.errors.email) {
        this.setState({emailError:true})
        }
        
      if(responseJson.errors==="Invalid Password") {
          this.setState({passwordError:true})
      }
      return
    }
    if (responseJson) {
      this.handleOpenCreateStudentSuccess()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
handleCloseAddEditStudentView=()=>{
  this.setState({
    render:""
  })
}
handleOpenCreateStudentSuccess(){
  this.setState({
    createSuccessModal:true
  })
}
handleCloseCreateStudentSuccess(){
  this.getTeacherStudents();
  this.setState({
    createSuccessModal:false,
    render:"",
    limitExceeded:false,
    pageNoStudent: 1
  })
}
handleDateFilter(value:string){
this.getTeacherStudents(1,value)
}
handleStatusFilter(value:string){
  this.getTeacherStudents(1,"",value)
  }
handleResetError(){
  this.setState({
    passwordError:false,
    emailError:false
  })
}
}
