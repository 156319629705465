import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  Grid,
  Box,
  NativeSelect,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Chip,
  Modal
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { BootstrapInput } from "../../../CustomisableUserProfiles/src/MyProfile/MyProfile.web";
import GenericButton from "../../../../components/src/GenericButton.web";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import moment from "moment";
import {errorAlert} from "../assets";
import CreateStudentSuccess from "./CreateStudentSuccess.web";
const configJSON = require("../config");
const useStyles = makeStyles((theme) => ({
  studentPasswordTitle: {
    fontSize: "32px",
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  errorLayout: {
    display: "flex",
    marginTop: "10px",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  studentBtnLayout: {
    display: "flex",
    justifyContent: "end",
    padding: "50px 0",
  },
  inputValue: {
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Poppins',
      color: '#2C2C2E',
      fontSize: "16px",
    },
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#3F526D",
  },
  matched: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "green",
    fontWeight: 400,
  },
  container: {
    padding: "0 35px",
  },
  multiSelect: {
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Poppins',
      color: '#2C2C2E',
      fontSize: "16px",
    },
    width: "100%",
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1C1E",
  },
  errorAlertIcon: {
    width: "18px",
    height: "18px",
    marginRight: "10px",
  },
  customChip: {
    margin: theme.spacing(0.5),
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
  classOptions: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#3F526D",
  },
}));
const genderOptions = ["Male", "Female", "Other"];
const profileOptions = ["Complete", "Incomplete"];

const AddStudent = (props: any) => {
  const {
    createStudentByTeacher,
    classList,
    studentDetails,
    updateStudentByTeacher,
    handleCloseAddEditStudentView,
    handleCloseCreateStudentSuccess,
    createSuccessModal,
    passwordError,
    handleResetError,
    emailError,
    limitExceeded
  }=props
  const initialClassNames = studentDetails?.classes?.map((item: any) => ({
    id: item.id,
    name: item.name,
  }));
  const classes = useStyles();
  const isUpdateRequest = Object.keys(studentDetails).length;
 const [showPassword,setShowPassword]=useState(false)
 const [showConfirmPassword,setShowConfirmPassword]=useState(false)
 const [showCurrentPassword, setCurrentShowPassword] =
    React.useState<boolean>(false);
 const [selectedClassOptions,setSelectedClassOptions]=useState(initialClassNames?initialClassNames:[])
 const [isMatched, setIsMatched] = React.useState(false);
 const [errors,setErrors]=React.useState<any>({})
 const [formData,setFormData]=useState<any>(studentDetails)

  const handleDateChange = (date:any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    handleChange({ target: { name: "birthDate", value: formattedDate } });
  };
  const initBirthDate = () => {
    return formData.birthDate
      ? moment(formData.birthDate, "DD/MM/YYYY", true).toDate()
      : undefined;
  };
  
  const handleInputChange = (_: any, value: any) => {
    if (value.length === 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["class"]: "Invalid input",
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors["class"];
        return newErrors;
      });
    }
    setSelectedClassOptions(value);
  };
  const renderVisibilityIcon = (fieldType: boolean) => {
    if (fieldType) {
      return <Visibility />;
    } else {
      return <VisibilityOff />;
    }
  };
  const handleShowPassword=()=>{
    setShowPassword(!showPassword)
  }
  const handleShowConfirmPassword=()=>{
    setShowConfirmPassword(!showConfirmPassword)
  }
  const handleClickShowCurrentPassword = () => {
    setCurrentShowPassword(!showCurrentPassword);
  };
  const handlePasswordConfirmation = (e: any) => {
    const { name, value } = e.target;
    if (formData.fieldPassword && name === "confirmPassword") {
      if (formData.fieldPassword !== value) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: "Invalid input",
        }));
        setIsMatched(false);
      } else {
        setErrors((prevErrors: any) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
        setIsMatched(true);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePasswordChange = (e: any) => {
    const { name, value } = e.target;
    const fieldPasswordValidation= /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/
    handleChange(e);
    if (formData.confirmPassword && name === "fieldPassword") {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors["confirmPassword"];
        return newErrors;
      });
   
      if (formData.confirmPassword !== value) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["confirmPassword"]: "Invalid input",
        }));
        setIsMatched(false);
      }
      else if(formData.confirmPassword === value && !value.match(fieldPasswordValidation)){
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fieldPassword"]: "Invalid input",
        }));
        setIsMatched(false);
      } else {
        setErrors((prevErrors: any) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
        setIsMatched(true);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleChange =(e:any)=>{
    handleResetError()
    const {name,value}=e.target
    const validationRules: any = {
      fullName: /^.+$/,
      birthDate: /^.+$/,
      profileStatus: /^.+$/,
      email: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]+/,
      fieldPassword:
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
    };
    if (name === "fieldPassword") {
      setIsMatched(false);
    }
    if (!value.match(validationRules[name])) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Invalid input",
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const isNonPasswordChangeFieldsPresent = () => {
    if (
      formData.fullName &&
      formData.email &&
      formData.profileStatus &&
      selectedClassOptions.length > 0
    ) {
      return true;
    } else {
      if (!formData.fullName) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["fullName"]: "Invalid input",
        }));
      }
      if (!formData.email) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["email"]: "Invalid input",
        }));
      }
      if (!formData.profileStatus) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["profileStatus"]: "Invalid input",
        }));
      }
      if (selectedClassOptions.length === 0) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["class"]: "Invalid input",
        }));
      }
      return false;
    }
  };
  const checkForPasswordChangeFieldErrors = () => {
    if (!formData.fieldPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["fieldPassword"]: "Invalid input",
      }));
    }
    if (!formData.confirmPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["confirmPassword"]: "Invalid input",
      }));
    }
    if (!formData.currentPassword) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["currentPassword"]: "Invalid input",
      }));
    }
  };
  const isEditMandatoryFieldsPresent = () => {
    if (
      formData.fieldPassword === undefined &&
      formData.confirmPassword === undefined &&
      formData.currentPassword === undefined
    ) {
      return isNonPasswordChangeFieldsPresent();
    } else if (
      !(
        formData.fieldPassword &&
        formData.confirmPassword &&
        formData.currentPassword
      )
    ) {
      checkForPasswordChangeFieldErrors();
      return false;
    } else {
      return true;
    }
  };  
  const handleDeleteOption = (optionToDelete: any) => {
    const updatedOptions = selectedClassOptions.filter(
      (option: any) => option.id !== optionToDelete.id
    );
    setSelectedClassOptions(updatedOptions);
  };
  const isAllCreateMandatoryFieldsPresent = () => {
    const mandatoryFields = ["fullName", "email", "fieldPassword", "profileStatus","confirmPassword"];
    let isAllFieldsValid = true;
    setErrors({});
    mandatoryFields.forEach((field) => {
        if (!formData[field]) {
            setErrors((prevErrors:any) => ({
                ...prevErrors,
                [field]: "Invalid input",
            }));
            isAllFieldsValid = false;
        }
    });

    if (selectedClassOptions.length === 0) {
        setErrors((prevErrors:any) => ({
            ...prevErrors,
            class: "Invalid input",
        }));
        isAllFieldsValid = false;
    }
    if(!isMatched){
      isAllFieldsValid = false;
    }
    return isAllFieldsValid;
};

  const renderPasswordType = (fieldType: boolean) => {
    if (fieldType) {
      return "text";
    } else {
      return "password";
    }
  };
  const isMandatoryFieldsPresent = () => {
    if (isUpdateRequest) {
      return isEditMandatoryFieldsPresent();
    } else {
      return isAllCreateMandatoryFieldsPresent();
    }
  };
  const createStudent = (e: any) => {
    if (!isMandatoryFieldsPresent()) return;
    if (Object.keys(errors).length) return;
    const studentData: any = {
      fullName: formData.fullName,
      email: formData.email,
      gender: formData.gender,
      phone: formData.phone,
      birthDate: formData.birthDate,
      fieldPassword: formData.fieldPassword,
      profileStatus: formData.profileStatus,
      classId: selectedClassOptions.map((item: any) => item.id),
    };
    if (isUpdateRequest) {
      studentData["id"] = studentDetails.id;
      studentData["currentPassword"] = formData.currentPassword;
      studentData["fieldPassword"] = formData.fieldPassword;
      studentData["confirmPassword"] = formData.confirmPassword;
      updateStudentByTeacher(studentData);
    } else {
      createStudentByTeacher(studentData);
    }
      
  };
  
  return (
    <Box sx={{ px: "35px", mt: "20px" }}>
      <form>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="fullName" className={classes.inputLabel}
                  >
                    Full Name*
                  </label>
                  <TextField
                    id="fullName"
                    data-test-id="fullname"
                    name="fullName"
                    data-testid={"fullName"}
                    fullWidth
                    placeholder="Enter Your Name"
                    variant="outlined"
                    value={formData.fullName}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                    onChange={handleChange}
                  />
                  {Boolean(errors.fullName) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.fullName,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="birthDate"
                    className={classes.inputLabel}
                  >
                    Date of Birth (optional)
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        id="birthDate"
                        name="birthDate"
                        variant="dialog"
                        data-test-id={"birthDate"}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        value={initBirthDate()}
                        labelFunc={() =>
                          formData.birthDate || "Enter Birth Date"
                        }
                        fullWidth
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="gender" className={classes.inputLabel}>
                    Gender (optional)
                  </label>
                  <NativeSelect
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    input={<BootstrapInput />}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {genderOptions.map((option) => (
                      <option
                        key={option}
                        value={option.toLowerCase()}
                        className={classes.inputValue}
                      >
                        {option}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="profileStatus" className={classes.inputLabel}>
                    Profile Status*
                  </label>
                  <NativeSelect
                    id="profileStatus"
                    name="profileStatus"
                    value={formData.profileStatus}
                    input={<BootstrapInput />}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {profileOptions.map((option) => (
                      <option
                        key={option}
                        value={option.toLowerCase()}
                        className={classes.inputValue}
                      >
                        {option}
                      </option>
                    ))}
                  </NativeSelect>
                  {Boolean(errors.profileStatus) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: "Please select profile status.",
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="email"
                    className={classes.inputLabel}
                  >
                    Email ID*
                  </label>
                  <TextField
                    id="email"
                    name="email"
                    data-testid={"email"}
                    fullWidth
                    placeholder="Enter Email"
                    variant="outlined"
                    value={formData.email}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                    onChange={handleChange}
                  />
                  {emailError && !Boolean(errors.email) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: "The email has already been taken",
                        }}
                      />
                    </Box>
                  )}
                  {Boolean(errors.email) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: "Please enter a valid email ID.",
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="phone"
                    className={classes.inputLabel}
                  >
                    Mobile Number (optional)
                  </label>
                  <TextField
                    id="phone"
                    name="phone"
                    data-testid={"phone"}
                    fullWidth
                    placeholder="Enter Your Phone"
                    variant="outlined"
                    value={formData.phone}
                    InputProps={{
                      className: classes.inputValue,
                    }}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="class" className={classes.inputLabel}>
                    Class*
                  </label>
                  <Autocomplete
                    id="class"
                    data-test-id="class-input"
                    fullWidth
                    options={classList}
                    multiple
                    getOptionLabel={(option: any) => option.name}
                    value={selectedClassOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Classes"
                        className={classes.multiSelect}
                      />
                    )}
                    onChange={handleInputChange}
                    renderTags={(value, getTagProps) =>
                      
                      value.map((option: any, index) => (
                        
                        <Chip
                          data-test-id="class-input-chip"
                          label={option.name}
                          {...getTagProps({ index })}
                          onDelete={() => handleDeleteOption(option)}
                          className={classes.customChip}
                        />
                      ))
                    
                    }
                    renderOption={(option: any, { selected }) => (
                      <React.Fragment>
                        <Typography className={classes.classOptions}>
                          {option.name}
                        </Typography>
                      </React.Fragment>
                    )}
                    PopperComponent={(props) => (
                      <div {...props} style={{ zIndex: 2000 }} />
                    )}
                  />
                  {Boolean(errors.class) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: "Please select class",
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.studentPasswordTitle}>
           { isUpdateRequest? "Change Password":"Profile Password"}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={5}>
            {isUpdateRequest ? (
                <>
                  <Grid item md={6}>
                    <FormControl fullWidth variant="outlined">
                      <label
                        className={classes.inputLabel}
                        htmlFor="currentPassword"
                      >
                        Current Password
                      </label>
                      <OutlinedInput
                        id="currentPassword"
                        name="currentPassword"
                        data-testid={"currentPassword"}
                        fullWidth
                        placeholder="Enter Your Current Password"
                        onChange={handleChange}
                        value={formData.currentPassword}
                        className={classes.inputValue}
                        type={renderPasswordType(showCurrentPassword)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id={"show-current-password"}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassword}
                              edge="end"
                            >
                              {renderVisibilityIcon(showCurrentPassword)}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(errors.currentPassword) && (
                        <Box className={classes.errorLayout}>
                          <img
                            className={classes.errorAlertIcon}
                            src={errorAlert}
                          />
                          <Typography
                            className={classes.error}
                            dangerouslySetInnerHTML={{
                              __html: configJSON.formErrors.currentPassword,
                            }}
                          />
                        </Box>
                      )}
                      {passwordError && !Boolean(errors.currentPassword)&& (
                        <Box className={classes.errorLayout}>
                          <img
                            className={classes.errorAlertIcon}
                            src={errorAlert}
                          />
                          <Typography
                            className={classes.error}
                            dangerouslySetInnerHTML={{
                              __html: "Invalid Password",
                            }}
                          />
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="fieldPassword"
                    className={classes.inputLabel}
                  >
                    Enter Password
                  </label>
                  <OutlinedInput
                    id="fieldPassword"
                    name="fieldPassword"
                    data-test-id={"fieldPassword"}
                    fullWidth
                    placeholder="New Password"
                    onChange={handlePasswordChange}
                    type={renderPasswordType(showPassword)}
                    value={formData.fieldPassword}
                    className={classes.inputValue}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id={"show-password"}
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {renderVisibilityIcon(showPassword)}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                   {Boolean(errors.fieldPassword) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: `Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)`,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant="outlined">
                  <label
                    htmlFor="confirmPassword"
                    className={classes.inputLabel}
                  >
                    Confirm Password
                  </label>
                  <OutlinedInput
                    id="confirmPassword"
                    data-test-id="password-confirmation"
                    name="confirmPassword"
                    fullWidth
                    placeholder="Confirm password"
                    className={classes.inputValue}
                    onChange={handlePasswordConfirmation}
                    type={renderPasswordType(showConfirmPassword)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id={"show-confirm-password"}
                          aria-label="toggle password visibility"
                          onClick={handleShowConfirmPassword}
                          edge="end"
                        >
                          {renderVisibilityIcon(showConfirmPassword)}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {isMatched && !Boolean(errors.fieldPassword) ? (
                    <Typography
                      className={classes.matched}
                      dangerouslySetInnerHTML={{
                        __html: "The password and confirm password matched successfully.",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {Boolean(errors.confirmPassword) && (
                    <Box className={classes.errorLayout}>
                      <img
                        className={classes.errorAlertIcon}
                        src={errorAlert}
                      />
                      <Typography
                        className={classes.error}
                        dangerouslySetInnerHTML={{
                          __html: configJSON.formErrors.confirmPassword,
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Box className={classes.studentBtnLayout}>
              <GenericButton
                type="NORMAL"
                label="Cancel"
                data-test-id="cancel-btn"
                handleClick={handleCloseAddEditStudentView}
                customStyle={{ width: "165px" }}
              />
              <GenericButton
                type="COLORED"
                data-test-id="save-add-btn"
                label={isUpdateRequest?"Save":"Add"}
                handleClick={(e)=>{createStudent(e)}}
                customStyle={{ width: "165px" }}
              />
              <Modal open={createSuccessModal} onClose={handleCloseCreateStudentSuccess}>
                <CreateStudentSuccess
                  fullName={formData.fullName}
                  isUpdateRequest={isUpdateRequest}
                  data-test-id="success-modal"
                  handleCloseModal={handleCloseCreateStudentSuccess}
                  limitExceeded={limitExceeded}
                />
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddStudent;
