import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import React from "react";
import { debounce } from "lodash";
import generatePDF from "react-to-pdf";
import { Roles } from "../../../components/src/types";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClick: () => void; 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  view : string;
  classTitle: string;
  searchQuery: string;
  searchStudentQuery: string;
  pageLimit: number;
  pageLimitStudents: number;
  pageNo: number;
  pageNoStudents: number;
  classListData: any;
  studentListData: any[];
  selectedTeacher: any;
  teachersList: any[];
  dashboardLoading: boolean;
  token: string;
  loading: boolean;
  errorMsg: string;
  dashboardData: any;
  studentCount: number;
  teacherCount: number;
  reportType: string;
  dateRange: any;
  teacherId: string;
  classId: string;
  selectStudents: any[],
  summaryReportList:any[],
  studentIdList:any[],
  data:any[],
  selectAll:boolean,
  selectedIds:any[],
  openModal:boolean,
  csvSucessShow:boolean,
  detailReportList:any[],
  selectedStudent:number,
  visiblevalue:boolean;
  startDate: any;
  endDate: any;
  internalDate: any;
  isSmallScreen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  studentsDetailReportRef: any;
  apiDashboardItemCallId: string = "";
  classListApiCallId: string = "";
  studentListAPICallId: string = ''
  summaryReportListAPICallId: string = ''
  detailReportListAPICallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: '',
      view: "teacherList",
      classTitle: "",
      searchQuery: "",
      searchStudentQuery: "",
      pageLimit: 1,
      pageLimitStudents: 1,
      pageNo: 1,
      pageNoStudents: 1,
      classListData: [],
      studentListData: [],
      selectedTeacher: {},
      teachersList: [],
      dashboardLoading: true,
      token: "",
      loading: false,
      errorMsg: "",
      dashboardData: [],
      studentCount: 0,
      teacherCount: 0,
      reportType: '',
      dateRange: '',
      teacherId: '',
      classId: '',
      selectStudents: [],
      summaryReportList:[],
      studentIdList:[],
      data:[],
      selectAll:false,
      selectedIds:[],
      openModal:false,
      csvSucessShow:false,
      detailReportList:[],
      selectedStudent:0,
      visiblevalue:false,
      startDate: "",
      endDate: "",
      internalDate: null,
      isSmallScreen: window.innerWidth <= 600,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.studentsDetailReportRef = React.createRef();
    this.getDetailReportListData = this.getDetailReportListData.bind(this);
    this.getSummaryReportListData = this.getSummaryReportListData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.getStudentListData = this.getStudentListData.bind(this);
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    {getName(MessageEnum.SessionResponseMessage) === message.id &&
      this.renderDashboardData(message)}
  
      {(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.apiDashboardItemCallId != null &&
        this.apiDashboardItemCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) &&
        this.renderTeachersData(message) }
  
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.classListApiCallId != null &&
      this.classListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            this.setState({classListData: responseJson?.data})
          }else {
            const errorResponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
          }
          this.setState({
            dashboardLoading: false
          });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.studentListAPICallId != null &&
      this.studentListAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            this.setState({studentListData: responseJson.data, pageLimitStudents: responseJson?.meta?.total_page})
          }else {
            this.setState({studentListData:[],pageLimitStudents:1})
            const errorResponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
          }
        }
        this.otherAPI(message)  
        
    // Customizable Area End 
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    
    let cameFromDashboard = await getStorageData("cameFromDashboard");
    cameFromDashboard = JSON.parse(cameFromDashboard)

    let role = await getStorageData("role");

    if(role ===Roles.SUB_ADMIN ){
      this.getDashboardDataListing();   
      if(cameFromDashboard){
        this.getClassesList({}, {"id":cameFromDashboard.attributes.teacher_id,"image": cameFromDashboard.attributes.attachment, "name": cameFromDashboard.attributes.full_name})
        this.getClassDetails(cameFromDashboard.attributes.class_name, cameFromDashboard.id)
      } 
    }
    this.setState({selectAll:false})
  }
  // commit 
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  renderDashboardData=(message: Message)=>{
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token, loading: true }, () => {
      this.getDashboardDataListing();
    });
  }

  renderTeachersData=(message: Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false,
          studentCount: responseJson.data[0].attributes.student_count,
          teacherCount: responseJson.data[0].attributes.teacher_count,
          teachersList: responseJson.data,
          pageLimit: responseJson.meta.total_page
        });
      }
    } else {
      this.setState({teachersList: []})
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false
        });
      }
    }
    this.setState({
      dashboardLoading: false
    });
  }

  getDashboardDataListing(): boolean {
    
    const headerDashboardAPI = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessageDashboardAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessageDashboardAPI.messageId;
    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetUrl}?per_page=9&full_name=${this.state.searchQuery}&page=${this.state.pageNo}`
    );

    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerDashboardAPI)
    );

    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessageDashboardAPI.id, requestMessageDashboardAPI);
    return true;
  }

  handleBreadCrumb=(view: string)=>{
    this.handleSelectAll()
    this.setState({view: view})
    if(view === "teacherList"){
      this.setState({classListData: [], studentListData: []})
    }  
    if(view === "classList") {
      this.setState({studentListData: []})
    }
    if(view === "studentDetails") {
      this.setState({selectStudents: []})
    }
  }

  handlePageChange = (event: any, page: number) => {
    this.setState({
      pageNo: page
    },
    () => { 
      this.getDashboardDataListing(); 
     });   
  }

  handleStudentsPageChange = (event: any, page: number) => {
    this.setState({
      pageNoStudents: page
    },
    () => { 
      this.getStudentListData(this.state.teacherId, this.state.classId); 
     });   
  }

  getClassesList=(event: any,teacher: any)=>{
    this.setState({view: 'classList'})
    this.getClassListDataListing(teacher.id)
    this.setState({selectedTeacher: {
      id:teacher.id,
      attributes:{
       full_name:teacher.name,
       image:teacher.image
      } 
     }})
    this.setState({
       dashboardLoading: true,
      teacherId: teacher.id
    });
  }

  getClassListDataListing(id: string): boolean {
    const headerClassListData = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessageClassListData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.classListApiCallId = requestMessageClassListData.messageId;
    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classListGetUrl}?teacher_id=${id}`
    );

    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerClassListData)
    );

    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessageClassListData.id, requestMessageClassListData);
    return true;
  }

  getStudentListData(teacherId: string, classId: string, startDate?:any, endDate?:any): boolean {
    let endpoint;
    if(startDate && endDate){
      endpoint = `${configJSON.filterStudentsList}?start_date=${startDate}&end_date=${endDate}&teacher_id=${teacherId}&class_id=${classId}`
    } else {
      endpoint = `${configJSON.studentListGetUrl}?teacher_id=${teacherId}&class_id=${classId}&full_name=${this.state.searchStudentQuery}&page=${this.state.pageNoStudents}`
    }
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleModalClose = (data: any) => {
    this.setState({selectAll:false})
    this.setState({view: "studentDetails",
    reportType: data.reportType,
    dateRange: data.dateRange
  })
  }

  debouncedSearch = debounce(() => {
    this.getDashboardDataListing();
  }, 1000); 

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({ searchQuery: event.target.value,  },
    () => { 
      this.debouncedSearch(); 
    }); 
  };

  debouncedStudentSearch = debounce(() => {
    this.getStudentListData(this.state.teacherId, this.state.classId);
  }, 1000); 

  handleStudentSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({ searchStudentQuery: event.target.value },
    () => { 
      this.debouncedStudentSearch(); 
    }); 
  };

  handleCloseVisible=()=>{
    this.setState({visiblevalue:false, internalDate: null})
    removeStorageData("adminStartDate")
    removeStorageData("adminEndDate")
  }
  handleOpenVisible=(className: string, id: string)=>{
    this.setState({visiblevalue:true})
    this.setState({view: "classDetails"})
    this.setState({classTitle: className, classId: id})
    removeStorageData("adminStartDate")
    removeStorageData("adminEndDate")
  }
  getClassDetails=(className: string, id: string)=>{
    this.setState({visiblevalue:true})
    this.setState({view: "classDetails"})
    this.setState({classTitle: className, classId: id})
    this.getStudentListData(this.state.teacherId, id)
  }
  getTeacherCalendar(id: string) {
    const currentLocation = window.location.toString(); 
    const newLocation = `${currentLocation.replace(/\/dashboard$/, '/teachers')}/${id}`; 
    window.location.href = newLocation;
  }

  handleSubmitSelectStudent = (data: any[],view:string) => {
    this.setState({
      selectStudents: data,
      view: view
    })
  }

  getSummaryReportListData(ids:any,csv?:boolean): boolean {
    let paramsdata = ids.student_ids.join("&student_ids[]=")
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.summaryReportListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `bx_block_statisticsreports2/statisticsreports/generate_lesson_stundents_answers_summary_report?student_ids[]=${paramsdata}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otherAPI=(message:any)=>{
        const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson || responseJson.data){
        this.setState({summaryReportList:responseJson?.data?responseJson?.data:responseJson,detailReportList:responseJson?.data?responseJson?.data:responseJson})
      }else{
        this.setState({summaryReportList:[]})
      }
      
  }


  handleTeacherStudentsPageChange = (event: any, page: number) => {
    this.setState({
      pageNoStudents: page
    })
  }

  handleTeachersStudentSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchStudentQuery: event.target.value },
      () => { 
        this.debouncedStudentSearch(); 
      }); 
  };

  handleSelectAll = () => {
    this.setState({
      selectAll: !this.state.selectAll,
      selectedIds: !this.state.selectAll ? this.state.studentListData?.map((item:any) => item.id) :[],
      studentListData:this.state.studentListData.map((item: any) => {
          return { ...item, selected: !this.state.selectAll }
        })
    });
  };



  handleChange = (record:any) => {
    
    const newData = this.state.studentListData.map(item => {
      return item.id === record.id ? { ...item, selected: !item.selected } : item;
    });
    const selectedIds = newData.filter(item => item.selected).map(item => item.id);
    if(this.state.studentListData.length==selectedIds.length){
      this.setState(
        { selectAll: true });
    }else{
      this.setState(
        { selectAll: false });
    }
    this.setState(
    { studentListData: newData, selectedIds: selectedIds });
  };

  handleCloseModal=()=>{
    this.setState({ openModal: false });
  }

  handleOpenModal=()=>{
  
    this.setState({ openModal: true });
  }

  handleDownloadPDF = () => {
    // Get element
    // const targetElement = document.getElementById("students-detail-report-container");
    const targetElement: HTMLElement | null = this.studentsDetailReportRef.current;
    // Change the height and generate the PDF
    targetElement && (targetElement.style.height = "auto");
    const getTargetElement = () => targetElement;
    generatePDF(getTargetElement, {
      filename: "students-detail-report.pdf",
      resolution: 2,
      overrides: {
        pdf: {
          compress: true
        }
      }
    });
    // Reset the height
    targetElement && (targetElement.style.height = "100vh");
    // Close the modal
    setTimeout(this.handleCloseModal, 1500);
  }

  getExportReportApicall = () => {
    
  if(this.state.summaryReportList && this.state.summaryReportList[0]){
      const csvContent = "data:text/csv;charset=utf-8," 
          + Object.keys(this.state.summaryReportList[0]).map(key => key).join(",") + "\n" +
          this.state.summaryReportList.map(row => Object.values(row).join(",")).join("\n");  
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "SummaryReportList.csv");
      document.body.appendChild(link);
      link.click();
      this.setState({csvSucessShow:true})
      setTimeout(() => this.handleCloseModal(),1500)
  }
  };

  getDetailReportListData(ids:any): boolean {
   
    let paramsdata = ids.student_ids.join("&student_ids[]=")
    
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.detailReportListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deatilReportList+"?student_ids[]="+paramsdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.get
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSelection(index:number) {
    this.setState({ selectedStudent: index });
  }

  handleScroll = () => {
    const studentDetailContainer = document.getElementById("abc");
  
    if (studentDetailContainer) {
      const studentSections = Array.from(studentDetailContainer.querySelectorAll('[id^="stundent-"]'));
      
      let selectedIndex = -1;
  
      studentSections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const topPos = rect.top + studentDetailContainer.scrollTop;
  
        if (studentDetailContainer.scrollTop >= topPos - 300) {
          selectedIndex = index;
        }
      });
  
      if (selectedIndex !== -1 && this.state.selectedStudent !== selectedIndex) {
        this.setState({ selectedStudent: selectedIndex });
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setInternalDate = (internalDate:any) => {
    this.setState({
      internalDate
    })
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };
  
   fetchDatesAndGetDetails = async () => {
    const startDate = await getStorageData("adminStartDate");
    const endDate = await getStorageData("adminEndDate");
    
    if (startDate && endDate) {
      this.getStudentListData(this.state.teacherId, this.state.classId, startDate, endDate);
    } else {
      this.getStudentListData(this.state.teacherId, this.state.classId);
    }
  };
  
  
  // Customizable Area End
}
