import React from 'react';
const ReactWordcloud = require("react-wordcloud").default
import {Spiral} from 'react-wordcloud'

interface Props {
  words: {
    text: string;
    value: number;
  }[];
}

const CustomWordCloud: React.FC<Props> = ({ words }) => {
  const options = {
    spiral: 'archimedean' as Spiral,
    rotations: 0,
    rotationAngles: [0, 90] as [number, number],
    enableTooltip: false,
    deterministic: true,
    fontFamily: 'Poppins',
    fontSizes: [10, 60] as [number, number],
  };
  return <ReactWordcloud words={words} options={options}/>
};

export default CustomWordCloud;
