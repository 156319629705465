import React, { useEffect, useState } from "react";
import { Modal, Box, Button, FormControl, RadioGroup, Switch, Popover, IconButton , Grid, Divider,Typography,styled,FormControlLabel,Radio } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddBackgroundImageModal from "../../../../CFWordCloud/src/Teacher/components/AddBackgroundImageModal.web";
import {whiteSetTimer, Cross, AddCircleIcon, settingBtn, points, addBackground, whiteAddBackground, blueTimer, whitePoints, whiteDoublePoint, whiteMusic, blueDoublePoint, blueMusicBtn, timer } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
import StorageProvider from "../../../../../framework/src/StorageProvider";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { minutesDec, minutesInc, secondsDec, secondsInc } from "./Mcq.web";

interface SetTime {
  minutes: string;
  seconds: string;
}
interface Option {
  id: number;
  text: string;
}
interface IProp{
  updateTime: (minutes:string, seconds:string) => void;
  getOptions:(data:any)=>void;
  pollState: any;
  preview:string | null;
  setPreview:(url: string | null) => void;
  handlePollChange:(value: boolean, name: string) => void;
  pollSettingState: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean,
  };
  multipleOption:boolean;
  setMultipleOption:(value:boolean)=>void;
  setBackgroundImage?:(image:any)=>void
}
interface ButtonOption {
  label: string;
  value: boolean;
}


const handleMouseLeave = (setIsHovered: (arg0: boolean) => void) => {
  setIsHovered(false);
};

const pageType = async (setpageTypeData:(data: string) => void) => {
  let quizName = await StorageProvider.get("pageTypeData")
  setpageTypeData(quizName)
}



const SettingBtn = (isRunningTime: boolean,timeSet: SetTime,handleClick: React.MouseEventHandler<HTMLButtonElement>,pageTypeData:string | undefined ) => {
  const classes = useStyles();
  return(
    <>
    {pageTypeData == "quizName" && <Box className={classes.settingBtn} >
      <Box style={{ display: "flex" }}>
        {isRunningTime &&
          <Box className={classes.mainTimerBox}>
            <Box className={classes.clockTimerFirst}>
              <img className={classes.heightStyle} src={whiteSetTimer} />
            </Box>
            <Box className={classes.secondTimer}>
              <Typography className={classes.blackColor}>{timeSet.minutes}</Typography>
              <Typography className={classes.blackColor}>{`:${timeSet.seconds}`}</Typography>
            </Box>
          </Box>
        }
      </Box>
      <IconButton data-test-id="popOverTestId" onClick={handleClick}>
        <img src={settingBtn} />
      </IconButton>
    </Box>}
    </>
  )
}

const CounterTime = (isTimerHovered: boolean,setIsTimerHovered: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; (arg0: boolean): void; },handleModalTimerOpen: React.MouseEventHandler<HTMLElement> | undefined) => {
  const classes = useStyles();
  return(
    <Box className={classes.popupElements}>
      <Box className={classes.popupItems}
        onMouseEnter={() => handleTimerEnter(setIsTimerHovered)}
        onMouseLeave={() => handleTimerLeave(setIsTimerHovered)}
        data-test-id="handlemodalTestId"
        onClick={handleModalTimerOpen}>
        <img src={isTimerHovered ? timer : blueTimer} />
        <Typography className={classes.popupHeading}>Add Countdown Timer</Typography>
      </Box>
    </Box>
  )
}

const DoublePointBtn = (setIsDoubleHovered: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; (arg0: boolean): void; },isDoubleHovered: boolean,props: IProp) => {
  const classes = useStyles();
  return(
    <Box className={classes.popupElements}
      data-test-id="doubleTestId"
      onMouseEnter={() => handleDoublePointEnter(setIsDoubleHovered)}
      onMouseLeave={() => handleDoublePointLeave(setIsDoubleHovered)}>
      <Box className={classes.popupItems}>
        <img src={isDoubleHovered ? whiteDoublePoint : blueDoublePoint} />
        <Typography className={classes.popupHeading} >Double Points</Typography>
      </Box>
      <SwitchPoint data-test-id="doublePointChangeTestId" checked={props.pollSettingState.checkedB} onChange={(event) => props.handlePollChange(event.target.checked, event.target.name)}name="checkedB" />
    </Box>
  )
}

const BackgroundImg = (isHovered: boolean,setIsHovered: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; (arg0: boolean): void; },handleModalOpen: React.MouseEventHandler<HTMLElement>) => {
  const classes = useStyles();
  return(
    <Box className={classes.popupElements}>
      <Box className={classes.popupItems}
        onMouseEnter={() => handleMouseEnter(setIsHovered)}
        onMouseLeave={() => handleMouseLeave(setIsHovered)}
        onClick={handleModalOpen}
        data-test-id="isHoverTesxtId">
        <img src={isHovered ? whiteAddBackground : addBackground} />
        <Typography className={classes.popupHeading} >Add Background Image</Typography>
      </Box>
    </Box>
  )
}

const handleTimerLeave = (setIsTimerHovered: (arg0: boolean) => void) => {
  setIsTimerHovered(false);
};

const handlePointsHovered = (setIsPointsHovered: (arg0: boolean) => void) => {
  setIsPointsHovered(true);
}

const handleMouseEnter = (setIsHovered: (arg0: boolean) => void) => {
  setIsHovered(true);
};

const handlePointsLeave = (setIsPointsHovered: (arg0: boolean) => void) => {
  setIsPointsHovered(false);
}

const handleTimerEnter = (setIsTimerHovered: (arg0: boolean) => void) => {
  setIsTimerHovered(true);
};

const handleDoublePointEnter = (setIsDoubleHovered: (arg0: boolean) => void) => {
   setIsDoubleHovered(true)
}


const SecondText = (seconds: number,minutes: number,setMinutes: { (value: React.SetStateAction<number>): void; (value: number | ((clockpreMinutes: number) => number)): void; },setSeconds: { (value: React.SetStateAction<number>): void; (value: number | ((prevSeconds: number) => number)): void; },formattedSecondsDecremented: {}  ,formattedSecondsIncremented: {} ) => {
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnPollIncSeconds'
        onClick={()=> secondsInc(seconds, minutes, setSeconds, setMinutes)}
      />
      <Typography className="passiveTime">{formattedSecondsDecremented}</Typography>
      <input
        className="inpt"
        id="minute"
        min={0}
        max={59}
        value={seconds < 10 ? "0" + seconds : seconds}
        onWheel={(event) => handleSetScroll(event, "seconds", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedSecondsIncremented}</Typography>
      <ArrowDropDownIcon 
        data-test-id='btnPollDecSeconds'
        style={{width:'32px', height: '32px'}}
        onClick={()=> secondsDec(seconds, minutes, setSeconds, setMinutes)}
      />
    </div>
  )
}


const MinuteText = (minutes: number ,seconds: number ,setMinutes: ((value: number | ((clockpreMinutes: number) => number)) => void) ,setSeconds: ((value: number | ((prevSeconds: number) => number)) => void) ,formattedMinutesDecremented: {},formattedMinutesIncremented: {}) =>{
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnPollIncMinutes'
        onClick={()=> minutesInc(minutes, setMinutes)}
      />
      <Typography className="passiveTime">{formattedMinutesDecremented}</Typography>
      <input
        data-test-id="handleScrollTestId"
        className="inpt"
        id="hour"
        min={0}
        max={23}
        value={minutes < 10 ? "0" + minutes : minutes}
        onWheel={(event) => handleSetScroll(event, "minutes", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedMinutesIncremented}</Typography>
      <ArrowDropDownIcon 
        data-test-id='btnPollDecMinutes'
        style={{width:'32px', height: '32px'}}
        onClick={()=> minutesDec(minutes, setMinutes)}
      />
    </div>
  )
}

const handleCloseModal = (setModalOpen: (arg0: boolean) => void) => {
  setModalOpen(false);
}

const handleMusicEnter = (setIsMusicHovered: (arg0: boolean) => void) => {
  setIsMusicHovered(true)
}

const handleDoublePointLeave = (setIsDoubleHovered: (arg0: boolean) => void) => {
  setIsDoubleHovered(false)
}

const handleMusicLeave = (setIsMusicHovered: (arg0: boolean) => void) => {
  setIsMusicHovered(false)
}

let timesetOut: NodeJS.Timeout | null = null;

const clearExistingTimesetOut = () => {
  if (timesetOut !== null) {
    clearTimeout(timesetOut);
  }
};

export const handleSetMinutes = (
  clockDelta: number,
  minutes: number,
  setMinutes: (value: number | ((clockpreMinutes: number) => number)) => void
) => {
  if (clockDelta === 1 && minutes === 23) {
    setMinutes(0);
  } else if (clockDelta === -1 && minutes === 0) {
    setMinutes(23);
  } else {
    setMinutes((clockpreMinutes) => Math.min(Math.max(clockpreMinutes + clockDelta, 0), 23));
  }
};

export const handleSetSeconds = (
  clockDelta: number,
  seconds: number,
  setMinutes: (value: number | ((clockpreMinutes: number) => number)) => void,
  setSeconds: (value: number | ((prevSeconds: number) => number)) => void
) => {
  if (clockDelta === 1 && seconds === 59) {
    setMinutes((clockpreMinutes) => (clockpreMinutes + 1) % 24);
    setSeconds(0);
  } else if (clockDelta === -1 && seconds === 0) {
    setMinutes((clockpreMinutes) => (clockpreMinutes - 1 + 24) % 24);
    setSeconds(59);
  } else {
    setSeconds((prevSeconds) => Math.min(Math.max(prevSeconds + clockDelta, 0), 59));
  }
};

const QuestionOptions = (options: { id: number, text: string }[],handleChangeOptionText: (id: number, text: string) => void) => {
  const classes = useStyles();
  return(
    <Box className={classes.mainPollWrapper}>
      <FormControl className={classes.formPollWrapper} component="fieldset">
        <RadioGroup>
          {options?.map((option) => (
            <Box style={{ marginTop: "60px" }}>
              <span className={classes.optionPollHead}>{`Option ${option.id}`}</span>
              <div className={classes.optionPollbody} key={option.id}>
                <input
                  className={classes.optionPollinput}
                  type="text"
                  placeholder={`Answer ${option.id} here`}
                  value={option.text}
                  onChange={(e) => handleChangeOptionText(option.id, e.target.value)}
                />
              </div>
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

const PointBtn = (setIsPointsHovered: { (arg0: boolean): void; (arg0: boolean): void; } ,isPointsHovered: boolean,props: IProp  ) => {
  const classes = useStyles();
  return(
    <Box className={classes.popupElements}
      data-test-id="switchpointTestId"
      onMouseEnter={() => handlePointsHovered(setIsPointsHovered)}
      onMouseLeave={() => handlePointsLeave(setIsPointsHovered)}>
      <Box className={classes.popupItems}>
        <img src={isPointsHovered ? whitePoints : points} />
        <Typography className={classes.popupHeading}>Points</Typography>
      </Box>
      <Grid item>
        <SwitchPoint data-test-id="changePointTestId" checked={props.pollSettingState.checkedA ? true : false} onChange={(event) => props.handlePollChange(event.target.checked, event.target.name)} name="checkedA" />
      </Grid>
    </Box>
  )
}

const handleSetScroll = (
  event: React.WheelEvent<HTMLInputElement>,
  type: string,
  minutes: number,
  seconds: number,
  setMinutes: (value: number | ((clockpreMinutes: number) => number)) => void,
  setSeconds: (value: number | ((prevSeconds: number) => number)) => void
) => {
  const clockDelta = event.deltaY > 0 ? 1 : -1;

  clearExistingTimesetOut();

   
    if (type === "minutes") {
      handleSetMinutes(clockDelta, minutes, setMinutes);
    } else if (type === "seconds") {
      handleSetSeconds(clockDelta, seconds, setMinutes, setSeconds);
    }
  
};



const handleSetTimer = (props: IProp,minutes: number,seconds: number,setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; },setIsTimerSet: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; },setTimeset: { (value: React.SetStateAction<SetTime>): void; (arg0: { minutes: string; seconds: string; }): void; }) => {    
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  props.updateTime(formattedMinutes, formattedSeconds)
  setTimeset({
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  });
  setIsModalOpen(false)
  setIsTimerSet(true)
}

const handleClose = (setAnchorEl: (arg0: null) => void) => {
  setAnchorEl(null);
};

const handleModalTimewrClose = (setIsModalOpen: (arg0: boolean) => void) => {
  setIsModalOpen(false)
}
const useStyles = makeStyles(() => ({
  editPollArea: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    padding: '20px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    position:"relative",
  },
  optionPollWrapper: {
    border: "1px dashed rgba(149, 157, 165)",
    borderRadius: "10px",
    marginBottom: "20px",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "start",
  },
  optionPollbody: {
    display: "flex",
    gap: "10px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 30px)"
  },
  optionPollinput: {
    flex:"1 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 400,
    paddingLeft:"15px"
  },
  mainTimerBox :{
    display:"flex",
    borderRadius:"40px"
  },
  normalPollBtn: {
    borderRadius: "10px",
    padding: "10px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    width: "123px",
    fontFamily: "Poppins",
    fontSize: "12px",
    margin: "0 auto",
    marginBottom: "15px",
    fontWeight: 500,
    justifyContent: "start",
    whiteSpace: "nowrap",
  },
  mainPollWrapper: {
    width: "100%"
  },
  popupItems: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "15px 0"
  },
  popupHeading: {
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 500,
    "&:hover": {
      color: "white"
    }
  },
  clockTimerFirst :{
    width: "40px",
    height: "40px",  
    marginTop: "20px",
    borderRadius:"20px 0px 0px 20px",
    justifyContent:"center",
    display:"flex",
    backgroundColor: "#2B3D7D", 
    alignItems:"center",
    alignContent:"center" 
  },
  formPollWrapper: {
    display: "block"
  },
  optionPollHead: {
    fontFamily:'Poppins',
    padding: "15px"
  },
  secondTimer :{
    display:"flex",
    width: "90px", 
    height: "40px", 
    marginTop: "20px",
    borderRadius:"0px 20px 20px 0px",
    alignItems:"center",
    alignContent:"center",
   justifyContent:"center",
   backgroundColor: "white",
  },
  blackColor:{
    color: "black" 
  },
  heightStyle:{
    width: "18px" ,
    height: "20px"
  },
  settingBtn : {
    display:"flex",
    zIndex:200,
    alignSelf:"flex-end"
  },
  popupElements: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    cursor: "pointer",
    color: "#324381",
    "&:hover": {
      background: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color :"white",
    }, 
  },
  AddOptionBtn:{
    width:"20px",
    height:"20px",
    marginRight:"8px"
  },
  multipleOptionBtn:{
    borderRadius: '6px', 
    border: '2px solid #2B3D7D', 
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily:"Poppins",
    padding: '4px',
    fontSize:"12px",
    width:"123px",
    height:"40px"
  }
}));

const SwitchPoint = withStyles((theme) => ({
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box"
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor:"white"
  },
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    "& .MuiSwitch-track":{
      boxSizing: "border-box"
    }
  },
  topgeight:{
    marginTop:"60px"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
  },
  checked: {},
}))(Switch);

const Poll= (props:IProp) => {
  let pollTimer = props.pollState?.countdown_timer ? props.pollState?.countdown_timer : "00:00"
  let splitPollTime = pollTimer?.split(":");
  const classes = useStyles();

  const [options, setOptions] = useState<Option[]>([]);
  const pollOptions= props?.pollState?.questions?.[0]?.options;
  const [isHovered, setIsHovered] = useState(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>();
  const [isTimerHovered, setIsTimerHovered] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeSet, setTimeset] = useState<SetTime>({  minutes: splitPollTime[0] ? splitPollTime[0] : "00", seconds: splitPollTime[1] ?splitPollTime[1] : "00" });
  const [isRunningTime, setIsTimerSet] = useState<boolean>(props.pollState?.countdown_timer);
  const [isPointsHovered, setIsPointsHovered] = useState(false);
  const [isDoubleHovered, setIsDoubleHovered] = useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState<null>(null);
  const [isMusicHovered, setIsMusicHovered] = useState(false);
  const [pageTypeData, setpageTypeData] = React.useState<string>();


  useEffect(()=>{
    pageType(setpageTypeData)
  },[])
 
  const handleModalTimerOpen = () => {
    setIsModalOpen(true)
    setAnchorEl(null)
  }
 


  useEffect(() => {
    props.setBackgroundImage && props.setBackgroundImage(backgroundImage);
  }, [backgroundImage])
  
  useEffect(()=>{
    props.getOptions(options)
  },[options])


  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  };



  const handleModalOpen = () => {
    setModalOpen(true)
    setAnchorEl(null)
  }

 

  const adjustedMinutesDecremented = minutes - 1 > -1 ? (minutes - 1) : 23;
 const formattedMinutesDecremented = adjustedMinutesDecremented < 10 ? "0" + adjustedMinutesDecremented : adjustedMinutesDecremented;

  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center', 
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${props.preview})`,
  };

  const adjustedMinutesIncremented = minutes + 1 < 24 ? (minutes + 1) : 0;
const formattedMinutesIncremented = adjustedMinutesIncremented < 10 ? "0" + adjustedMinutesIncremented : adjustedMinutesIncremented;

  useEffect(()=>{
    const transformedOptions: Option[] = pollOptions?.map((apiOption:{answer:string}, index:number) => ({
      id: index + 1,
      text: apiOption.answer,
    }));
    
    setOptions(transformedOptions);
  },[pollOptions])

  const adjustedSecondsIncremented = seconds + 1 < 60 ? (seconds + 1) : 0;
  const formattedSecondsIncremented = adjustedSecondsIncremented < 10 ? "0" + adjustedSecondsIncremented : adjustedSecondsIncremented;

  const handleAddOption = () => {
    const newOption: Option = { id: options.length + 1, text: ''};
    setOptions([...options, newOption]);
  };

  const adjustedSecondsDecremented = seconds - 1 > -1 ? (seconds - 1) : 59;
 const formattedSecondsDecremented = adjustedSecondsDecremented < 10 ? "0" + adjustedSecondsDecremented : adjustedSecondsDecremented;

  const handleChangeOptionText = (id: number, text: string) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, text } : option
    );
    setOptions(updatedOptions);
  };


  const openId = Boolean(anchorEl);
  const settingId = openId ? 'simple-popover' : undefined;
  const handleChange = (value:boolean) => {
    props.setMultipleOption(value); 
  };
  const buttonOptions:ButtonOption[] = [
    { label: 'Single', value: false },
    { label: 'Multiple', value: true },
  ];
  
  const RadioButtons = () => {
    
    return (
      <div style={{ display: 'flex', alignSelf: 'flex-end', gap: '4px' }}>
        {buttonOptions.map((option:any) => (
          <button
            data-test-id="radio-btn"
            key={option.value}
            style={{
              backgroundColor: props.multipleOption === option.value ? '#2B3D7D' : '#fff',
              color: props.multipleOption === option.value ? '#fff' : '#2B3D7D',
            }}
            className={classes.multipleOptionBtn}
            onClick={() => handleChange(option.value)}
          >
            <FormControlLabel
              data-test-id={`radio-btn-${option.label.toLowerCase()}`}
              value={option.value}
              control={
                <Radio
                  style={{
                    color: props.multipleOption === option.value ? '#fff' : '#2B3D7D',
                  }}
                  checked={props.multipleOption === option.value}
                />
              }
              label={option.label}
              labelPlacement="end"
              style={{ pointerEvents: 'none' }}
            />
          </button>
        ))}
      </div>
    );
  };
  

  return (
    <Box className={classes.editPollArea} style={props.preview ?backgroundStyle : undefined}>
      {SettingBtn(isRunningTime,timeSet,handleClick,pageTypeData)}
      <Popover
        data-test-id="handleCloseBtn"
        id={settingId}
        open={openId}
        anchorEl={anchorEl}
        onClose={()=>handleClose(setAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginLeft: "60px",
          },
        }}
      >
        {BackgroundImg(isHovered,setIsHovered,handleModalOpen)}
        <Divider />
        {CounterTime(isTimerHovered,setIsTimerHovered,handleModalTimerOpen)}
      </Popover>
      {RadioButtons()}
      {QuestionOptions(options,handleChangeOptionText)}
      {options?.length < 6 && < Button
        data-test-id="add-btn"
        variant="outlined"
        className={classes.normalPollBtn}
        fullWidth
        onClick={handleAddOption}><img src={AddCircleIcon} className={classes.AddOptionBtn} alt="Image" />Add option</Button>}
        <AddBackgroundImageModal
        data-test-id="closeImageModal"
        open={modalOpen}
        onClose={()=>handleCloseModal(setModalOpen)}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        setPreview={props.setPreview}
      />
      <Modal open={isModalOpen} onClose={handleModalTimerOpen}>
        <MainBox>
          <img data-test-id="modalTimeTestId" className="closIcon" src={Cross} onClick={()=>handleModalTimewrClose(setIsModalOpen)} />
          <Typography className="pickerModalTitle">Add countdown timer</Typography>
          <Box className="timeSectionWrapper">
            {MinuteText(minutes,seconds,setMinutes,setSeconds,formattedMinutesDecremented,formattedMinutesIncremented)}
            {SecondText(seconds,minutes,setMinutes,setSeconds,formattedSecondsDecremented,formattedSecondsIncremented)}
          </Box>
          <GenericButton data-test-id="setTimerTestId" handleClick={()=>handleSetTimer(props,minutes,seconds,setIsModalOpen,setIsTimerSet,setTimeset)} label="Set Timer" type={"COLORED"} customStyle={{width: "400px" }}/>
        </MainBox>
      </Modal>
    </Box >
  );
};

const MainBox = styled("div")({
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  padding: "40px",
  height:"auto",
  borderRadius: "10px",
  backgroundColor: "#fff",
  position: "relative",
  maxWidth: "400px",
  width: "calc(100% - 40px)",
  "& .inpt": {
    width: "40px",
    fontSize: "17px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    textAlign: "center",
    borderTop: "1px solid rgb(45, 53, 92)",
    borderBottom: "1px solid rgb(45, 53, 92)",
    cursor:'pointer',

    "&:disabled": {
      backgroundColor: "white",
      color: "rgb(45, 53, 92)",
    },
  },
  "& .pickerModalTitle": {
    color: "#2B3D7D",
    fontSize: "20px",
    textTransform: "capitalize"
  },
  "& .timeSectionWrapper": {
    display: "flex",
    gap: "40px",
    paddingTop:"20px",
    paddingBottom:"40px"
  },
  "& .timeSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  "& .setimerBtn": {
    width: "100%",
    backgroundColor: "rgb(45, 53, 92)",
    color: "#fff",
    padding:"10px 0px 10px 0px",
    textTransform: "capitalize"
  },
  "& .closIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "44px",
    height: "44px",
    cursor: "pointer"
  }
});
export default Poll;

