import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  rating: number;
  name: string;
  email: string;
  overallComment: string;
  thingLike: string;
  improvementSug: string;
  emailErrorMsg: string;
  authToken: string;
  errors: {
    name?: string;
    email?: string;
    thingLike?: string;
    improvementSug?: string;
    rating?: string;
  };
  isModalOpen: boolean;
  isLimitModalOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageForms2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailReg: RegExp;
  feedbackApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      rating: 0,
      name: "",
      email: "",
      overallComment: "",
      thingLike: "",
      improvementSug: "",
      emailErrorMsg: "getting error",
      authToken: "",
      errors: {
        name: '',
        email: '',
        thingLike: '',
        improvementSug: '',
        rating: '',
      },
      isModalOpen: false,
      isLimitModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId === this.feedbackApiCallId) {
      this.feedbackApiCallIdReceive(responseJson, errorReponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await getStorageData("authToken");
    this.setState({
      authToken: authToken
    });
  }

  handleRatingClick = (index: number) => {
    this.setState((prevState) => ({
      ...prevState,
      rating: index + 1,
      errors: { ...prevState.errors, rating: '' }
    }));
  };

  handleInputChange = (name: string, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: { ...prevState.errors, [name]: '' }
    }));
  };

  handleCloseModal = () => {
    this.setState({isModalOpen: !this.state.isModalOpen})
  }

  handleGoToHome = () => {
    this.setState({isModalOpen: false})

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  isValidForm = () => {
    const { name, email, thingLike, improvementSug, rating } = this.state;
    let errors: Partial<S['errors']> = {};
  
    if (!name) {
      errors.name = "Name is required";
    }
    if (!email) {
      errors.email = "Email ID is required";
    } else if(!this.emailReg.test(this.state.email)){
      errors.email = "Email is invalid";
    }
    if (!thingLike) {
      errors.thingLike = "Things I like about Engagemo is required";
    }
    if (!improvementSug) {
      errors.improvementSug = "Suggestions for Improvement is required";
    }
    if (!rating) {
      errors.rating = "Rating is required";
    }
  
    this.setState({ errors });
    
    return Object.keys(errors).length === 0;
  }

  submitFeedbackForm = () => {

    if(!this.isValidForm()){
      return;
    }
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken,
    };

    const httpBody = {
      "feedback":{
        "name": this.state.name,
        "email": this.state.email,
        "comments": this.state.overallComment,
        "things_i_like": this.state.thingLike,
        "suggestion_for_improvement": this.state.improvementSug,
        "rating": this.state.rating,
      }
    };

    const feedbackMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.feedbackApiCallId = feedbackMsg.messageId;

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedbackEndPoint
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    feedbackMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.feedbackMethodType
    );
    runEngine.sendMessage(feedbackMsg.id, feedbackMsg);
  }

  feedbackApiCallIdReceive = (responseJson: any, errorReponse: any) => {
    if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
      this.setState({
        isLimitModalOpen: true,
      })
    }
    if(responseJson?.data){
      this.setState({
        isModalOpen: true,
        rating: 0,
        name: "",
        email: "",
        overallComment: "",
        thingLike: "",
        improvementSug: ""
      })
    }
  }

  handleCloseLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: false,
    })
  }
  // Customizable Area End
}
