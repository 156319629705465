import React from "react";
import { Box, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ItemList from "../../../../../../components/src/ItemList.web";
import SearchInput from "../../../../../../components/src/SearchInput.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import Loader from "../../../../../../components/src/Loader.web";
import SelectedClass from "../../../SchoolAdmin/components/SelectedClass/SelectedClass.web";
import Gsuiteintegration from "../../../../../gsuiteintegration/src/Gsuiteintegration.web";
const useStyles = makeStyles((theme) => ({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  tabsStyle: {
    "&.MuiTabs-root": {
      boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.3px solid #2B3D7D",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "22px",
    },
  },
  tabStyle: {
    "&.MuiTab-root": {
      color: "#2B3D7D",
      fontWeight: 500,
      fontSize: "22px",
      width: "218px",
      textTransform: "capitalize",
     fontFamily:"Poppins",
     [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      width: '32%',
      padding:'0px 4px'
     },
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      fontSize: "20px",
      width: "218px",
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        width: '36%'
       },
    },
  },
  tabBarStyle: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "start",
    marginLeft: "35px",
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft:0
     },
  },
  searchbarTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#2B3D7D",
    marginTop: "30px",
    marginBottom: "25px",
  },
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function a11yProps(index: any) {
  return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}
const ClassListPage = (props: any) => {
  const classes = useStyles();
  const {
    searchInput,
    handleSearchInput,
    callback,
    classList,
    pageLimit,
    pageNo,
    handleTeacherClassPageChange,
    loading,
    role,
    selectedSharedPractice,
    selectedTab,
    navigation
  } = props;
  const [value, setValue] = React.useState(selectedTab);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
};
  return (
    <>
      <Box className={classes.tabBarStyle}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "unset",
            },
          }}
          className={classes.tabsStyle}
        >
          <Tab className={classes.tabStyle} label="My Library" {...a11yProps(0)}/>
          <Tab className={classes.tabStyle} style={{ borderRight: "1px solid",borderLeft: "1px solid" }} label="Shared Library" {...a11yProps(1)}/>
          <Tab className={classes.tabStyle} label="Go to Drive" {...a11yProps(2)}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Grid item md={12}>
        <Box sx={{ px: "35px", mb: "20px" }}>
          <Typography className={classes.searchbarTitle}>My Classes</Typography>
          <SearchInput
            placeholder={"Search by class name"}
            handleSearchInput={handleSearchInput}
            searchInput={searchInput}
          />
        </Box>
      </Grid>
      {loading ? (
        <Loader loading />
      ) : (<>
        {classList &&
          classList.length === 0 ? (
          <Typography className={classes.noRecords}>No records found</Typography>
        ) : (<>
          <Box sx={{ px: "20px", pt: "5px" }}>
            <ItemList dataList={classList} handleClick={callback} />
          </Box>
          <Box sx={{ px: "35px", py: "50px" }}>
            <BasicPagination
              handlePageChange={handleTeacherClassPageChange}
              pageLimit={pageLimit}
              pageNo={pageNo}
            />
          </Box>
        </>)}
      </>)}
      </TabPanel>
      <TabPanel value={value} index={1}>
      <SelectedClass
            type='sharedLibrary'
            role={role}
            callback={selectedSharedPractice}
          />
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Box style={{width:"90%", paddingLeft:"3%"}}>
            <Gsuiteintegration
            data-test-id="gdriveIntegration"
            navigation={navigation}
            isCheckboxNeeded={false}
            id={""} 
            revertColumns={true}
            headerLabel={"Downloaded On"}
            googleBtnStyle={{marginTop:"-120%"}}
            advanceOptions
            />
    </Box>
            </TabPanel>
    </>
  );
};

export default ClassListPage;
