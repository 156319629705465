import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { googleIcon } from "./assets";
import FileListDrive from "./components/FileListDrive.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GsuiteintegrationController, {
  Props,
  configJSON,
} from "./GsuiteintegrationController";
import { deleteProfileImage } from "../../PeopleManagement2/src/assets";

export default class Gsuiteintegration extends GsuiteintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModalContent = () => {
    if (this.state.deleting) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            minWidth: "200px",
            minHeight: "100px",
          }}
        >
          <Loader loading={this.state.deleting} left={"48.7%"} />
          <p>Deleting...</p>
        </div>
      );
    }
  
    if (this.state.successMessage) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            minWidth: "200px",
            minHeight: "100px",
          }}
        >
          <img src={deleteProfileImage} alt="Success" />
          <p>{this.state.successMessage}</p>
        </div>
      );
    }
  
    return null;
  };
  
  // Customizable Area End

  render() {
    const { isSignedIn, files } = this.state;
    return (
      // Customizable Area Start
      <>
        <div>
          {!isSignedIn ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%", paddingTop: "20px" }}
            >
              <Grid item>
                <button
                  data-test-id={"btnSocialLogin"}
                  style={{
                    ...webStyle.googlebtn,
                    ...this.props.googleBtnStyle,
                  }}
                  onClick={() => this.handleAuthClick()}
                >
                  <img src={googleIcon} alt="Google Icon" />
                  <span style={webStyle.googlebtnText}>
                    {"Login with Google"}
                  </span>
                </button>
              </Grid>
            </Grid>
          ) : (
            <>
              <FileListDrive
                data-test-id="fileListDrive"
                isCheckBoxNeeded={this.props.isCheckboxNeeded}
                files={files}
                onCheckBoxClick={this.props.onButtonClick}
                importPressed={this.props.importPressed}
                getFilesData={this.getFilesData}
                setImportPressed={this.props.setImportPressed}
                goToDrive={this.openGdrive}
                revertColumns={this.props.revertColumns}
                headerLabel={this.props.headerLabel}
                advanceOptions={this.props.advanceOptions}
                handleView={this.handleView}
                handleDelete={this.deleteFile}
              />
            </>
          )}
        </div>
        <Modal open={this.state.deleting || this.state.successMessage !== ""}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
                 {this.renderModalContent()}

          </div>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  btnSpacing: {
    margin: "0 20px",
  },
  buttonContainer: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
  },
  listWrapper: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
  },
  googlebtn: {
    background: "#fff",
    border: "1px solid #2b3d7d",
    padding: "10px",
    borderRadius: "8px",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  googlebtnText: {
    color: "#2b3d7d",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "8px"
  },
};
// Customizable Area End
