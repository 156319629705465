import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomContextMenu from "./CustomContextMenu.web";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
const Assests = require("../../../assets");

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  practiceImage: {
    width: "28px",
    height: "28px",
    marginRight: "30px",
  },
  practiceNameColumnBox: {
    display: "flex",
    alignItems: "center",
  },
});

export default function CustomTable(props: any) {
  const { practiceData, deleteTableData, role, downloadLesson,selectedLessons, generateLink, shareLink,handleCloseNoMobileModal,openNoMobileModal,handleOpenNoMobileModal } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContextMenu, setOpenContextMenu] = React.useState(false);
  const [openShareLinkModal, setOpenShareLinkModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [contextMenuData, setContextMenuData] = React.useState<any>({});
  const handleOpenContextMenu = (
    event: any,
    id: string,
    name: string,
    type: string,
    itemType:string,
  ) => {
    event.preventDefault();
    setContextMenuData({
      id,
      type,
      name,
      itemType
      
    });
    setAnchorEl(event.currentTarget);
    setOpenContextMenu(true);
  };
  const handleCloseContextMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenContextMenu(false);
  };
  const handleCloseShareLinkModal = () => {
    setOpenShareLinkModal(false);
  };
  const handleOpenShareLinkModal = () => {
    setOpenShareLinkModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
            <>
            <TableHead>
            <TableRow>
              <StyledTableCell align="left">{practiceData.type}</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {practiceData.data.map((data: any) => (
              <StyledTableRow key={data.id}>
                <StyledTableCell component="th" scope="row">
                  <Box className={classes.practiceNameColumnBox}>
                    <img
                      className={classes.practiceImage}
                      src={Assests[practiceData.type]}
                    />{" "}
                    {data.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">{data.status}</StyledTableCell>
                <StyledTableCell align="center">
                  <MoreVertIcon
                  data-test-id="action-icon"
                    onClick={(e) =>
                      handleOpenContextMenu(
                        e,
                        data.id,
                        data.name,
                        practiceData.type,
                        data.itemType,
                      )
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          </>
        </Table>
      </TableContainer>
      <CustomContextMenu
      data-test-id="context-menu"
        anchorEl={anchorEl}
        openContextMenu={openContextMenu}
        handleCloseContextMenu={handleCloseContextMenu}
        handleOpenShareLinkModal={handleOpenShareLinkModal}
        handleCloseShareLinkModal={handleCloseShareLinkModal}
        openShareLinkModal={openShareLinkModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        contextMenuData={contextMenuData}
        handleDeleteEntry={deleteTableData}
        role={role}
        generateLink={generateLink}
        shareLink={shareLink}
        handleOpenNoMobileModal={handleOpenNoMobileModal}
        handleCloseNoMobileModal={handleCloseNoMobileModal}
        openNoMobileModal={openNoMobileModal}
        downloadLesson={downloadLesson}
        selectedLessons={selectedLessons}
      />
    </>
  );
}
