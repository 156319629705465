import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { userDefaultIcon1 } from "../../blocks/dashboard/src/assets";

interface Data {
  id: string;
  image: string;
  name: string;
}

interface ItemListProps {
  dataList: Data[];
  handleClick: (event: React.MouseEvent, data: any) => void;
  imageShape?: any;
}

const useStyles = makeStyles((theme) => ({
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px 10px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection:'column',
      width:'200px'
     },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('xs')]: {
      maxWidth:'180px'
     },
  },
  listItemImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#fff",
    flexShrink: 0,
  },
  classLists:{
    [theme.breakpoints.down('xs')]: {
     justifyContent:'center'

    },
  }
}));
const ItemList = (props: ItemListProps) => {
  const { dataList, handleClick, imageShape } = props;
  const classes = useStyles();
  return (
    <Box>
      <Grid container className={classes.classLists}>
        {dataList.map((data: Data) => (
          <Grid item md={4} sm={6} >
            <Box
              className={classes.listItemBox}
              onClick={(event) => handleClick(event, data)}
            >
              <Box className={classes.imageBox} >
                <img
                  className={classes.listItemImage}
                  // style={imageShape}
                  src={data.image ? data.image
                    : userDefaultIcon1}
                />
              </Box>
              <Typography className={classes.listItemFont}>
                {data.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ItemList;
