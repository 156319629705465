import React from "react";
// Customizable Area Start
import {
    Grid,
    styled,
    Box,
    Button,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
    Modal, Backdrop, Fade
} from "@material-ui/core";

import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { infoIcon, leftSideIcon, RightSideIcon, studentSubmittedIcon } from './assets'
import { constructYoutubeUrlFromVideoId, extractVideoIdFromThumbnailUrl } from "../../../components/src/Utils.web";
import { tickIcon } from "../../MultipageForms2/src/assets";
import { redCross } from "../../CfCourseCreation23/src/assets";

interface Option {
    marked_as_right_answer: boolean;
    answer: string;
    updated_at: string;
    order_no: number | null;
    id: number;
    assignment_mcq_questions_id: number;
    created_at: string;
}
interface Question {
    options: Option[];
    question_background_image: string | null;
    question: {
        id: number;
        assignment_markup_tools_id: number;
        question: string;
        updated_at: string;
        created_at: string;
    };
};
interface Pair {
    id: number;
    assignment_markup_tools_id: number;
    pair_answer: string;
    lesson_markup_tools_id: number | null;
    pair_correct_answer: string;
    quiz_markup_tools_id: number | null;
    order_no: number;
    pair_question: string;
    updated_at: string;
    created_at: string;
}

interface AssignmentMarkupTool {
    id: string;
    type: string;
    attributes: {
        videos_url: (string | null)[];
        assignments_id: number;
        assignment_sub_stage_name: string[] | null;
        title: string | null;
        stage_number: string;
        assignment_stage_id: number;
        description: (string | { topic: string; answer: string })[] | null;
        assignment_stage_name: string;
        questions: Question[] | null;
        question: string | null;
        videos: (string | { id: number; url: string })[] | null;
        background_image: string | null;
        pairs: Pair[];
        images: (string | { id: number; url: string })[] | null;
        countdown_timer: number | null;
    };
}

interface Answer {
    teacher_correct_answer: string;
    correct_answer: boolean | null;
    topic: string;
    answer: string;
    question?: string
}

interface ProcessedData {
    subStage?: AssignmentMarkupTool;
    answers?: StudentAnswer[];
    base: AssignmentMarkupTool;
}

interface StudentAnswer {
    type: string;
    id: string;
    attributes: {
        correct_answer: boolean | null;
        account_id: number;
        assignment_id: number;
        assignment_stage_id: number;
        updated_at: string;
        created_at: string;
        answers: Answer[];
    };
}

  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End

import AssessmentViewController, {
    Props,
} from "./AssessmentViewController.web";

// Customizable Area Start
export default class AssessmentView extends AssessmentViewController {
    constructor(props: Props) {
        super(props);
    }

    displayAnswer = (data: ProcessedData, isSubStage: boolean) => {

        let isCorrect = false;
        let currentSubStage = this.handleQuestionSubValidation(data);
        let currentBaseStage = this.handleQuestionValidation(data);
        let mapArray = isSubStage ? currentSubStage : currentBaseStage
        let stageType = data.base.attributes.assignment_stage_name
        if (stageType === "polling_question") {
            return null; 
        }        
        mapArray && mapArray.forEach((value) => {
            if (this.checkIsCorrectAns(data, value.answer)) {
                isCorrect = true;
            }
        });
    
        const message = isCorrect ? 'Correct' : 'Incorrect';
        const icon = isCorrect ? tickIcon : redCross;
    
        return (
            <Grid container alignItems="center" style={{ padding: '8px' }}>
                <Grid item>
                    <img style={webStyle.tickIconSmall} src={icon} alt="" />
                </Grid>
                <Grid item>
                    <Typography style={webStyle.Ansmessage}>Your answer is <span style={webStyle.AnsmessageSpan}>{message}</span></Typography>
                </Grid>
            </Grid>
        );
    }


    textQuestion = (data: ProcessedData) => {
        return (
            <><Box sx={webStyle.mainWrapper}>
                    <Grid container style={webStyle.mainWrapperContainers as React.CSSProperties}> <Grid item  style={webStyle.mainWrapperRightSide}>
                            <Typography style={webStyle.textQuesTitle} dangerouslySetInnerHTML={{
                                __html: data.base.attributes.title || '',
                            }}/> <br />
                            <Typography
                                style={webStyle.textQuesTitle}
                                dangerouslySetInnerHTML={{
                                    __html: typeof data?.base?.attributes?.description?.[0] === 'string' && data.base.attributes.description?.[0] || ''
                                }}/>
                        </Grid>
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" && <Box style={webStyle.addMultimediaMcq} >
                                <Typography style={webStyle.firstHedingTexts as React.CSSProperties}>Text / Passage Question</Typography>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{__html: data?.subStage.attributes?.questions?.[0]?.question?.question || ''}}/>
                                {this.renderOptions(data)}
                                {this.displayAnswer(data, true)}
                                <Button data-test-id="btnAddFeedbackTextQuestion" style={webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data?.subStage && data?.subStage.attributes?.questions?.[0]?.question?.id)}>Feedback</Button></Box>
                        }
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && this.openEndedQuestion(data.subStage, data.answers, true)
                        }
                    </Grid>
                </Box></>
        );
    };

    wordCloud = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        return (
            <Box sx={webStyle.mainWrapper}> <Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}><Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}><Grid style={webStyle.firstTextGrids}><Typography style={webStyle.firstHedingTexts}>Word Cloud</Typography></Grid><Grid>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{
                                    __html: data.attributes.title || '',
                                }}/>
                            </Grid>
                           
                            {data.attributes.description && data.attributes.description.map((value, index) => (
                                <Grid item key={index}>
                                    {Object.keys(value).map((keys, subIndex) => (
                                        <input
                                            key={subIndex}
                                            disabled
                                            style={webStyle.optionForInput}
                                            placeholder={keys}
                                            value={this.handleWordCloudValue(answers, index) || ''}
                                        />
                                    ))}
                                </Grid>
                            ))} <Grid item>
                                    <Button style={webStyle.submitButtonActive as React.CSSProperties} 
                                       data-test-id="btnAddFeedbackwordCloud"
                                       onClick={()=>this.addFeedback(data.id)}
                                    >Feedback
                                    </Button>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        )
    }


    chartQuestion = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        let dataType = data.attributes.assignment_stage_name
        let type = this.setTypeForChart(dataType)
        return (
            <Box sx={webStyle.mainWrapper}><Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}>
                    <Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>
                            <Grid style={webStyle.firstTextGrids}> <Typography style={webStyle.firstHedingTexts}>{type}</Typography></Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleTitle(data.attributes.title),}}/></Grid>
                            {data.attributes.description && 
                                data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (<Grid item key={index}>
                                    {
                                        typeof value !== 'string' &&
                                        <>
                                            <Typography style={webStyle.chartQuestion} dangerouslySetInnerHTML={{ __html: value.topic}} />
                                            <Typography 
                                                style={webStyle.whChartQuestionAns as React.CSSProperties}
                                                dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index)}} 
                                            />
                                        </>
                                    }</Grid>
                            ))}
                            <Grid item>
                                    <Button style={ webStyle.submitButtonActive as React.CSSProperties} 
                                    onClick={()=>this.addFeedback(data.id)}
                                    data-test-id="BtnSendFeedbackChartQues"
                                    >Feedback
                                    </Button>
                         </Grid>
                        </Grid></Grid></Grid>
            </Box>
        )
    }

    mcqs = (data: ProcessedData) => {
        return (<Box sx={webStyle.mainWrapper}><Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}><Grid item style={webStyle.mainContainersWidth}>

                        <Grid style={webStyle.mainWrapperForWordsCloud}>
                            
                            <Grid style={webStyle.firstTextGrids}><Typography style={webStyle.firstHedingTexts}>{data.base.attributes.assignment_stage_name === "MCQ" ? "Multiple Choice Question" : "Polling Question"}</Typography></Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: data.base.attributes.questions && data.base.attributes.questions[0].question.question || '',}}/></Grid>
                            {data.base.attributes.questions && data.base.attributes.questions[0].options.map((value, index) => (
                                <Grid key={index}
                                    style={
                                        this.getAnswerStyle(value.answer, data, data.base.attributes.assignment_stage_name)
                                    }
                                >
                                    {value.answer}
                                    {(Array.isArray(data.answers?.[0]?.attributes?.answers?.[0]?.answer)
                                        ? data.answers?.[0]?.attributes?.answers?.[0]?.answer.includes(value.answer)
                                        : value.answer === data.answers?.[0]?.attributes?.answers?.[0]?.answer) && (
                                        <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                           
                            {
                                this.displayAnswer(data, false)
                            }
                                    <Grid item>
                                        <Button
                                            style={webStyle.submitButtonActive as React.CSSProperties
                                            }
                                            data-test-id='btnSendFeedbackMcq'
                                            onClick={()=>this.addFeedback(
                                                (data 
                                                && data.base 
                                                && data.base.attributes 
                                                && data.base.attributes.questions 
                                                && data.base.attributes.questions[0] 
                                                && data.base.attributes.questions[0].question 
                                                && data.base.attributes.questions[0].question.assignment_markup_tools_id) || 0
                                            )}
                                        >
                                            Feedback
                                                </Button>
                                    </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Box>
        );
    };


    dragDrop = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        const dataForPair = data.attributes.pairs
        return (
            <PropertyDeveloperCss><Box sx={webStyle.mainWrapper}><Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}><Grid style={webStyle.mainContainerWidthDrag}><Typography style={webStyle.firstHedingTexts} >

                            You can drag the options in column 'B' with the correct matching. Match column 'A' with its corrected pairs of column 'B'</Typography>
                            <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{__html: data.attributes.title || ''}}/>
                            <Box><Box className="headingLayout"><Box className="heading">A</Box><Box className="heading">B</Box></Box><Grid container justifyContent="space-between" spacing={3}>
                                    <Grid item md={6}>
                                        {dataForPair.map((question, index) => (
                                            <Box key={index} className="matchingBoxA">
                                                {question.pair_question}
                                            </Box>
                                        ))}
                                    </Grid>


                                    <Grid item md={6}>
                                        {answers?.[0]?.attributes?.answers.map((question, index) => (
                                            <Box
                                                key={index}
                                                className={(question && question.answer) === (question && question.teacher_correct_answer) ? 'matchingBoxBCorrect' : 'matchingBoxBWrong'}
                                            >
                                                {question.answer}
                                            </Box>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                            <Grid item>
                                                <Button
                                                    style={
                                                        webStyle.submitButtonActive as React.CSSProperties   
                                                    }
                                                    onClick={()=>this.addFeedback(data.id)}
                                                    data-test-id='btnSendFeedbackDragDrop'
                                                >
                                                    Feedback
                                                    </Button>
                                            </Grid>
                                </Grid>
                            </Box>
                            </Grid>
                    </Grid>
                </Box>
            </PropertyDeveloperCss>
        )
    };

    whChartQuestion = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        return (
            <Box sx={webStyle.mainWrapper}><Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}><Grid item style={webStyle.mainContainerWidthSec}><Grid style={webStyle.mainWrapperChartQuestion}><Grid style={webStyle.firstTextGrids}>
                                <Typography style={webStyle.firstHedingTexts}>WH-Chart Question</Typography>
                            </Grid>
                            <Grid><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleTitle(data.attributes.title),}}/></Grid>

                            <Box 
                                display="flex"
                                flexWrap="wrap"
                                m={-2}
                            >
                                {data.attributes.description && data.attributes.description.map((item, index) => {
                                    if (typeof item !== 'string') {
                                        return (
                                            <Box 
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'end',
                                                    flexBasis: 'calc(50% - 16px)',
                                                    boxSizing: 'border-box',
                                                    margin: 8,
                                                    padding: 16,
                                                }}
                                                key={index}
                                            >
                                                <Typography 
                                                    style={webStyle.chartQuestion as React.CSSProperties}
                                                    dangerouslySetInnerHTML={{ __html: item.topic}} 
                                                />
                                                <Typography 
                                                    style={webStyle.whChartQuestionAns as React.CSSProperties}
                                                    dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index)}} 
                                                /> </Box>
                                        );}
                                    return null;
                                })}
                            </Box><Grid item><Button data-test-id="btnSendFeedbackWhChart" style={webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data.id)}>Feedback</Button></Grid>
                        </Grid>
                    </Grid></Grid>
            </Box>
        )
    };

    openEndedQuestion = (data: AssignmentMarkupTool, answers?: StudentAnswer[], isSubStage?: boolean) => {
        return (<Box style={!isSubStage ? webStyle.openEndedWrapper : webStyle.openEndedBlankWrapper}><Box style={isSubStage ? webStyle.openEndedMainBoxSubStage : webStyle.openEndedMainBoxStage} >
                    <Typography style={webStyle.firstHedingTexts}>Open-Ended Question</Typography><Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: data.attributes.title || '',}}/>

                    <Typography style={webStyle.openEndedAns as React.CSSProperties} dangerouslySetInnerHTML={{ __html: answers?.[0]?.attributes?.answers?.[0]?.answer || '',}}/>
                    <Button style={webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data.id)} data-test-id='btnAddFeedbackOpenEnded'>Feedback</Button>

                </Box></Box>
        )
    };

    renderMediaContent = (data: AssignmentMarkupTool) => {
        const {attributes} = data;
        const videoID = attributes?.videos_url && attributes.videos_url[0] != null && extractVideoIdFromThumbnailUrl(attributes.videos_url[0]);
        const youtubeUrl = videoID ? constructYoutubeUrlFromVideoId(videoID) : 'defaultFallbackUrl';
        
        const videoUrl = attributes?.videos && attributes.videos.length > 0 && typeof attributes.videos[0] !== 'string' && attributes.videos[0]?.url;
        const imageUrl = attributes?.images && attributes.images.length > 0 && typeof attributes.images[0] !== 'string' && attributes.images[0]?.url;

        return (

            <Box style={webStyle.addTextLayout} >

                {
                    imageUrl && <img
                        src={imageUrl}
                        data-test-id="img-preview"
                        height="100%"
                        width="100%" />
                }

                {
                    videoUrl && <video
                        data-test-id="video"
                        height="100%"
                        width="100%"
                        controls ><source src={videoUrl} type="video/mp4" />
                    </video>
                }

                {
                    videoID && <iframe
                        data-test-id="youtube"
                        height="100%"
                        width="100%"
                        src={youtubeUrl.replace('watch?v=', 'embed/')}
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                }</Box>)
    };


    addMultimedia = (data: ProcessedData) => {

        return (
            <><Box sx={webStyle.mainWrapper}><Grid container style={webStyle.mainWrapperContainers as React.CSSProperties}>
                        <Grid style={webStyle.mainWrapperRightSide}>{this.renderMediaContent(data.base)}</Grid>
                        {
                            data?.subStage && data?.subStage?.attributes.assignment_stage_name === "MCQ" &&
                            <Box style={webStyle.addMultimediaMcq} >
                                <Typography style={webStyle.firstHedingTexts as React.CSSProperties}>Text / Passage Question</Typography>
                                <Typography style={webStyle.textStyleSec} 
                                dangerouslySetInnerHTML={{
                                    __html: data?.subStage.attributes?.questions?.[0]?.question?.question || ''
                                }}/>
                                {this.renderOptions(data)}
                                {this.displayAnswer(data, true)}
                                <Button 
                                    data-test-id="btnSendFeedbackAddMultimedia"
                                    style={webStyle.submitButtonActive as React.CSSProperties}
                                    onClick={()=>this.addFeedback(data?.subStage && data?.subStage.attributes?.questions?.[0]?.question?.id)}
                                >Feedback
                                </Button>
                            </Box>
                        }
                        {data?.subStage && data?.subStage?.attributes.assignment_stage_name === "open_ended_question" && this.openEndedQuestion(data.subStage, data.answers, true)}
                        
                    </Grid>


                </Box>
            </>
        );
    };



    vennDiagram = (data: AssignmentMarkupTool, answers: StudentAnswer[]) => {
        return (
            <Box sx={webStyle.mainWrapper}><Grid alignItems="center" justifyContent="center" container style={webStyle.mainWrapperContainers as React.CSSProperties}><Grid item style={webStyle.mainContainersWidth}>
                        <Grid style={webStyle.mainWrapperChartQuestion}>

                            <Grid style={webStyle.firstTextGrids}><Typography style={webStyle.firstHedingTexts}>Venn Diagram Question</Typography></Grid>
                            <Grid>
                                <Typography style={webStyle.textStyleSec} dangerouslySetInnerHTML={{ __html: this.handleTitle(data.attributes.title),}}/>
                            </Grid>
                            {data.attributes.description && data.attributes.description.map((value: string | { topic: string; answer: string }, index: number) => (
                                <Grid item key={index}>
                                    {typeof value !== 'string' &&
                                        <><Typography style={webStyle.chartQuestion}>{`Answer ${index + 1}`}</Typography>
                                            <Typography 
                                                dangerouslySetInnerHTML={{ __html: this.getInnerHtml(answers, index)}} 
                                                style={webStyle.whChartQuestionAns as React.CSSProperties}
                                            />
                                        </>
                                    }</Grid>))}
                            <Grid item>
                                <Button data-test-id='btnSendFeedbackVenDiagram' style={ webStyle.submitButtonActive as React.CSSProperties} onClick={()=>this.addFeedback(data.id)}>Feedback</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                
            </Box>
        )
    }

    renderOptions = (data: ProcessedData) => {
        return data.subStage && data.subStage.attributes && 
        data.subStage.attributes.questions && 
        data.subStage.attributes.questions[0] &&
        data.subStage.attributes.questions[0].options.map((value, index) => (<Grid key={index} style={this.getAnswerStyle(value.answer, data, data.subStage && data.subStage.attributes.assignment_stage_name)}
            >
                {value.answer}
                {value.answer === data.answers?.[0]?.attributes?.answers?.[0]?.answer && <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                        <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} /></Grid>
                }

            </Grid>));
    };

    stepper = () => {
        const { allData, 
            currentStep 
        } = this.state;

        if (!allData.length) return true;

        const processData = (data: AssignmentMarkupTool[]): ProcessedData[] => {

            const getNumericParts = (stageNumber: string): number => 
                parseInt(stageNumber.match(/\d+/)?.[0] || '0', 10);
        
            data.sort((a, b) => {

                const numBs = getNumericParts(b.attributes.stage_number);
                const numAs = getNumericParts(a.attributes.stage_number);

                return numAs - numBs;

            });
            const combinedDatas: { [key: string]: AssignmentMarkupTool[] } 
            = {};

            data.forEach((item) => {
                const { stage_number } 
                = item.attributes;

                    const numericPart = getNumericParts(stage_number).toString();
        
                if (!combinedDatas[numericPart]) {combinedDatas[numericPart] = []}

                combinedDatas[numericPart].push(item);
            });

            const mainArray: ProcessedData[] = [];
        
            for (const combinedDatasKey in combinedDatas) {

                const subStages = combinedDatas[combinedDatasKey].filter(stage => !Boolean(Number(stage.attributes.stage_number)));
                const baseStages = combinedDatas[combinedDatasKey].filter(stage => Boolean(Number(stage.attributes.stage_number)));
                baseStages.forEach(base => {mainArray.push({base: base});subStages.forEach(subStage => {mainArray.push({base: base,subStage: subStage})});});
                if (subStages.length > 0 && baseStages.length === 0) {subStages.forEach(subStage => {mainArray.push({base: subStage})})}
            }
            return mainArray;
        };


        const proData = processData(allData);

        const lastData: Array<{
            base: any['base'];
            subStage?: AssignmentMarkupTool;
            answers: any[];
        }> = proData.map(assignment => {

            const stageId = assignment.base.attributes.assignment_stage_id;

            const subStagesId = assignment.subStage?.attributes.assignment_stage_id;

            let reversedAnswers = this.state.submitedAnswer.slice().reverse()

            const answers = reversedAnswers && reversedAnswers.filter(answer =>
                answer.attributes.assignment_stage_id === stageId ||
                (subStagesId !== undefined && answer.attributes.assignment_stage_id === subStagesId)
            );
        


            return {
                base: assignment.base,
                subStage: assignment.subStage,
                answers
            };
        });
        
        const currentData = lastData[currentStep];

        const datasType = currentData.base.attributes.assignment_stage_name;

        switch (datasType) {
            
            case "MCQ":
                return this.mcqs(currentData);
            case "add_text":
                return this.textQuestion(currentData);
            case "add_multimedia":
                return this.addMultimedia(currentData);
            case "open_ended_question":
                return this.openEndedQuestion(currentData.base, currentData?.answers);
            case "matching_question":
                this.setData(currentData.base.attributes.pairs)
                return this.dragDrop(currentData.base, currentData.answers);
            case "t_chart":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "kwl_chart":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "seed_discussion":
                return this.chartQuestion(currentData.base, currentData.answers);
            case "venn_diagram":
                return this.vennDiagram(currentData.base, currentData?.answers);
            case "wh_chart":
                return this.whChartQuestion(currentData.base, currentData.answers);
            case "add_word_cloud":
                return this.wordCloud(currentData.base, currentData?.answers);  
            case "polling_question":
                return this.mcqs(currentData);
            default:
                return true;
        }
    }
// Customizable Area End

    render() {
        // Customizable Area Start
        const { 
            assignmentName, 
            assignmentStatus, 
            className, 
            totalStep 
        } = this.state;
        // Customizable Area End
            return (
                // Customizable Area Start
                <>
                <Modal
          open={this.state.openModel}
          onClose={this.handleClose}
          data-test-id="handleClose"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openModel}>

            <div
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '25%',
                padding: '16px',
                backgroundColor: 'white',
                outline: 'none',
                borderRadius: '8px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
              }}
            >
                <h2>Feedback</h2>
                <p style={webStyle.firstHedingTexts} data-test-id="handelFeedbackWrite">{this.state.feedbackText}</p>
            </div>
          </Fade>
        </Modal><Grid><Grid container justifyContent="space-between" alignItems="center" style={webStyle.hedingContainer}><Grid item>
                                <Grid container alignItems="center"><Grid item ><ArrowBackIosIcon data-test-id="btnBack" onClick={()=> this.handleBackToViewList()} style={webStyle.firstHedingIcon} /></Grid><Grid item style={webStyle.firstHedingTexts}>{assignmentName}</Grid></Grid>
                            </Grid>
                            <Grid item><Grid container><Grid item>
                                        <Grid container direction="column">
                                            <Grid item style={webStyle.secHedingText}>Class</Grid>
                                            <Grid item style={webStyle.firstHedingTexts}>{className}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={webStyle.hedingBlock}>
                                        <Grid container direction="column"><Grid item style={webStyle.secHedingText}>Feedback</Grid><Grid item style={webStyle.firstHedingTexts}>{totalStep}</Grid></Grid>
                                    </Grid>
                                    <Grid item style={webStyle.hedingBlock}>

                                        <Grid container direction="column"><Grid item style={webStyle.secHedingText}>Status</Grid><Grid item style={webStyle.firstHedingTexts}>{assignmentStatus === 'complete' && 'Completed'}</Grid></Grid>
                                    </Grid>
                                </Grid>
                            </Grid></Grid>
                    </Grid>
                    <Grid>{this.stepper()}</Grid>
                    {
                        !this.state.allDataLoading && 
                        <Grid container alignItems="center" justifyContent="center" style={webStyle.pginationGrid as React.CSSProperties}>
                            <Grid item>  <img style={webStyle.cursorPointer} src={leftSideIcon} data-test-id="handelBack" onClick={this.handelBack} /></Grid>
                            <Grid item style={webStyle.paginationText}>{`${this.state.allData[this.state.currentStep]?.attributes?.stage_number}/${this.state.totalStep}`}</Grid>
                            <Grid item><img style={webStyle.cursorPointer} src={RightSideIcon} data-test-id="handelNext" onClick={this.handelNext} />
                            </Grid>
                        </Grid>
                    }
                </>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
const webStyle = {
    
    firstHedingTexts: { fontSize: "14px", fontWeight: 500, color: "#282829", fontFamily: "Poppins",  },
    mainWrapper: {
        height: "91.2%",
        backrgroundColor: "#B2F1F5F9",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#2b3d7d0A",
        marginTop: "1px",
        fontFamily: "Roboto-Medium",
      },
    hedingBlock: { marginLeft: "60px" },
    firstHedingIcon: { fontSize: "14px", marginRight: '16px', cursor: 'pointer' },
    firstHedingIcons: { fontSize: "14px", marginRight: "6px" },
    hedingContainer: { padding: "1% 2%", 'box-shadow': '0px 1px 3px 0px #00000014' },
    secHedingText: { fontSize: "11px", fontWeight: 400, color: "#282829", fontFamily: "Poppins",  },
    mainWrapperContainers: { 
        height: "100%", padding: "44px 44px 44px 44px",position: "relative" as "relative",width: "100%",overflow:"auto" ,
    },
    mainWrapperContainersForSubStage: { 
        width: "34%",
        height: "100%", 
        position: "relative" as "relative",
        overflow:"auto" ,
    },
    mainWrapperRightSide: { 
        width: "65%",
        height: "100%",
        overflow: "auto",
        marginRight: '5px',
        paddingRight: "2%",
        fontFamily: "Poppins", 
    },
    mainWrapperLeftSide: { 
        backgroundColor: "#fff",
        height: "100%",
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", 
        padding: "0px 5%",
    },
    mainWrapperForWordsCloud: { 
        borderRadius: "9px",
        padding: "4% 7% 7% 7%",
        height: "100%",
        backgroundColor: "#fff",
        boxSizing: 'border-box' as 'border-box',
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    },
    mainWrapperChartQuestion: {
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        height: "fit-content",
        backgroundColor: "#fff",
        padding: "4% 7% 7% 7%",
        borderRadius: "9px",
        boxSizing: 'border-box' as 'border-box',
    },
    submitButtonBeforeClickDrag: {
        width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
        , opacity: "0.5", borderRadius: "25px", color: "#fff",
        fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
        marginTop: "10px"
    },
    buttonBeforeClick: {
      width: "100%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , opacity: "0.5", borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "8px 20px", fontWeight: 700
    },
    textStyleSec: { fontSize: "21px", fontWeight: 500, color: "#282829", fontFamily: "Poppins",  margin: "20px 0px" },

    
    submitButtonActive: {
      width: "45%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
      , borderRadius: "25px", color: "#fff",
      fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
      marginTop: "10px"
    },
    
    
    optionBeforSelect: {
        fontFamily: "Poppins",
        width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
        cursor: "pointer",
    },
    submitedText: { fontSize: "14px", fontFamily: "Poppins", color: "#3F526D", fontWeight: 700, marginLeft: "10px" },

    submitButtonActiveDrag: {
        width: "30%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
        , borderRadius: "25px", color: "#fff",
        marginTop: "10px",
        fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
      },
    optionSelect: {
      width: "92%", border: "2px solid #2B3D7D", borderRadius: "20px", position: "relative",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    optionAfterSelect: {
        fontFamily: "Poppins",
      width: "92%", background: "#2B3D7D", borderRadius: "20px", position: "relative",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
      cursor: "pointer", border: "2px solid #2B3D7D",
    },
    icoStyle: { color: "#fff", fontSize: "20px" },
    containerSubmitIcon: { backgroundColor: "#2B3D7D", borderRadius: "50%", padding: "3px" },
    iconStyle: { color: "#fff", fontSize: "15px" },
    firstTextGrids: { paddingTop: "20px" },
    containerForIcon: { position: "absolute", top: "9px", right: "12px", borderRadius: "50%", padding: "3px" },
    pginationGrid: {
      position: "fixed", left: "50%",
        bottom: "20px", width: "120px", padding: "4px",
      boxShadow: "0.5px 1px 1px 1px #646464", borderRadius: "11px",
      background: '#fff'
    },
    paginationText: { margin: "0px 8px", fontSize: "14px", fontWeight: 600, color: "#3F526D", fontFamily: "Poppins" },
    styleOfImage: { width: "100%", height: "100%" },
    editBtnContainer: { cursor: "pointer", border: "2px solid #2B3D7D", borderRadius: "25px", width: "100px", padding: "9px 0px" },
    optionForInput: {
      width: "100%", border: "1px solid #2B3D7D", borderRadius: "20px",
      margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    mainContainerWidthDrag: { width: "40%", backgroundColor: "#fff", padding: "3%", borderRadius: "20px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" },
    mainContainerWidthSec: { width: "60%" },
    chartQuestion: { fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 500 },
    mainContainersWidth: { width: "40%" },
    whChartQuestionAns: {
        fontSize: "14px", 
        height: '100px',
        width: "100%",
        fontFamily: "Poppins", 
        border: "1px solid #5C2B3D7D", 
        fontWeight: 600, 
        color: "#282829", 
        borderRadius: "4px", 
        resize: "none", 
        boxSizing: 'border-box',
        margin: "2% 0px 5% 0px",
        padding: '5px 10px 5px 10px',

        overflowY: 'scroll',
        msOverflowStyle: 'none' as 'none',
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    chartQuestionTextfield: {
      fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 600, width: "100%",
      borderRadius: "4px", border: "1px solid #5C2B3D7D", resize: "none", margin: "2% 0px 5%",
      padding: '5px 10px 5px 10px',
    },
    openEndedAns: {
        borderRadius: "4px", 
        minHeight: '200px',
        fontFamily: "Poppins", 
        color: "#282829", 
        border: "1px solid #5C2B3D7D", 
        resize: "none", 
        margin: "2% 0px 5% 0px",
        fontSize: "14px", 
        overflowY: 'scroll',
        padding: '5px 5px 5px 5px',
        fontWeight: 600, 
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    addTextLayout: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    textQuesTitle: {
        lineHeight: '26px',
        display: 'block',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
    },
    wrongAnswer: {
        width: "92%", border: "1px solid #FF0000", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
        position: "relative" as "relative",
        cursor: "pointer",
        background: "#FF0000",
        fontFamily: "Poppins", lineHeight: "16px"
      },
    rightAnswer: {
        width: "92%", background: "#00A52C", borderRadius: "20px", position: "relative" as "relative",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
        fontFamily: "Poppins", lineHeight: "16px",
        cursor: "pointer", border: "2px solid #00A52C",
      },

      openEndedWrapper: {
        background: "#F1F5F9",
        paddingTop: "30px",
        paddingBottom: "30px",
        backrgroundColor: "#B2F1F5F9",
        minHeight: "92%",
      },  
      noAnswer: {
        width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
        margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
        cursor: "pointer",
        fontFamily: "Poppins", lineHeight: "16px"
      },
      
      openEndedMainBoxSubStage: {
        width: '100%',
      },

      openEndedBlankWrapper: {
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        width: '32%',
        height: "100%",
        padding: "24px 24px 24px 24px",
        boxSizing: 'border-box' as 'border-box',
        overflowY: 'scroll' as 'scroll', 
        borderRadius: "9px",
        msOverflowStyle: 'none' as 'none' ,
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      openEndedMainBoxStage: {
        width: '34%',
        height: "100%",
        margin: 'auto',
        marginTop: '30px',
        padding: "24px 24px 24px 24px",
        backgroundColor: "#fff",
        overflowY: 'scroll' as 'scroll',
        boxSizing: 'border-box' as 'border-box',
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
      },
      
    cursorPointer: {
        cursor: 'pointer',
    },
    addMultimediaMcq: {
        width: '29%',
        height: "100%",
        borderRadius: "9px",
        padding: "24px 24px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", 
        backgroundColor: "#fff",
        boxSizing: 'border-box' as 'border-box',
        overflowY: 'scroll' as 'scroll',
        scrollbarWidth: 'none' as 'none',
        msOverflowStyle: 'none' as 'none' ,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    
    tickIcon: {
        width: '60px',
        height: '60px',
        display: 'block',
        margin: 'auto',
    },
    feedbackSentDesc: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center' as 'center',
        color: '#202020',
    },
    feedbackSentTxt: {
        margin: '12px 0 16px 0',
        fontFamily: 'Poppins',
        fontSize: '24px',
        textAlign: 'center' as 'center',
        color: '#202020',
    },
    
    
    infoIcon: {
        display: 'block',
        margin: 'auto',
        marginBottom: '12px',
    },
    feedbackSentDoneBtn: {
        display: 'block',
        margin: 'auto',
        border: 'none',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '28px',
        color: '#FFFFFF',
        padding: '14px 42px 14px 42px',
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        borderRadius: '50px',
    },
    doYouWantToSendTxt: {
        fontWeight: 400,
        fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#202020',
        textAlign: 'center' as 'center',
        lineHeight: '28px',
    },
    alertModalAssignName: {
        border: '0.5px solid #2B3D7D1F',
        width: '412px',
        height: "60px",
        margin: 'auto',
        marginTop: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#2C2C2E',
        backgroundColor: '#2B3D7D14',
        fontSize: '16px',
        borderRadius: '6px',
    },
    alertBtnBox: {
        width: '412px',
        margin: "auto",
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sendNowBtn: {
        cursor: 'pointer',
        padding: "16px 24px 16px 24px",
        fontSize: '16px',
        fontWeight: 400,
        borderRadius: '50px',
        color: '#3F526D',
        background: 'none',
        border: '1px solid #2B3D7D',
        fontFamily: 'Poppins',
    },
    saveAndContiBtn: {
        color: '#FFFFFF',
        padding: "16px 24px 16px 24px",
        fontWeight: 400,
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        fontFamily: 'Poppins',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '50px',
        fontSize: '16px',
    },
    tickIconSmall: {
        width: '20px',
        height: '20px',
        display: 'block',
        margin: 'auto',
        marginRight: '8px',
    },
    Ansmessage: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        color: '#3F526D',
    },
    AnsmessageSpan: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
    },
  };
  
  const PropertyDeveloperCss = styled(Box)({
    '& .question': {
        fontWeight: 500,
        fontSize: "18px",
        fontFamily: "Poppins",
        color: "#282829",
        marginBottom: "20px",
    },
    "& .instruction": {
        color: "#282829",
        fontSize: "14px",
        fontWeight: 500,
        marginBottom: "15px",
        fontFamily: "Poppins",
    },

    "& .matchingBoxBWrong": {
        cursor: "move",
        fontFamily: "Poppins",
        borderRadius: "10px",
        fontWeight: 500,
        background: "#FF0000",
        padding: "16px 12px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        color: "#282829",
        fontSize: "14px",
        marginBottom: "10px",
    },
    "& .matchingBoxBCorrect": {
        borderRadius: "10px",
        padding: "16px 12px",
        fontSize: "14px",
        color: "#282829",
        background: "#00A52C",
        fontFamily: "Poppins",
        cursor: "move",
        marginBottom: "10px",
        fontWeight: 500,
        border: "1px solid rgba(43, 61, 125, 0.24)",
    },
    
    "& .matchingBoxB": {
        padding: "16px 12px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        borderRadius: "10px",
        backgroundColor: "rgba(43, 61, 125, 0.08)",
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        fontWeight: 500,
        marginBottom: "10px",
        cursor: "move",
    },
    "& .matchingBoxA": {
        fontFamily: "Poppins",
        marginBottom: "10px",
        borderRadius: "10px",
        padding: "16px 12px",
        color: "#282829",
        fontSize: "14px",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        fontWeight: 500,
    },
    "& .matchingBoxBActive": {
        padding: "16px 12px",
        border: "1px solid #2B3D7D",
        borderRadius: "10px",
        backgroundColor: "#2B3D7D",
        fontFamily: "Poppins",
        marginBottom: "10px",
        cursor: "move",
        fontSize: "14px", fontWeight: 500, color: "#fff",
    },
    "& .heading": {
        fontFamily: "Poppins",
        fontSize: "21px",
        color: "#282829",
        fontWeight: 700,
    },
    "& .headingLayout": {
        display: "flex",
        marginBottom: "15px",
        justifyContent: "space-around",
        textAlign: "center",
    },
  
    
    "& .contentBox": {
        width: "380px",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0px 0px 10px 0px #F1F5F9",
        borderRadius: "10px",
        flexDirection: "column",
        padding: "30px 60px",
    },
    "& .mainLayout": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "30px 60px",
    },
    "& .submitBtn": {
        width: "107px",
        height: "44px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
        borderRadius: "50px",
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#2B3D7D",
        },
    },
    "& .disabledBtn": {
        fontFamily: "Poppins",
        width: "107px",
        height: "44px",
        fontWeight: 500,
        color: "#FFFFFF",
        borderRadius: "50px",
        backgroundColor: "#2B3D7D",
        fontSize: "14px",
        opacity: "0.5",
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#2B3D7D",
        },
    },
    "& .submitBtnLayout": {
      marginTop: "25px",
      cursor: "pointer"
    },
  })
// Customizable Area End