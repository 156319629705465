export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const leftSideIcon = require("../assets/leftSideIcon.svg");
export const RightSideIcon = require("../assets/RightSideIcon.svg");
export const editIcon = require("../assets/editIcon.svg");
export const profileIcon = require("../assets/profileIcon.svg");
export const greenTick = require("../assets/greenTick.png");
export const studentSubmittedIcon = require("../assets/student_submitted.svg");
export const infoIcon = require("../assets/info_icon.svg");

