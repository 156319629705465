import React from "react";
import { Box,Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { venn } from "../../../../assets";
import { QuestionHeaders } from "../../../../../../../components/src/types";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";

const useStyles = makeStyles(() => ({
  question: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "20px",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "10px",
    "&.heading-bold":{
      marginTop:"5px",
      fontWeight:"600",
    }
  },
  image: {
    width: "352px",
    height: "234.67px",
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
  },
  questionTypeVenn:{
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "15px",
    "& .titleTop":{
      marginBottom:"10px"
    },
  },
  mainBox:{
    position:'relative'
  }
}));
const VennQuestion = (props: any) => {
  const classes = useStyles();
  const { currentStage } = props;
  const [height,setHeight] = React.useState(0);
  const hasCanvasData = currentStage.attributes.canvas_data;
  let jsonCamvas =  undefined
  let canvasSize =  undefined
  if(hasCanvasData)
  {
    const parsedData = typeof hasCanvasData == "object"?hasCanvasData:JSON.parse(hasCanvasData); 
    jsonCamvas = parsedData.canvas;
    canvasSize = parsedData.canvasSize 
  }
  return (
    <Box sx={{ p: "80px 30px 30px" }} className={classes.mainBox}>
      <Box>
        <Typography className={`${classes.questionTypeVenn} titleTop`}>{QuestionHeaders.VENN_DIAGRAM}</Typography>
        {  jsonCamvas && <Box style={{position:"absolute",width:"90%",height:"100%",}}>
          <StaticCanvas canvasData={jsonCamvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} callbackCanvas={setHeight}/>
          </Box>}
        <Box
          className={`${classes.title} heading-bold`}
          dangerouslySetInnerHTML={{ __html: currentStage.attributes.title }}
        />
      
      </Box>
      <Box className={classes.imageBox} style={{marginTop:`${height}px`}}>
        <img className={classes.image} src={venn} />
      </Box>
    </Box>
  );
};

export default VennQuestion;
