Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfCourseCreation23";
exports.labelBodyText = "CfCourseCreation23 Body";

exports.btnExampleTitle = "CLICK ME";
exports.addTextApi="bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.updateMultimediaApi="bx_block_cfcoursecreation23/lesson_markups/update_lesson_markup_tool"
exports.addKwlChartApi="bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.getStagesApi="bx_block_cfcoursecreation23/lesson_markups/lesson_markup_tools"
exports.addMultiMediaApi = "bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.addWordCloudApi = "bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.addMcqApi = "bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.addPollApi = "bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.addOpenEndedApi="bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"
exports.WebAPIKey=  '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'
exports.WebClienctID = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.webClientId = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.upploadFileGoogleApi = ''
exports.POST="POST"
exports.GET="GET"
exports.PUT="PUT"
exports.graphicModalTitle="Choose your Graphic Organizer"
exports.studentCountTitle="Graphic organizer"
exports.KWLChartModalText={title:"KWL-Chart added successfully!",
                          content:"Your KWL-Chart has been added successfully to"}
exports.TChartModalText={title:"T-Chart added successfully!",
                          content:"Your T-Chart has been added successfully to"}
exports.WHChartModalText={title:"WH-Chart added successfully!",
                          content:"Your WH-Chart has been added successfully to"}
exports.VennDiagramModalText={title:"Venn Diagram added successfully!",
                          content:"Your Venn Diagram has been added successfully to"}
exports.SeedDiscussionModalText={title:"Seed Discussion added successfully!",
                          content:"Your Seed Discussion has been added successfully to"}
exports.addTextApiForAssignment="bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool"
exports.getStageApiForAssignment="bx_block_assessmenttest/assignment_markups/assignment_markup_tools"
exports.addMatchingQApi="bx_block_cfcoursecreation23/lesson_markups/create_lesson_markup_tool"  
exports.addQuizMcqApi="bx_block_questionbank/quiz_markups/create_quiz_markup_tool" 
exports.matchingApiQuiz = "bx_block_questionbank/quiz_markups/create_quiz_markup_tool"
exports.addPollApiEndPoint = "bx_block_questionbank/quiz_markups/create_quiz_markup_tool"  
exports.openEndedForQuiz = "bx_block_questionbank/quiz_markups/create_quiz_markup_tool"                     
exports.MatchingQuestionText={title:" Matching Questions added Successfully!",
                          content:"Your Matching Questions have been added successfully to"}
exports.addPollStageApiForAssignment="bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool"
exports.addMCQApiForAssignment="bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool"
exports.addMatchingQApiForAssignment="bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool"
exports.addMultimediaApiForAssignment="bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool"
exports.DELETE="DELETE"
exports.deleteStageFromLesson="bx_block_cfcoursecreation23/lesson_markups/delete_lesson_stage"
exports.deleteStageFromAssignment="bx_block_assessmenttest/assignment_markups/delete_assignment_stage"
exports.updateLessonStage="bx_block_cfcoursecreation23/lesson_markups/update_lesson_markup_tool"
exports.updateAssignmentStage="bx_block_assessmenttest/assignment_markups/update_assignment_stage"
exports.PATCH="PATCH"
exports.updateLessonStageMcq="bx_block_cfcoursecreation23/lesson_markups/update_lesson_stage"
exports.getAllThQuiz = "bx_block_questionbank/quiz_markups/quiz_markup_tools"
exports.updateQuizEndPoint = "bx_block_questionbank/quiz_markups/update_quiz_markup_tool"
exports.deletStageApiEndpoint = "bx_block_questionbank/quiz_markups/delete_quiz_stage"
exports.saveLessonPublicLib = "bx_block_content_management/shared_libraries/save_to_shared_library"
exports.updateAssignment = "bx_block_assessmenttest/assignment_markups/update_assignment_stage"
exports.getmethod="GET"
exports.postMethod="POST"
exports.putMethod="PUT"
exports.updateQuiz="bx_block_content_management/teacher_flows/update_quiz";
// Customizable Area End