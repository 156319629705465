export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const question = require("../assets/question.svg");
export const comments = require("../assets/comments.svg");
export const defaultImage = require("../assets/default_image.jpg");
export const send = require("../assets/send.svg");
export const attach = require("../assets/attach.svg");
export const mention = require("../assets/mention.svg");
export const emoji = require("../assets/emoji.svg");
export const blueTick = require("../assets/blueTick.png");
export const greenTick = require("../assets/greenTick.png");
export const esclamation = require("../assets/esclamation.png");
export const correct = require("../assets/correct.png");
export const incorrect = require("../assets/view_invalid.svg");
export const closeIcon = require("../assets/close-icon.svg");
export const quizMusic = require("../assets/EngagemoQuizMusic.wav")
