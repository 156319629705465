import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Modal } from "@material-ui/core";
import GenericButton from "../../../../components/src/GenericButton.web";
import JoinClassModal from "../../../../components/src/JoinClassModal.web";
import { newLessonIcon } from "../assets";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor:  "#FDF4DC",
    color:  "#202020",
    fontFamily:  "Poppins",
    fontWeight:  500,
    fontSize:  "16px",
  },
  body:  {
    fontSize:  "14px",
    color:  "#3F526D",
    fontFamily:  "Poppins",
    fontWeight:  400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  tableStyle: {
    minWidth: 700,
  },
  noteIcon: {
    width: "28px",
    height: "28px",
    marginLeft: "30px",
    cursor:"pointer",
  },
  practiceImageStyle: {
    width: "28px",
    height: "28px",
    marginRight: "12px",
  },
  practiceNameColumnBoxStyle: {
    display: "flex",
    alignItems: "center",
  },
});


export default function DataTable(props: any) {
  const { practiceData } = props;
  const [openModal, setIsModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const classes = useStyles();

  const handleClick = (code: string) => {
    setCode(code);
    handleOpenModal();
  }

  const handleCloseModal=()=>{
    setIsModalOpen(false)
  }
  const handleOpenModal=()=>{
    setIsModalOpen(true)
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.tableStyle} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Lesson Name</StyledTableCell>
            <StyledTableCell align="center">Lesson Code</StyledTableCell>
            <StyledTableCell align="center">Stages</StyledTableCell>
            <StyledTableCell  align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {practiceData?.map((data: any) => (
            <StyledTableRow key={data.id}>
              <StyledTableCell component="th" scope="row">
                <Box className={classes.practiceNameColumnBoxStyle}>
                  <img
                    className={classes.practiceImageStyle}
                    src={newLessonIcon}
                  />{" "}
                  {data.name}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">{data.code}</StyledTableCell>
              <StyledTableCell align="center">{data.stages}</StyledTableCell>
              <StyledTableCell align="center">
                <GenericButton
                  data-test-id="joinNowBtn"
                  type="NORMAL"
                  label="Join Now"
                  handleClick={() => {
                    handleClick(data.code)
                  }}
                  customStyle={
                    (data.isLessonLive)
                      ? {color: 'white', backgroundColor: '#00A52C', border: 0, marginRight: '0'}
                      : {marginRight: '0'}
                  }
                  disabled={!data.isLessonLive}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      <Modal data-test-id="liveClassModal" open={openModal} onClose={handleCloseModal}>
        <JoinClassModal
          data-test-id="liveClass-model"
          handleCloseModal={handleCloseModal}
          name="Lesson"
          sessionCode={code}
        />
      </Modal>
    </React.Fragment>
  );
}
