import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fullscreenLogo,viewFullscreenLogo } from "../assets";
import { Roles } from "../../../../components/src/types";
const useStyles = makeStyles(() => ({
  lessonName: {
    fontFamily: "Poppins",
    fontSize: "13px",
    color: "#282829",
    fontWeight: 400,
  },
  mainLayout: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderBottom:"1px solid #F0F0F0",
  },
  lessonDetails: {
    display: "flex",
    marginBottom: "5px",
  },
  switchLayout: {
    display: "flex",
    alignItems: "center",
  },
  endLesson: {
    display: "flex",
    alignItems: "center",
  },
  class: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
  },
  selectedBtn: {
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 15px",
    textTransform: "none",
  },
  nonSelectedBtn: {
    backgroundColor: "#FFF",
    borderRadius: "50px",
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 15px",
    textTransform: "none",
  },
  endLessonBtn: {
    backgroundColor: "#FD5F5A !important",
    borderRadius: "50px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 15px",
    textTransform: "none",
    marginLeft: "24px",
    width: "130px",
  },
  fullscreenImg: {
    width: "19px",
    height: "19px",
    cursor:"pointer"
  },
  tag: {
    backgroundColor: "#D4DCE5",
    borderRadius: "10px",
    marginLeft: "10px",
    padding: "4px 10px",
    fontFamily: "Poppins",
    fontSize: "11px",
    color: "#3F526D",
    fontWeight: 400,
  },
}));
// 
const LiveSectionHeader = (props:any) => {
  const {lessonName, className, role,leaveLesson,setScreen,screen,modifyFullScreen,fullscreen,isQuiz} = props;
  const classes = useStyles();
  const Screens=["Lesson","Whiteboard"]
  const checkEnd=isQuiz?"End Quiz":"End Lesson"
  const checkLeave=isQuiz?"Leave Quiz":"Leave Lesson"
  return (
    <Box className={classes.mainLayout}>
      <Box>
        <Box className={classes.lessonDetails}>
          <Typography className={classes.lessonName}>
            {lessonName} <span className={classes.tag}>Computer</span>
          </Typography>
        </Box>
        <Typography className={classes.class}>{className}</Typography>
      </Box>


     { isQuiz ?<Box className={classes.switchLayout}>
        <Button className={classes.selectedBtn} >Quiz</Button>  
      </Box>: <Box className={classes.switchLayout}>
      { Screens.map((item)=>{
        return( <Button className={item==screen?classes.selectedBtn:classes.nonSelectedBtn} onClick={()=>setScreen(item)} data-test-id={`screen-${item}`}>{item}</Button>)
          })
      }
      </Box>}
      <Box className={classes.endLesson}>
        <img className={classes.fullscreenImg} src={fullscreen?viewFullscreenLogo:fullscreenLogo} onClick={modifyFullScreen} data-test-id="maxminImg"/>
        <Button className={classes.endLessonBtn}
          onClick={leaveLesson}
          data-test-id="lessonEnd"
          >
            {role===Roles.TEACHER?checkEnd:checkLeave}
        </Button>
      </Box>
    </Box>
  );
};

export default LiveSectionHeader;
