import React from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "180px",
    whiteSpace: "pre-wrap",
    height: "740px",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "10px",
    color: "#282829",
  },
  inputLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    width: "49%",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#5D5A6F",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "black",
      borderRadius: "10px",
    },
  },
}));

const MatchingQuesPreview = (props: any) => {
  const { currentStage } = props
  const classes = useStyles();
  const pairs = currentStage.attributes.pairs || currentStage.attributes.matching_pairs || [];
  const hasCanvasData = currentStage.attributes.canvas_data;

  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {
      const parsedData = JSON.parse(hasCanvasData);
      jsonCanvas = parsedData.canvas;
      canvasSize = parsedData.canvasSize;
  }
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${currentStage.attributes.background_image?.url})`,
  };
  return (
    <>
      <div className={classes.titleBox} id="content" >
        <Typography className={classes.title} dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}></Typography>
      </div>
      {jsonCanvas && <Box style={{width:"90%",position:"absolute"}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
        </Box>}
      <Box className={classes.stageTitle} style={backgroundStyle}>
        {pairs.map((pair: any) => (
          <Box key={pair.id} sx={{ px: "30px", pt: "30px" }}>
            <Typography className={classes.text}>
              Pair {pair.order_no}
            </Typography>
            <Box className={classes.inputLayout}>
              <TextField
                value={pair.pair_question}
                data-test-id="question"
                className={classes.textField}
                variant="outlined"
                disabled
              />
              <TextField
                value={pair.pair_correct_answer	}
                data-test-id="answer"
                className={classes.textField}
                variant="outlined"
                disabled
              />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default MatchingQuesPreview;
