import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { editProfileIcon,changePasswordIcon,logoutIcon } from "./assets";
import ProfileOption from "./ProfileOption.web";
const useStyles = makeStyles(() => ({
  optionsLayout: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
const OptionLayout = (props: any) => {
  const { handleLogOut, redirect, configJSON } = props;
  const classes = useStyles();
  return (
    <Box className={classes.optionsLayout}>
      <ProfileOption
        option={configJSON.editProfile}
        image={editProfileIcon}
        callback={redirect}
      />
      <ProfileOption
        option={configJSON.changePassword}
        image={changePasswordIcon}
        callback={redirect}
      />
      <ProfileOption
        option={configJSON.logOut}
        image={logoutIcon}
        callback={handleLogOut}
      />
    </Box>
  );
};

export default OptionLayout;
