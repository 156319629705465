import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContentEditable from "react-contenteditable";
import DrawingCanvas from "../HeaderTools.web";
import { fabric } from "fabric";
const useStyles = makeStyles(() => ({
  editArea: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    padding: "25px",
    paddingTop: "90px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
}));
const AddText = (props: any) => {
  const titleRef = React.useRef<any>(null);
  const { setAddTextState, addTextState, canvas } = props;
  const [canvasObj,setCanvasObj] = useState<fabric.Canvas|null>(null)
  const [isCanvasFocused,setCanvasFocused] = useState<boolean>(false)
  const classes = useStyles();
  const handleInput = (event: any) => {
    setAddTextState(event.target.value);
  };
  return (
    <>
    <div style={{position:"relative",marginTop:"41px"}} >
      <div data-testId ="focusId" style={{pointerEvents:isCanvasFocused?"all":"none"}}> 
        <DrawingCanvas navigation="" id=""  canvas={canvas} handleCanvas={"AddText"} setCanvasObj={setCanvasObj} 
        isCanvasFocused={isCanvasFocused} setCanvasFocused={setCanvasFocused}
        />
      </div>
      <ContentEditable
        className={classes.editArea}
        html={  addTextState ?? ""}
        disabled={false}
        onChange={handleInput}
        innerRef={titleRef}
        data-testId="stageDescriptionInput"
      />
    </div>
    </>
  );
};

export default AddText;
