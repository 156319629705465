import React from "react";
import { Box, Grid, Button, Typography, Container } from "@material-ui/core";
import { leftBtn, rightBtn } from "../assets";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  bannerLayout: {
    padding: "50px 0",
    background: "#2b3d7d0A",
  },
  btnLayout: {
    display: "flex",
    marginBottom: "150px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  navBtnLayout: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginBottom: "30px",
    fontSize: "48px",
  },
  subHeading: {
    color: "#322F37",
    fontFamily: "Poppins",
    fontWeight: 400,
    marginBottom: "30px",
    fontSize: "16px",
  },
  buttonBg: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    marginRight: "20px",
    color: "#FFF",
    borderRadius: "10px",
    width: "190px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  normalBtn: {
    borderRadius: "10px",
    width: "190px",
    padding: "12px",
    textTransform: "none",
    borderColor: "#3F526D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  bannerImage: {
    borderRadius: "10px",
  },
  slideCounter: {
    fontFamily: "Poppins",
    color: "#323548",
    fontSize: "18px",
  },
  bannerContent: {
    marginRight: "80px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));
const Banner = (props: any) => {
  const classes = useStyles();
  const {
    bannerData,
  } = props;
  const [currentBannerIndex, setCurrentBannerIndex] = React.useState<number>(0);
  const bannerChanger=()=>{
    return setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerData.length);
    }, 5000);
  }
  React.useEffect(() => {
    const intervalId =  bannerChanger()
    return () => clearInterval(intervalId);
  }, [bannerData]);
const prevBanner=()=>{
  setCurrentBannerIndex((prevIndex) => (prevIndex - 1) % bannerData.length);
}
const nextBanner=()=>{
  setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerData.length);
}
const currentBanner = bannerData[currentBannerIndex];
  return (
    <Box className={classes.bannerLayout}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={7} sm={12} xs={12}>
            <Box className={classes.bannerContent}>
              <Box>
                <Typography variant="h3" className={classes.heading}>
                  {currentBanner?.title}
                </Typography>
                <Typography variant="body1" className={classes.subHeading}>
                  {currentBanner?.description}
                </Typography>
              </Box>
              <Box className={classes.btnLayout}>
                {currentBanner?.link1_name && currentBanner?.link1 &&(
                  <Button
                    href={currentBanner?.link1}
                    className={classes.buttonBg}
                    variant="outlined"
                    data-testid="getStartedBtn"
                  >
                    {currentBanner?.link1_name}
                  </Button>
                )}

                {currentBanner?.link2_name && currentBanner?.link2 &&(
                  <Button
                    href={currentBanner?.link2}
                    className={classes.normalBtn}
                    variant="outlined"
                    data-testid="learnMoreBtn"
                  >
                    {currentBanner?.link2_name}
                  </Button>
                )}
              </Box>

              <Box className={classes.navBtnLayout}>
                <Button
                  onClick={prevBanner}
                  data-test-id="prevBtn"
                  disabled={currentBannerIndex+1 === 1 ? true : false}
                >
                  <img src={leftBtn} />
                </Button>
                <Typography className={classes.slideCounter}>
                  {currentBannerIndex+1}/{bannerData.length}
                </Typography>
                <Button
                  onClick={nextBanner}
                  data-test-id="nextBtn"
                  disabled={currentBannerIndex+1 === bannerData.length ? true : false}
                >
                  <img src={rightBtn} />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            {currentBanner?.image && (
              <img
                className={classes.bannerImage}
                src={currentBanner?.image}
                width="100%"
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
