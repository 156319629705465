export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeIcon= require("../assets/image_Close.svg");
export const multimediaIcon= require("../assets/image_upload_icon.svg");
export const timerIcon = require("../assets/timer.svg");
export const settingsIcon = require("../assets/settings.svg");
export const backgroundImageIcon = require("../assets/background_image.svg");
export const backgroundImageActivePng = require("../assets/backgroundimageActiveIcon.png");
export const timerActivePng = require("../assets/countdownTimerActive.png");


