import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  TextField,
  Grid,
  Button,
  Typography
} from "@material-ui/core";
import StaticCanvas from "../../../../../components/src/StaticCanvas.web";
import { ACTIONS, getQuestionHeader } from "../../../../../components/src/types";
const useStyles = makeStyles(() => ({
  mainLayout: {
    padding: "30px 60px",
    justifyContent: "center",
    alignItems: "flex-start",
    display: "flex",
    height: "100%",
  },
  question: {
    marginBottom: "20px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "18px",
  },
  submitBtn: {
    borderRadius: "50px",
    height: "44px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    width: "107px",
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    "&:hover": {
      backgroundColor: "#2B3D7D",
      color: "#FFFFFF",
    },
  },
  contentBox: {
    padding: "30px 60px",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    backgroundColor: "#FFF",
    display: "flex",
    width: "620px",
    justifyContent: "center",
    borderRadius: "10px",
    flexDirection: "column",
    position:"relative"
  },
  inputLabel: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "10px 0",
  },
  inputValue: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#2C2C2E",
    fontFamily: "Poppins",
  },
  disabledBtn: {
    opacity:"0.5",
    fontWeight: 500,
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    width: "107px",
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    "&:hover": {
      backgroundColor: "#2B3D7D",
      color: "#FFFFFF",
    },
  },
  submitBtnLayout: {
    cursor:"pointer",
    marginTop: "25px",
  },
  title: {
    marginBottom: "10px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "21px",
  },
  qtype: {
    marginBottom: "7px",
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#282829",
    fontWeight: 400,
},
}));

const StudentChartLive = (props: any) => {
  const { stage, showQuestion,addChartSubmitData,stageAnswers, isGuest } = props;
  const classes = useStyles();
  const mdValue = stage?.attributes.description.length === 6 ? 6 : 12;
  const [chartState, setChartState] = useState(() => stage?.attributes.description || []);
  const canAnswer = (stageAnswers??[]).length<=0 && !isGuest;
  const [height,setHeight] = useState(0);
  const hasCanvasData = stage?.attributes?.canvas_data;
  const jsonCanvas = hasCanvasData ?  hasCanvasData.canvas : undefined
  const canvasSize = hasCanvasData ?  hasCanvasData.canvasSize : undefined
  const getTypeQuestion=()=> {
    return getQuestionHeader(stage?.attributes.lesson_stage_name ?? "" as ACTIONS) 
  }
  useEffect(() => {
    setChartState(stage?.attributes.description || []);
  }, [stage?.attributes.description]);

  const handleInputChange = (id: string, event: any) => {
    const index = parseInt(id.split("-")[1]) - 1;
    const updatedState = chartState.map((item:any, idx:number) => {
      if (idx === index) {
        return { ...item, answer: event.target.value };
      }
      return item;
    });
    setChartState(updatedState);
  };

  const handleSubmit = () => {
    const submissionData = chartState.map((item:any) => ({
      question: item.topic,
      answer: item.answer
    }));
    addChartSubmitData(submissionData);
  };
  return (
    <Box className={classes.mainLayout}>
      <Box className={classes.contentBox}>
      <Typography className={classes.qtype}>
        {getTypeQuestion()}
      </Typography>
        <Box style={{marginBottom:`${height}px`}}>
        {  jsonCanvas &&
        <Box style={{position:"absolute",width:"90%",height:"100%",}}>
          <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined} 
          callbackCanvas={setHeight}
          />
          </Box>}
          <div className={classes.title} dangerouslySetInnerHTML={{__html:stage?.attributes.title}}/>
        </Box>
        <Grid container spacing={2}>
          {stage?.attributes.description.map((item: any, index: number) => (
            <Grid key={index} item md={mdValue}>
              <FormControl fullWidth variant="outlined">
                <div className={classes.inputLabel} dangerouslySetInnerHTML={{__html:item.topic}}/>
                <TextField
                  id={`answer-${index + 1}`}
                  name={`answer-${index + 1}`}
                  data-test-id={"option-name"}
                  fullWidth
                  placeholder={`Answer ${index+1}`}
                  variant="outlined"
                  onChange={(e) => handleInputChange(`topic-${index + 1}`, e)}
                  className={classes.inputValue}
                  value={chartState[index]?.answer || ""}
                  disabled={!canAnswer}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        {canAnswer && <Box className={classes.submitBtnLayout}>
          <Button className={!showQuestion? classes.disabledBtn :classes.submitBtn} onClick={showQuestion && handleSubmit}>Submit</Button>
        </Box>}
      </Box>
    </Box>
  );
};

export default StudentChartLive;
