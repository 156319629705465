import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#2B3D7D",
      border: "1px solid #2B3D7D",
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.90rem',
        minWidth: '28px',
        height: '28px',
        margin: '0 2px',
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#ffffff",
      border: "1px solid #2B3D7D",
    },
    "& .MuiPaginationItem-ellipsis": {
      border: "none",
    },
  },
  paginationLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    marginTop:"100px",
    marginBottom:"50px",
    "@media (max-width: 600px)": {
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
}));

export default function BasicPagination(props:any) {
  const {handlePageChange, pageLimit, pageNo} = props
  const classes = useStyles();
  return (
    <Box className={classes.paginationLayout}>
      <Pagination
        classes={{ ul: classes.ul }}
        count={pageLimit}
        page={pageNo}
        variant="outlined"
        shape="rounded"
        size="large"
        siblingCount={0}
        onChange={handlePageChange}
      />
    </Box>
  );
}
