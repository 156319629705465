import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { defaultImage} from "../../../../assets";
const useStyles = makeStyles(() => ({
  mainLayout: {
    padding: "20px",
  },
  studentHeadingLayout: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  studentHeading: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#2B3D7D",
    fontWeight: 500,
  },
  countBox: {
    backgroundColor: "#F1F5F9",
    border: "1px solid #2B3D7D",
    borderRadius: "10px",
    marginLeft: "15px",
    width: "31px",
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  count: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#2B3D7D",
    fontWeight: 500,
  },
  studentImage: {
    width: "36px",
    height: "36px",
    marginRight: "10px",
    borderRadius: "50%",
  },
  studentName: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F526D",
    fontWeight: 400,
  },
  studentLayout: {
    display: "flex",
    alignItems: "center",
    marginBottom:"16px",
    "&:last-child":{
      marginBottom:"0",
    },
  },
}));
const Participants = (props: any) => {
    const { participants } = props;
  const classes = useStyles();
  return (
    <Box className={classes.mainLayout}>
      <Box className={classes.studentHeadingLayout}>
        <Typography className={classes.studentHeading}>Students</Typography>
        <Box className={classes.countBox}>
          <Typography className={classes.count}>
            {participants.length}
          </Typography>
        </Box>
      </Box>
      {participants.map((participant: any) => (
        <Box className={classes.studentLayout}>
          <img
            src={participant.profileImage ? participant.profileImage : defaultImage}
            className={classes.studentImage}
          />
          <Typography className={classes.studentName}>
            {participant.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Participants;
