Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/dashboards";
exports.classListGetUrl = "bx_block_content_management/teacher_flows/teacher_class_index";
exports.studentListGetUrl = "bx_block_statisticsreports2/statisticsreports/listing_students"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.btnExampleTitle = "CLICK ME";
exports.breadCrumbTitle = 'Teachers';
exports.post="POST";
exports.summaryReportList="bx_block_statisticsreports2/statisticsreports/generate_lesson_stundents_answers_summary_report"
exports.get="GET";
exports.getStudentId="bx_block_statisticsreports2/statisticsreports/listing_students"
exports.deatilReportList="bx_block_statisticsreports2/statisticsreports/generate_detailed_lesson_report"
exports.filterStudentsList = "bx_block_statisticsreports2/statisticsreports/date_by_filter_student"
// Customizable Area End