import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, useMediaQuery } from "@material-ui/core";
import {
    assignment_pending,
    certificate_earned,
    course_progress,
    course_complete
} from "../../assets";
const useStyles = makeStyles(() => ({
    overviewTitle: {
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 600,
        color: "#2B3D7D",
        marginTop: "30px",
        marginBottom: "25px", 
    },
    container:{
    //    maxWidth:"80%", 
       width:"250px",      
       margin:"5px",
       padding:"15px",
       border:"none",
       borderRadius:"10px",
       boxShadow:"0px 0px 8px 0px rgba(0,0,0,0.25)"
    },
    nameText:{
        fontFamily: "Poppins",
        fontSize: "14px",
        marginLeft:"10px"
    },
    countText:{
        fontFamily: "Poppins",
        fontSize: "40px",
        fontWeight: 600,
        width:" max-content"
    }
}));
const actionItems = [{
    name: "Course in progress",
    count: 18,
    image: course_progress,
    background: "#FF633B",
}, {
    name: "Course completed",
    count: 5,
    image: course_complete,
    background: "#00CC7E",
}, {
    name: "Assignments Pending",
    count: 18,
    image: assignment_pending,
    background: "#FF9570",
},]
const ActionItems = (props: any) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (<>
        <Typography className={classes.overviewTitle}>Overview</Typography>
            <Grid container={isSmallScreen ? false : true} style={{backgroundColor : 'red !important'}}>
                {actionItems.map((data: any, index: number) => (
                    <Grid item md key={data.name}>
                        <Box
                            className={classes.container}
                        >
                            <Grid container>
                                <Grid item>
                                    <img src={data.image} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.nameText}>
                                        {data.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography className={classes.countText} style={{ borderBottom: `2px solid ${data.background}`}}>
                                {(props.studentActionItems && (Object.values(props.studentActionItems)[index] as React.ReactNode)) ?? 0}
                            </Typography>
                        </Box>
                    </Grid>))}
            </Grid>
    </>
    )
}
export default ActionItems;