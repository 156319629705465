import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Assignments, Lessons, Quizzes, assignmentsIcon, lessonsIcon, quizzesIcon } from "../../../assets";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { makeStyles } from "@material-ui/core/styles";
import { CLASS_OPTIONS, Roles } from "../../../../../../components/src/types";
const useStyles = makeStyles((theme) => ({
  listItemBox: {
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginTop: "30px",
    marginRight: "45px",
    padding: "15px",
    width: "183px",
    height: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
      cursor: "pointer",
    },
    [theme.breakpoints.down('xs')]: {
      marginRight:0
     },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 600,
    textAlign: "center",
  },
  listItemImage: {
    marginBottom: "17px",
    width: "100px",
    height: "100px",
    alignSelf: "center",
  },
  container: {
    display: "flex",
    marginLeft: "35px",
    [theme.breakpoints.down('xs')]: {
      flexDirection:'column',
      alignItems:'center',
      width:'100%',
      marginLeft:0
     },
  },
}));

const SelectedClass = (props: any) => {
  const { callback, locations, currentLocation, redirect, role, type } = props;
  const practices = [
    { name: CLASS_OPTIONS.LESSONS, image: role === Roles.SUB_ADMIN ? Lessons : lessonsIcon },
    { name: CLASS_OPTIONS.QUIZZES, image: role === Roles.SUB_ADMIN ? Quizzes : quizzesIcon },
    { name: CLASS_OPTIONS.ASSIGNMENTS, image: role === Roles.SUB_ADMIN ? Assignments : assignmentsIcon },
  ];
  
  const classes = useStyles();
  return (
    <>
      { type !== "sharedLibrary" && (<Box sx={{ pl: "35px", mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
      </Box>)}
      <Box className={classes.container}>
        {practices.map((practice: any, index: any) => (
          <Box
            key={index}
            className={classes.listItemBox}
            onClick={() => callback(practice.name)}
          >
            <img className={classes.listItemImage} src={practice.image} />
            <Typography className={classes.listItemFont}>
              {practice.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default SelectedClass;
