import React from "react";
// Customizable Area Start
import {
  Box,
  Grid
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Roles } from "../../../components/src/types";
import SchoolAdminCatalogue from "./SchoolAdmin/SchoolAdminCatalogue.web";
import TeacherCatalogue from "./Teachers/TeacherCatalogue.web";
import StudentCatalogue from "./Student/StudentCatalogue.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.SUB_ADMIN) {
      return (
        <SchoolAdminCatalogue navigation={this.props.navigation} id={this.props.id}/>
      );
    }
    if (role === Roles.TEACHER) {
      return (
        <TeacherCatalogue navigation={this.props.navigation} id={this.props.id}/>
      );
    }
    if (role === Roles.STUDENT) {
      return (
        <StudentCatalogue navigation={this.props.navigation} id={this.props.id}/>
      );
    }
  }
  
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
       {this.renderViewBasedOnRole(this.state.role)}
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
