import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Roles } from "../../../components/src/types";
import TeacherContentManagement from "./Teacher/TeacherContentManagement.web"
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
import StudentContentManagement from "./Student/StudentContentManagement.web";

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handlePageChangeClassList = this.handlePageChangeClassList.bind(this)
    this.handleSearchClassName = this.handleSearchClassName.bind(this)
    this.showClassDetailView = this.showClassDetailView.bind(this)
    this.createClass = this.createClass.bind(this)
    this.showStudentListView = this.showStudentListView.bind(this)
    this.showStudentLessonListView = this.showStudentLessonListView.bind(this)
    this.showAddStudentView = this.showAddStudentView.bind(this)
    this.redirect = this.redirect.bind(this)
    this.createStudent = this.createStudent.bind(this)
    this.updateStudent = this.updateStudent.bind(this)
    this.viewStudent = this.viewStudent.bind(this)
    this.deleteStudent = this.deleteStudent.bind(this)
    this.deleteCleanup = this.deleteCleanup.bind(this)
    this.sendReminder = this.sendReminder.bind(this)
    this.reminderCleanup = this.reminderCleanup.bind(this)
    this.showLessonListView = this.showLessonListView.bind(this)
    this.handlePageLessonList = this.handlePageLessonList.bind(this)
    this.handlePageStudentLessonList = this.handlePageStudentLessonList.bind(this)
    this.handleSearchLessonName = this.handleSearchLessonName.bind(this)
    this.handleSearchStudentLessonName = this.handleSearchStudentLessonName.bind(this)
    this.handlePageChangeStudent = this.handlePageChangeStudent.bind(this)
    this.handleSearchStudent = this.handleSearchStudent.bind(this)
    this.redirectToLiveLessonStreamView = this.redirectToLiveLessonStreamView.bind(this)
    this.setCreateUpdateStudentModal = this.setCreateUpdateStudentModal.bind(this)
    this.handlePageQuizList = this.handlePageQuizList.bind(this)
    this.handleSearchQuizName = this.handleSearchQuizName.bind(this)
    this.deliverQuiz = this.deliverQuiz.bind(this)
    this.handleNavigation = this.handleNavigation.bind(this)
    this.handleParamsNavigation = this.handleParamsNavigation.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER) {
      return (
        <TeacherContentManagement
        data-test-id="teacherContent"
          myClassesState={this.state.myClassesState}
          handlePageChangeClassList={this.handlePageChangeClassList}
          handleSearchClassName={this.handleSearchClassName}
          showClassDetailView={this.showClassDetailView}
          createClass={this.createClass}
          destination={this.state.destination}
          locations={this.locations}
          showStudentListView={this.showStudentListView}
          showAddStudentView={this.showAddStudentView}
          showLessonListView={this.showLessonListView}
          redirect={this.redirect}
          createStudent={this.createStudent}
          updateStudent={this.updateStudent}
          viewStudent={this.viewStudent}
          deleteStudent={this.deleteStudent}
          isDeleteSuccess={this.state.isDeleteSuccess}
          deleteCleanup={this.deleteCleanup}
          sendReminder={this.sendReminder}
          pageNo={this.state.pageNo}
          reminderCleanup={this.reminderCleanup}
          isReminderSuccess={this.state.isReminderSuccess}
          isLoading={this.state.isLoading}
          pageLimit={this.state.pageLimit}
          pageNoStudent={this.state.pageNoStudent}
          pageLimitStudent={this.state.pageLimitStudent}
          handlePageLessonList={this.handlePageLessonList}
          pageNoLesson={this.state.pageNoLesson}
          pageLimitLesson={this.state.pageLimitLesson}
          handleSearchLessonName={this.handleSearchLessonName}
          handlePageChangeStudent={this.handlePageChangeStudent}
          handleSearchStudent={this.handleSearchStudent}
          searchInputStudentName={this.state.searchInputStudentName}
          redirectToLiveLessonStreamView={this.redirectToLiveLessonStreamView}
          setCreateUpdateStudentModal={this.setCreateUpdateStudentModal}
          studentApiError={this.state.studentApiError}
          showReportView={this.showReportView}
          openReportModal={this.state.openReportModal}
          openReportModalFunc={this.openReportModal}
          closeReportModal={this.closeReportModal}
          showSummaryReportListView={this.showSummaryReportListView}
          pageNoAssignment={this.state.pageNoAssignment}
          pageLimitAssignment={this.state.pageLimitAssignment}
          handlePageAssignmentList={this.handlePageAssignmentList}
          handleSearchAssignmentName={this.handleSearchAssignmentName}
          pageNoQuiz={this.state.pageNoQuiz}
          pageLimitQuiz={this.state.pageLimitQuiz}
          handlePageQuizList={this.handlePageQuizList}
          handleSearchQuizName={this.handleSearchQuizName}
          deliverQuiz={this.deliverQuiz}
          handleOpenNoMobileModal={this.handleOpenNoMobileModal}
          handleCloseNoMobileModal={this.handleCloseNoMobileModal}
          openNoMobileModal={this.state.openNoMobileModal}
          deleteClassCallback={this.deleteClass}
          deleteClassErrorMsg={this.state.errorMsg}
          showError={this.state.showError}
          handleCloseError={this.handleCloseError}
          setInternalDate={this.setInternalDate}
          internalDate={this.state.internalDate}
        />
      );
    }
    // Customizable Area Start
    if (role === Roles.STUDENT) {
      return (
        <StudentContentManagement
          data-test-id = "studentContentManagement"
          myClassesState={this.state.myClassesState} 
          classList={this.state.studentClassListData}
          destination={this.state.destination}
          locations={this.locations}
          showStudentListView={this.showStudentListView}
          showStudentLessonListView={this.showStudentLessonListView}
          redirect={this.redirect}
          showClassDetailView={this.showClassDetailView}
          handleNavigation={this.handleNavigation}
          handleParamsNavigation={this.handleParamsNavigation}
          isLoading={this.state.isLoading}
          pageNoStudentLesson={this.state.pageNoStudentLesson}
          pageLimitStudentLesson={this.state.pageLimitStudentLesson}
          handleSearchStudentClassName={this.handleSearchStudentClassName}
          handleSearchStudentLessonName={this.handleSearchStudentLessonName}
          handlePageStudentLessonList={this.handlePageStudentLessonList}
        />
      )
    }
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <SuccessSnackbar
          message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
          open={this.state.isLimitModalOpen} 
          onClose={this.handleCloseLimitSnackbar} 
          type={'error'}
        />
        {this.renderViewBasedOnRole(this.state.role)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
