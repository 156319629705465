import React from "react";
import AddTextLive from "../../../../LiveStreaming/src/components/AddTextLive.web";
import { ACTIONS } from "../../../../../components/src/types";
import StudentChartLive from "./StudentChartLive.web";
import StudentWordCloud from "./StudentWordCloud.web";
import MultiMediaLive from "../../../../LiveStreaming/src/components/MultiMediaLive.web";
import StudentMcqLive from "./StudentMcqLive.web";
import StudentOpenEndedLive from "./StudentOpenEndedLive.web";
import StudentMatchingLive from "./StudentMacthingLive.web";
const StudentLiveScreen = (props: any) => {
  const { currentStage,showQuestion,addChartSubmitData,showResult,wordCloudAnswer,stageAnswers,isQuizStudent,isQuizTimedOut,seconds,isPlaying,isGuest } = props;
  const stageType = isQuizStudent?currentStage?.attributes.quiz_stage_name as ACTIONS:currentStage?.attributes.lesson_stage_name as ACTIONS

  const renderCurrentStage = () => {
    if (!currentStage) return;
    if (stageType === ACTIONS.ADD_TEXT) {
      return <AddTextLive stage={currentStage} />;
    } else if (
      currentStage.attributes.lesson_stage_name === ACTIONS.KWL_CHART ||
      currentStage.attributes.lesson_stage_name === ACTIONS.WH_CHART ||
      currentStage.attributes.lesson_stage_name === ACTIONS.SEED_DISCUSSION ||
      currentStage.attributes.lesson_stage_name === ACTIONS.T_CHART ||
      currentStage.attributes.lesson_stage_name === ACTIONS.VENN_DIAGRAM
    ) {
      return <StudentChartLive stage={currentStage} showQuestion={showQuestion} addChartSubmitData={addChartSubmitData} stageAnswers={stageAnswers} isGuest={isGuest}/>;
    }else  if (stageType === ACTIONS.MCQ) {
      return <StudentMcqLive stage={currentStage} showQuestion={showQuestion} showResult={showResult} handleSubmit={props.handleSubmit} selectedOption={props.selectedOption} setSelectedOption={props.setSelectedOption}
      selectedOptionId={props.selectedOptionId} setSelectedOptionId={props.setSelectedOptionId} stageAnswers={stageAnswers} isQuizStudent={isQuizStudent} isQuizTimedOut={isQuizTimedOut} isGuest={isGuest}
       />;
    } else if (
      currentStage.attributes.lesson_stage_name === ACTIONS.ADD_WORD_CLOUD
    ) {
      return <StudentWordCloud stage={currentStage} showQuestion={showQuestion} addChartSubmitData={addChartSubmitData} showResult={showResult} wordCloudAnswer={wordCloudAnswer} stageAnswers={stageAnswers} isGuest={isGuest}/>;
    }else if (
      currentStage.attributes.lesson_stage_name === ACTIONS.MULTIMEDIA
    ) {
      return <MultiMediaLive stage={currentStage} isStudent={true} seconds={seconds} isPlaying={isPlaying}/>;
    }else if (
      stageType === ACTIONS.OPEN_ENDED_QUESTION
    ) {
      return <StudentOpenEndedLive stage={currentStage}  showQuestion={showQuestion} setSuccess={props.setSuccess} stageAnswers={stageAnswers} isQuizStudent={isQuizStudent} isQuizTimedOut={isQuizTimedOut} isGuest={isGuest}/>;
    }
    else if (
      stageType === ACTIONS.ADD_MATCHING_Q
    ) {
      return <StudentMatchingLive stage={currentStage}  showQuestion={showQuestion} addChartSubmitData={addChartSubmitData} showResult={showResult} stageAnswers={stageAnswers} isQuizStudent={isQuizStudent} isQuizTimedOut={isQuizTimedOut} isGuest={isGuest}/>;
    } else  if (stageType === ACTIONS.POLLING_Q) {
      return <StudentMcqLive stage={currentStage} showQuestion={showQuestion} showResult={showResult} handleSubmit={props.handleSubmit} selectedOption={props.selectedOption} setSelectedOption={props.setSelectedOption} isPolling={true}
      selectedOptionId={props.selectedOptionId} setSelectedOptionId={props.setSelectedOptionId} stageAnswers={stageAnswers} isQuizStudent={isQuizStudent} isQuizTimedOut={isQuizTimedOut} isGuest={isGuest}
      />;
    }
  };
  return (
    <>
      <div style={{position:"relative"}}>{renderCurrentStage()}</div>
    </>
  );
};

export default StudentLiveScreen;
