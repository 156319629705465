import React from "react";

import { Typography, Breadcrumbs } from "@material-ui/core";
type OverflowXProperty = "hidden";
type TextAlignProperty = "center";
import AppHeader from "../../../components/src/AppHeader.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
const configJSON = require("./config");


interface RenderBreadCrumbsProps {
  view: string;
  handleBreadCrumb: any;
  teacherName: string;
  classTitle: string;
  reportsHeading: string;
}

interface HeaderProps {
  reportType: string;
  view: string;
}

export const RenderBreadCrumbs: React.FC<RenderBreadCrumbsProps> = ({
  view,
  handleBreadCrumb,
  teacherName,
  classTitle,
  reportsHeading,
}) => {
  const breadcrumbc1 =
    view === "classDetails" ||
    view === "studentDetails" ||
    view === "reportsView" ||
    view === "detailReportsView";
  const breadcrumbc2 = view === "classList" || breadcrumbc1;
  return (
    <Breadcrumbs
      data-test-id="breadcrumb-section"
      style={webStyles.navigationIcon}
      separator={<NavigateNextIcon fontSize="large" />}
    >
      <Typography
        data-test-id="breadcrumb-title"
        style={breadcrumbc2 ? webStyles.title : webStyles.boldTitleDefault}
        onClick={() => handleBreadCrumb("teacherList")}
      >
        {configJSON.breadCrumbTitle}
      </Typography>
      {breadcrumbc2 && (
        <Typography
          data-test-id="breadcrumb-title2"
          style={breadcrumbc1 ? webStyles.title : webStyles.boldTitle}
          onClick={() => handleBreadCrumb("classList")}
        >
          {teacherName}
        </Typography>
      )}
      {breadcrumbc1 && (
        <Typography
          data-test-id="breadcrumb-title3"
          style={webStyles.boldTitle}
          onClick={() => handleBreadCrumb("studentDetails")}
        >
          {classTitle}
        </Typography>
      )}
      {view === "reportsView" && (
        <Typography
          data-test-id="breadcrumb-title4"
          style={webStyles.boldTitle}
        >
          {reportsHeading}
        </Typography>
      )}
      {view === "detailReportsView" && (
        <Typography
          data-test-id="breadcrumb-title4"
          style={webStyles.boldTitle}
        >
          {reportsHeading}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export const Header: React.FC<HeaderProps> = ({ reportType, view }) => {
  const reportsHeading =
    reportType === "summary" ? "Summary Report" : "Detailed Report";
  const reportsCheck =
    view === "reportsView" ? `${reportsHeading}s` : "Reports";
  const headerTitle =
    view === "studentDetails" ? "Select Student" : reportsCheck;
  return <AppHeader data-test-id="appheader-section" title={headerTitle} />;
};

const webStyles = {
  filterIcon: {
    cursor: "pointer",
    marginTop: "24px",
  },
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  headText: {
    fontSize: "24px",
    // color: "#2B3D7D",
    letterSpacing: "0.32px",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "30px 30px 0px",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#757575",
    cursor: "pointer",
  },
  boldTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#000000",
  },
  boldTitleDefault: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "0px",
  },
  breadcrumbBox: {
    padding: "0px 11px",
    marginTop: "0px",
  },
  noRecordText: {
    textAlign: "center" as TextAlignProperty,
    fontFamily: "Poppins",
  },
  rightWrapper: {
    height: "100%",
    overflowY: "scroll" as OverflowXProperty,
  },
  paginationBox: {
    marginTop: "100px",
    marginBottom: "50px",
  },
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
};
