import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "60px"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    
    whiteSpace: "pre-wrap"
  },
  addTextLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 60px",
    marginBottom: "60px"
  },
  descWithCanvas: {
    marginTop: "260px",
  },
  addTextLayoutD: {
    padding: "30px 60px",
  },
}));

const PreviewStagePage = (props: any) => {
  const {currentStage}=props
  const classes = useStyles();
  const iscanvasExist = currentStage.attributes.canvas_data
  let hasCanvasDataTitle = undefined; 
  let hasCanvasDataDesc = undefined; 
  let canvasSize = undefined; 
  let canvasDescSize = undefined; 

  if(iscanvasExist){
    const parsedData = typeof iscanvasExist == "object"?iscanvasExist:JSON.parse(iscanvasExist)
    hasCanvasDataTitle = parsedData.canvas
    hasCanvasDataDesc = parsedData.AddTextCanvas
    if(parsedData.canvasSize)
    {
      canvasSize = JSON.parse(parsedData.canvasSize)
    }
    if(parsedData.addTextCanvasSize)
    {
      canvasDescSize = JSON.parse(parsedData.addTextCanvasSize)
    }
  }
  return (
    <div >
      <Box className={classes.addTextLayout}>
        <Typography className={classes.title}  dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}/>
      </Box>
    {  hasCanvasDataTitle && <Box style={{ width: "100%", position: "absolute", zIndex: 1 }}><StaticCanvas canvasData={hasCanvasDataTitle} id="canvasId" canvasSize={canvasSize}/></Box>}
      <div className={`${classes.stageTitle} ${hasCanvasDataDesc && classes.descWithCanvas}`} id="content">
        <Typography style={{ color: "#1C1C1E", fontSize: "20px", fontFamily: "Poppins" }} 
         dangerouslySetInnerHTML={{
            __html: currentStage.attributes.description[0],
           }}/>
      </div>
      {  hasCanvasDataDesc && <Box style={{ width: "100%",zIndex: 1}}>
  <StaticCanvas canvasData={hasCanvasDataDesc} id="canvasId2" canvasSize={canvasDescSize} isFull={true}/>
  </Box>}
    </div>
  );
};

export default PreviewStagePage;
