import React from "react";
import { Modal, ListItemText } from "@material-ui/core";
import DeleteProfileModal from "./DeleteProfileModal.web";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/src/StyledMenu.web";

const CustomContextMenu = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    handleCloseContextMenu,
    handleOpenDeleteProfileModal,
    handleCloseDeleteProfileModal,
    openDeleteProfileModal,
    redirect,
    contextMenuUserData,
    deleteProfile,
    role,
    isDeleteSuccess,
    deleteProfileError,
  } = props;
  return (
    <>
      <StyledMenu
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={openContextMenu}
        onClose={handleCloseContextMenu}
      >
        <StyledMenuItem>
          <ListItemText
            data-testid="view-classes-option"
            primary="View Classes"
            onClick={() => redirect("Classes", contextMenuUserData, role)}
            color="#48484A"
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemText
            data-testid="delete-profile-option"
            primary="Delete Profile"
            onClick={(e) => {
              handleCloseContextMenu(e);
              handleOpenDeleteProfileModal();
            }}
            color="#48484A"
          />
        </StyledMenuItem>
      </StyledMenu>
      <Modal
        data-testid="delete-modal"
        open={openDeleteProfileModal}
        onClose={handleCloseDeleteProfileModal}
      >
        <DeleteProfileModal
          handleCloseModal={handleCloseDeleteProfileModal}
          userData={contextMenuUserData}
          deleteProfile={deleteProfile}
          handleCloseContextMenu={handleCloseContextMenu}
          role={role}
          isDeleteSuccess={isDeleteSuccess}
          deleteProfileError={deleteProfileError}
        />
      </Modal>
    </>
  );
};

export default CustomContextMenu;
