import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { defaultQuestion } from "../../../../assets";
import { ACTIONS } from "../../../../../../../components/src/types";
const useStyles = makeStyles(() => ({
  text: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
    marginBottom: "20px",
  },
  image: {
    width: "60px",
    heignt: "60px",
    marginBottom: "10px",
  },
  button: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 500,
    border: "1px solid #2B3D7D",
    borderRadius: "50px",
    textTransform: "none",
  },
  mainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
const InitQuestion = (props: any) => {
  const { setShowQuestion, showQuestion, currentStage,hasSubStage } = props;
  const classes = useStyles();
  const stageType=currentStage?.attributes?.lesson_stage_name as ACTIONS
  const notShowQuestion = [ACTIONS.ADD_TEXT , ACTIONS.MULTIMEDIA].includes(stageType) && !hasSubStage
  const renderText = () => {
    if (notShowQuestion)
      return "No questions, yet.";
    else return "No questions started yet.";
  };
  return (
    <Box className={classes.mainLayout}>
      <Box className={classes.content}>
        <img src={defaultQuestion} className={classes.image} />
        <Typography className={classes.text}>{renderText()}</Typography>
        {!notShowQuestion &&(
          <Button
            data-test-id="start-question"
            className={classes.button}
            variant="outlined"
            onClick={() => setShowQuestion(!showQuestion)}
          >
            Start Question
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default InitQuestion;
