import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { Roles } from "../../../components/src/types";
// Customizable Area End

import CfQuestionBank2Controller, {
  Props,
  configJSON,
} from "./CfQuestionBank2Controller";
import AddMatchingQ from "./Teacher/components/AddMatchingQ.web";

export default class CfQuestionBank2 extends CfQuestionBank2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER) {
      return (
        <AddMatchingQ
          pairs={this.props.pairs}
          setPairs={this.props.setPairs}
          pairCount={this.props.pairCount}
          setPairCount={this.props.setPairCount}
          matchSettingState={this.props.matchSettingState}
          setMatchSettingState={this.props.setMatchSettingState}
          preview={this.props.preview}
          setPreview = {this.props.setPreview} 
          updateTime = {this.props.updateTime}
          setmatchquestionState = {this.props.setmatchquestionState}
          matchquestionState = {this.props.matchquestionState}
          handleMatchChange={this.props.handleMatchChange}
          matchSetState={this.props.matchSetState}
          openEndedState={this.props.openEndedState}
          handleOpenEndedChange={this.props.handleOpenEndedChange}
          setBackgroundImage={this.props.setBackgroundImage}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.renderViewBasedOnRole(this.state.role)}
       </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
