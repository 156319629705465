import React, { SetStateAction, useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "../../../../components/src/CheckBox.web";
import { Box, Typography, IconButton, Menu, MenuItem, Button, ListItemText, Modal } from '@material-ui/core';
import { gSlides, gArchive, gSheets, folderSvg, gVideo, gImage, gNotes, gMusic, gPpt, gJson, gDriveIcon } from "../assets";
import { deleteOption, deleteOptionActive, previewOption, previewOptionActive } from "../../../catalogue/src/assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { trashIcon } from "../../../../components/src/assets";

type DateInput = string | number | Date;


const formatDate = (dateString: DateInput) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

const formatDate2 = (dateString: DateInput) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12).padStart(2, '0'); 

  return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
};


const convertBytesToSize = (bytes: string): string => {
  const size = parseInt(bytes, 10);
  if (size < 1024) {
    return `${size} Bytes`;
  } else if (size < 1024 * 1024) {
    return `${Math.floor(size / 1024)} KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

const StyledTableItem = withStyles((theme) => ({
  root: {
      "&:hover": {
          backgroundImage:
              "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
          "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
              color: theme.palette.common.white,
              fontFamily: "Poppins",
              fontSize: "16px",
          },
      },
  },
}))(MenuItem);
const StyledTable = withStyles({
  paper: {
      border: "1px solid #d3d4d5",
      borderRadius: "6px",
  },
})((props: any) => (
  <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "center",
      }}
      {...props}
  />
));

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: '100%' 
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    height: "50vh",
    tableLayout: 'fixed' 
  },
  div: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  fileName: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  button: {
    fontSize: '16px',
    color: '#2B3D7D',
    fontWeight: 500,
    backgroundColor: '#fff',
    borderColor: '#2B3D7D',
    borderRadius: '8px',
    marginBottom: '0px',
    padding: '6px 20px',
    cursor: 'pointer',
    fontFamily: 'Poppins',
  },
  img: {
    marginRight: '15px',
    height: '30px',
    width: '30px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  code_img: {
    height: "15px", width: "15px", marginLeft: "25px", cursor: 'pointer',
  },
  googDriveText: {
    display: "flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  upperHeader: {
    fontFamily:"Poppins",
    color:"gray",
    fontWeight:600,
    textTransform:"none"
  },
  deleteBtn: {
    backgroundColor: "#FE575A",
    color: "#FFF",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#FE575A",
      color: "#FFF",
    },
  },
  cancelBtn: {
    border: "1px solid #FE575A",
    color: "#3F526D",
    borderRadius: "10px",
    width: "270px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "space-around",
    gap:"10px"
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "17px",
    color: "#000000",
    marginBottom: "10px",
    marginTop: "25px",
  },
  paper: {
    position: "absolute",
    width: 520,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: theme.spacing(4, 4, 0),
  },
}));

type MimeTypeIcons = {
  [key: string]: string;
};

interface FileListDriveProps {
  files: any;
  onCheckBoxClick: (isChecked: boolean, allFilesAreJson: boolean) => void;
  importPressed: boolean | undefined;
  getFilesData: (fileId: string, fileName: string) => void;
  goToDrive: () => void;
  setImportPressed: ((value: SetStateAction<boolean>) => void) | undefined;
  revertColumns: boolean|undefined;
  isCheckBoxNeeded: boolean | undefined;
  headerLabel: string | undefined;
  advanceOptions: boolean | undefined;
  handleView: Function;
  handleDelete: Function;
}

const getFileIconStyle = (mimeType: string) => {
  return mimeType === "application/vnd.google-apps.folder" || mimeType === "application/vnd.google-apps.presentation"
    ? { height: "32px", width: "32px", marginRight: "7px", marginLeft: "-3px" }
    : { height: "24px", width: "24px", marginRight: "10px" };
};

const getFOlderStyles = (mimeType:string) => {
  return mimeType === "application/vnd.google-apps.folder" ? { fontSize: "24px" } : { width: "100%" }
}

export default function FileListDrive(props: FileListDriveProps) {
  const [checkedFiles, setCheckedFiles] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [showConfirmationModal,setShowConfirmationmodal] = useState<boolean>(false)
  const viewImageRef = React.useRef<any>();
  const deleteImageRef = React.useRef<any>();
  const [modalStyle] = React.useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const confirmDelete = () => {
    if (selectedFileId) {
      props.handleDelete(selectedFileId)
    }    
    handleClose();
    setShowConfirmationmodal(false)
  };

  const cancelDelete = () => {
   setShowConfirmationmodal(false)
   setSelectedFileId(null);
   handleClose();
  };


  const handleClick = (event: React.MouseEvent<HTMLElement>, file: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedFileId(file);
  };
  

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFileId(null);

  };

  const handleView = () => {
    if (selectedFileId) {
      props.handleView(selectedFileId)
    }    
    handleClose();
  };

  const handleDelete = () => {    
   setShowConfirmationmodal(true)
  };
  
  useEffect(() => {
    if (props.importPressed && checkedFiles.length > 0) {
      const fileId = checkedFiles[0];
      const file = props.files.find((file: any) => file.id === fileId);
      
      if (file) {
        const fileName = file.name;
        props.getFilesData(fileId, fileName);
      }
    }
      
    if(props.setImportPressed){
      props.setImportPressed(false);
    }
  }, [props.importPressed, checkedFiles]);
  
  const handleCheckboxChange = (id: string) => {
    const isChecked = checkedFiles.includes(id);
    let updatedCheckedFiles: string[];
  
    if (isChecked) {
      updatedCheckedFiles = [];
    } else {
      updatedCheckedFiles = [id];
    }
  
    setCheckedFiles(updatedCheckedFiles);
    
    const allFilesAreJson = updatedCheckedFiles.every(fileId => {
      const file = props.files.find((file: any) => file.id === fileId);
      return file.mimeType === 'application/json';
    });
  
    props.onCheckBoxClick(updatedCheckedFiles.length > 0, allFilesAreJson);
  };
  
  const mimeTypeIcons: MimeTypeIcons = {
    "video/mp4": gVideo,
    "application/vnd.google-apps.folder": folderSvg,
    "image/png": gImage,
    "application/vnd.google-apps.spreadsheet": gSheets,
    "application/vnd.google-apps.document": gNotes,
    "application/zip": gArchive,
    "application/vnd.android.package-archive": gArchive,
    "audio/mpeg": gMusic,
    "application/vnd.google-apps.presentation": gSlides,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": gPpt,
    "application/json": gJson
  };

  const getIconByMimeType = (mimeType: string) => mimeTypeIcons[mimeType] || "https://drive-thirdparty.googleusercontent.com/16/type/application/octet-stream";

  const { files } = props;
  const classes = useStyles();

  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">
          <div className={classes.div}>
            File Name
          </div>
        </StyledTableCell>
        {props.revertColumns ? (
          <>
            <StyledTableCell align="center">File Size</StyledTableCell>
            <StyledTableCell align="center">  {props.headerLabel || "Created On"}</StyledTableCell>
          </>
        ) : (
          <>
            <StyledTableCell align="center">  {props.headerLabel || "Created On"}</StyledTableCell>
            <StyledTableCell align="center">File Size</StyledTableCell>
          </>
        )}
      </TableRow>
    );
  };

  const tableBody = () => {
    return files.map((file: { id: React.Key | undefined; mimeType: string; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; modifiedTime: string | number | Date; size: string; }) => (
      <StyledTableRow key={file.id}>
        <StyledTableCell align="left">
          <div className={classes.div}>
            {props.isCheckBoxNeeded && <Checkbox
              data-test-id="checkbox"  
              id={file.id}
              isChecked={checkedFiles.includes(file.id)} 
              onChange={(id: string) => handleCheckboxChange(id)} 
              style={{ marginRight: "20px" }}
            />}
            <img 
              src={getIconByMimeType(file.mimeType)} 
              alt="" 
              style={getFileIconStyle(file.mimeType)}

            />
            <div className={classes.fileName}>
              {file.name}
            </div>
          </div>
        </StyledTableCell>
        {props.revertColumns ? (
          <>
            <StyledTableCell align="center">
              <Typography id="codeInput" style={getFOlderStyles(file.mimeType)}>
                {file.mimeType === "application/vnd.google-apps.folder" ? "-" : convertBytesToSize(file.size)}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Box style={{display:"flex"}}>
                <Typography id="codeInput" style={{ width: "60%", marginLeft:"78px",fontSize:"0.9rem" }}>{formatDate2(file.modifiedTime)}</Typography>
                {props.advanceOptions && (
        <>
          <IconButton  onClick={(event) => handleClick(event, file)} style={{ padding: '0' }}>
          <MoreVertIcon style={{ cursor: 'pointer' }} />
          </IconButton>
          <StyledTable
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledTableItem onClick={handleView}
            onMouseEnter={(e) =>
                      viewImageRef.current && (viewImageRef.current.src = previewOptionActive)
                    }
                    onMouseLeave={(e) => viewImageRef.current && (viewImageRef.current.src = previewOption)}
                >
                    <img
                        src={previewOption}
                        ref={viewImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="View" color="#48484A" />
            </StyledTableItem>
            <StyledTableItem
                    data-test-id="open-delete-modal"
                    onMouseEnter={(e) =>
                        (deleteImageRef.current.src = deleteOptionActive)
                    }
                    onMouseLeave={(e) => (deleteImageRef.current.src = deleteOption)}
                    onClick={handleDelete}
                >
                    <img
                        src={deleteOption}
                        ref={deleteImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Delete" color="#48484A" />
                </StyledTableItem>
          </StyledTable>
        </>
      )}
              </Box>
            </StyledTableCell>
          </>
        ) : (
          <>
            <StyledTableCell align="center">
              <Box>
                <Typography id="codeInput" style={{ width: "100%" }}>{formatDate(file.modifiedTime)}</Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Typography id="codeInput" style={file.mimeType === "application/vnd.google-apps.folder" ? { fontSize: "24px" } : { width: "100%" }}>
                {file.mimeType === "application/vnd.google-apps.folder" ? "-" : convertBytesToSize(file.size)}
              </Typography>
            </StyledTableCell>
          </>
        )}
      </StyledTableRow>
    ));
  };

  return (
    <>
      <Box py={"10px"} className={classes.googDriveText}>
        <Typography className={classes.upperHeader}>Google Drive</Typography>
        <Button onClick={() => props.goToDrive()}>
          <img
            src={gDriveIcon}
            height={30}
            width={30}
            style={{ marginRight: "5px" }}
          />
          <Typography className={classes.upperHeader}>
            Open Folder in Google Drive
          </Typography>
        </Button>
      </Box>
      <TableContainer style={{ height: "56vh" }} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>{tableHead()}</TableHead>
          <TableBody>{tableBody()}</TableBody>
        </Table>
      </TableContainer>

      <Modal open={showConfirmationModal}>
      <Box style={modalStyle} className={classes.paper}>

           <Box className={classes.popupContent}>
            <img src={trashIcon} />
            <Typography className={classes.heading}>
            Are you sure you want to delete this file ?
            </Typography>
        </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              minWidth: "150px",
              minHeight: "60px",
            }}
          >
            <Box className={classes.btnLayout}>
              <Button
                data-testid="cancelBtn"
                className={classes.cancelBtn}
                onClick={cancelDelete}
              >
                Cancel
              </Button>
              <Button
                data-test-id="deleteModalBtn"
                className={classes.deleteBtn}
                onClick={() => {
                  confirmDelete();
                }}
              >
                Delete
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}
