import React from "react";
import { Box, Modal, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon, multimediaIcon } from "../../assets";
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(() => ({
    modalStyles: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
        overflow: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "480px",
        background: "#fff",
        border: "none",
        boxShadow: "24",
        borderRadius: "15px",
        padding: "24px",
        overflowX: "hidden",
    },
    closeIcon: {
        position: "absolute",
        right: "20px",
        top: "20px",
        cursor: "pointer"
    },
    modalHeadText: {
        fontFamily: 'Poppins',
        fontSize: "22px",
        fontWeight: 700,
        color: "#2B3D7D",
        textAlign: "center",
    },
    dragIcon: {
        width: "60px",
    },
    dragUploadBox: {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        border: "2px dashed #e5e5e5",
        padding: "32px",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "400px",
        borderRadius: "15px",
        cursor: "pointer"
    },
    dragDropheading: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#000",
        fontWeight: 600,
        lineHeight: '30px',
        textAlign: "center",
    },
    dragDropSubheading: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#3F526D",
        fontWeight: 400,
        lineHeight: '30px',
        textAlign: "center",
    },
    browseLink: {
        textDecoration: "underline"
    },
}));

const AddBackgroundImageModal = (props: any) => {
    const { open, onClose, setBackgroundImage, setPreview} = props;
    const classes = useStyles();

    const onDrop = React.useCallback((acceptedFiles: any) => {
        const file = new FileReader();
        file.onload = function() {
          setBackgroundImage(acceptedFiles[0]);
          setPreview(file.result);
          onClose()
        };
        file.readAsDataURL(acceptedFiles[0]);
      }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept:{
          "accept": [".png",".jpg",".jpeg"]
        },
        multiple:false
    });

    return (
        <Modal open={open} onClose={onClose}>
            <Box className={classes.modalStyles}>
                <img className={classes.closeIcon} src={closeIcon} onClick={onClose} />
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.modalHeadText}>
                            Add Background Images
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box {...getRootProps()} className={classes.dragUploadBox}>
                            <input {...getInputProps()} style={{ display: "none" }} />
                            <img src={multimediaIcon} className={classes.dragIcon} />
                            <Typography className={classes.dragDropheading}>
                                Drag & Drop file or <span className={classes.browseLink}>Browse</span>
                            </Typography>
                            <Typography className={classes.dragDropSubheading}>
                                Supported formats: JPEG, JPG, PNG
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default AddBackgroundImageModal;
