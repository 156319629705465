import React from 'react';
import {Snackbar,IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {closeNotificationIcon, discardIcon,successIcon} from "./assets"

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: '#4AA155',
    color: '#fff',
  },
  error: {
    backgroundColor: '#C74244',
    color: '#fff',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontFamily:"Poppins"
  },
  icon: {
    marginRight: theme.spacing(1),
    color: '#fff',
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

interface SuccessBarData{
  message:string;
  type:string;
}
interface Props{
  open:boolean,
  onClose:()=>void;
  successBarData:SuccessBarData;
}
interface SuccessSnackbarProps {
  message: string;
  open: boolean;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  type:string
}

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({ message, open, onClose,type }) => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: type==='error' ? classes.error : classes.success,
      }}
      message={
        <span id="message-id" className={classes.message}>
            <img src={type==='error'?discardIcon:successIcon} className={classes.icon}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <img src={closeNotificationIcon}/>
        </IconButton>,
      ]}
      data-testId="SnackBar"
    />
  );
};

const CustomSuccessPopup = (props:Props) => {
    const {open,onClose,successBarData}=props;

  return (
    <div>
      <SuccessSnackbar 
        message={successBarData.message} 
        open={open} 
        onClose={onClose} 
        type={successBarData.type}
      />
    </div>
  );
};

export default CustomSuccessPopup;
