import React, { useEffect } from "react";
import { Menu, MenuItem, ListItemText, Modal, useMediaQuery } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  deleteOption,
  previewOption,
  downloadOption,
  previewOptionActive,
  downloadOptionActive,
  deleteOptionActive,
  annotateOptionActive,
  annotateOptionInactive,
  viewNotesIconInactive
} from "../../../assets";
import NotForMobile from "../../../../../../components/src/NotForMobile.web";
import Notes from "../../../../../Notes/src/Notes.web";
import DeleteConfirmationModal from "../../../Teachers/components/TeacherSelectedPractice/DeleteConfirmationModal.web";
const StyledStudentMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);

interface NoteItem {
  id: number; 
}

const StyledStudentMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StudentCustomContextMenu = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    handleCloseContextMenu,
    handleCloseNoMobileModal,
    openNoMobileModal,
    selectedLessons,
    contextMenuData,
    downloadLesson,
    openAddNotesModal,
    handleCloseAddNotesModal,
    handleShowNotesIcon,
    handleOpenAddNotesModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteStudentLibrary,
    noteData,
    openDeleteConfirmationModal,
    handleOpenNoMobileModal
  } = props;
  const previewImageRef = React.useRef<any>();
  const deleteImageRef = React.useRef<any>();
  const downloadImageRef = React.useRef<any>();
  const notesImageRef = React.useRef<any>();
  const annotateImageRef = React.useRef<any>();
  const [labelName, setLabelName] = React.useState<string>('')
  const [showIcon, setShowicon] = React.useState<boolean>(false)
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const findId = noteData.find((objectMenu: any) => objectMenu === Number(contextMenuData.id))
    if(findId===undefined){
      setShowicon(true)
    }else {
      setShowicon(false)
    }
}, [contextMenuData])

  return (<>

    <StyledStudentMenu
      id="customized-menu"
      data-testid="customized-menu"
      anchorEl={anchorEl}
      open={openContextMenu}
      onClose={handleCloseContextMenu}
    >
      <StyledStudentMenuItem
        data-test-id="view-option"
        onMouseEnter={(e) =>
          previewImageRef.current && (previewImageRef.current.src = previewOptionActive)
        }
        onMouseLeave={(e) => previewImageRef.current && (previewImageRef.current.src = previewOption)}
        onClick={() => isSmallScreen ? (setLabelName("View"), handleOpenNoMobileModal()) : selectedLessons(contextMenuData.id,false,contextMenuData.itemType)}

     >
        <img
          src={previewOption}
          ref={previewImageRef}
          style={{ marginRight: "10px" }}
        />
        <ListItemText primary="View" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem
        data-test-id="annotate-option"
        onMouseEnter={(e) =>
          annotateImageRef.current && (annotateImageRef.current.src = annotateOptionActive)
        }
        onMouseLeave={(e) => annotateImageRef.current && (annotateImageRef.current.src = annotateOptionInactive)}
        onClick={() => isSmallScreen ? (setLabelName("Annotate"), handleOpenNoMobileModal()) : selectedLessons(contextMenuData.id,true,contextMenuData.itemType)}

     >
        <img
          src={annotateOptionInactive}
          ref={annotateImageRef}
          style={{ marginRight: "10px" }}
        />
        <ListItemText primary="Annotate" color="#48484A" />
      </StyledStudentMenuItem>
       
      {showIcon && (<StyledStudentMenuItem
        data-test-id="notes-option"
        onMouseEnter={(e) => notesImageRef.current && (notesImageRef.current.src = viewNotesIconInactive)}
        onMouseLeave={(e) => notesImageRef.current && (notesImageRef.current.src = viewNotesIconInactive)}
        onClick={(event) => {
          handleOpenAddNotesModal("Add", contextMenuData.id, contextMenuData.name)
          handleCloseContextMenu(event);
      }}
      >
        <img
          src={viewNotesIconInactive}
          ref={notesImageRef}
          style={{ marginRight: "10px", width: "16px", height: "16px"  }}
        />
        <ListItemText primary="Add Notes" color="#48484A" />
      </StyledStudentMenuItem>)}
      <StyledStudentMenuItem
        data-test-id="download-option"
        onMouseEnter={(e) =>
          downloadImageRef.current && (downloadImageRef.current.src = downloadOptionActive)
        }
        onMouseLeave={(e) => downloadImageRef.current && (downloadImageRef.current.src = downloadOption)}
        onClick={()=>{downloadLesson(contextMenuData.itemType,contextMenuData.id)}}
      >
        <img
          src={downloadOption}
          ref={downloadImageRef}
          style={{ marginRight: "10px" }}
        />
        <ListItemText primary="Download" color="#48484A" />
      </StyledStudentMenuItem>
      <StyledStudentMenuItem
        data-test-id="delete-option"
        onMouseEnter={(e) =>
          deleteImageRef.current && (deleteImageRef.current.src = deleteOptionActive)
        }
        onMouseLeave={(e) => deleteImageRef.current && (deleteImageRef.current.src = deleteOption)}
        onClick={(event) => {
          handleOpenDeleteModal();
          handleCloseContextMenu(event);
        }}
      >
        <img
          src={deleteOption}
          ref={deleteImageRef}
          style={{ marginRight: "10px" }}
        />
        <ListItemText primary="Delete" color="#48484A" />
      </StyledStudentMenuItem>
    </StyledStudentMenu>
    <Modal
      data-testid="delete-modal"
      open={openDeleteConfirmationModal}
      onClose={handleCloseDeleteModal}
    >
      <DeleteConfirmationModal
        handleCloseModal={handleCloseDeleteModal}
        contextMenuData={contextMenuData}
        deleteStudentLibrary={deleteStudentLibrary}
        />
    </Modal>
    <Notes
      id=""
      navigation={undefined}
      handleCloseModal={handleCloseAddNotesModal}
      open={openAddNotesModal}
      handleShowNotesIcon={handleShowNotesIcon}
    />
    <Modal
             onClose={handleCloseNoMobileModal}
             open={openNoMobileModal}
             data-testid="nomobile-modal"
            >
             <NotForMobile
              handleCloseModal={handleCloseNoMobileModal}
             moduleName={labelName}
            />
            </Modal>
  </>
  );
};

export default StudentCustomContextMenu;
