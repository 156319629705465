import React from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon,addMatchingQModal } from "../../../../CfQuestionBank2/src/assets";
export const configJSON = require("../../config");
const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";

const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px"
  },
  modalStyles: {
    width: "475px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    border: "none",
    overflowX: "hidden",
    boxShadow: "24",
    borderRadius: "15px",
    padding: "24px",
  },
  heading: {
    fontWeight: 850,
    fontFamily: "Poppins",
    fontSize: "22px",
    width:"360px",
    color: "rgb(43, 61, 125)",
    lineHeight: '30px',
    marginBottom: "8px",
    marginTop:"14px",
    textAlign: "center"
  },
  modalImage : {
    width:"210px",
  },
  modalBtn: {
    color: "#fff",
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    fontWeight: 400,
    width: "350px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "16px",
    cursor: "pointer"
  },
  title:{
    textAlign:"center",
    fontWeight:550,
    fontFamily: "Poppins",
  }
}));

const AddMatchingQModal = (props: any) => {
  const { open, onClose, lessonName} = props;
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalStyles}>
        <img src={addMatchingQModal} className={classes.modalImage}/>
        <img data-test-id="close-icon" className={classes.closeIcon} src={closeIcon} onClick={onClose} />
        <Typography className={classes.heading}>
         {configJSON.MatchingQuestionText.title}
        </Typography>
        <Typography className={classes.title}>
            {configJSON.MatchingQuestionText.content} {lessonName}
        </Typography>
      </Box>
    </Modal>
  );
};

export default AddMatchingQModal;