import React from "react";
import { Box, Typography, Button,Modal,Switch  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeModalIcon } from "./assets";
import { ACTIONS,QuestionHeaders } from "./types";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "440",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"77px 20px",
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    },
    "& .cross-icon":{
      cursor:"pointer"
    },
    "&.score-board-modal":{
      width: "492",
      padding:"50px 24px",
    }
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "60px",
    color: "#2B3D7D",
    fontWeight: 700,
    lineHeight:"1.25",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"15px",
  },
  supText:{
    color: "#282829",
    fontSize:"16px",
    fontWeight:500,
    lineHeight:"1",
    "&.absText":{
      position: "absolute", top: "15px", left: "24px"
    },
  },
  text:{
    fontSize:"14px",
    color:"#2C2C2E",
    fontWeight:400,
    marginBottom:"8px",
  },
  questionType:{
    fontSize:"16px",
    color:"#2C2C2E",
    fontWeight:400,
    marginBottom:"8px",
    textAlign:"left",
  },
  question:{
    fontSize:"16px",
    color:"#2C2C2E",
    fontWeight:600,
    textAlign:"left", 
  },
  mediumText:{
    color:"#282829",
    fontSize:"24px",
    fontWeight:600,
    lineHeight:"1.25",
  },
  questionBoxWrapper:{
    padding:"15px",
    backgroundColor:"#eff0f5",
    borderRadius:"10px",
    width: "100%", boxSizing: "border-box",
    marginBottom:"30px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom:"0",
    "& .image-wrapper":{
      marginBottom:"15px",
    }
  },
  btnLayout: {
    display: "flex",
    justifyContent: "center",
    "& button + button":{
      marginLeft:"16px",
    }
  },
  siteBtn:{
    height: "52px",
    lineHeight: "52px",
    border: "1px solid #2B3D7D",
    color: "#2B3D7D",
    minWidth: "52px",
    width: "auto",
    padding: "0 15px",
    fontSize: "16px",
    fontWeight: 500,
    transition:"all 0.5s",
    textTransform:"unset",
    "&:hover":{
      backgroundColor:"#1e2b58",
      color:"#FFF"
    },
    "&.fill-btn":{
      color:"#FFF",
      backgroundColor:"#2B3D7D",
      "&:hover":{
        backgroundColor:"#1e2b58",
      },
    }
  },

  saveHeading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight:"1.6",
    textAlign:"center",
    padding:"0 15px",
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const QuizModal = (props: any) => {
  const { stage,open, handleClose,isFinalScore,name,scoreData } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const stageType = stage?.attributes.quiz_stage_name as ACTIONS

  const getQuestionType=()=> {
    switch(stageType?? ""){
      case ACTIONS.MCQ: return QuestionHeaders.MCQ;
      case ACTIONS.OPEN_ENDED_QUESTION: return QuestionHeaders.OPEN_ENDED_QUESTION
      case ACTIONS.ADD_MATCHING_Q: return QuestionHeaders.ADD_MATCHING_Q
      case ACTIONS.POLLING_Q: return QuestionHeaders.POLLING_Q
      default : return ""
    }
  }

  const getQuestion=()=> {
    
    switch(stageType?? ""){
      case ACTIONS.MCQ: return stage.attributes?.questions[0]?.question?.question;
      case ACTIONS.OPEN_ENDED_QUESTION: 
      case ACTIONS.ADD_MATCHING_Q: return stage?.attributes.title
      case ACTIONS.POLLING_Q: return QuestionHeaders.POLLING_Q
      default : return ""
    }
  }

  
  return (
    <Modal
        open={open}
    >
      <Box style={modalStyle} className={`${classes.paper} ${!isFinalScore && "score-board-modal"}`}>
        <a data-test-id="closeQuizModal" onClick={handleClose} title="close modal" className="close-modal"><img src={closeModalIcon} alt="Modal close icon" className="cross-icon"/></a>
        <Box className={classes.popupContent}>
          {isFinalScore?
          <>
            <Typography className={classes.supText}>
            Your Score
            </Typography>
            <Typography className={classes.heading}>
            {scoreData.total_score}
            </Typography>
            <Typography className={classes.text}>
            You have successfully completed the quiz
            </Typography>
            <Typography className={classes.mediumText}>
            {name}
            </Typography>     
          </>
          :
          <>
            <Typography className={`${classes.supText} absText`}>
              Scoreboard
            </Typography>
            <Box className={classes.questionBoxWrapper}>
              <Typography className={classes.questionType}>
                {getQuestionType()}
              </Typography>
              <Typography className={classes.question} dangerouslySetInnerHTML={{__html: getQuestion()}}>
              </Typography>
            </Box>
            <Typography className={classes.supText}>
                Your points
            </Typography>
            <Typography className={classes.heading}>
              {scoreData.current_stage_score}
            </Typography>
         </>
          }
       
        </Box>
       
      </Box>
    </Modal>
  );
};

export default QuizModal;
