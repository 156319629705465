import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { venn } from "../../../../assets";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";

const useStyles = makeStyles(() => ({
  vennTitle: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "180px",
    whiteSpace: "pre-wrap",
    height: "740px",
  },
  placeholder: {
    "&:empty:before": {
      content: "attr(placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 400,
    },
    color: "#9FA1A4",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
  },
  instructionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "5px",
  },
  
  instructionDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "15px",
  },
  vennBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "100px",
  },
}));

const VennDiagramPreview = (props: any) => {
  const { currentStage } = props
  const classes = useStyles();
  const hasCanvasData = currentStage.attributes.canvas_data;
  let jsonCamvas =  undefined
  let canvasSize =  undefined
  if(hasCanvasData)
  {
    const parsedData = typeof hasCanvasData == "object"?hasCanvasData:JSON.parse(hasCanvasData); 
    jsonCamvas = parsedData.canvas;
    canvasSize = parsedData.canvasSize 
  }
  return (
    <div  id="content" >
      <Box className={classes.titleBox}>
        <Typography className={classes.vennTitle} dangerouslySetInnerHTML={{
            __html: currentStage.title,
           }}/>
      </Box>
      {jsonCamvas && <Box style={{position:"absolute",width:"90%"}}>
          <StaticCanvas canvasData={jsonCamvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
          </Box>}
      <div className={classes.stageTitle}>
        <Box className={classes.instructionDiv} sx={{ px: "25px" }}>
          <Typography className={classes.instructionTitle}>
            Instruction:
          </Typography>
          <Box
            className={classes.placeholder}
            dangerouslySetInnerHTML={{
              __html: currentStage.attributes.question?currentStage.attributes.question:"Enter instructions or question here",
             }}
          />
        </Box>
        <Box sx={{ px: "25px" }}>
        <Box className={classes.vennBox}>
        <img src={venn} />
      </Box>
        </Box>
      </div>

    </div>
  );
};

export default VennDiagramPreview;
