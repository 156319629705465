import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Roles } from "../../../components/src/types";
import TeacherCourseCreation from "./Teacher/TeacherCourseCreation.web"
import SaveLessonModal from "../../../components/src/SaveLessonModal.web";
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CfCourseCreation23Controller, {
  Props,
  configJSON,
} from "./CfCourseCreation23Controller";

export default class CfCourseCreation23 extends CfCourseCreation23Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.redirectToDashboard = this.redirectToDashboard.bind(this)
    this.addTextStage = this.addTextStage.bind(this)
    this.addMultiMediaStage = this.addMultiMediaStage.bind(this)
    this.addChartStage = this.addChartStage.bind(this)
    this.updateMultiMediaStage = this.updateMultiMediaStage.bind(this)
    this.addWordCloudStage = this.addWordCloudStage.bind(this)
    this.addMcqStage= this.addMcqStage.bind(this)
    this.addPollStage= this.addPollStage.bind(this)
    this.addOpenEndedStage = this.addOpenEndedStage.bind(this)
    this.addMatchingQStage = this.addMatchingQStage.bind(this)
    this.getStages = this.getStages.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER) {
      return (
        <TeacherCourseCreation 
        data-test-Id= "courseCreationId"
        selectedView={this.state.selectedView}
        redirectToDashboard={this.redirectToDashboard}
        stages={this.state.stages}
        addTextStage={this.addTextStage}
        addMultiMediaStage={this.addMultiMediaStage}
        addChartStage={this.addChartStage}
        lessonName={this.state.lessonName}
        updateMultiMediaStage={this.updateMultiMediaStage}
        addWordCloudStage={this.addWordCloudStage}
        addMcqStage={this.addMcqStage}
        addPollStage={this.addPollStage}
        addOpenEndedStage={this.addOpenEndedStage}
        addMatchingQStage={this.addMatchingQStage}
        loading={this.state.loading}
        deleteStageCallBack={this.deleteStage}
        reorderStage={this.reorderStage}
        buttonType={this.state.buttonType}
        saveModalOpen={this.saveModalOpen}
        getStages={this.getStages}
        navigation={this.props.navigation}
        handleActiveSiled={this.handleActiveSiled}
        isActive={this.state.isActive}
        handleOpenEndedChange={this.handleOpenEndedChange}
        openEndedState={this.state.openEndedState}
        handleOpenEndedClick={this.handleOpenEndedClick}
        isDoublePointActve={this.state.isDoublePointActve}
        handleDoublePointActive={this.handleDoublePointActive}
        handleMcqClick={this.handleMcqClick}
        handleMcqChange={this.handleMcqChange}
        mcqSettingState={this.state.mcqSettingState}
        handleMatchChange={this.handleMatchChange}
        handleMatchClick={this.handleMatchClick}
        handlePollClick={this.handlePollClick}
        handlePollChange={this.handlePollChange}
        matchSetState={this.state.matchSetState}
        pollSettingState={this.state.pollSettingState}
        updateQuizMusic={this.updateQuizMusic}
        isMusic={this.state.isMusic}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <SuccessSnackbar
        message={this.state.isLimitError?'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.':'Something went wrong. Please try again using a smaller file'} 
        open={this.state.isLimitModalOpen} 
        onClose={this.handleCloseLimitSnackbar} 
        type={'error'}
      />
      {this.renderViewBasedOnRole(this.state.role)} 
      <SaveLessonModal open={this.state.saveModalOpen} saveModalClose={this.saveModalClose} saveLessonInLib={this.saveLesson} showSuccess={this.state.showSaveSuccess} type={this.state.selectedView}/>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
