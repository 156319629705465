import React from "react";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { Box, Grid, Typography,useMediaQuery } from "@material-ui/core";
import SearchSection from "../../../../../../components/src/SearchSection.web";
import DataTable from "../../DataTable.web";
import Loader from "../../../../../../components/src/Loader.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  breadCrumb:{
    paddingLeft: "35px",
    marginTop: "30px"
  },
  tableLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px",
    marginTop: "10px"
   },
   searchLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px"
   }
});
const StudentSelectedPractice = (props: any) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    locations,
    currentLocation,
    tableSearchInput,
    handleSearchInputTable,
    redirect,
    practiceData,
    loading,
    handlePracticePageChange,
    pageLimitPractice,
    pageNoPractice,
    handleCloseNoMobileModal,
    openNoMobileModal,
    selectedLessons,
    editPractice,
    handleOpenAddFolderModal,
    downloadLesson,
    notesList,
    handleOpenDeleteModal,
    handleShowNotesIcon,
    handleCloseDeleteModal,
    deleteStudentLibrary,
    openDeleteConfirmationModal,
    handleOpenNoMobileModal
  } = props;
  return (
    <>
      <Box className={classes.breadCrumb}>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
      </Box>
      <Grid container={isSmallScreen ? false : true}>
        <Grid item md={12}>
          <Box className={classes.searchLayout}>
            <SearchSection
              searchInput={tableSearchInput}
              handleSearchInput={handleSearchInputTable}
              placeholder={"Search by name"}
            />
          </Box>
        </Grid>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            {!practiceData.data.length ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Grid item md={12}>
                  <Box className={classes.tableLayout}>
                    <DataTable
                      practiceData={practiceData}
                      handleOpenNoMobileModal={handleOpenNoMobileModal}
                      handleCloseNoMobileModal={handleCloseNoMobileModal}
                      openNoMobileModal={openNoMobileModal}
                      selectedLessons={selectedLessons}
                      editPractice={editPractice}
                      handleOpenAddFolderModal={handleOpenAddFolderModal}
                      downloadLesson={downloadLesson}
                      notesList={notesList}
                      handleShowNotesIcon={handleShowNotesIcon}
                      handleOpenDeleteModal = {handleOpenDeleteModal}
                      handleCloseDeleteModal={handleCloseDeleteModal}
                      deleteStudentLibrary={deleteStudentLibrary}
                      openDeleteConfirmationModal={openDeleteConfirmationModal}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.breadCrumb}>
                    <BasicPagination
                      handlePageChange={handlePracticePageChange}
                      pageLimit={pageLimitPractice}
                      pageNo={pageNoPractice}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default StudentSelectedPractice;
