import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import { Box, Grid, Typography,useMediaQuery } from "@material-ui/core";
import SearchSection from "../../../../../components/src/SearchSection.web";
import DataTable from "../DataTable.web";
import Loader from "../../../../../components/src/Loader.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  breadCrumb:{
    paddingLeft: "35px",
    marginTop: "30px"
  },
  tableLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px",
    marginTop: "10px"
   },
   searchLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px"
   }
});
const StudentLessonList = (props: any) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    loading,
    locations,
    destination,
    studentLessonData,
    tableSearchInput,
    pageNoPractice,
    pageLimitPractice,
    redirect,
    handleSearchInputTable,
    handlePracticePageChange,
  } = props;
  const [practiceData, setPracticeData] = useState([]);

  useEffect(() => {
    let isLessonLive = false;
    const listData = studentLessonData.map((data: any) => {
      if(data.live && !isLessonLive) {
        isLessonLive = true;
        data.isLessonLive = true;
      } else {
        data.isLessonLive = false;
      }
      return data;
    });
    setPracticeData(listData);
  }, [studentLessonData]);

  return (
    <>
      <Box className={classes.breadCrumb}>
        <CustomBreadcrumb
          locations={locations}
          destination={destination}
          redirect={redirect}
        />
      </Box>
      <Grid container={isSmallScreen ? false : true}>
        <Grid item md={12}>
          <Box className={classes.searchLayout}>
            <SearchSection
              searchInput={tableSearchInput}
              handleSearchInput={handleSearchInputTable}
              placeholder={"Search by name"}
            />
          </Box>
        </Grid>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            {!practiceData?.length ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Grid item md={12}>
                  <Box className={classes.tableLayout}>
                    <DataTable practiceData={practiceData} />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.breadCrumb}>
                    <BasicPagination
                      handlePageChange={handlePracticePageChange}
                      pageLimit={pageLimitPractice}
                      pageNo={pageNoPractice}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default StudentLessonList;
