import React from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
export const configJSON = require("../../config");
import {
  tChart,
  whChart,
  kwlChart,
  seedDiscussion,
  vennDiagram,
  info,
} from "../../assets";
import { ACTIONS, COURSES_PAGES } from "../../../../../components/src/types";
const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  paperGraphicModal: {
    position: "absolute",
    width: "767px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    padding: "20px 30px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "60px",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
  },

  containerGraphicModal: {
    position: "relative",
    width: "100%",
  },
  optionItemGraphicModal: {
    width: "calc(33.33% - 20px)",
    boxSizing: "border-box",
    padding: "10px",
    textAlign: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    cursor: "pointer",
    marginBottom: "30px",
    marginRight: "20px",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
  },
  optionName: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "10px",
  },
  optionBoxGraphicModal: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0px 25px",
  },
}));
const options = [
  { name: "KWL Chart", image: kwlChart, actionName: ACTIONS.KWL_CHART },
  { name: "T-Chart", image: tChart, actionName: ACTIONS.T_CHART },
  {
    name: "Venn Diagram",
    image: vennDiagram,
    actionName: ACTIONS.VENN_DIAGRAM,
  },
  {
    name: "Seed Discussion",
    image: seedDiscussion,
    actionName: ACTIONS.SEED_DISCUSSION,
  },
  { name: "WH-Chart", image: whChart, actionName: ACTIONS.WH_CHART },
];
const AddGraphicModal = (props: any) => {
  const {
    openGraphicModal,
    handleCloseGraphicModal,
    handleOpenStudentCountModal,
    handleActionChange,
    selectedView
  } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const handleClick=(option:any)=>{
    handleCloseGraphicModal();
    if(selectedView===COURSES_PAGES.LESSON){
      handleOpenStudentCountModal(option);
    }else if(selectedView===COURSES_PAGES.ASSIGNMENT){
      handleActionChange(option.actionName)
    }
  }
  return (
    <Modal open={openGraphicModal} onClose={handleCloseGraphicModal}>
      <Box style={modalStyle} className={classes.paperGraphicModal}>
        <Box className={classes.containerGraphicModal}>
          <Box className={classes.popupContent}>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {configJSON.graphicModalTitle}
              </Typography>
              <ClearIcon onClick={handleCloseGraphicModal} />
            </Box>
            <Box className={classes.optionBoxGraphicModal}>
              {options.map((option: any) => (
                <Box
                  className={classes.optionItemGraphicModal}
                  data-test-id="handleClick"
                  onClick={() => {
                   handleClick(option)
                  }}
                  key={option.name}
                >
                  <img src={option.image} style={{ marginBottom: "10px" }} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography className={classes.optionName}>
                      {option.name}
                    </Typography>
                    <img src={info} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddGraphicModal;
