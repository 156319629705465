import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
import { lessons, assignments, quizzes } from "../../assets";
const configJSON = require("../../config");
const useStyles = makeStyles((theme) => ({
  imageOption: {
    marginBottom: "10px",
  },
  popupContentOption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    width: "100%",
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    padding: "15px 30px",
    minHeight: "204px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    maxWidth: "180px",
    flexDirection: "column",
    "&:hover": {
      background: "#ebf8fe",
      borderColor:"#3093DDFF",
      color: "#121212"
    }
  },
  titleOption: {
    fontFamily: "Poppins",
    fontSize: "28px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "40px",
  },
  optionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
  },
  optionsLayout: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
  },
  paperOption: {
    position: "absolute",
    width: "740px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },

  crossIconOption: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor:"pointer"
  },
}));
const options = [
  {
    name: "Lessons",
    image: lessons,
    action:"Lesson"
  },
  {
    name: "Quizzes",
    image: quizzes,
    action:"Quiz"
  },
  {
    name: "Assignments",
    image: assignments,
    action:"Assignment"
  },
];
const OptionsModal = (props: any) => {
  const { handleCloseModal, handleCreateOpenModal } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paperOption}>
      <Box className={classes.container}>
        <ClearIcon
          onClick={handleCloseModal}
          className={classes.crossIconOption}
        />
        <Box className={classes.popupContentOption}>
          <Typography className={classes.titleOption}>
            {configJSON.selectOptionText}
          </Typography>
          <Box className={classes.optionsLayout}>
            {options.map((option: any) => (
              <Box key={option.name}>
                <Box
                  data-test-id="action-button"
                  onClick={(e) => handleCreateOpenModal(option.action)}
                  className={classes.itemBox}
                >
                  <img src={option.image} className={classes.imageOption} />
                  <Typography className={classes.optionTitle}>
                    {option.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OptionsModal;
