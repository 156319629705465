import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon, studentIcon, teacherIcon } from "./assets";

interface BasicCardProps {
  //   data: any;
  //   handleClick: (event: React.MouseEvent, data: any) => void;
  isVisibleModal: boolean;
  hideModal: any;
  handleSelectRole: any;
  selectedRole: string;
  handleSubmitRole: any;
  source: string;
}
type PositionStyle = 'absolute'
const gradientBackground = "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%)";

const useStyles = makeStyles(() => ({
  modalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justyfyContent: 'center',
    gap: "12px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // minWidth: 300,
    // maxWidth:600,    
    // bgcolor: "background.paper",
    backgroundColor: "#fff",
    border: "none",
    // boxShadow: 24,
    borderRadius: "15px",
    // p: 4,
    padding: "32px",
    width: '40%',
  },
  modalHeadText: {
    fontFamily: 'Poppins',
    fontSize: "28px",
    fontWeight: 700,
    color: "#2B3D7D"
  },
  closeIcon: {
    position: "absolute" as PositionStyle,
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  selectRoleText: {
    fontSize: '22px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#0a033c',
    marginBottom: '40px'
  },
  boxRow: {
    // display: "flex",
    // flexDirection:'row',
    // gap: "30px",
    // justifyContent: "space-evenly",
    // marginBottom: '40px'
    justifyContent:'space-around'
  },
  boxTech: {
    display: "flex",
    // gap: "10px",
    flexDirection: "column",
    width: "200px",
    height: "145px",
    // width: '15vw',
    // height: '30vh',
    borderRadius: "10px",
    border: "1px solid #dedde4",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // margin:'10px'
    marginTop:'10px'
  },
  boxActive: {
    border: "1px solid #2b3d7d",
    background: "#eef2ff",
  },
  boxIcon: {
    width: "60px",
    height: "60px",
  },
  textColor: {
    color: '#2B3D7D',
    fontFamily: "Poppins"
  },
  nextsbtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "82%",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    marginBottom: '10px',
    marginTop: '40px'
  },
}));
const UserSelectionModal = (props: BasicCardProps) => {
  const { isVisibleModal, hideModal, handleSelectRole, selectedRole, handleSubmitRole, source } = props;
  const classes = useStyles();

  return (
    <Modal open={isVisibleModal} onClose={hideModal} >
      <Box className={classes.modalsStyles}>
        <Typography variant="h4" className={classes.modalHeadText}>
          {source === "signup" ? "Sign Up" : "Login With Google"}
        </Typography>
        <img className={classes.closeIcon} src={closeIcon} onClick={hideModal} />
        <Typography variant="h5" className={classes.selectRoleText}>
          Select Role
        </Typography>
        {/* <Box 
        
        > */}
        <Grid container 
        className={classes.boxRow}
        >
          

          <Grid item md={4} sm={4} xs={10}
            data-test-id="role-select-teacher"
            className=
            {`${classes.boxTech}
            ${selectedRole === 'Teacher' ? classes.boxActive : ''}`}
            onClick={() => handleSelectRole("Teacher")}>
            <img src={teacherIcon} className={classes.boxIcon} />
            <Typography variant="h6" className={classes.textColor}>
              Teacher
            </Typography>
          </Grid >

          

          <Grid item md={4} sm={4} xs={10}
            data-test-id="role-select-student"
            className={`${classes.boxTech} ${selectedRole === 'Student' ? classes.boxActive : ''}`}
            onClick={() => handleSelectRole("Student")}>
            <img src={studentIcon} className={classes.boxIcon} />
            <Typography variant="h6" className={classes.textColor}>
              Student
            </Typography>
          </Grid>            



        </Grid>
        {/* </Box> */}
        <button data-test-id="submitRoleBtn" className={classes.nextsbtn} onClick={handleSubmitRole}>{source === "signup" ? "Next" : "Submit"}</button>
      </Box>
    </Modal>
  );
};

export default UserSelectionModal;


