import React from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import { TEACHER_DASHBOARD_VIEWS } from "../../../../components/src/types";
import TeacherInfo from "./pages/TeacherInfo.web";
const TeacherDashboard = (props: any) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    classList,
    searchInputClassName,
    totalStudentCount,
    selectedView,
  } = props.teacherState;
  const {
    handlePageChange,
    handleSearchClassName,
    pageNo,
    pageLimit,
    createLesson,
    setSelectedOption,
    selectedOption,
    redirectToClassDetailView,
    redirectToLibrary,
    getFormClassList,
    formClassList,
    createQuiz,
    createAssignment,
    redirectToLibraryassignment
  } = props;

  const renderSwitch = (selectedView: string) => {
    if (selectedView === TEACHER_DASHBOARD_VIEWS.DASHBOARD) {
      return (
        <TeacherInfo
          classList={classList}
          pageLimit={pageLimit}
          totalStudentCount={totalStudentCount}
          searchInputClassName={searchInputClassName}
          pageNo={pageNo}
          handleSearchClassName={handleSearchClassName}
          handlePageChange={handlePageChange}
          createLesson={createLesson}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          redirectToClassDetailView={redirectToClassDetailView}
          redirectToLibrary = {redirectToLibrary}
          getFormClassList={getFormClassList}
          formClassList={formClassList}
          createAssignment={createAssignment}
          createQuiz={createQuiz}
          redirectToLibraryassignment={redirectToLibraryassignment}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item {...(!isSmallScreen && { xs: 2 })}>
          {!isSmallScreen && <Sidebar />}
        </Grid>
        <Grid item {...(!isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <AppHeaderWeb title={TEACHER_DASHBOARD_VIEWS.DASHBOARD} />
          </Grid>
          <Grid item md={12}>
            {renderSwitch(selectedView)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherDashboard;
