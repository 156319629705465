import React from "react";
import GenericStaticCard from "../../../../../components/src/GenericStaticCard.web";
import { Box, Grid } from "@material-ui/core";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import {
  assignmentsIcon,
  lessons,
  quizzes,
} from "../../assets";
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  typeList: {
    [theme.breakpoints.down('xs')]: {
     justifyContent:"center"
    },
  },
}));


const staticOptions = [
  {
    label: "Lessons",
    image: lessons,
  },
  {
    label: "Quizzes",
    image: quizzes,
  },
  {
    label: "Assignments",
    image: assignmentsIcon,
  },
];
export const handleGoToAssignTask = (label:string)=>{
  if(label ==='Assignments'){
    window.location.replace("/assigntasks")
  }
}

export const handleClick = (label: string, destination:any, showLessonListView:any) => {
  showLessonListView({
    view: MY_CLASSES_VIEWS.CLASS_LIBRARY,
    breadcrumbDisplayName: destination,
    destination: label,
  });
};

const ClassLibrary = (props: any) => {
  const { destination, locations, showLessonListView, redirect } = props;
  const classes = useStyles();

  
  return (
    <Box sx={{ px: "35px" }}>
      <Box sx={{ mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={destination}
          redirect={redirect}
        />
      </Box>
      <Box sx={{ mt: "30px" }}>
        <Grid container spacing={4} className={classes.typeList}>
          {staticOptions.map((option) => (
            <Grid item key={option.label}>
              <GenericStaticCard
                label={option.label}
                image={option.image}
                handleClick={
                  handleClick.bind(this,option.label, destination, showLessonListView)
                }
                imageStyle={{height:"123px",width:"107px"}}
                data-test-id={`libcard-${option.label}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ClassLibrary;
