import React from "react";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { TextField,Typography,Popover,Divider } from "@material-ui/core";
import { sendIcon,userIcon,deleteIcon, editIcon, } from "./assets";
import CustomDeleteComment from "../../../components/src/CustomDeleteComment.web";
import { dateTimeFormat } from '../../../components/src/CustomDateTimeFormat.web';
interface Comment{
  id: string;
  commentDesc: string;
 hozPos:string;
 verPos:string;
 name:string;
 createdAt:string;
}
// Customizable Area End

import CommentController, {Props} from "./CommentsController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Comments extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.deleteComment = this.deleteComment.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.updateCommentApi=this.updateCommentApi.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      this.setState({ iconPos: { ...this.state.iconPos, show: false } });
      return;
    }
    if (selection.rangeCount > 0) {
      this.setState({ selectionRange: selection.getRangeAt(0) });
    }
  }
  
  handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ commentDesc: e.target.value });
  }

  handleCreateComment = () => {
    if(this.state.commentDesc !== ""){
      this.addComment(this.state.commentDesc);
    }
  }
  handleUpdateComment = () => {
    if(this.state.isEdit){
      this.updateCommentApi(this.state.commentId);
    }else{
      this.addComment(this.state.commentDesc);
    }
  }
 
  renderCommentList=()=>{
    return (
      <>
        <div
          style={style.containerBox}
        >
          <div style={style.mainBox}>
          {this.props.commentArr?.map((comment:Comment)=>
          (<>
          <div style={style.mainWrapper}>
          <div style={style.topWrapper}>
            <div style={style.iconWrapper}>
              <img src={userIcon} />
              <Typography style={style.name}>{comment.name}</Typography>
              <Typography style={style.dateTime}>{dateTimeFormat(comment.createdAt)}</Typography>
            </div>
            <div style={style.imageBox}>
              <div 
              data-test-id="edit-option"
              onClick={()=>this.setState({isEdit:true,commentId:comment.id,commentDesc:comment.commentDesc})}>
                <img style={style.editIconImg} src={editIcon} />
              </div>
              <div 
                data-test-id="delete-option"
                onClick={() => this.setState({ isDelete: true,commentId:comment.id,commentDesc:comment.commentDesc })}>
                <img style={style.deleteIconImg} src={deleteIcon} />
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "40px" }}>
            <Typography variant="body1" style={style.commentDesc}>
              {comment.commentDesc}
            </Typography>
          </div>
        </div>
        <Divider/>
          </>)
          )}
          </div>
          <div style={style.input}>
            <TextField
              id="comment"
              name="comment"
              fullWidth
              InputProps={{ style: { height: '40px' }, disableUnderline: true }}
              variant="standard"
              style={style.textField}
              placeholder='Enter your message here...'
              value={this.state.commentDesc}
              onChange={this.handleChange}
            />
            <div style={style.imgBox} data-test-id="update-option" onClick={this.handleUpdateComment}>
              <img src={sendIcon} style={style.sendIcon} />
            </div></div>
        </div>
        <CustomDeleteComment
          data-test-id="delete-modal"
          handleCloseDeleteModal={this.handleCloseDelete}
          deleteCommentApi={this.deleteComment}
          showDeletePopUp={this.state.isDelete}
          commentId={this.state.commentId}
          commentDesc={this.state.commentDesc}
        />
      </>
    );
  }
  
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      this.props.anchorEl ===null ? 
      (
        <Popover
        anchorEl={null}
        anchorReference="none"
        open={this.props.anchorEll != null}
        data-test-id="add-comment"
        onClose={() => {
          this.props.setAnchorEll(null)
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            left: `${this.props.tooltipPos.x}px`,
            top: `${this.props.tooltipPos.y}px`,
            transform: 'translate(0, 0)',
            width: "414px",
            height: "40px",
            boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
            backgroundColor: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            overflowY:"hidden"
        },
      }}
    >
            <TextField
              id="comment"
              name="comment"
              data-test-id="comment-name"
              fullWidth
              InputProps={{ style: { height: '40px' }, disableUnderline: true }}
              variant="standard"
              style={style.textField}
              placeholder='Enter your message here...'
              value={this.state.commentDesc}
              onChange={this.handleChange}
              autoComplete="off"
            />
      <button style={{...style.imgBox1,
    backgroundImage:  this.state.commentDesc !== ""? "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)":"",
    backgroundColor:this.state.commentDesc === "" ?"#808080":""
      }}
      disabled={this.state.commentDesc===""}
       data-test-id="create-option" onClick={this.handleCreateComment}>
        <img src={sendIcon} style={style.sendIcon} />
      </button>
    </Popover>) : this.renderCommentList()
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const style = {
  container: {
    width: "414px",
    height: "40px",
    position: 'absolute',
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    transform: 'translateY(10px)',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      }
    },
    flex: 1,
  },
  sendIcon: {
    width: "20px",
    height: "20px"
  },
  imgBox: {
    borderRadius: "50%",
    height: "32px",
    width: "32px",
    backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  containerBox: {
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px 7px',
    borderRadius: "5px",
    flexDirection: "column" as "column",
  },
  editIconImg: { height: '18px', width: '18px',cursor: "pointer" },
  deleteIconImg: { height: '24px', width: '24px',cursor: "pointer" },
  commentDesc: { marginTop: 24, fontFamily: "Poppins" },
  mainWrapper: {
    display: "flex", flexDirection: "column" as "column", padding: "0px 16px",marginTop:"10px"
  },
  topWrapper: {
    display: "flex", flexDirection: "row" as "row", justifyContent: "space-between", gap: "20px"
  },
  iconWrapper: {
    display: 'flex', gap: "15px", justifyContent: "center", alignItems: "center"
  },
  name: {
    color: "#2B3D7D", fontFamily: "Poppins", fontSize: "16px", fontWeight: "bold" as "bold"
  },
  dateTime: { color: "#282829", fontFamily: "Poppins", fontSize: "12px" },
  imageBox: { display: "flex", flexDirection: "row" as "row", gap: "10px", justifyContent: "center", alignItems: "center" },
  input: { display: 'flex', width: "100%", border: "1px soild #48484A", justifyContent: "center", alignItems:"center" },
  imgBox1: {
    borderRadius: "50%",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "none"
  },
  mainBox:{display:"flex",flexDirection:'column' as 'column',overflow:"auto",height:"fit-content",maxHeight:"248px"}
}

// Customizable Area End
