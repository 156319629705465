import React from "react";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import SearchInput from "../../../../../components/src/SearchInput.web";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import LessonTable from "../components/LessonTable.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
const useStyles = makeStyles(() => ({
  searchBarLayout: {
    display: "flex",
  },
  addButtonLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
}));
const LessonList = (props: any) => {
  const classes = useStyles();
  const {
    destination,
    locations,
    lessonList,
    redirect,
    handleSearchLessonName,
    searchInputClassName,
    pageNoLesson,
    pageLimitLesson,
    handlePageLessonList,
    redirectToLiveLessonStreamView,
    handleCloseNoMobileModal,
    openNoMobileModal,
    handleOpenNoMobileModal
  } = props;

  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={destination}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addButtonLayout}>
          <Box className={classes.searchBarLayout}>
            <SearchInput
              handleSearchInput={handleSearchLessonName}
              searchInput={searchInputClassName}
              placeholder={"Search by name"}
            />
          </Box>
        </Box>
        <Box>
          {lessonList.length === 0 ? (
            <Typography className={classes.noRecords}>
              No records found
            </Typography>
          ) : (
            <>
              <LessonTable lessonList={lessonList} handleClick={redirectToLiveLessonStreamView} handleOpenNoMobileModal={handleOpenNoMobileModal}
          handleCloseNoMobileModal={handleCloseNoMobileModal}
          openNoMobileModal={openNoMobileModal}/>

              <Box sx={{ px: "35px", py: "50px" }}>
                <BasicPagination
                  handlePageChange={handlePageLessonList}
                  pageLimit={pageLimitLesson}
                  pageNo={pageNoLesson}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LessonList;
