import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon } from "../../../assets";
import GenericButton from "../../../../../../components/src/GenericButton.web";

const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  paper: {
    position: "absolute",
    width: "490px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "28px 8px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
  },
  lessonName: {
    borderRadius: "6px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    border: "1px solid rgba(43, 61, 125, 0.12)",
    width: "330px",
    height: "64px",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    marginBottom: "20px",
  },
  deleteheading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#202020",
    fontWeight: 400,
    lineHeight: '28px',
    marginBottom: "20px",
    textAlign: "center",
    width: "399px"
  },
  image: {
    marginBottom: "15px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const MoveConfirmationPopup = (props: any) => {
  const { handleClose, contextMenuData,moveLessonToFolder,folderId} = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const handleMove=()=>{
   moveLessonToFolder(folderId,contextMenuData.id)
  }
  return (
    <>
      <Box sx={modalStyle} className={classes.paper}>
        <img className={classes.closeIcon} src={closeIcon} onClick={handleClose} />
        <Box >
          <Box className={classes.popupContent}>
            <Typography className={classes.deleteheading}>
              Are you sure you want to move the <span style={{color:"#2B3D7D",fontWeight:"bolder"}}>"{contextMenuData.name}" </span>{contextMenuData.itemType} to this folder?
            </Typography>
            <span className={classes.lessonName}>
              {contextMenuData.name}
            </span>
            <Box className={classes.btnLayout}>
              <GenericButton
                type="NORMAL"
                label="Cancel"
                handleClick={handleClose}
                customStyle={{ width: "150px" }}
              />
              <GenericButton
                data-test-id="move-btn"
                type="COLORED"
                label="Move"
                handleClick={handleMove}
                customStyle={{ width: "150px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};


export default MoveConfirmationPopup;