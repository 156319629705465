import React from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import GenericButton from "../../../../../../components/src/GenericButton.web";

const useStyles = makeStyles((theme) => ({
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer"
},
paper: {
  position: "absolute",
  width: "380px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "10px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  display: "flex",
  padding: "30px 40px",
  [theme.breakpoints.down('xs')]: {
    width:'72%'
   },
},
header: {
  width:"100%",
  display: "flex",
  flexDirection: "column",
},
title: {
  fontFamily: "Poppins",
  fontSize: "22px",
  color: "#2C2C2E",
  fontWeight: 600,
  marginBottom: "25px",
},
inputLabel: {
  padding: "10px 0",
  fontFamily: "Poppins",
  fontSize: "1rem",
  fontWeight: 600,
  color: "#2C2C2E",
},
inputField:{
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins',
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins',
  },
  marginTop:"10px",
}
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const AddFolderModal = (props: any) => {
  const {
    handleCloseAddFolderModal,
    handleChangeFolderInput,
    createLessonFolder,
    folderName,
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [customError,setCustomError] = React.useState<string>("")
  const handleSave = (data:string) => {
    const pattern = /^(?!\s*$).+/;
    if (!pattern.test(data)) {
      setCustomError("Folder name is required!");
    }else{
      createLessonFolder(folderName.id,data)
    }
  }
  return (
        <Box style={modalStyle} className={classes.paper} >
          <Box className={classes.popupContent}>
            <Box className={classes.closeIcon} onClick={handleCloseAddFolderModal}>
              <ClearIcon
                data-test-id="close-modal"
              />
            </Box>
            <Box className={classes.header}>
              <Typography className={classes.title}>
                {folderName.type==="Add"? "New Folder":"Edit Folder"}
              </Typography>
            </Box>
            <Box sx={{ mb: "40px", mt:"25px" }}>
              <label htmlFor="folderName" className={classes.inputLabel}>
              Folder Name
              </label>
              <TextField
                id="folderName"
                name="folderName"
                data-test-id="folder-name"
                fullWidth
                className={classes.inputField}
                placeholder="Folder Name"
                variant="outlined"
                value={folderName.name}
                error={!!customError}
                onChange={(e)=>{
                  setCustomError("")
                  handleChangeFolderInput(e.target.value)}}
              />
              {customError && (
          <Typography color="error" style={{ marginBottom: '10px',fontFamily:"Poppins" }}>
            {customError}
          </Typography>
        )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: "25px",width:"100%" }}>
              <GenericButton
                data-test-id="save-btn"
                type="COLORED"
                label={`${folderName.type==="Add"?"Create" : "Save"} Folder`}
                handleClick={()=>handleSave(folderName.name)}
                customStyle={{width:"100%"}}
              />
            </Box>
          </Box>
    </Box>
  );
};

export default AddFolderModal;
