import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { deleteConfirmationImage } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
const useStyles = makeStyles((theme) => ({
  deleteConfirmationTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 400,
    textAlign: "center",
  },
  deleteConfirmationImage: {
    marginBottom: "10px",
  },
  btnLayout: {
    [theme.breakpoints.down('xs')]: {
      display:"flex",
      width: "100%"
     },
  }
}));
const DeleteConfirmation = (props: any) => {
  const { deleteStudent, userData, handleCloseModal } = props;
  const classes = useStyles();
  const handleDelete = (e: any) => {
    deleteStudent(userData.id);
  };
  return (
    <>
      <img
        className={classes.deleteConfirmationImage}
        src={deleteConfirmationImage}
      />
      <Typography className={classes.deleteConfirmationTitle}>
        {`Are you sure you want to delete "${userData.name}" student profile?`}
      </Typography>
      <Box sx={{ mt: "30px" }} className={classes.btnLayout}>
        <GenericButton
          type="NORMAL"
          label="Cancel"
          handleClick={(e) => handleCloseModal(e, userData.id)}
          customStyle={{ width: "154px" }}
        />
        <GenericButton
          type="COLORED"
          label="Delete"
          handleClick={(e) => handleDelete(e)}
          customStyle={{ width: "154px" }}
        />
      </Box>
    </>
  );
};

export default DeleteConfirmation;
