export const logoMiniIcon = require("../assets/logo-white.svg");
export const notificationIcon = require("../assets/notification.svg");
export const profileIcon = require("../assets/userimg.svg");
export const teacherImage = require("../assets/teacher.svg");
export const studentImage = require("../assets/student.svg");
export const searchBarIcon = require("../assets/searchIcon.svg");
export const calendarPickerIcon = require("../assets/calendar_picker_button.png");
export const defaultImage = require("../assets/default_image.jpg");
export const trashImageIcon = require("../assets/delete_image.svg");
export const library = require("../assets/library.svg");
export const reports = require("../assets/reports.svg");
export const students = require("../assets/students.svg");
export const calender = require("../assets/calender.svg");
export const createActionImage = require("../assets/create_action.svg");
export const deliverActionImage = require("../assets/deliver_action.svg");
export const assessActionImage = require("../assets/assess_action.svg");
export const assignActionImage = require("../assets/assign_action.svg");
export const studentCount = require("../assets/student_count.svg");
export const viewStudentCount = require("../assets/view_student_count.svg");
export const lessons = require("../assets/lessons.svg");
export const assignments = require("../assets/assignments.svg");
export const quizzes = require("../assets/quizzes.svg");
export const uploadIcon = require("../assets/view_upload.svg");
export const assignment_pending=require("../assets/assignment_pending.png");
export const certificate_earned=require("../assets/certificate_earned.png");
export const course_progress=require("../assets/course_progress.png");
export const course_complete=require("../assets/course_complete.png");
export const DeleteInactive=require("../assets/delete_option_inactive.svg");
export const EditInactive=require("../assets/edit_option_inactive.svg");
export const FilterIcon = require("../assets/filterIcon.svg")
export const StudentIcon = require("../assets/studentIcon.svg")
export const DefaultClassIcon = require("../assets/defaultClassIcon.jpg")
export const SuccessIcon = require("../assets/successIcon.png")
export const TickIcon = require("../assets/tickIcon.svg")
export const calendarIcon = require("../assets/calendarIcon.svg")
export const DeleteActive = require("../assets/delete_option_active.svg")
export const EditActive = require("../assets/edit_option_active.svg")
export const userDefaultIcon1 = require("../assets/class_default_1.png")
export const calendarDefaultIcon2 = require("../assets/class_default_2.png")
export const folderDefaultIcon = require("../assets/image_default_folder.svg")
export const groupLessonIcon = require("../assets/group_lesson_icon.svg")
export const groupQuizIcon = require("../assets/group_quiz_icon.svg")
export const groupAssignmentIcon = require("../assets/group_assignment_icon.svg")
export const noClassImg = require("../assets/noClassImg.svg")
export const ViewClock = require("../assets/view_Quizzes.svg")
export const ClockIcon = require("../assets/clockIcon.svg")
export const ViewTableQuizIcon = require("../assets/view_Quizzes1.svg")
export const TableAssignmentIcon = require("../assets/image_Assignments.svg")
export const EditEventIcon= require("../assets/edit_events_icon.png")
export const dateIcon= require("../assets/dateIcon.png")