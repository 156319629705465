import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { deleteSuccessImage } from "../../assets";
const configJSON = require("../../config");
const useStyles = makeStyles(() => ({
  deleteSuccessImage: {
    marginBottom: "10px",
  },
  deleteSuccessTitle: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "5px",
  },
  deleteSuccessSubtitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#3F526D",
    fontWeight: 400,
    textAlign: "center",
  },
}));
const DeleteSuccess = (props: any) => {
  const { fullName } = props;
  const classes = useStyles();
  return (
    <>
      <img className={classes.deleteSuccessImage} src={deleteSuccessImage} />
      <Typography className={classes.deleteSuccessTitle}>
        {configJSON.deleteSuccessTitle}
      </Typography>
      <Typography className={classes.deleteSuccessSubtitle}>
        {`Student profile "${fullName}" has been deleted successfully.`}
      </Typography>
    </>
  );
};

export default DeleteSuccess;
