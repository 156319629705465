import React from "react";
import InitQuestion from "./InitQuestion.web";
import { ACTIONS } from "../../../../../../../components/src/types";
import { Box, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChartQuestion from "./ChartQuestion.web";
import VennQuestion from "./VennQuestion.web";
import WordCloudQuestion from "./WordCloudQuestion.web";
import McqQuestion from "./McqQuestion.web";
import OpenEndedQuestion from "./OpenEndedQuestion.web";
import MatchingQuestion from "./MatchingQuestion.web";

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
    marginBottom: "20px",
  },
  mainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Question = (props: any) => {
  const { currentStage, showQuestion, setShowQuestion, rightAnswerTick,stageType,subStages } = props;
  const classes = useStyles();
  const renderQuestionLayout = () => {
    if (!currentStage) return;
    if (
      stageType === ACTIONS.KWL_CHART ||
      stageType === ACTIONS.WH_CHART ||
      stageType === ACTIONS.SEED_DISCUSSION ||
      stageType === ACTIONS.T_CHART
    ) {
      return <ChartQuestion currentStage={currentStage} />;
    }else if(stageType === ACTIONS.VENN_DIAGRAM){
      return <VennQuestion currentStage={currentStage} />;
    }else if(stageType === ACTIONS.ADD_WORD_CLOUD){
      return <WordCloudQuestion currentStage={currentStage} />;
    }else if(stageType === ACTIONS.MCQ){
      return <McqQuestion currentStage={currentStage} rightAnswerTick={rightAnswerTick} />
    }else if(stageType === ACTIONS.OPEN_ENDED_QUESTION){
      return <OpenEndedQuestion currentStage={currentStage}/>
    }else if(stageType === ACTIONS.ADD_MATCHING_Q){
      return <MatchingQuestion currentStage={currentStage}/>
    } else if(stageType === ACTIONS.POLLING_Q){
      return <McqQuestion currentStage={currentStage} isPolling={true}/>
    }  else{
      return (
      <Box className={classes.mainLayout}>
      <Box className={classes.content}>
        <Typography className={classes.text}>{"Questions started"}</Typography>
      </Box>
    </Box>)
    }
  };
  return (
    <>
      {!showQuestion ? (
        <InitQuestion
          setShowQuestion={setShowQuestion}
          showQuestion={showQuestion}
          currentStage={currentStage}
          hasSubStage={(subStages ?? []).length>0}
        />
      ) : (
        renderQuestionLayout()
      )}
    </>
  );
};

export default Question;
