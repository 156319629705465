import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import { noClassImg } from "../../assets";

interface Data {
  attributes: Attribute,
  image: string;
  id: string;
  name: string;
}

interface Attribute {
  attachment: string,
  class_code: string
  class_name: string,
}
const useStyles = makeStyles(() => ({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  div: {},
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px 10px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
      cursor: "pointer"
    },
    "@media (max-width: 600px)": {
     flexDirection: 'column'
    },
  },
  idBar: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 600px)": {
      marginTop: '6px'
     },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 600,
  },
  idText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
  },
  listItemImage: {
    width: "100%",
    height: "100%",
  },
  countBarImage: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    marginRight: "10px",
    padding: "8px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  classDetailsStudent: {
    "@media (max-width: 600px)": {
      alignItems:'center',
      display:"flex",
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop:12
     },
  },
})); 
const ClassList = (props: any) => {
  const { dataList, longView, handleClick } = props;
  const classes = useStyles();
  let datalist = dataList && (longView ? dataList : dataList.slice(0, 9))
  return (
    <Box>
      {datalist && <>
        {datalist.length === 0 ? (
          <Typography className={classes.noRecords}>No records found</Typography>
        ) : (
          <Grid container>
            {datalist.length > 0 && datalist?.map((data: Data) => (
              <Grid item md={4} sm={6} xs={12} key={data.id}>
                <Box
                  className={classes.listItemBox}
                  onClick={handleClick ? (e) =>
                    handleClick(e, {
                      id: data.id,
                      view: MY_CLASSES_VIEWS.MY_CLASSES,
                      breadcrumbDisplayName: "My Classes",
                      destination: data?.attributes?.class_name,
                    }) : undefined
                  }
                >
                  <Box className={classes.imageBox} >
                    <img className={classes.listItemImage} src={data?.attributes?.attachment ?? noClassImg} />
                  </Box>
                  <Box className={classes.classDetailsStudent}>
                    <Typography className={classes.listItemFont}>
                      {data?.attributes?.class_name}
                    </Typography>
                    <Box className={classes.idBar}>
                      <Typography className={classes.idText}>
                        Class Code: {data?.attributes?.class_code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </>}
    </Box>
  );
};

export default ClassList;
