import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import Loader from "../../../../../../components/src/Loader.web";
import ItemList from "../../../../../../components/src/ItemList.web";
const useStyles = makeStyles(() => ({
  classNameFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
  },

  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop: "20px",
  },
  classBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    cursor: "pointer",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
  },
  classImageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#fff",
  },
  classImage: {
    width: "100%",
    height: "100%",
  },
}));
const ClassList = (props: any) => {
  const {
    currentLocation,
    locations,
    teacherClasses,
    callback,
    redirect,
    loading,
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box sx={{pl:"35px", mt:"30px"}}>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
      </Box>
      {loading ? (
        <Loader loading />
      ) : (
        <>
          {teacherClasses.length === 0 ? (
            <Typography className={classes.noRecords}>
              No records found
            </Typography>
          ) : (
            <>
              <Box sx={{ px: "20px", pt: "5px" }}>
                <ItemList dataList={teacherClasses} handleClick={callback} />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ClassList;
